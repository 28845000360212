<div class="modal__content">
    <div class="modal__list">

        <h5>Autograph Item</h5>
        <div class="select">
            <!-- <select class="input" name="selectedObject" id="selectedObject" [(ngModel)]="options.item.signedObject">
                <option *ngFor="let object of objects" [ngValue]="object">{{object.name}}</option>
            </select> -->
        </div>

        <h5>Service</h5>
        <!-- <div class="checkout__item" *ngFor="let service of autographServices" [class.item- -selected]="isSelected(service)" (click)="select(service)">
            <h5>{{service.name}}</h5>
        </div> -->
    
        <h5>Sticker Placement</h5>
        <div class="select">
            <!-- <select class="input" name="selectedObject" id="selectedObject" [(ngModel)]="options.settings.stickerOnItem">
                <option *ngFor="let placement of stickerPlacement" value="{{placement.onItem}}">{{placement.name}}</option>
            </select> -->
        </div>

        <h5>Grading</h5>
        <!-- <div class="checkout__item" *ngFor="let option of gradingOptions" [class.item- -selected]="isSelected(option)" (click)="select(option)">
            <h5>{{option.name}}</h5>
        </div> -->

    </div>
</div>
<div class="modal__actions">
    <button class="button button--secondary" (click)="close()">Cancel</button>
    <button class="button">Save</button>
</div>