<img style="top:10px" src="/assets/img/close-modal.svg" class="modal__close" (click)="close()">

<div class="new-site__modal" style="justify-items:baseline;">
    <h4 style="font-weight:600;">
    ALERT!
    </h4>

    <p style="font-size:18px;text-align:left;white-space:inherit;">
        Updating this address WILL NOT change the delivery address for any orders currently in progress.
        For security reasons, we will always deliver to the address specified when the order was originally
        placed unless you contact our support department and make a specific request for a change.
    </p>
    <p style="font-size:18px;text-align:left;white-space:inherit;">
        If you would like to change the delivery address of an existing order, please add the new address
        (if it isn’t in your profile) and email <a href = "mailto:support&#64;gosgc.com" class="footer__menu-item" style="color:#785aff;">support&#64;gosgc.com</a>. In the email, please provide your order
        number, the new address, the reason for changing the address, and the best number to reach you,
        and we will get in touch with you as soon as we can.
    </p>

    <p>
        Thank you!
    </p>
</div>
