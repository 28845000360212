import { Component, Input } from '@angular/core';

@Component({
    selector: 'card-result-item',
    templateUrl: './card-result-item.component.html',
    styleUrls: ['../../checkout.component.sass']
})

export class CardResultItem {
    @Input() item: any = {};
}