import { Component, Input } from '@angular/core';
import { ModalService, ModalInterface } from '../../services/modal.service';
import { CheckoutService } from '../../../checkout/services/checkout.service';
import { Order } from '../../../orders/models/order';
import { OrderService } from '../../../orders/services/order.service';
import { ErrorService } from '../../../common/services/error.service';

@Component({
  selector: 'app-payment-terms',
  templateUrl: './payment-terms.component.html',
  styleUrls: ['./payment-terms.component.css']
})
export class PaymentTermsComponent implements ModalInterface {
    constructor(
        private modalService: ModalService,
        private checkoutService: CheckoutService,
        private orderService: OrderService,
        private errorService: ErrorService,
    ) { }

    type = "app-payment-terms";
    loader!: boolean;
    options: any;
    @Input() order = new Order();
    currentOrder = new Order();
    termsAccept: boolean = false;
    paypalAmountmismatch: any = 'One or more values may not have been updated properly. Please check all values and services again and click Next.';
    termsAcceptData ="Check here to indicate that you have read and agree to the SGC Terms and Conditions.";


    termsaccepted(accept: boolean) {
        this.checkoutService.changeCurrentpayTerms(accept)
        this.checkoutService.submitTerms(accept, this.termsAcceptData);
        this.close();
    }

    //termsaccepted(accept: boolean) {
    //    this.checkoutService.currentOrder$.subscribe(user => this.currentOrder = user);
    //    this.orderService.submitOrderTerms(accept, this.termsAcceptData, this.currentOrder).subscribe(response => {
    //        
    //        if (response.success == true) {
    //            this.checkoutService.changeCurrentpayTerms(accept)
    //        } else {
    //            this.errorService.paypalHandleError(this.paypalAmountmismatch);
    //        }
    //    });
    //    this.close();
    //}

    close() {
        this.modalService.close(this.type, 'close');
    }

    getMessage(): string {
        var defaultMessage = ``

        return this.options && this.options.message ? this.options.message : defaultMessage;
    }
    getMessage1(): string {
        var defaultMessage = ` If you are picking up your order at SGC or a show, we require a shipping address in the event you are unable to pick up your order. `

        return this.options && this.options.message1 ? this.options.message1 : defaultMessage;
    }
    getMessage2(): string {
        var defaultMessage = `If your shipping address is not displayed, or you would like to add another address, click the '+' to add an address. After saving the address, click it to view your shipping options`

        return this.options && this.options.message2 ? this.options.message2 : defaultMessage;
    }

    getActionLabel(): string {
        return this.options && this.options.actionLabel;
    }

    hasAction(): boolean {
        return this.options && this.options.actionLabel;
    }

    takeAction() {
        this.options.actionCallback();
    }
}

