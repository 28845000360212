import { Component } from "@angular/core";
import { ModalInterface, ModalService } from "../../../../common/services/modal.service";
import { Address } from "../../../../common/models/user";

@Component({
    selector: 'no-user-address-alert',
    templateUrl: './no-user-address-alert.component.html',
    styleUrls: ['../../../checkout.component.sass']
})
export class NoUserAddressAlertComponent implements ModalInterface {

    constructor(
        private modalService: ModalService
    ) { }

    title = "Address Alert";
    subtitle = "";
    type = "no-user-address-alert";
    options: any;

    close() {
        this.modalService.close(this.type, 'close');
    }

    accept() {
        this.modalService.open('address-editor',new Address({ country: { code: 'US' }, state: { code: '' } }));
        //this.close();
    }
}
