import { ActivatedRoute } from '@angular/router';
import {
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  OnDestroy,
} from '@angular/core';
import { PopReportsService } from '../services/pop-reports.service';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';
import { EmitType } from '@syncfusion/ej2-base';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-popreport-sendmessage',
  templateUrl: './popreport-sendmessage.component.html',
  styleUrls: ['./popreport-sendmessage.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class PopreportSendmessageComponent implements OnInit, OnDestroy {
  successmsg = false;
  certificationCodePattern = '^-?[0-9]\\d*(\\-\\d{1,3})?$';
  mobNumberPattern = '^((\\+91-?)|0)?[0-9]{10}$';
  email = '[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$';
  cardno: any;
  cardSet: any;
  subject: any;
  description: any;
  sportcard: any;
  check: any;
  crdno: any;
  crdSet: any;
  subj: any;
  desc: any;
  sport: any;
  output: any;
  page: any;
  form!: FormGroup;

  private subs = new SubSink();
  @ViewChild('Dialog')
  public dialogObj!: DialogComponent;
  public width: string = '335px';
  public visible: boolean = false;
  public multiple: boolean = false;
  public showCloseIcon: Boolean = true;
  public formHeader: string = 'Success';
  public content: string = 'You have successfully registered, Thank you.';
  public target: string = '#control_wrapper';
  public isModal: boolean = true;
  public animationSettings: any = {
    effect: 'Zoom',
  };
  public textboxValue!: any;
  private formSubmitAttempt!: boolean;
  public dlgBtnClick: EmitType<object> = () => {
    this.dialogObj.hide();
  };
  public dlgButtons: Object[] = [
    {
      click: this.dlgBtnClick.bind(this),
      buttonModel: { content: 'Ok', isPrimary: true },
    },
  ];
  public focusoutfunction(args: any) {
    if (args.target.value !== '') {
      args.target.parentElement
        .getElementsByClassName('e-float-text')[0]
        .classList.add('e-label-top');
    } else {
      args.target.parentElement
        .getElementsByClassName('e-float-text')[0]
        .classList.remove('e-label-top');
    }
  }
  SubmitEmail() {
    this.formSubmitAttempt = true;
    if (this.form.invalid) {
      return;
    }
    this.popReportsService.ReportIssueEmail(this.form.value).subscribe(
      (data) => {
        this.successmsg = true;
      },
      (error) => {
        this.successmsg = false;
      }
    );
  }

  constructor(
    private formBuilder: FormBuilder,
    private _routeParams: ActivatedRoute,
    private popReportsService: PopReportsService
  ) {}

  ngOnInit() {
    this.cardno = this._routeParams.snapshot.params['cardno'];
    this.cardSet = this._routeParams.snapshot.params['cardSet'];
    this.subject = this._routeParams.snapshot.params['subject'];
    this.description = this._routeParams.snapshot.params['description'];
    this.sportcard = this._routeParams.snapshot.params['sportcard'];
    this.page = this._routeParams.snapshot.params['page'];
    this.crdno = '#' + this.cardno;
    this.crdSet = this.cardSet;
    this.subj = this.subject;
    this.desc = this.description;
    this.sport = this.sportcard;
    this.check = 'regular';
    if (this.cardno == 'N/A') {
      this.crdno = '';
    }
    if (this.crdSet == 'N/A') {
      this.crdSet = '';
    }
    if (this.subj == 'N/A') {
      this.subj = '';
    }
    if (this.desc == 'N/A') {
      this.desc = '';
    }
    if (this.sport == 'N/A') {
      this.sport = '';
    }
    if (this.crdSet == 'T205 Master Pop') {
      this.sport = 'T205 Master Pop';
      this.check = 'T205 Master Pop';
    }
    if (this.crdSet == 'T206 Master Pop') {
      this.sport = 'T206 Master Pop';
      this.check = 'T206 Master Pop';
    }
    this.textboxValue = '';
    this.form = this.formBuilder.group({
      setname: new FormControl(this.cardSet),
      cardnumber: new FormControl(this.cardno),
      subject: new FormControl(this.subject),
      description: new FormControl(this.description),
      typeofissue: new FormControl('', Validators.required),
      firstname: new FormControl(
        null,
        Validators.compose([Validators.maxLength(100), Validators.required])
      ),
      email: new FormControl(
        null,
        Validators.compose([Validators.pattern(this.email)])
      ),
      certificationcode: new FormControl(
        null,
        Validators.compose([
          Validators.minLength(7),
          Validators.maxLength(11),
          Validators.pattern(this.certificationCodePattern),
        ])
      ),
      comments: new FormControl(null),
      mobile: new FormControl(
        null,
        Validators.compose([Validators.maxLength(20)])
      ),
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public isFieldValid(field: string) {
    return (
      !this.form.get(field)?.valid &&
      (this.form.get(field)?.dirty || this.form.get(field)?.touched)
    );
  }

  //typeofissuechange(event)
  //{
  //
  //    let issuechange = event.target.options[event.target.options.selectedIndex].value
  //    if (issuechange == "I had this card and it was cracked out") {
  //        this.form.get('email').setValidators([Validators.required,Validators.email]);
  //    }
  //    else
  //    {
  //        this.form.get('email').setValidators([Validators.email]);
  //    }
  //    this.form.updateValueAndValidity();
  //}
}
