import {
  Component,
  OnInit,
  ViewChild,
  CUSTOM_ELEMENTS_SCHEMA,
} from '@angular/core';
import { Router } from '@angular/router';
import { StateService } from '../common/services/state.service';
import { ActivatedRoute } from '@angular/router';
import { CheckoutService } from '../checkout/services/checkout.service';
import { OrderService } from '../orders/services/order.service';
import { Order } from '../orders/models/order';
import { User } from '../common/models/user';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';
import { environment } from '../../environments/environment';
import { ErrorService } from '../common/services/error.service';

@Component({
  selector: 'app-paypal',
  templateUrl: './paypal.component.html',
  styleUrls: ['./paypal.component.sass'],
})
export class PaypalComponent implements OnInit {
  baseUrl: string = environment.sgc.apiUrl;

  constructor(
    private route: ActivatedRoute,
    private orderService: OrderService,
    private router: Router,
    private checkoutService: CheckoutService,
    private stateService: StateService,
    private formBuilder: FormBuilder,
    private errorService: ErrorService
  ) {}

  invoiceOrderId: any;
  order = new Order();
  currentUser = new User();
  form?: FormGroup;
  email = '^[^@]+@[a-zA-Z0-9._-]+\\.+[a-z._-]+$';
  isSubmitting = false;
  emailsentmsg: any;
  isemailsent: boolean = false;
  paypalExecptionMsg: any;

  ngOnInit() {
    this.errorService.paypalExecption$.subscribe(
      (x: any) => (this.paypalExecptionMsg = x)
    );
    this.form = this.formBuilder.group({
      orderNumber: new FormControl(
        '',
        Validators.compose([
          Validators.pattern('^[0-9]*$'),
          Validators.required,
        ])
      ),
      name: new FormControl(
        null,
        Validators.compose([
          Validators.pattern('^[a-zA-Z,.!? ]*$'),
          Validators.maxLength(100),
          Validators.required,
        ])
      ),
      email: new FormControl(
        null,
        Validators.compose([
          Validators.pattern(this.email),
          Validators.required,
        ])
      ),
      phone: new FormControl('', Validators.required),
      alternatePhone: new FormControl(''),
      comments: new FormControl(''),
    });

    var orderId = this.getOrderId();
    this.invoiceOrderId = orderId;
    if (!orderId) return;
    this.checkoutService.currentOrder$.subscribe((order) => {
      this.order = order;
      this.form?.get('orderNumber')!.setValue(this.order.publicId);
      this.form?.get('name')!.setValue(this.order.customer.user.name);
      this.form?.get('email')!.setValue(this.order.customer.user.email);
      this.form?.get('phone')!.setValue(this.order.customer.phoneNumber);
    });
    this.currentUser = this.stateService.currentUser.get();
  }

  startNewSubmission() {
    this.router.navigate(['/checkout']);
  }

  mySubmission() {
    this.router.navigate(['/account/myorder']);
  }

  getOrderId() {
    if (!this.route.snapshot.params) return null;
    return this.route.snapshot.params['id'];
  }
}
