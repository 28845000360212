export class Alert {

    constructor(options?: any) {
        if(!options) {
            return;
        }

        this.type = options.type;
        this.message = options.message;
        this.actionCallback = options.actionCallback;
        this.actionLabel = options.actionLabel || "Dismiss";
    }

    type!: AlertType;
    message!: string;
    actionCallback!: any;
    actionLabel!: string;
}
 
export enum AlertType {
    Success,
    Error,
    Info,
    Warning
}