<img style="top:10px" src="/assets/img/close-modal.svg" class="modal__close" (click)="close()">

<div  class="h-100-img">
    <!-- <img src="/assets/img/b-medium.jpg" alt="Auth Code"> -->
    <picture>
        <source media="(max-width:574px)" srcset={{geth692FrontImage()}}>
        <img src={{geth1000FrontImage()}}  alt="Auth Code">
      </picture>
</div>
<style>
.h-100-img , .h-100-img img{
    width: -webkit-fill-available;
}
</style>
