<div class="modal__content">
    <div class="columns columns--2">
        <div class="modal__list">
            <input type="text" id="search" class="input" placeholder="Search Signer" name="search" [formControl]="searchTerm">
            <!-- <button class="button button- -sm" *ngIf="!isAnyResult()">Add</button> -->
            <subject-result-item *ngFor="let item of searchResults" [item]="item" (click)="select(item)"></subject-result-item>
        </div>
        <div class="columns__divider"></div>
        <div class="modal__list">
            <h4>Signers on your item</h4>
            <div class="chip" *ngFor="let signer of options.item.signers">
                <p>{{signer.name}}</p>
                <input type="image" src="/assets/img/trash.svg" class="button-icon" (click)="remove(signer)">
            </div>
        </div>
    </div>
</div>
<modal-actions [options]="options" [type]="type"></modal-actions>
