<style>
    ul, ol {
        padding-left: 40px;
        font-family: 'd35';
        font-weight: normal;
        margin: 0;
    }

    ul {
        list-style: disc;
    }

    p, ul, ol, i {
        font-size: 1.8rem;
        line-height: 2.25rem;
    }
    ::ng-deep .custom-grid-styles .e-pager div.e-parentmsgbar {
    float: right !important;
    padding-bottom: 9px !important;
    padding-right: 10px !important;
    padding-top: 9px !important;
  }

  ::ng-deep .custom-grid-styles .e-pager .e-pagercontainer .e-firstpagedisabled {
    padding: 12px 10px 8px !important;
  }
  ::ng-deep .custom-grid-styles .e-headercelldiv {
    /* margin-bottom: 0 !important; */
    padding: 0 !important;
  }

  ::ng-deep .custom-grid-styles .e-grid .e-gridheader thead .e-icons:not(.e-check):not(.e-stop) {
    font-size: 10px !important;
  }

  ::ng-deep .custom-grid-styles .e-toolbar {
    overflow: unset !important;
  }

  ::ng-deep .custom-grid-styles .e-grid .e-altrow {
    background-color: #f4f4f4 !important;
  }

  @media (max-width: 769px) {
    ::ng-deep .custom-grid-styles .e-pager div.e-parentmsgbar {
    -webkit-box-sizing: border-box;
    box-sizing: border-box !important;
    display: inline-block !important;
    float: initial !important;
    padding-bottom: 0 !important;
    padding-right: 0 !important;
    padding-top: 0 !important;
    text-align: center !important;
    vertical-align: top !important;
    width: calc(60% - 48px) !important;
}
  }
</style>

<div class="row_ m-0 p-4 w-100">
    <div class="col-12">
        <nav class="navbar ">
            <div class="sub-menu">
                <input type="checkbox" id="show-menu" role="button">
                <label for="show-menu" class="show-menu"></label>
                <ul class=" menu" id="menu">
                    <li>
                        <a class="menu__item " routerLink="/account/dashboard"><img src="./assets/img/dashboard/subm.png" alt="" width="15" /> Dashboard</a>
                    </li>
                    <li>
                        <a class="menu__item " routerLink="/account/myorder"><img src="./assets/img/dashboard/order.png" alt="" width="15" /> My Orders</a>
                    </li>
                    <li>
                        <a class="menu__item " routerLink="/account/sellerready"><img src="./assets/img/dashboard/seller.png" alt="" width="15" /> Seller Ready</a>
                    </li>
                    <li>
                        <a class="menu__item " routerLink="/account/receipts"><img src="../../../../assets/img/dashboard/Dollar Icon - Black - S.png" alt="" width="15" /> Receipts</a>
                    </li>
                    <li>
                        <a class="menu__item " routerLink="/account/needhelp"><img src="./assets/img/dashboard/helpp.png" alt="" width="15" /> I Need Help</a>
                    </li>
                    <li>
                        <a class="menu__item active" routerLink="/account"><img src="./assets/img/dashboard/user.png" alt="" width="15" />My Profile</a>
                    </li>
                    <!--<li>
                        <a class="menu__item" routerLink="/account/my-cards"><img src="./assets/img/dashboard/seller.png" alt="" width="15" />My Cards</a>
                    </li>-->
                    <!--<li>
                        <a class="menu__item" routerLink="/account/my-cards-download"><img src="./assets/img/dashboard/seller.png" alt="" width="15" />Card Downloads</a>
                    </li>-->
                </ul>
            </div>
        </nav>
    </div>
</div>
<div class="wrapper">
    <h2>My Profile</h2>
    <div class="user-links mt-4 p-4">
        <div class="row m-0 mt-1">
            <div class="col-12 mb-4">
                <img src=""><h3>{{currentUser.name}}</h3>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="grey-bg h-100">
                    <div class="row m-0 align-items-center">
                        <div class="col-9">
                            <h5>Customer Name</h5>
                            <p>{{getCustomerNameValue()}}</p>
                        </div>
                        <div class="col-3 text-right"><a (click)="editCustomer()"><img src="./assets/img/dashboard/edit.png" alt="" width="15" /></a></div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="grey-bg h-100">
                    <div class="row m-0 align-items-center">
                        <div class="col-9">
                            <h5>Email</h5>
                            <p>{{getEmailValue()}}</p>
                        </div>
                        <div class="col-3 text-right"><a (click)="editEmail()"><img src="./assets/img/dashboard/edit.png" alt="" width="15" /></a></div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="grey-bg h-100">
                    <div class="row m-0 align-items-center">
                        <div class="col-9">
                            <h5>Phone Number</h5>
                            <p>{{getPhoneNumberValue()}}</p>
                        </div>
                        <div class="col-3 text-right"><a (click)="editPhone()"><img src="./assets/img/dashboard/edit.png" alt="" width="15" /></a></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row m-0 mt-4">
            <div class="col-12 mb-4">
                <h3>Shipping Address(es):</h3>
            </div>
            <button class="button mb-4" style="margin-left: 14px; background: black;" (click)="addAddress()">
                <span _ngcontent-c4="">Add Address</span>
            </button>
        </div>
        <div class="col-lg-9 e-bigger e-adaptive-demo">
            <div class="e-mobile-layout">
                <div *ngIf="!checkDeleted()" class="overflow-x e-mobile-content custom-grid-styles">
                    <ejs-grid #grid [dataSource]='userAddresses' gridLines="both" [allowSorting]='true'
                              [allowFiltering]="true" [filterSettings]='FilterOptions' enableAdaptiveUI='true'
                              [allowResizing]='true' allowTextWrap='true' [textWrapSettings]='wrapSettings'>
                        <e-columns>
                            <e-column [allowFiltering]="false" [allowSorting]='false' headerText='Default' width='50' style="font-size: 12px;">
                                <ng-template #template let-userAddresses>
                                    <input type="checkbox" [ngModel]="userAddresses.isDefault" (ngModelChange)="setDefault(userAddresses)" />
                                </ng-template>
                            </e-column>
                            <e-column field='street1' [allowFiltering]="false" headerText='Street' type='string' width='90' textAlign="center">
                            </e-column>
                            <e-column field='street2' [allowFiltering]="false" headerText='Street 2' type='string' textAlign='center' width=70></e-column>
                            <e-column field='city' [allowFiltering]="false" [allowSorting]='false' headerText='City' width='70' textAlign="center">
                            </e-column>
                            <e-column field='state.name' [allowFiltering]="false" [allowSorting]='false' headerText='State' width='70' textAlign="center">
                            </e-column>
                            <e-column field='zipCode' [allowFiltering]="false" [allowSorting]='false' headerText='Zip Code' width='70' textAlign="center">
                            </e-column>
                            <e-column field='country.name' [allowFiltering]="false" [allowSorting]='false' headerText='Country' width='70' textAlign="center">
                            </e-column>
                            <e-column field='phoneNumber' [allowFiltering]="false" headerText='Phone Number' type="string" textAlign="center" width=90></e-column>
                            <e-column [allowFiltering]="false" [allowSorting]='false' headerText='Action' width='70' textAlign="center">
                                <ng-template #template let-userAddresses>
                                    <button *ngIf="!checkUserAdress(userAddresses)" (click)="editAddress(userAddresses)"><img src="./assets/img/dashboard/edit.png" alt="" width="15" /></button>
                                    <button *ngIf="!checkUserAdress(userAddresses)" (click)="removeAddress(userAddresses)"><img src="./assets/img/dashboard/del.png" alt="" width="15" /></button>
                                    <!--<button (click)="addAddress()"><img src="./assets/img/dashboard/add.png" alt="" width="15" /></button>-->
                                </ng-template>
                            </e-column>
                        </e-columns>
                    </ejs-grid>
                </div>
            </div>
        </div>
        <div class="row m-0 mt-4">
            <div class="col-12 mb-4"><h3>My Order Preferences:</h3></div>
            <div class="col-12 col-lg-3 col-md-4 col-sm-6 preferance">
                <p style="margin-bottom:10px">
                    <input type="checkbox" value="" id="defaultCheck2" [ngModel]="currentUser.preferences.encapsulationRequested" (ngModelChange)="setPreferenceSelection('encapsulationRequested')">
                    <label for="defaultCheck2" style="margin-left: 6px;">Always Encapsulate Items if Altered</label>
                </p>
                <p style="margin-bottom:15px">
                    <input type="checkbox" value="" id="defaultCheck1" [ngModel]="currentUser.preferences.useFedexAccount" (ngModelChange)="useFedexAccount($event)">
                    <label for="defaultCheck1" style="margin-left: 6px;">Use My FedEx Account</label>
                </p>

                <ul *ngIf="currentUser.preferences.useFedexAccount">
                    <li style="font: size 16px;">
                        FedEx Account: <strong style="font-weight: 400;">{{getCurrentFedexAccountValue()}} </strong><a href="javascript:void(0)" (click)="setCurrentFedexAccount()"><img src="./assets/img/dashboard/edit.png" alt="" width="10" /></a>
                    </li>
                    <li style="font: size 16px;">
                        FedEx Account Billing Zip: <strong style="font-weight: 400;">{{getFedexAccountPostalCode()}} </strong><a href="javascript:void(0)" (click)="setFedexAccountPostalCode()"><img src="./assets/img/dashboard/edit.png" alt="" width="10" /></a>
                    </li>
                </ul>
                <button class="button mb-4" style="background: black;" (click)="saveMyPrefrences()">
                    <span _ngcontent-c4="">SAVE MY PREFERENCES</span>
                </button>
            </div>
        </div>
        <div class="row m-0 mt-4">
            <div class="col-12 mb-4"><h3>My Cards Preferences:</h3></div>
            <div class="col-12 col-lg-12 col-md-12 col-sm-12 preferance">

                <p style="margin-bottom:10px">
                    <input type="checkbox" value="" id="defaultCheck9" [ngModel]="currentUser.preferences.myCardsAddAfterShipped" (ngModelChange)="setPreferenceSelection('myCardsAddAfterShipped')">
                    <label for="defaultCheck9" style="margin-left: 6px;">After my orders have been marked shipped, I want all graded cards automatically added to My Cards.</label>
                </p>
                <button class="button mb-4" style="background: black;" (click)="saveMyPrefrences()">
                    <span _ngcontent-c4="">SAVE MY PREFERENCES</span>
                </button>
            </div>
        </div>
    </div>
    <span class="loader" *ngIf="currentUser != null && currentUser.saveInProgress != null && currentUser.saveInProgress"></span>

    <div class="user-links mt-4 p-4">
        <div class="row m-0 mt-4">
            <div class="col-12 mb-4"><h3>COMMUNICATION AND MARKETING PREFERENCES</h3></div>
            <div class="col-12 col-lg-12 col-md-12 col-sm-12 preferance">
                <p style="margin-bottom:15px">
                    <input type="checkbox" value="" id="defaultCheck6" [ngModel]="currentUser.preferences.mktgOptInSGCProductsPricing" (ngModelChange)="setPreferenceSelection('mktgOptInSGCProductsPricing')">
                    <label for="defaultCheck6"> I want to receive email notifications from SGC, which may include new website features, product information, and pricing changes.</label>
                </p>
                <p style="margin-bottom:15px">
                    <input type="checkbox" value="" id="defaultCheck7" [ngModel]="currentUser.preferences.mktgOptInSGCMarketing" (ngModelChange)="setPreferenceSelection('mktgOptInSGCMarketing')">
                    <label for="defaultCheck7"> I want to receive email notifications from SGC, which may include special offers, promotions, and SGC special events.</label>
                </p>
                <p style="margin-bottom:27px">
                    <input type="checkbox" value="" id="defaultCheck8" [ngModel]="currentUser.preferences.mktgOptIn3PartyMarketing" (ngModelChange)="setPreferenceSelection('mktgOptIn3PartyMarketing')">
                    <label for="defaultCheck8"> I want to receive email notifications from SGC, which may include partner or third-party advertiser special offers, promotions, and events related to trading cards.</label>
                </p>
                <button class="button mb-4" style="background: black;" (click)="saveMyPrefrences()">
                    <span _ngcontent-c4="">SAVE MY PREFERENCES</span>
                </button>
            </div>

            <p style="margin-left: 15px; margin-top: 8px; margin-bottom: 1rem;"><u>Communications Summary</u></p>
            <div class="col-12 col-lg-12 col-md-12 col-sm-12 preferance">
                <p style="margin-bottom:27px">
                    Per the Terms and Conditions you agree to when using the Service, you consent to receiving transactional communications (“emails”), including but
                    not limited to, new account confirmation and email verification, password reset, notification your order has been received and shipped back to you,
                    payment-related issues and responses to your customer service inquiries. SGC does not send non-transactional or marketing communications to
                    you without your consent. SGC does not sell your personal information to any third parties.

                </p>
                <p style="margin-bottom:20px">
                    To review the Terms and Conditions, <a routerLink="/terms">click here</a>.
                </p>
                <p>
                    To review the Privacy Policy,  <a routerLink="/privacy">click here</a>.
                </p>
            </div>
        </div>
    </div>


    

    <div class="user-links mt-4 p-4">
        <div class="row m-0 mt-4">
            <div class="col-12 mb-4"><h3>REQUESTING YOUR PERSONAL DATA AND USAGE</h3></div>
            <div class="col-12 col-lg-12 col-md-12 col-sm-12 preferance">
                <p style="margin-bottom:27px">
                    SGC adheres to personal privacy laws. Please read the <a routerLink="/privacy">Privacy Policy</a> to understand our process, your rights and how you can request your personal
                    data or make an informed decision to request your personal data and account deletion. For inquiries, go to our <a routerLink="/collector-support/contact-us">support page</a> and request your personal
                    information by selecting the option “Privacy Issues / Account Deletion” from the Type of Inquiry dropdown. You may also email <a href="mailto:privacy@gosgc.com">privacy&#64;gosgc.com</a> with
                    any issues. You will be asked to verify your account and identity.
                </p>
                <p style="margin-bottom:27px">
                    Per the Terms and Conditions you agreed by using our Service, you consent to sharing your personal information for transactions that you’ve requested with the following entities
                    of our choosing;
                </p>
                <ul style="margin-bottom:27px">
                    <li style="margin-bottom: 3px;margin-top:8px;font-size:1.8rem;line-height: 2.25rem;">
                        third-party payment processors for the purposes of processing payments and refunds
                    </li>
                    <li style="margin-bottom: 3px;margin-top:8px;font-size:1.8rem;line-height: 2.25rem;">
                        third-party shipping carriers and their respective software partners, you specified when you placed your order
                    </li>
                    <li style="margin-bottom: 3px;margin-top:8px;font-size:1.8rem;line-height: 2.25rem;">
                        any software or service licensed by SGC to process customer orders
                    </li>
                    <li style="margin-bottom: 3px;margin-top:8px;font-size:1.8rem;line-height: 2.25rem;">
                        any third-party that you have granted us permission to share your personal information
                    </li>
                </ul>
                <p style="margin-bottom:15px">
                    For inquiries, go to our <a routerLink="/collector-support/contact-us">support page</a> and select the option “Privacy Issues / Account Deletion” from the Type of Inquiry dropdown. You may also
                    email <a href="mailto:privacy@gosgc.com">privacy&#64;gosgc.com</a> with any issues or concerns about your personal information. You will be asked to verify your account and identity.
                </p>
            </div>
        </div>
    </div>

    <div class="user-links mt-4 p-4">
        <div class="row m-0 mt-4">
            <div class="col-12 mb-4" style="margin-top: 8px; margin-bottom: 5px;" *ngIf="!currentUser.preferences.custReqAcctDelete"><h3>REQUESTING PERSONAL DATA AND ACCOUNT DELETION</h3></div>
            <div class="col-12 col-lg-12 col-md-12 col-sm-12 preferance" *ngIf="!currentUser.preferences.custReqAcctDelete">
                <p style="margin-bottom:27px">
                    Should you wish to cancel your account and request deletion of your personal data, select the checkbox below and click the button to begin the process.
                    Please read the “Requesting Deletion of Your Personal Data or Account” of our <a routerLink="/privacy">Privacy Policy</a> to understand our process for deleting your account.
                </p>
                <p style="margin-bottom:27px">
                    For inquiries, go to our <a routerLink="/collector-support/contact-us">support page</a> and request your personal information by selecting the option “Privacy Issues / Account Deletion” from the Type
                    of Inquiry dropdown. You may also email <a href="mailto:privacy@gosgc.com">privacy&#64;gosgc.com</a> with any issues. You will be asked to verify your account and identity.
                </p>
                <p style="margin-bottom:27px">
                    To begin the account deletion process, check the box below and click the button.
                </p>

                <p style="margin-bottom:1rem">
                    <input type="checkbox" value="" id="defaultCheck9" [ngModel]="currentUser.preferences.custReqAcctDeletePrivacy" (ngModelChange)="useCustReqAcctDelete($event)">
                    <label for="defaultCheck9"> I have read the Privacy Policy and want SGC to begin the process to Delete my Personal Information and my SGC Account.</label>
                </p>

                <button class="button mt-4 mb-4" style="margin-left: 14px; background:black;" [disabled]="!currentUser.custReqAcctDeletePrivacy" [class.button--disabled]="!currentUser.custReqAcctDeletePrivacy" (click)="DeleteAccount('custReqAcctDelete')">
                    <span _ngcontent-c4="">REQUEST DELETION OF SGC ACCOUNT AND PERSONAL INFORMATION</span>
                </button>
            </div>

            <div class="col-12 mb-4" style="margin-top: 8px; margin-bottom: 5px;" *ngIf="currentUser.preferences.custReqAcctDelete"><h3>CANCEL REQUEST FOR PERSONAL DATA AND ACCOUNT DELETION</h3></div>

            <div class="col-12 col-lg-12 col-md-12 col-sm-12 preferance" *ngIf="currentUser.preferences.custReqAcctDelete">
                <p style="margin-bottom:1rem">
                    <b>
                        We have logged your request to delete your personal information and SGC account.
                    </b>
                </p>
                <p style="margin-bottom:27px">
                    For inquiries, go to our <a routerLink="/collector-support/contact-us">support page</a> and request your personal information by selecting the option “Privacy Issues / Account Deletion”
                    from the Type of Inquiry dropdown. You may also email <a href="mailto:privacy@gosgc.com">privacy&#64;gosgc.com</a> with any issues. You will be asked to verify your account and identity.
                </p>
                <p style="margin-bottom:27px">
                    If you want to keep your SGC account and cancel your request to delete your personal information and SGC account, click the button below.
                </p>
                <button class="button mt-4 mb-4" style="margin-left: 14px; background:black;" (click)="KeepAccount('custReqAcctDelete')">
                    <span _ngcontent-c4="">Keep MY Account</span>
                </button>
            </div>
        </div>
    </div>
</div>



