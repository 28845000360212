import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/';
// import 'rxjs/add/operator/map';
import {map} from 'rxjs'
import { environment } from '../../../environments/environment';

@Injectable()
export class ApiService {

    constructor(private http: HttpClient) { }

    baseUrl: string = environment.sgc.apiUrl;

    getOptions(options?: any) {

        var httpOptions = {
            headers : new HttpHeaders().set('Authorization', `Bearer ${localStorage.getItem('access_token')}`),
            params: new HttpParams()
        };

        if(options && options.params) {
            options.params.forEach((x:any) => httpOptions.params = httpOptions.params.set(x.key, x.value));
        }

        return httpOptions;
    }
   
    delete(resource: string): Observable<any> {
        return this.http.delete(`${this.baseUrl}${resource}`, this.getOptions());
    }
    deletee(resource: string, userId: string, options?: any): Observable<any> {
        
        let url = `${this.baseUrl}${resource}/${userId}`;
        return this.http.delete(url, this.getOptions(options));
      }
    get(resource: string, options?: any) : Observable<any> {
        return this.http.get(`${this.baseUrl}${resource}`, this.getOptions(options));
    }

    post(resource: string, request: any) : Observable<any> {
        console.log(`THE API IS =>${this.baseUrl}${resource}`, request, this.getOptions())
        return this.http.post(`${this.baseUrl}${resource}`, request, this.getOptions());
    }

    postWithoutAuth(resource: string, request: any): Observable<any> {
        return this.http.post(`${this.baseUrl}${resource}`, request);
    }

    put(resource: string, request: any): Observable<any> {
        return this.http.put(`${this.baseUrl}${resource}`, request, this.getOptions());
    }

   

    patch(resource: string, request: any): Observable<any> {
        return this.http.patch(`${this.baseUrl}${resource}`, request, this.getOptions());
    }
}
