import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { User, Address, UserOrderInfo } from '../../../common/models/user';
import { UserService } from '../../../common/services/user.service';
import { ModalService } from '../../../common/services/modal.service';
import { CheckoutService } from '../../../checkout/services/checkout.service';
import {
  GridComponent,
  FilterSettingsModel,
  IFilter,
  TextWrapSettingsModel,
  PageSettingsModel,
} from '@syncfusion/ej2-angular-grids';
import { DataUtil } from '@syncfusion/ej2-data';
import * as _ from 'lodash';

@Component({
  selector: 'account-profile',
  templateUrl: './account-profile.component.html',
  styleUrls: [
    '../need-help/need-help.component.css',
    '../../dashboard.component.css',
  '../my-profile/my-profile.component.css',
  ],
})
export class AccountProfileComponent implements OnInit {
  constructor(
    private router: Router,
    private userService: UserService,
    private modalService: ModalService,
    private checkoutService: CheckoutService
  ) {}

  currentUser = new User();
  userAddresses = new Array<Address>();
  userOrderInfo = new Array<UserOrderInfo>();
  address = new Address();
  CheckdeleteAdress: boolean = false;

  startNewSubmission() {
    this.router.navigate(['/checkout']);
  }

  removeAddress(address: Address) {
    this.checkoutService.addressRemove(address);
    this.modalService.open('app-delete-address', address);
    this.grid.refresh();
    //this.userService.removeAddress(address)
    //    .subscribe(() => {
    //    var index = this.userAddresses.findIndex(address => address.id == address.id);
    //    this.userAddresses.splice(index, 1);
    //    this.userService.remoeveUserAddresses$(this.userAddresses);
    //    this.userService.userAddresses$.subscribe(addresses => this.userAddresses = addresses);
    //    this.grid.refresh()
    //});
  }

  checkDeleted(): boolean {
    this.userService.CheckdeleteAdress.subscribe(
      (user) => (this.CheckdeleteAdress = user)
    );
    if (this.CheckdeleteAdress == true) {
      this.userService.userAddresses$.subscribe(
        (addresses) => (this.userAddresses = addresses)
      );
      this.userService.deleteAdress(false);
      this.grid.refresh();
    }
    return this.CheckdeleteAdress;
  }

  setDefault(address: Address) {
    //address.isDefault = true;
    this.userService.setDafaultAddress(address).subscribe((a) => {
      var a = a;
    });
  }

  getUserProfilePictureUrl(): string {
    return this.currentUser.profilePictureUrl
      ? this.currentUser.profilePictureUrl
      : '/assets/img/default-user.svg';
  }

  editAddress(address: Address) {
    this.checkoutService.newAddress(address);
    //this.openAddressEditor(address);
    this.openAddressAlert();
  }

  checkUserAdress(address: Address): any {
    for (let itm of this.userOrderInfo) {
        if (address.id == itm.shippingAddressId) {
            return true
        }
    }
}

  addAddress() {
    this.address = new Address({
      country: { code: 'US' },
      state: { code: '' },
    });
    this.checkoutService.newAddress(this.address);
    this.openAddAddressAlert();
  }

  openAddressEditor(address?: Address) {
    this.modalService.open('address-editor', address);
  }

  openAddressAlert() {
    this.modalService.open('app-alert-address');
    //this.checkoutService.cancelCheckout();
  }

  openAddAddressAlert() {
    this.modalService.open('app-alert-add-address');
    //this.checkoutService.cancelCheckout();
  }

  editCustomer() {
    localStorage.setItem('title', 'Customer Name');
    this.openProfileEditor('Set your name', 'name');
  }

  editEmail() {
    localStorage.setItem('title', 'Email');
    this.openProfileEditor('Set your email', 'email');
  }

  editPhone() {
    localStorage.setItem('title', 'Phone Number');
    this.openProfileEditor('Set your phone number', 'phoneNumber')
  }

  openProfileEditor(label: string, editing: string) {
    var options: any = {label: label};

    options.user = _.cloneDeep(this.currentUser);
    options.label = label;
    options.editing = editing;

    this.modalService.open('edit-profile', options);
  }

  useFedexAccount(useFedexAccount: boolean) {
    
    console.log(useFedexAccount)
    if (!useFedexAccount || this.currentUser.preferences.currentFedexAccount) {
        this.setPreferenceSelection('useFedexAccount');
        if(useFedexAccount===true){
            localStorage.setItem("useFed","true")
        }
        else{
            localStorage.setItem("useFed","false")
        }
        return;
    }
    this.currentUser.preferences.useFedexAccount = useFedexAccount;
    this.setCurrentFedexAccount();
}

  setPreferenceOptInSGCProductsPricing(MktgOptInSGCProductsPricing: boolean) {
    this.userService
      .setPreferenceOptInSGCProductsPricing(
        MktgOptInSGCProductsPricing,
        this.currentUser
      )
      .subscribe((result) => {});
  }

  setPreferenceOptInSGCMarketing(MktgOptInSGCMarketing: boolean) {
    this.userService
      .setPreferenceOptInSGCMarketing(MktgOptInSGCMarketing, this.currentUser)
      .subscribe((result) => {});
  }

  setPreferenceOptIn3PartyMarketing(MktgOptIn3PartyMarketing: boolean) {
    this.userService
      .setPreferenceOptIn3PartyMarketing(
        MktgOptIn3PartyMarketing,
        this.currentUser
      )
      .subscribe((result) => {});
  }

  useCustReqAcctDelete(CustReqAcctDelete: boolean) {
    this.currentUser.custReqAcctDeletePrivacy =
      !this.currentUser.custReqAcctDeletePrivacy;
  }

  getCurrentFedexAccountValue(): string {
    console.log(this.currentUser);
        return this.currentUser.preferences.currentFedexAccount ? this.currentUser.preferences.currentFedexAccount : 'Your Fedex account';
    }

  getPhoneNumberValue(): string {
    this.userService.userProfile$.subscribe(
      (user) => (this.currentUser = user)
    );
    return this.currentUser.phoneNumber
      ? this.currentUser.phoneNumber
      : 'Your phone number';
  }

  getEmailValue(): string {
    this.userService.userProfile$.subscribe(
      (user) => (this.currentUser = user)
    );
    return this.currentUser.email ? this.currentUser.email : 'Your Email';
  }

  getCustomerNameValue(): string {
    this.userService.userProfile$.subscribe(
      (user) => (this.currentUser = user)
    );
    return this.currentUser.name ? this.currentUser.name : 'Your Name';
  }

  setCurrentFedexAccount() {
    localStorage.setItem('title', 'FedEx Account');
    this.openProfileEditor('Set your FedEx account number', 'fedexAccount');
  }

  setPreferenceSelection(property: string) {
    this.currentUser.preferences[property] =
      !this.currentUser.preferences[property];
  }

  saveMyPrefrences() {
    this.currentUser.saveInProgress = true;
    this.updateUserProfile();
  }

  DeleteAccount(property: string) {
    this.currentUser.saveInProgress = true;
    this.currentUser.preferences[property] = true;
    this.updateUserProfile();
  }

  KeepAccount(property: string) {
    this.currentUser.saveInProgress = true;
    this.currentUser.custReqAcctDeletePrivacy = false;
    this.currentUser.preferences[property] = false;
    this.updateUserProfile();
  }

  updateUserProfile() {
    this.userService.updateProfile(this.currentUser).subscribe();
  }

  getImageAccountUrl() {
    const regex = /^(http|https|ftp):\/\//i;

    if (regex.test(this.currentUser.imageAccountUrl)) {
      return this.currentUser.imageAccountUrl;
    }

    return `//${this.currentUser.imageAccountUrl}`;
  }

  getFedexAccountPostalCode() {
    return this.currentUser.preferences.fedexAccountPostalCode;
  }

  setFedexAccountPostalCode() {
    localStorage.setItem('title', 'FedEx Account Billing Zip');
    this.openProfileEditor(
      'Set your FedEx Account Billing Zip ',
      'fedexAccountPostalCode'
    );
  }

  hasImageAccountUrl(): boolean {
    return this.currentUser.imageAccountUrl != null;
  }

  @ViewChild('grid')
  public grid!: GridComponent;
  public FilterOptions: FilterSettingsModel = {
    type: 'Menu',
  };
  public wrapSettings!: TextWrapSettingsModel;

  ngOnInit() {
    
    this.wrapSettings = { wrapMode: 'Both' };
    this.userService.getCurrentUserProfile().subscribe((user) => {
      //this.userService.userProfile$.subscribe(user => this.currentUser = user);
      this.userService.userAddresses$.subscribe(
        (addresses) => (this.userAddresses = addresses)
      );
      //this.userService.userOrderInfo$.subscribe(orderInfo => this.userOrderInfo = orderInfo);
      this.userOrderInfo = user.orders;
      this.userAddresses = user.addresses;
      this.currentUser = user;
      console.log(this.currentUser);
    });
  }
}
