//export const standardRawGrading: Object[] = [
//    {
//        "DECVALUE": "<$500",
//        "SpecialItemsNonGuarantee": "- ",
//        "ThirtyBusinessGuarantee": "$20",
//        "TwoBusinessNonGuarantee": "-"
//    },
//    {
//        "DECVALUE": "<$1,500",
//        "SpecialItemsNonGuarantee": "$50",
//        "ThirtyBusinessGuarantee": "$35",
//        "TwoBusinessNonGuarantee": "-"
//    },
//    {
//        "DECVALUE": "<$3,500",
//        "SpecialItemsNonGuarantee": "$85",
//        "ThirtyBusinessGuarantee": "$85",
//        "TwoBusinessNonGuarantee": "$100"
//    },
//    {
//        "DECVALUE": "<$7,500",
//        "SpecialItemsNonGuarantee": "$250",
//        "ThirtyBusinessGuarantee": "$250",
//        "TwoBusinessNonGuarantee": "$250"
//    },
//    {
//        "DECVALUE": "<$20,000",
//        "SpecialItemsNonGuarantee": "$500",
//        "ThirtyBusinessGuarantee": "$500",
//        "TwoBusinessNonGuarantee": "$500"
//    },
//    {
//        "DECVALUE": "<$50,000",
//        "SpecialItemsNonGuarantee": "$1,000",
//        "ThirtyBusinessGuarantee": "$1,000",
//        "TwoBusinessNonGuarantee": "$1,000"
//    },
//    {
//        "DECVALUE": "<$100,000",
//        "SpecialItemsNonGuarantee": "$2,000",
//        "ThirtyBusinessGuarantee": "$2,000",
//        "TwoBusinessNonGuarantee": "$2,000"
//    },
//    {
//        "DECVALUE": ">$100,000 **",
//        "SpecialItemsNonGuarantee": "$3,750",
//        "ThirtyBusinessGuarantee": "$3,750",
//        "TwoBusinessNonGuarantee": "$3,750"
//    }
//];
//export const OversizedstandardRawGrading: Object[] = [
//    {
//        "DECVALUE": "<$3,500",
//        "SpecialItemsNonGuarantee": "$125 ",
//        "ThirtyBusinessGuarantee": "$125",
//        "TwoBusinessNonGuarantee": "-"
//    },
//    {
//        "DECVALUE": "<$7,500",
//        "SpecialItemsNonGuarantee": "$250",
//        "ThirtyBusinessGuarantee": "$250",
//        "TwoBusinessNonGuarantee": "$250"
//    },
//    {
//        "DECVALUE": "<$20,000",
//        "SpecialItemsNonGuarantee": "$500",
//        "ThirtyBusinessGuarantee": "$500",
//        "TwoBusinessNonGuarantee": "$500"
//    },
//    {
//        "DECVALUE": "<$50,000",
//        "SpecialItemsNonGuarantee": "$1,000",
//        "ThirtyBusinessGuarantee": "$1,000",
//        "TwoBusinessNonGuarantee": "$1,000"
//    },
//    {
//        "DECVALUE": "<$100,000",
//        "SpecialItemsNonGuarantee": "$2,000",
//        "ThirtyBusinessGuarantee": "$2,000",
//        "TwoBusinessNonGuarantee": "$2,000"
//    },
//    {
//        "DECVALUE": ">$100,000 **",
//        "SpecialItemsNonGuarantee": "$3,750",
//        "ThirtyBusinessGuarantee": "$3,750",
//        "TwoBusinessNonGuarantee": "$3,750"
//    }
//];
//export const reholder: Object[] = [
//    {
//        "DECVALUE": "<$1,500",
//        "SpecialItemsNonGuarantee": "-",
//        "ThirtyBusinessGuarantee": "$20",
//        "TwoBusinessNonGuarantee": "-"
//    },
//    {
//        "DECVALUE": "<$7,500",
//        "SpecialItemsNonGuarantee": "$50",
//        "ThirtyBusinessGuarantee": "$50",
//        "TwoBusinessNonGuarantee": "-"
//    },
//    {
//        "DECVALUE": "<$20,000",
//        "SpecialItemsNonGuarantee": "$100",
//        "ThirtyBusinessGuarantee": "$85",
//        "TwoBusinessNonGuarantee": "$100"
//    },
//    {
//        "DECVALUE": "<$50,000",
//        "SpecialItemsNonGuarantee": "$200",
//        "ThirtyBusinessGuarantee": "$200",
//        "TwoBusinessNonGuarantee": "$200"
//    },
//    {
//        "DECVALUE": ">$50,000",
//        "SpecialItemsNonGuarantee": "$250",
//        "ThirtyBusinessGuarantee": "$250",
//        "TwoBusinessNonGuarantee": "$250"
//    }
//];
//export const Oversizedreholder: Object[] = [
//    {
//        "DECVALUE": "<$7,500",
//        "SpecialItemsNonGuarantee": "$75",
//        "ThirtyBusinessGuarantee": "$85",
//        "TwoBusinessNonGuarantee": "-"
//    },
//    {
//        "DECVALUE": "<$20,000",
//        "SpecialItemsNonGuarantee": "$100",
//        "ThirtyBusinessGuarantee": "$100",
//        "TwoBusinessNonGuarantee": "$100"
//    },
//    {
//        "DECVALUE": "<$50,000",
//        "SpecialItemsNonGuarantee": "$200",
//        "ThirtyBusinessGuarantee": "$200",
//        "TwoBusinessNonGuarantee": "$200"
//    },
//    {
//        "DECVALUE": ">$50,000",
//        "SpecialItemsNonGuarantee": "$250",
//        "ThirtyBusinessGuarantee": "$250",
//        "TwoBusinessNonGuarantee": "$250"
//    }
//];


export const SSI_GROUND_D: Object[] = [
    {
        "ItemsCount": "1-5",
        "FedExGroundUSPS": "$25",
        "USPSPriority": "$15"
    },
    {
        "ItemsCount": "6-20",
        "FedExGroundUSPS": "$30",
        "USPSPriority": "$25"
    },
    {
        "ItemsCount": "21-30",
        "FedExGroundUSPS": "$35 (a)",
        "USPSPriority": "$30 (a)"
    }
];
export const SSI_FEDEXOVERNIGHT_D: Object[] = [
    {
        "ItemsCount": "1-11",
        "Two_Day": "$45",
        "STD_Overnight": "$60"
    },
    {
        "ItemsCount": "12-25",
        "Two_Day": "$60",
        "STD_Overnight": "$80"
    },
    {
        "ItemsCount": "26-50",
        "Two_Day": "$75",
        "STD_Overnight": "$100"
    },
    {
        "ItemsCount": "51-75",
        "Two_Day": "$90",
        "STD_Overnight": "(a)"
    },
    {
        "ItemsCount": "76-100 (b)",
        "Two_Day": "$110 (b)",
        "STD_Overnight": "(a)"
    }
];
export const OVERSIZEDTALL_BOY_ITEMS_GROUND_D: Object[] = [
    {
        "ItemsCount": "1-5",
        "FedExGround": "$30",
        "USPSPriority": "$30"
    },
    {
        "ItemsCount": "6-15",
        "FedExGround": "$40",
        "USPSPriority": "-"
    },
    {
        "ItemsCount": "6-20",
        "FedExGround": "-",
        "USPSPriority": "$40"
    },
    {
        "ItemsCount": "16-20",
        "FedExGround": "$50 (a)",
        "USPSPriority": "-"
    },
    {
        "ItemsCount": "21-30",
        "FedExGround": "-",
        "USPSPriority": "$50 (b)"
    }
];
export const SSI_FEDEX_INTERNATIONAL: Object[] = [
    {
        "ItemsCount": "1-5",
        "Ground": "$45",
        "EconomyAir": "$60",
        "PriorityAir": "$80"
    },
    {
        "ItemsCount": "6-10",
        "Ground": "$50",
        "EconomyAir": "$60",
        "PriorityAir": "$80"
    },
    {
        "ItemsCount": "11-25",
        "Ground": "$60",
        "EconomyAir": "$80",
        "PriorityAir": "$110"
    },
    {
        "ItemsCount": "26-50",
        "Ground": "$80",
        "EconomyAir": "$120 (c)",
        "PriorityAir": "$150 (d)"
    },
    {
        "ItemsCount": "51-100",
        "Ground": "$150 (b)",
        "EconomyAir": "-",
        "PriorityAir": "-"
    }
];

export const BowmanGrading: Object[] = [
    {
        "DECVALUE": "<$10,000,000",
        "5": "$9",
    }
];
export const standardRawGrading: Object[] = [
    {
        "DECVALUE": "<$1,500",
        "TotalCards": "1-9",
        /*"5": "$30",*/
        "5": "$15",
        //"10": "$40",
        //"5": "$75",
        "2": "$40",
    },
    //{
    //    "DECVALUE": "",
    //    "TotalCards": "10-19",
    //    /*"5": "$30",*/
    //    "5": "$22",
    //    //"10": "$40",
    //    //"5": "$75",
    //    "2": "$125",
    //},
    //{
    //    "DECVALUE": "",
    //    "TotalCards": "20-49",
    //    /*"5": "$30",*/
    //    "5": "$20",
    //    //"10": "$40",
    //    //"5": "$75",
    //    "2": "$125",
    //},
    //{
    //    "DECVALUE": "",
    //    "TotalCards": "50+",
    //    /*"5": "$30",*/
    //    "5": "$18",
    //    //"10": "$40",
    //    //"5": "$75",
    //    "2": "$125",
    //},
    {
        "DECVALUE": "<$3,500",
        "TotalCards": "Any",
       /* "5": "$85",*/
        "5": "$85",
        //"10": "-",
        //"5": "$85",
        "2": "$125",
    },
    {
        "DECVALUE": "<$7,500",
        "TotalCards": "Any",
        /*"5": "$250",*/
        "5": "-",
        //"10": "-",
        //"5": "-",
        "2": "$250",
    },
    {
        "DECVALUE": "<$20,000",
        "TotalCards": "Any",
       /* "5": "$500",*/
        "5": "-",
        //"10": "-",
        //"5": "-",
        "2": "$500",
    },
    {
        "DECVALUE": "<$50,000",
        "TotalCards": "Any",
        /*"5": "$1,000",*/
        "5": "-",
        //"10": "-",
        //"5": "-",
        "2": "$1,000",
    },
    {
        "DECVALUE": "<$100,000",
        "TotalCards": "Any",
        /*"5": "$2,000",*/
        "5": "-",
        //"10": "-",
        //"5": "-",
        "2": "$2,000",
    },
    {
        "DECVALUE": ">$100,000 **",
        "TotalCards": "Any",
        /*"5": "$3,750",*/
        "5": "-",
        //"10": "-",
        //"5": "-",
        "2": "$3,750",
    }
];
export const preCardGrading: Object[] = [
    {
        "DECVALUE": "<$1,500",
        /*"5": "$30",*/
        "25": "$18",
        //"10": "$40",
        //"5": "$75",
        "2": "$75",
    },
    {
        "DECVALUE": "<$3,500",
        /* "5": "$85",*/
        "25": "$51",
        //"10": "-",
        //"5": "$85",
        "2": "$75",
    },
    {
        "DECVALUE": "<$7,500",
        /*"5": "$250",*/
        "25": "-",
        //"10": "-",
        //"5": "-",
        "2": "$150",
    },
    {
        "DECVALUE": "<$20,000",
        /* "5": "$500",*/
        "25": "-",
        //"10": "-",
        //"5": "-",
        "2": "$300",
    },
    {
        "DECVALUE": "<$50,000",
        /*"5": "$1,000",*/
        "25": "-",
        //"10": "-",
        //"5": "-",
        "2": "$600",
    },
    {
        "DECVALUE": "<$100,000",
        /*"5": "$2,000",*/
        "25": "-",
        //"10": "-",
        //"5": "-",
        "2": "$1,200",
    },
    {
        "DECVALUE": ">$100,000 **",
        /*"5": "$3,750",*/
        "25": "-",
        //"10": "-",
        //"5": "-",
        "2": "$2,250",
    }
];
export const OversizedstandardRawGrading: Object[] = [
    {
        "DECVALUE": "<$1,500",
        "5": "$50",
    },
    {
        "DECVALUE": "<$3,500",
        "5": "$85",
    },
    {
        "DECVALUE": "<$7,500",
        /*"5": "$250",*/
        "5": "$250",
        //"10": "-",
        //"5": "$250",
        //"2": "-",
    },
    {
        "DECVALUE": "<$20,000",
        /*"5": "$500",*/
        "5": "$500",
        //"10": "-",
        //"5": "$500",
        //"2": "-",
    },
    {
        "DECVALUE": "<$50,000",
        /*"5": "$1,000",*/
        "5": "$1,000",
        //"10": "-",
        //"5": "$1,000",
        //"2": "-",
    },
    {
        "DECVALUE": "<$100,000",
        /*"5": "$2,000",*/
        "5": "$2,000",
        //"10": "-",
        //"5": "$2,000",
        //"2": "-",
    },
    {
        "DECVALUE": ">$100,000 **",
        /*"5": "$3,750",*/
        "5": "$3,750",
        //"10": "-",
        //"5": "$3,750",
        //"2": "-",
    }
];
export const reholder: Object[] = [
    {
        "DECVALUE": "<$3,500",
        "TotalCards": "1-49",
        /*"5": "$30",*/
        "5": "$15",
        //"10": "-",
        //"5": "$75",
        "2": "$40",
    },
    //{
    //    "DECVALUE": "",
    //    "TotalCards": "50+",
    //    /*"5": "$30",*/
    //    "5": "$17",
    //    //"10": "-",
    //    //"5": "$75",
    //    "2": "$125",
    //},
    {
        "DECVALUE": "<$7,500",
        "TotalCards": "Any",
        /*"5": "$30",*/
        "5": "$75",
        //"10": "-",
        //"5": "$75",
        "2": "$125",
    },
    {
        "DECVALUE": ">$7,500",
        "TotalCards": "Any",
        /*"5": "$125",*/
        "5": "-",
        //"10": "-",
        //"5": "-",
        "2": "$125",
    }
];
export const Oversizedreholder: Object[] = [
    {
        "DECVALUE": "<$1,500",
        "5": "$50",
    },
    {
        "DECVALUE": "<$3,500",
        "5": "$85",
    },
    {
        "DECVALUE": ">$3,500",
       /* "5": "$250",*/
        "5": "$250",
        //"10": "-",
        //"5": "$250",
        //"2": "-",
    }
];

export const RawCardReview: Object[] = [
    {
        "DECVALUE": "<$2,500",
        "BusinessDays": "End of Show",
        "ServiceFee": "$25"
    }
];


export const nsccOnSiteStandard: Object[] = [
    {
        "DECVALUE": "<$1,500",
        "EndOfShow": "$35",
        "SameDay": "$135",
    },
    {
        "DECVALUE": "<$3,500",
        "EndOfShow": "$85",
        "SameDay": "$185",
    },
    {
        "DECVALUE": "<$7,500",
        "EndOfShow": "$250",
        "SameDay": "$350",
    },
    {
        "DECVALUE": "<$20,000",
        "EndOfShow": "$500",
        "SameDay": "$600",
    },
    {
        "DECVALUE": "<$50,000",
        "EndOfShow": "$1,000",
        "SameDay": "$1,100",
    },
    {
        "DECVALUE": "<$100,000",
        "EndOfShow": "$2,000",
        "SameDay": "$2,100",
    },
    {
        "DECVALUE": ">$100,000 **",
        "EndOfShow": "$3,750",
        "SameDay": "$3,750",
    }
];
export const nsccOnSiteReholder: Object[] = [
    {
        "DECVALUE": "<$3,500",
        "EndOfShow": "$25",
        "SameDay": "$125",
    },
    {
        "DECVALUE": "<$7,500",
        "EndOfShow": "$75",
        "SameDay": "$175",
    },
    {
        "DECVALUE": "<$20,000",
        "EndOfShow": "$125",
        "SameDay": "$225",
    },
    {
        "DECVALUE": ">$20,000",
        "EndOfShow": "$250",
        "SameDay": "$350",
    }
];
export const nsccOnSiteStandardOverSize: Object[] = [
    {
        "DECVALUE": "<$3,500",
        "EndOfShow": "$100",
        "SameDay": "$200",
    },
    {
        "DECVALUE": "<$7,500",
        "EndOfShow": "$250",
        "SameDay": "$350",
    },
    {
        "DECVALUE": "<$20,000",
        "EndOfShow": "$500",
        "SameDay": "$600",
    },
    {
        "DECVALUE": "<$50,000",
        "EndOfShow": "$1,000",
        "SameDay": "$1,100",
    },
    {
        "DECVALUE": "<$100,000",
        "EndOfShow": "$2,000",
        "SameDay": "$2,100",
    },
    {
        "DECVALUE": ">$100,000 **",
        "EndOfShow": "$3,750",
        "SameDay": "$3,750",
    }
];
export const nsccOnSiteReholderOverSize: Object[] = [
    {
        "DECVALUE": "<$3,500",
        "EndOfShow": "$100",
        "SameDay": "$200",
    },
    {
        "DECVALUE": ">$3,500",
        "EndOfShow": "$250",
        "SameDay": "$350",
    }
];

export const nsccOnTakeBackStandard: Object[] = [
    {
        "DECVALUE": "<$1,500",
        "10": "$15",
    },
    {
        "DECVALUE": "<$3,500",
        "10": "$85",
    },
    {
        "DECVALUE": "<$7,500",
        "10": "$250",
    },
    {
        "DECVALUE": "<$20,000",
        "10": "$500",
    },
    {
        "DECVALUE": "<$50,000",
        "10": "$1,000",
    },
    {
        "DECVALUE": "<$100,000",
        "10": "$2,000",
    },
    {
        "DECVALUE": ">$100,000 **",
        "10": "$3,750",
    }
];
export const nsccOnTakeBackReholder: Object[] = [
    {
        "DECVALUE": "<$3,500",
        "10": "$15",
    },
    {
        "DECVALUE": "<$7,500",
        "10": "$75",
    },
    {
        "DECVALUE": "<$20,000",
        "10": "$125",
    },
    {
        "DECVALUE": ">$20,000",
        "10": "$250",
    }
];
export const nsccOnTakeBackStandardOverSize: Object[] = [
    {
        "DECVALUE": "<$1,500",
        "10": "$50",
    },
    {
        "DECVALUE": "<$3,500",
        "10": "$85",
    },
    {
        "DECVALUE": "<$7,500",
        "10": "$250",
    },
    {
        "DECVALUE": "<$20,000",
        "10": "$500",
    },
    {
        "DECVALUE": "<$50,000",
        "10": "$1,000",
    },
    {
        "DECVALUE": "<$100,000",
        "10": "$2,000",
    },
    {
        "DECVALUE": ">$100,000 **",
        "10": "$3,750",
    }
];
export const nsccOnTakeBackReholderOverSize: Object[] = [
    {
        "DECVALUE": "<$1,500",
        "10": "$50",
    },
    {
        "DECVALUE": "<$3,500",
        "10": "$85",
    },
    {
        "DECVALUE": ">$3,500",
        "10": "$250",
    }
];

