import { Component, OnInit } from '@angular/core';
import { StateService } from '../../../common/services/state.service';
import { User } from '../../../common/models/user';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';
import { OrderService } from '../../services/order.service';
import { AuthCodeCollections } from '../../models/order';
//import { Console } from '@angular/animations/src/console';

@Component({
  selector: 'app-collection-list',
  templateUrl: './collection-list.component.html',
  styleUrls: [
    './collection-list.component.css',
    '../../../common/layout/left-nav-layout/left-nav-layout.component.sass',
    '../../../checkout/checkout.component.sass',
  ],
})
export class CollectionListComponent implements OnInit {
  currentUser = new User();
  public UserCollections?: AuthCodeCollections[];
  certificationCodePattern = '^-?[0-9]\\d*(\\-\\d{3})?$';
  form?: FormGroup;
  Searchform?: FormGroup;
  isRefreshing = false;
  isSearching = false;
  authCode: any;
  authCodeSearch: any;
  oldCollectionlength: any;
  newCollectionlength: any;
  duplicatemessage = false;
  submitmsg = false;
  searchmsg = false;
  curPage: number;
  pageSize: number;
  showRecord = false;
  emptySearchmsg = false;
  SeeSearch = false;

  constructor(
    private formBuilder: FormBuilder,
    private stateService: StateService,
    private OrderService: OrderService
  ) {
    this.curPage = 1;
    this.pageSize = 50;
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      authcode: new FormControl(
        '',
        Validators.compose([
          Validators.minLength(7),
          Validators.maxLength(11),
          Validators.required,
          Validators.pattern(this.certificationCodePattern),
        ])
      ),
    });
    this.Searchform = this.formBuilder.group({
      searchauthcode: new FormControl(
        '',
        Validators.compose([
          Validators.minLength(7),
          Validators.maxLength(11),
          Validators.required,
          Validators.pattern(this.certificationCodePattern),
        ])
      ),
    });

    this.currentUser = this.stateService.currentUser.get();
    this.authCode = this.form.controls['authcode'].value;
    this.OrderService.GetCertCode(this.authCode, this.currentUser.id).subscribe(
      (response) => {
        if (response != null) {
          this.UserCollections = response;
          this.oldCollectionlength = this.UserCollections.length;
          if (this.UserCollections.length >= 1) {
            this.showRecord = true;
          }
        }
        this.isRefreshing = false;
      }
    );
  }

  submitAuthCode() {
    if (this.form?.invalid) {
      return;
    }
    this.submitmsg = false;
    this.searchmsg = false;
    this.isRefreshing = true;
    this.emptySearchmsg = false;

    this.authCode = this.form?.controls['authcode'].value;
    this.OrderService.submitCertCode(
      this.authCode,
      this.currentUser.id
    ).subscribe((response) => {
      if (response != null) {
        this.UserCollections = response;
        this.newCollectionlength = this.UserCollections.length;
        if (this.UserCollections.length >= 1) {
          this.showRecord = true;
        }
      }
      this.isRefreshing = false;
      if (this.newCollectionlength == this.oldCollectionlength) {
        this.duplicatemessage = true;
        this.oldCollectionlength = this.newCollectionlength;
      } else {
        this.form?.controls['authcode'].setValue('');
        this.duplicatemessage = false;
        this.oldCollectionlength = this.newCollectionlength;
        this.submitmsg = true;
      }
    });
  }

  searchAuthCode() {
    if (this.Searchform?.invalid) {
      return;
    }
    this.isSearching = true;
    this.submitmsg = false;
    this.searchmsg = false;
    this.duplicatemessage = false;
    this.emptySearchmsg = false;

    this.authCodeSearch = this.Searchform?.controls['searchauthcode'].value;
    this.OrderService.GetCertCode(
      this.authCodeSearch,
      this.currentUser.id
    ).subscribe((response) => {
      if (response != null) {
        this.UserCollections = response;
        if (this.UserCollections.length >= 1) {
          this.showRecord = true;
          this.searchmsg = true;
          this.Searchform?.controls['searchauthcode'].setValue('');
        } else {
          this.emptySearchmsg = true;
        }
      }
      this.isSearching = false;
    });
  }

  SearchAuthCodeCollection() {
    this.submitmsg = false;
    this.searchmsg = false;
    this.duplicatemessage = false;
    this.emptySearchmsg = false;

    this.SeeSearch = true;
    this.OrderService.GetCertCode('', this.currentUser.id).subscribe(
      (response) => {
        if (response != null) {
          this.UserCollections = response;
          if (this.UserCollections.length >= 1) {
            this.showRecord = true;
          }
        }
        this.SeeSearch = false;
      }
    );
  }

  numberOfPages() {
    return Math.ceil(this.UserCollections!.length / this.pageSize);
  }
}
