<div
  class="checkout__steps"
  style="align-items: center; flex-direction: column"
>
  <h3
    class="checkout-top-nav-title"
    style="
      display: flex;
      flex-direction: row;
      color: white;
      justify-content: center;
      font-size: 30px;
      padding-right: 25px;
    "
  >
    Order {{ getOrderNumber() }}
  </h3>
  <div
    *ngFor="let step of steps"
    style="color: white; font-family: 'd37'; font-size: 1.6em"
  >
    <div
      class="checkout_Stepname"
      style="
        display: flex;
        flex-direction: column;
        text-align: center;
        font-weight: bold;
      "
    >
      {{ step.name }}
      <span
        *ngIf="!isStep8()"
        class="checkout_StepNo"
        [class.checkout_StepNo_Active]="isCurrentStep(step.stepNumber)"
        [class.checkout_StepNo_Completed]="hasStepPassed(step.stepNumber)"
      ></span>
      <span
        *ngIf="isStep8()"
        class="checkout_StepNo8"
        [class.checkout_StepNo8_Active]="isCurrentStep(step.stepNumber)"
        [class.checkout_StepNo8_Completed]="hasStepPassed(step.stepNumber)"
      ></span>
    </div>
    <!-- <div  style="flex-direction: column;">
       </div> -->
  </div>
</div>
<div
  class="checkout__actions"
  *ngIf="!isCheckoutFinished()"
  style="padding-right: 5%"
>
  <button
    class="button button--inverted--cancel"
    *ngIf="!isStep8()"
    (click)="cancelOrder()"
  >
    CANCEL ORDER
  </button>
  <button
    class="button button--inverted--save"
    *ngIf="!isStep8()"
    (click)="saveForLater()"
  >
    Save for later
  </button>
</div>
<style type="text/css">
  .checkout-top-nav-title {
        margin-bottom: -8px !important;
    }
  @media only screen and (max-width: 700px) {
    .button--cancel {
      display: none;
    }
    .checkout_Stepname {
      font-size: 0px;
    }
    .checkout__steps {
      /* gap: 4px; */
      margin-right: 11px;
    }
    .checkout_StepNo {
      padding: 5px 5px !important;
      border-radius: 10px !important;
      border: 1px solid #fff !important;
    }
    .checkout_StepNo_Active {
      padding: 5px 5px !important;
      border-radius: 10px !important;
      border: 1px solid #fff !important;
      background-color: rgb(108, 108, 108) !important;
    }
    .checkout_StepNo_Completed {
      padding: 5px 5px !important;
      border-radius: 10px !important;
      border: 1px solid #fff !important;
      background-color: rgb(250, 250, 250) !important;
    }
    .checkout_StepNo8 {
      padding: 5px 5px !important;
      border-radius: 10px !important;
      border: 1px solid #fff !important;
    }
    .checkout_StepNo8_Active {
      padding: 5px 5px !important;
      border-radius: 10px !important;
      border: 1px solid #fff !important;
      background-color: rgb(250, 250, 250) !important;
    }
    .checkout_StepNo8_Completed {
      padding: 5px 5px !important;
      border-radius: 10px !important;
      border: 1px solid #fff !important;
      background-color: rgb(250, 250, 250) !important;
    }
    .checkout-top-nav-title {
      font-size: 17px !important ;
      margin-left: -3px !important;
      margin-right: -15px !important;
      margin-block: -7px !important;
    }
    /* .button--inverted{
            padding-top: 20px;
            padding-bottom: 20px;
        } */
    .checkout__actions {
      gap: 2px !important;
    }
    .button--inverted--cancel {
      font-size: 10px;
      width: 61px;
      padding: 0px;
    }
    .button--inverted--save {
      font-size: 10px;
      width: 65px;
      padding-inline: 0px;
    }
  }
  /* @media only screen and (min-width : 1024px) {
            .checkout__steps{ */
  /* display: none; */
  /* flex-direction: row;
            }
        } */
  /* @media only screen and (max-width : 990px) {
        .checkout__steps {
            display: inline-block;
        }
    } */
  .button--cancel {
    background-color: #ae0701;
    color: white;
    border: 1px solid white;
    /* margin-top: 7px; */
    font-weight: 100;
    margin-left: 2px;
  }
  .button--inverted--cancel {
    background-color: transparent;
    color: white;
    border: 1px solid white;
    /* margin-top: 7px; */
    font-weight: 100;
    margin-left: 5px;
    margin-right: 4px;
  }
  .button--inverted--save {
    background-color: transparent;
    color: white;
    border: 1px solid white;
    /* margin-top: 7px; */
    font-weight: 100;
    margin-left: 5px;
    margin-right: 4px;
  }
  .button--inverted {
    background-color: transparent;
    color: white;
    border: 1px solid white;
    /* margin-top: 7px; */
    font-weight: 100;
    margin-left: 5px;
    margin-right: 4px;
  }
  .checkout_StepNo {
    padding: 2px 32px;
    border: 1px solid #fff;
  }
  .checkout_StepNo_Active {
    padding: 2px 32px;
    border: 1px solid #fff;
    background-color: rgb(108, 108, 108);
  }
  .checkout_StepNo_Completed {
    padding: 2px 32px;
    border: 1px solid #fff;
    background-color: rgb(250, 250, 250);
  }
  .checkout_StepNo8 {
    padding: 2px 32px;
    border: 1px solid #fff;
  }
  .checkout_StepNo8_Active {
    padding: 2px 32px;
    border: 1px solid #fff;
    background-color: rgb(250, 250, 250);
  }
  .checkout_StepNo8_Completed {
    padding: 2px 32px;
    border: 1px solid #fff;
    background-color: rgb(250, 250, 250);
  }

  @media only screen and (max-height: 420px) {
    .button--cancel {
      display: none;
    }
    .checkout_Stepname {
      font-size: 0px;
    }
    .checkout__steps {
      gap: 10px;
      margin-left: 10px;
    }
    .checkout_StepNo {
      padding: 5px 5px !important;
      border-radius: 10px !important;
      border: 1px solid #fff !important;
    }
    .checkout_StepNo_Active {
      padding: 5px 5px !important;
      border-radius: 10px !important;
      border: 1px solid #fff !important;
      background-color: rgb(108, 108, 108) !important;
    }
    .checkout_StepNo_Completed {
      padding: 5px 5px !important;
      border-radius: 10px !important;
      border: 1px solid #fff !important;
      background-color: rgb(250, 250, 250) !important;
    }
    .checkout-top-nav-title {
      font-size: 17px !important ;
      margin-right: 150px !important;
    }
    .checkout__actions {
      gap: 2px !important;
    }
    .button--inverted--cancel {
      font-size: 10px;
      width: 61px;
      padding: 0px;
    }
    .button--inverted--save {
      font-size: 10px;
      width: 65px;
      padding-inline: 0px;
    }
    .checkout_StepNo8 {
      padding: 5px 5px !important;
      border-radius: 10px !important;
      border: 1px solid #fff !important;
    }
    .checkout_StepNo8_Active {
      padding: 5px 5px !important;
      border-radius: 10px !important;
      border: 1px solid #fff !important;
      background-color: rgb(250, 250, 250) !important;
    }
    .checkout_StepNo8_Completed {
      padding: 5px 5px !important;
      border-radius: 10px !important;
      border: 1px solid #fff !important;
      background-color: rgb(250, 250, 250) !important;
    }
  }
</style>
