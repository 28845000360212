export class ModalEvent {
    constructor(type?: string, event?: string, action?: string, options?: any) {
      this.type = type || '';
      this.event = event || '';
      this.action = action || '';
      this.options = options || {};
    }
  
    type: string;
    event: string;
    action: string;
    options: any = {};
  
    isOpen(): boolean {
      return this.event === 'open';
    }
  
    isClose(): boolean {
      return this.event === 'close';
    }
  
    isActionOk(): boolean {
      return this.action === 'ok';
    }
  
    isActionCancel(): boolean {
      return this.action === 'cancel';
    }
  }
  