import { Component, OnInit } from '@angular/core';

import {
  ModalService,
  ModalInterface,
} from '../../../../../common/services/modal.service';
import { OrderService } from '../../../../../orders/services/order.service';
import { SignableObject } from '../../../../../orders/models/order';
// import { OrderItemConfigurationService } from '../../../../services/order-item-configuration.service';
import { OrderItemConfigurationService } from 'src/app/checkout/services/order-item-configuration.services';
@Component({
  // moduleId: module.id,
  selector: 'select-autograph-item',
  templateUrl: './select-autograph-item.component.html',
  styleUrls: ['../../../../checkout.component.sass'],
})
export class SelectAutographItemComponent implements ModalInterface, OnInit {
  constructor(
    private modalService: ModalService,
    private orderService: OrderService,
    private orderItemConfigurationService: OrderItemConfigurationService
  ) {}

  title = 'Autograph Item';
  subtitle = 'Please specify which type of item contains your signature/s';
  type = 'select-autograph-item';
  options: any;
  objects!: SignableObject[];

  ngOnInit() {
    this.orderService
      .getSignableObjects()
      .subscribe((objects) => (this.objects = objects));
  }
}
