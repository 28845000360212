import { Component, Input } from '@angular/core';
import { AutographOrderItem } from '../../../orders/models/order';

@Component({
  selector: 'autograph-item-list',
  templateUrl: './autograph-item-list.component.html',
  styleUrls: ['../../../checkout/checkout.component.sass'],
})
export class AutographItemListComponent {
  @Input() items2 = new Array<AutographOrderItem>();

  getCardName(item: any): string {
    return item.card ? item.card.name : '--';
  }
}
