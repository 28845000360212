import { Component, OnInit } from '@angular/core';
import { CheckoutService } from '../../services/checkout.service';
import { Order } from '../../../orders/models/order';
import { Router } from '@angular/router';

@Component({
  selector: 'order-placed',
  templateUrl: './order-placed.component.html',
  styleUrls: ['../../checkout.component.sass']
})
export class OrderPlacedComponent implements OnInit {

    constructor(
        private checkoutService: CheckoutService,
        private router: Router
    ){}

    order = new Order();
    orderTypeCode: any;

    getShippingMethod(): string {
        return this.order.getShippingMethodName();
    }

    goToInvoice() {
        var orderId = this.order.getId();
        this.router.navigate([`/orders/${orderId}/invoice`]);
    }

    getOrderPublicId() {
        var publicId = this.order.getNumber();
        if (publicId == 0 && this.checkoutService.summaryOrder != null) {
            publicId = this.checkoutService.summaryOrder.publicId;
        }
        return publicId;
    }

    ngOnInit() {
        // localStorage.removeItem("fedAcc")
        this.checkoutService.currentOrder$.subscribe(order => this.order = order);
        this.orderTypeCode = this.checkoutService.getOrderTypeCode();
    }
}
