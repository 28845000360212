import { Pipe, PipeTransform } from '@angular/core';
import { Address } from '../models/user';


@Pipe({
    name: 'streetFormat'
})
export class StreetFormatPipe implements PipeTransform {
    transform(address: Address): string {
        let output: string = null!;

        if (!address) return null!;

        output = address.street1;
        
        return address.street2 ? output += ', ' + address.street2 : output;
    }
}
