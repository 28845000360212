import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'stickerPlacementFormat'
})
export class StickerPlacementFormatPipe implements PipeTransform {
    transform(item: any): string {

        if (!item) return null!;
        
        return item.settings.stickerOnItem ? 'On Item' : 'On Letter';
    }
}