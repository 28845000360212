import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sgc-terms',
  templateUrl: './sgc-terms.component.html',
  styleUrls: ['./sgc-terms.component.css']
})
export class SgcTermsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
