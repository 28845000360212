<!--<ejs-sidebar id="default-sidebar" #sidebar [type]='type' [enablePersistence]='enablePersistence' (created)="onCreated($event)" style="visibility: hidden" [target]='target'>
    <br />
    <br />

    <div class="title" style="font-size:16px;">Show This Grade or Better</div>
    <select class="new-header-row" [formControl]="SelectGrades" (change)="changeGrades($event.target.value)" style="margin-left:16px; ">
        <option value='All'>All</option>
        <option value='10 Pristine only'>10 Pristine only</option>
        <option value='10 Gem or 10 Pristine'>10 Gem or 10 Pristine</option>
        <option value='9.5 MT+ or better'>9.5 MT+ or better</option>
        <option value='9 MT or better'>9 MT or better</option>
        <option value='8.5 NM MT+ or better'>8.5 NM MT+ or better</option>
        <option value='8 NM MT or better'>8 NM MT or better</option>
        <option value='7.5 NM+ or better'>7.5 NM+ or better</option>
        <option value='7 NM or better'>7 NM or better</option>
        <option value='6.5 EX NM+ or better'>6.5 EX NM+ or better</option>
        <option value='6 EX NM or better'>6 EX NM or better</option>
        <option value='5.5 EX+ or better'>5.5 EX+ or better</option>
        <option value='5 EX or better'>5 EX or better</option>
        <option value='4.5 VG EX+ or better'>4.5 VG EX+ or better</option>
        <option value='4 VG EX or better'>4 VG EX or better</option>
        <option value='3.5 VG+ or better'>3.5 VG+ or better</option>
        <option value='3 VG or better'>3 VG or better</option>
        <option value='2.5 Good+ or better'>2.5 Good+ or better</option>
        <option value='2 Good or better'>2 Good or better</option>
        <option value='1.5 Fair or better'>1.5 Fair or better</option>
        <option value='1 Poor or better'>1 Poor or better</option>
        <option value='A'>A</option>
    </select>
    <hr />
    <div class="title" style="font-size:16px;">Column Grading Order</div>
    <div class="sub-title">
        <ejs-radiobutton label="A-10" name="1" value="A" [formControl]="SelectGradingOrder"></ejs-radiobutton>
        <ejs-radiobutton label="10-A" name="1" value="10" [formControl]="SelectGradingOrder" style="margin-left: 20px;"></ejs-radiobutton>

    </div>
    <hr />
    <div class="title" style="font-size:16px;">Show Old SGC Grading</div>
    <div class="sub-title">
        <ejs-radiobutton label="No" name="2" value="No" [formControl]="oldSgcGrades"></ejs-radiobutton>
        <ejs-radiobutton label="Yes" name="2" value="Yes" [formControl]="oldSgcGrades" style="margin-left: 20px;"></ejs-radiobutton>
    </div>

    <hr />
    <div class="center-align">
        <button ej-button id="close" class="e-btn close-btn" (click)="refreshgrid()">Refresh</button>
    </div>
</ejs-sidebar>
<div id="head">
    <button ejs-button id="toggle" #togglebtn cssClass="e-flat" iconCss="e-icons burg-icon" isToggle="true"
            (click)="btnClick()" content="View POP Options" style="padding-top: 10px;padding-right: 10px;width:unset;cursor:pointer;font-family: 'd77';font-weight: normal;text-transform: uppercase;color: #785aff;font-size: 13px;"></button>
    <a routerLink="/"><img src="/assets/img/logo.svg" alt="SGC" style="float:left;width:100px;padding: 10px;"></a>
    <h3 class="menu__item" style="padding:10px;text-align: center;"> {{name}} <button ej-button id="close" class="e-btn close-btn" [routerLink]="['/population-reports']" style="padding-left:8px;padding-right:8px;background:black;padding-bottom: 0px;padding-top: 0px;margin-left:40px;">NEW SEARCH</button></h3>
</div>
<div id="head2">
</div>-->

<div style="background-color: #252525; color: white; border-bottom: 0.5px solid gray;">
  <a routerLink="/"
    ><img
      src="/assets/img/logoInvoiceWhite.svg"
      alt="SGC"
      style="width: 90px; padding: 8px"
  /></a>
  <h3
    class="menu__item"
    style="
      padding: 10px;
      text-align: left;
      margin-top: -58px;
      margin-left: 125px;
    "
  >
    {{ name }}
    <a  class="login d-inline-block" [routerLink]="['/pop-report']" style="font-size: 17px; margin: 0 0 0 40px;">
      New Search
    </a>
    <!-- <button
      ej-button
      id="close"
      class="e-btn close-btn custom-PopRowdataMasterPopLink"
      [routerLink]="['/pop-report']"
      style="
        padding-left: 4px;
        background: black;
        padding-bottom: 0px;
        padding-top: 0px;
        padding-right: 4px;
        margin-left: 40px;
      "
    >
      New Search
    </button> -->
  </h3>
</div>

<p class="custom-PopdataRowColor" style="background-image: url(/assets/img/web_thin_banner.jpg);">
  <!--<span>
        <button ej-button id="close" class="e-btn close-btn" (click)="refreshgrid()" style="padding-left:5px;padding-right:5px;background:rgb(73, 46, 196);border-color: rgb(73, 46, 196);">Sort by Card #</button>
    </span>-->
  <span
    class="custom-PopdataRow custom-PopdataGradingOrder"
    style="padding-left: 0px; margin-left: 10px;"
  >
    Grading Order
  </span>
  <span>
    <select
      class="new-header-row custom-PopRowdataSelectMargin custom-PopRowdataMasterSelect"
      [formControl]="SelectGradingOrder"
      (change)="GradingOrder10($event)"
      style="
        padding: 1px !important;
        border: 1px solid #ccc;
        border-radius: 4px;
        resize: vertical;
      "
    >
      <option value="10">10-A</option>
      <option value="A">A-10</option>
    </select>
  </span>
  <span class="custom-PopdataRow custom-PopdataGradingOrder">
    Show Old Grading
  </span>
  <span>
    <select
      class="new-header-row custom-PopRowdataSelectMargin custom-PopRowdataMasterSelectoldgrade"
      [formControl]="oldSgcGrades"
      (change)="oldSgcGradesNo($event)"
      style="
        padding: 1px !important;
        border: 1px solid #ccc;
        border-radius: 4px;
        resize: vertical;
      "
    >
      <option value="No">No</option>
      <option value="Yes">Yes</option>
    </select>
  </span>
  <span class="custom-PopdataRow custom-PopdataGradingOrder">
    Show This Grade or Better
  </span>
  <span>
    <select
      class="new-header-row custom-PopRowdataSelectMargin custom-PopRowdataMasterSelectWidth"
      [formControl]="SelectGrades"
      (change)="changeGrades($event)"
      style="
        padding: 1px !important;
        border: 1px solid #ccc;
        border-radius: 4px;
        resize: vertical;
      "
    >
      <option value="All">All</option>
      <option value="10 Pristine only">10 Pristine only</option>
      <option value="10 Gem or 10 Pristine">10 Gem or 10 Pristine</option>
      <option value="9.5 MT+ or better">9.5 MT+ or better</option>
      <option value="9 MT or better">9 MT or better</option>
      <option value="8.5 NM MT+ or better">8.5 NM MT+ or better</option>
      <option value="8 NM MT or better">8 NM MT or better</option>
      <option value="7.5 NM+ or better">7.5 NM+ or better</option>
      <option value="7 NM or better">7 NM or better</option>
      <option value="6.5 EX NM+ or better">6.5 EX NM+ or better</option>
      <option value="6 EX NM or better">6 EX NM or better</option>
      <option value="5.5 EX+ or better">5.5 EX+ or better</option>
      <option value="5 EX or better">5 EX or better</option>
      <option value="4.5 VG EX+ or better">4.5 VG EX+ or better</option>
      <option value="4 VG EX or better">4 VG EX or better</option>
      <option value="3.5 VG+ or better">3.5 VG+ or better</option>
      <option value="3 VG or better">3 VG or better</option>
      <option value="2.5 Good+ or better">2.5 Good+ or better</option>
      <option value="2 Good or better">2 Good or better</option>
      <option value="1.5 Fair or better">1.5 Fair or better</option>
      <option value="1 Poor or better">1 Poor or better</option>
      <option value="A">A</option>
    </select>
  </span>
</p>

<div *ngIf="isSearching === false" style="display: flex; justify-content: center; background-color: #5d5d5d;" class="master-pop-search">
  <!-- <div class="wrap"> -->
    <div class="e-input-group e-search" style="width: unset; margin-block: 4px;">
      <input (keyup)="searchEnterKey($event)" [(ngModel)]="searchText" class="e-input e-search" name='input' type="text" placeholder="Search Player Name" autocomplete="off"/>
      <span (click)="clearSearch()" [style.visibility]="searchText ? 'visible' : 'hidden'" class="e-input-group-icon e-clear-icon e-icons e-sicon"></span>
      <span (click)="search()" class="e-input-group-icon e-search-icon e-icons"></span>
    </div>
  <!-- </div> -->
</div>

<div class="overlay active" *ngIf="isSearching === true">
  <div class="welcome">
    <div class="loader xl"></div>
    <div class="welcome-text">
      Please, hold on a few seconds while loading population report
    </div>
  </div>
</div>

<div *ngIf="isSearching === false">
  <div class="control-section">
    <div class="master-pop-grid"
      *ngIf="
        SelectGradingOrder.value === '10' &&
        oldSgcGrades.value === 'No' &&
        AllPopulationReport.length != 0
      "
    >
      <ejs-grid
        #grid
        [dataSource]="AllPopulationReport"
        [filterSettings]="filterOptions"
        [allowFiltering]="true"
        [allowGrouping]="true"
        [groupSettings]="groupOptions"
        [editSettings]="editing"
        allowExcelExport="true"
        allowPdfExport="true"
        [searchSettings]="searchSettings"
        
        [allowResizing]="true"
        height="1000"
        gridLines="Both"
        allowTextWrap="true"
        [textWrapSettings]="wrapSettings"
        [frozenRows]="0"
        [contextMenuItems]="contextMenuItems"
        (contextMenuClick)="contextMenuClick($event)"
        [allowPaging]="true"
        [pageSettings]="pageSettings"
      >
        <e-columns>
          <!--<e-column field='cardNumber' [allowSorting]='false' headerText='#' width='75' textAlign="left" type="string"></e-column>-->
          <e-column
            field="cardSet"
            headerText="Card Set"
            width="180"
            textAlign="left"
            type="string"
          ></e-column>
          <e-column
            field="cardSubject"
            [allowFiltering]="true"
            headerText="Subject"
            width="160"
            textAlign="left"
            type="string"
          ></e-column>
          <e-column
            field="cardDescription"
            allowTextWrap="true"
            headerText="Description"
            width="180"
            textAlign="left"
            type="string"
          ></e-column>
          <!--<e-column headerText='Report An Issue' width='150' textAlign="center">
            <ng-template #template let-AllPopulationReport>
                <a style="cursor:pointer;border-bottom: 1px solid black;" (click)="reportanissue(AllPopulationReport.cardNumber,AllPopulationReport.cardSubject,AllPopulationReport.cardDescription)">Click here</a>
            </ng-template>
        </e-column>-->

          <e-column
            field="gradeAH"
            [allowFiltering]="false"
            headerText="Total"
            width="110"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count10PH"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 10 PRI"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count10H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 10 Gem/Pri"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count9pt5H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 9.5 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count9H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 9 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count8pt5H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 8.5 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count8H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 8 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count7pt5H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 7.5 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count7H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 7 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count6pt5H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 6.5 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count6H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 6 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count5pt5H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 5.5 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count5H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 5 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count4pt5H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 4.5 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count4H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 4 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count3pt5H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 3.5 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count3H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 3 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count2pt5H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 2.5 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count2H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 2 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count1pt5H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 1.5 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count1H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 1 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>

          <e-column
            field="grade10P"
            [allowFiltering]="false"
            headerText="10 PRI"
            width="65"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade10P == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade10P }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade10"
            [allowFiltering]="false"
            headerText="10"
            width="55"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade10 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade10 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade9pt5"
            [allowFiltering]="false"
            headerText="9.5"
            width="55"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade9pt5 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade9pt5 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade9"
            [allowFiltering]="false"
            headerText="9"
            width="55"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade9 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade9 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade8pt5"
            [allowFiltering]="false"
            headerText="8.5"
            width="55"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade8pt5 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade8pt5 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade8"
            [allowFiltering]="false"
            headerText="8"
            width="55"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade8 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade8 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade7pt5"
            [allowFiltering]="false"
            headerText="7.5"
            width="55"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade7pt5 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade7pt5 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade7"
            [allowFiltering]="false"
            headerText="7"
            width="55"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade7 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade7 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade6pt5"
            [allowFiltering]="false"
            headerText="6.5"
            width="55"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade6pt5 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade6pt5 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade6"
            [allowFiltering]="false"
            headerText="6"
            width="55"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade6 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade6 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade5pt5"
            [allowFiltering]="false"
            headerText="5.5"
            width="55"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade5pt5 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade5pt5 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade5"
            [allowFiltering]="false"
            headerText="5"
            width="55"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade5 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade5 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade4pt5"
            [allowFiltering]="false"
            headerText="4.5"
            width="55"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade4pt5 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade4pt5 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade4"
            [allowFiltering]="false"
            headerText="4"
            width="55"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade4 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade4 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade3pt5"
            [allowFiltering]="false"
            headerText="3.5"
            width="55"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade3pt5 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade3pt5 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade3"
            [allowFiltering]="false"
            headerText="3"
            width="55"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade3 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade3 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade2pt5"
            [allowFiltering]="false"
            headerText="2.5"
            width="55"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade2pt5 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade2pt5 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade2"
            [allowFiltering]="false"
            headerText="2"
            width="55"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade2 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade2 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade1pt5"
            [allowFiltering]="false"
            headerText="1.5"
            width="55"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade1pt5 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade1pt5 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade1"
            [allowFiltering]="false"
            headerText="1"
            width="55"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade1 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade1 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="gradeA"
            [allowFiltering]="false"
            headerText="A"
            width="55"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.gradeA == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.gradeA }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
        </e-columns>

        <e-aggregates>
          <e-aggregate>
            <e-columns>
              <e-column field="gradeAH" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>{{
                  AllPopulationReport.sum
                }}</ng-template>
              </e-column>
              <e-column field="grade10P" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade10" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade9pt5" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade9" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade8pt5" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade8" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade7pt5" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade7" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade6pt5" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade6" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade5pt5" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade5" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade4pt5" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade4" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade3pt5" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade3" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade2pt5" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade2" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade1pt5" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade1" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="gradeA" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
            </e-columns>
          </e-aggregate>
        </e-aggregates>

        <e-aggregates>
          <e-aggregate>
            <e-columns>
              <e-column field="gradeAH" type="sum">
                <ng-template #footerTemplate let-data
                  >TOTALS: {{ data.sum }}</ng-template
                >
              </e-column>
              <e-column field="count10PH" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count10H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count9pt5H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count9H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count8pt5H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count8H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count7pt5H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count7H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count6pt5H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count6H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count5pt5H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count5H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count4pt5H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count4H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count3pt5H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count3H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count2pt5H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count2H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count1pt5H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count1H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>

              <e-column field="grade10P" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade10" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade9pt5" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade9" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade8pt5" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade8" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade7pt5" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade7" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade6pt5" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade6" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade5pt5" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade5" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade4pt5" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade4" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade3pt5" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade3" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade2pt5" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade2" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade1pt5" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade1" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="gradeA" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
            </e-columns>
          </e-aggregate>
        </e-aggregates>
      </ejs-grid>
    </div>

    <div class="master-pop-grid"
      *ngIf="
        SelectGradingOrder.value === 'A' &&
        oldSgcGrades.value === 'No' &&
        AllPopulationReport.length != 0
      "
    >
      <ejs-grid
        #grid
        [dataSource]="AllPopulationReport"
        [filterSettings]="filterOptions"
        [allowFiltering]="true"
        [allowGrouping]="true"
        [groupSettings]="groupOptions"
        [editSettings]="editing"
        allowExcelExport="true"
        allowPdfExport="true"
        [allowResizing]="true"
        height="1000"
        gridLines="Both"
        allowTextWrap="true"
        [textWrapSettings]="wrapSettings"
        [frozenRows]="0"
        [contextMenuItems]="contextMenuItems"
        (contextMenuClick)="contextMenuClick($event)"
        [allowPaging]="true"
        [pageSettings]="pageSettings"
      >
        <e-columns>
          <!--<e-column field='cardNumber' [allowSorting]='false' headerText='#' width='75' textAlign="left" type="string"></e-column>-->
          <e-column
            field="cardSubject"
            headerText="Subject"
            width="160"
            textAlign="left"
            type="string"
          ></e-column>
          <e-column
            field="cardDescription"
            headerText="Description"
            width="180"
            textAlign="left"
            type="string"
          ></e-column>
          <!--<e-column headerText='Report An Issue' width='150' textAlign="left">
            <ng-template #template let-AllPopulationReport>
                <a style="text-decoration-line: none;cursor: pointer;" (click)="reportanissue(AllPopulationReport.cardNumber,AllPopulationReport.cardSubject,AllPopulationReport.cardDescription)">Report</a>
            </ng-template>
        </e-column>-->

          <e-column
            field="gradeAH"
            [allowFiltering]="false"
            headerText="Total"
            width="110"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count10PH"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 10 PRI"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count10H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 10 Gem/Pri"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count9pt5H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 9.5 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count9H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 9 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count8pt5H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 8.5 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count8H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 8 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count7pt5H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 7.5 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count7H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 7 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count6pt5H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 6.5 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count6H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 6 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count5pt5H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 5.5 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count5H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 5 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count4pt5H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 4.5 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count4H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 4 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count3pt5H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 3.5 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count3H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 3 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count2pt5H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 2.5 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count2H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 2 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count1pt5H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 1.5 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count1H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 1 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>

          <e-column
            field="gradeA"
            [allowFiltering]="false"
            headerText="A"
            width="55"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.gradeA == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.gradeA }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade1"
            [allowFiltering]="false"
            headerText="1"
            width="55"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade1 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade1 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade1pt5"
            [allowFiltering]="false"
            headerText="1.5"
            width="55"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade1pt5 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade1pt5 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade2"
            [allowFiltering]="false"
            headerText="2"
            width="55"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade2 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade2 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade2pt5"
            [allowFiltering]="false"
            headerText="2.5"
            width="55"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade2pt5 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade2pt5 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade3"
            [allowFiltering]="false"
            headerText="3"
            width="55"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade3 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade3 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade3pt5"
            [allowFiltering]="false"
            headerText="3.5"
            width="55"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade3pt5 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade3pt5 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade4"
            [allowFiltering]="false"
            headerText="4"
            width="55"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade4 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade4 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade4pt5"
            [allowFiltering]="false"
            headerText="4.5"
            width="55"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade4pt5 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade4pt5 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade5"
            [allowFiltering]="false"
            headerText="5"
            width="55"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade5 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade5 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade5pt5"
            [allowFiltering]="false"
            headerText="5.5"
            width="55"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade5pt5 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade5pt5 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade6"
            [allowFiltering]="false"
            headerText="6"
            width="55"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade6 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade6 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade6pt5"
            [allowFiltering]="false"
            headerText="6.5"
            width="55"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade6pt5 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade6pt5 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade7"
            [allowFiltering]="false"
            headerText="7"
            width="55"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade7 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade7 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade7pt5"
            [allowFiltering]="false"
            headerText="7.5"
            width="55"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade7pt5 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade7pt5 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade8"
            [allowFiltering]="false"
            headerText="8"
            width="55"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade8 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade8 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade8pt5"
            [allowFiltering]="false"
            headerText="8.5"
            width="55"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade8pt5 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade8pt5 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade9"
            [allowFiltering]="false"
            headerText="9"
            width="55"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade9 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade9 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade9pt5"
            [allowFiltering]="false"
            headerText="9.5"
            width="55"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade9pt5 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade9pt5 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade10"
            [allowFiltering]="false"
            headerText="10"
            width="55"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade10 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade10 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade10P"
            [allowFiltering]="false"
            headerText="10 PRI"
            width="65"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade10P == 0; else login">
                <span>-</span>
              </div>
              <ng-template #login>
                <div>
                  <span>{{ AllPopulationReport.grade10P }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
        </e-columns>

        <e-aggregates>
          <e-aggregate>
            <e-columns>
              <e-column field="gradeAH" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>{{
                  AllPopulationReport.sum
                }}</ng-template>
              </e-column>
              <e-column field="gradeA" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade1" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade1pt5" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade2" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade2pt5" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade3" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade3pt5" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade4" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade4pt5" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade5" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade5pt5" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade6" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade6pt5" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade7" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade7pt5" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade8" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade8pt5" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade9" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade9pt5" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade10" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade10P" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
            </e-columns>
          </e-aggregate>
        </e-aggregates>

        <e-aggregates>
          <e-aggregate>
            <e-columns>
              <e-column field="gradeAH" type="sum">
                <ng-template #footerTemplate let-data
                  >TOTALS: {{ data.sum }}</ng-template
                >
              </e-column>
              <e-column field="count10PH" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count10H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count9pt5H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count9H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count8pt5H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count8H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count7pt5H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count7H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count6pt5H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count6H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count5pt5H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count5H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count4pt5H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count4H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count3pt5H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count3H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count2pt5H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count2H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count1pt5H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count1H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>

              <e-column field="gradeA" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade1" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade1pt5" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade2" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade2pt5" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade3" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade3pt5" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade4" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade4pt5" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade5" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade5pt5" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade6" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade6pt5" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade7" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade7pt5" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade8" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade8pt5" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade9" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade9pt5" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade10" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade10P" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
            </e-columns>
          </e-aggregate>
        </e-aggregates>
      </ejs-grid>
    </div>

    <div class="master-pop-grid"
      *ngIf="
        SelectGradingOrder.value === '10' &&
        oldSgcGrades.value === 'Yes' &&
        AllPopulationReport.length != 0
      "
    >
      <ejs-grid
        #grid
        [dataSource]="AllPopulationReport"
        [filterSettings]="filterOptions"
        [allowFiltering]="true"
        [allowGrouping]="true"
        [groupSettings]="groupOptions"
        [editSettings]="editing"
        allowExcelExport="true"
        allowPdfExport="true"
        [allowResizing]="true"
        height="1000"
        gridLines="Both"
        allowTextWrap="true"
        [textWrapSettings]="wrapSettings"
        [frozenRows]="0"
        [contextMenuItems]="contextMenuItems"
        (contextMenuClick)="contextMenuClick($event)"
        [allowPaging]="true"
        [pageSettings]="pageSettings"
      >
        <e-columns>
          <!--<e-column field='cardNumber' [allowSorting]='false' headerText='#' width='75' textAlign="left" type="string"></e-column>-->
          <e-column
            field="cardSubject"
            headerText="Subject"
            width="170"
            textAlign="left"
            type="string"
          ></e-column>
          <e-column
            field="cardDescription"
            headerText="Description"
            width="180"
            textAlign="left"
            type="string"
          ></e-column>
          <!--<e-column headerText='Report An Issue' width='150' textAlign="left">
            <ng-template #template let-AllPopulationReport>
                <a style="text-decoration-line: none;cursor: pointer;" (click)="reportanissue(AllPopulationReport.cardNumber,AllPopulationReport.cardSubject,AllPopulationReport.cardDescription)">Report</a>
            </ng-template>
        </e-column>-->

          <e-column
            field="gradeAH"
            [allowFiltering]="false"
            headerText="Total"
            width="110"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count10PH"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 10 PRI"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count10H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 10 Gem/Pri"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count9pt5H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 9.5 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count9H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 9 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count8pt5H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 8.5 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count8H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 8 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count7pt5H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 7.5 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count7H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 7 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count6pt5H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 6.5 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count6H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 6 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count5pt5H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 5.5 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count5H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 5 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count4pt5H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 4.5 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count4H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 4 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count3pt5H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 3.5 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count3H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 3 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count2pt5H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 2.5 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count2H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 2 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count1pt5H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 1.5 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count1H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 1 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>

          <e-column
            field="grade10P"
            [allowFiltering]="false"
            headerText="100/10 PRI"
            width="100"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade10P == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade10P }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade10"
            [allowFiltering]="false"
            headerText="98/10"
            width="65"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade10 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade10 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade9pt5"
            [allowFiltering]="false"
            headerText="97/9.5"
            width="65"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade9pt5 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade9pt5 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade9"
            [allowFiltering]="false"
            headerText="96/9"
            width="65"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade9 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade9 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade8pt5"
            [allowFiltering]="false"
            headerText="92/8.5"
            width="65"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade8pt5 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade8pt5 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade8"
            [allowFiltering]="false"
            headerText="88/8"
            width="65"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade8 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade8 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade7pt5"
            [allowFiltering]="false"
            headerText="86/7.5"
            width="65"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade7pt5 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade7pt5 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade7"
            [allowFiltering]="false"
            headerText="84/7"
            width="65"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade7 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade7 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade6pt5"
            [allowFiltering]="false"
            headerText="82/6.5"
            width="65"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade6pt5 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade6pt5 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade6"
            [allowFiltering]="false"
            headerText="80/6"
            width="65"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade6 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade6 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade5pt5"
            [allowFiltering]="false"
            headerText="70/5.5"
            width="65"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade5pt5 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade5pt5 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade5"
            [allowFiltering]="false"
            headerText="60/5"
            width="65"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade5 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade5 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade4pt5"
            [allowFiltering]="false"
            headerText="55/4.5"
            width="65"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade4pt5 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade4pt5 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade4"
            [allowFiltering]="false"
            headerText="50/4"
            width="65"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade4 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade4 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade3pt5"
            [allowFiltering]="false"
            headerText="45/3.5"
            width="65"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade3pt5 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade3pt5 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade3"
            [allowFiltering]="false"
            headerText="40/3"
            width="65"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade3 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade3 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade2pt5"
            [allowFiltering]="false"
            headerText="35/2.5"
            width="65"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade2pt5 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade2pt5 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade2"
            [allowFiltering]="false"
            headerText="30/2"
            width="65"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade2 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade2 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade1pt5"
            [allowFiltering]="false"
            headerText="20/1.5"
            width="65"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade1pt5 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade1pt5 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade1"
            [allowFiltering]="false"
            headerText="10/1"
            width="65"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade1 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade1 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="gradeA"
            [allowFiltering]="false"
            headerText="A"
            width="55"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.gradeA == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.gradeA }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
        </e-columns>

        <e-aggregates>
          <e-aggregate>
            <e-columns>
              <e-column field="gradeAH" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>{{
                  AllPopulationReport.sum
                }}</ng-template>
              </e-column>
              <e-column field="grade10P" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade10" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade9pt5" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade9" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade8pt5" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade8" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade7pt5" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade7" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade6pt5" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade6" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade5pt5" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade5" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade4pt5" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade4" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade3pt5" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade3" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade2pt5" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade2" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade1pt5" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade1" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="gradeA" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
            </e-columns>
          </e-aggregate>
        </e-aggregates>

        <e-aggregates>
          <e-aggregate>
            <e-columns>
              <e-column field="gradeAH" type="sum">
                <ng-template #footerTemplate let-data
                  >TOTALS: {{ data.sum }}</ng-template
                >
              </e-column>
              <e-column field="count10PH" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count10H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count9pt5H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count9H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count8pt5H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count8H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count7pt5H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count7H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count6pt5H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count6H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count5pt5H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count5H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count4pt5H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count4H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count3pt5H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count3H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count2pt5H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count2H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count1pt5H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count1H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>

              <e-column field="grade10P" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade10" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade9pt5" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade9" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade8pt5" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade8" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade7pt5" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade7" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade6pt5" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade6" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade5pt5" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade5" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade4pt5" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade4" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade3pt5" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade3" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade2pt5" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade2" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade1pt5" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade1" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="gradeA" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
            </e-columns>
          </e-aggregate>
        </e-aggregates>
      </ejs-grid>
    </div>

    <div class="master-pop-grid"
      *ngIf="
        SelectGradingOrder.value === 'A' &&
        oldSgcGrades.value === 'Yes' &&
        AllPopulationReport.length != 0
      "
    >
      <ejs-grid
        #grid
        [dataSource]="AllPopulationReport"
        [filterSettings]="filterOptions"
        [allowFiltering]="true"
        [allowGrouping]="true"
        [groupSettings]="groupOptions"
        [editSettings]="editing"
        allowExcelExport="true"
        allowPdfExport="true"
        [allowResizing]="true"
        height="1000"
        gridLines="Both"
        allowTextWrap="true"
        [textWrapSettings]="wrapSettings"
        [frozenRows]="0"
        [contextMenuItems]="contextMenuItems"
        (contextMenuClick)="contextMenuClick($event)"
        [allowPaging]="true"
        [pageSettings]="pageSettings"
      >
        <e-columns>
          <!--<e-column field='cardNumber' [allowSorting]='false' headerText='#' width='90' textAlign="left" type="string"></e-column>-->
          <e-column
            field="cardSubject"
            headerText="Subject"
            width="170"
            textAlign="left"
            type="string"
          ></e-column>
          <e-column
            field="cardDescription"
            headerText="Description"
            width="180"
            textAlign="left"
            type="string"
          ></e-column>
          <!--<e-column headerText='Report An Issue' width='150' textAlign="left">
            <ng-template #template let-AllPopulationReport>
                <a style="text-decoration-line: none;cursor: pointer;" (click)="reportanissue(AllPopulationReport.cardNumber,AllPopulationReport.cardSubject,AllPopulationReport.cardDescription)">Report</a>
            </ng-template>
        </e-column>-->

          <e-column
            field="gradeAH"
            [allowFiltering]="false"
            headerText="Total"
            width="110"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count10PH"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 10 PRI"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count10H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 10 Gem/Pri"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count9pt5H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 9.5 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count9H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 9 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count8pt5H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 8.5 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count8H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 8 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count7pt5H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 7.5 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count7H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 7 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count6pt5H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 6.5 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count6H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 6 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count5pt5H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 5.5 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count5H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 5 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count4pt5H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 4.5 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count4H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 4 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count3pt5H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 3.5 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count3H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 3 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count2pt5H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 2.5 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count2H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 2 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count1pt5H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 1.5 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>
          <e-column
            field="count1H"
            [visible]="false"
            [allowFiltering]="false"
            headerText="Total 1 ≥"
            width="85"
            textAlign="left"
            type="number"
          ></e-column>

          <e-column
            field="gradeA"
            [allowFiltering]="false"
            headerText="A"
            width="55"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.gradeA == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.gradeA }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade1"
            [allowFiltering]="false"
            headerText="10/1"
            width="65"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade1 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade1 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade1pt5"
            [allowFiltering]="false"
            headerText="20/1.5"
            width="65"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade1pt5 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade1pt5 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade2"
            [allowFiltering]="false"
            headerText="30/2"
            width="65"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade2 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade2 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade2pt5"
            [allowFiltering]="false"
            headerText="35/2.5"
            width="65"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade2pt5 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade2pt5 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade3"
            [allowFiltering]="false"
            headerText="40/3"
            width="65"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade3 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade3 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade3pt5"
            [allowFiltering]="false"
            headerText="45/3.5"
            width="65"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade3pt5 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade3pt5 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade4"
            [allowFiltering]="false"
            headerText="50/4"
            width="65"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade4 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade4 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade4pt5"
            [allowFiltering]="false"
            headerText="55/4.5"
            width="65"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade4pt5 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade4pt5 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade5"
            [allowFiltering]="false"
            headerText="60/5"
            width="65"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade5 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade5 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade5pt5"
            [allowFiltering]="false"
            headerText="70/5.5"
            width="65"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade5pt5 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade5pt5 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade6"
            [allowFiltering]="false"
            headerText="80/6"
            width="65"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade6 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade6 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade6pt5"
            [allowFiltering]="false"
            headerText="82/6.5"
            width="65"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade6pt5 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade6pt5 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade7"
            [allowFiltering]="false"
            headerText="84/7"
            width="65"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade7 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade7 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade7pt5"
            [allowFiltering]="false"
            headerText="86/7.5"
            width="65"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade7pt5 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade7pt5 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade8"
            [allowFiltering]="false"
            headerText="88/8"
            width="65"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade8 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade8 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade8pt5"
            [allowFiltering]="false"
            headerText="92/8.5"
            width="65"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade8pt5 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade8pt5 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade9"
            [allowFiltering]="false"
            headerText="96/9"
            width="65"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade9 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade9 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade9pt5"
            [allowFiltering]="false"
            headerText="97/9.5"
            width="65"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade9pt5 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade9pt5 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade10"
            [allowFiltering]="false"
            headerText="98/10"
            width="65"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade10 == 0; else result">
                <span>-</span>
              </div>
              <ng-template #result>
                <div>
                  <span>{{ AllPopulationReport.grade10 }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
          <e-column
            field="grade10P"
            [allowFiltering]="false"
            headerText="100/10 PRI"
            width="100"
            textAlign="right"
            type="number"
          >
            <ng-template #template let-AllPopulationReport>
              <div *ngIf="AllPopulationReport.grade10P == 0; else login">
                <span>-</span>
              </div>
              <ng-template #login>
                <div>
                  <span>{{ AllPopulationReport.grade10P }}</span>
                </div>
              </ng-template>
            </ng-template>
          </e-column>
        </e-columns>

        <e-aggregates>
          <e-aggregate>
            <e-columns>
              <e-column field="gradeAH" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>{{
                  AllPopulationReport.sum
                }}</ng-template>
              </e-column>
              <e-column field="gradeA" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade1" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade1pt5" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade2" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade2pt5" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade3" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade3pt5" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade4" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade4pt5" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade5" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade5pt5" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade6" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade6pt5" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade7" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade7pt5" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade8" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade8pt5" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade9" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade9pt5" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade10" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field="grade10P" type="sum">
                <ng-template #groupCaptionTemplate let-AllPopulationReport>
                  <div *ngIf="AllPopulationReport.sum == 0; else result">
                    <span>-</span>
                  </div>
                  <ng-template #result>
                    <div>
                      <span>{{ AllPopulationReport.sum }}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
            </e-columns>
          </e-aggregate>
        </e-aggregates>

        <e-aggregates>
          <e-aggregate>
            <e-columns>
              <e-column field="gradeAH" type="sum">
                <ng-template #footerTemplate let-data
                  >TOTALS: {{ data.sum }}</ng-template
                >
              </e-column>
              <e-column field="count10PH" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count10H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count9pt5H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count9H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count8pt5H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count8H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count7pt5H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count7H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count6pt5H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count6H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count5pt5H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count5H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count4pt5H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count4H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count3pt5H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count3H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count2pt5H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count2H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count1pt5H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="count1H" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>

              <e-column field="gradeA" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade1" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade1pt5" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade2" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade2pt5" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade3" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade3pt5" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade4" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade4pt5" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade5" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade5pt5" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade6" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade6pt5" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade7" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade7pt5" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade8" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade8pt5" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade9" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade9pt5" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade10" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
              <e-column field="grade10P" type="sum">
                <ng-template #footerTemplate let-data
                  ><div *ngIf="data.sum == 0; else result"><span>-</span></div>
                  <ng-template #result
                    ><div>{{ data.sum }}</div></ng-template
                  ></ng-template
                >
              </e-column>
            </e-columns>
          </e-aggregate>
        </e-aggregates>
      </ejs-grid>
    </div>
  </div>
</div>
