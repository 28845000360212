<style>
    ul, ol {
        padding-left: 40px;
        font-family: 'd35';
        font-weight: normal;
        margin: 0;
    }

    ul {
        list-style: disc;
    }

    p, ul, ol, i {
        font-size: 1.8rem;
        line-height: 2.25rem;
    }
    strong {
        font-weight: 400;
    }
</style>
<!--<div class="section-title section-title--culture">
    <h2>SGC Global Customer Privacy Statement</h2>
</div>-->

<div class="content content--terms">

    <p><strong>Effective as of June 28, 2022</strong></p>

    <p><strong>Privacy Policy</strong></p>

    <p>
        Your privacy is important to us at New SGC LLC, (“SGC”, “we”, “our” or “us”). This SGC Global Customer Privacy Statement (“Privacy Policy”) applies to: any website owned or operated by or on behalf of us, including, without limitation, this website currently located at gosgc.com (the “Website”), when you register for a customer account on the Website, when you complete or submit an order on the Website, when you authorize and consent to an SGC employee creating an account and/or submitting an order on your behalf, as well as any marketing or promotions subscribed to via the Website, and any other service or product of SGC, including digital properties, as expressly indicated by SGC such as through a link or other display of this Privacy Policy (collectively with the Website, the “Services”). This Privacy Policy describes what information, including Personal Information we collect from or about you on our Services, how we use or share such information, and how to exercise any rights you may have with respect to your Personal Information.
    </p>

    <p>
        Please carefully read this Privacy Policy, which is part of and subject to our <a routerLink="/sgc-terms">Terms and Conditions</a>, before using our Website or Services, including, without limitation, gosgc.com, and any related SGC web pages that display this Privacy Policy. If you do not agree with our <a routerLink="/sgc-terms"> Terms and Conditions</a> and/or the Privacy Policy, please refrain from using our Website or Services. By accessing or using our Website or Services, you agree to our use of your Personal Information and other information consistent with the <a routerLink="/sgc-terms">Terms and Conditions</a> and this Privacy Policy.
    </p>

    <p>
        Information We Collect

        We collect various types of information about our users in connection with the Services, including:

    </p>
    <ul>
        <li>Information you provide to us during account creation, including identity data such as your full name;</li>
        <li>Contact Information and Account Information you provide to us during order submission, such as mailing address, billing address, email address phone number, username and password, preferences, feedback and survey responses;</li>
        <li>Information we collect from third-party shipping carriers and payment processors;</li>
        <li>Geolocation Information, which may include your IP address location; and</li>
        <li>Transaction, Technical, and Communication Information, as well as Marketing Preferences our IT systems collect about your use of our Services, such to improve and customize your experience using our Services</li>
    </ul>

    <p>
        When information identifies or is reasonably capable of being associated with, or could reasonably be linked, directly or indirectly, with you, or your household, we refer to it as “Personal Information.”
    </p>

    <p><strong>Personal Information You Provide to Us. </strong>  We collect Personal Information you give us when you use certain Services. For example, we may ask for your name, e-mail address and account name when you sign up for an account with us, and we may ask for a street address, or phone number when you submit an order. We also may collect information about the cards you’ve added to orders, how frequently you submit orders, your use of promo codes.</p>

    <p><strong>Personal Information Shared with Us from Third-Parties. </strong> You consent to sharing some of your personal information with certain types of third parties for the purpose of using our Services when agreeing to our <a routerLink="/sgc-terms">Terms and Conditions</a>.  We may collect Personal Information about you from these third parties, such as payment processors and shipping carriers, handling your transactions. For example, a payment provider may return an email address or phone number that you provided to your bank, and a shipping carrier may return a zip code with your postal code.  This information is logged as part of your transaction but not added to your customer profile.</p>

    <p>We are not responsible for the policies and practices of third parties. Any information you give to those organizations is dealt with under their privacy policy, <a routerLink="/sgc-terms">Terms and Conditions</a>, and other policies.</p>

    <p><u>Your credit card information, debit card information, bank, and PayPal credentials are never seen nor retained by us. SGC does not access or store your financial information when you use our Services.</u> SGC uses third party providers to process payments. These providers use and processing of your Personal Information, including your financial information, is subject to their privacy policies and not this Privacy Policy.</p>

    <p><strong>Personal Information Collected Automatically. </strong>In addition, we may use various tools and technologies like cookies and web server logs to automatically collect information about your use of the Services. This type of information would be used for system and Service troubleshooting. This information is not linked directly with your account and the personal information you have provided us.  For example, we may collect:</p>
    <ul>
        <li>Device information - such as your hardware model, IP address, other unique device identifiers, operating system details, web browser type and version, internet service provider details, referring URLs, time and length of visits, and pages viewed, and settings of the device you use to access the Services.</li>
        <li>Usage information - the time and duration of your use of the Services and other information about your interaction with content offered through a Service, and any information stored in cookies and similar technologies that we have set on your device.</li>
        <li>Location information - such as your device’s IP address, your mobile device’s rough GPS signal when you use the Services.</li>
    </ul>




    <p><strong>Personal Information Storage. </strong> We will keep your Personal Information, order history and transactional history for as long as you allow and as it is needed to fulfill the purposes for which it was collected and in order to comply with legal or regulatory requirements, resolve disputes, and enforce our agreements.  </p>

    <p><u>How We Use Information</u></p>
    <p>We use the information we collect to, among other things:</p>
    <ul>
        <li>Register and create your account</li>
        <li>Send transactional emails to the email address specified on your account</li>
        <li>Process and fulfill orders you submit</li>
        <li>Deliver products and services you requested or contracted for</li>
        <li>
            Charge payments and issue refunds for Services you request
        </li>
        <li>
            Provide and manage the Services you request
        </li>
        <li>
            Communicate with you about our products, services, and promotions that you have opted into receiving
        </li>
        <li>
            Provide customer support and respond at your request
        </li>
        <li>
            Manage your API credentials and access to data
        </li>
        <li>
            Perform troubleshooting and debugging on IT systems
        </li>
        <li>
            Comply with a legal obligation
        </li>
        <li>
            Where it is necessary for our legitimate interests (or those of a third party) and your interest and fundamental rights do not override those interests
        </li>
        <li>
            Help improve, upgrade, or enhance our services and products and develop new services and products
        </li>
        <li>
            To monitor to detect security incidents and protect against malicious, deceptive, fraudulent, or illegal activity, and hold those responsible for that activity
        </li>
        <li>
            Enforce our <a routerLink="/sgc-terms">Terms and Conditions</a>, including in this Privacy Policy; and
        </li>
        <li>
            We may otherwise use your information with your consent or at your direction.
        </li>
    </ul>

    <p>
        <u>Sharing of Your Personal Information</u>
    </p>

    <p>
        We will not sell, trade, or disclose your Personal Information to any third-party except as set forth in this Privacy Policy and as follows:
    </p>
    <ul>
        <li>For payment processors of our choosing to facilitate a payment or refund, including, but not limited to PayPal and Authorize.Net, to complete credit card, debit card, PayPal, or any other SGC pre-approved method of payment.</li>
        <li>Shipping carriers and shipping carrier software providers, including but not limited to, USPS and FedEx. If you visit one of these linked websites, you should read the website’s privacy policy, <a routerLink="/sgc-terms">Terms and Conditions</a>, and their other policies.</li>
        <li>
            Authentication platforms to verify your credentials.
        </li>
        <li>To fulfill the purpose for which you provide it.</li>
        <li>
            To SGC affiliated entities.
        </li>
        <li>
            Marketing and automation platforms for the purposes of sending notifications of events, products, and promotions that you have opted-in to.
        </li>
        <li>
            To designated service providers we use to support our business and who are bound by contractual obligations to keep Personal Information confidential and use it only for the purposes for which we disclose it to them.
        </li>
        <li>
            For any other purpose disclosed by us when you provide the information.
        </li>
        <li>
            To comply with any court order, law or legal process, including to respond to any law enforcement, government or regulatory request.
        </li>
        <li>
            Other parties when required or permitted by law, as necessary to protect our users, or in connection with a corporate transaction.
        </li>
        <li>
            We may disclose aggregated, non-personally identifiable information about the Website users without restriction.
        </li>
        <li>
            To enforce or apply our <a routerLink="/sgc-terms">Terms and Conditions</a> and other agreements, including for billing and collection purposes.
        </li>
    </ul>

    <p><strong>Aggregated Data</strong> We also work with, and may also provide to third-parties, various types of data that are de-identified or aggregated, and not reasonably capable of being associated with, or reasonably be linked, directly or indirectly, with you or your household.  This includes information about the items that have been serviced, their grades, certification codes, and images. This data is not Personal Information.</p>
    <p><strong>When Required or Permitted by Law, or As Necessary to Protect Our Users and Services. </strong>We reserve the right to disclose any personal information as needed if the information is requested by law enforcement agencies or if we are required to do so by law, regulation, subpoena, court order, or by a government entity. We also may disclose Personal Information to third parties if we have reason to believe that disclosing the information is necessary to conduct investigations of possible breaches of law, to cooperate in any legal investigations, or to identify, contact, or bring legal action against someone who may be violating the  <a routerLink="/sgc-terms">Terms and Conditions</a> of our Website and Services.</p>
    <p><strong>Getting SGC Grading Services Without Sharing Personal Information With Us. </strong>If you want SGC to grade your cards, but do not want to share your personal information with us, you may indirectly submit your cards to SGC using one of our bulk submitters. As part of our agreements with Bulk Submitters, SGC does not request, nor accept, any personal information from bulk submitters. We are not responsible for the privacy practices of Bulk Submitters and recommend you review their privacy policies carefully.  We do not share your personal information with our bulk submitters. Should we choose to offer goods or services from our partners in the future, you will have to opt-in to receive these offers and communications. </p>
    <p><u>Retention of Data</u></p>
    <p>We will retain your Personal Information and transactional data in our system in perpetuity as long as your account remains active. Most of our customers want this information retained so that they can review their cards, grades, images and use services such as authentication code lookups, population report(s) and set registries. We may retain your Personal Information for a period of time after our relationship with you has ended where there is an ongoing business need to retain it, including to comply with our legal, regulatory, tax and/or accounting obligations. We will do so in accordance with our data retention policies and applicable law.</p>
    <p>Our IT systems may also retain logs and other system usage information that was automatically collected for 90 days.</p>
    <p>We may also retain personal information in our routine system backups.  These backups are only accessed when absolutely necessary to address system troubleshooting and debugging. Our internal policy requires approval from the CTO or CEO in order to access the personal information, and another approval to restore any data from a backup.</p>
    <p>If you want your personal data and account deleted, you may request deletion at any time</p>
    <p><u>Data Security and Other Important Information</u></p>
    <p><strong>Data Security. </strong> SGC has procedural, technical and physical security measures to help protect against the risk of loss, misuse, and alteration of information that you provide, including Personal Information. We request third parties with whom we share Personal Information to do the same. Nevertheless, such security measures may not prevent all loss, misuse, or alteration of information on the Websites or Services, and SGC is not responsible for any damages or liabilities relating to any such security failures. By using the Websites or Services, you understand that there is a risk that data and communications, including email and other electronic communications, may be accessed by unauthorized third parties. The foregoing does not affect any liability that cannot be excluded or limited under applicable law.</p>
    <p>Please do not provide your password to another person. We cannot be responsible for a data breach or other incident if a password is used by unauthorized persons.</p>
    <p>
        <strong>Third Party Websites and Services. </strong>
        Our Website or Services may include links to websites or other online properties that are not under our control. Our website may link to our shipping carriers, social media accounts, and job posting on third-party websites, including, but not limited to FedEx, USPS, PayPal, Facebook, Instagram, Twitter, LinkedIn, Indeed, and services that we do not operate and are outside of our control. SGC shall have no responsibility or liability for the content or privacy practices of any such linked website or any link or linking program at any time. Please exercise caution and review the privacy statements applicable to the third-party websites and services you use. If you click on a link on our Website and link to a third party’s website, then this Privacy Policy will not apply to your Personal Information collected on that third party’s website and you must read the privacy policy on that website to see how your information will be handled.
        Social media widgets or tools may appear on our Websites or Services. Your interaction with and use of these widgets or tools will be governed by the terms of use and privacy policy of the applicable social media provider (e.g., Facebook).
    </p>
    <p>
        <strong>International Transfers. </strong>
        The Services are headquartered in the United States. Please be aware that information you provide to us or that we obtain as a result of your use of the Services may be collected in your country and subsequently transferred to, maintained and processed in the United States or another country by us or our service providers for the purposes mentioned above in accordance with applicable law. The privacy laws of these countries may not always offer the same level of protection as in your country. By using the Services, you consent to the collection, international transfer, storage, and processing of your information in the United States.
    </p>
    <p>
        <strong>Protecting Children’s Privacy. </strong>
        Our Website is not intended for children under 13 years of age. No one under age 13 may provide any Personal Information to or on the Website. We do not knowingly collect Personal Information from children under 13. If you are under 13, do not use or provide any information on the Website or through any of its features, register on the Website, make any purchases through the Website, or provide any information about yourself to us, including your name, address, telephone number, email address, or any screen name or username you may use. If we learn we have collected or received Personal Information from a child under 13 without verification of parental consent, we will delete that information. If you believe we might have any information from or about a child under 13, please contact us.
    </p>
    <p>If you are under the age of 18 and use our Website, you must have your parent’s permission and we will assume you have done so. We do not otherwise knowingly collect Personal Information from anyone under the age of 18. By using the Website or Services, you represent and warrant that you are at least 18 years old. If we become aware that a customer is under the age of 18 and has registered or otherwise provided Personal Information without parental consent, we will make reasonable efforts to delete such information from our records in accordance with applicable law.</p>
    <p>
        <strong>User With Disabilities and ADA compliance. </strong>individual assisting a user with a disability, and have difficulty accessing or navigating our digital channels – including this Privacy Policy – please contact us at <a href="mailto:support@gosgc.com"> support&#64;gosgc.com</a>.

    </p>
    <p>
        <strong>
            Corporate Transactions.
        </strong>We reserve the right to transfer any information we have about you in the event that we sell or transfer all or a portion of our business or assets to a third party, such as in the event of a merger, acquisition, bankruptcy reorganization, or other change of ownership or control. If another entity acquires us or any of our assets, information we have collected about you may be transferred to such entity. In addition, if any bankruptcy or reorganization proceeding is brought by or against us, such information may be considered an asset of ours and may be sold or transferred to third parties. Should such a sale or transfer occur, we will use reasonable efforts to try to require that the transferee use Personal Information provided through the Services in a manner that is consistent with this Privacy Policy.
    </p>
    <p><strong>Changes to Our Privacy Policy. </strong> We may modify this Privacy Policy from time to time. Such changes will be posted to this page. We will notify you of material changes by appropriate means. Any changes to the Privacy Policy will become effective and will supersede all previous terms as of the Effective Date. Your use of the Services following such a change indicates your acceptance of the revised Privacy Policy.</p>
    <p><u>Managing Your Personal Data and Opt Out</u></p>
    <p><strong>Personal Information. </strong>You may access or modify your personal information and marketing preferences by the following methods:</p>
    <ol>
        <li>Logging into gosgc.com with your account and selecting “My Profile”, or</li>
        <li>
            Using the “Collector Support” or “I Need Help” links on to the gosgc.com and selecting the “General Inquiries” or any option specifically naming Personal Information from the Type of Inquiry dropdown.
        </li>
    </ol>
    <p>Please note, that we currently do not allow you to edit your shipping addresses when they have been added to an order. You will receive a popup notification of this issue if you attempt to edit an address that meets this criteria in your profile.</p>
    <p><strong>Marketing Preferences. </strong>You may opt out of receiving commercial emails, and other electronic messages from us (excluding transactional messages) by the following methods:</p>
    <ol>
        <li>Logging into gosgc.com with your account and selecting “My Profile”, and opting out of the emails.  At account creation, all marketing emails will be set to “Opt Out” by default.</li>
        <li>Following the instructions contained in the email messages.            </li>
    </ol>
    <p><u>Requesting Deletion of Your Personal Data or Account</u></p>
    <p>Per the <a routerLink="/sgc-terms">Terms and Conditions</a> of our website, you consent to SGC storing your personal data in order to provide services.  SGC does not allow users to request deletion of their personal data and keep their existing account active.  You may request deletion of your personal data and account by the following methods:</p>
    <ol>
        <li>Logging into gosgc.com with your account and selecting “My Profile”, and selecting “Request Deletion of my Account” from the Type of Inquiry dropdown.</li>
        <li>Logging into gosgc.com with your account and selecting “I Need Help”, and selecting “Request Deletion of my Account” from the Type of Inquiry dropdown.</li>
        <li>Emailing <a href="mailto:privacy@gosgc.com">privacy&#64;gosgc.com</a></li>
    </ol>
    <p>Whichever way you elect to contact us, we may ask that you confirm and verify your identity.</p>
    <p><strong>What to Expect After Requesting Deletion Of Your Personal Information and Account</strong></p>
    <ul>
        <li>Immediately after you have initiated the request, our system will place your account on hold. You will not be able to submit orders during this time. You will still be able to log into your account, access all your orders and personal information, and submit support requests.</li>
        <li>We will respond via email from <a href="mailto:privacy@gosgc.com"> privacy&#64;gosgc.com</a> in 10 calendar days confirming your request.</li>
        <li>
            We will need up to 45 calendar days to safely process your request and delete your personal information and account safely.
        </li>
        <li>
            Should you change your mind and want to cancel your request, you may do so on your My Profile page or contact <a href="mailto:privacy@gosgc.com"> privacy&#64;gosgc.com</a>.
        </li>
        <li>Upon deletion of your personal information and account, you will not be notified. We will not be able to restore your account or access to your grades or images once we have processed your request.</li>
    </ul>
    <p>Note that there are some reasons we will not be able to fully address your request, including, but not limited to, if we need to complete a transaction for you, to detect and protect against fraudulent and illegal activity, to reconcile any payment or carrier shipping disputes, to exercise our rights, for our internal purposes, or to comply with a legal obligation.</p>
    <p><strong><u>California Consumer Privacy Act</u></strong></p>
    <p>Your California Privacy Rights. As a California resident, you have certain rights over your Personal Information held by SGC under the California Consumer Privacy Act (the “CCPA”).</p>
    <p>Access and Deletion Requests. California residents are entitled twice a year, free of charge, to request and obtain:</p>
    <ul>
        <li>
            the categories and specific pieces of Personal Information that we collected about you in the preceding 12 months, the business purpose for such collection, and corresponding categories of sources from which we collected the information;
        </li>
        <li>
            the categories of Personal Information, and the categories of third parties to whom we disclosed your Personal Information in the preceding 12 months, and the business or commercial purpose for such disclosure; and
        </li>
        <li>
            You may also request that we delete any Personal Information that we have collected from or about you by requesting deletion of your account.
        </li>
    </ul>
    <p>Requests to exercise the above-described rights should be directed to <a href="mailto:privacy@gosgc.com"> privacy&#64;gosgc.com</a>. In addition, you may contact us by clicking on this <a href="javascript:void(0);">link</a>. Whichever way you elect to contact us, we may ask that you confirm and verify your identity before we can respond to your requests. We value your privacy and will not discriminate in response to your exercise of your privacy rights. </p>
    <p>
        Agent Requests. You may authorize someone to make a privacy rights request on your behalf (an authorized agent). Authorized agents will need to demonstrate that you’ve authorized them to act on your behalf, and that they are registered with the California Secretary of State to conduct business in California, or must demonstrate they have power of attorney pursuant to applicable probate law. We retain the right to request confirmation directly from you confirming that the agent is authorized to make such a request, or to request additional information to confirm the agent’s identity. An authorized agent is prohibited from using a consumer’s Personal Information, or any information collected from or about the consumer, for any purpose other than to fulfill the consumer’s requests, for verification, or for fraud prevention.

    </p>
    <p>
        If you submit Personal Information about others, you represent and warrant that you are authorized to do so and that you have received authorization from the person about whom you are providing the information and that person has consented to have all information used, disclosed, and transferred in accordance with this Privacy Policy.
    </p>

    <p><strong><u>EEA/UK Privacy Rights </u></strong></p>
    <p>For residents of the European Economic Area (EEA) and the UK, we advise that your personal information will be transferred to and processed in the United States, which has data protection laws that are different than those in your country and may not be as protective. The United States has not received a finding of “adequacy” from the European Union under Article 45 of the General Data Protection Regulation (GDPR). Our legal basis for collecting and using your personal information is to do so with your consent; where we need the personal information for performance of a contract or requested service or where the collection and use is in our or another’s legitimate interests and not overridden by your data protection interests or fundamental rights and freedoms. In some cases, we may also have a legal obligation to collect the personal information in question. If we collected your Personal Information with your consent, you may withdraw your consent at any time.</p>
    <p>Our retention of your personal information and any subsequent communications are based on our legitimate interest in providing you with new and potentially relevant materials based on your geography, role, or company. As always, you can elect to opt out from receiving such future communications.</p>
    <p>To the extent that we transfer personal information from the EEA or UK to a jurisdiction outside the EEA or UK that has not been adduced by the European Commission or UK as providing adequate data protections (such as the United States), we will ensure that such Personal Information is safeguarded through appropriate contractual terms or other approved mechanisms.</p>
    <p>Rights for EEA and UK Residents: If you are a resident of the EEA or UK, you have the right to:</p>
    <ul>
        <li>
            Find out if we use your personal information, to access your Personal Information, and receive copies of your personal information.
        </li>
        <li>
            Withdraw any express consent that you have provided to the processing of your Personal Information at any time without penalty.
        </li>
        <li>
            Access your Personal Information and have it corrected or amended if it is inaccurate or incomplete.
        </li>
        <li>
            Obtain a transferable copy of some of your Personal Information which can be transferred to another provider when the Personal Information was processed based on your consent.
        </li>
        <li>
            If you believe your Personal Information is inaccurate, no longer necessary for our business purposes, or if you object to our processing of your Personal Information, you also have the right to request that we restrict the processing of your data pending our investigation and/or verification of your claim.
        </li>
        <li>
            Request your Personal Information be deleted or restricted under certain circumstances.
        </li>
    </ul>
    <p>
        Withdrawing your consent will not affect the lawfulness of any processing we conducted prior to your withdrawal, nor will it affect processing of your Personal Information conducted in reliance on lawful processing grounds other than consent.
        If we ask you to provide personal information to us to comply with a legal requirement or enter into a contract, we will inform you of this and let you know whether providing us with your Personal Information is required and if not, the consequences of not sharing your Personal Information with us.
    </p>
    <p>
        Similarly, if we collect and use your Personal Information in reliance on our or a third party’s legitimate interests and those interests are not already described above, we will let you know what those legitimate interests are.
    </p>
    <p>
        We endeavor to apply suitable safeguards to protect the privacy and security of your Personal Information and to use it only consistent with your relationship with us and the practices described in this Privacy Policy.
    </p>
    <p>To withdraw consent or exercise these rights, please contact us via email at <a href="mailto:privacy@gosgc.com">privacy&#64;gosgc.com</a>.</p>
    <p>If you are not satisfied with our response, or believe we are processing your Personal Information in violation of the law, you have the right to lodge a complaint with the Supervisory Authority (also known as Data Protection Authority) or other appropriate governmental authority in your EEA Member State or the UK, as applicable. A list of Supervisory Authorities is available  <a href="https://edpb.europa.eu/about-edpb/board/members_en" target="_blank">here</a>. </p>
</div>
