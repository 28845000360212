import { Injectable } from '@angular/core';
import { Address, User, Customer, UserOrderInfo } from '../models/user';
import * as _ from 'lodash';
import { map,catchError } from 'rxjs/operators';

import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { BehaviorSubject } from 'rxjs';
import { StateService } from './state.service';
import { CheckoutService } from '../../checkout/services/checkout.service';
import { ErrorService } from '../../common/services/error.service';

@Injectable()
export class UserService {

    constructor(
        private apiService: ApiService,
        private stateService: StateService,
        private errorService: ErrorService,
        private checkoutService: CheckoutService
    ) { }

    private userAddressesSource = new BehaviorSubject<Address[]>(new Array<Address>());
    userAddresses$ = this.userAddressesSource.asObservable();

    private userOrderInfoSource = new BehaviorSubject<UserOrderInfo[]>(new Array<UserOrderInfo>());
    userOrderInfo$ = this.userOrderInfoSource.asObservable();

    private selectedCustomerAddressesSource = new BehaviorSubject<Address[]>(new Array<Address>());
    selectedCusAddresses$ = this.selectedCustomerAddressesSource.asObservable();

    remoeveUserAddresses$(address: Address[]) {
        this.userAddressesSource.next(address);
    }

    private deleteAdressSource = new BehaviorSubject<boolean>(false);
    CheckdeleteAdress = this.deleteAdressSource.asObservable();

    deleteAdress(deleted:any) {
        this.deleteAdressSource.next(deleted);
    }

    updatedSeletedCustomerAdrs(address: Address[]) {
        this.selectedCustomerAddressesSource.next(address);
    }

    private userProfileSoure = new BehaviorSubject<User>(new User());
    userProfile$ = this.userProfileSoure.asObservable();

    logoutUser(user: User) {
        this.userProfileSoure.next(user);
    }

    logoutCurrentUser() : Observable<any> {
        return this.apiService.get(`/user/removeCurrentUserContext`);
    }

    getCurrentUser(): Observable<any> {
        return this.apiService.get('/user').pipe(
            map(data => {
            var user = new User(data);
            this.userOrderInfoSource.next(data.orders);
            this.userAddressesSource.next(user.addresses);
            this.userProfileSoure.next(user);
            return data;
        }),catchError((error:any) => this.errorService.handleAsDefaultErrorOrderSubmit(error)));
    }

    userLastLoggedIn(userId:any): Observable<any> {
        
        return this.apiService.get(`/user/userLoggedIn/${userId}`)
            .pipe(catchError(error => this.errorService.handleAsDefaultErrorOrderSubmit(error)))
    }

    getUserAddresses(): Observable<Address[]> {
        return this.apiService.get('/user/addresses').pipe(
          map(data => _.map(data.items, item => new Address(item))),
          catchError(error => this.errorService.handleAsDefaultErrorOrderSubmit(error))
        );
      }

    getSelectedCustomerAddresses(userId:any): Observable<Address[]> {
        return this.apiService.get(`/user/addresses/${userId}`)
        .pipe(map(data => _.map(data.items, item => new Address(item)))
            ,catchError(error => this.errorService.handleAsDefaultErrorOrderSubmit(error)))
    }

    removeAddress(address: Address) {
        var addressId = address.id;
        return this.apiService.delete(`/user/addresses/${addressId}`)
            .subscribe(() => {
                var currentUser = this.stateService.currentUser.get();
                var index = currentUser.addresses.findIndex(address => address.id == addressId);
                if (index != -1) {
                    currentUser.addresses.splice(index, 1);
                } 
                this.userAddressesSource.next(currentUser.addresses);
                this.deleteAdressSource.next(true);
            });
    }

    addAddress(address: any): Observable<Address> {
        var data = JSON.parse(localStorage.getItem('newUserInfo')!);
        if (data != null) {
            var request = this.getAddressRequestForSelectedCustomer(address);
            return this.apiService.post(`/user/addresses/customer-Address`, request)
            .pipe(map(data => {
                var newAddress = new Address(data);
                var dataUser = JSON.parse(localStorage.getItem('newUserInfo')!);
                var userId = dataUser.newUserId;
                this.getSelectedCustomerAddresses(userId).subscribe(a => {
                    this.editableCustomer.addresses = a;
                    this.updatedSeletedCustomerAdrs(a);
                });
                return newAddress;
            }),catchError(error => this.errorService.handleAsDefaultErrorOrderSubmit(error)))
        }
        else {
            var request = this.getAddressRequest(address);
            return this.apiService.post(`/user/addresses`, request)
            .pipe(map(data => {
                var newAddress = new Address(data);
                var currentUser = this.stateService.currentUser.get();
                this.getUserAddresses().subscribe(a => {
                    currentUser.addresses = a;
                    this.userAddressesSource.next(currentUser.addresses);
                });
                return newAddress;
            }),catchError(error => this.errorService.handleAsDefaultErrorOrderSubmit(error)))
        }
    }

    editableCustomer = new Customer();

    editAddress(address: Address): Observable<Address> {
        var request = this.getAddressRequest(address);
        var addressId = address.id;
        return this.apiService.patch(`/user/addresses/${addressId}`, request).pipe(map(data => {
            var newAddress = new Address(data);
            var currentUser = this.stateService.currentUser.get();
            var data = JSON.parse(localStorage.getItem('newUserInfo')!);
            var userId = null
            if (data != null) {
                userId = data.newUserId;
                this.getSelectedCustomerAddresses(userId).subscribe(a => {
                    this.editableCustomer.addresses = a;
                    this.updatedSeletedCustomerAdrs(a);
                    this.checkoutService.unSetShippingMethod();
                });
            }
            else {
                this.getUserAddresses().subscribe(a => {
                    currentUser.addresses = a;
                    this.userAddressesSource.next(currentUser.addresses);
                });
            }
            return newAddress;
        }),catchError(error => this.errorService.handleAsDefaultErrorOrderSubmit(error)))
    }

    setDafaultAddress(address: Address): Observable<Address> {
        var request = this.getAddressRequest(address);
        var addressId = address.id;
        return this.apiService.put(`/user/${addressId}/setDefault`, request).pipe(map(data => {
            var newAddress = new Address(data);
            var currentUser = this.stateService.currentUser.get();
            var data = JSON.parse(localStorage.getItem('newUserInfo')!);
            var userId = null
            if (data != null) {
                userId = data.newUserId;
                this.getSelectedCustomerAddresses(userId).subscribe(a => {
                    this.editableCustomer.addresses = a;
                    this.updatedSeletedCustomerAdrs(a);
                    this.checkoutService.unSetShippingMethod();
                });
            }
            else {
                this.getUserAddresses().subscribe(a => {
                    currentUser.addresses = a;
                    this.userAddressesSource.next(currentUser.addresses);
                });
            }
            return newAddress;
        }),catchError(error => this.errorService.handleAsDefaultErrorOrderSubmit(error)))
    }

    getAddressRequest(address: any): string {
        var request: any = {}
        request.street1 = address.street1;
        if (address.street2) request.street2 = address.street2;
        request.city = address.city;
        request.zipCode = address.zipCode;
        //if (address.country.code == 'US' || address.country.code == 'CA') { 
        if (address.state.code != '') request.state = address.state.code;
        //}
        if (address.country.code) request.country = address.country.code;
        request.isDefault = address.isDefault;
        if (address.phoneNumber) request.phoneNumber = address.phoneNumber;

        return request;
    }

    getAddressRequestForSelectedCustomer(address: any): string {
        var request: any = {}
        var data = JSON.parse(localStorage.getItem('newUserInfo')!);
        if (data != null) {
            request.userId = data.newUserId;
        }
        request.street1 = address.street;
        if (address.street2) request.street2 = address.street2;
        request.city = address.city;
        request.zipCode = address.zipCode;
        //if (address.country.code == 'US' || address.country.code == 'CA') { 
        if (address.state.code != '') request.state = address.state.code;
        //}
        if (address.country.code) request.country = address.country.code;
        request.isDefault = address.isDefault;
        if (address.phoneNumber) request.phoneNumber = address.phoneNumber;

        return request;
    }

    updateProfile(profile: any): Observable<any> {        
        var request: any = {};
        profile.phoneNumber && (request.phoneNumber = profile.phoneNumber);
        profile.preferences.useFedexAccount != null && (request.useFedexAccount = profile.preferences.useFedexAccount);
        profile.preferences.currentFedexAccount && (request.currentFedexAccount = profile.preferences.currentFedexAccount);
        profile.preferences.encapsulationRequested != null && (request.encapsulationRequested = profile.preferences.encapsulationRequested);
        profile.preferences.fedexAccountPostalCode != null && (request.fedexAccountPostalCode = profile.preferences.fedexAccountPostalCode);
        profile.preferences.mktgOptInSGCProductsPricing != null && (request.mktgOptInSGCProductsPricing = profile.preferences.mktgOptInSGCProductsPricing);
        profile.preferences.mktgOptInSGCMarketing != null && (request.mktgOptInSGCMarketing = profile.preferences.mktgOptInSGCMarketing);
        profile.preferences.mktgOptIn3PartyMarketing != null && (request.mktgOptIn3PartyMarketing = profile.preferences.mktgOptIn3PartyMarketing);
        profile.preferences.custReqAcctDelete != null && (request.custReqAcctDelete = profile.preferences.custReqAcctDelete);
        request.custReqAcctDelete == true ? request.CustReqAcctDeleteOn = new Date() : request.CustReqAcctDeleteOn = null;
        profile.preferences.myCardsAddAfterShipped != null && (request.myCardsAddAfterShipped = profile.preferences.myCardsAddAfterShipped);
        profile.name && (request.name = profile.name);

        return this.apiService.patch(`/user`, request).pipe(map(data => {
            var currentUser = this.stateService.currentUser.get();
            data.phoneNumber && (currentUser.phoneNumber = data.phoneNumber);
            data.useFedexAccount != null && (currentUser.preferences.useFedexAccount = data.useFedexAccount);
            data.currentFedexAccount && (currentUser.preferences.currentFedexAccount = data.currentFedexAccount);
            data.encapsulationRequested && (currentUser.preferences.encapsulationRequested = data.encapsulationRequested);
            data.name && (currentUser.name = data.name);
            data.fedexAccountPostalCode && (currentUser.preferences.fedexAccountPostalCode = data.fedexAccountPostalCode);
            
            data.mktgOptInSGCProductsPricing != null && (currentUser.preferences.mktgOptInSGCProductsPricing = data.mktgOptInSGCProductsPricing);
            data.mktgOptInSGCMarketing != null && (currentUser.preferences.mktgOptInSGCMarketing = data.mktgOptInSGCMarketing);
            data.mktgOptIn3PartyMarketing != null && (currentUser.preferences.mktgOptIn3PartyMarketing = data.mktgOptIn3PartyMarketing);
            data.custReqAcctDelete != null && (currentUser.preferences.custReqAcctDelete = data.custReqAcctDelete);
            data.myCardsAddAfterShipped != null && (currentUser.preferences.myCardsAddAfterShipped = data.myCardsAddAfterShipped);
            currentUser.saveInProgress = false;

            this.userProfileSoure.next(currentUser);
            console.log(currentUser.preferences.currentFedexAccount);
            if(localStorage.getItem('useFed') == 'false') {
                localStorage.removeItem("fedAcc")
            }
            else {
                localStorage.setItem('fedAcc',currentUser.preferences.currentFedexAccount)
            }
        }),catchError(error => this.errorService.handleAsDefaultErrorOrderSubmit(error)))
    }

    updateEmail(profile: User): Observable<any> {
        var request: any = {};
        profile.email && (request.email = profile.email);

        return this.apiService.patch(`/user/email`, request).pipe(map(data => {
            var currentUser = this.stateService.currentUser.get();
            data.email && (currentUser.email = data.email);
            this.userProfileSoure.next(currentUser);
        }),catchError(error => this.errorService.handleAsDefaultErrorOrderSubmit(error)))
    }


    recoverUsername(email: string): Observable<any> {
        var request: any = { email: email };
        return this.apiService.post(`/user/recover-username`, request).pipe(catchError(error => this.errorService.handleAsDefaultErrorOrderSubmit(error)))
    }

    setPreferenceOptInSGCProductsPricing(MktgOptInSGCProductsPricing: boolean, user: User): Observable<any> {
        return this.apiService.put(`/user/${MktgOptInSGCProductsPricing}/setPreferenceOptInSGCProductsPricing`, { Id: user.id })
            .pipe(catchError((error:any) => this.errorService.handleAsDefaultErrorOrderSubmit(error)))
    }

    setPreferenceOptInSGCMarketing(MktgOptInSGCMarketing: boolean, user: User): Observable<any> {
        return this.apiService.put(`/user/${MktgOptInSGCMarketing}/setPreferenceOptInSGCMarketing`, { Id: user.id })
            .pipe(catchError((error:any) => this.errorService.handleAsDefaultErrorOrderSubmit(error)))
    }

    setPreferenceOptIn3PartyMarketing(MktgOptIn3PartyMarketing: boolean, user: User): Observable<any> {
        return this.apiService.put(`/user/${MktgOptIn3PartyMarketing}/setPreferenceOptIn3PartyMarketing`, { Id: user.id })
            .pipe(catchError((error:any) => this.errorService.handleAsDefaultErrorOrderSubmit(error)))
    }

    useCustReqAcctDelete(CustReqAcctDelete: boolean, user: User): Observable<any> {
        return this.apiService.put(`/user/${CustReqAcctDelete}/useCustReqAcctDelete`, { Id: user.id })
            .pipe(catchError((error:any) => this.errorService.handleAsDefaultErrorOrderSubmit(error)))
    }

    DeleteAccount(user: User): Observable<any> {
        return this.apiService.put(`/user/DeleteAccount`, { Id: user.id })
            .pipe(catchError((error:any) => this.errorService.handleAsDefaultErrorOrderSubmit(error)))
    }

    KeepAccount(user: User): Observable<any> {
        return this.apiService.put(`/user/KeepAccount`, { Id: user.id })
            .pipe(catchError((error:any) => this.errorService.handleAsDefaultErrorOrderSubmit(error)))
    }

    getCustomerByName(request: any): Observable<Array<Customer>> {
        // const options = {
        //     params: []
        // };
        const options: { params: { key: string; value: any; }[] } = {
            params: []
          };
        if (request && request.limit) {options.params.push({ key: 'limit', value: request.limit });}
        if (request && request.offset) options.params.push({ key: 'offset', value: request.offset });
        if (request && request.filter && request.filter.query) options.params.push({ key: 'name', value: request.filter.query });
        return this.apiService.get(`/customers`, options).pipe(map(data => _.map(data.items, item => new Customer(item)))
            ,catchError((error:any) => this.errorService.handleAsDefaultErrorOrderSubmit(error)))
    }

    private updatedCustomerSource = new BehaviorSubject<Customer>(null!);
    updatedCustomer$ = this.updatedCustomerSource.asObservable();

    updatedCustomer(customer: Customer) {
        this.updatedCustomerSource.next(customer);
        this.updatedCustomerSource.next(null!);
    }

    updateCustomer(request: any): Observable<Customer> {
        const customerId = request.user.id;

        return this.apiService.patch(`/customers/${customerId}`, request).pipe(map(data => new Customer(data))
            ,catchError((error:any) => this.errorService.handleAsDefaultErrorOrderSubmit(error)))
    }

    private addressSubject: BehaviorSubject<Address> = new BehaviorSubject<Address>(null!);

    registerAddress(address: Address) {
        this.addressSubject.next(address);
        this.addressSubject.next(null!);
    }

    getAddressSubject(): BehaviorSubject<Address> {
        return this.addressSubject;
    }

    getCurrentUserProfile(): Observable<any> {
        return this.apiService.get('/user/userProfile').pipe(map((data:any) => {
            var user = new User(data);
            this.userOrderInfoSource.next(data.orders);
            this.userAddressesSource.next(user.addresses);
            this.userProfileSoure.next(user);
            return data;
        }),catchError((error:any) => this.errorService.handleAsDefaultErrorOrderSubmit(error)))
    }
}
