import {
  Component,
  Input,
  OnInit,
  Compiler,
  ViewChild,
  ElementRef,
  OnChanges,
} from '@angular/core';
import { CardOrderItem, Order, OrderType } from '../../../orders/models/order';
import { Animations } from '../../../common/animations/animations';
import { CheckoutService } from '../../services/checkout.service';
import { ModalService } from '../../../common/services/modal.service';
import { OrderService } from '../../../orders/services/order.service';
import * as _ from 'lodash';
import { FormControl, Validators } from '@angular/forms';
import { CardGradingValues } from '../../../orders/models/order';
// import { debug } from "util";
import { Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
// import { viewClassName } from "@angular/compiler";

@Component({
  selector: 'added-card-item',
  templateUrl: './added-card-item.component.html',
  styleUrls: ['../../checkout.component.sass'],
  animations: [Animations.expand],
})
export class AddedCardItem implements OnInit {
  // certificationCodePattern = "^-?[0-9]\\d*(\\-\\d{1,4})?$";
  certificationCodePattern = '^(SGC\\s*:\\s*|SGC\\s*)?(\\d{7,8}(-\\d{3,4})?)$';
  newItemsStepStateMessage: string = 'Required information missing';
  optionscard: any;
  @ViewChild('certificationCodeInput') valuedCertificationCode!: ElementRef;
  @ViewChild('minimumRequestedGrade') minimumRequestedGrade!: ElementRef;
  @ViewChild('editInput')
  valueDeclare!: ElementRef;
  subject = new BehaviorSubject<string>(this.newItemsStepStateMessage);
  currentMessage = this.subject.asObservable();
  selectedServiceValue: string = '';
  constructor(
    private _compiler: Compiler,
    private checkoutService: CheckoutService,
    private modalService: ModalService,
    private orderService: OrderService
  ) {}

  @Input() item = new CardOrderItem();
  @Input() order = new Order();
  @Input() options: any;
  @Input() index!: number;

  canSaveButtondefault: boolean = false;
  canSaveButtoncrossover: boolean = false;
  canSaveButtonpreGradeEncapsulation: boolean = false;
  canSaveButtonreview: boolean = false;
  canSaveButtonreholder: boolean = false;

  itemInfo: boolean = false;
  oldDeclaredValue!: number;
  valueUpdated!: boolean;
  isSubmitting = false;
  isCardOptionSubmitting = false;
  declaredValue!: number;
  declarecaluemessage: string = 'Please Enter Declared Value';

  OldSelectCardOptions!: string;
  OldCrossover!: string;
  OldReview!: string;
  OldReholder!: string;
  OldOnlyA!: string;
  OldcertificationCode!: string;
  OldminimumRequestedGrade!: string;
  oldOversized: any;
  OldPreGradeEncapsulation!: string;

  tooltips = {
    oversized: '<p>If your card is larger than 3.5 x 5.5 inches, check this box for the correct pricing and shipping. If items are oversized and this box is not checked, you will be upcharged.</p>',
    crossover: '<p>Crossovers applies only to cards not currently in SGC holders. When entering the Cert Code, please type PSA, BGS, etc. and then the certification code on the label.</p>',
    preGradeEncapsulation: '<p>Pre-Grade Encapsulation</p>',
    review: '<p>Reviewing applies only to cards currently in SGC holders. If a card receives a higher grade that changes the declared value to a higher pricing tier, you will be upcharged.</p>',
    reholder: '<p>If the card does not get a bump in grade, your card will be reholdered with a new label.</p>',
    reholder2nd: '<p>Reholdering applies only to cards currently in SGC holders. If your holder is completely broken or tampered with, you will be upcharged to a full grading fee.</p>',
    onlyA: '<p>If checked, your item will not receive a numeric grade. If authentic, it will receive a grade of “A” Authentic.</p>'
  }

  grades = new Array<any>();
  orderType = new OrderType();

  SelectCardOptions: FormControl = new FormControl('', Validators.required);
  //ADDITION

  toggleInfo() {
    this.itemInfo = !this.itemInfo;
  }

  encapsulationSelected(event: any) {
    this.item.settings.encapsulationRequested = event.target.checked;
    //this.item.data.encapsulationOnly = event.target.checked;
    if (event.target.checked == true) {
      this.item.data.minimumRequestedGrade =
        'Any grade, even "A" Authentic, if altered';
    } else if (event.target.checked == false) {
      this.item.data.minimumRequestedGrade = '';
    }
    this.updateDeclaredValue();
  }

  overSizedSelected(event: any) {
    this.item.settings.oversized = event.target.checked;
    this.checkDeclaredValue();
  }

  removeItem() {
    if (this.declaredValue > 0) {
      this.options.callbacks.removeItem(this.item);
      this.checkDeclaredValue();
    } else {
      this.options.callbacks.removeItem(this.item);
      this.checkDeclaredValue();
    }
  }

  updateDeclaredValue() {
    if (this.SelectCardOptions.value == '') {
      this.item.data.crossover = false;
      this.item.data.review = false;
      this.item.data.reholder = false;
      this.item.data.pregradeEncapsulation = false;
      this.canSaveButtondefault = false;
    }
    if (this.SelectCardOptions.value == 'Crossover') {
      this.canSaveButtoncrossover = false;
    }
    if (this.SelectCardOptions.value == 'Review') {
      this.canSaveButtonreview = false;
    }
    if (this.SelectCardOptions.value == 'Reholder') {
      this.canSaveButtonreholder = false;
    }
    if (this.SelectCardOptions.value == 'Pre-Grade Encapsulation') {
      this.canSaveButtonpreGradeEncapsulation = false;
    }

    this.oldOversized = this.item.settings.oversized;
    // if (!this.canSubmitValue()) return;
    this.isSubmitting = true;

    this.newItemsStepStateMessage = '';
    this.checkoutService.newItemsStepStateMessage(
      this.newItemsStepStateMessage
    );
    // if(this.declaredValue=NaN){
    //     this.item.setDeclaredValue(0);
    // }
    this.item.setDeclaredValue(this.declaredValue);
    // console.log(this.declaredValue);

    this.checkoutService
      .updateOrderItemForNewPlan(this.item)
      .subscribe((orderItem) => {
        this.isSubmitting = false;
        this.oldDeclaredValue = orderItem.getDeclaredValue();
        this.valueUpdated = true;
        this.item.setTotalFee(orderItem.getTotalFee()!);
        setTimeout(() => {
          this.valueUpdated = false;
        }, 5000);
      });
    //this.CanSaveButtonCheck();
  }

  getDataLabel(): string {
    if (!this.item.data) return 'Crossover or review?';
    if (this.item.data.crossover) return 'Crossover selected';
    if (this.item.data.review) return 'Review selected';
    if (this.item.data.reholder) return 'Reholder selected';
    if (this.item.data.relabel) return 'Relabel selected';
    if (this.item.data.pregradeEncapsulation)
      return 'Pre-Grade Encapsulation selected';
    if (this.item.data.encapsulationOnly) return 'Only A selected';
    return 'Crossover or review?';
  }

  canSubmitValue(): boolean {
    var itemValue = this.declaredValue;

    if (itemValue && itemValue > 0) {
      this.declarecaluemessage = '';
    } else {
      this.declarecaluemessage = 'Please Enter Declared Value';
    }

    return Boolean(itemValue && itemValue > 0);
    //var isMinimumRequestedGrade = !this.item.data.minimumRequestedGrade;
    //var isEmpty = !isMinimumRequestedGrade;

    //if (this.SelectCardOptions.value == "")
    //{
    //    return (itemValue && itemValue > 0 && itemValue != this.oldDeclaredValue) && (this.item.data.minimumRequestedGrade != this.OldminimumRequestedGrade) && !this.isSubmitting;
    //}
    //if (this.SelectCardOptions.value == "Crossover") {
    //    return (itemValue && itemValue > 0 && itemValue != this.oldDeclaredValue) && (this.item.data.crossover != this.OldCrossover && this.item.data.encapsulationOnly != this.OldOnlyA  && this.item.data.certificationCode != this.OldcertificationCode && this.item.data.minimumRequestedGrade != this.OldminimumRequestedGrade) && !this.isSubmitting;

    //}
    //if (this.SelectCardOptions.value == "Review") {
    //    return (itemValue && itemValue > 0 && itemValue != this.oldDeclaredValue) && (this.item.data.review != this.OldReview && this.item.data.reholder != this.OldReholder && this.item.data.certificationCode != this.OldcertificationCode && this.item.data.minimumRequestedGrade != this.OldminimumRequestedGrade) && !this.isSubmitting;

    //}
    //if (this.SelectCardOptions.value == "Reholder") {
    //    return (itemValue && itemValue > 0 && itemValue != this.oldDeclaredValue) && (this.item.data.reholder != this.OldReholder && this.item.data.certificationCode != this.OldcertificationCode) && !this.isSubmitting;
    //}
  }

  ngOnInit(): void {
    
    this.checkoutService.error.subscribe((data) => {
      // console.log(data);
      if (data) {
        if (!this.declaredValue) {
          this.valueDeclare.nativeElement.focus();
        }
        // if (
        //     !this.item.data.certificationCode ||
        //     this.item.data.certificationCode == ""
        // ) {
        //     this.valuedCertificationCode.nativeElement.focus();
        // }
        // if (this.item.data.minimumRequestedGrade == "") {
        //     this.minimumRequestedGrade.nativeElement.focus();
        // }
        if (this.item.data.crossover == true) {
          if (this.item.data.minimumRequestedGrade == '') {
            this.minimumRequestedGrade.nativeElement.focus();
          }
          if (
            this.item.data.certificationCode == '' ||
            this.item.data.certificationCode < 1000
          ) {
            this.valuedCertificationCode.nativeElement.focus();
          }
        }
        if (this.item.data.review == true) {
          if (this.item.data.minimumRequestedGrade == '') {
            this.minimumRequestedGrade.nativeElement.focus();
          }
          if (
            this.item.data.certificationCode == '' ||
            !this.item.data.certificationCode.match(
              this.certificationCodePattern
            )
          ) {
            this.valuedCertificationCode.nativeElement.focus();
          }
        }
        if (this.item.data.reholder == true) {
          if (
            this.item.data.certificationCode == '' ||
            !this.item.data.certificationCode.match(
              this.certificationCodePattern
            )
          ) {
            this.valuedCertificationCode.nativeElement.focus();
          }
        }
      }
    });

    //this._compiler.clearCache();
    this.oldDeclaredValue = this.item.getDeclaredValue();
    this.declaredValue = this.item.getDeclaredValue();

    if (this.declaredValue > 0) {
      if (
        this.item.orderType!.code == 'card-pre-grade' &&
        this.declaredValue > 2499
      ) {
        this.declarecaluemessage = 'Declared Value Must Be Less Than 2500';
        this.newItemsStepStateMessage = 'Required information missing';
        this.checkoutService.newItemsStepStateMessage(
          this.newItemsStepStateMessage
        );
      } else {
        this.declarecaluemessage = '';
      }
    } else {
      this.declarecaluemessage = 'Declared Value Required';
      this.newItemsStepStateMessage = 'Required information missing';
      this.checkoutService.newItemsStepStateMessage(
        this.newItemsStepStateMessage
      );
    }

    this.grades = CardGradingValues;
    this.orderType = this.item.orderType!;

    this.OldCrossover = this.item.data.crossover;
    this.OldReview = this.item.data.review;
    this.OldReholder = this.item.data.reholder;
    this.OldPreGradeEncapsulation = this.item.data.pregradeEncapsulation;
    this.OldOnlyA = this.item.data.encapsulationOnly;
    this.OldcertificationCode = this.item.data.certificationCode;
    this.OldminimumRequestedGrade = this.item.data.minimumRequestedGrade;
    this.oldOversized = this.item.settings.oversized;
    // console.log('crossoverddddddddddddddddddddddddd', this.item.data);
    if (
      this.item.data.crossover == false &&
      this.item.data.review == false &&
      this.item.data.reholder == false &&
      this.item.data.pregradeEncapsulation == false
    ) {
      this.OldSelectCardOptions = '';
      this.changeCardServices('');
    } else if (this.item.data.crossover == true) {
      this.OldSelectCardOptions = 'Crossover';
      this.changeCardServices('Crossover');
      this.updateDeclaredValue();
    } else if (this.item.data.review == true) {
      this.OldSelectCardOptions = 'Review';
      this.changeCardServices('Review');
      this.updateDeclaredValue();
    } else if (this.item.data.reholder == true) {
      this.OldSelectCardOptions = 'Reholder';
      this.changeCardServices('Reholder');
      this.updateDeclaredValue();
    } else if (this.item.data.pregradeEncapsulation == true) {
      this.OldSelectCardOptions = 'Pre-Grade Encapsulation';
      this.changeCardServices('Pre-Grade Encapsulation');
      this.updateDeclaredValue();
    }
    this._compiler.clearCache();
  }

  setCardOptions() {
    this.modalService.open('set-card-options', this.item);
  }

  getCardName() {
    return this.item && this.item.getCardName();
  }

  save() {
    if (this.SelectCardOptions.value == '') {
      this.item.data.crossover = false;
      this.item.data.review = false;
      this.item.data.reholder = false;
    }
    this.isCardOptionSubmitting = true;
    this.checkoutService
      .updateOrderItemForNewPlan(this.item)
      .subscribe((orderItem) => {
        this.isSubmitting = false;
        this.oldDeclaredValue = orderItem.getDeclaredValue();
        this.valueUpdated = true;
        this.item.setTotalFee(orderItem.getTotalFee()!);
        setTimeout(() => {
          this.valueUpdated = false;
        }, 5000);
      });
  }

  CanSaveButtonCheck() {
    //if ((this.OldSelectCardOptions = this.SelectCardOptions.value)
    //    && (this.OldCrossover = this.item.data.crossover)
    //    && (this.OldReview = this.item.data.review)
    //    && (this.OldReholder = this.item.data.reholder)
    //    && (this.OldOnlyA = this.item.data.encapsulationOnly)
    //    && (this.OldcertificationCode = this.item.data.certificationCode)
    //    && (this.OldminimumRequestedGrade = this.item.data.minimumRequestedGrade)) {
    //    this.canSaveButtonreview == true;
    //}
    //else {
    //    this.canSaveButtonreview == false;
    //}
  }

  canSave(): boolean {
    var isCrossoverSelected = this.item.data.crossover;
    var isReviewSelected = this.item.data.review;
    var isReholderSelected = this.item.data.reholder;
    var isRelabelSelected = this.item.data.relabel;
    var isCertificationCodeSelected = !!this.item.data.certificationCode;
    var isMinimumRequestedGrade = !!this.item.data.minimumRequestedGrade;
    var isEncapsulationOnly = !!this.item.data.encapsulationOnly;

    var crossover =
      isCrossoverSelected &&
      isCertificationCodeSelected &&
      isMinimumRequestedGrade;
    var review = isReviewSelected && isCertificationCodeSelected;
    var reholder = isReholderSelected && isCertificationCodeSelected;
    var relabel = isRelabelSelected && isCertificationCodeSelected;

    var isEmpty =
      !isCrossoverSelected &&
      !isReviewSelected &&
      !isRelabelSelected &&
      !isReholderSelected &&
      !isCertificationCodeSelected &&
      !isMinimumRequestedGrade;

    return (
      crossover ||
      review ||
      reholder ||
      relabel ||
      isEncapsulationOnly ||
      isEmpty
    );
  }

  setEncapsulationOnly(selection: boolean) {
    //selection && (this.item.settings.encapsulationRequested = selection);
    this.item.data.encapsulationOnly = selection;
  }

  CardServices: any = ['Crossover', 'Review', 'Reholder'];
  PokemonCardServices: any = ['Crossover', 'Reholder'];

  CrossoverDisabled!: boolean;
  ReviewDisabled!: boolean;
  ReholderDisabled!: boolean;
  PreGradeEncapsulationDisabled!: boolean;

  changeCardServices(SeletedValue: any) {
    if (SeletedValue == '') {
      this.SelectCardOptions.setValue('');
      if (this.OldSelectCardOptions == this.SelectCardOptions.value) {
        this.item.data.crossover = this.OldCrossover;
        this.item.data.review = this.OldReview;
        this.item.data.reholder = this.OldReholder;
        this.item.data.pregradeEncapsulation = this.OldPreGradeEncapsulation;
        this.item.data.encapsulationOnly = this.OldOnlyA;
        this.item.data.certificationCode = this.OldcertificationCode;
        this.item.data.minimumRequestedGrade = this.OldminimumRequestedGrade;
        this.item.settings.oversized = this.oldOversized;

        if (
          this.item.data.minimumRequestedGrade != '' &&
          this.item.data.minimumRequestedGrade != 'null' &&
          this.item.data.minimumRequestedGrade != null
        ) {
          this.item.data.minimumRequestedGrade;
        } else {
          this.item.data.minimumRequestedGrade = '';
        }
        if (this.declaredValue > 0) {
          this.updateDeclaredValue();
        }
      } else {
        this.item.data.minimumRequestedGrade = '';
        if (this.declaredValue > 0) {
          this.updateDeclaredValue();
        }
      }
    } else if (SeletedValue == 'Crossover') {
      this.CrossoverDisabled = true;
      this.item.data.crossover = true;
      this.item.data.review = false;
      this.item.data.reholder = false;
      this.item.data.pregradeEncapsulation = false;
      this.SelectCardOptions.setValue('Crossover');
      if (this.OldSelectCardOptions == this.SelectCardOptions.value) {
        this.item.data.crossover = this.OldCrossover;
        this.item.data.review = this.OldReview;
        this.item.data.reholder = this.OldReholder;
        this.item.data.encapsulationOnly = this.OldOnlyA;
        this.item.data.certificationCode = this.OldcertificationCode;
        this.item.data.minimumRequestedGrade = this.OldminimumRequestedGrade;
        this.item.settings.oversized = this.oldOversized;

        if (
          this.item.data.certificationCode != '' &&
          SeletedValue == 'Crossover' &&
          this.item.data.crossover == true
        ) {
          this.item.data.certificationCode;
        } else {
          this.item.data.certificationCode = '';
          this.newItemsStepStateMessage = 'Required information missing';
          this.checkoutService.newItemsStepStateMessage(
            this.newItemsStepStateMessage
          );
        }

        if (
          this.item.data.minimumRequestedGrade != '' &&
          SeletedValue == 'Crossover' &&
          this.item.data.crossover == true
        ) {
          this.item.data.minimumRequestedGrade;
        } else {
          this.item.data.minimumRequestedGrade = '';
          this.newItemsStepStateMessage = 'Required information missing';
          this.checkoutService.newItemsStepStateMessage(
            this.newItemsStepStateMessage
          );
        }

        if (this.item.data.encapsulationOnly == true) {
          this.item.data.encapsulationOnly = true;
        } else {
          this.item.data.encapsulationOnly = false;
        }
      } else {
        this.newItemsStepStateMessage = 'Required information missing';
        this.checkoutService.newItemsStepStateMessage(
          this.newItemsStepStateMessage
        );

        this.item.data.certificationCode = '';
        this.item.data.minimumRequestedGrade = '';
        this.item.data.encapsulationOnly = false;
      }
      this.checkoutService.Errorcount();
    } else if (SeletedValue == 'Review') {
      this.ReviewDisabled = true;
      this.SelectCardOptions.setValue('Review');
      this.item.data.review = true;
      this.item.data.crossover = false;
      this.item.data.pregradeEncapsulation = false;

      if (this.OldSelectCardOptions == this.SelectCardOptions.value) {
        this.item.data.crossover = this.OldCrossover;
        this.item.data.review = this.OldReview;
        this.item.data.reholder = this.OldReholder;
        this.item.data.encapsulationOnly = this.OldOnlyA;
        this.item.data.certificationCode = this.OldcertificationCode;
        this.item.data.minimumRequestedGrade = this.OldminimumRequestedGrade;
        this.item.settings.oversized = this.oldOversized;

        if (
          this.item.data.certificationCode != '' &&
          SeletedValue == 'Review' &&
          this.item.data.review == true
        ) {
          this.item.data.certificationCode;
        } else {
          this.item.data.certificationCode = '';
          this.newItemsStepStateMessage = 'Required information missing';
          this.checkoutService.newItemsStepStateMessage(
            this.newItemsStepStateMessage
          );
        }

        if (
          this.item.data.minimumRequestedGrade != '' &&
          SeletedValue == 'Review' &&
          this.item.data.review == true
        ) {
          this.item.data.minimumRequestedGrade;
        } else {
          this.item.data.minimumRequestedGrade = '';
          this.newItemsStepStateMessage = 'Required information missing';
          this.checkoutService.newItemsStepStateMessage(
            this.newItemsStepStateMessage
          );
        }

        if (this.item.data.reholder == true && this.item.data.review == true) {
          this.item.data.reholder = true;
        } else {
          this.item.data.reholder = false;
        }

        if (this.item.data.encapsulationOnly == true) {
          this.item.data.encapsulationOnly = true;
        } else {
          this.item.data.encapsulationOnly = false;
        }
      } else {
        this.newItemsStepStateMessage = 'Required information missing';
        this.checkoutService.newItemsStepStateMessage(
          this.newItemsStepStateMessage
        );
        this.item.data.certificationCode = '';
        this.item.data.minimumRequestedGrade = '';
        this.item.data.reholder = false;
        this.item.data.encapsulationOnly = false;
      }
      this.checkoutService.Errorcount();
    } else if (SeletedValue == 'Reholder') {
      this.ReholderDisabled = true;
      this.item.data.review = false;
      this.item.data.reholder = true;
      this.item.data.crossover = false;
      this.item.data.pregradeEncapsulation = false;
      this.SelectCardOptions.setValue('Reholder');

      if (this.OldSelectCardOptions == this.SelectCardOptions.value) {
        this.item.data.crossover = this.OldCrossover;
        this.item.data.review = this.OldReview;
        this.item.data.reholder = this.OldReholder;
        this.item.data.encapsulationOnly = this.OldOnlyA;
        this.item.data.certificationCode = this.OldcertificationCode;
        this.item.data.minimumRequestedGrade = this.OldminimumRequestedGrade;
        this.item.settings.oversized = this.oldOversized;

        if (
          this.item.data.certificationCode != '' &&
          SeletedValue == 'Reholder' &&
          this.item.data.reholder == true
        ) {
          this.item.data.certificationCode;
        } else {
          this.item.data.certificationCode = '';
          this.newItemsStepStateMessage = 'Required information missing';
          this.checkoutService.newItemsStepStateMessage(
            this.newItemsStepStateMessage
          );
        }

        if (this.item.data.encapsulationOnly == true) {
          this.item.data.encapsulationOnly = true;
        } else {
          this.item.data.encapsulationOnly = false;
        }

        if (
          this.item.data.certificationCode != '' &&
          SeletedValue == 'Reholder' &&
          this.item.data.reholder == true
        ) {
          if (this.declaredValue > 0) {
            this.updateDeclaredValue();
          }
        }
      } else {
        this.item.data.certificationCode = '';
        this.newItemsStepStateMessage = 'Required information missing';
        this.checkoutService.newItemsStepStateMessage(
          this.newItemsStepStateMessage
        );
        this.item.data.encapsulationOnly = false;
      }
      this.checkoutService.Errorcount();
    } else if (SeletedValue == 'Pre-Grade Encapsulation') {
      this.PreGradeEncapsulationDisabled = true;
      this.item.data.pregradeEncapsulation = true;
      this.item.data.crossover = false;
      this.item.data.review = false;
      this.item.data.reholder = false;
      this.SelectCardOptions.setValue('Pre-Grade Encapsulation');
      if (this.OldSelectCardOptions == this.SelectCardOptions.value) {
        this.item.data.pregradeEncapsulation = this.OldPreGradeEncapsulation;
        this.item.data.crossover = this.OldCrossover;
        this.item.data.review = this.OldReview;
        this.item.data.reholder = this.OldReholder;
        this.item.data.encapsulationOnly = this.OldOnlyA;
        this.item.data.certificationCode = this.OldcertificationCode;
        this.item.data.minimumRequestedGrade = this.OldminimumRequestedGrade;
        this.item.settings.oversized = this.oldOversized;

        if (
          this.item.data.certificationCode != '' &&
          SeletedValue == 'Pre-Grade Encapsulation' &&
          this.item.data.pregradeEncapsulation == true
        ) {
          this.item.data.certificationCode;
        } else {
          this.item.data.certificationCode = '';
          this.newItemsStepStateMessage = 'Required information missing';
          this.checkoutService.newItemsStepStateMessage(
            this.newItemsStepStateMessage
          );
        }

        if (
          this.item.data.minimumRequestedGrade != '' &&
          SeletedValue == 'Pre-Grade Encapsulation' &&
          this.item.data.pregradeEncapsulation == true
        ) {
          this.item.data.minimumRequestedGrade;
        } else {
          this.item.data.minimumRequestedGrade = '';
          this.newItemsStepStateMessage = 'Required information missing';
          this.checkoutService.newItemsStepStateMessage(
            this.newItemsStepStateMessage
          );
        }

        if (this.item.data.encapsulationOnly == true) {
          this.item.data.encapsulationOnly = true;
        } else {
          this.item.data.encapsulationOnly = false;
        }

        if (
          this.item.data.certificationCode != '' &&
          SeletedValue == 'Pre-Grade Encapsulation' &&
          this.item.data.pregradeEncapsulation == true
        ) {
          if (this.declaredValue > 0) {
            this.updateDeclaredValue();
          }
        }
      } else {
        this.newItemsStepStateMessage = 'Required information missing';
        this.checkoutService.newItemsStepStateMessage(
          this.newItemsStepStateMessage
        );

        this.item.data.certificationCode = '';
        this.item.data.minimumRequestedGrade = '';
        this.item.data.encapsulationOnly = false;
      }
      this.checkoutService.Errorcount();
    }
  }

  crossoverMRG(value: any | null) {
    if (this.SelectCardOptions.value == 'Crossover') {
      if (
        value == this.OldminimumRequestedGrade &&
        this.item.data.certificationCode == this.OldcertificationCode &&
        value != '' &&
        this.item.data.certificationCode != '' &&
        this.declaredValue == this.oldDeclaredValue &&
        this.declaredValue > 0
      ) {
        this.declarecaluemessage = '';
        this.canSaveButtoncrossover = false;

        this.newItemsStepStateMessage = '';
        this.checkoutService.newItemsStepStateMessage(
          this.newItemsStepStateMessage
        );
      } else {
        if (
          this.declaredValue > 0 &&
          this.item.data.certificationCode != '' &&
          value != ''
        ) {
          this.declarecaluemessage = '';
          this.canSaveButtoncrossover = true;
        } else {
          this.newItemsStepStateMessage = 'Required information missing';
          this.checkoutService.newItemsStepStateMessage(
            this.newItemsStepStateMessage
          );
          this.checkoutService.Errorcount();
          this.canSaveButtoncrossover = false;
        }
      }
      //let Buttoncrossover = document.getElementById("Buttoncrossover");
      if (this.canSaveButtoncrossover == true) {
        this.updateDeclaredValue();

        //this.newItemsStepStateMessage = ""
        //this.checkoutService.newItemsStepStateMessage(this.newItemsStepStateMessage);
      }
    }
  }

  crossoverCERC() {
    if (this.SelectCardOptions.value == 'Crossover') {
      if (
        this.item.data.minimumRequestedGrade == this.OldminimumRequestedGrade &&
        this.item.data.certificationCode == this.OldcertificationCode &&
        this.item.data.minimumRequestedGrade != '' &&
        this.item.data.certificationCode != '' &&
        this.declaredValue == this.oldDeclaredValue &&
        this.declaredValue > 0
      ) {
        this.declarecaluemessage = '';
        this.canSaveButtoncrossover = false;

        this.newItemsStepStateMessage = '';
        this.checkoutService.newItemsStepStateMessage(
          this.newItemsStepStateMessage
        );
      } else {
        if (
          this.declaredValue > 0 &&
          this.item.data.certificationCode != '' &&
          this.item.data.minimumRequestedGrade != '' &&
          (this.item.data.certificationCode >= 1000
            || ((this.item.data.certificationCode.includes("PSA")))
            || ((this.item.data.certificationCode.includes("BGS"))))
        ) {
          this.declarecaluemessage = '';
          this.canSaveButtoncrossover = true;
        } else {
          this.canSaveButtoncrossover = false;
          this.checkoutService.Errorcount();
          this.newItemsStepStateMessage = 'Required information missing';
          this.checkoutService.newItemsStepStateMessage(
            this.newItemsStepStateMessage
          );
        }
      }

      //let Buttoncrossover2 = document.getElementById("Buttoncrossover");
      if (this.canSaveButtoncrossover == true) {
        this.updateDeclaredValue();
        //this.newItemsStepStateMessage = ""
        //this.checkoutService.newItemsStepStateMessage(this.newItemsStepStateMessage);
      }
    }
  }

  preGradeEncapsulationMRG() {
    if (this.SelectCardOptions.value == 'Pre-Grade Encapsulation') {
      if (this.declaredValue > 0 && this.item.data.certificationCode != '') {
        this.declarecaluemessage = '';
        this.canSaveButtonpreGradeEncapsulation = true;
      } else {
        this.newItemsStepStateMessage = 'Required information missing';
        this.checkoutService.newItemsStepStateMessage(
          this.newItemsStepStateMessage
        );

        this.canSaveButtonpreGradeEncapsulation = false;
      }
      if (this.canSaveButtonpreGradeEncapsulation == true) {
        this.updateDeclaredValue();
      }
    }
  }

  preGradeEncapsulationCERC() {
    if (this.SelectCardOptions.value == 'Pre-Grade Encapsulation') {
      if (
        this.item.data.certificationCode == this.OldcertificationCode &&
        this.item.data.certificationCode != '' &&
        this.declaredValue == this.oldDeclaredValue &&
        this.declaredValue > 0
      ) {
        this.declarecaluemessage = '';
        this.canSaveButtonpreGradeEncapsulation = false;

        this.newItemsStepStateMessage = '';
        this.checkoutService.newItemsStepStateMessage(
          this.newItemsStepStateMessage
        );
      } else {
        if (this.declaredValue > 0 && this.item.data.certificationCode != '') {
          this.declarecaluemessage = '';
          this.canSaveButtonpreGradeEncapsulation = true;
        } else {
          this.canSaveButtonpreGradeEncapsulation = false;

          this.newItemsStepStateMessage = 'Required information missing';
          this.checkoutService.newItemsStepStateMessage(
            this.newItemsStepStateMessage
          );
        }
      }

      if (this.canSaveButtonpreGradeEncapsulation == true) {
        this.updateDeclaredValue();
      }
    }
  }

  reviewMRG(value: any) {
    if (this.SelectCardOptions.value == 'Review') {
      if (
        value == this.OldminimumRequestedGrade &&
        this.item.data.certificationCode == this.OldcertificationCode &&
        value != '' &&
        this.item.data.certificationCode != '' &&
        this.declaredValue == this.oldDeclaredValue &&
        this.declaredValue > 0
      ) {
        this.declarecaluemessage = '';
        this.canSaveButtonreview = false;

        this.newItemsStepStateMessage = '';
        this.checkoutService.newItemsStepStateMessage(
          this.newItemsStepStateMessage
        );
      } else {
        if (
          this.declaredValue > 0 &&
          this.item.data.certificationCode != '' &&
          value != ''
        ) {
          this.declarecaluemessage = '';
          this.canSaveButtonreview = true;
        } else {
          this.checkoutService.Errorcount();
          this.newItemsStepStateMessage = 'Required information missing';
          this.checkoutService.newItemsStepStateMessage(
            this.newItemsStepStateMessage
          );

          this.canSaveButtonreview = false;
        }
      }
      //let Buttonreview = document.getElementById("Buttonreview");
      if (this.canSaveButtonreview == true) {
        this.updateDeclaredValue();

        //this.newItemsStepStateMessage = ""
        //this.checkoutService.newItemsStepStateMessage(this.newItemsStepStateMessage);
      }
    }
  }

  reviewCERC() {
    if (this.SelectCardOptions.value == 'Review') {
      if (
        this.item.data.certificationCode == this.OldcertificationCode &&
        this.item.data.minimumRequestedGrade == this.OldminimumRequestedGrade &&
        this.item.data.minimumRequestedGrade != '' &&
        this.item.data.certificationCode != '' &&
        this.declaredValue == this.oldDeclaredValue &&
        this.declaredValue > 0
      ) {
        this.declarecaluemessage = '';
        this.canSaveButtonreview = false;

        this.newItemsStepStateMessage = '';
        this.checkoutService.newItemsStepStateMessage(
          this.newItemsStepStateMessage
        );
      } else {
        if (
          this.declaredValue > 0 &&
          this.item.data.certificationCode != '' &&
          this.item.data.minimumRequestedGrade != '' &&
          this.item.data.certificationCode.match(this.certificationCodePattern)
        ) {
          this.declarecaluemessage = '';
          this.canSaveButtonreview = true;
        } else {
          this.checkoutService.Errorcount();
          this.newItemsStepStateMessage = 'Required information missing';
          this.checkoutService.newItemsStepStateMessage(
            this.newItemsStepStateMessage
          );

          this.canSaveButtonreview = false;
        }
      }
      //let Buttonreview2 = document.getElementById("Buttonreview");
      if (this.canSaveButtonreview == true) {
        this.updateDeclaredValue();

        //this.newItemsStepStateMessage = ""
        //this.checkoutService.newItemsStepStateMessage(this.newItemsStepStateMessage);
      }
    }
  }

  reholderCERC() {
    if (this.SelectCardOptions.value == 'Reholder') {
      if (
        this.item.data.certificationCode == this.OldcertificationCode &&
        this.item.data.certificationCode != '' &&
        this.declaredValue == this.oldDeclaredValue &&
        this.declaredValue > 0
      ) {
        this.declarecaluemessage = '';
        this.canSaveButtonreholder = false;

        this.newItemsStepStateMessage = '';
        this.checkoutService.newItemsStepStateMessage(
          this.newItemsStepStateMessage
        );
      } else {
        if (
          this.declaredValue > 0 &&
          this.item.data.certificationCode != '' &&
          this.item.data.certificationCode.match(this.certificationCodePattern)
        ) {
          this.declarecaluemessage = '';
          this.canSaveButtonreholder = true;
        } else {
          this.checkoutService.Errorcount();
          this.canSaveButtonreholder = false;
          this.newItemsStepStateMessage = 'Required information missing';
          this.checkoutService.newItemsStepStateMessage(
            this.newItemsStepStateMessage
          );
        }
      }
      //let Buttonreholder = document.getElementById("Buttonreholder");
      if (this.canSaveButtonreholder == true) {
        this.updateDeclaredValue();

        //this.newItemsStepStateMessage = ""
        //this.checkoutService.newItemsStepStateMessage(this.newItemsStepStateMessage);
      }
    }
  }

  defaultMRG(value: any) {
    if (this.SelectCardOptions.value == '') {
      if (
        value == this.OldminimumRequestedGrade &&
        this.declaredValue == this.oldDeclaredValue &&
        this.declaredValue > 0
      ) {
        this.declarecaluemessage = '';
        this.canSaveButtondefault = false;

        this.newItemsStepStateMessage = '';
        this.checkoutService.newItemsStepStateMessage(
          this.newItemsStepStateMessage
        );
      } else {
        if (this.declaredValue > 0) {
          this.canSaveButtondefault = true;
          this.declarecaluemessage = '';
        } else {
          this.newItemsStepStateMessage = 'Required information missing';
          this.checkoutService.newItemsStepStateMessage(
            this.newItemsStepStateMessage
          );
          this.canSaveButtondefault = false;
          this.declarecaluemessage = 'Declared Value Required';
        }
      }
      //let Buttondefault = document.getElementById("Buttondefault");
      if (this.canSaveButtondefault == true) {
        this.updateDeclaredValue();

        //this.newItemsStepStateMessage = ""
        //this.checkoutService.newItemsStepStateMessage(this.newItemsStepStateMessage);
      }
    }
  }

  getErrorCount(): number {
    return this.checkoutService.Errorcount();
  }

  checkDeclaredValue() {
    
    // console.log(this.declaredValue);
    this.getErrorCount();
    if (this.SelectCardOptions.value == "") {
        if (
            this.item.data.minimumRequestedGrade ==
                this.OldminimumRequestedGrade &&
            this.declaredValue == this.oldDeclaredValue &&
            this.declaredValue > 0 &&
            this.oldOversized == this.item.settings.oversized
        ) {
            this.declarecaluemessage = "";
            this.canSaveButtondefault = false;

            this.newItemsStepStateMessage = "";
            this.checkoutService.newItemsStepStateMessage(
                this.newItemsStepStateMessage
            );
        } else {
            if (this.declaredValue > 0) {
                this.canSaveButtondefault = true;
                this.declarecaluemessage = "";
            } else {
                this.newItemsStepStateMessage =
                    "Required information missing";
                this.checkoutService.newItemsStepStateMessage(
                    this.newItemsStepStateMessage
                );
                if ((this.declaredValue = NaN)) {
                    this.declaredValue = 0;
                }
                this.updateDeclaredValue();
                this.canSaveButtondefault = false;
                this.declarecaluemessage = "Declared Value Required";
            }

            if (
                this.orderType?.code == "card-pre-grade" &&
                this.declaredValue > 2499
            ) {
                this.newItemsStepStateMessage =
                    "Required information missing";
                this.checkoutService.newItemsStepStateMessage(
                    this.newItemsStepStateMessage
                );
                this.canSaveButtondefault = false;
                this.declarecaluemessage =
                    "Declared Value Must Be Less Than $2,500";
            }
        }
        //let Buttondefault = document.getElementById("Buttondefault");
        if (this.canSaveButtondefault == true) {
            this.updateDeclaredValue();

            //this.newItemsStepStateMessage = ""
            //this.checkoutService.newItemsStepStateMessage(this.newItemsStepStateMessage);
        }
        if (
            isNaN(this.declaredValue) ||
            this.declaredValue <= 0 ||
            this.declaredValue == null
        ) {
            this.declarecaluemessage = "Declared Value Required";
            this.checkoutService.Errorcount();
        }
    }
    if (this.SelectCardOptions.value == "Crossover") {
        if (
            this.item.data.minimumRequestedGrade ==
                this.OldminimumRequestedGrade &&
            this.item.data.certificationCode == this.OldcertificationCode &&
            this.item.data.minimumRequestedGrade != "" &&
            this.item.data.certificationCode != "" &&
            this.declaredValue == this.oldDeclaredValue &&
            this.declaredValue > 0 &&
            this.oldOversized == this.item.settings.oversized
        ) {
            this.declarecaluemessage = "";
            this.canSaveButtoncrossover = false;
            this.updateDeclaredValue();

            this.newItemsStepStateMessage = "";
            this.checkoutService.newItemsStepStateMessage(
                this.newItemsStepStateMessage
            );
        } else {
            if (
                this.declaredValue > 0 &&
                this.item.data.certificationCode != "" &&
                this.item.data.minimumRequestedGrade != ""
            ) {
                this.declarecaluemessage = "";
                this.canSaveButtoncrossover = true;
                this.updateDeclaredValue();
            } else {
                this.canSaveButtoncrossover = false;
                this.updateDeclaredValue();
                this.newItemsStepStateMessage =
                    "Required information missing";
                this.checkoutService.newItemsStepStateMessage(
                    this.newItemsStepStateMessage
                );
            }
        }

        //let Buttoncrossover = document.getElementById("Buttoncrossover");
        // if (this.canSaveButtoncrossover == true) {
        //     this.updateDeclaredValue();

        //     //this.newItemsStepStateMessage = ""
        //     //this.checkoutService.newItemsStepStateMessage(this.newItemsStepStateMessage);
        // }
        this.updateDeclaredValue();
        if (this.updateDeclaredValue!) {
            this.declarecaluemessage = "";
        }
        if (
            isNaN(this.declaredValue) ||
            this.declaredValue <= 0 ||
            this.declaredValue == null
        ) {
            this.declarecaluemessage = "Declared Value Required";
            this.checkoutService.Errorcount();
        }
    }
    if (this.SelectCardOptions.value == "Review") {
        if (
            this.item.data.certificationCode == this.OldcertificationCode &&
            this.item.data.minimumRequestedGrade ==
                this.OldminimumRequestedGrade &&
            this.item.data.minimumRequestedGrade != "" &&
            this.item.data.certificationCode != "" &&
            this.declaredValue == this.oldDeclaredValue &&
            this.declaredValue > 0 &&
            this.oldOversized == this.item.settings.oversized
        ) {
            this.declarecaluemessage = "";
            this.canSaveButtonreview = false;

            this.newItemsStepStateMessage = "";
            this.checkoutService.newItemsStepStateMessage(
                this.newItemsStepStateMessage
            );
        } else {
            if (
                this.declaredValue > 0 &&
                this.item.data.certificationCode != "" &&
                this.item.data.minimumRequestedGrade != ""
            ) {
                this.declarecaluemessage = "";
                this.canSaveButtonreview = true;
            } else {
                this.newItemsStepStateMessage =
                    "Required information missing";
                this.checkoutService.newItemsStepStateMessage(
                    this.newItemsStepStateMessage
                );

                this.canSaveButtonreview = false;
            }
        }
        //let Buttonreview2 = document.getElementById("Buttonreview");
        // if (this.canSaveButtonreview == true) {
        //     this.updateDeclaredValue();

        //     //this.newItemsStepStateMessage = ""
        //     //this.checkoutService.newItemsStepStateMessage(this.newItemsStepStateMessage);
        // }
        this.updateDeclaredValue();
        if (this.updateDeclaredValue!) {
            this.declarecaluemessage = "";
        }
        if (
            isNaN(this.declaredValue) ||
            this.declaredValue <= 0 ||
            this.declaredValue == null
        ) {
            this.declarecaluemessage = "Declared Value Required";
            this.checkoutService.Errorcount();
        }
    }
    if (this.SelectCardOptions.value == "Reholder") {
        if (
            this.item.data.certificationCode == this.OldcertificationCode &&
            this.item.data.certificationCode != "" &&
            this.declaredValue == this.oldDeclaredValue &&
            this.declaredValue > 0 &&
            this.oldOversized == this.item.settings.oversized
        ) {
            this.declarecaluemessage = "";
            this.canSaveButtonreholder = false;

            this.newItemsStepStateMessage = "";
            this.checkoutService.newItemsStepStateMessage(
                this.newItemsStepStateMessage
            );
            this.declarecaluemessage = "Declared Value Required";
        } else {
            if (
                this.declaredValue > 0 &&
                this.item.data.certificationCode != ""
            ) {
                this.declarecaluemessage = "";
                this.canSaveButtonreholder = true;
            } else {
                this.canSaveButtonreholder = false;

                this.newItemsStepStateMessage =
                    "Required information missing";
                this.checkoutService.newItemsStepStateMessage(
                    this.newItemsStepStateMessage
                );
            }
        }
        //let Buttonreholder = document.getElementById("Buttonreholder");
        // if (this.canSaveButtonreholder == true) {
        //     this.updateDeclaredValue();

        //     //this.newItemsStepStateMessage = ""
        //     //this.checkoutService.newItemsStepStateMessage(this.newItemsStepStateMessage);
        // }
        this.updateDeclaredValue();
        if (this.updateDeclaredValue!) {
            this.declarecaluemessage = "";
        }
        if (
            isNaN(this.declaredValue) ||
            this.declaredValue <= 0 ||
            this.declaredValue == null
        ) {
            this.declarecaluemessage = "Declared Value Required";
            this.checkoutService.Errorcount();
        }
    }
    if (this.SelectCardOptions.value == "Pre-Grade Encapsulation") {
        if (
            this.item.data.minimumRequestedGrade ==
                this.OldminimumRequestedGrade &&
            this.item.data.certificationCode == this.OldcertificationCode &&
            this.item.data.minimumRequestedGrade != "" &&
            this.item.data.certificationCode != "" &&
            this.declaredValue == this.oldDeclaredValue &&
            this.declaredValue > 0 &&
            this.oldOversized == this.item.settings.oversized
        ) {
            this.declarecaluemessage = "";
            this.canSaveButtonpreGradeEncapsulation = false;

            this.newItemsStepStateMessage = "";
            this.checkoutService.newItemsStepStateMessage(
                this.newItemsStepStateMessage
            );
        } else {
            if (
                this.declaredValue > 0 &&
                this.item.data.certificationCode != "" &&
                this.item.data.minimumRequestedGrade != ""
            ) {
                this.declarecaluemessage = "";
                this.canSaveButtonpreGradeEncapsulation = true;
            } else {
                this.canSaveButtonpreGradeEncapsulation = false;

                this.newItemsStepStateMessage =
                    "Required information missing";
                this.checkoutService.newItemsStepStateMessage(
                    this.newItemsStepStateMessage
                );
            }
        }

        if (this.canSaveButtonpreGradeEncapsulation == true) {
            this.updateDeclaredValue();
        }
    }
}
}
