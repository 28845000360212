import { Component } from '@angular/core';

import { ModalService, ModalInterface } from '../../services/modal.service';

@Component({
    selector: 'feature-coming-soon',
    templateUrl: './feature-coming-soon.component.html'
})

export class FeatureComingSoonComponent implements ModalInterface {
    constructor(
        private modalService: ModalService
    ){}

    type = "feature-coming-soon";
    loader!: boolean;
    options: any;

    close() {
        this.modalService.close(this.type, 'close');
    }

    getMessage(): string {
        var defaultMessage = `We're working hard to give you the best experience ever.
        Just hold on and we'll let you know when this feature is available.`

        return this.options && this.options.message ? this.options.message : defaultMessage;
    }

    getActionLabel(): string {
        return this.options && this.options.actionLabel;
    }

    hasAction(): boolean {
        return this.options && this.options.actionLabel;
    }

    takeAction() {
        this.options.actionCallback();
    }
}
