import { trigger, state, style, transition, animate } from '@angular/animations';

export class Animations {

    public static expand = trigger('expand', [
        state('false', style({height: 0})),
        transition('void => *', [
            style({height: 0, overflow: 'hidden'}),
            animate('200ms',style({height: '*'}))
        ]),
        transition('* => void', [
            style({height: '*', overflow: 'hidden'}),
            animate('200ms', style({height: 0}))
            ])
        ])
    
}