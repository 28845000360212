<style>
  .display-thumbnail-card {
    display: -webkit-box;
  }

  .mr-15 {
    margin-right: 15px;
  }
</style>
<div class="row_ m-0 p-4 w-100" style="padding: 1rem; background: #f2f2f2 !important;">
  <div class="col-12">
    <nav class="navbar ">
      <div class="sub-menu">
        <input type="checkbox" id="show-menu" role="button">
        <label for="show-menu" class="show-menu"></label>
        <ul class=" menu" id="menu">
          <li>
            <a class="menu__item " routerLink="/account/dashboard"><img src="./assets/img/dashboard/subm.png" alt="" width="15" /> Dashboard</a>
          </li>
          <li>
            <a class="menu__item " routerLink="/account/myorder"><img src="./assets/img/dashboard/order.png" alt="" width="15" /> My Orders</a>
          </li>
          <li>
            <a class="menu__item " routerLink="/account/sellerready"><img src="./assets/img/dashboard/seller.png" alt="" width="15" /> Seller READY</a>
          </li>
          <li>
            <a class="menu__item " routerLink="/account/receipts"><img src="../../../../assets/img/dashboard/Dollar Icon - Black - S.png" alt="" width="15" /> Receipts</a>
          </li>
          <li>
            <a class="menu__item " routerLink="/account/needhelp"><img src="./assets/img/dashboard/helpp.png" alt="" width="15" /> I Need Help</a>
          </li>
          <li>
            <a class="menu__item " routerLink="/account"><img src="./assets/img/dashboard/user.png" alt="" width="15" />My Profile</a>
          </li>
          <li>
            <a class="menu__item active" routerLink="/account/my-cards"><img src="./assets/img/dashboard/user.png" alt="" width="15" />My Cards</a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</div>


<div class="wrapper" style="background: #f2f2f2 !important;">


  <div class="control-section default mb-4">
    <h2>My Cards</h2>
    <div class="badge-default" style="text-align:center">
      <div class="e-btn-group e-custom-button">
        <button id="update" class="e-btn" style="width: auto; border-radius: 8px;" (click)="selectTab(3,'completed')">
          Downloads
          <span class="e-badge e-badge-info e-badge-notification e-badge-overlap">{{availableForDownload}}</span>
        </button>
        <button id="task" class="e-btn" style="width: auto; border-radius: 8px;" (click)="selectTab(3,'pending')">
          Pending Downloads
          <span class="e-badge e-badge-success e-badge-notification e-badge-overlap">{{willBeReadySoon}}</span>
        </button>
      </div>
    </div>
  </div>
  <p [style.color]="msgColor" style="text-align:center;">{{cardsMsg}}</p>


  <div class="control-section default-ribbon-section mt-4" *ngIf="!isCardsLoading">
    <div id="default-ribbonContainer">
      <ejs-ribbon id="ribbon" #ribbon (tabSelecting)='tabSelecting($event)'>
        <e-ribbon-tabs>
          <e-ribbon-tab header="Home">
            <e-ribbon-groups>
              <e-ribbon-group header="My Cards">
                <e-ribbon-collections>
                  <e-ribbon-collection>
                    <e-ribbon-items>
                      <e-ribbon-item type=Button [buttonSettings]="cardAddButton">
                      </e-ribbon-item>
                    </e-ribbon-items>
                  </e-ribbon-collection>
                </e-ribbon-collections>
              </e-ribbon-group>
              <e-ribbon-group header="View">
                <e-ribbon-collections>
                  <e-ribbon-collection>
                    <e-ribbon-items>
                      <e-ribbon-item type="Button" [buttonSettings]="gallery6Button">
                      </e-ribbon-item>
                      <e-ribbon-item type="Button" [buttonSettings]="gallery4Button">
                      </e-ribbon-item>
                    </e-ribbon-items>
                  </e-ribbon-collection>
                  <e-ribbon-collection>
                    <e-ribbon-items>
                      <e-ribbon-item type="Button" [buttonSettings]="gallery2Button">
                      </e-ribbon-item>
                      <e-ribbon-item type="Button" [buttonSettings]="listButton">
                      </e-ribbon-item>
                    </e-ribbon-items>
                  </e-ribbon-collection>
                </e-ribbon-collections>
              </e-ribbon-group>
              <e-ribbon-group header="Filter By Set & Card">
                <e-ribbon-collections>
                  <e-ribbon-collection>
                    <e-ribbon-items>
                      <e-ribbon-item type="ComboBox" [allowedSizes]='smallSize' [comboBoxSettings]="sportDdl">
                      </e-ribbon-item>
                      <e-ribbon-item type="ComboBox" [allowedSizes]='smallSize' [comboBoxSettings]="setDdl">
                      </e-ribbon-item>
                      <e-ribbon-item type="ComboBox" [comboBoxSettings]="subjectDdl">
                      </e-ribbon-item>
                    </e-ribbon-items>
                  </e-ribbon-collection>
                  <e-ribbon-collection>
                    <e-ribbon-items>
                      <e-ribbon-item type="ComboBox" [allowedSizes]='smallSize' [comboBoxSettings]="yearFromDdl">
                      </e-ribbon-item>
                      <e-ribbon-item type="ComboBox" [allowedSizes]='smallSize' [comboBoxSettings]="yearToDdl">
                      </e-ribbon-item>
                      <e-ribbon-item type="ComboBox" [allowedSizes]='smallSize' [comboBoxSettings]="cardNoDdl">
                      </e-ribbon-item>
                    </e-ribbon-items>
                  </e-ribbon-collection>
                </e-ribbon-collections>
              </e-ribbon-group>
              <e-ribbon-group header="Filter By SGC Info">
                <e-ribbon-collections>
                  <e-ribbon-collection>
                    <e-ribbon-items>
                      <e-ribbon-item type="ComboBox" [comboBoxSettings]="gradeDdl">
                      </e-ribbon-item>
                      <e-ribbon-item type=CheckBox [allowedSizes]='smallSize' [checkBoxSettings]="gradeOrBetterChk">
                      </e-ribbon-item>
                      <e-ribbon-item type=CheckBox [allowedSizes]='smallSize' [checkBoxSettings]="autoOnlyChkB">
                      </e-ribbon-item>
                    </e-ribbon-items>
                  </e-ribbon-collection>
                  <e-ribbon-collection>
                    <e-ribbon-items>
                      <e-ribbon-item type="ComboBox" [comboBoxSettings]="ordNoDdl">
                      </e-ribbon-item>
                      <e-ribbon-item type="ComboBox" [comboBoxSettings]="certCodeDdl">
                      </e-ribbon-item>
                    </e-ribbon-items>
                  </e-ribbon-collection>
                </e-ribbon-collections>
              </e-ribbon-group>

              <e-ribbon-group header="For Download" [isCollapsible]=false>
                <e-ribbon-collections>
                  <e-ribbon-collection>
                    <e-ribbon-items>
                      <e-ribbon-item type="Button" [buttonSettings]="selectAllButFilter">
                      </e-ribbon-item>
                      <e-ribbon-item type="Button" [buttonSettings]="clearAllButFilter">
                      </e-ribbon-item>
                    </e-ribbon-items>
                  </e-ribbon-collection>
                </e-ribbon-collections>
              </e-ribbon-group>
              <e-ribbon-group header="Cards Selection">
                <e-ribbon-collections>
                  <e-ribbon-collection>
                    <e-ribbon-items>
                      <e-ribbon-item type="ComboBox" [comboBoxSettings]="downlaodTypeDdl">
                      </e-ribbon-item>
                      <e-ribbon-item type=Button [buttonSettings]="downlaodAddButton">
                      </e-ribbon-item>
                    </e-ribbon-items>
                  </e-ribbon-collection>
                </e-ribbon-collections>
              </e-ribbon-group>
            </e-ribbon-groups>
          </e-ribbon-tab>
          <e-ribbon-tab header="Downloads">
          </e-ribbon-tab>
        </e-ribbon-tabs>
      </ejs-ribbon>
    </div>
  </div>

  <section class="user-links mt-4 p-4">
    <div class="row">
      <div class="col-lg-12" *ngIf="isCertLookup==false && (selectedTab === 1 || selectedTab === 2)">
        <div class="row mb-5 justify-content-center img-with-body" *ngIf="isRefreshing">
          <div class="overlay active">
            <div class="welcome">
              <div class="loader xl" style="background-size:70px;width:85px;height:85px;"></div>
              <div style="text-align:center;font-size:15px;font-weight:600;">Cards Loading...</div>
            </div>
          </div>
        </div>
        <h4 class="h4style" *ngIf="MyCardOrderItemLength == 0 && !isRefreshing" style="text-align:center;margin-top:5%;font-size:20px !important;text-align:center;text-transform:none !important;">No card(s) to display.</h4>

        <div class="row mb-5 justify-content-center img-with-body" *ngIf="twoCol && cardsLoad">
          <div class="col-lg-6 mb-5" *ngFor="let item of MyCardOrderItems; let i=index;">
            <div class="card card-collection  position-relative  p-0 border-0" [ngClass]="{'selected': item.isChecked == true}">
              <div class="text-center position-relative four-img">
                <img class="card-coll-img" [ngClass]="{'showBackImage': item.showBackImage == true}" src="{{get692AuthImageFront(item.orderItemId)}}" (error)="onImgError(item,$event)" alt="Card image cap">
                <img class="card-coll-img" [ngClass]="{'showBackImage': item.showBackImage == false}" src="{{get692AuthImageBack(item.orderItemId)}}" (error)="onImgError(item,$event)" alt="Card image cap">
                <span class="tick" style="right:25px;">&#10003;</span>
                <div class="hover-lay">
                  <label class="toggle" [ngClass]="{'downloadselect': item.isChecked == true}" *ngIf="item.myCardOwnerId == currentUser.id">
                    <input class="toggle__checkbox" type="checkbox" [(ngModel)]="item.isChecked" (ngModelChange)="addToDownload($event,item.orderItemId)" />
                    <span title="Click to deselect" *ngIf="item.isChecked" class="toggle__check downloadselect">unselect</span>
                    <span title="Click to select" *ngIf="!item.isChecked" class="toggle__check">select</span>
                  </label>
                  <a title="View Large" style="display:inline-block; margin:5px 0 0 15px;"><img src="/assets/img/zoom.svg" alt="Zoom In" (click)="openImage(item.showBackImage,item.orderItemId)"></a>
                  <a title="Switch Side" style="display:inline-block; margin:5px 0 0 15px;"><img src="/assets/img/f-b-view.svg" alt="Switch View" (click)="ShowImage(item,item.showBackImage)"></a>
                  <a title="Delete" style="display:inline-block; margin:5px 0 0 15px;"><img src="/assets/img/Remove-icon.png" alt="Delete" (click)="OpenConfirmationModal(item)"></a>
                </div>
              </div>
              <div class="card-body">
                <p class="h3" style="color:#000;font-family:'d77'">Cert Code: {{item.certificationCode}}</p>
                <p class="h3" style="color:#000;font-family:'d77'">Card Grade: {{getCardGrade(item)}}&nbsp;&nbsp;&nbsp;<br><span *ngIf="item.cardAutoGrade !=null"> Autograph Grade: {{item.cardAutoGrade}}</span></p>
                <p class="h3">{{item.cardSet}}</p>
                <p class="h3">{{item.cardNumber}}&nbsp;{{item.cardSubject}}</p>
                <p class="h3">{{item.cardDescription}}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-5 justify-content-center img-with-body" *ngIf="fourCol && cardsLoad">
          <div class="col-lg-3 mb-5" *ngFor="let item of MyCardOrderItems; let i=index;">
            <div class="card card-collection  position-relative  p-0 border-0" [ngClass]="{'selected': item.isChecked == true}">
              <div class="position-relative four-img">
                <img class="card-coll-img" [ngClass]="{'showBackImage': item.showBackImage == true}" src="{{get692AuthImageFront(item.orderItemId)}}" (error)="onImgError(item,$event)" alt="Card image cap">
                <img class="card-coll-img" [ngClass]="{'showBackImage': item.showBackImage == false}" src="{{get692AuthImageBack(item.orderItemId)}}" (error)="onImgError(item,$event)" alt="Card image cap">
                <div class="hover-lay">
                  <label class="toggle" [ngClass]="{'downloadselect': item.isChecked == true}" *ngIf="item.myCardOwnerId == currentUser.id">
                    <input class="toggle__checkbox" type="checkbox" [(ngModel)]="item.isChecked" (ngModelChange)="addToDownload($event,item.orderItemId)" />
                    <span title="Click to deselect" *ngIf="item.isChecked" class="toggle__check downloadselect">unselect</span>
                    <span title="Click to select" *ngIf="!item.isChecked" class="toggle__check">select</span>
                  </label>
                  <!--<p class="text-center"><span class="bttn">Select to download</span></p>-->
                  <a style="display:inline-block;" title="View Large"><img src="/assets/img/zoom.svg" alt="Zoom In" (click)="openImage(item.showBackImage,item.orderItemId)"></a>
                  <a style="display:inline-block;" title="Switch Side"><img src="/assets/img/f-b-view.svg" alt="Switch View" (click)="ShowImage(item,item.showBackImage)"></a>
                  <a style="display:inline-block;" title="Delete"><img src="/assets/img/Remove-icon.png" alt="Delete" (click)="OpenConfirmationModal(item)"></a>
                </div>
                <span class="tick" style="right:16px;">&#10003;</span>
              </div>

              <div class="card-body">
                <p class="h3" style="color:#000;font-family:'d77'">Cert Code: {{item.certificationCode}}</p>
                <p class="h3" style="color:#000;font-family:'d77'">Card Grade: {{getCardGrade(item)}}&nbsp;&nbsp;&nbsp;<br><span *ngIf="item.cardAutoGrade !=null"> Autograph Grade: {{item.cardAutoGrade}}</span></p>
                <p class="h3">{{item.cardSet}}</p>
                <p class="h3">{{item.cardNumber}}&nbsp;{{item.cardSubject}}</p>
                <p class="h3">{{item.cardDescription}}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-5 justify-content-center" *ngIf="sixCol && cardsLoad && !isRefreshing">
          <div class="col-lg-2 mb-5" *ngFor="let item of MyCardOrderItems; let i=index;">
            <div class="card card-collection position-relative  p-0 border-0" [ngClass]="{'selected': item.isChecked == true}">
              <div class="text-center togg">
                <img class="card-coll-img" [ngClass]="{'showBackImage': item.showBackImage == true}" src="{{get275AuthImageFront(item.orderItemId)}}" (error)="onImgError(item,$event)" alt="Card image cap">
                <img class="card-coll-img" [ngClass]="{'showBackImage': item.showBackImage == false}" src="{{get275AuthImageBack(item.orderItemId)}}" (error)="onImgError(item,$event)" alt="Card image cap">
              </div>
              <div class="hover-lay">
                <label style="margin-left: 0px; font-size: 13px;" class="toggle" [ngClass]="{'downloadselect': item.isChecked == true}" *ngIf="item.myCardOwnerId == currentUser.id">
                  <input class="toggle__checkbox" type="checkbox" [(ngModel)]="item.isChecked" (ngModelChange)="addToDownload($event,item.orderItemId)" />
                  <span title="Click to deselect" *ngIf="item.isChecked" class="toggle__check downloadselect">unselect</span>
                  <span title="Click to select" *ngIf="!item.isChecked" class="toggle__check">select</span>
                </label>
                <a title="View Large" style="display:inline-block;"><img style="width:15px; height: 15px; margin-left: 16px;" src="/assets/img/zoom.svg" height="25" alt="Zoom In" (click)="openImage(item.showBackImage,item.orderItemId)"></a>
                <a title="Switch Side" style="display:inline-block;" class="tog"><img style="width:15px; height: 15px;  margin-left: 3px" src="/assets/img/f-b-view.svg" alt="Switch View" (click)="ShowImage(item,item.showBackImage)"></a>
                <a title="Delete" style="display:inline-block;" class="tog"><img style="width:15px; height: 15px; margin-left: 3px" src="/assets/img/Remove-icon.png" alt="Delete" (click)="OpenConfirmationModal(item)"></a>

              </div>
              <span class="tick" style="width:17px;top:14px;right:28px;font-size:15px;">&#10003;</span>
            </div>
          </div>
        </div>


        <div *ngIf="onecol && cardsLoad && !isRefreshing && MyCardOrderItems.length > 0">
          <div class="row mb-5 justify-content-between" *ngFor="let item of MyCardOrderItems; let i=index;">
            <div class="col-lg-5">
              <div class="row">
                <div class="col-lg-6">
                  <div class="card-collection p-0  position-relative" [ngClass]="{'selected': item.isChecked == true}">
                    <div class="text-center">
                      <img class="card-coll-img" src="{{get275AuthImageBack(item.orderItemId)}}" (error)="onImgError(item,$event)" alt="Card image cap">
                    </div>
                    <div class="hover-lay">
                      <a title="View Large" style="display:inline-block; margin:0 15px"><img src="/assets/img/zoom.svg" height="35" alt="Zoom In" (click)="openBackImage(item.orderItemId)"></a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="card-collection  position-relative p-0 border-0" [ngClass]="{'selected': item.isChecked == true}">
                    <div class="text-center">
                      <img class="card-coll-img" src="{{get275AuthImageFront(item.orderItemId)}}" (error)="onImgError(item,$event)" alt="Card image cap">
                    </div>
                    <div class="hover-lay">
                      <label style="margin-left:14px" class="toggle" [ngClass]="{'downloadselect': item.isChecked == true}" *ngIf="item.myCardOwnerId == currentUser.id">
                        <input class="toggle__checkbox" type="checkbox" [(ngModel)]="item.isChecked" (ngModelChange)="addToDownload($event,item.orderItemId)" />
                        <span title="Click to deselect" *ngIf="item.isChecked" class="toggle__check downloadselect">unselect</span>
                        <span title="Click to select" *ngIf="!item.isChecked" class="toggle__check">select</span>
                      </label>
                      <a title="View Large" style="display:inline-block;margin:0 15px "><img src="/assets/img/zoom.svg" height="35" alt="Zoom In" (click)="openFrontImage(item.orderItemId)"></a>
                      <a title="Delete" style="display:inline-block;margin:0 15px "><img src="/assets/img/Remove-icon.png" height="35" alt="Delete" (click)="OpenConfirmationModal(item)"></a>
                    </div>
                    <span class="tick">&#10003;</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-7">
              <div class="card-body">
                <p class="h3">{{item.cardSet}}</p>
                <p class="h3">#{{item.cardNumber}}&nbsp;{{item.cardSubject}}</p>
                <p class="h3" *ngIf="item.line3">{{item.line3}}</p>
                <p class="h3" *ngIf="item.line4">{{item.line4}}</p>
                <p class="h3" style="color:#000;font-family:'d77'">Cert Code: {{item.certificationCode}}&nbsp;&nbsp;&nbsp; Card Grade: {{getCardGrade(item)}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br><span *ngIf="item.cardAutoGrade != null"> Autograph Grade: {{item.cardAutoGrade}}</span></p>


                <!--<p class="h3">{{item.cardDescription}}</p>
                <p class="h3">{{item.sport}}</p>
                <p class="h3">{{item.orderNumber}}</p>-->
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="col-lg-12" *ngIf="isCertLookup==false && selectedTab === 3">
        <div class="res-btn" *ngIf="activeTab === 1">
          <div class="submitBtn" style="text-align:center;">
            <button class="submit-btn e-btn" cssClass="e-link" id="submit-btn"
                    (click)="selectTab(1,'home')" style="padding-bottom: 7px; padding-top: 5px; cursor: pointer; margin-top: 28px; margin-bottom: 15px; margin-top: -15px;border-radius:4px;">
              View My Cards
            </button>
          </div>
        </div>

        <div class="accordion_content">
          <ejs-grid #gridSub [dataSource]='checkDownlaodCardOrderItems' gridLines="both" allowPaging="true" [allowSorting]='true'
                    [allowFiltering]="true" [pageSettings]="pageSettings" [filterSettings]='FilterOptions'
                    [allowResizing]='true' allowTextWrap='true' [textWrapSettings]='wrapSettings'>
            <e-columns>
              <e-column field='createdOn' headerText='Requested On' type="date" [format]="format" [filter]='filter' textAlign="center" width=90></e-column>
              <e-column field='zipGeneratedOn' headerText='Generated On' [format]="format" [filter]='filter' type="date" [allowSorting]='false' textAlign="center" width=90></e-column>
              <e-column field='orderNumber' [allowSorting]='false' headerText='Order Number' width='70' textAlign="center">
                <ng-template #template let-checkDownlaodCardOrderItems>
                  <div *ngIf="checkDownlaodCardOrderItems.orderNumber == 0;else results">
                    <span>-</span>
                  </div>
                  <ng-template #results>
                    <div>
                      <span>{{checkDownlaodCardOrderItems.orderNumber}}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>
              <e-column field='totalCards' headerText='Total Cards' type="number" textAlign="center" width=50></e-column>
              <e-column field='certCode' [allowSorting]='false' headerText='Card Name' width='70' textAlign="center">
                <ng-template #template let-checkDownlaodCardOrderItems>
                  <div *ngIf="checkDownlaodCardOrderItems.certCode == null || checkDownlaodCardOrderItems.certCode == 'null' ;else results">
                    <span>-</span>
                  </div>
                  <ng-template #results>
                    <div>
                      <span>{{checkDownlaodCardOrderItems.cardName}}</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>

              <e-column field='zipRequestStateStatus' [allowSorting]='false' headerText='Download Status' width='70' textAlign="center">
                <ng-template #template let-checkDownlaodCardOrderItems>
                  <div *ngIf="checkDownlaodCardOrderItems.zipRequestState == 3;else results">
                    <a class="green-badge" target="_blank" href="{{checkDownlaodCardOrderItems.zipUrl}}">Completed Download</a>
                  </div>
                  <ng-template #results>
                    <div>
                      <span class="red-badge">Pending Download</span>
                    </div>
                  </ng-template>
                </ng-template>
              </e-column>

              <!--<e-column field='zipUrl' headerText='Download' width='90' textAlign="center">
                  <ng-template #template let-checkDownlaodCardOrderItems>
                      <div *ngIf="checkDownlaodCardOrderItems.zipRequestState == 3;else result">
                          <a style="text-decoration-line: none;cursor: pointer;color:#785aff;" target="_blank" href="{{checkDownlaodCardOrderItems.zipUrl}}">Download</a>
                      </div>
                      <ng-template #result>
                          <div>
                              <span>Your download link is being prepared, please check back after few minutes.</span>
                          </div>
                      </ng-template>
                  </ng-template>
              </e-column>-->

            </e-columns>
          </ejs-grid>
        </div>

      </div>

      <div *ngIf="isCertLookup==true" class="col-lg-12">
        <div class="row mb-5 justify-content-center img-with-body">
          <div class="w-100-flex">
            <div class="w-65">
              <div class="content content--compact" style="min-height:0vh;margin-bottom:0px;margin-top:10px;grid-template-columns: 30px 1fr 0px; padding-right:15px;">
                <p>
                  Enter your certification code below. For black labels, enter all 7 digits. For green and white labels, enter all 11 characters (XXXXXXX-XXX).
                </p>
                <!--<input type="text" id="search" class="input" name="search" [formControl]="searchTerm">-->
                <form id="form" [formGroup]="form" style="margin-top:-45px;">
                  <div class="d-flex">
                    <div>
                      <h4 style="padding-bottom:4px;padding-top:3px; font-weight: 600 ">CERT CODE LOOKUP</h4>
                      <input type="text" class="input" placeholder="Cert Code" formControlName="authcode" style="width:100%;">
                      <div *ngIf="form.controls['authcode'].touched && !form.controls['authcode'].valid" style="color: red;margin-top: 0px;">
                        <p *ngIf="form.controls['authcode'].hasError('minlength')" class="text-danger">
                          * Min Length is 5
                        </p>
                        <p *ngIf="form.controls['authcode'].hasError('maxlength')" class="text-danger">
                          * Max Length is 12
                        </p>
                        <p *ngIf="form.controls['authcode'].hasError('pattern')" class="text-danger">
                          * Invalid format
                        </p>
                      </div>
                    </div>
                    <div *ngIf="showgradeSubject">
                      <h4 style="padding-bottom:4px;padding-top:3px; font-weight: 600 ">Grade</h4>
                      <div class="select">
                        <select class="input" formControlName="grade">
                          <option value="">Select Grade</option>
                          <option value="A">A</option>
                          <option value="1">1 POOR/10</option>
                          <option value="1.5">1.5 FAIR/20</option>
                          <option value="2">2 GOOD/30</option>
                          <option value="2.5">2.5 GOOD+/35</option>
                          <option value="3">3 VG/40</option>
                          <option value="3.5">3.5 VG+/45</option>
                          <option value="4">4 VG, EX/50</option>
                          <option value="4.5">4.5 VG, EX+/55</option>
                          <option value="5">5 EX/60</option>
                          <option value="5.5">5.5 EX+/70</option>
                          <option value="6">6 EX, NM/80</option>
                          <option value="6.5">6.5 EX, NM+/82</option>
                          <option value="7">7 NM/84</option>
                          <option value="7.5">7.5 NM+/86</option>
                          <option value="8">8 NM, MT/88</option>
                          <option value="8.5">8.5 NM, MT+/92</option>
                          <option value="9">9 MT/96</option>
                          <option value="9.5">9.5 MT+/97</option>
                          <option value="10 GM">10 GEM/98</option>
                          <option value="10 PRI">10 PRISTINE/100</option>
                        </select>
                      </div>
                      <div *ngIf="form.controls['grade'].touched && !form.controls['grade'].valid" style="color: red;margin-top: 0px;">
                        <p *ngIf="form.controls['grade'].hasError('required')" class="text-danger">
                          * Grade is required
                        </p>
                      </div>
                    </div>
                    <div *ngIf="showgradeSubject">
                      <h4 class="w-155" style="padding-bottom:4px;padding-top:3px; font-weight: 600">Enter subject first/last Name as on label</h4>
                      <input type="text" class="input" placeholder="Subject Enter the first or last name" formControlName="subject" style="width:100%;">
                      <div *ngIf="form.controls['subject'].touched && !form.controls['subject'].valid" style="color: red;margin-top: 0px;">
                        <p *ngIf="form.controls['subject'].hasError('maxlength')" class="text-danger">
                          * Max Length is 15
                        </p>
                        <p *ngIf="form.controls['subject'].hasError('required')" class="text-danger">
                          * Subject is required
                        </p>
                      </div>
                    </div>
                    <div class="res-btn">
                      <div class="submitBtn">
                        <button class="submit-btn e-btn" id="submit-btn" [disabled]="form.invalid"
                                (click)="CheckAuthCode()" style="padding-bottom: 7px; padding-top: 5px; cursor: pointer; margin-top: 28px; ">
                          <span *ngIf="!isRefreshing">Check Cert Code</span>
                          <div *ngIf="isRefreshing" class="loader xs invert-color"></div>
                        </button>
                        <button class="submit-btn e-btn" cssClass="e-link" id="submit-btn"
                                (click)="BackFromcertCodeLookup()" style="padding-bottom: 7px; padding-top: 5px; cursor: pointer; margin-top: 28px;">
                          View My Cards
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
                <p style="font-weight: 600; margin-top: -20px;" *ngIf="cardExistMsg != ''">
                  {{cardExistMsg}}
                </p>
                <p *ngIf="cardAddedStatus == 'cardAdded'" style="color: green; margin-top: -20px;">
                  This card has been added to your My Cards.
                </p>
                <p *ngIf="cardAddedStatus == 'cardRemoved'" style="color: red; margin-top: -20px;">
                  This card has been removed from your My Cards.
                </p>

                <div class="cert-nerw_text" style="margin-top:-35px;" *ngIf="!showCertCodeMsg">
                  <p>Please keep in mind that SGC has been authenticating and grading cards for over 24 years and the addition of high-res images to our Cert Verification feature is a service that, in the grand scheme of things, is still in its infancy. It is our plan moving forward to increase the caliber of service provided by this new feature as we continue to fine tune our processes and improve the quality and consistency of our images.</p>
                </div>
                <div class="text-danger" *ngIf="PopLineheck==false">
                  <p class="w-35" style="color:red;">
                    The POP is currently updating with today's transactions. Please try again later.
                  </p>
                </div>


                <div *ngIf="authCodeResult.popResultCount == 0 || emptymsg==true" class="text-danger">
                  <p class="w-35" style="color:red;">
                    We were unable to find that certification code in our lookup. Please ensure that the certification code is entered correctly. If you continue to see this message, click the button below to send information about your SGC card to our Collector Support Team and we will look into it further.
                  </p>
                  <br />
                  <div style="text-align:left;margin-left:0px;">
                    <div class="submitBtn">
                      <a (click)="LookUpQuestions()" class="submit-btn e-btn" id="submit-btn" style="padding-bottom:7px;padding-top:4px;">Report an Issue </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="content" style="min-height:30vh;grid-gap:0px 0px;margin-top:0px;margin-bottom:0px;padding-top:20px;" *ngIf="authCodeResult.popResultCount == 1">
                <div style="margin-left: -61px;" class="">
                  <div>
                    <h4 class="HeadingCertCode">SEARCHED AUTH CODE :<span class="DatacertCode">{{authcertcode}}</span></h4>
                    <h4 class="HeadingCertCode">Set :<span class="DatacertCode">{{authCodeResult.cardSet}}</span></h4>
                    <h4 class="HeadingCertCode">Card # :<span class="DatacertCode">{{authCodeResult.cardNumber}}</span></h4>
                    <h4 class="HeadingCertCode">Subject :<span class="DatacertCode">{{authCodeResult.cardSubject}}</span></h4>
                    <h4 class="HeadingCertCode" *ngIf="authCodeResult.cardDescription !=''">Description :<span class="DatacertCode">{{authCodeResult.cardDescription}}</span></h4>
                    <h4 class="HeadingCertCode" *ngIf="authCodeResult.cardDescription ==''">Description :<span class="DatacertCode">-</span></h4>
                    <h4 class="HeadingCertCode">Graded On :<span class="DatacertCode">{{authCodeResult.gradeDate }}</span></h4>

                    <h4 class="HeadingCertCode" *ngIf="authCodeResult.grade10P !=0 && !authcertcodelength">Grade:<span class="DatacertCode">10 PRISTINE</span></h4>
                    <h4 class="HeadingCertCode" *ngIf="authCodeResult.grade10 !=0 && !authcertcodelength">Grade:<span class="DatacertCode">10 GEM</span></h4>
                    <h4 class="HeadingCertCode" *ngIf="authCodeResult.grade9pt5 !=0 && !authcertcodelength">Grade:<span class="DatacertCode">9.5 MT+</span></h4>
                    <h4 class="HeadingCertCode" *ngIf="authCodeResult.grade9 !=0 && !authcertcodelength">Grade:<span class="DatacertCode">9 MT</span></h4>
                    <h4 class="HeadingCertCode" *ngIf="authCodeResult.grade8pt5 !=0 && !authcertcodelength">Grade:<span class="DatacertCode">8.5 NM, MT+</span></h4>
                    <h4 class="HeadingCertCode" *ngIf="authCodeResult.grade8 !=0 && !authcertcodelength">Grade:<span class="DatacertCode">8 NM, MT</span></h4>
                    <h4 class="HeadingCertCode" *ngIf="authCodeResult.grade7pt5 !=0 && !authcertcodelength">Grade:<span class="DatacertCode">7.5 NM+</span></h4>
                    <h4 class="HeadingCertCode" *ngIf="authCodeResult.grade7 !=0 && !authcertcodelength">Grade:<span class="DatacertCode">7 NM</span></h4>
                    <h4 class="HeadingCertCode" *ngIf="authCodeResult.grade6pt5 !=0 && !authcertcodelength">Grade:<span class="DatacertCode">6.5 EX, NM+</span></h4>
                    <h4 class="HeadingCertCode" *ngIf="authCodeResult.grade6 !=0 && !authcertcodelength">Grade:<span class="DatacertCode">6 EX, NM</span></h4>
                    <h4 class="HeadingCertCode" *ngIf="authCodeResult.grade5pt5 !=0 && !authcertcodelength">Grade:<span class="DatacertCode">5.5 EX+</span></h4>
                    <h4 class="HeadingCertCode" *ngIf="authCodeResult.grade5 !=0 && !authcertcodelength">Grade:<span class="DatacertCode">5 EX</span></h4>
                    <h4 class="HeadingCertCode" *ngIf="authCodeResult.grade4pt5 !=0 && !authcertcodelength">Grade:<span class="DatacertCode">4.5 VG, EX+</span></h4>
                    <h4 class="HeadingCertCode" *ngIf="authCodeResult.grade4 !=0 && !authcertcodelength">Grade:<span class="DatacertCode">4 VG, EX</span></h4>
                    <h4 class="HeadingCertCode" *ngIf="authCodeResult.grade3pt5 !=0 && !authcertcodelength">Grade:<span class="DatacertCode">3.5 VG+</span></h4>
                    <h4 class="HeadingCertCode" *ngIf="authCodeResult.grade3 !=0 && !authcertcodelength">Grade:<span class="DatacertCode">3 VG</span></h4>
                    <h4 class="HeadingCertCode" *ngIf="authCodeResult.grade2pt5 !=0 && !authcertcodelength">Grade:<span class="DatacertCode">2.5 GOOD+</span></h4>
                    <h4 class="HeadingCertCode" *ngIf="authCodeResult.grade2 !=0 && !authcertcodelength">Grade:<span class="DatacertCode">2 GOOD</span></h4>
                    <h4 class="HeadingCertCode" *ngIf="authCodeResult.grade1pt5 !=0 && !authcertcodelength">Grade:<span class="DatacertCode">1.5 FAIR</span></h4>
                    <h4 class="HeadingCertCode" *ngIf="authCodeResult.grade1 !=0 && !authcertcodelength">Grade:<span class="DatacertCode">1 POOR</span></h4>

                    <h4 class="HeadingCertCode" *ngIf="authCodeResult.grade10P !=0 && authcertcodelength">Grade:<span class="DatacertCode">10 PRISTINE/100</span></h4>
                    <h4 class="HeadingCertCode" *ngIf="authCodeResult.grade10 !=0 && authcertcodelength">Grade:<span class="DatacertCode">10 GEM/98</span></h4>
                    <h4 class="HeadingCertCode" *ngIf="authCodeResult.grade9pt5 !=0 && authcertcodelength">Grade:<span class="DatacertCode">9.5 MT+/97</span></h4>
                    <h4 class="HeadingCertCode" *ngIf="authCodeResult.grade9 !=0 && authcertcodelength">Grade:<span class="DatacertCode">9 MT/96</span></h4>
                    <h4 class="HeadingCertCode" *ngIf="authCodeResult.grade8pt5 !=0 && authcertcodelength">Grade:<span class="DatacertCode">8.5 NM, MT+/92</span></h4>
                    <h4 class="HeadingCertCode" *ngIf="authCodeResult.grade8 !=0 && authcertcodelength">Grade:<span class="DatacertCode">8 NM, MT/88</span></h4>
                    <h4 class="HeadingCertCode" *ngIf="authCodeResult.grade7pt5 !=0 && authcertcodelength">Grade:<span class="DatacertCode">7.5 NM+/86</span></h4>
                    <h4 class="HeadingCertCode" *ngIf="authCodeResult.grade7 !=0 && authcertcodelength">Grade:<span class="DatacertCode">7 NM/84</span></h4>
                    <h4 class="HeadingCertCode" *ngIf="authCodeResult.grade6pt5 !=0 && authcertcodelength">Grade:<span class="DatacertCode">6.5 EX, NM+/82</span></h4>
                    <h4 class="HeadingCertCode" *ngIf="authCodeResult.grade6 !=0 && authcertcodelength">Grade:<span class="DatacertCode">6 EX, NM/80</span></h4>
                    <h4 class="HeadingCertCode" *ngIf="authCodeResult.grade5pt5 !=0 && authcertcodelength">Grade:<span class="DatacertCode">5.5 EX+/70</span></h4>
                    <h4 class="HeadingCertCode" *ngIf="authCodeResult.grade5 !=0 && authcertcodelength">Grade:<span class="DatacertCode">5 EX/60</span></h4>
                    <h4 class="HeadingCertCode" *ngIf="authCodeResult.grade4pt5 !=0 && authcertcodelength">Grade:<span class="DatacertCode">4.5 VG, EX+/55</span></h4>
                    <h4 class="HeadingCertCode" *ngIf="authCodeResult.grade4 !=0 && authcertcodelength">Grade:<span class="DatacertCode">4 VG, EX/50</span></h4>
                    <h4 class="HeadingCertCode" *ngIf="authCodeResult.grade3pt5 !=0 && authcertcodelength">Grade:<span class="DatacertCode">3.5 VG+/45</span></h4>
                    <h4 class="HeadingCertCode" *ngIf="authCodeResult.grade3 !=0 && authcertcodelength">Grade:<span class="DatacertCode">3 VG/40</span></h4>
                    <h4 class="HeadingCertCode" *ngIf="authCodeResult.grade2pt5 !=0 && authcertcodelength">Grade:<span class="DatacertCode">2.5 GOOD+/35</span></h4>
                    <h4 class="HeadingCertCode" *ngIf="authCodeResult.grade2 !=0 && authcertcodelength">Grade:<span class="DatacertCode">2 GOOD/30</span></h4>
                    <h4 class="HeadingCertCode" *ngIf="authCodeResult.grade1pt5 !=0 && authcertcodelength">Grade:<span class="DatacertCode">1.5 FAIR/20</span></h4>
                    <h4 class="HeadingCertCode" *ngIf="authCodeResult.grade1 !=0 && authcertcodelength">Grade:<span class="DatacertCode">1 POOR/10</span></h4>

                    <h4 class="HeadingCertCode" *ngIf="authCodeResult.gradeA !=0">Grade:<span class="DatacertCode">A</span></h4>
                  </div>
                  <div class="display-thumbnail-card">
                    <div class="mr-15" *ngIf="showImage && userSignIn" style="cursor:pointer;">
                      <h4 class="w-155 HeadingCertCode">FRONT IMAGE</h4>
                      <img src={{h275FrontImage}} onerror="this.onerror=null;this.src='/assets/img/noimageavailable.jpeg';" (click)="openFrontImage(authCodeResult.id)" />
                    </div>

                    <div *ngIf="showImage && userSignIn" style="cursor:pointer;">
                      <h4 class="w-155 HeadingCertCode">BACK IMAGE</h4>
                      <img src={{h275BackImage}} onerror="this.onerror=null;this.src='/assets/img/noimageavailable.jpeg';" (click)="openBackImage(authCodeResult.id)" />
                    </div>
                    <div class="mr-15" *ngIf="!showImage" style="cursor:pointer;">
                      <img src="/assets/img/noimageavailable.jpeg" alt="Auth Code">
                    </div>
                  </div>
                  <h4 *ngIf="!userSignIn" style="color:#785aff">Please <a href="javascript:void(0)">Sign In</a> Or <a href="javascript:void(0)">Create</a> an Account to see backs and high resolutions images.</h4>
                </div>
              </div>

              <div style="display: flex; margin-left: 65px;" *ngIf="cardAddedStatus == 'cardExist' || cardAddedStatus == 'cardAdded'">
                <button class="submit-btn e-btn" cssClass="e-link" id="submit-btn" (click)="removeCardFromCollection(authcertcode)"
                        style="padding-bottom: 7px; padding-top: 5px; cursor: pointer; margin-top: 20px; margin-bottom: 20px; background: #BD362F; color: #FFFFFF; ">
                  Remove from My Cards
                </button>
              </div>


            </div>


          </div>
        </div>
      </div>
    </div>
  </section>
</div>
