import { Component, OnInit } from '@angular/core';
import { ShippingMethod, Order, OrderType, AdminKPIExecutive } from '../../../orders/models/order';
import { CheckoutService } from '../../services/checkout.service';
import { OrderService } from '../../../orders/services/order.service';
import { ModalService } from '../../../common/services/modal.service';
import { Router } from "@angular/router";
import { Location } from '@angular/common';
import { UserService } from '../../../common/services/user.service';
import { Meta, Title } from '@angular/platform-browser';
import { Address, Customer, User } from '../../../common/models/user';
import * as _ from 'lodash';


@Component({
    selector: 'pay-order',
    templateUrl: './pay-order.component.html',
    styleUrls: ['../../checkout.component.sass']
})
export class PayOrderComponent implements OnInit {

    constructor(
        private router: Router,
        private checkoutService: CheckoutService,
        private orderService: OrderService,
        private location: Location,
        private modalService: ModalService,
        private userService: UserService,
        private metaService: Meta,
        private titleService: Title
    ) { }



    adminKPIExecutive = new AdminKPIExecutive();
    order = new Order();
    phoneNumber: any;
    shippingSelected!: boolean;
    shippingMethods = new Array<ShippingMethod>();
    selectedShippingMethod = new ShippingMethod();
    showPhoneNumber: boolean = false;
    orderTypeCode: any;


    currentUser = new User();
    currentCustomer = new Customer();
    userAddresses = new Array<Address>();
    customerAddresses = new Array<Address>();
    selectedAddress = new Address();

    oldFedexAccount!: number;
    canEditFedexAccount: boolean = false;
    showLoginUserAddress: boolean = true;

    editFedexAccount() {
        this.canEditFedexAccount = true;
    }

    getShippingMethod(): string {
        return this.order.getShippingMethodName();
    }

    isShippingMethodSelected(shippingMethod: ShippingMethod): boolean {

        if (this.shippingSelected == false) {
            this.shippingSelected = true;
            this.selectShippingMethod(shippingMethod);
        }
        return this.selectedShippingMethod == shippingMethod;
    }

    updateOrderWithShipping() {
        this.checkoutService.updateOrderWithShipping();
    }

    selectShippingAccount(personal: boolean) {


        this.order.settings.useFedexAccount = personal;
        if ((personal && this.order.settings.fedexAccount && !this.canEditFedexAccount) || !personal) this.updateOrderWithShipping();
    }

    selectShippingMethod(shippingMethod: ShippingMethod) {

        this.checkoutService.setEnable();
        this.selectedShippingMethod = shippingMethod;
        if (this.isUSPSSelected() && this.order.settings.useFedexAccount) {
            this.checkoutService.setShippingMethod(shippingMethod);
        }
        else {
            this.checkoutService.setShippingMethod(shippingMethod);
        }
    }

    isUSPSSelected(): boolean {
        return Boolean(this.selectedShippingMethod && this.selectedShippingMethod.carrier && this.selectedShippingMethod.carrier.code == 'usps');
    }

    getOrderItems(): any[] {
        return this.order.getItems();
    }

    getOrderType(): OrderType {
        return this.order.getOrderType();
    }

    getItemsCount(): number {
        return this.order.getItemsCount();
    }

    getTotalDeclaredValue(): number {
        return this.order.getTotalDeclaredValue();
    }

    getTurnaround(): any {
        if (this.order.getTotalTurnaround() == 1) {
            return "End of Show";
        }
        else if (this.order.getTotalTurnaround() == 2) {
            return "1-2 estimated business days";
        }
        else if (this.order.getTotalTurnaround() == 10) {
            return "5-10 estimated business days";
        }
        //else if (this.order.getTotalTurnaround() == 5) {
        //    return "1-5 estimated business days";
        //}
        else if (this.order.getTotalTurnaround() == 48) {
            return "43-48 estimated business days";
        }
        else if (this.order.getTotalTurnaround() == 55) {
            return "50-55 estimated business days";
        }
        else if (this.order.getTotalTurnaround() == 57) {
            return "52-57 estimated business days";
        }
        else if (this.order.getTotalTurnaround() == 70) {
            return "65-70 estimated business days";
        }
        else if (this.order.getTotalTurnaround() == 3) {
            return "1-3 estimated business days";
        }
        else if (this.order.getTotalTurnaround() == 25) {
            return "20-25 estimated business days";
        }
        else if (this.order.getTotalTurnaround() == 50) {
            return "45-50 estimated business days";
        }
        //else if (this.order.getTotalTurnaround() == 30) {
        //    return "30 Business Day Guaranteed";
        //}
        //else if (this.order.getTotalTurnaround() == 100) {
        //    return "Special Items Non-Guarantee";
        //}
        else {
            return this.order.getTotalTurnaround() + " estimated business days";
        }
    }

    getShippingMethodCarrierLogoUrl(): string {
        return this.order.shippingMethod ? `/assets/img/${this.order.shippingMethod.carrier!.code}.png` : '/assets/img/fedex.png';
    }

    getShippingOptionText(): string {
        return this.order.settings.useFedexAccount ? `Payment via FedEx account (${this.order.settings.fedexAccount})` : 'Payment via SGC Account';
    }

    ngOnInit() {


        this.userService.userProfile$.subscribe(user => this.currentUser = user);
        if (this.currentUser.isEmployee)
        {
            this.showLoginUserAddress = false;
        }

        this.orderTypeCode = this.checkoutService.getOrderTypeCode();

        if (this.showLoginUserAddress != false)
        {
            this.checkoutService.currentOrder$.subscribe(order =>
            {
                if (!order.allItemsWithDeclaredValue()) return;
                this.order = order;
                this.oldFedexAccount = this.order.settings.fedexAccount;
                this.userService.userAddresses$.subscribe(addresses =>
                {
                    this.userAddresses = addresses;
                    if (addresses.length == 0)
                    {
                        // this.openAddressEditor(new Address({ country: { code: 'US' }, state: { code: '' } }));
                    }
                    else
                    {
                        if (order.shippingAddress && order.shippingAddress.id)
                        {
                            this.selectedAddress = _.find(addresses, a => a.id == order.shippingAddress.getId())!;
                            this.checkoutService.getAvailableShippingMethods().subscribe(shippingMethods =>
                            {
                                this.shippingMethods = shippingMethods;
                                if (order.shippingMethod && order.shippingMethod.id) this.selectedShippingMethod = _.find(shippingMethods, sp => sp.id == order.shippingMethod.getId())!;
                                if (!this.selectedShippingMethod) this.checkoutService.unSetShippingMethod();
                            });
                        }
                        else
                        {
                            if (addresses.length >= 1) this.selectAddress(addresses[0]);
                        }
                    }
                });
            });
        }
        else
        {
            this.checkoutService.currentOrder$.subscribe(order =>
            {
                if (!order.allItemsWithDeclaredValue()) return;
                this.order = order;
                this.oldFedexAccount = this.order.settings.fedexAccount;
            });
        }


        this.checkoutService.changeCurrentpayTerms(false)
        var orderId = this.checkoutService.currentOrder.id;
        if (!orderId) return;
        this.orderService.getOrder(orderId).subscribe(order => {
            this.order = order;
            if (this.order.shippingAddress.phoneNumber) {
                this.showPhoneNumber = true;
                this.phoneNumber = this.order.shippingAddress.phoneNumber;
            }
        });
        this.orderTypeCode = this.checkoutService.getOrderTypeCode();

        //this.orderService.checkBusinessDayGur().subscribe(adminKPIExecutive => {
        //    this.adminKPIExecutive = adminKPIExecutive;
        //    this.checkoutService.newGuranteeDaysMsg(this.adminKPIExecutive);
        //});
    }

    // addAddress() {
    //     this.openAddressEditor(new Address({ country: { code: 'US' }, state: { code: '' } }));
    // }
    openAddressEditor(address?: Address) {
        this.modalService.open('address-editor', address);
    }

    selectAddress(address: Address) {
        // if (this.selectedAddress == address) return;
        // this.selectedAddress = address;
        // this.checkoutService.setShippingAddress(address).subscribe(order => {
        //     this.checkoutService.getAvailableShippingMethods().subscribe(shippingMethods => {
        //         this.shippingMethods = shippingMethods;
        //         if (order.shippingMethod && order.shippingMethod.id) this.selectedShippingMethod = _.find(shippingMethods, sp => sp.id == order.shippingMethod.getId());
        //         if (!this.selectedShippingMethod) this.checkoutService.unSetShippingMethod();
        //     });

        //     //this.checkoutService.getAvailableShippingMethods().subscribe(shippingMethods => {
        //     //    this.shippingMethods = shippingMethods;
        //     //});
        // });
    }

    //Contactus() {

    //
    //    this.orderService.isPaymentContactInfo$(true);
    //    //this.location.replaceState('/collector-support/contact-us');
    //    this.router.navigate(['collector-support/contact-us']);
    //    //this.router.navigate([`/collector-support`]);

    //}


}
