import { Component, OnInit } from '@angular/core';

import { ModalService, ModalInterface } from '../../../../common/services/modal.service';
import { OrderService } from '../../../../orders/services/order.service';

@Component({
    selector: 'set-card-options',
    templateUrl: './set-card-options.component.html',
    styleUrls: ['../../../checkout.component.sass']
})

export class SetCardOptionsComponent implements ModalInterface, OnInit {
    constructor(
        private modalService: ModalService,
        private orderService: OrderService
    ){}

    title = "Set card options";
    type = "set-card-options";
    loader!: boolean;
    options: any; // OrderItem
    data: any;

    isSubmitting: boolean = false;

    close() {
        this.modalService.close(this.type, 'close');
    }

    save() {
        this.isSubmitting = true;
        this.options.data = this.data;
        this.orderService.updateOrderItem(this.options).subscribe(() => {
            this.isSubmitting = false;
            this.close();
        });
    }

    canSave(): boolean {
        var isCrossoverSelected = this.data.crossover;
        var isReviewSelected = this.data.review;
        var isReholderSelected = this.data.reholder;
        var isRelabelSelected = this.data.relabel;
        var isCertificationCodeSelected = !!this.data.certificationCode;
        var isMinimumRequestedGrade = !!this.data.minimumRequestedGrade;
        var isEncapsulationOnly = !!this.data.encapsulationOnly;

        var crossover = isCrossoverSelected && isCertificationCodeSelected && isMinimumRequestedGrade;
        var review = isReviewSelected && isCertificationCodeSelected;
        var reholder = isReholderSelected && isCertificationCodeSelected;
        var relabel = isRelabelSelected && isCertificationCodeSelected;

        var isEmpty = !isCrossoverSelected && !isReviewSelected && !isRelabelSelected && !isReholderSelected && !isCertificationCodeSelected && !isMinimumRequestedGrade;

        return crossover || review || reholder || relabel || isEncapsulationOnly || isEmpty;
    }

    ngOnInit() {
        if(!this.options) return this.close();

        this.data = this.options.data;
    }

    setEncapsulationOnly(selection: boolean) {
        selection && (this.options.settings.encapsulationRequested = selection);
        this.data.encapsulationOnly = selection;
    }
}
