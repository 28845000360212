import * as _ from 'lodash';

export class PopulationReport {
  constructor(data?: any) {
    if (!data) return;

    this.Id = data.Id;
    this.Sport = data.Sport;
    this.Year = data.Year;
    this.Manufacturer = data.Manufacturer;
    this.CardSet = data.CardSet;
    this.CardNumber = data.CardNumber;
    this.CardGroup = data.CardGroup;
    this.CardSubject = data.CardSubject;
    this.CardDescription = data.CardDescription;
    //this.POP_Description_Override = data.POP_Description_Override;
    //this.MaybeCardDescription_Line3 = data.MaybeCardDescription_Line3;
    //this.MaybeCardDescription_Line4 = data.MaybeCardDescription_Line4;
    //this.MaybeCardDescription_CardDescription2 = data.MaybeCardDescription_CardDescription2;
    //this.MaybeCardDescription_InvoiceLineDescription = data.MaybeCardDescription_InvoiceLineDescription;
    //this.MaybeCardDescription_InvoiceLineDescription2 = data.MaybeCardDescription_InvoiceLineDescription2;
    //this.MaybeCardDescription_InvoiceLineComments = data.MaybeCardDescription_InvoiceLineComments;
    this.Grade10P = data.Grade10P;
    this.Grade10 = data.Grade10;
    this.Grade9pt5 = data.Grade9pt5;
    this.Grade9 = data.Grade9;
    this.Grade8pt5 = data.Grade8pt5;
    this.Grade8 = data.Grade8;
    this.Grade7pt5 = data.Grade7pt5;
    this.Grade7 = data.Grade7;
    this.Grade6pt5 = data.Grade6pt5;
    this.Grade6 = data.Grade6;
    this.Grade5pt5 = data.Grade5pt5;
    this.Grade5 = data.Grade5;
    this.Grade4pt5 = data.Grade4pt5;
    this.Grade4 = data.Grade4;
    this.Grade3pt5 = data.Grade3pt5;
    this.Grade3 = data.Grade3;
    this.Grade2pt5 = data.Grade2pt5;
    this.Grade2 = data.Grade2;
    this.Grade1pt5 = data.Grade1pt5;
    this.Grade1 = data.Grade1;
    this.GradeA = data.GradeA;
    this.HideIfIsSheetCut = data.HideIfIsSheetCut;

    this.Count10PH = data.Count10PH;
    this.Count10H = data.Count10H;
    this.Count9pt5H = data.Count9pt5H;
    this.Count9H = data.Count9H;
    this.Count8pt5H = data.Count8pt5H;
    this.Count8H = data.Count8H;
    this.Count7pt5H = data.Count7pt5H;
    this.Count7H = data.Count7H;
    this.Count6pt5H = data.Count6pt5H;
    this.Count6H = data.Count6H;
    this.Count5pt5H = data.Count5pt5H;
    this.Count5H = data.Count5H;
    this.Count4pt5H = data.Count4pt5H;
    this.Count4H = data.Count4H;
    this.Count3pt5H = data.Count3pt5H;
    this.Count3H = data.Count3H;
    this.Count2pt5H = data.Count2pt5H;
    this.Count2H = data.Count2H;
    this.Count1pt5H = data.Count1pt5H;
    this.Count1H = data.Count1H;
    this.GradeAH = data.GradeAH;

    this.sort = data.sort;
    //this.HideFromPOP = data.HideFromPOP;
    //this.CurrentAuthCode = data.CurrentAuthCode;
    //this.PossibleLegacyAuthCodeOrOtherGrader = data.PossibleLegacyAuthCodeOrOtherGrader;
    //this.IsReview = data.IsReview;
    //this.IsReholder = data.IsReholder;
    //this.GradeDate = data.GradeDate;
    //this.CustomerName = data.CustomerName;
    //this.PreviousGrader = data.PreviousGrader;
    //this.IsCrossover = data.IsCrossover;
    //this.IsEncapsulationOnly = data.IsEncapsulationOnly;
    //this.Subject1 = data.Subject1;
    //this.Subject2 = data.Subject2;
    //this.Subject3 = data.Subject3;
    //this.ManuIdNew = data.ManuIdNew;
    //this.SetIdNew = data.SetIdNew;
    //this.CardIDNew = data.CardIDNew;
    //this.LastModifiedOn = data.LastModifiedOn;
    //this.New_Id = data.New_Id;
    //this.Legacy_Id = data.Legacy_Id;
    //this.LegacyAuthCode = data.LegacyAuthCode;
    //this.AutoCertCode = data.AutoCertCode;
    //this.GradingServiceDescription = data.GradingServiceDescription;
    //this.SrNO = data.SrNO;
  }

  Id!: string;
  Sport!: string;
  Year!: string;
  Manufacturer!: string;
  CardNumber!: string;
  CardGroup!: string;
  CardSubject!: string;
  CardDescription!: string;
  CardSet!: string;
  //POP_Description_Override: string;
  //MaybeCardDescription_Line3: string;
  //MaybeCardDescription_Line4: string;
  //MaybeCardDescription_CardDescription2: string;
  //MaybeCardDescription_InvoiceLineDescription: string;
  //MaybeCardDescription_InvoiceLineDescription2: string;
  //MaybeCardDescription_InvoiceLineComments: string;
  Grade10P!: number;
  Grade10!: number;
  Grade9pt5!: number;
  Grade9!: number;
  Grade8pt5!: number;
  Grade8!: number;
  Grade7pt5!: number;
  Grade7!: number;
  Grade6pt5!: number;
  Grade6!: number;
  Grade5pt5!: number;
  Grade5!: number;
  Grade4pt5!: number;
  Grade4!: number;
  Grade3pt5!: number;
  Grade3!: number;
  Grade2pt5!: number;
  Grade2!: number;
  Grade1pt5!: number;
  Grade1!: number;
  GradeA!: number;
  HideIfIsSheetCut!: boolean;

  Count10PH!: number;
  Count10H!: number;
  Count9pt5H!: number;
  Count9H!: number;
  Count8pt5H!: number;
  Count8H!: number;
  Count7pt5H!: number;
  Count7H!: number;
  Count6pt5H!: number;
  Count6H!: number;
  Count5pt5H!: number;
  Count5H!: number;
  Count4pt5H!: number;
  Count4H!: number;
  Count3pt5H!: number;
  Count3H!: number;
  Count2pt5H!: number;
  Count2H!: number;
  Count1pt5H!: number;
  Count1H!: number;
  GradeAH!: number;
  sort!: number;
  //HideFromPOP : boolean;

  //CurrentAuthCode: string;
  //PossibleLegacyAuthCodeOrOtherGrader: string;
  //IsReview : boolean;
  //IsReholder : boolean;
  //GradeDate: Date;
  //CustomerName: string;
  //PreviousGrader: string;
  //IsCrossover : number;
  //IsEncapsulationOnly : number;
  //Subject1: string;
  //Subject2: string;
  //Subject3: string;
  //ManuIdNew : string;
  //SetIdNew : string;
  //CardIDNew : string;
  //LastModifiedOn: Date;
  //New_Id : string;
  //Legacy_Id : number;
  //LegacyAuthCode: string;
  //AutoCertCode: string;
  //GradingServiceDescription: string;
}

export const GradingValues: Array<any> = [
  {
    value: 'All',
    label: 'All',
  },
  {
    value: '10.5',
    label: '10 PRI',
  },
  {
    value: '10',
    label: '10',
  },
  {
    value: '9.5',
    label: '9.5',
  },
  {
    value: '9',
    label: '9',
  },
  {
    value: '8.5',
    label: '8.5',
  },
  {
    value: '8',
    label: '8',
  },
  {
    value: '7.5',
    label: '7.5',
  },
  {
    value: '7',
    label: '7',
  },
  {
    value: '6.5',
    label: '6.5',
  },
  {
    value: '6',
    label: '6',
  },
  {
    value: '5.5',
    label: '5.5',
  },
  {
    value: '5',
    label: '5',
  },
  {
    value: '4.5',
    label: '4.5',
  },
  {
    value: '4',
    label: '4',
  },
  {
    value: '3.5',
    label: '3.5',
  },
  {
    value: '3',
    label: '3',
  },
  {
    value: '2.5',
    label: '2.5',
  },
  {
    value: '2',
    label: '2',
  },
  {
    value: '1.5',
    label: '1.5',
  },
  {
    value: '1',
    label: '1',
  },
  {
    value: 'A',
    label: 'A',
  },
];

export class PopulationReportSearch {
  Sport!: string;
  CardSet!: string;
}

export class PopulationReportYear {
  sport!: string;
  year!: string;
  manufacturer!: string;
  cardSet!: string;
  CardGroup!: string;
  cardDescription!: string;
}

export class PopReportSendMessage {
  setname!: string;
  cardnumber!: string;
  subject!: string;
  description!: string;
  typeofissue!: string;
  firstname!: string;
  email!: string;
  certificationcode!: string;
  comments!: string;
  mobile!: string;
}

export class PopPipelineStatus {
  constructor(data?: any) {
    if (!data) return;

    this.Id = data.Id;
    this.PopPipelineRunning = data.PopPipelineRunning;
    this.PipelineStartTime = data.PipelineStartTime;
    this.PipelineEndTime = data.PipelineEndTime;
  }

  Id!: string;
  PopPipelineRunning!: boolean;
  PipelineStartTime!: Date;
  PipelineEndTime!: Date;
}

export class PopReportAuthCode {
  constructor(data?: any) {
    if (!data) return;

    (this.authCode = data.authCode),
      (this.cardNumber = data.cardNumber),
      (this.cardSet = data.cardSet),
      (this.cardDescription = data.cardDescription),
      (this.cardSubject = data.cardSubject),
      (this.sport = data.sport),
      (this.year = data.year),
      (this.manufacturer = data.manufacturer),
      (this.gradeDate = data.gradeDate),
      (this.grade10P = data.grade10P ? data.grade10P : null);
    this.grade10 = data.grade10 ? data.grade10 : null;
    this.grade9pt5 = data.grade9pt5 ? data.grade9pt5 : null;
    this.grade9 = data.grade9 ? data.grade9 : null;
    this.grade8pt5 = data.grade8pt5 ? data.grade8pt5 : null;
    this.grade8 = data.grade8 ? data.grade8 : null;
    this.grade7pt5 = data.grade7pt5 ? data.grade7pt5 : null;
    this.grade7 = data.grade7 ? data.grade7 : null;
    this.grade6pt5 = data.grade6pt5 ? data.grade6pt5 : null;
    this.grade6 = data.grade6 ? data.grade6 : null;
    this.grade5pt5 = data.grade5pt5 ? data.grade5pt5 : null;
    this.grade5 = data.grade5 ? data.grade5 : null;
    this.grade4pt5 = data.grade4pt5 ? data.grade4pt5 : null;
    this.grade4 = data.grade4 ? data.grade4 : null;
    this.grade3pt5 = data.grade3pt5 ? data.grade3pt5 : null;
    this.grade3 = data.grade3 ? data.grade3 : null;
    this.grade2pt5 = data.grade2pt5 ? data.grade2pt5 : null;
    this.grade2 = data.grade2 ? data.grade2 : null;
    this.grade1pt5 = data.grade1pt5 ? data.grade1pt5 : null;
    this.grade1 = data.grade1 ? data.grade1 : null;
    this.gradeA = data.gradeA ? data.gradeA : null;
    this.id = data.id ? data.id : null;
    this.migrationState = data.migrationState ? data.migrationState : null;
    this.variantGenerationState = data.variantGenerationState
      ? data.variantGenerationState
      : null;
    //this.populationReport = _.map(data.populationReport, item => new PopulationReport(item));
    this.popResultCount = data.popResultCount ? data.popResultCount : null;
  }

  authCode!: string;
  cardNumber!: string;
  cardSet!: string;
  cardDescription!: string;
  cardSubject!: string;
  sport!: string;
  year!: string;
  manufacturer!: string;
  gradeDate!: string;
  grade10P?: number;
  grade10?: number;
  grade9pt5?: number;
  grade9?: number;
  grade8pt5?: number;
  grade8?: number;
  grade7pt5?: number;
  grade7?: number;
  grade6pt5?: number;
  grade6?: number;
  grade5pt5?: number;
  grade5?: number;
  grade4pt5?: number;
  grade4?: number;
  grade3pt5?: number;
  grade3?: number;
  grade2pt5?: number;
  grade2?: number;
  grade1pt5?: number;
  grade1?: number;
  gradeA?: number;
  id!: string;
  migrationState?: number;
  variantGenerationState?: number;

  //populationReport = new Array<PopulationReport>();
  popResultCount?: number;
}
