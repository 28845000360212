import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthService } from '../services/auth.service';
import { StateService } from '../services/state.service';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(
        private authService: AuthService, 
        private stateService: StateService,
        private router: Router) 
        { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.checkLogin(state.url);
    }

    checkLogin(url: string): boolean {

        if (!this.authService.isAuthenticated()) {
            localStorage.setItem('redirectUrl', url);
            this.authService.login();
            return false;
        }

        if(!this.stateService.currentUser.has()) {
            this.router.navigate(["/welcome"]);
        }

        return true;
    }
}
