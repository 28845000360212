<div class="invoice">
    <div class="invoice__title">
        <h2>{{orderStatus}}</h2>

        <div class="invoice__actions">
            <button class="button button--secondary" (click)="back()">Go Back</button>
            <button class="button" (click)="print()">Print</button>
        </div>
        <img src="/assets/img/logoInvoice.svg" alt="SGC" class="invoice__logo" style="width:100px;">
    </div>

    <div class="invoice__content" style="grid-gap:12px;">
        <h3 style="margin-top:5px;">Dear <span style="text-transform:uppercase">{{order.customer.user.name}},</span></h3>
        <p>Thank you for your order. Please follow the instructions below. Please note our suite number has changed.</p>

        <h3 style="padding-top:10px;">STEP 1. SECURE YOUR CARDS</h3>
        <p>Secure your cards in semi-rigid card holders, like Card Saver I or Ultra Pro.</p>
        <p>We do recommend shipping your cards in top loaders because cards can shift during transit, damaging corners.</p>
        <p>Use cardboard as a backing and attach rubber bands to hold your cards in place.</p>
        <h3 style="padding-top:10px;">STEP 2. SHIP YOUR CARDS</h3>
        <p>Send your cards in a cardboard box or padded envelope.</p>
        <p>
            SGC is unable to provide an inbound shipping label because we do not know the size, weight, or insurance requirements of your shipment.
        </p>
        <p>
            We recommend shipping your cards using FedEx or USPS Certified Mail so that you have a tracking number.
        </p>
        <p>Please cut out the mailing address below to tape it to your package.</p>

        <div class="invoice__columns" style="grid-gap:0px;">
            <div class="invoice__section" style="border-left:none;">
                <p>OUR MAILING ADDRESS </p>
                <div style="border:dashed;padding-left:45px;padding-right:45px;padding-bottom:18px;padding-top:20px;">
                    <h4>
                        SGC<br>
                        951 W. Yamato Rd.<br>
                        Suite 150<br>
                        Boca Raton, FL 33431<br><br>
                        <span style="padding-left:75px;font-size:14px;text-transform:capitalize;">
                            {{AttnDept}}
                            <span style="font-style: italic;font-size:11px;">O# {{order.publicId}}</span>
                        </span>
                    </h4>
                </div>
            </div>
            <div class="invoice__section" style="border-left:none;">
                <p>YOUR USPS RETURN ADDRESS LABEL</p>
                <div style="border:dashed;padding-left:45px;padding-right:45px;padding-bottom:18px;padding-top:20px;">
                    <h4>
                        {{order.customer.user.name}}<br>
                        {{order.shippingAddress | streetFormat}}<br>
                        {{order.shippingAddress | cityStateCountryFormat: true}}<br>
                    </h4>
                </div>
                <p>
                    <img src="/assets/img/scissors.png" style="width: 50px; padding-top:10px;">
                </p>
            </div>
        </div>
        <h4>DOUBLE CHECK TO PACK YOUR PRINTED INVOICE AND ALL CARDS ON YOUR INVOICE IN THE BOX.</h4>
        <h3 style="padding-top:10px;">WHAT HAPPENS NEXT?</h3>
        <p>When we receive your package, you will receive an automated email from “no-reply&#64;gosgc.com.”</p>
        <p>When your package is sent back, you will receive an automated email with your tracking number. </p>
        <p>All packages are sent with signature confirmation.</p>
        <p>Thank you for submitting your cards to SGC.</p>
    </div>
    <div class="isInvoice" style="margin-bottom:40px;"></div>

    <div class="invoice__content">
        <h2>
            Order {{order.publicId}}
        </h2>
        <h3 style="font-size:35px; margin-top:-10px;"> {{order.customer.user.name}}</h3>
        <div style="margin-top:7px;">
            <div>
                <h3 *ngIf="orderTypeCode == 'card-grading'">{{getOrderTypeName() | dashForNoValueFormat}}</h3>
                <h3 *ngIf="orderTypeCode == '22BOWMANCHR'">{{this.getInvoiceHeader()}}</h3>
                <h3 *ngIf="orderTypeCode == '22POKEMON'">{{this.getInvoiceHeader()}}</h3>
                <h3 *ngIf="orderTypeCode == 'GRNOTES'">{{this.getInvoiceHeader()}}</h3>
                <h3 *ngIf="orderTypeCode == '23BOWMAN'">{{this.getInvoiceHeader()}}</h3>
                <h3 *ngIf="orderTypeCode == '23TOPPSS2'">{{this.getInvoiceHeader()}}</h3>
                <h3 *ngIf="orderTypeCode == 'FOOTBALL2023'">{{this.getInvoiceHeader()}}</h3>
                <h3 *ngIf="orderTypeCode == '24BASKETBALL'">{{this.getInvoiceHeader()}}</h3>
                <h3 *ngIf="orderTypeCode == '24TOPPSS2'">{{this.getInvoiceHeader()}}</h3>
                <h3 *ngIf="orderTypeCode == '23TOPPSCHR'">{{this.getInvoiceHeader()}}</h3>

                <h3 *ngIf="orderTypeCode == '23NSCCONSITE'">{{this.getInvoiceHeader()}} - {{getNsccOrderType(order)}}</h3>
                <h3 *ngIf="orderTypeCode == '23NSCCTAKEBACK'">{{this.getInvoiceHeader()}}</h3>
                <h3 *ngIf="orderTypeCode == '23BOWMANEOY'">{{this.getInvoiceHeader()}}</h3>


                <i *ngIf="order.submittedOn">Submitted on {{order.submittedOn | amLocal | amDateFormat: 'MMMM Do YYYY, h:mm:ss a'}}</i>
            </div>
            <p *ngIf="orderTypeCode != 'card-pre-grade'" style="margin-top:10px;">{{getPrintingInstruction()}}</p>

            <h4 *ngIf="orderTypeCode != 'card-pre-grade' && checkTypeForAddress(order)==false" style="margin-top:10px;">
                SGC<br>
                951 W. Yamato Rd<br>
                Suite 150<br>
                Boca Raton, FL 33431
            </h4>
            <!--<h4 *ngIf="orderTypeCode != 'card-pre-grade' && checkTypeForAddress(order)==true" style="margin-top:10px;">
                SGC<br>
                NSCC DROP OFF AND PICKUP<br>
                BOOTH 525<br>
                LOCATED NEXT TO HERITAGE AUCTIONS
            </h4>-->

            <h4 *ngIf="orderTypeCode == 'card-pre-grade'">
                Bring this invoice and cards to the SGC Booth at the NSCC.
                Verify that your email and phone number printed on this invoice are correct and you are able to receive notifications on your mobile device.  When your order is ready to be picked up, SGC will email, call and/or text.
            </h4>

            <div class="invoice__columns" style="margin-top:10px;">
                <div class="invoice__section">
                    <h3>Customer Information</h3>
                    <div class="invoice__section_customer">
                        <i>Name</i>
                        <p>{{ order.customer.user.name }}</p>
                    </div>
                    <div class="invoice__section_customer">
                        <i>Customer Number</i>
                        <p>{{ order.customer.user.customerNumber }}</p>
                    </div>
                    <div class="invoice__section_customer">
                        <i>Phone Number</i>
                        <p>{{ order.customer.user.phoneNumber }}</p>
                    </div>
                    <div class="invoice__section_customer">
                        <i>Email Address</i>
                        <p>{{ order.customer.user.email }}</p>
                    </div>
                </div>

                <div class="invoice__section">
                    <h3>Billing Information</h3>
                    <div>
                        <i>Promo Discount</i>
                        <p>{{order.fee.promo | currency: 'USD': 'symbol': '1.2-2' | dashForNoValueFormat}}</p>
                    </div>

                    <div>
                        <i>Service Fee</i>
                        <p>{{getTotalServiceFee() | currency: 'USD': 'symbol': '1.2-2' | dashForNoValueFormat}}</p>
                    </div>

                    <div>
                        <i>Shipping Fee</i>
                        <p>{{order.fee.shipping | currency: 'USD': 'symbol': '1.2-2' | dashForNoValueFormat}}</p>
                    </div>

                    <div>
                        <i><strong>Total</strong></i>
                        <p><b>{{order.fee.total | currency: 'USD': 'symbol': '1.2-2' | dashForNoValueFormat}}</b></p>
                    </div>
                </div>

                <div class="invoice__section">
                    <h3 *ngIf="orderTypeCode != '23NSCCONSITE'">Shipping Information</h3>
                    <h3 *ngIf="orderTypeCode == '23NSCCONSITE'">IF CANNOT BE PICKED UP, SHIP TO</h3>
                    <div class="invoice__shipping">
                        <p><strong>{{order.shippingAddress | streetFormat}}</strong></p>
                        <p>{{order.shippingAddress | cityStateCountryFormat: true}}</p>
                        <p *ngIf="hasAddressPhoneNumber()">{{getAddressPhoneNumber()}}</p>
                    </div>
                    <div>
                        <img src="{{getShippingMethodImageUrl()}}" alt="Shipping">
                        <h5 *ngIf="orderTypeCode == '23NSCCONSITE'">NSCC ONSITE PICKUP - $0</h5>
                        <h5 *ngIf="orderTypeCode != '23NSCCONSITE'">{{getShippingMethod()}} - $ {{order.fee.shipping}}</h5>
                    </div>
                    <div *ngIf="orderTypeCode != '23NSCCONSITE'">
                        <img src="{{getShippingMethodImageUrl()}}" alt="Shipping">
                        <h5>{{getShippingOptionText()}}</h5>
                    </div>
                </div>

                <div class="invoice__section">
                    <h3>Submission Details</h3>
                    <div>
                        <i>Total items added</i>
                        <p>{{getItemsCount()}}</p>
                    </div>
                    <div>
                        <i>Total Declared Value</i>
                        <p>{{order.totalDeclaredValue | currency: 'USD': 'symbol': '1.0-0'}}</p>
                    </div>
                    <div>
                        <i>Turnaround time</i>
                        <p>{{getTurnaround()}}</p>
                    </div>
                    <div *ngIf="hasImageAccountUrl() && ShowIcon" class="isInvoice" style="margin-top:15px;">
                        <h5>Download SellerReady Images</h5>
                        <p><a style="font-size:22px; margin-left:15px" routerLink="/account/sellerready"><strong>Click Here</strong></a></p>
                    </div>
                </div>
            </div>



        </div>
    </div>
    <div class="invoice__content">
        <div class="invoice__section invoice__items" >
            <h3>Items added</h3>

            <!-- Cards table -->
            <div class="invoice__table" *ngIf="isCardGrading()">
                <table class="invoice__table--cards">
                    <tr class="invoice__table-header">
                        <td>ID</td>
                        <td style="margin-right: 5px;">Name</td>
                        <td>Description</td>
                        <td>Card Services</td>
                        <td *ngIf="!showResults()">Value</td>
                        <td *ngIf="showResults()">Grade</td>
                        <td *ngIf="showResults()">Auth Code</td>
                        <td *ngIf="ShowIcon" class="isInvoice">Front Image</td>
                        <td *ngIf="ShowIcon" class="isInvoice">Back Image</td>
                        <td>Service Fee</td>
                    </tr>
                    <tr *ngFor="let item of getOrderItems(); let i = index">
                        <td>{{i + 1}}</td>
                        <td>
                            <span class="holder-img__hexagons" *ngIf="isAlteredItemsEncapsulated(item)">
                                <b>{{item.rejectionCode.code}}</b>
                                <img class="" src="{{getHexagonPath()}}">
                            </span>
                            {{getCardName(item) | cardNameFormat}}
                        </td>
                        <td>{{getCardDescription(item) | dashForNoValueFormat}}</td>
                        <td>
                            <div>
                                <span class="tag" *ngFor="let extraData of getCardExtraData(item)">{{extraData.label}}</span>
                                <span class="tag" *ngIf="item.settings.oversized==true">OSZ</span>
                            </div>
                        </td>


                        <td *ngIf="!showResults()">{{item.getDeclaredValue() | currency: 'USD': 'symbol': '1.0-0'}}</td>
                        <td *ngIf="showResults()">{{getGradeResult(item)}}</td>
                        <td *ngIf="showResults()">{{getAuthenticationCode(item)}}</td>

                        <td *ngIf="item.migrationState != 2 && item.variantGenerationState != 2 && ShowIcon" class="isInvoice">
                            <img src="/assets/img/FNotExist.png" alt="Not Exist" style="height: 32px;width: 32px;" />
                        </td>
                        <td *ngIf="item.migrationState != 2 && item.variantGenerationState != 2 && ShowIcon" class="isInvoice">
                            <img src="/assets/img/BNotExist.png" alt="Not Exist" style="height: 32px;width: 32px;" />
                        </td>
                        <td *ngIf="item.migrationState == 2 && item.variantGenerationState == 2 && ShowIcon" class="isInvoice">
                            <ng-template #Front style="height:unset !important;">
                                <img src="{{get275AuthImageFront(item.id)}}" alt="Not Exist" />
                            </ng-template>
                            <img src="/assets/img/FExist.png" alt="Exist" [tooltip]="$any(Front)" style="height: 32px;width: 32px;"  placement="top" contentType="template" />
                            
                        </td>
                        <td *ngIf="item.migrationState == 2 && item.variantGenerationState == 2 && ShowIcon" class="isInvoice">
                            <ng-template #Back style="height:1% !important;">
                                <img src="{{get275AuthImageBack(item.id)}}" alt="Not Exist" />
                            </ng-template>
                            <img src="/assets/img/BExist.png" alt="Exist" [tooltip]="$any(Back)" style="height: 32px;width: 32px;" contentType="template" />
                            
                        </td>
                        <td>{{item.getTotalFee() | currency: 'USD': 'symbol': '1.0-0'}}</td>
                    </tr>
                </table>
            </div>

            <!-- Autographs table -->
            <div class="invoice__table" *ngIf="isAutographAuthentication()">
                <table class="invoice__table--autographs">
                    <tr class="invoice__table-header">
                        <td>ID</td>
                        <td>Subject/s</td>
                        <td>Object</td>
                        <td>Card</td>
                        <td>Sticker</td>
                        <td>Grading</td>
                        <td>Declared Value</td>
                    </tr>
                    <tr *ngFor="let item of getOrderItems(); let i = index" [class.invoice__table-row-inverted]="showInvertedRowStyle(i)">
                        <td>{{i + 1}}</td>
                        <td>{{item.getSubjects() | listSubjectsFormat | dashForNoValueFormat}}</td>
                        <td>{{item.getSignableObjectName()}}</td>
                        <td>{{getCardName(item) | cardNameFormat}}</td>
                        <td>{{item | stickerPlacementFormat}}</td>
                        <td>{{item | autographGradingFormat}}</td>
                        <td>{{item.getDeclaredValue() | currency: 'USD': 'symbol': '1.0-0'}}</td>
                </table>
            </div>
        </div>
    </div>
</div>

<style type="text/css">
    @media only screen and (max-width: 770px) {
        .invoice__content {
            display: flex !important;
            flex-direction: column !important;
        }

            .invoice__content h2 {
                font-size: 32px !important;
            }

        .invoice__columns {
            display: flex !important;
            flex-direction: column !important;
        }

        ::ng-deep.invoice__table table td {
            padding: 10px 8px 0px 10px !important;
        }

        .invoice__section_customer {
            width: fit-content !important;
        }

            .invoice__section_customer p {
                font-size: 12px !important;
            }
    }

    @media print {

  div{
    break-inside: avoid;
  }
}
</style>
