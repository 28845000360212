import { Component } from '@angular/core';
import {
  ModalInterface,
  ModalService,
} from '../../../common/services/modal.service';
import { UserService } from '../../../common/services/user.service';
import { ErrorService } from '../../../common/services/error.service';

@Component({
  selector: 'edit-profile',
  templateUrl: './edit-profile.component.html',
})
export class EditProfileComponent implements ModalInterface {
  constructor(
    private modalService: ModalService,
    private userService: UserService,
    private errorService: ErrorService
  ) {
    
  }

  type = 'edit-profile';
  title = localStorage.getItem('title')!;
  options: any; // { label, user, isEditing}
  isSubmitting: boolean = false;
  

  close() {
    this.modalService.close(this.type, 'close');
  }

  isEditing(property: string): boolean {
    return this.options.editing == property;
  }

  save() {
    this.isSubmitting = true;
    switch (this.options.editing) {
      case 'email':
        this.userService.updateEmail(this.options.user).subscribe(
          () => {
            this.isSubmitting = false;
            this.close();
          },
          (error) => {
            this.errorService.handleError(error, 'EditProfile.email');
            this.isSubmitting = false;
          }
        );
        break;
      default:
        this.userService.updateProfile(this.options.user).subscribe(
          () => {
            this.isSubmitting = false;
            this.close();
          },
          (error) => {
            this.errorService.handleError(error, 'EditProfile.email');
            this.isSubmitting = false;
          }
        );
        break;
    }
  }
}
