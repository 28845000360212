import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TooltipModule } from 'ng2-tooltip-directive';
import { CurrencyMaskModule } from 'ng2-currency-mask';

import { AppCommonModule } from '../common/common.module';

import { CheckoutComponent } from './checkout.component';

import { CheckoutOrderComponent } from './views/checkout-order/checkout-order.component';
import { SelectExpertiseComponent } from './views/expertise/select-expertise.component';
import { AddItemsComponent } from './views/items/add-items.component';
import { SelectShippingComponent } from './views/shipping/select-shipping.component';
import { PayOrderComponent } from './views/payment/pay-order.component';

import { UseFedexAccountComponent } from './components/modals/use-fedex-account/use-fedex-account.component';
import { OrderPlacedComponent } from './views/confirmed/order-placed.component';
import { CheckoutTopNavComponent } from './components/checkout-top-nav/checkout-top-nav.component';
import { CheckoutBottomNavComponent } from './components/checkout-bottom-nav/checkout-bottom-nav.component';
import { CheckoutSideBarComponent } from './components/checkout-side-bar/checkout-side-bar.component';
import { OrdersModule } from '../orders/orders.module';
import { AddedCardItem } from './components/added-card-item/added-card-item.component';
import { CardResultItem } from './components/card-result-item/card-result-item.component';
import { AddedAutographItem } from './components/added-autograph-item/added-autograph-item.component';
import { SubjectResultItem } from './components/subject-result-item/subject-result-item.component';
import { ResultItem } from './components/result-item/result-item.component';
import { AddedOrderItem } from './components/added-order-item/added-order-item.component';
import { CheckoutRoutingModule } from './checkout-routing.module';
import { CheckoutService } from './services/checkout.service';

import { RouterModule } from '@angular/router';
import { OrderItemConfigurationService } from './services/order-item-configuration.services';
import { AddSignerComponent } from './components/modals/specify-autograph/add-signer/add-signer.component';
import { SelectAutographItemComponent } from './components/modals/specify-autograph/select-autograph-item/select-autograph-item.component';
import { SelectAutographServiceComponent } from './components/modals/specify-autograph/select-autograph-service/select-autograph-service.component';
import { SelectGradingComponent } from './components/modals/specify-autograph/select-grading/select-grading.component';
import { SelectStickerPlacementComponent } from './components/modals/specify-autograph/select-sticker-placement/select-sticker-placement.component';
import { ModalActionsComponent } from './components/modal-actions/modal-actions.component';
import { SelectPlanComponent } from './views/plans/select-plan.component';
import { AddAutographCardComponent } from './components/modals/specify-autograph/add-autograph-card/add-autograph-card.component';
import { EditAutographComponent } from './components/modals/edit-autograph/edit-autograph.component';
import { PersonalShippingAlertComponent } from './components/modals/personal-shipping-alert/personal-shipping-alert.component';
import { CheckoutStepGuard } from './guards/checkout-step.guard';
import { SetCardOptionsComponent } from './components/modals/set-card-options/set-card-options.component';
import { TermsAlertComponent } from './components/modals/terms-alert/terms-alert.component';
import { NoUserAddressAlertComponent } from './components/modals/no-user-address-alert/no-user-address-alert.component';
// import { OrderModule } from 'ngx-order-pipe';
import { GridModule } from '@syncfusion/ej2-angular-grids';
import { TabModule } from '@syncfusion/ej2-angular-navigations';
import { AddressComponent } from './views/address/address.component';
import { PaymentProcessComponent } from './views/payment-process/payment-process.component';
//import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    CheckoutOrderComponent,
    SelectExpertiseComponent,
    AddItemsComponent,
    SelectShippingComponent,
    PayOrderComponent,
    UseFedexAccountComponent,
    OrderPlacedComponent,
    CheckoutBottomNavComponent,
    CheckoutTopNavComponent,
    CheckoutSideBarComponent,
    AddedCardItem,
    CardResultItem,
    AddedAutographItem,
    SubjectResultItem,
    ResultItem,
    AddedOrderItem,
    CheckoutComponent,
    AddSignerComponent,
    SelectAutographItemComponent,
    SelectAutographServiceComponent,
    SelectGradingComponent,
    SelectStickerPlacementComponent,
    ModalActionsComponent,
    SelectPlanComponent,
    AddAutographCardComponent,
    EditAutographComponent,
    PersonalShippingAlertComponent,
    SetCardOptionsComponent,
    TermsAlertComponent,
    NoUserAddressAlertComponent,
    AddressComponent,
    PaymentProcessComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppCommonModule,
    OrdersModule,
    RouterModule,
    FormsModule,
    CurrencyMaskModule,
    // MatTooltipModule,
    CheckoutRoutingModule,
    ReactiveFormsModule,
    TooltipModule,
    // OrderModule,
    GridModule,
    TabModule,
  ],
  // entryComponents: [
  //   UseFedexAccountComponent,
  //   OrderPlacedComponent,
  //   AddSignerComponent,
  //   SelectAutographItemComponent,
  //   SelectAutographServiceComponent,
  //   SelectGradingComponent,
  //   SelectStickerPlacementComponent,
  //   AddAutographCardComponent,
  //   EditAutographComponent,
  //   PersonalShippingAlertComponent,
  //   SetCardOptionsComponent,
  //   TermsAlertComponent,
  //   NoUserAddressAlertComponent,
  // ],
  providers: [
    CheckoutService,
    OrderItemConfigurationService,
    CheckoutStepGuard,
  ],
})
export class CheckoutModule {}
