import { NgModule } from '@angular/core';
import { RouterModule, Routes, UrlSegment } from '@angular/router';

import { WelcomeComponent } from './views/welcome/welcome.component';
import { LoginComponent } from './views/login/login.component';
import { BrowserCompatibilityWarningComponent } from './views/browser-compatibility-warning/browser-compatibility-warning.component';
import { RegisterUserComponent } from './views/register-user/register-user.component';
import { SgcPrivacyComponent } from './views/sgc-privacy/sgc-privacy.component';
import { SgcTermsComponent } from './views/sgc-terms/sgc-terms.component';
import { SgcTermsConditionsComponent } from './views/sgc-terms-conditions/sgc-terms-conditions.component';
import { SgcPrivacyPolicyComponent } from './views/sgc-privacy-policy/sgc-privacy-policy.component';

export function aspxPages(url: UrlSegment[]) {
  return url.length === 1 && url[0].path.endsWith('.aspx')
    ? { consumed: url }
    : null;
}

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  // { path: 'register', component: RegisterUserComponent },
  { path: 'welcome', component: WelcomeComponent },

  {
    path: 'compatibility-warning',
    component: BrowserCompatibilityWarningComponent,
  },
  {   
    path: 'sgc-privacy', 
    component: SgcPrivacyComponent 
  },
  {   
    path: 'sgc-terms', 
    component: SgcTermsComponent 
  },
  { path: 'sgc-terms-conditions', component: SgcTermsConditionsComponent },
  { path: 'sgc-privacy-policy', component: SgcPrivacyPolicyComponent },
  {
    path: 'OurStaff.aspx',
    redirectTo: 'culture/grading-culture',
    pathMatch: 'full',
  },
  {
    path: 'GradingScale.aspx',
    redirectTo: 'how-it-works/card-grading',
    pathMatch: 'full',
  },
  {
    path: 'AutographGradingScale.aspx',
    redirectTo: 'how-it-works/autographs',
    pathMatch: 'full',
  },
  {
    path: 'ContactUs.aspx',
    redirectTo: 'culture/contact-us',
    pathMatch: 'full',
  },
  {
    path: 'GradingFees.aspx',
    redirectTo: 'how-it-works/card-grading',
    pathMatch: 'full',
  },
  {
    path: 'AuthenticationFees.aspx',
    redirectTo: 'how-it-works/autographs',
    pathMatch: 'full',
  },
  {
    path: 'IndividualSigners.aspx',
    redirectTo: 'how-it-works/individual-signers',
    pathMatch: 'full',
  },
  {
    path: 'CustomInserts.aspx',
    redirectTo: 'how-it-works/custom-inserts',
    pathMatch: 'full',
  },
  {
    path: 'Exclusions.aspx',
    redirectTo: 'how-it-works/cards-not-graded',
    pathMatch: 'full',
  },
  {
    path: 'DealerGallery.aspx',
    redirectTo: 'how-it-works/dealers',
    pathMatch: 'full',
  },
  {
    path: 'SendMessage.aspx',
    redirectTo: 'culture/contact-us',
    pathMatch: 'full',
  },
  {
    path: 'StorageBoxes.aspx',
    redirectTo: 'how-it-works/card-grading',
    pathMatch: 'full',
  },
  { matcher: aspxPages, redirectTo: '/' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class CommonRoutingModule {}
