<div class="wrapper">
    <h2>Hello {{currentUser.name}}</h2>
    <div class="user-links  mt-4">
        <div class="containerbox">
            <div class="row m-0">
                <div class="col-lg-4 col-md-6 col-sm-12 mb-5">
                    <a routerLink="/account/myorder" class="linkbox h-100  hover-black">
                        <div class="row align-items-center text-sm-center ">
                            <div class="col-12 col-sm-4">
                                <div class="figure">
                                    <img class="image-main" src="/assets/img/dashboard/myorder.png" alt="My order">
                                    <img class="image-hover" src="/assets/img/dashboard/myorder-hover.png" alt="my order">
                                </div>

                            </div>
                            <div class="col-12 col-sm-8">
                                <h3>My Orders</h3>
                                <p>Review all submitted orders, continue draft orders, and shipped orders & tracking numbers.</p>
                            </div>
                        </div>
                    </a>
                </div>

                <div class=" col-lg-4 col-md-6 col-sm-12 mb-5">
                    <a routerLink="/checkout"  (click)="toggleMobileMenu()" class="linkbox h-100  hover-indigo" style="color:#000000;background:#c0c0c0;">
                        <div class="row align-items-center text-sm-center ">
                            <div class="col-12 col-sm-4">
                                <div class="figure">
                                    <img class="image-main" src="/assets/img/dashboard/submission.png" alt="Submission">
                                    <img class="image-hover" src="/assets/img/dashboard/submission-hover.png" alt="Submission">
                                </div>
                            </div>
                            <div class="col-12 col-sm-8">
                                <h3 style="color:#000000;background:#c0c0c0;">Start new Submission</h3>
                                <p style="color:#000000;">Send your cards for grading, reholder, review or crossover.</p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class=" col-lg-4 col-md-6 col-sm-12 mb-5">
                    <a routerLink="/account/sellerready" class="linkbox h-100  hover-black">
                        <div class="row align-items-center text-sm-center ">
                            <div class="col-12 col-sm-4">
                                <div class="figure">
                                    <img class="image-main" src="/assets/img/dashboard/seller-ready.png" alt="Seller Ready">
                                    <img class="image-hover" src="/assets/img/dashboard/seller-ready-hover.png" alt="Seller Ready">
                                </div>
                            </div>
                            <div class="col-12 col-sm-8">
                                <h3>Seller  Ready</h3>
                                <p>
                                    View or download images of all of your SGC graded cards in high resolution.
                                </p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 mb-5">
                    <a
                        routerLink="/account/receipts"
                        class="linkbox h-100 hover-black"
                    >
                        <div class="row align-items-center text-sm-center">
                            <div class="col-12 col-sm-4">
                                <div class="figure">
                                    <img
                                        class="image-main"
                                        src="../../../../assets/img/dashboard/Dollar Icon - Black 2.1.png.png"
                                        alt="paypal"
                                    />
                                    <img
                                        class="image-hover"
                                        src="../../../../assets/img/dashboard/Dollar Icon - White 2.2.png.png"
                                        alt="paypal"
                                    />
                                </div>
                            </div>
                            <div class="col-12 col-sm-8">
                                <h3>Receipts</h3>
                                <p>
                                    View all accepted, pending and denied
                                    transactions.
                                </p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class=" col-lg-4 col-md-6 col-sm-12 mb-5">
                    <a routerLink="/account/needhelp" class="linkbox h-100  hover-black">
                        <div class="row align-items-center text-sm-center ">
                            <div class="col-12 col-sm-4">
                                <div class="figure">
                                    <img class="image-main" src="/assets/img/dashboard/help.png" alt="help">
                                    <img class="image-hover" src="/assets/img/dashboard/help-hover.png" alt="help">
                                </div>
                            </div>
                            <div class="col-12 col-sm-8">
                                <h3>I Need Help</h3>
                                <p>Get customer or technical support and restore deleted orders.</p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class=" col-lg-4 col-md-6 col-sm-12 mb-5">
                    <a routerLink="/account" class="linkbox h-100  hover-black">
                        <div class="row align-items-center text-sm-center ">
                            <div class="col-12 col-sm-4">
                                <div class="figure">
                                    <img class="image-main" src="/assets/img/dashboard/profile.png" alt="profile">
                                    <img class="image-hover" src="/assets/img/dashboard/profile-hover.png" alt="profile">
                                </div>
                            </div>
                            <div class="col-12 col-sm-8">
                                <h3>My Profile</h3>
                                <p>
                                    Add or edit addresses, email,
                                    and phone numbers.
                                </p>
                            </div>
                        </div>
                    </a>
                </div>
                <!--<div class=" col-lg-4 col-md-6 col-sm-12 mb-5">
                    <a routerLink="/account/my-cards" class="linkbox h-100  hover-black">
                        <div class="row align-items-center text-sm-center ">
                            <div class="col-12 col-sm-4">
                                <div class="figure">
                                    <img class="image-main" src="/assets/img/dashboard/seller-ready.png" alt="Seller Ready">
                                    <img class="image-hover" src="/assets/img/dashboard/seller-ready-hover.png" alt="Seller Ready">
                                </div>
                            </div>
                            <div class="col-12 col-sm-8">
                                <h3>My Cards</h3>
                                <p>
                                    View all your card images.
                                </p>

                            </div>
                        </div>
                    </a>
                </div>
                <div class=" col-lg-4 col-md-6 col-sm-12 mb-5">
                    <a routerLink="/account/my-cards-download" class="linkbox h-100  hover-black">
                        <div class="row align-items-center text-sm-center ">
                            <div class="col-12 col-sm-4">
                                <div class="figure">
                                    <img class="image-main" src="/assets/img/dashboard/seller-ready.png" alt="Seller Ready">
                                    <img class="image-hover" src="/assets/img/dashboard/seller-ready-hover.png" alt="Seller Ready">
                                </div>
                            </div>
                            <div class="col-12 col-sm-8">
                                <h3>Card Downloads</h3>
                                <p>
                                    View All Card Downloads.
                                </p>
                            </div>
                        </div>
                    </a>
                </div>-->
            </div>
        </div>

    </div>
</div>
<!--<div style="mrgin-top:-20px;">
    <img alt="SGC" src="/assets/img/CardWallpaper.jpg" style="width:100%;">

</div>-->
