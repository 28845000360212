import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dashForNoValueFormat'
})
export class DashForNoValueFormat {
    transform(value: any): string {

        if (!value) return "--";
        return value;
    }
}