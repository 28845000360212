import { Injectable } from '@angular/core';
import { CanActivateChild, Router } from '@angular/router';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { CheckoutService } from '../services/checkout.service';
import { Observable ,of} from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class CheckoutStepGuard implements CanActivateChild {

    constructor(
        private checkoutService: CheckoutService,
        private router: Router) 
        { }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

        var orderId = this.getOrderId(state);

        if(!orderId) return of(true);

        var segment = this.getUrlSegment(state);

        if(!segment) return of(true);

        var targetStep = this.checkoutService.getStepBySegment(segment);

        if(!targetStep) return of(true);

        return this.checkoutService.getOrder(orderId).pipe(
            map(o => {
              var order = o;
              
              this.checkoutService.currentOrder = o;
              
              var allowedStep = this.checkoutService.getAllowedStep(targetStep.stepNumber, order);
          
              if (targetStep != allowedStep) {
                this.checkoutService.goToStep(allowedStep.stepNumber, order);
              }
          
              return true;
            })
          ); 
    }

    getOrderId(state: RouterStateSnapshot) {
        if (!this.router.parseUrl(state.url).root.children['primary'].segments.length) return null;

        if (this.router.parseUrl(state.url).root.children['primary'].segments.length < 2) return null;
        
        return this.router.parseUrl(state.url).root.children['primary'].segments[1].path;
    }

    getUrlSegment(state: RouterStateSnapshot): string {
        if (!this.router.parseUrl(state.url).root.children['primary'].segments.length) return null!;

        if (this.router.parseUrl(state.url).root.children['primary'].segments.length < 3) return null!;
        
        return this.router.parseUrl(state.url).root.children['primary'].segments[2].path;
    }
}
