import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  Input,
  Compiler,
} from '@angular/core';
import { debounceTime } from 'rxjs';
import { FormControl } from '@angular/forms';
import { SearchService } from '../../../common/services/search.service';
import {
  OrderType,
  OrderItem,
  Order,
  AdminKPIExecutive,
  CardOrderItem,
} from '../../../orders/models/order';
import { OrderService } from '../../../orders/services/order.service';
import { CheckoutService } from '../../services/checkout.service';
// import { OrderPipe } from 'ngx-order-pipe';
// import "rxjs/add/operator/debounceTime";
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { ModalService } from '../../../common/services/modal.service';
import { GridComponent, GridLine } from '@syncfusion/ej2-angular-grids';
import { ColumnModel } from '@syncfusion/ej2-angular-grids';

@Component({
  selector: 'add-items',
  templateUrl: './add-items.component.html',
  styleUrls: ['../../checkout.component.sass', './add-items.component.css'],
})
export class AddItemsComponent implements OnInit, OnDestroy {
  @Input() index!: number;

  cardItem: CardOrderItem = new CardOrderItem();
  adminKPIExecutive = new AdminKPIExecutive();
  orderitemsort: string = 'item.id';
  showLabel: string = 'Hide Pricing Table';
  reverse: boolean = true;
  hide = true;

  sortedCollection: any[] = [];
  public StdColumns!: ColumnModel[];
  public StdColumnsBasketball!: ColumnModel[];
  public StdColumnsBaseball!: ColumnModel[];

  @ViewChild('grid')
  public lines!: GridLine;
  public grid!: GridComponent;

  ngOnInit() {
    debugger;
    this._compiler.clearCache();
    this.checkoutService.currentOrder$.subscribe((order) => {
      this.order = order;
      // if (this.order.items.length > 0 && this.checkoutService.addItemCount == 0) {
      //   this.order.items.reverse();
      // }
      this.order.setPlan(null);
      this.order.shippingMethod = null!;
      this.orderType = this.order.getOrderType();
      this.setFilterKind();
    });
    // this.orderService.checkBusinessDayGur().subscribe((adminKPIExecutive) => {
    //   this.adminKPIExecutive = adminKPIExecutive;
    //   this.checkoutService.newGuranteeDaysMsg(this.adminKPIExecutive);
    // });
    const itemAddedSubscription = this.checkoutService.itemAdded$.subscribe(
      (item) => {
        if (!this.searchResults) return;

        var match = _.find(this.searchResults, (x) => x == item.resultItem);
        if (!match) return;

        match.isAdding = false;
      }
    );

    this.subscription.add(itemAddedSubscription);

    const newCardAddedSubscription =
      this.checkoutService.newCardAdded$.subscribe((card) => {
        if (!card) return;

        var item = {
          id: card.id,
          kind: 'card',
        };
        this.selectResultItem(item);
      });

    this.subscription.add(newCardAddedSubscription);
    this.lines = 'Both';
    this.StdColumns = [
        //{
        //    field: "TotalCards",
        //    headerText: " CARDS ON ORDER",
        //    textAlign: "Center",
        //},
        //{
        //    field: "Fee",
        //    headerText: "GRADING FEE/CARD",
        //    textAlign: "Center",
        //},
        {
            field: "TotalCards",
            headerText: this.orderType.code == "23TOPPSCHR" ? "CARDS ON ORDER" : "STANDARD<br>(5-10 DAY)<br>",
            textAlign: "Center",
            disableHtmlEncode: false
        },
        {
            field: "Fee",
            headerText: this.orderType.code == "23TOPPSCHR" ? "GRADING FEE/CARD" : "IMMEDIATE<br>(1-2 DAY)<br>",
            textAlign: "Center",
            disableHtmlEncode: false
        },
    ];
    this.StdColumnsBasketball = [
        //{
        //    field: "TotalCards",
        //    headerText: " CARDS ON ORDER",
        //    textAlign: "Center",
        //},
        //{
        //    field: "Fee",
        //    headerText: "GRADING FEE/CARD",
        //    textAlign: "Center",
        //},
        {
            field: "TotalCards",
            headerText: this.orderType.code == "23TOPPSCHR" ? "CARDS ON ORDER" : "STANDARD<br>(5-10 Business Days)<br>",
            textAlign: "Center",
            disableHtmlEncode: false
        },
        // {
        //     field: "Fee",
        //     headerText: this.orderType.code == "23TOPPSCHR" ? "GRADING FEE/CARD" : "IMMEDIATE<br>(1-2 DAY)<br>",
        //     textAlign: "Center",
        //     disableHtmlEncode: false
        // },
    ];
    this.StdColumnsBaseball = [
        //{
        //    field: "TotalCards",
        //    headerText: " CARDS ON ORDER",
        //    textAlign: "Center",
        //},
        //{
        //    field: "Fee",
        //    headerText: "GRADING FEE/CARD",
        //    textAlign: "Center",
        //},
        {
            field: "TotalCards",
            headerText: this.orderType.code == "23TOPPSCHR" ? "CARDS ON ORDER" : "STANDARD<br>(5-10 Business Days)<br>",
            textAlign: "Center",
            disableHtmlEncode: false
        },
        // {
        //     field: "Fee",
        //     headerText: this.orderType.code == "23TOPPSCHR" ? "GRADING FEE/CARD" : "IMMEDIATE<br>(1-2 DAY)<br>",
        //     textAlign: "Center",
        //     disableHtmlEncode: false
        // },
    ];
    this.checkoutService.Errorcount();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  constructor(
    private _compiler: Compiler,
    private searchService: SearchService,
    private orderService: OrderService,
    private checkoutService: CheckoutService,
    private modalService: ModalService,
    // private orderPipe: OrderPipe
  ) {
    // this.sortedCollection = orderPipe.transform(this.order, 'item.id');
    this.searchTerm.valueChanges.pipe(debounceTime(500)).subscribe((data) => {
      this.search(data);
    });
  }

  selectResultItem(resultItem: any) {

    console.log(this.order.items);
    if (resultItem) {
      resultItem.isAdding = true;
      this.checkoutService.selectResultItem(resultItem);
      this.checkoutService.showFixissues = false;

      // this.checkoutService.errorCount++;
      //this.checkoutService.setshow(true);

      // if (this.checkoutService.errorCount > 0) {
      //     this.checkoutService.showFixissues = true;
      // } else {
      //     this.checkoutService.showFixissues = false;
      // }
    }
  }
  searchOptions = {
    limit: 10,
    offset: 0,
    filter: {
      query: null,
      kind: null as string | null,
      ordertype: null as string | null,
    },
    result: 0,
  };

  isSearching = false;
  searchResults = new Array<any>();
  searchTerm: FormControl = new FormControl();

  orderType = new OrderType();
  order = new Order();
  options = {
    callbacks: {
      removeItem: this.removeOrderItem.bind(this),
      searchItems: this.search.bind(this),
      updateOrderItem: this.updateOrderItem.bind(this),
    },
  };
  private subscription = new Subscription();

  // selectResultItem(resultItem: any) {
  //     resultItem.isAdding = true;
  //     this.checkoutService.selectResultItem(resultItem);
  // }
  addItemWithoutCard() {
    var item: any = {
      name:
        this.searchTerm && this.searchTerm.value
          ? this.searchTerm.value
          : 'No Card Selected',
      kind: 'card',
    };

    this.selectResultItem(item);
  }

  Show() {
    if (this.hide == false) {
      this.hide = true;
      this.showLabel = 'Hide Pricing Table';
    } else {
      this.hide = false;
      this.showLabel = 'Show Pricing Table';
    }
  }

  OpenPopUp() {
    this.modalService.open('app-cards-instructions');
  }

  encapsulationSelected(event: any) {
    this.order.settings.encapsulationRequested = event.target.checked;
    _.forEach(this.order.items, (item) => {
      item.settings.encapsulationRequested =
        this.order.settings.encapsulationRequested;
      //item.data.encapsulationOnly = this.order.settings.encapsulationRequested;
    });
    this.checkoutService.updateOrder();
  }

  search(data: any) {
    if (data) {
      if (data != this.searchOptions.filter.query) this.resetSearchOptions();
      this.searchOptions.filter.query = data;
      this.searchOptions.filter.ordertype = this.orderType.code;

      if (this.isSearching) return;

      this.isSearching = true;
      this.searchService
        .searchItemByKeyword(this.searchOptions)
        .subscribe((response: any) => {
          if (
            response &&
            response.filter &&
            response.filter.query != this.searchOptions.filter.query
          ) {
            this.isSearching = false;
            this.search(this.searchOptions.filter.query);
            return;
          }
          this.isSearching = false;
          this.updateSearchOptions(response);
          this.searchResults = response.items;
        });
    } else {
      this.resetSearchOptions();
      this.searchResults = [];
      this.isSearching = false;
    }
  }

  resetSearchOptions() {
    this.searchOptions.offset = 0;
    this.searchOptions.result = 0;
  }

  setFilterKind() {
    var kind = this.orderType
      ? this.orderType.isCardGrading() ||
        this.orderType.isRawCardReview() ||
        this.orderType.isBowmanChrome() ||
        this.orderType.isPokemanspecials() ||
        this.orderType.is24BASKETBALL() ||
        this.orderType.is24TOPPSS2() ||
        this.orderType.is23BOWMANEOY()
        ? 'card'
        : this.orderType.isAutographAuthentication()
        ? 'subject'
        : null
      : null;

    if (kind) this.searchOptions.filter.kind = kind;
  }

  updateSearchOptions(response: any) {
    this.searchOptions.offset += this.searchOptions.limit;
    this.searchOptions.filter.query = response.filter.query;
    this.searchOptions.result = response.items.length;
  }

  showLoadMore(): boolean {
    return Boolean(
      this.searchResults.length &&
        this.searchOptions.result == this.searchOptions.limit
    );
  }

  loadMore() {
    this.searchService
      .searchItemByKeyword(this.searchOptions)
      .subscribe((response: any) => {
        this.updateSearchOptions(response);
        this.searchResults = this.searchResults.concat(response.items);
      });
  }

  isAnyResult(): boolean {
    return this.searchResults != null && this.searchResults.length > 0;
  }

  getItemsCount(): number {
    return this.order.getItemsCount();
  }

  // Used for the customers to create an autograph order item without having to know the customer's
  createItemWithNoSubject() {
    this.selectResultItem({ kind: 'subject' });
  }

  isAutographAuthentication(): boolean {
    return this.orderType && this.orderType.isAutographAuthentication();
  }

  showEncapsulationOption(): boolean {
    return this.isCardGrading();
  }

  isCardGrading(): boolean {
    return (
      this.orderType &&
      (this.orderType.isCardGrading() ||
        this.orderType.isRawCardReview() ||
        this.orderType.isBowmanChrome() ||
        this.orderType.is23BOWMANEOY() ||
        this.orderType.isPokemanspecials() ||
        this.orderType.is24BASKETBALL() ||
        this.orderType.is24TOPPSS2() 
      )
    );
  }

  showAddItemWithoutCard(): boolean {
    return (
      this.isCardGrading() &&
      !this.isSearching &&
      !!this.searchOptions.filter.query
    );
  }

  removeOrderItem(orderItem: OrderItem) {
    this.checkoutService.removeOrderItem(orderItem);
  }

  updateOrderItem(orderItem: OrderItem) {
    this.checkoutService.updateOrderItem(orderItem);
  }

  getSearchInputLabel(): any {

    // console.log(this.orderType);
    if (this.orderType.isBowmanChrome()) {
        return "Search for 2022 Bowman Chrome cards";
    }
    else if (this.orderType.is23Bowman()) {
        return 'Search for 2023 Bowman Special Cards';
    }
    else if (this.orderType.isGRNOTES()) {
        return 'Search for Card(s)';
    }
    else if (this.orderType.is24BASKETBALL()) {
        return 'Search for Basketball Cards';
    }
    else if (this.orderType.is24TOPPSS2()) {
        return 'Search for 2024 Topps Series 2 Cards';
    }
    else if (this.orderType.isPokemanspecials()) {
        return "Search TCG cards";
    } else if (this.orderType.isToppsSeries2()) {
        return "Search for 2023 Topps Series 2 Cards ";
    } else if (this.orderType.isFootBall()) {
        return "Search for Football Cards to Add to Your Submission";
    } else if (this.orderType.is23TOPPSCHR()) {
        return "Search for 2023 Topps Chrome Cards";
    } else if (this.isCardGrading()) {
        return "Search for cards";
    } else {
        ("Search for signers");
    }
}


  getHintLabelForSearchInput(): string {
    if (
        this.orderType.isBowmanChrome() ||
        this.orderType.isGRNOTES()
    ) {
        return "START WITH SUBJECT AND CARD NUMBER, THEN ADD THE SET NAME. CLICK ON THE RESULT THAT BEST MATCHES YOUR CARD.";
    }
    else if (this.orderType.isPokemanspecials()) {
        return 'START WITH SUBJECT AND CARD NUMBER, THEN ADD THE SET NAME. IT IS OKAY IF YOUR CARD DOES NOT APPEAR IN THE SEARCH RESULTS. SIMPLY ENTER THE YEAR, MANUFACTURER, CARD NUMBER AND SUBJECT TO THE BEST OF YOUR ABILITY. THEN SELECT THE "CLICK HERE TO ADD YOUR CARD" LINK BELOW. PLEASE NOTE, SGC WILL CORRECT ANY INACCURACIES SO THAT ALL CARDS ARE LABELED CORRECTLY DURING PROCESSING.'
    }
    else if (this.orderType.isToppsSeries2()) {
        return 'START WITH THE PLAYER NAME AND CARD NUMBER, THEN ADD THE INSERT AND/OR PARALLEL, IF APPLICABLE. CLICK ON THE RESULT THAT BEST MATCHES YOUR CARD.';
    }
    else if (this.orderType.isFootBall() || this.orderType.is23TOPPSCHR()) {
        return 'START WITH A PLAYER NAME AND CARD NUMBER, THEN ADD THE INSERT AND/OR PARALLEL, IF APPLICABLE. IT IS OKAY IF YOUR CARD DOES NOT APPEAR IN THE SEARCH RESULTS. SIMPLY ENTER THE YEAR, MANUFACTURER, CARD NUMBER, PLAYER NAME, INSERT AND/OR PARALLEL TO THE BEST OF YOUR ABILITY. THEN SELECT THE "CLICK HERE TO ADD YOUR CARD" BUTTON BELOW. PLEASE NOTE, SGC WILL CORRECT ANY INACCURACIES SO THAT ALL CARDS ARE LABELED CORRECTLY DURING PROCESSING.';
    }
    else if (this.orderType.is23Bowman()) {
        return 'START WITH A PLAYER NAME AND CARD NUMBER, THEN ADD AN INSERT AND/OR PARALLEL, IF APPLICABLE. CLICK ON THE RESULT THAT BEST MATCHES YOUR CARD.';
    }
    else if (this.orderType.is24BASKETBALL()) {
        return 'START WITH A PLAYER NAME AND CARD NUMBER. THEN ADD THE INSERT AND/OR PARALLEL, IF APPLICABLE. IT IS OK IF YOUR CARD DOES NOT APPEAR IN THE SEARCH RESULTS. SIMPLY ENTER THE YEAR, MANUFACTURER, CARD NUMBER, PLAYER NAME, INSERT AND/OR PARALLEL TO THE BEST OF YOUR ABILITY. THEN SELECT THE “CLICK HERE TO ADD YOUR CARD” LINK BELOW. PLEASE NOTE, DURING PROCESSING, SGC WILL CORRECT ANY INACCURACIES SO THAT ALL CARDS ARE LABELED CORRECTLY.';
    }
    else if (this.orderType.is24TOPPSS2()) {
        return 'START WITH A PLAYER NAME AND CARD NUMBER. THEN ADD THE INSERT AND/OR PARALLEL, IF APPLICABLE. IT IS OK IF YOUR CARD DOES NOT APPEAR IN THE SEARCH RESULTS. SIMPLY ENTER THE YEAR, MANUFACTURER, CARD NUMBER, PLAYER NAME, INSERT AND/OR PARALLEL TO THE BEST OF YOUR ABILITY. THEN SELECT THE “CLICK HERE TO ADD YOUR CARD” LINK BELOW. PLEASE NOTE, DURING PROCESSING, SGC WILL CORRECT ANY INACCURACIES SO THAT ALL CARDS ARE LABELED CORRECTLY.';
    }
    else if (this.orderType.is23NSCCONSITE() || this.orderType.is23NSCCTAKEBACK()) {
        return 'START WITH A PLAYER NAME AND CARD NUMBER, THEN ADD THE INSERT AND/OR PARALLEL, IF APPLICABLE. IT IS OKAY IF YOUR CARD DOES NOT APPEAR IN THE SEARCH RESULTS. SIMPLY ENTER THE YEAR, MANUFACTURER, CARD NUMBER, PLAYER NAME, INSERT AND/OR PARALLEL TO THE BEST OF YOUR ABILITY. THEN SELECT THE "CLICK HERE TO ADD YOUR CARD" BUTTON BELOW. PLEASE NOTE, SGC WILL CORRECT ANY INACCURACIES SO THAT ALL CARDS ARE LABELED CORRECTLY DURING PROCESSING.';
    }
    else if (this.orderType.is23BOWMANEOY()) {
      return 'START WITH A PLAYER NAME AND CARD NUMBER FROM A 2022 OR 2023 BOWMAN SET, THEN ADD THE INSERT AND/OR PARALLEL, IF APPLICABLE. IT IS OK IF YOUR CARD DOES NOT APPEAR IN THE SEARCH RESULTS. SIMPLY ENTER THE YEAR, CARD NUMBER, PLAYER NAME, INSERT AND/OR PARALLEL TO THE BEST OF YOUR ABILITY. THEN SELECT THE “CLICK HERE TO ADD YOUR CARD” LINK BELOW. PLEASE NOTE, DURING PROCESSING, SGC WILL CORRECT ANY INACCURACIES SO THAT ALL CARDS ARE LABELED CORRECTLY.            '
  }
    else {
        return this.isCardGrading()
            ? "Start with a player name and card number. Then add the insert and/or parallel, if applicable. It is OK if your card does not appear in the search results. Simply enter the year, manufacturer, card number, player name, insert and/or parallel to the best of your ability. Then select the “Click Here To Add Your Card” link below. Please note, during processing, SGC will correct any inaccuracies so that all cards are labeled correctly."
            : "Click on the result that matches the signer for your item.";
    }
}




  getItemsCountStatement(): any {
    var itemsCount = this.order.getItemsCount();
    if (itemsCount == undefined) {
      return "Let's get started! Add your first card.";
    }

    if (this.orderType.isBowmanChrome() || this.orderType.isPokemanspecials() || this.orderType.is23Bowman() ||
    this.orderType.is23BOWMANEOY()) {
      if (itemsCount == 1) {
        return "You've added your first card! All items must have a declared value entered before proceeding to the next step. Use the search bar to add additional cards to your order and click 'Next' when finished.";
      } else {
        return (
          'You have a total of ' +
          itemsCount +
          " cards on your order. All items must have a declared value entered before proceeding to the next step. Use the search bar to add additional cards to your order and click 'Next' when finished."
        );
      }
    } else {
      if (itemsCount == 1) {
        return "You've added your first card! All items must have a declared value entered before proceeding to the next step. Use the search bar to add additional cards to your order and click 'Next' when finished.";
      }
      if (itemsCount == 2) {
        return 'You have a total of 2 cards on your order. Submit 8 more cards to have your order qualify for the reduced base grading fee of $22/card.';
      }
      if (itemsCount == 3) {
        return 'You have a total of 3 cards on your order. Submit 7 more cards to have your order qualify for the reduced base grading fee of $22/card.';
      }
      if (itemsCount == 4) {
        return 'You have a total of 4 cards on your order. Submit 6 more cards to have your order qualify for the reduced base grading fee of $22/card.';
      }
      if (itemsCount == 5) {
        return 'You have a total of 5 cards on your order. Submit 5 more cards to have your order qualify for the reduced base grading fee of $22/card.';
      }
      if (itemsCount == 6) {
        return 'You have a total of 6 cards on your order. Submit only 4 more cards to have your order qualify for the reduced base grading fee of $22/card.';
      }
      if (itemsCount == 7) {
        return 'You have a total of 7 cards on your order. Submit only 3 more cards to have your order qualify for the reduced base grading fee of $22/card.';
      }
      if (itemsCount == 8) {
        return 'You have a total of 8 cards on your order. Submit only 2 more cards to have your order qualify for the reduced base grading fee of $22/card.';
      }
      if (itemsCount == 9) {
        return 'You have a total of 9 cards on your order. Submit only 1 more card to have your order qualify for the reduced base grading fee of $22/card.';
      }
      if (itemsCount == 10) {
        return "You have a total of 10 cards on your order. Your order's base grading fee per card has now been reduced to $22!";
      }
      if (itemsCount == 11) {
        return 'You have a total of 11 cards on your order. Submit 9 more cards to have your order qualify for the reduced base grading fee of $20/card.';
      }
      if (itemsCount == 12) {
        return 'You have a total of 12 cards on your order. Submit 8 more cards to have your order qualify for the reduced base grading fee of $20/card.';
      }
      if (itemsCount == 13) {
        return 'You have a total of 13 cards on your order. Submit 7 more cards to have your order qualify for the reduced base grading fee of $20/card.';
      }
      if (itemsCount == 14) {
        return 'You have a total of 14 cards on your order. Submit 6 more cards to have your order qualify for the reduced base grading fee of $20/card.';
      }
      if (itemsCount == 15) {
        return 'You have a total of 15 cards on your order. Submit 5 more cards to have your order qualify for the reduced base grading fee of $20/card.';
      }
      if (itemsCount == 16) {
        return 'You have a total of 16 cards on your order. Submit only 4 more cards to have your order qualify for the reduced base grading fee of $20/card.';
      }
      if (itemsCount == 17) {
        return 'You have a total of 17 cards on your order. Submit only 3 more cards to have your order qualify for the reduced base grading fee of $20/card.';
      }
      if (itemsCount == 18) {
        return 'You have a total of 18 cards on your order. Submit only 2 more cards to have your order qualify for the reduced base grading fee of $20/card.';
      }
      if (itemsCount == 19) {
        return 'You have a total of 19 cards on your order. Based on the tiered pricing structure, it is not economical to stop now. Submit only 1 more card to receive an additional $2 discount on all cards in your order submitted at the base level.';
      }
      if (itemsCount == 20) {
        return "You have a total of 20 cards on your order. Your order's base grading fee per card has now been reduced to $20!";
      }
      if (itemsCount == 21) {
        return 'You have a total of 21 cards on your order. Submit a total of 50 cards or more to have your order qualify for the lowest base grading fee of $18/card.';
      }
      if (itemsCount == 22) {
        return 'You have a total of 22 cards on your order. Submit a total of 50 cards or more to have your order qualify for the lowest base grading fee of $18/card.';
      }
      if (itemsCount == 23) {
        return 'You have a total of 23 cards on your order. Submit a total of 50 cards or more to have your order qualify for the lowest base grading fee of $18/card.';
      }
      if (itemsCount == 24) {
        return 'You have a total of 24 cards on your order. Submit a total of 50 cards or more to have your order qualify for the lowest base grading fee of $18/card.';
      }
      if (itemsCount == 25) {
        return 'You have a total of 25 cards on your order. Submit a total of 50 cards or more to have your order qualify for the lowest base grading fee of $18/card.';
      }
      if (itemsCount == 26) {
        return 'You have a total of 26 cards on your order. Submit a total of 50 cards or more to have your order qualify for the lowest base grading fee of $18/card.';
      }
      if (itemsCount == 27) {
        return 'You have a total of 27 cards on your order. Submit a total of 50 cards or more to have your order qualify for the lowest base grading fee of $18/card.';
      }
      if (itemsCount == 28) {
        return 'You have a total of 28 cards on your order. Submit a total of 50 cards or more to have your order qualify for the lowest base grading fee of $18/card.';
      }
      if (itemsCount == 29) {
        return 'You have a total of 29 cards on your order. Submit a total of 50 cards or more to have your order qualify for the lowest base grading fee of $18/card.';
      }
      if (itemsCount == 30) {
        return 'You have a total of 30 cards on your order. Submit a total of 50 cards or more to have your order qualify for the lowest base grading fee of $18/card.';
      }
      if (itemsCount == 31) {
        return 'You have a total of 31 cards on your order. Submit a total of 50 cards or more to have your order qualify for the lowest base grading fee of $18/card.';
      }
      if (itemsCount == 32) {
        return 'You have a total of 32 cards on your order. Submit a total of 50 cards or more to have your order qualify for the lowest base grading fee of $18/card.';
      }
      if (itemsCount == 33) {
        return 'You have a total of 33 cards on your order. Submit a total of 50 cards or more to have your order qualify for the lowest base grading fee of $18/card.';
      }
      if (itemsCount == 34) {
        return 'You have a total of 34 cards on your order. Submit a total of 50 cards or more to have your order qualify for the lowest base grading fee of $18/card.';
      }
      if (itemsCount == 35) {
        return 'You have a total of 35 cards on your order. Submit a total of 50 cards or more to have your order qualify for the lowest base grading fee of $18/card.';
      }
      if (itemsCount == 36) {
        return 'You have a total of 36 cards on your order. Submit a total of 50 cards or more to have your order qualify for the lowest base grading fee of $18/card.';
      }
      if (itemsCount == 37) {
        return 'You have a total of 37 cards on your order. Submit a total of 50 cards or more to have your order qualify for the lowest base grading fee of $18/card.';
      }
      if (itemsCount == 38) {
        return 'You have a total of 38 cards on your order. Submit a total of 50 cards or more to have your order qualify for the lowest base grading fee of $18/card.';
      }
      if (itemsCount == 39) {
        return 'You have a total of 39 cards on your order. Submit a total of 50 cards or more to have your order qualify for the lowest base grading fee of $18/card.';
      }
      if (itemsCount == 40) {
        return 'You have a total of 40 cards on your order. Submit 10 more cards to have your order qualify for the lowest base grading fee of $18/card.';
      }
      if (itemsCount == 41) {
        return 'You have a total of 41 cards on your order. Submit 9 more cards to have your order qualify for the lowest base grading fee of $18/card.';
      }
      if (itemsCount == 42) {
        return 'You have a total of 42 cards on your order. Submit 8 more cards to have your order qualify for the lowest base grading fee of $18/card.';
      }
      if (itemsCount == 43) {
        return 'You have a total of 43 cards on your order. Submit 7 more cards to have your order qualify for the lowest base grading fee of $18/card.';
      }
      if (itemsCount == 44) {
        return 'You have a total of 44 cards on your order. Submit 6 more cards to have your order qualify for the lowest base grading fee of $18/card.';
      }
      if (itemsCount == 45) {
        return 'You have a total of 45 cards on your order. Based on the tiered pricing structure, it is not economical to stop now. Submit only 5 more cards to receive an additional $2 discount on all cards in your order submitted at the base level. ';
      }
      if (itemsCount == 46) {
        return 'You have a total of 46 cards on your order. Based on the tiered pricing structure, it is not economical to stop now. Submit only 4 more cards to receive an additional $2 discount on all cards in your order submitted at the base level. ';
      }
      if (itemsCount == 47) {
        return 'You have a total of 47 cards on your order. Based on the tiered pricing structure, it is not economical to stop now. Submit only 3 more cards to receive an additional $2 discount on all cards in your order submitted at the base level. ';
      }
      if (itemsCount == 48) {
        return 'You have a total of 48 cards on your order. Based on the tiered pricing structure, it is not economical to stop now. Submit only 2 more cards to receive an additional $2 discount on all cards in your order submitted at the base level. ';
      }
      if (itemsCount == 49) {
        return 'You have a total of 49 cards on your order. Based on the tiered pricing structure, it is not economical to stop now. Submit only 1 more card to receive an additional $2 discount on all cards in your order submitted at the base level. ';
      }
      if (itemsCount == 50) {
        return "You have a total of 50 cards on your order. Your order's base grading fee per card has now been reduced to $18!";
      }
      if (itemsCount >= 50) {
        return (
          'You have a total of ' +
          itemsCount +
          ' cards on your order. Your order qualifies for the lowest base grading fee of $18/card!'
        );
      }
    }
  }

  pokemons: Object[] = [
    {
        /*TotalCards: "Any quantity, any declared value, any service",*/
        TotalCards: "$12",
        Fee: "$40",
    },
];
bowman23: Object[] = [
    {
        TotalCards: "$12",
        Fee: "$40",
    }
]

bowman23N: Object[] = [
  {
      TotalCards: "$12",
      Fee: "$40",
  }
]
basketball24: Object[] = [
  {
      TotalCards: "$12",
      Fee: "$12",
  }
]
baseball24: Object[] = [
  {
      TotalCards: "$9",
      Fee: "$9",
  }
]



standardRawGrading: Object[] = [
    {
        TotalCards: "$15",
        Fee: "$40",
    }
    //{
    //    TotalCards: "10-19",
    //    Fee: "$22",
    //},
    //{
    //    TotalCards: "20-49",
    //    Fee: "$20",
    //},
    //{
    //    TotalCards: "50+",
    //    Fee: "$18",
    //},
];



  grading(): any {
    if (this.orderType.isPokemanspecials() || this.orderType.isFootBall()) {
        return this.pokemons;
    }
    else if (this.orderType.is23Bowman() || this.orderType.isToppsSeries2() || this.orderType.is23TOPPSCHR() || this.orderType.is23BOWMANEOY()) {
        return this.bowman23;
    }
    else if (this.orderType.is23BOWMANEOY()) {
      return this.bowman23N;
  }
    else if (this.orderType.is24BASKETBALL()) {
      return this.basketball24;
  }
    else if (this.orderType.is24TOPPSS2()) {
      return this.baseball24;
  }
    else {
        return this.standardRawGrading;
    }
}
}
