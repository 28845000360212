import { Component, OnInit } from "@angular/core";
import { CheckoutService } from "../../../checkout/services/checkout.service";
import { loadStripe, PaymentIntent, Stripe, StripeElement, StripeElements, StripePaymentElement } from '@stripe/stripe-js';
import { Router } from "@angular/router";
import { User } from "../../../common/models/user";
import { ActivatedRoute } from "@angular/router";
import { StateService } from "../../../common/services/state.service";
import { never } from "rxjs";
import { OrderService } from "../../../orders/services/order.service";
import { Order } from "../../../orders/models/order";
@Component({
  selector: 'app-payment-process',
  templateUrl: './payment-process.component.html',
  styleUrls: ['./payment-process.component.css']
})
export class PaymentProcessComponent implements OnInit {

    constructor(
        private orderService: OrderService,
        private checkoutService: CheckoutService,
        private route: ActivatedRoute,
        private router: Router,
        private stateService: StateService
    )
    { }

    order = new Order();
    currentUser = new User();
    isLoading: boolean = false;
    paymentType: any;
    saveCard: boolean = false;
    orderId: any;
    showCards: boolean = true;
    stripe: any;
    elements: any;
    stripeCustomerID!: string;
    customerPaymentIntent: any;
    error: boolean = false;
    errormessage!: string;
    saveErrorMessage(message: string): void {
        this.errormessage = message;
    }

    ngOnInit() {

        var _this = this;
        this.currentUser = this.stateService.currentUser.get();

        this.orderId = this.getOrderId();
        if (!this.orderId) return;

        this.checkoutService.getOrder(_this.orderId).subscribe(order => {

            this.order = order;
            // this.checkoutService.stripePaymentStep(order, 5);

            this.loadStripe();

            this.saveCard = false;
            this.getCustomerSaveCards();
        });

        //payment form submit call
        var form = document.getElementById('payment-form');
        form?.addEventListener('submit', function (e) {
            e.preventDefault();
            _this.isLoading = false;
            const setupFutureUsage = _this.saveCard ? "off_session" : "null";
            _this.orderService.updateStripeCustomer(_this.orderId, _this.paymentType, _this.customerPaymentIntent.id, setupFutureUsage, _this.stripeCustomerID).subscribe(res =>
            {
                _this.stripe.confirmPayment({ elements: _this.elements, redirect: "if_required" }).then((result:any) => {
                    if (result.paymentIntent) {
                        _this.processPayment(result.paymentIntent, result.paymentIntent.client_secret);
                    }
                    else {
                        // _this.navigateToStripeError(_this.orderId);
                        _this.error=true
                        if (
                            result.error.decline_code ===
                            "generic_decline"
                        ) {
                            const message =
                                "The transaction has been declined by your bank or our processor with no explanation provided. Please try again or try another card.";
                            return _this.saveErrorMessage(message);
                        }
                        if (
                            result.error.decline_code ===
                            "insufficient_funds"
                        ) {
                            const message =
                                "The card provided has insufficient funds. Please enter another card. ";
                            return _this.saveErrorMessage(message);
                        }
                        if (result.error.code === "incorrect_cvc") {
                            const message =
                                "The CSC number provided is invalid. Please check the CSC number and try again. ";
                            return _this.saveErrorMessage(message);
                        }
                        if (result.error.code === "processing_error") {
                            const message =
                                "An error occured while processing the card. Please try again. If the problem persists, try another card or contact support&#64;gosgc.com. ";
                            return _this.saveErrorMessage(message);
                        }
                        if (result.error.code === "expired_card") {
                            const message =
                                "The card provided has expired. Please check the card or enter a new card. ";
                            return _this.saveErrorMessage(message);
                        }
                        if (result.error.code === "incorrect_number") {
                            const message =
                                "The card number provided is invalid. Please check the card number and try again.";
                            return _this.saveErrorMessage(message);
                        }
                        if (
                            result.error.decline_code ===
                            "lost_card"
                        ) {
                            const message =
                            " THIS CARD WAS REPORTED LOST BY THE CARD ISSUER. PLEASE ENTER ANOTHER CARD."
                            return _this.saveErrorMessage(message);
                        }
                        if (
                            result.error.decline_code ===
                            "stolen_card"
                        ) {
                            const message =
                                "THIS CARD WAS REPORTED STOLEN BY THE CARD ISSUER. PLEASE ENTER ANOTHER CARD.";
                            return _this.saveErrorMessage(message);
                        }
                        else{
                            const message =
                            "An unexpected error occurs while processing your payment.";
                        return _this.saveErrorMessage(message);

                        }
                     }
                });
            });
        });
    }

    createStripePayment()
    {
        var _this = this;
        this.isLoading = false;
        var elements = this.stripe.elements({ clientSecret: this.customerPaymentIntent.clientSecret });
        var paymentElement = elements.create('payment', { wallets: { googlePay: 'never', applePay: 'never' } });
        paymentElement.mount('#payment-element');

        //google pay btn
        const paymentRequest = this.stripe.paymentRequest({
            country: 'US',
            currency: 'usd',
            total: {
                label: 'Amount',
                amount: this.customerPaymentIntent.amount,
            },
            requestPayerName: true,
            requestPayerEmail: true,
        });

        const prButton = elements.create('paymentRequestButton', { paymentRequest: paymentRequest, });
        paymentRequest.canMakePayment().then((r:any) => {
            prButton.mount('#payment-request-button');
        }).catch((e:any) => console.log(e));

        paymentRequest.on('paymentmethod', async (ev:any) => {
            var currentOrder = _this.checkoutService.currentOrder;
            var paymentMethodId = ev.paymentMethod.id;
            _this.checkoutService.googleAndApplePayPaymentReconciliation(_this.orderId, _this.customerPaymentIntent.id, currentOrder.fee.total).subscribe(res => {
                _this.checkoutService.getOrderForStripePaymentReconciliation().subscribe(order => {
                    if ((currentOrder.fee.total != order.fee.total) || (currentOrder.items.length != order.items.length)) {
                        ev.complete('success');
                        _this.checkoutService.stripePaymentMismatch(order);
                    }
                    else {
                        _this.stripe.confirmCardPayment(_this.customerPaymentIntent.clientSecret, { payment_method: paymentMethodId }, { handleActions: false }).then(function (result:any) {
                            if (result.paymentIntent) {
                                ev.complete('success');
                                _this.processPayment(result.paymentIntent, _this.customerPaymentIntent.clientSecret);
                            }
                            else {
                                ev.complete('fail');
                                _this.error = true
                            }
                        });
                    }
                });
            });
        });

        // change payment elements
        this.isLoading = true;
        paymentElement.on('change', (event:any) => {
            this.paymentType = event.value.type;
            if (this.paymentType == "card") {
                document.getElementById("saveCardChkbox")!.style.display = "block";
                document.getElementById("submit")!.style.display = "block";
            }
            else {
                document.getElementById("saveCardChkbox")!.style.display = "none";
                document.getElementById("submit")!.style.display = "none";
            }

            if (event.complete) { document.getElementById("submit")!.style.display = "block"; }
            else { document.getElementById("submit")!.style.display = "none"; }
        });

        this.elements = elements;
    }

    getCustomerSaveCards() {
        
        this.orderService.getCustomerSaveCards(this.orderId, this.order.fee.total, this.currentUser, this.order.publicId).subscribe(res => {
            if (res.success)
            {
                this.stripeCustomerID = res.stripeCustomerID;
                this.customerPaymentIntent = res.paymentIntent;
                if (res.cards && res.cards.length > 0) {
                    this.renderTable(res.cards);
                    document.getElementById('savedCards')!.style.visibility = "visible";
                    document.getElementById('payment-form')!.style.display = "none";
                    this.isLoading = true;
                }
                else {
                    document.getElementById('savedCards')!.style.display = "none";
                    document.getElementById('payment-form')!.style.display = "block"
                    this.createStripePayment();
                }
            }
            else {
                // this.navigateToStripeError(this.orderId);
                this.error=true

            }
        });
    }

    paymentWithSavedCard() {
        debugger;
        this.isLoading = false;
        var paymentMethodId = document.querySelector('input[name="card"]:checked')!.id;
        var paymentMethodType = "card";
        this.orderService.paymentWithSavedCard(this.customerPaymentIntent.id, this.currentUser, this.orderId, this.customerPaymentIntent.amount, paymentMethodId, paymentMethodType, this.stripeCustomerID).subscribe(res => {
            if (res.success)
            {
                const _this = this;
                const clientSecret = res.paymentIntent.clientSecret;
                _this.checkoutService.getOrderForStripePaymentReconciliation().subscribe(order =>
                {
                    var currentOrder = this.checkoutService.currentOrder;
                    if ((currentOrder.fee.total != order.fee.total) || (currentOrder.items.length != order.items.length)) {
                        this.checkoutService.stripePaymentMismatch(order);
                    }
                    else {
                        this.stripe.confirmCardPayment(clientSecret, { payment_method: paymentMethodId, }).then( (result:any) => {
                            if (result.paymentIntent) {
                                _this.processPayment(result.paymentIntent, clientSecret);
                            }
                            else {
                                // _this.navigateToStripeError(_this.orderId);
                                this.error = true
                            }
                        });
                    }
                });
            }
            else {
                // this.navigateToStripeError(this.orderId);
                this.error=true
            }
        }); 
    }

    saveCardDetails(event:any) {
        this.saveCard = event.target.checked;
    }

    getOrderId() {

        return this.checkoutService.currentOrder.id;
    }

    loadStripe() {
        this.orderService.getStripePublishableKey().subscribe(response => {
            loadStripe(response.publishableKey).then(
                stripe => {
                    this.stripe = stripe;
                });
        });
    }

    createNewPayment() {
        
        document.getElementById('savedCards')!.style.display = "block";
        document.getElementById('payment-form')!.style.display = "block";
        this.createStripePayment();
    }

    processPayment(paymentIntent: any, clientSecret: string) {
        if (paymentIntent.status == "succeeded" || paymentIntent.status == "processing") {
            this.navigateToStripeSuccess(paymentIntent.status);
        }
        else if (paymentIntent.status == "requires_action") {
            var _this = this;
            this.stripe.confirmCardPayment(clientSecret).then(function (data:any) {
                if (data.paymentIntent) { _this.navigateToStripeSuccess(data.paymentIntent.status); }
                else {
                    // _this.navigateToStripeError(_this.orderId);
                    _this.error=true
                 }
            });
        }
        else {
            // this.navigateToStripeError(this.orderId);
            this.error=true
        }
    }
    NavigateToStripeError() {
        return this.error;
    }

    navigateToStripeError(orderId: string) {
        this.isLoading = true;
        this.router.navigate([`/orders/${orderId}/stripe-error`]);
    }

    navigateToStripeSuccess(status: string) {
        this.isLoading = true;
        this.checkoutService.processStripePayment(status);
    }

    renderTable = (data:any) => {

        let html = "";
        let i = 0;
        data.forEach((x:any) => {
            let id = x.id;
            let { brand, last4 } = x.card;

            if (i == 0) {
                html += `<tr style="font-size:2rem;">
                        <td>
                            <input type="radio" id=${id} name="card" class="card" value=${id} checked="checked">
                        </td>
                        <td style="text-transform: uppercase;">${brand}</td>
                        <td>**** **** **** ${last4}</td>
                    </tr>
                    `
            }
            else {
                html += `<tr style="font-size:2rem;">
                        <td>
                            <input type="radio" id=${id} name="card" class="card" value=${id}>
                        </td>
                        <td style="text-transform: uppercase;">${brand}</td>
                        <td>**** **** **** ${last4}</td>
                    </tr>
                    `
            }
            i = i + 1;
        });
        document.getElementById('tcards')!.innerHTML = html;
    }
}
