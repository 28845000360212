import { Component, Input } from '@angular/core';
import {
  OrderItem,
  OrderType,
  CardOrderItem,
  AutographOrderItem,
} from '../../../orders/models/order';
@Component({
  selector: 'added-order-item',
  templateUrl: './added-order-item.component.html',
  styleUrls: ['../../checkout.component.sass'],
})
export class AddedOrderItem {
  // @Input() item?: CardOrderItem | AutographOrderItem;

  // @Input() item = new OrderItem();
  @Input() item: any;
  @Input() item2 = new AutographOrderItem();
  @Input() type = new OrderType();
  @Input() options: any;
  @Input() index!: number;

  isCard(): boolean {

    // console.log('typeeeeeeeeeee',this.type);
    return (
      this.type.isCardGrading() ||
      this.type.isRawCardReview() ||
      this.type.isBowmanChrome() ||
      this.type.isPokemanspecials() ||
      this.type.is23TOPPSCHR() || this.type.is23BOWMANEOY() || this.type.is24BASKETBALL() || this.type.is24TOPPSS2()
    );
  }

  isAutograph(): boolean {
    return this.type.isAutographAuthentication();
  }
}

// import { Component, Input } from '@angular/core';
// import { OrderItem, OrderType , CardOrderItem} from '../../../orders/models/order';

// @Component({
//   selector: 'added-order-item',
//   templateUrl: './added-order-item.component.html',
//   styleUrls: ['../../checkout.component.sass'],
// })
// export class AddedOrderItem {
//   // @Input() item = new OrderItem();
//   @Input() type = new OrderType();
//   @Input() options: any;
//   @Input() index!: number;

//   isCard(): boolean {
//     return (
//       this.type.isCardGrading() ||
//       this.type.isRawCardReview() ||
//       this.type.isBowmanChrome() ||
//       this.type.isPokemanspecials()
//     );
//   }

//   isAutograph(): boolean {
//     return this.type.isAutographAuthentication();
//   }

//   @Input() item!: CardOrderItem ;
//   // @Input()
//   // set item(item: any) {
//   //   if (this.isCard()) {
//   //     this._item = item as CardOrderItem;
//   //   } else {
//   //     this._item = item as OrderItem;
//   //   }
//   // }
//   // get item(): any {
//   //   return this._item;
//   // }
// }
