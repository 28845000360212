import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { CommonRoutingModule } from './common-routing.module';

import { ModalComponent } from './modals/modal.component';
import { SGCModalDirective } from './directives/modal.directive';
import { ModalService } from './services/modal.service';
import { WelcomeComponent } from './views/welcome/welcome.component';
import { LoginComponent } from './views/login/login.component';
import { AddressEditorComponent } from './modals/address-editor/address-editor.component';
import { ViewCardDetailsComponent } from './modals/view-card-details/view-card-details.component';
import { StateService } from './services/state.service';
import { UserService } from './services/user.service';
import { SearchService } from './services/search.service';
import { AuthService } from './services/auth.service';
import { MonitorService } from './services/monitor.service';
import { AuthGuard } from './guards/auth.guard';
import { ListSubjectsFormatPipe } from './pipe/list-subjects-format.pipe';
import { StreetFormatPipe } from './pipe/street-format.pipe';
import { CardNameFormatPipe } from './pipe/card-name-format.pipe';
import { CityStateCountryFormat } from './pipe/city-state-country-format.pipe';
import { StickerPlacementFormatPipe } from './pipe/sticker-placement-format.pipe';
import { AutographGradingFormatPipe } from './pipe/autograph-grading-format.pipe';
import { DashForNoValueFormat } from './pipe/dash-for-no-value-format.pipe';
import { ViewBioComponent } from './modals/view-bio/view-bio.component';
import { ApiService } from './services/api.service';
import { AutographServiceFormatPipe } from './pipe/autograph-service-format.pipe';
import { FeatureNotAvailableComponent } from './modals/feature-not-available/feature-not-available.component';
import { FeatureComingSoonComponent } from './modals/feature-coming-soon/feature-coming-soon.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoaderComponent } from './components/loader/loader.component';
import { OrderItemListComponent } from './components/order-item-list/order-item-list.component';
import { CardItemListComponent } from './components/card-item-list/card-item-list.component';
import { AutographItemListComponent } from './components/autograph-item-list/autograph-item-list.component';
import { CatalogService } from './services/catalog.service';
import { AlertService } from './services/alert.service';
import { ErrorService } from './services/error.service';
import { NewSiteAlertComponent } from './modals/new-site/new-site.component';
import { BooleanFormatPipe } from './pipe/boolean-format.pipe';
import { BrowserCompatibilityWarningComponent } from './views/browser-compatibility-warning/browser-compatibility-warning.component';
import { SessionService } from './services/session.service';

import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { ShippingInstructionComponent } from './modals/shipping-instruction/shipping-instruction.component';
import { CardsInstructionsComponent } from './modals/cards-instructions/cards-instructions.component';
import { CancelOrderComponent } from './modals/cancel-order/cancel-order.component';
import { PaymentTermsComponent } from './modals/payment-terms/payment-terms.component';
import { AlertAddressComponent } from './modals/alert-address/alert-address.component';
import { AlertAddAddressComponent } from './modals/alert-add-address/alert-add-address.component';
import { DeleteAddressComponent } from './modals/delete-address/delete-address.component';
import { ViewThumbnailImageComponent } from './modals/view-thumbnail-image/view-thumbnail-image.component';
import { ViewThumbnailFrontImageComponent } from './modals/view-thumbnail-front-image/view-thumbnail-front-image.component';
import { NoRightClickDirective } from './no-right-click.directive';

import { DeleteCardComponent } from './modals/delete-card/delete-card.component';
import { RegisterUserComponent } from './views/register-user/register-user.component';
import { SgcPrivacyComponent } from './views/sgc-privacy/sgc-privacy.component';
import { SgcTermsComponent } from './views/sgc-terms/sgc-terms.component';
import { SgcTermsConditionsComponent } from './views/sgc-terms-conditions/sgc-terms-conditions.component';
import { SgcPrivacyPolicyComponent } from './views/sgc-privacy-policy/sgc-privacy-policy.component';

@NgModule({
  declarations: [
    WelcomeComponent,
    LoginComponent,
    ModalComponent,
    SGCModalDirective,
    AddressEditorComponent,
    ViewCardDetailsComponent,
    ListSubjectsFormatPipe,
    ViewBioComponent,
    StreetFormatPipe,
    CardNameFormatPipe,
    CityStateCountryFormat,
    StickerPlacementFormatPipe,
    AutographGradingFormatPipe,
    DashForNoValueFormat,
    AutographServiceFormatPipe,
    FeatureNotAvailableComponent,
    FeatureComingSoonComponent,
    LoaderComponent,
    OrderItemListComponent,
    CardItemListComponent,
    AutographItemListComponent,
    NewSiteAlertComponent,
    BooleanFormatPipe,
    BrowserCompatibilityWarningComponent,
    ShippingInstructionComponent,
    CardsInstructionsComponent,
    CancelOrderComponent,
    PaymentTermsComponent,
    AlertAddressComponent,
    AlertAddAddressComponent,
    DeleteAddressComponent,
    DeleteCardComponent,
    ViewThumbnailImageComponent,
    ViewThumbnailFrontImageComponent,
    NoRightClickDirective,
    RegisterUserComponent,
    SgcPrivacyComponent,
    SgcTermsComponent,
    SgcTermsConditionsComponent,
    SgcPrivacyPolicyComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    CommonRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgIdleKeepaliveModule.forRoot(),
  ],
  providers: [
    ModalService,
    StateService,
    UserService,
    SearchService,
    AuthService,
    AuthGuard,
    ApiService,
    MonitorService,
    CatalogService,
    AlertService,
    ErrorService,
    SessionService,
  ],
  exports: [
    ModalComponent,
    AddressEditorComponent,
    ViewThumbnailImageComponent,
    ViewCardDetailsComponent,
    ListSubjectsFormatPipe,
    StreetFormatPipe,
    CardNameFormatPipe,
    CityStateCountryFormat,
    StickerPlacementFormatPipe,
    AutographGradingFormatPipe,
    DashForNoValueFormat,
    AutographServiceFormatPipe,
    LoaderComponent,
    OrderItemListComponent,
    CardItemListComponent,
    AutographItemListComponent,
    NewSiteAlertComponent,
    BooleanFormatPipe,
    CardsInstructionsComponent,
  ],
  // entryComponents: [
  //   AddressEditorComponent,
  //   ViewThumbnailImageComponent,
  //   ViewThumbnailFrontImageComponent,
  //   ViewCardDetailsComponent,
  //   ViewBioComponent,
  //   FeatureNotAvailableComponent,
  //   FeatureComingSoonComponent,
  //   NewSiteAlertComponent,
  //   ShippingInstructionComponent,
  //   CardsInstructionsComponent,
  //   CancelOrderComponent,
  //   PaymentTermsComponent,
  //   AlertAddressComponent,
  //   AlertAddAddressComponent,
  //   DeleteAddressComponent,
  //   DeleteCardComponent,
  // ],
})
export class AppCommonModule {}
