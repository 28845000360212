import { Component, OnInit, AfterViewChecked } from '@angular/core';
import { CheckoutService } from './services/checkout.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Order } from '../orders/models/order';

@Component({
  selector: 'checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.sass']
})
export class CheckoutComponent implements OnInit {

  constructor(
    private checkoutService: CheckoutService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  order!: Order;

  ngOnInit() {
    
    try {
      this.initSubmission();
      this.checkoutService.currentOrder$.subscribe(order => {
        this.order = order;
      });
    } catch (e) {
      // console.log("the error on ngonit is =", e)
    }

  }

  initSubmission() {
    var orderId = this.getOrderId();
    var segment = this.getUrlSegment();
    if(this.checkoutService.getinit()==true){
        segment='expertise'
    }
    if(localStorage.getItem("init")){
        var a=localStorage.getItem("init")
        if(a=="true"){
            segment='expertise'
            localStorage.removeItem("init")
        }
    }
    // console.log(segment);
    this.checkoutService.initCheckout(orderId!, segment);
}

  getOrderId() {
    if (!this.route.snapshot.children.length) return null;

    var params = this.route.snapshot.children[0].paramMap;

    if (!params.has('id')) return null;

    return params.get('id');
  }

  getUrlSegment(): string {
    if (!this.router.parseUrl(this.router.url).root.children['primary'].segments.length) return null!;

    if (this.router.parseUrl(this.router.url).root.children['primary'].segments.length < 3) return null!;

    // console.log(this.router.parseUrl(this.router.url).root.children['primary'].segments);

    return this.router.parseUrl(this.router.url).root.children['primary'].segments[2].path;
  }

  isLoading(): boolean {
    return !this.order || !this.order.id;
  }
}
