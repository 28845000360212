import { Component } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'browser-compatibility-warning',
    templateUrl: './browser-compatibility-warning.component.html'
})
export class BrowserCompatibilityWarningComponent {

    linkCopied: boolean = false;

    copyLink(){
        let selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = environment.sgc.webUrl;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);

        this.linkCopied = true;
    }
}
