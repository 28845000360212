export const environment = {
    auth0: {
      clientId: '525U3QhnyMhRCh081MFzKOIn736iZfny',
      domain: 'sgc-dev.auth0.com',
      callbackUrl: 'https://dev-sgc-web.azurewebsites.net/welcome',
      audience: 'https://api.sgccard.com'
    },
    sgc: {
      apiUrl: 'https://dev-customer-sgc-api.azurewebsites.net/v1',
      webUrl: 'https://dev-sgc-web.azurewebsites.net'
    },
    production: false,
    captcha: {
      siteKey: '6Lfu6OciAAAAAH5xhNUCSThso88lr5xNpKDy7fyB',
      secretKey: '6Lfu6OciAAAAAFFbmTLmqd65viNQTWdHTf3gkTa-'
    },  
    paypal: {
      env: 'sandbox'
    },
    appInsights: {
      instrumentationKey: '97b757b2-8ea1-4968-b726-79d29c01285d',
      verboseLogging: false,
      enableDebug: false
    },
    session: {
      idle: 7200,
      logoutTimeout: 10,
      keepAliveInterval: 1800
    }
  };
  
  
  
