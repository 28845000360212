import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as auth0 from 'auth0-js';
import { environment } from '../../../environments/environment';
import { Observable, from, BehaviorSubject } from 'rxjs';

@Injectable()
export class AuthService {
  auth0 = new auth0.WebAuth({
    clientID: environment.auth0.clientId,
    domain: environment.auth0.domain,
    responseType: 'token id_token',
    audience: environment.auth0.audience,
    redirectUri: environment.auth0.callbackUrl,
    // redirectUri: environment.auth0.goToCheckOut,
    scope: 'openid email profile'
  });

  constructor(public router: Router) {}

  private loggedInSource = new BehaviorSubject<any>(null);
  loggedIn$ = this.loggedInSource.asObservable();
  private loggedOutSource = new BehaviorSubject<any>(null);
  loggedOut$ = this.loggedOutSource.asObservable();

  public login(): void {
    this.auth0.authorize();
  }

  public handleAuthentication(): Observable<any> {
    const authPromise = new Promise((resolve, reject) => {
      this.auth0.parseHash((err: any, authResult: any) => {
        if (err) {
          this.logout();
          return reject(err);
        }

        const isAuthenticated = authResult && authResult.accessToken && authResult.idToken;

        if (isAuthenticated) {
          this.authenticateUser(authResult);
        }

        resolve(isAuthenticated);
      });
    });

    return from(authPromise);
  }

  private setSession(authResult: any): void {
    const expiresAt = JSON.stringify(authResult.expiresIn * 1000 + new Date().getTime());
    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('expires_at', expiresAt);
  }

  private authenticateUser(authResult: any) {
    this.setSession(authResult);
    this.sendLoggedInEvent();
  }

  sendLoggedInEvent() {
    this.loggedInSource.next({});
  }

  private clearSession() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('user');
  }

  public logout(): void {
    this.clearSession();
    this.loggedOutSource.next({});
    this.router.navigate(['/']);
  }

  public isAuthenticated(): boolean {
    const expiresAt = JSON.parse(localStorage.getItem('expires_at') ?? 'null');
    return new Date().getTime() < expiresAt;
  }

  checkSession() {
    this.auth0.checkSession({}, (err: any, authResult: any) => {
      this.setSession(authResult);
    });
  }
}
