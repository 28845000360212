<div class="added-card-item">
  <div
    class="added-card-item__actions"
    style="display: flex; justify-content: space-between; align-items: center"
  >
    <h3>{{ getCardName() | cardNameFormat }}</h3>
    <input
      type="image"
      src="/assets/img/trash.svg"
      class="button-icon"
      (click)="removeItem()"
    />
  </div>
  <div class="added-card-item__info">
    <p *ngIf="item.line3" style="padding-top: 8px">
      {{ item.line3 }}
    </p>
  </div>
  <div class="added-card-item__info">
    <input
      #editInput
      type="text"
      name="value-{{ index }}"
      #value="ngModel"
      [(ngModel)]="declaredValue"
      class="input main_input"
      placeholder="Enter Declared Value"
      currencyMask
      [options]="{ align: 'left', precision: '0' }"
      [class.input--submitted]="valueUpdated"
      [attr.tabindex]="index"
      (blur)="checkDeclaredValue()"
      [maxlength]="10"
      style="margin-top: 10px"
    />
    <div style="color: red; font-size: 12px; margin-bottom: 10px">
      {{ declarecaluemessage }}
    </div>
  </div>



  <div
         class="lumns"
         *ngIf="orderType.code == 'card-grading' || orderType.code == '23NSCCONSITE' || orderType.code == '23NSCCTAKEBACK' || orderType.code == '22POKEMON'"
         style="display: flex">
        <label
               class="toggle"
               style="padding-right: 20px"
               *ngIf="SelectCardOptions.value != 'Pre-Grade Encapsulation'">
            Encapsulate if altered
            <input
                   type="checkbox"
                   [checked]="item.settings.encapsulationRequested"
                   (change)="encapsulationSelected($event)"
                   />
            <span class="toggle__check toggle__check_2"></span>
        </label>
        <label
               class="toggle"
               [tooltip]="tooltips.oversized"
               content-type="template"
               *ngIf="SelectCardOptions.value != 'Pre-Grade Encapsulation'">
            Oversized Item
            <input
                   type="checkbox"
                   [checked]="item.settings.oversized"
                   (change)="overSizedSelected($event)"
                   />
            <span class="toggle__check toggle__check_2"></span>
        </label>
        <label
               class="toggle"
               [tooltip]="tooltips.onlyA"
               content-type="template"
               *ngIf="SelectCardOptions.value === ''"
               style="margin-left: 18px">
            Authentic
            <input
                   type="checkbox"
                   [ngModel]="item.data.encapsulationOnly"
                   (ngModelChange)="setEncapsulationOnly($event)"
            />
            <span class="toggle__check toggle__check_2"></span>
        </label>

        <!--<p><a class="link" (click)="setCardOptions()">{{getDataLabel()}}</a></p>-->
    </div>
    <div
    class="lumns"
    *ngIf="orderType.code == '23BOWMANEOY'"
    style="display: flex">
   <label
          class="toggle"
          style="padding-right: 20px"
          *ngIf="SelectCardOptions.value != 'Pre-Grade Encapsulation'">
       Encapsulate if altered
       <input
              type="checkbox"
              [checked]="item.settings.encapsulationRequested"
              (change)="encapsulationSelected($event)"
              />
       <span class="toggle__check toggle__check_2"></span>
   </label>
   <!-- <label
          class="toggle"

          content-type="template"
          *ngIf="SelectCardOptions.value != 'Pre-Grade Encapsulation'">
       Oversized Item
       <input
              type="checkbox"
              [checked]="item.settings.oversized"
              (change)="overSizedSelected($event)"
              />
       <span class="toggle__check toggle__check_2"></span>
   </label> -->
   <label
          class="toggle"
          [tooltip]="tooltips.onlyA"
          content-type="template"
          *ngIf="SelectCardOptions.value === ''"
          style="margin-left: 18px">
       Authentic
       <input
              type="checkbox"
              [ngModel]="item.data.encapsulationOnly"
              (ngModelChange)="setEncapsulationOnly($event)"
       />
       <span class="toggle__check toggle__check_2"></span>
   </label>

   <!--<p><a class="link" (click)="setCardOptions()">{{getDataLabel()}}</a></p>-->
</div>


    <div class="lumns"
         *ngIf="orderType.code == '23TOPPSS2' || orderType.code == 'FOOTBALL2023' || orderType.code == '24BASKETBALL' || orderType.code == '24TOPPSS2' || orderType.code == '23TOPPSCHR'"
         style="display: flex">
        <label class="toggle"
              [tooltip]="tooltips.onlyA"
               content-type="template"
               *ngIf="SelectCardOptions.value === ''"
               style="margin-left: 18px">
            Authentic
            <input type="checkbox"
                   [ngModel]="item.data.encapsulationOnly"
                   (ngModelChange)="setEncapsulationOnly($event)" />
            <span class="toggle__check toggle__check_2"></span>
        </label>

        <!--<p><a class="link" (click)="setCardOptions()">{{getDataLabel()}}</a></p>-->
    </div>

    <div class="added-card-item__info"
         *ngIf="orderType.code == 'card-grading' || orderType.code == '23NSCCONSITE' || orderType.code == '23NSCCTAKEBACK' || orderType.code == '22POKEMON'">
        <h3 class="ng-star-inserted" style="margin-top: 10px">
            Select Card Services
        </h3>
        <div class="select" style="width: 100%">
            <select
                    class="input"
                    [formControl]="SelectCardOptions"
                    (change)="changeCardServices(SelectCardOptions.value)">
                <option value="">Raw Card Grading</option>
                <option
                        *ngFor="let service of CardServices"
                        value="{{ service }}">
                    {{ service }}
                </option>
            </select>
        </div>
    </div>
    <div class="added-card-item__info"
    *ngIf=" orderType.code == '23BOWMANEOY'">
   <h3 class="ng-star-inserted" style="margin-top: 10px">
       Select Card Services
   </h3>
   <div class="select" style="width: 100%">
       <select
               class="input"
               [formControl]="SelectCardOptions"
               (change)="changeCardServices(SelectCardOptions.value)">
           <option value="">Raw Card Grading</option>
       </select>
   </div>
</div>
    <div
         class="added-card-item__info"
         *ngIf="orderType.code == '23BOWMAN' || orderType.code == 'GRNOTES'">
        <h3 class="ng-star-inserted" style="margin-top: 10px">
            Select Card Services
        </h3>
        <div class="select" style="width: 50%">
            <select
                    class="input"
                    [formControl]="SelectCardOptions"
                    (change)="changeCardServices(SelectCardOptions.value)">
                <option value="">Raw Card Grading</option>
                <option
                        *ngFor="let service of PokemonCardServices"
                        value="{{ service }}">
                    {{ service }}
                </option>
            </select>
        </div>
    </div>

    <div
         class="added-card-item__info"
         *ngIf="orderType.code == 'card-pre-grade'">
        <h3 class="ng-star-inserted" style="margin-top: 10px">
            Select Card Services
        </h3>
        <div class="select" style="width: 50%">
            <select
                    class="input"
                    [formControl]="SelectCardOptions"
                    (change)="changeCardServices(SelectCardOptions.value)">
                <option value="">RAW CARD REVIEW</option>
            </select>
        </div>
    </div>

    <div class="added-card-item__info"
         *ngIf="orderType.code == 'FOOTBALL2023' || orderType.code == '24BASKETBALL' || orderType.code == '24TOPPSS2' || orderType.code == '23TOPPSS2' || orderType.code == '23TOPPSCHR'">
        <h3 class="ng-star-inserted" style="margin-top: 10px">
            Select Card Services
        </h3>
        <div class="select" style="width: 50%">
            <select class="input"
                    [formControl]="SelectCardOptions"
                    (change)="changeCardServices(SelectCardOptions.value)">
                <option value="">Raw Card Grading</option>
            </select>
        </div>
    </div>

  <ng-template #OverSized>
    <p>
      If your card is larger than 3.5 x 5.5 inches, check this box for the
      correct pricing and shipping. If items are oversized and this box is not
      checked, you will be upcharged.
    </p>
  </ng-template>
  <ng-template #Crossover>
    <p>
      Crossovers applies only to cards not currently in SGC holders. When
      entering the Cert Code, please type PSA, BGS, etc. and then the
      certification code on the label.
    </p>
  </ng-template>
  <ng-template #PreGradeEncapsulation>
    <p>Pre-Grade Encapsulation</p>
  </ng-template>
  <ng-template #Review>
    <p>
      Reviewing applies only to cards currently in SGC holders. If a card
      receives a higher grade that changes the declared value to a higher
      pricing tier, you will be upcharged.
    </p>
  </ng-template>
  <ng-template #Reholder>
    <p>
      If the card does not get a bump in grade, your card will be reholdered
      with a new label.
    </p>
  </ng-template>
  <ng-template #Reholder2nd>
    <p>
      Reholdering applies only to cards currently in SGC holders. If your holder
      is completely broken or tampered with, you will be upcharged to a full
      grading fee.
    </p>
  </ng-template>
  <ng-template #OnlyA>
    <p>
      If checked, your item will not receive a numeric grade. If authentic, it
      will receive a grade of “A” Authentic.
    </p>
  </ng-template>
  <div class="modal__content" *ngIf="SelectCardOptions.value === 'Crossover'">
    <div class="modal__list">
      <div
        class="modal__list-columns"
        style="margin-top: 7px; margin-bottom: 7px"
      >
        <label class="toggle" [tooltip]="tooltips.crossover" content-type="template">
          Crossover
          <input
            type="checkbox"
            disabled="{{ CrossoverDisabled }}"
            [(ngModel)]="item.data.crossover"
          />
          <span class="toggle__check toggle__check_2"></span>
        </label>
        <label
          class="toggle"
          [tooltip]="tooltips.onlyA"
          content-type="template"
          style="margin-left: 18px"
        >
          Authentic
          <input
            type="checkbox"
            [ngModel]="item.data.encapsulationOnly"
            (ngModelChange)="setEncapsulationOnly($event)"
          />
          <span class="toggle__check toggle__check_2"></span>
        </label>
        <label class="toggle"> </label>
      </div>
      <div class="modal__list-columns">
        <div class="select" style="width: 70%">
          <select
            #minimumRequestedGrade
            class="input select"
            [(ngModel)]="item.data.minimumRequestedGrade"
            (change)="crossoverMRG($event)"
            #CrminimumRequestedGrade="ngModel"
            [ngClass]="{
              'light-red': CrminimumRequestedGrade.invalid
            }"
            required
          >
            <option value="">Minimum Grade Requested (Required)</option>

            <option value="{{ grade.label }}" *ngFor="let grade of grades">
              {{ grade.label }}
            </option>
          </select>
          <!-- <div
                        *ngIf="
                            CrminimumRequestedGrade.invalid &&
                            (CrminimumRequestedGrade.dirty ||
                                CrminimumRequestedGrade.touched)
                        "
                        style="color: red; font-size: 12px"
                    >
                        <div *ngIf="CrminimumRequestedGrade.errors.required">
                            Minimum Grade Requested Required.
                        </div>
                    </div> -->
        </div>
        <!-- <div
                    style="
                        color: red;
                        font-size: 12px;
                        margin-bottom: 7px;
                        margin-top: 7px;
                    "
                    *ngIf="CrminimumRequestedGrade.invalid"
                >
                    Minimum Grade Requested invalid
                </div> -->
        <input
          type="text"
          class="input"
          style="width: 70%"
          placeholder="PSA/BGS/Other Cert Code (Required)"
          [(ngModel)]="item.data.certificationCode"
          (blur)="crossoverCERC()"
          #CrcertificationCode="ngModel"
          required
          minlength="4"
          maxlength="50"
          #certificationCodeInput
          [class.error]="
            CrcertificationCode.invalid &&
            (CrcertificationCode.dirty || CrcertificationCode.touched)
          "
        />
        <div
          *ngIf="
            CrcertificationCode.invalid &&
            (CrcertificationCode.dirty || CrcertificationCode.touched)
          "
          style="color: red; font-size: 12px"
        >
          <!-- <div *ngIf="CrcertificationCode.errors.required">
                        PSA/BGS/Other Cert Code Required.
                    </div> -->
          <div *ngIf="CrcertificationCode.errors?.['minlength']">
            Enter Grading Company: Cert Code
          </div>
          <div
            *ngIf="CrcertificationCode.errors?.hasOwnProperty('maxlength')
            "
          >
          </div>
        </div>
        <!-- <div
                    style="
                        color: red;
                        font-size: 12px;
                        margin-bottom: 3px;
                        margin-top: 7px;
                    "
                    *ngIf="CrcertificationCode.invalid"
                >
                    PSA/BGS/Other Cert Code invalid
                </div> -->
      </div>
    </div>
    <div style="padding-left: 395px">
      <div *ngIf="isSubmitting" class="loader xs invert-color"></div>
      <!--<button class="button" id="Buttoncrossover" (click)="updateDeclaredValue()" [class.button--disabled]="!canSaveButtoncrossover" [disabled]="!canSaveButtoncrossover" *ngIf="CrcertificationCode.valid && CrminimumRequestedGrade.valid && declaredValue > 0">
                <div *ngIf="isSubmitting" class="loader xs invert-color"></div>
                <span *ngIf="!isSubmitting">Save</span>
            </button>-->
    </div>
  </div>
  <div class="modal__content" *ngIf="SelectCardOptions.value === 'Review'">
    <div class="modal__list">
      <div class="modal__list-columns">
        <label class="toggle" [tooltip]="tooltips.review" content-type="template">
          Review
          <input
            type="checkbox"
            disabled="{{ ReviewDisabled }}"
            [(ngModel)]="item.data.review"
          />
          <span class="toggle__check toggle__check_2"></span>
        </label>
        <label
          class="toggle"
          [tooltip]="tooltips.reholder"
          content-type="template"
          style="margin-left: 18px"
        >
          Reholder
          <input type="checkbox" [(ngModel)]="item.data.reholder" />
          <span class="toggle__check toggle__check_2"></span>
        </label>
        <label class="toggle"> </label>
      </div>
      <div class="modal__list-columns">
        <div class="select" style="width: 70%">
          <select
            #minimumRequestedGrade
            class="input select"
            [(ngModel)]="item.data.minimumRequestedGrade"
            (change)="reviewMRG($event)"
            #ReminimumRequestedGrade="ngModel"
            [ngClass]="{
              'light-red': ReminimumRequestedGrade.invalid
            }"
            required
          >
            <option value="">Minimum Grade Requested (Required)</option>
            <option value="{{ grade.label }}" *ngFor="let grade of grades">
              {{ grade.label }}
            </option>
          </select>

          <!-- <div
                        *ngIf="
                            ReminimumRequestedGrade.invalid &&
                            (ReminimumRequestedGrade.dirty ||
                                ReminimumRequestedGrade.touched)
                        "
                        style="color: red; font-size: 12px"
                    >
                        <div *ngIf="ReminimumRequestedGrade.errors.required">
                            Minimum Grade Requested Required.
                        </div>
                    </div> -->
        </div>
        <!-- <div
                    style="color: red; font-size: 12px"
                    *ngIf="ReminimumRequestedGrade.invalid"
                >
                    Minimum Grade Requested invalid
                </div> -->
        <input
          #certificationCodeInput
          type="text"
          class="input"
          style="width: 70%"
          placeholder="SGC Certification Code (Required)"
          [(ngModel)]="item.data.certificationCode"
          (blur)="reviewCERC()"
          #RecertificationCode="ngModel"
          required
          [pattern]="certificationCodePattern"
          minlength="7"
          maxlength="12"
          [class.error]="
            RecertificationCode.invalid &&
            (RecertificationCode.dirty || RecertificationCode.touched)
          "
        />
        <div
          *ngIf="
            RecertificationCode.invalid &&
            (RecertificationCode.dirty || RecertificationCode.touched)
          "
          style="color: red; font-size: 12px"
        >
          <!-- <div *ngIf="RecertificationCode.errors.required">
                        SGC Certification Code Required.
                    </div> -->
          <!-- <div *ngIf="RecertificationCode.errors.pattern">
            SGC Certification Code format is invalid
          </div> -->
          <div *ngIf="RecertificationCode.errors?.['pattern']">
            SGC Certification Code format is invalid
          </div>

          <!-- <div *ngIf="RecertificationCode.errors.minlength">
                        Enter 7-Digit or 11-Character SGC Cert Code.
                    </div>
                    <div *ngIf="RecertificationCode.errors.maxlength">
                        Enter 7-Digit or 11-Character SGC Cert Code.
                    </div> -->
        </div>
        <!-- <div
                    style="color: red; font-size: 12px"
                    *ngIf="RecertificationCode.invalid"
                >
                    SGC Certification Code Required invalid
                </div> -->
      </div>
    </div>
    <div style="padding-left: 395px">
      <div *ngIf="isSubmitting" class="loader xs invert-color"></div>
      <!--<button class="button" id="Buttonreview" (click)="updateDeclaredValue()" [class.button--disabled]="!canSaveButtonreview" [disabled]="!canSaveButtonreview" *ngIf="RecertificationCode.valid && ReminimumRequestedGrade.valid">
                <div *ngIf="isSubmitting" class="loader xs invert-color"></div>
                <span *ngIf="!isSubmitting">Save</span>
            </button>-->
    </div>
  </div>
  <div class="modal__content" *ngIf="SelectCardOptions.value === 'Reholder'">
    <div class="modal__list">
      <div class="modal__list-columns">
        <!-- <label
          class="toggle"

          matTooltip="Reholder2nd"
          content-type="template"
          [options]="{ placement: 'left' }"
        >
          Reholder
          <input
            type="checkbox"
            disabled="{{ ReholderDisabled }}"
            [(ngModel)]="item.data.reholder"
          />
          <span class="toggle__check toggle__check_2"></span>
        </label> -->

        <label
          class="toggle"
          [tooltip]="tooltips.reholder2nd"
          [options]="{ placement: 'left' }"
        >
          Reholder
          <input
            type="checkbox"
            [disabled]="ReholderDisabled"
            [(ngModel)]="item.data.reholder"
          />
          <span class="toggle__check toggle__check_2"></span>
        </label>

        <label
          class="toggle"
          [tooltip]="tooltips.onlyA"
          content-type="template"
          *ngIf="item.data.encapsulationOnly === true"
          style="margin-left: 18px"
        >
          Authentic
          <input
            type="checkbox"
            [ngModel]="item.data.encapsulationOnly"
            (ngModelChange)="setEncapsulationOnly($event)"
          />
          <span class="toggle__check"></span>
        </label>
        <label class="toggle"> </label>
      </div>
      <div class="modal__list-columns">
        <input
          #certificationCodeInput
          type="text"
          class="input"
          placeholder="SGC Certification Code (Required)"
          (blur)="reholderCERC()"
          style="width: 70%"
          [(ngModel)]="item.data.certificationCode"
          #ReHcertificationCode="ngModel"
          required
          [pattern]="certificationCodePattern"
          minlength="7"
          maxlength="12"
          [class.error]="
            ReHcertificationCode.invalid &&
            (ReHcertificationCode.dirty || ReHcertificationCode.touched)
          "
        />
        <div
          *ngIf="
            ReHcertificationCode.invalid &&
            (ReHcertificationCode.dirty || ReHcertificationCode.touched)
          "
          style="color: red; font-size: 12px"
        >
          <!-- <div *ngIf="ReHcertificationCode.errors.required">
                        Enter Grading Company: Cert Code
                    </div> -->
          <!-- <div *ngIf="ReHcertificationCode.errors.pattern">
            SGC Certification Code format is invalid
          </div> -->
          <div
            *ngIf="
              ReHcertificationCode.errors &&
              ReHcertificationCode.errors.hasOwnProperty('pattern')
            "
          >
            SGC Certification Code format is invalid
          </div>

          <!-- <div *ngIf="ReHcertificationCode.errors.minlength">
                        Enter Grading Company: Cert Code
                    </div>
                    <div *ngIf="ReHcertificationCode.errors.maxlength">
                        Enter Grading Company: Cert Code
                    </div> -->
        </div>
        <!-- <div
                    style="color: red; font-size: 12px"
                    *ngIf="ReHcertificationCode.invalid"
                >
                    SGC Certification Code invalid
                </div> -->
      </div>
    </div>
    <div style="padding-left: 395px">
      <div *ngIf="isSubmitting" class="loader xs invert-color"></div>
      <!--<button class="button" id="Buttonreholder" (click)="updateDeclaredValue()" [class.button--disabled]="!canSaveButtonreholder" [disabled]="!canSaveButtonreholder" *ngIf="ReHcertificationCode.valid">
                <div *ngIf="isSubmitting" class="loader xs invert-color"></div>
                <span *ngIf="!isSubmitting">Save</span>
            </button>-->
    </div>
  </div>

  <div
    class="modal__content"
    *ngIf="SelectCardOptions.value === 'Pre-Grade Encapsulation'"
  >
    <div class="modal__list">
      <div class="modal__list-columns">
        <label
          class="toggle"
          [tooltip]="tooltips.preGradeEncapsulation"
          content-type="template"
        >
          Pre-Grade Encapsulation
          <input
            type="checkbox"
            disabled="{{ PreGradeEncapsulationDisabled }}"
            [(ngModel)]="item.data.pregradeEncapsulation"
          />
          <span class="toggle__check toggle__check_2"></span>
        </label>
        <!-- <label
          class="toggle"
          [tooltip]="OnlyA"

          content-type="template"
          style="margin-left: 18px"
        >
          Authentic
          <input
            type="checkbox"
            [ngModel]="item.data.encapsulationOnly"
            (ngModelChange)="setEncapsulationOnly($event)"
          />
          <span class="toggle__check toggle__check_2"></span>
        </label> -->
        <label
          class="toggle"
          [tooltip]="tooltips.onlyA"
          style="margin-left: 18px"
          content-type="template"
        >
          Authentic
          <input
            type="checkbox"
            [ngModel]="item.data.encapsulationOnly"
            (ngModelChange)="setEncapsulationOnly($event)"
          />
          <span class="toggle__check toggle__check_2"></span>
        </label>

        <label class="toggle"> </label>
      </div>
      <div class="modal__list-columns">
        <!--<div class="select" style="width: 70%;">
                    <select class="input select" [(ngModel)]="item.data.minimumRequestedGrade" (change)="preGradeEncapsulationMRG($event.target.value)" #CrminimumRequestedGrade="ngModel" required>
                        <option value="">SGC Pre-Grade Grade</option>
                        <option value="{{grade.label}}" *ngFor="let grade of grades">{{grade.label}}</option>
                    </select>
                    <div *ngIf="CrminimumRequestedGrade.invalid && (CrminimumRequestedGrade.dirty || CrminimumRequestedGrade.touched)" style="color: red;font-size: 12px;">
                        <div *ngIf="CrminimumRequestedGrade.errors.required">
                            SGC Pre-Grade Grade Required.
                        </div>
                    </div>
                </div>
                <div style="color: red;font-size: 12px;" *ngIf="CrminimumRequestedGrade.invalid">SGC Pre-Grade Grade invalid</div>-->

        <input
          type="text"
          class="input"
          style="width: 70%"
          placeholder="SGC Pre-Grade Grade"
          [(ngModel)]="item.data.minimumRequestedGrade"
          (blur)="preGradeEncapsulationMRG()"
          #CrminimumRequestedGrade="ngModel"
          minlength="1"
          maxlength="6"
        />
        <div style="color: red; font-size: 12px">
          <!--<div *ngIf="CrminimumRequestedGrade.errors.required">SGC Pre-Grade Grade Required.</div>
                    <div *ngIf="CrminimumRequestedGrade.errors.minlength">SGC Pre-Grade Grade must between 1 and 6 characters</div>-->
        </div>
        <!--<div style="color:red;font-size: 12px;" *ngIf="CrminimumRequestedGrade.invalid">SGC Pre-Grade Grade invalid</div>-->

        <input
          type="text"
          class="input"
          style="width: 70%"
          placeholder="SGC Pre-Grade Cert Code"
          [(ngModel)]="item.data.certificationCode"
          (blur)="preGradeEncapsulationCERC()"
          #CrcertificationCode="ngModel"
          required
          minlength="1"
          maxlength="6"
        />
        <div
          *ngIf="
            CrcertificationCode.invalid &&
            (CrcertificationCode.dirty || CrcertificationCode.touched)
          "
          style="color: red; font-size: 12px"
        >
          <div *ngIf="CrcertificationCode.errors?.hasOwnProperty('required')">
            SGC Pre-Grade Cert Code Required.
          </div>
          <div
            *ngIf="
              CrcertificationCode.errors &&
              CrcertificationCode.errors.hasOwnProperty('minlength')
            "
          >
            SGC Pre-Grade Cert Code must between 1 and 6 characters
          </div>
          <div
            *ngIf="
              CrcertificationCode.errors &&
              CrcertificationCode.errors.hasOwnProperty('minlength')
            "
          >
            SGC Pre-Grade Cert Code must be between 1 and 6 characters
          </div>
        </div>
        <div
          style="color: red; font-size: 12px"
          *ngIf="CrcertificationCode.invalid"
        >
          SGC Pre-Grade Cert Code invalid
        </div>
      </div>
    </div>
    <div style="padding-left: 395px">
      <div *ngIf="isSubmitting" class="loader xs invert-color"></div>
    </div>
  </div>

  <div class="modal__content" *ngIf="SelectCardOptions.value === ''">
    <div class="modal__list" style="display: none">
      <div class="modal__list-columns">
        <div class="select" style="width: 70%">
          <!-- <select
                        class="input select"
                        [(ngModel)]="item.data.minimumRequestedGrade"
                        (change)="defaultMRG($event.target.value)"


                    >
                        <option value="">
                            Minimum Requested Grade (Optional)
                        </option>
                        <option
                            value="{{ grade.label }}"
                            *ngFor="let grade of grades"
                        >
                            {{ grade.label }}
                        </option>
                    </select> -->
          <select
            class="input select"
            [(ngModel)]="item.data.minimumRequestedGrade"
            (change)="defaultMRG($event)"
          >
            <option value="">Minimum Requested Grade (Optional)</option>
            <option value="{{ grade.label }}" *ngFor="let grade of grades">
              {{ grade.label }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div style="padding-left: 395px">
      <div *ngIf="isSubmitting" class="loader xs invert-color"></div>
      <!--<button class="button" id="Buttondefault" (click)="updateDeclaredValue()" [class.button--disabled]="!canSaveButtondefault" [disabled]="!canSaveButtondefault" *ngIf="declaredValue > 0">
                <div *ngIf="isSubmitting" class="loader xs invert-color"></div>
                <span *ngIf="!isSubmitting">Save</span>
            </button>-->
    </div>
  </div>
</div>

<style type="text/css">
  /* .input[type="text"]:not(:placeholder-shown) {
        background-color: transparent;
    } */

  .input[type="text"]:placeholder-shown {
    background-color: lemonchiffon;
  }

  .light-red {
    background-color: lemonchiffon; /* light red background color */
  }
  .input.error {
    background-color: lemonchiffon;
  }
  /* @media only screen and (max-width: 770px) {
        .added-card-item {
            width: 164px;
        }
        .main_input {
            width: 148px;
        }
        .lumns {
            display: flex;
            flex-direction: column;
            align-items: baseline;
        }
    } */
</style>
