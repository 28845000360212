import { Component } from '@angular/core';

@Component({
  selector: 'app-left-nav-layout',
  templateUrl: './left-nav-layout.component.html',
  styleUrls: ['./left-nav-layout.component.sass']
})
export class LeftNavLayoutComponent {

}
