<img src="/assets/img/close-modal.svg" class="modal__close" (click)="close()">

<div class="bio-details">
    <div class="avatar">
        <img src="/assets/img/team/{{options.code}}.jpg">
    </div>
    <div class="bio-details__info">
        <h2>
            {{options.name}}
            <span>{{options.role}}</span>
        </h2>

        <p class="bio-details__description">
            {{options.bio}}
        </p>

    </div>
</div>