import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MomentModule } from 'ngx-moment';

import { PopReportsService } from './services/pop-reports.service';
import { AppCommonModule } from '../common/common.module';
import { PopReportsRoutingModule } from './pop-reports-routing.module';
import { CommonModule } from '@angular/common';
import { PopReportsComponent } from './views/pop-reports/pop-reports.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  GridModule,
  SearchService,
  ToolbarService,
  AggregateService,
  ResizeService,
  PageService,
  SortService,
  FilterService,
  GroupService,
} from '@syncfusion/ej2-angular-grids';
import { PopReportresultComponent } from './views/pop-reportresult/pop-reportresult.component';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ToolbarModule } from '@syncfusion/ej2-angular-navigations';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import {
  RadioButtonModule,
  ButtonModule,
} from '@syncfusion/ej2-angular-buttons';
import {
  SidebarModule,
  ContextMenuModule,
} from '@syncfusion/ej2-angular-navigations';
import { PopreportSendmessageComponent } from './popreport-sendmessage/popreport-sendmessage.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import {
  TextBoxModule,
  NumericTextBoxModule,
} from '@syncfusion/ej2-angular-inputs';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { PopreportMasterpopComponent } from './popreport-masterpop/popreport-masterpop.component';
import { CheckAuthCodeComponent } from './check-auth-code/check-auth-code.component';
import { AuthCodeQuestionsComponent } from './auth-code-questions/auth-code-questions.component';
import { AuthNoRightClickDirective } from './auth-no-right-click.directive';
import { environment } from '../../environments/environment';



@NgModule({
  imports: [
    AppCommonModule,
    BrowserModule,
    PopReportsRoutingModule,
    CommonModule,
    MomentModule,
    GridModule,
    FormsModule,
    ReactiveFormsModule,
    NgxCaptchaModule,
    UiSwitchModule,
    ToolbarModule,
    DropDownListModule,
    RadioButtonModule,
    SidebarModule,
    ButtonModule,
    ContextMenuModule,
    TextBoxModule,
    NumericTextBoxModule,
    DialogModule,
  ],
  declarations: [
    PopReportsComponent,
    PopReportresultComponent,
    PopreportSendmessageComponent,
    PopreportMasterpopComponent,
    CheckAuthCodeComponent,
    AuthCodeQuestionsComponent,
    AuthNoRightClickDirective,
  ],
  providers: [
    PopReportsService,
    ResizeService,
    PageService,
    SortService,
    FilterService,
    AggregateService,
    GroupService,
    SearchService,
    ToolbarService,
  ],
})
export class PopReportsModule {}
