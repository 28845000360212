<div class="table">
  <table class="table--8 table--review">
    <tr class="table__header">
      <td>ID</td>
      <td>Subject/s</td>
      <td>Object</td>
      <td>Card</td>
      <td>Sticker</td>
      <td>Grading</td>
      <td>Declared Value</td>
      <td>Service Fee</td>
    </tr>
    <tr *ngFor="let items2 of items2; let i = index">
      <td>{{ i + 1 }}</td>
      <td>
        {{ items2.getSubjects() | listSubjectsFormat | dashForNoValueFormat }}
      </td>
      <td>{{ items2.getSignableObjectName() }}</td>
      <td>{{ getCardName(items2) | cardNameFormat }}</td>
      <td>{{ items2 | stickerPlacementFormat }}</td>
      <td>{{ items2 | autographGradingFormat }}</td>
      <td>
        {{
          items2.getDeclaredValue()
            | currency : "USD" : "symbol" : "1.0-0"
            | dashForNoValueFormat
        }}
      </td>
      <td>
        {{
          items2.totalFee
            | currency : "USD" : "symbol" : "1.0-0"
            | dashForNoValueFormat
        }}
      </td>
    </tr>
  </table>
</div>
