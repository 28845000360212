import { Injectable } from '@angular/core';
import { StateService } from '../../common/services/state.service';

@Injectable()
export class CardGradingService {
    constructor(
        private stateService: StateService
    ){}

    submenuItems = [
        {
            name: "Submissions",
            path: "/card-grading/submissions"
        },
        {
            name: "Services And Pricing",
            path: "/card-grading/services-pricing"
        }
    ];

    activateSubMenu() {
        this.stateService.activateSubMenu(this.submenuItems);
    }

    deactivateSubMenu() {
        this.stateService.deactivateSubMenu();
    }
}