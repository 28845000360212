<div
    class="promo-banner-adjustment"
    style="padding-bottom: 100px"
>
    <div class="invoice__section" style="margin-top: 30px">
        <div style="margin-left: 50px">
            <h2 *ngIf="isLoading">STEP 7. PAYMENT</h2>
            <h3 *ngIf="isLoading" style="padding-top: 15px">
                PAYMENT INSTRUCTIONS
            </h3>
            <p
                *ngIf="isLoading"
                style="text-transform: inherit !important; padding-top: 10px"
            >
                To pay with a credit or debit card, fill in your credit card
                number, expiration, CVC, country and zip/postal code. When
                complete, a PAY NOW button will appear beneath your payment
                method.
            </p>
            <p
                *ngIf="isLoading"
                style="
                    text-transform: inherit !important;
                    padding-top: 10px;
                    padding-bottom: 10px;
                "
            >
                Alternatively, you may checkout with a 3rd party Wallet like
                Apple Pay or Google Pay, if given the option.
            </p>
            <p *ngIf="isLoading" style="text-transform: inherit !important">
                If you want to retain your credit or debit card information so
                future checkouts are easier, click the Save Card Details
                checkbox under the Country dropdown.
            </p>
            <div id="savedCards" style="visibility: hidden">
                <div
                    class="table"
                    id="dv-cards"
                    *ngIf="showCards"
                    style="width: 100%; margin-bottom: 10px; margin-top: 20px"
                >
                    <table class="table--8 table--review">
                        <thead style="font-size: 2rem">
                            <tr class="table__header">
                                <td>Select</td>
                                <td>Type</td>
                                <td>Ending</td>
                            </tr>
                        </thead>
                        <tbody id="tcards"></tbody>
                    </table>
                </div>
                <button
                    class="button button--full"
                    id="payWithCard"
                    style="margin-top: 10px; width: 235px; font-size: 20px"
                    (click)="paymentWithSavedCard()"
                >
                PAY NOW WITH SAVED CARD
                </button>
                <div style="margin-top: 10px">
                    <div
                        class="button_plus"
                        style="margin-top: 20px"
                        (click)="createNewPayment()"
                    ></div>
                    <p
                        style="
                            margin-top: 27px;
                            margin-left: 45px;
                            display: inline-block;
                            font-size: 18px;
                        "
                    >
                        Pay using another method
                    </p>
                </div>
            </div>

            <form id="payment-form" style="width: 500px">
                <div
                    id="payment-request-button"
                    style="margin-top: 20px; margin-bottom: 20px"
                >
                    <!-- A Stripe Element will be inserted here if the browser supports this type of payment method. -->
                </div>
                <div id="payment-element">
                    <!-- Elements will create form elements here -->
                </div>

                <div *ngIf="isLoading" style="margin-top: 10px"></div>

                <label
                    class="toggle"
                    style="padding-right: 20px; margin-top: 10px"
                    *ngIf="isLoading"
                    id="saveCardChkbox"
                >
                    Save Card Details
                    <input
                        type="checkbox"
                        [checked]="saveCard"
                        (change)="saveCardDetails($event)"
                    />
                    <span class="toggle__check toggle__check_2"></span>
                </label>

                <button
                    class="button button--full"
                    id="submit"
                    style="margin-top: 10px; display: none; font-size: 20px
                    "
                >
                    Pay now
                </button>

                <div id="error-message"></div>
            </form>

            <div
                *ngIf="NavigateToStripeError() == true"
                style="margin-top: 15px"
            >
                <h4>{{ errormessage }}</h4>
            </div>

            <div
                class=""
                [class.active]="!isLoading"
                *ngIf="!isLoading && !NavigateToStripeError()"
                style="height: 10px; margin-left: 50%; margin-top: 150px"
            >
                <div class="loader m"></div>
            </div>

            <div id="messages" role="alert" style="display: none"></div>
        </div>
    </div>
</div>

<style type="text/css">
    @media screen and (max-width: 767px) {
        #payment-form {
            max-width: 96%;
            padding: 0 0px;
        }
    }
</style>
