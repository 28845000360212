<div class="checkout__sidebar">

    <div class="overlay" [class.active]="isRefreshing" *ngIf="isRefreshing">
        <div class="loader m"></div>
    </div>

    <!-- <h3 class="checkout__sidebar-title">Order {{getOrderNumber()}}</h3>

    <div class="checkout__sidebar-item">
        <h4 *ngIf="getOrderTypeCode() == 'card-grading'">{{getOrderTypeName() | dashForNoValueFormat}}</h4>
        <h4 *ngIf="getOrderTypeCode() == '22BOWMANCHR'">TOPPS X SGC 2022 Bowman Chrome Special</h4>
        <h4 *ngIf="getOrderTypeCode() == '22POKEMON'">2023 POKÉMON SPECIAL</h4>
    </div>

    <div class="checkout__sidebar-item" *ngIf="!canPaySubmission()">
        <i>Total Items Added</i>
        <p>{{getItemsCount() | dashForNoValueFormat}}</p>
    </div>

    <div class="checkout__sidebar-item" *ngIf="!canPaySubmission()">
        <i>Total Declared Value</i>
        <p>{{getTotalDeclaredValue() | currency: 'USD': 'symbol': '1.0-0' | dashForNoValueFormat}}</p>
    </div>

    <div class="checkout__sidebar-item" *ngIf="!canPaySubmission()">
        <i>Turnaround time</i>
        <p> {{getTurnaround() | dashForNoValueFormat}}</p>
    </div>

    <div class="checkout__sidebar-item" *ngIf="!canPaySubmission()">
        <i>Shipping Method</i>
        <p>{{getShippingMethodName() | dashForNoValueFormat}}</p>
    </div>

    <div class="checkout__sidebar-divider"></div> -->

    <!-- <div class="checkout__promo-code" *ngIf="canPaySubmission()">
        <h3>Have a Promo Code?</h3>
        <input type="text" id="value" class="input" placeholder="Code" [class.input--error]="isError" [(ngModel)]="promoCode">
        <button class="button" (click)="applyPromoCode()" [class.button--disabled]="!canSubmitValue()" [disabled]="!canSubmitValue()">Apply</button>
    </div> -->
    <div class="checkout__promo--code" *ngIf="canPaySubmission()">
        <div>
          <h3>4. Have a Promo Code?</h3>
        </div>
        <div  style="margin: 10px 10px 0px 0px">
          <input
            type="text"
            id="value"
            class="input"
            placeholder="Code"
            [class.input--error]="isError"
            [(ngModel)]="promoCode"
          />
          <button
            class="button"
            (click)="applyPromoCode()"
            [class.button--disabled]="!canSubmitValue()"
            [disabled]="!canSubmitValue()"
          >
            Apply
          </button>
        </div>
      </div>
     <!-- <div class="checkout__sidebar-divider" *ngIf="canPaySubmission()"></div>
   <div class="checkout__sidebar-price" *ngIf="showPromoCode()">
        <i>Promo Discount</i>
        <p>{{getPromoFee() | currency: 'USD': 'symbol': '1.2-2' | dashForNoValueFormat}}</p>
    </div> -->

    <!-- <div class="checkout__sidebar-price">
        <i>Service Fee</i>
        <p>{{getTotalServiceFee() | currency: 'USD': 'symbol': '1.2-2' | dashForNoValueFormat}}</p>
    </div>

    <div class="checkout__sidebar-price" *ngIf="getOrderTypeCode() != 'card-pre-grade'">
        <i>Shipping Fee</i>
        <p>{{getShippingFee() | currency: 'USD': 'symbol': '1.2-2' | dashForNoValueFormat}}</p>
    </div>

    <div class="checkout__sidebar-price">
        <i><strong>Total</strong></i>
        <p><b>{{getTotalFee() | currency: 'USD': 'symbol': '1.2-2' | dashForNoValueFormat}}</b></p>
    </div>
     <div class="checkout__sidebar-item" *ngIf="!termsaccept">
        <button class="button button--full" [hidden]="!canPaySubmission()" (click)="openPaymentTerms()">Click here to Finish</button>
    </div> -->
    <!-- <div class="checkout__sidebar-item" [hidden]="!canPaySubmissionWithTerms() || canSubmitWithoutPaying()" *ngIf="!isRefreshing">
        <button class="button button--full" (click)="payWithStripe()">Pay Now</button>
    </div>
    <div class="checkout__sidebar-item" [hidden]="!canPaySubmissionWithTerms() || !canSubmitWithoutPaying()">
        <button class="button button--full" (click)="submitOrder()">Submit & Pay Later</button>
    </div> -->
</div>

<style >
    .ml-3{
        margin-left: 3px;
    }
    /* @media only screen and (max-width : 550px) {
        .checkout__sidebar{
            margin-right: 100px;
        }
    } */

/*
    @media only screen and (max-width: 500px){
        .checkout__sidebar{
            margin-left: -11%;
             width: 262px;
        }
    }
    @media only screen and (max-height: 420px) {
.checkout__sidebar {
width: 235px;
}
} */
    /* @media only screen and (min-width : 770px) {

    } */
</style >
