<div class="card-result-item">
    <!-- <img src="/assets/img/no-image.png"> -->
    <h4 style="word-wrap: break-word">{{ item.name | cardNameFormat }}</h4>
    <!--<p class="card-result-item__set" *ngIf="item.set"><i>Set:</i> {{item.set.name | dashForNoValueFormat}}</p>
    <p><i>Year:</i> {{item.year | dashForNoValueFormat}}</p>
    <p><i>Card:</i> {{item.number | dashForNoValueFormat}}</p>
    <p *ngIf="item.manufacturer"><i>Manufacturer:</i> {{item.manufacturer.name | dashForNoValueFormat}}</p>-->
    <p class="card-result-item__set" *ngIf="item.description">
        {{ item.description }}
    </p>
</div>
