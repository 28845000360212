import { Component } from '@angular/core';

import {
  ModalService,
  ModalInterface,
} from '../../../../../common/services/modal.service';
import { OrderService } from '../../../../../orders/services/order.service';
import { FormControl } from '@angular/forms';
import { SearchService } from '../../../../../common/services/search.service';
import { Card } from '../../../../../common/models/catalog';
import { debounceTime } from 'rxjs/operators';

@Component({
  //moduleId: module.id,
  selector: 'add-autograph-card',
  templateUrl: './add-autograph-card.component.html',
  styleUrls: ['../../../../checkout.component.sass'],
})
export class AddAutographCardComponent implements ModalInterface {
  constructor(
    private modalService: ModalService,
    private orderService: OrderService,
    private searchService: SearchService
  ) {
    this.searchTerm.valueChanges.pipe(debounceTime(200)).subscribe((data) => {
      this.search(data);
    });
  }

  title = 'Add Card';
  subtitle = 'Please specify which card contains your signature/s';
  type = 'add-autograph-card';
  options: any;

  searchOptions = {
    limit: 10,
    offset: 0,
    filter: { query: null, kind: 'card' },
    result: 0,
  };

  isSearching = false;
  searchResults = new Array<any>();
  searchTerm: FormControl = new FormControl();

  search(data: any) {
    if (data) {
      if (data != this.searchOptions.filter.query) this.resetSearchOptions();
      this.searchOptions.filter.query = data;
      this.isSearching = true;
      this.searchService
        .searchItemByKeyword(this.searchOptions)
        .subscribe((response: any) => {
          if (
            response &&
            response.filter &&
            response.filter.query != this.searchOptions.filter.query
          )
            return;
          this.isSearching = false;
          this.updateSearchOptions(response);
          this.searchResults = response.items;
        });
    } else {
      this.searchResults = [];
      this.isSearching = false;
    }
  }

  resetSearchOptions() {
    this.searchOptions.offset = 0;
    this.searchOptions.result = 0;
  }

  updateSearchOptions(response: any) {
    this.searchOptions.offset += this.searchOptions.limit;
    this.searchOptions.filter.query = response.filter.query;
    this.searchOptions.result = response.items.length;
  }

  showLoadMore(): boolean {
    return Boolean(
      this.searchResults.length &&
        this.searchOptions.result == this.searchOptions.limit
    );
  }

  loadMore() {
    this.searchService
      .searchItemByKeyword(this.searchOptions)
      .subscribe((response: any) => {
        this.updateSearchOptions(response);
        this.searchResults = this.searchResults.concat(response.items);
      });
  }

  isAnyResult(): boolean {
    return this.searchResults != null && this.searchResults.length > 0;
  }

  selectResultItem(item: any) {
    if (!item) return;
    this.options.item.card = new Card(item);
    this.searchResults = [];
  }

  isCardAdded(): boolean {
    return this.options.item && this.options.item.card;
  }

  removeCard() {
    this.options.item.card = null;
  }
}
