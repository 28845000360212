import { Component, Input } from '@angular/core';
import { CheckoutService } from '../../services/checkout.service';
import { ModalService } from '../../../common/services/modal.service';

@Component({
  selector: 'checkout-top-nav',
  templateUrl: './checkout-top-nav.component.html',
  styleUrls: ['../../checkout.component.sass'],
})
export class CheckoutTopNavComponent {
  constructor(
    private checkoutService: CheckoutService,
    private modalService: ModalService
  ) {}

  @Input() currentStep: any;
  @Input() steps!: any[];

  isCurrentStep(step: number): boolean {
    // console.log(this.currentStep);
    return this.currentStep == step;
  }

  getOrderNumber(): number {
    return this.checkoutService.currentOrder.getNumber();
  }

  hasStepPassed(step: number): boolean {
    return this.checkoutService.isCheckoutFinished() || this.currentStep > step;
  }

  isStep8(): boolean {
    return this.checkoutService.isStep8();
  }

  cancelOrder() {
    var options = {
      // message: this.getOrderNumber(),
      message1: `If you are picking up your order at SGC or a show, we require a shipping address in the event you are unable to pick up your order.`,
      message2: `If your shipping address is not displayed, or you would like to add another address, click the '+' to add an address. After saving the address, click it to view your shipping options`,

      actionLabel: 'Go To Legacy Registry',
      actionCallback: () =>
        window.open('https://sgclegacy.com/registrymain.aspx', '_blank'),
    };

    this.modalService.open('app-cancel-order', options);
    //this.checkoutService.cancelCheckout();
  }

  saveForLater() {
    this.checkoutService.navigateToHome();
  }

  isCheckoutFinished() {
    return this.checkoutService.isCheckoutFinished();
  }
}
