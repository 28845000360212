import { Component, OnInit } from '@angular/core';

import { ModalService } from './common/services/modal.service';
//import { ModalEvent } from './common/models/modal-event';
import { ModalEvent } from './common/models/modal.event';
import { AuthService } from './common/services/auth.service';
import { UserService } from './common/services/user.service';
import { StateService } from './common/services/state.service';
import { MonitorService } from './common/services/monitor.service';
import { Router } from '@angular/router';
import * as Bowser from 'bowser';
import { SessionService } from './common/services/session.service';

const browser = Bowser.getParser(window.navigator.userAgent);

@Component({
  selector: 'sgc-web',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  title = 'app';

  constructor(
    private modalService: ModalService,
    private auth: AuthService,
    private userService: UserService,
    private stateService: StateService,
    private monitorService: MonitorService,
    private router: Router,
    private sessionService: SessionService
  ) {
    this.monitorService.init();
    this.sessionService.init();
  }

  modalEvent: ModalEvent = new ModalEvent();

  isOpen(): boolean {
    return this.modalEvent.isOpen();
  }

  showNewSiteAlert() {
    var newSiteAlerted = JSON.parse(localStorage.getItem('new_site_alerted')!);
    // if (!newSiteAlerted) this.modalService.open('new-site-alert');
  }

  isIE(): boolean {
    return browser.getBrowserName() === 'Internet Explorer';
  }
  ngOnInit() {
    if (this.isIE()) {
      this.router.navigate(['/compatibility-warning']);
      return;
    }

    this.modalService.events$.subscribe((event) => (this.modalEvent = event));

    this.showNewSiteAlert();

    if (this.auth.isAuthenticated()) {
      this.userService.getCurrentUser().subscribe((user) => {
        this.monitorService.setAuthenticatedUserId(user.id);
        this.stateService.currentUser.set(user);
      });
      this.auth.sendLoggedInEvent();
    }
  }
}
