<div class="smartbanner" id="smartabanner" *ngIf="isAndroid">
    <div class="smartbanner-container">
        <!--<a href="#" id="smb-close" class="smartbanner-close">&times;</a>-->
        <span style="padding-left:10px;"><img src="/assets/img/pick-up.png" width="75" alt="SGC White Logo" title="SGC Logo" style="padding-top:15px;"></span>
        <div class="smartbanner-info">
            <div class="smartbanner-title">DOWNLOAD MOBILE APP</div>
            <div>SGC GRADING</div>
        </div>
        <a href="https://play.google.com/store/apps/details?id=com.sgcgrading" target="_blank" class="smartbanner-button">
            <span class="smartbanner-button-text">Install</span>
        </a>
    </div>
</div>
<div class="smartbanner" id="smartabanner" *ngIf="isiOS">
  <div class="smartbanner-container">
    <!--<a href="#" id="smb-close" class="smartbanner-close">&times;</a>-->
    <span style="padding-left:10px;"><img src="/assets/img/pick-up.png" width="75" alt="SGC White Logo" title="SGC Logo" style="padding-top:15px;"></span>
    <div class="smartbanner-info">
      <div class="smartbanner-title">DOWNLOAD MOBILE APP</div>
      <div>SGC GRADING</div>
    </div>
    <a href="https://apps.apple.com/pk/app/sgc-grading/id1625689449" target="_blank" class="smartbanner-button">
      <span class="smartbanner-button-text">Install</span>
    </a>
  </div>
</div>
<div
    class="container"
    style="
        border-radius: 0px;
        padding: 0px;
        background-color: #f3f3f3f5;
        padding-bottom: 1px;
    "
>
    <!--<div class="banner" *ngIf="allowGurPurpleBar">
        <p [innerHTML]="adminKPIExecutive.businessDaygurClose"></p>
    </div>-->
    <div class="banner" *ngIf="isMainPurpleBar()">
        <p [innerHTML]="adminKPIExecutive.mainPurpleBarMsg"></p>
    </div>

    <div class="header">
        <div class="header__logo">
            <a routerLink="/">
                <img
                    src="/assets/img/SGC_WhiteLogo_Updated-without-text.svg"
                    width="106"
                    alt="SGC White Logo"
                    title="SGC Logo"
                />
            </a>
        </div>
        <div class="header__menu-toggle">
            <input
                type="checkbox"
                [checked]="mobileMenu"
                (click)="toggleMobileMenu()"
            />
            <span></span>
            <span></span>
        </div>
        <ul
            class="menu"
            [class.menu--logged]="isAuthenticated()"
            [class.menu--show]="mobileMenu"
        >
            <!--<li>
        <a routerLink="/culture" routerLinkActive="menu__item--active" class="menu__item"
           (click)="toggleMobileMenu()">Culture</a>
    </li>-->
            <li>
                <a
                    routerLink="/card-grading"
                    routerLinkActive="menu__item--active"
                    class="menu__item"
                    (click)="toggleMobileMenu()"
                    >Card Grading</a
                >
            </li>
            <!--<li>
                <a [routerLink]="['/customercard']" routerLinkActive="menu__item--active" class="menu__item">Customer Collection</a>
            </li>-->
            <!--<li>
        <a routerLink="/uniform-authentication" routerLinkActive="menu__item--active" class="menu__item"
           (click)="toggleMobileMenu()">Uniform Authentication</a>
    </li>-->
            <!--<li><a (click)="goToLegacyRegistry()" class="menu__item">Registry</a></li>-->
            <!--<li><a (click)="goToLegacyPopulationReport()" class="menu__item">Population reports</a></li>-->
            <li>
                <a
                    routerLink="/pop-report"
                    routerLinkActive="menu__item--active"
                    class="menu__item"
                    (click)="toggleMobileMenu()"
                    >POP REPORT</a
                >
            </li>
            <!-- <li><a routerLink="/caard-system" routerLinkActive="menu__item- -active" class="menu__item">Caard system</a></li> -->
            <li>
                <a
                    routerLink="/cert-code-lookup"
                    routerLinkActive="menu__item--active"
                    class="menu__item"
                    (click)="toggleMobileMenu()"
                    >Cert Verification</a
                >
            </li>
            <!--<li><a routerLink="/aboutus" routerLinkActive="menu__item--active" class="menu__item" (click)="toggleMobileMenu()">About Us</a></li>-->
            <li>
                <a
                    routerLink="/collector-support"
                    routerLinkActive="menu__item--active"
                    class="menu__item"
                    (click)="toggleMobileMenu()"
                    >Collector Support</a
                >
            </li>
            <li>
                <a
                    routerLink="/sgc-grading-app"
                    class="menu__item"
                    routerLinkActive="menu__item--active"
                    (click)="toggleMobileMenu()"
                    >Download app</a
                >
            </li>
            <!--<li><a routerLink="/media" routerLinkActive="menu__item--active" class="menu__item" (click)="toggleMobileMenu()">Media</a></li>-->
            <li style="text-align: center">
                <a
                    routerLink="/checkout"
                    routerLinkActive="menu__item--hide"
                    class="login d-inline-block"
                    (click)="toggleMobileMenu()"
                    >Start New Submission</a
                >
            </li>
        </ul>
        <div
            class="user user--login"
            [class.user--show]="mobileMenu"
            *ngIf="!isAuthenticated()"
        >
            <a
                routerLink="/recover-user"
                routerLinkActive="menu__item--active"
                class="recover-username"
                >Forgot Username?</a
            >
            <a class="login" (click)="login()">Login</a>
        </div>
        <div
            class="user"
            [class.user--show]="mobileMenu"
            *ngIf="isAuthenticated()"
            (click)="toggleUserMenu()"
        >
            <span class="user__name">Welcome back, {{ getUserName() }}!</span>
            <div class="avatar">
                <img src="{{ getUserProfilePictureUrl() }}" />
            </div>
            <ul class="user__menu" [class.user__menu--active]="userMenu">
                <!--<li><a routerLink="/orders" (click)="toggleMobileMenu()">My submissions</a></li>-->
                <li>
                    <a
                        routerLink="/account/dashboard"
                        (click)="toggleMobileMenu()"
                        >DashBoard</a
                    >
                </li>
                <li>
                    <a routerLink="/account" (click)="toggleMobileMenu()"
                        >My profile</a
                    >
                </li>
                <li>
                    <a
                        routerLink="/account/myorder"
                        (click)="toggleMobileMenu()"
                        >My Orders</a
                    >
                </li>
                <li *ngIf="showCollection">
                    <a routerLink="/authcollection" (click)="toggleMobileMenu()"
                        >My collections</a
                    >
                </li>
                <li>
                    <a
                        routerLink="/account/sellerready"
                        (click)="toggleMobileMenu()"
                        >Seller Ready</a
                    >
                </li>
                <li>
                    <a
                        routerLink="/account/receipts"
                        (click)="toggleMobileMenu()"
                        >Receipts</a
                    >
                </li>
                <li>
                    <a
                        routerLink="/account/needhelp"
                        (click)="toggleMobileMenu()"
                        >I Need Help</a
                    >
                </li>
                <!--<li><a routerLink="/account/my-cards" (click)="toggleMobileMenu()">My Cards</a></li>-->
                <!--<li><a routerLink="/account/my-cards-download" (click)="toggleMobileMenu()">Card Downloads</a></li>-->
                <li>
                    <a (click)="logout()" (click)="toggleMobileMenu()"
                        >Sign out</a
                    >
                </li>

                <!--<li><a routerLink="/account/myprofile" (click)="toggleMobileMenu()">My New profile</a></li>-->
            </ul>
        </div>
    </div>

    <router-outlet (activate)="onActivate($event)"></router-outlet>
</div>

<style>
    @media (max-width: 1295px) {
        /* .menu {
            grid-gap: 14px;
            font-size: 14px;
        } */
    }
    @media (min-width: 1295px) and (max-width: 1340px)
    {
        .menu {
            font-size: 16px;
        }
    }

    .smartbanner {
        left: 0;
        top: 0;
        width: 100%;
        height: 78px;
        font-family: "Helvetica Neue", helvetica, arial, sans-serif;
        background: #fff;
        overflow: hidden;
        border-bottom: 1px solid #ccc;
        margin-bottom: 10px;
        -webkit-font-smoothing: antialiased;
        -webkit-text-size-adjust: none;
    }

    .smartbanner-container {
        margin: 0 auto;
    }

    .smartbanner-close {
        position: absolute;
        left: 7px;
        top: 7px;
        display: block;
        font-family: 'ArialRoundedMTBold',Arial;
        font-size: 15px;
        text-align: center;
        text-decoration: none;
        border-radius: 14px;
        -webkit-font-smoothing: subpixel-antialiased;
        border: 0;
        width: 17px;
        height: 17px;
        line-height: 17px;
        color: #b1b1b3;
        background: #efefef;
    }

        .smartbanner-close:active, .smartbanner-close:hover {
            color: #333;
        }

    .smartbanner-icon {
        position: absolute;
        left: 30px;
        top: 10px;
        display: block;
        width: 57px;
        height: 57px;
        background-color: #fff;
        background-size: cover;
        background-image: url("https://lh3.googleusercontent.com/yyEmckv5badLAHiu8y5rH1ieZNqxeuZOaWPZoj7o3yHujtNWffzZFLeXdOHJ3q2HrIA=w300-rw");
    }

    .smartbanner-info {
        position: absolute;
        left: 98px;
        top: 24px;
        width: 50%;
        font-size: 12px;
        line-height: 1.2em;
        font-weight: bold;
        color: #999;
    }

    .smartbanner-title {
        font-size: 15px;
        line-height: 17px;
        color: #000;
        font-weight: bold;
    }

    .smartbanner-button {
        position: absolute;
        right: 20px;
        top: 24px;
        border-bottom: 3px solid #b3c833;
        padding: 0 10px;
        min-width: 12%;
        height: 24px;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        font-weight: bold;
        color: #fff;
        background-color: #b3c833;
        text-decoration: none;
        border-radius: 5px;
    }

        .smartbanner-button:hover {
            background-color: #c1d739;
            border-bottom: 3px solid #8c9c29;
        }

    .smartbanner-button-text {
        text-align: center;
        display: block;
        padding: 0 5px;
    }
</style>
