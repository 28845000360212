<div
  class="checkout__content"
  style="padding-bottom: 100px; background-color: #e8e8e8"
>
  <h2>STEP 5. SELECT RETURN SHIPPING METHOD</h2>
  <div class="checkout__shipping">
    <div
      class="checkout__shipping-item fade-in"
      *ngFor="let shippingMethod of shippingMethods"
      [class.item--selected]="isShippingMethodSelected(shippingMethod)"
      (click)="selectShippingMethod(shippingMethod)"
    >
      <img src="/assets/img/{{ shippingMethod.carrier?.code }}.png" />
      <h5>{{ shippingMethod.name }}</h5>
      <p>
        {{ shippingMethod.fee | currency : "USD" : "symbol" : "1.0-0" }}
      </p>
    </div>
  </div>
  <!-- <div class="checkout__content" *ngIf="!showLoginUserAddress">
         <div class="checkout__shipping" *ngIf="showCustomer && orderTypeCode != 'card-pre-grade'" style="visibility: hidden;">
            <div class="checkout__shipping-item fade-in" *ngFor="let shippingMethod of shippingMethods" [class.item--selected]="isShippingMethodSelected(shippingMethod)" (click)="selectShippingMethod(shippingMethod)">
                <img src="/assets/img/{{shippingMethod.carrier.code}}.png">
                <h5>{{shippingMethod.name}}</h5>
                <p>{{shippingMethod.fee | currency: 'USD': 'symbol': '1.0-0'}}</p>
            </div>
          </div>
        </div> -->
  <div class="checkout__shipping-account">
    <h4 style="margin: 12px">Select shipping payment account</h4>
    <div
      class="checkout__shipping-account-item"
      [class.item--selected]="!isPersonalShippingAccountSelected()"
      (click)="selectShippingAccount(false)"
    >
      <h5>SGC Shipping & Handling</h5>
    </div>
    <div
      class="checkout__shipping-account-item"
      [class.item--selected]="isPersonalShippingAccountSelected()"
      (click)="selectShippingAccount(true)"
    >
      <h5>FedEx (Personal)</h5>
      <div *ngIf="canEditFedexAccount">
        <input
          type="text"
          class="input"
          placeholder="Fedex Account Number"
          [(ngModel)]="fedexAccount"
        />
        <button
          class="button"
          (click)="saveFedexAccount(); $event.stopPropagation()"
        >
          <!-- [disabled]="!canSubmitValue()"
                    [class.button--disabled]="!canSubmitValue()" -->
          Save
        </button>
      </div>
      <div *ngIf="!canEditFedexAccount">
        <p><i>Account number:</i> {{ getFedexAccountNumber() }}</p>
        <input
          type="image"
          src="/assets/img/edit.svg"
          class="button-icon"
          (click)="editFedexAccount(); $event.stopPropagation()"
        />
      </div>
    </div>
  </div>
  <div class="additional-info" style="margin-left: 17px">
    <h5>Additional Shipping Information</h5>
    <ul>
      <li>
        Available methods are based on total order items and Insurance Value.
      </li>
      <li>
        For orders insured over $25,000 please email
        <strong
          ><a
            href="mailto:support@gosgc.com?subject=Shipping Inquiry for Order# {{
              order.publicId
            }} over $25,000"
            >support&#64;gosgc.com</a
          ></strong
        >
        for pricing.
      </li>
      <li>FedEx prices include residential delivery.</li>
      <li>FedEx Ground shipping times vary based on region.</li>
      <li>All USPS orders are sent Priority Mail</li>
      <li>All orders are sent with signature confirmation.</li>
    </ul>
  </div>
</div>
<style type="text/css">
  @media only screen and (min-width: 550px) {
    .checkout__content {
      padding-bottom: 80px;
    }
  }
</style>
