import { Component, OnInit, ViewChild } from '@angular/core';
import {
  GridComponent,
  FilterSettingsModel,
  IFilter,
  TextWrapSettingsModel,
  PageSettingsModel,
} from '@syncfusion/ej2-angular-grids';
import { OrderService } from '../../../orders/services/order.service';
import {
  SubmittedOrderForCustomer,
  DraftOrderForCustomer,
  CompletedOrderForCustomer,
} from '../../../orders/models/order';
import { User } from '../../../common/models/user';
import { DataUtil } from '@syncfusion/ej2-data';
import { StateService } from '../../../common/services/state.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-my-submissions',
  templateUrl: './my-submissions.component.html',
  styleUrls: [
    './my-submissions.component.css',
    '../../dashboard.component.css',
  ],
})
export class MySubmissionsComponent implements OnInit {
  constructor(
    private orderService: OrderService,
    private router: Router,
    private stateService: StateService
  ) {}

  currentUser = new User();

  @ViewChild('gridSub')
  public gridSub!: GridComponent;
  @ViewChild('gridSubAll')
  public gridSubAll!: GridComponent;

  @ViewChild('gridComp')
  public gridComp!: GridComponent;
  @ViewChild('gridCompAll')
  public gridCompAll!: GridComponent;

  @ViewChild('gridDraf')
  public gridDraf!: GridComponent;
  @ViewChild('gridDrafAll')
  public gridDrafAll!: GridComponent;

  public gridColumns!: Object[];
  public format = { type: 'date', format: 'MM-dd-yy' };
  public FilterOptions: FilterSettingsModel = {
    type: 'Menu',
  };
  public filter: IFilter = {
    params: {
      format: 'yyyy-MM-dd',
    },
  };

  public pageSettings!: PageSettingsModel;
  public wrapSettings!: TextWrapSettingsModel;

  submittedOrderForCustomer!: SubmittedOrderForCustomer[];
  draftOrderForCustomer!: DraftOrderForCustomer[];
  completedOrderForCustomer!: CompletedOrderForCustomer[];

  isSubmittedOrderForCustomer: boolean = false;
  isAllSubmittedOrderForCustomer: boolean = false;
  isDraftOrderForCustomer: boolean = false;
  isAllDraftOrderForCustomer: boolean = false;
  isCompletedOrderForCustomer: boolean = false;
  isAllCompletedOrderForCustomer: boolean = false;

  ngOnInit() {
    this.pageSettings = { pageSize: 20 };
    this.wrapSettings = { wrapMode: 'Both' };
    this.stateService.currentUser.changes.subscribe((user) => {
      this.currentUser = user;
    });
    this.isSubmittedOrderForCustomer = false;
    this.isDraftOrderForCustomer = false;
    this.isCompletedOrderForCustomer = false;
    this.isAllSubmittedOrderForCustomer = false;
    this.isAllDraftOrderForCustomer = false;
    this.isAllCompletedOrderForCustomer = false;

    this.orderService
      .GetDraftOrderForCustomer(this.currentUser.customerId)
      .subscribe((response) => {
        for (let o of response) {
          if (o.lastModifiedOn == '0001-01-01T00:00:00Z') o.lastModifiedOn = '';
          if (o.payPalStatus == 'created')
            o.payPalStatus = 'Denied By Bank/CC or Cancelled By User';
          else if (o.payPalStatus == 'approved') o.payPalStatus = 'Approval';
          else if (o.payPalStatus == 'Failed') o.payPalStatus = 'Denied';
        }
        if (DataUtil.parse.parseJson) {
          this.draftOrderForCustomer = DataUtil.parse.parseJson(response);
        }
        this.isDraftOrderForCustomer = true;
      });
    this.orderService
      .GetCompletedOrderForCustomer(this.currentUser.customerId)
      .subscribe((response) => {
        for (let o of response) {
          if (o.shippedDate == '0001-01-01T00:00:00Z') o.shippedDate = '';
          if (o.name == 'Fedex' && o.trackingNumber != '')
            o.shippingTrackingUrl = `http://www.fedex.com/Tracking?action=track&tracknumbers=${o.trackingNumber}`;
          if (o.name == 'USPS' && o.trackingNumber != '')
            o.shippingTrackingUrl = `https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=${o.trackingNumber}`;
          if (o.name != 'Fedex' && o.name != 'USPS' && o.trackingNumber != '')
            o.shippingTrackingUrl = `http://www.fedex.com/Tracking?action=track&tracknumbers=${o.trackingNumber}`;
        }
        if (DataUtil.parse.parseJson) {
          this.completedOrderForCustomer = DataUtil.parse.parseJson(response);
        }
        this.isCompletedOrderForCustomer = true;
      });
    this.orderService
      .GetSubmittedOrderForCustomer(this.currentUser.customerId)
      .subscribe((response) => {
        for (let o of response) {
          if (o.submittedDate == '0001-01-01T00:00:00Z') o.submittedDate = '-';
          if (o.receivedDate == '0001-01-01T00:00:00Z') o.receivedDate = '-';
          if (o.gradedDate == '0001-01-01T00:00:00Z') o.gradedDate = '-';
        }
        if (DataUtil.parse.parseJson) {
          this.submittedOrderForCustomer = DataUtil.parse.parseJson(response);
        }
          this.isSubmittedOrderForCustomer = true;
      });
  }

  submittedOrderViewAll(response: string) {
    if (response == 'submitViewAll') {
      this.isSubmittedOrderForCustomer = false;
      this.isAllSubmittedOrderForCustomer = true;
    } else if (response == 'submitViewPage') {
      this.isSubmittedOrderForCustomer = true;
      this.isAllSubmittedOrderForCustomer = false;
    }
  }
  draftOrderViewAll(response: string) {
    if (response == 'draftViewAll') {
      this.isDraftOrderForCustomer = false;
      this.isAllDraftOrderForCustomer = true;
    } else if (response == 'draftViewPage') {
      this.isDraftOrderForCustomer = true;
      this.isAllDraftOrderForCustomer = false;
    }
  }
  completedOrderViewAll(response: string) {
    if (response == 'completedViewAll') {
      this.isCompletedOrderForCustomer = false;
      this.isAllCompletedOrderForCustomer = true;
    } else if (response == 'completedViewPage') {
      this.isCompletedOrderForCustomer = true;
      this.isAllCompletedOrderForCustomer = false;
    }
  }

  goToInvoice(orderId: any) {
    this.router.navigate([`/orders/${orderId}/invoice`]);
  }

  checkoutOrder(orderId: any) {
    debugger;
    this.router.navigate([`/checkout/${orderId}/items`]);
  }
}
