import { Component, OnInit } from '@angular/core';
import {
  ShippingMethod,
  Order,
  OrderType,
  AdminKPIExecutive,
} from '../../../orders/models/order';
import { CheckoutService } from '../../services/checkout.service';
import { OrderService } from '../../../orders/services/order.service';
import { ModalService } from '../../../common/services/modal.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { UserService } from '../../../common/services/user.service';
import { Meta, Title } from '@angular/platform-browser';
import { Address, Customer, User } from '../../../common/models/user';
import * as _ from 'lodash';
@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['../../checkout.component.sass'],
})
export class AddressComponent implements OnInit {
  issel!: boolean;
  firstCheck: boolean = true;
  constructor(
    private router: Router,
    private checkoutService: CheckoutService,
    private orderService: OrderService,
    private location: Location,
    private modalService: ModalService,
    private userService: UserService,
    private metaService: Meta,
    private titleService: Title
  ) {}

  adminKPIExecutive = new AdminKPIExecutive();
  order = new Order();
  currentOrder = new Order();
  phoneNumber: any;
  shippingSelected!: boolean;
  shippingMethods = new Array<ShippingMethod>();
  selectedShippingMethod = new ShippingMethod();
  showPhoneNumber: boolean = false;
  orderTypeCode: any;

  currentUser = new User();
  currentCustomer = new Customer();
  userAddresses = new Array<Address>();
  customerAddresses = new Array<Address>();
  selectedAddress = new Address();

  oldFedexAccount!: number;
  canEditFedexAccount: boolean = false;
  showLoginUserAddress: boolean = true;
  useFedex= JSON.parse(localStorage.getItem("user")!)
     isFed= localStorage.getItem("useFed")
     Fedex= String(this.order.settings.fedexAccount)

  editFedexFalseAccount() {
    this.canEditFedexAccount = true;
    if(this.isFed == 'false') {
      this.fedexAccount = null;
    }
  }
  
  editFedexAccount () {
    this.canEditFedexAccount = true;
  }

  getShippingMethod(): string {
    return this.order.getShippingMethodName();
  }

  isShippingMethodSelected(shippingMethod: ShippingMethod): boolean {
    if (this.shippingSelected == false) {
      this.shippingSelected = true;
      this.selectShippingMethod(shippingMethod);
    }
    return this.selectedShippingMethod == shippingMethod;
  }
  //   getFedexAccountNumber(): number {
  //     return this.order && this.order.settings.fedexAccount;
  //   }

  getFedexAccountNumber(): any {
    // return this.order && this.order.settings.fedexAccount;
  //   console.log(localStorage.getItem("user"));
  //   if(this.fedexAccount == null || this.fedexAccount == undefined) {
  //     return;
  // }
    if(this.isFed === "true"){
        if(localStorage.getItem("fedAcc")){
            this.fedexAccount=localStorage.getItem("fedAcc")
            this.order.settings.fedexAccount=this.fedexAccount
            return this.order && this.order.settings.fedexAccount;
        }
        else{
        this.fedexAccount = Number(this.useFedex.preferences.currentFedexAccount)
        console.log(typeof this.fedexAccount);
        this.order.settings.fedexAccount=this.fedexAccount
    }

        return this.order && this.order.settings.fedexAccount;
    }

    else if(this.isFed === "false"){
        if(localStorage.getItem("fedAcc")){
            this.fedexAccount=localStorage.getItem("fedAcc")
            this.order.settings.fedexAccount=this.fedexAccount
            return this.order && this.order.settings.fedexAccount;
        }
        else{
            return '';
        }
    }
    // this.saveFedexAccount()

}
  updateOrderWithShipping() {
    this.checkoutService.updateOrderWithShipping();
  }

  //   isPersonalShippingAccountSelected(): boolean {
  //     return this.order && this.order.settings.useFedexAccount;
  //   }

  isPersonalShippingAccountSelected(): boolean {
    if (
      localStorage.getItem('sel') &&
      localStorage.getItem('sel') === 'false'
    ) {
      this.order.settings.useFedexAccount = false;
      return this.order && this.order.settings.useFedexAccount;
    } else {
      return this.order && this.order.settings.useFedexAccount;
    }
  }

  //   selectShippingAccount(personal: boolean) {
  //     this.order.settings.useFedexAccount = personal;
  //     console.log(personal);
  //     if (
  //       (personal &&
  //         this.order.settings.fedexAccount &&
  //         !this.canEditFedexAccount) ||
  //       !personal
  //     )
  //       this.updateOrderWithShipping();
  //   }

  selectShippingAccount(personal: boolean) {
    debugger
    this.order.settings.useFedexAccount = personal;
    console.log(personal);
    if (
      personal &&
      this.order.settings.fedexAccount &&
      !this.canEditFedexAccount
    ) {
      this.checkoutService.order.settings.useFedexAccount = true;
      this.checkoutService.currentOrder.settings.useFedexAccount = true;
      this.order.settings.useFedexAccount = true;
      this.order.settings.fedexAccount = this.fedexAccount;
      this.issel = true;
      console.log(this.fedexAccount);
      localStorage.setItem('sel', 'true');
      if (this.fedexAccount != '' && this.fedexAccount) {
        this.saveFedexAccount();
        this.updateOrderWithShipping();
      }
    } else if (!personal) {
      this.checkoutService.order.settings.useFedexAccount = false;
      this.checkoutService.currentOrder.settings.useFedexAccount = false;
      this.issel = false;
      this.order.settings.useFedexAccount = false;
      // this.isPersonalShippingAccountSelected()
      this.checkoutService.saveFedexAccount(this.fedexAccount, false);
      console.log(this.checkoutService.currentOrder);
      this.updateOrderWithShipping();
    }
  }

  selectShippingMethod(shippingMethod: ShippingMethod) {
    this.checkoutService.setEnable();
    this.selectedShippingMethod = shippingMethod;
    if (this.isUSPSSelected() && this.order.settings.useFedexAccount) {
      this.checkoutService.setShippingMethod(shippingMethod);
    } else {
      this.checkoutService.setShippingMethod(shippingMethod);
    }
  }

  isUSPSSelected(): boolean {
    return Boolean(
      this.selectedShippingMethod &&
        this.selectedShippingMethod.carrier &&
        this.selectedShippingMethod.carrier.code == 'usps'
    );
  }

  getOrderItems(): any[] {
    return this.order.getItems();
  }

  getOrderType(): OrderType {
    return this.order.getOrderType();
  }

  getItemsCount(): number {
    return this.order.getItemsCount();
  }

  getTotalDeclaredValue(): number {
    return this.order.getTotalDeclaredValue();
  }

  getTurnaround(): any {
    if (this.order.getTotalTurnaround() == 1) {
      return 'End of Show';
    } else if (this.order.getTotalTurnaround() == 2) {
      return '1-2 estimated business days';
    } else if (this.order.getTotalTurnaround() == 10) {
      return '5-10 estimated business days';
    }
    //else if (this.order.getTotalTurnaround() == 5) {
    //    return "1-5 estimated business days";
    //}
    else if (this.order.getTotalTurnaround() == 48) {
      return '43-48 estimated business days';
    } else if (this.order.getTotalTurnaround() == 55) {
      return '50-55 estimated business days';
    } else if (this.order.getTotalTurnaround() == 57) {
      return '52-57 estimated business days';
    } else if (this.order.getTotalTurnaround() == 70) {
      return '65-70 estimated business days';
    } else if (this.order.getTotalTurnaround() == 3) {
      return '1-3 estimated business days';
    } else if (this.order.getTotalTurnaround() == 25) {
      return '20-25 estimated business days';
    } else if (this.order.getTotalTurnaround() == 50) {
      return '45-50 estimated business days';
    }
    //else if (this.order.getTotalTurnaround() == 30) {
    //    return "30 Business Day Guaranteed";
    //}
    //else if (this.order.getTotalTurnaround() == 100) {
    //    return "Special Items Non-Guarantee";
    //}
    else {
      return this.order.getTotalTurnaround() + ' estimated business days';
    }
  }

  getShippingMethodCarrierLogoUrl(): string {
    return this.order.shippingMethod
      ? `/assets/img/${this.order.shippingMethod.carrier!.code}.png`
      : '/assets/img/fedex.png';
  }

  getShippingOptionText(): string {
    return this.order.settings.useFedexAccount
      ? `Payment via Fed-ex account (${this.order.settings.fedexAccount})`
      : 'Payment via SGC Account';
  }

  // saveFedexAccount() {
  //     this.canEditFedexAccount = false;
  //     this.oldFedexAccount = this.order.settings.fedexAccount;
  //     this.order.settings.useFedexAccount = true;
  //     //this.updateorders();
  //     console.log(this.order.settings.fedexAccount);
  //     console.log(this.order.settings.useFedexAccount);
  // }

  // hassan
  fedexAccount: any;

  //   saveFedexAccount() {
  //     this.canEditFedexAccount = false;
  //     this.order.settings.fedexAccount = this.fedexAccount;
  //     this.order.settings.useFedexAccount = true;
  //     console.log('Fedex Account', this.fedexAccount);
  //     this.checkoutService.saveFedexAccount(this.fedexAccount);
  //     this.currentOrder.settings.fedexAccount = this.fedexAccount;
  //     this.currentOrder.settings.useFedexAccount = true;
  //   }

  saveFedexAccount() {
    debugger;
    console.log(localStorage.getItem("user"));
    if(this.fedexAccount == null || this.fedexAccount == undefined) {
      return;
  }
    else if(this.fedexAccount==="" && !this.fedexAccount){
        console.log(this.fedexAccount);
        this.canEditFedexAccount = false;
        this.checkoutService.order.settings.useFedexAccount = false;
        this.checkoutService.currentOrder.settings.useFedexAccount = false;
        this.issel=false
        this.order.settings.useFedexAccount=false
        // this.isPersonalShippingAccountSelected()
        this.selectShippingMethod(this.shippingMethods[this.shippingMethods.length-1])
        this.selectShippingAccount(false)
        this.checkoutService.saveFedexAccount(this.fedexAccount,false);
        console.log(this.checkoutService.currentOrder);
        this.updateOrderWithShipping();
        localStorage.removeItem("fedAcc")
        // this.updateOrderWithShipping();
    }
    else{
        this.canEditFedexAccount = false;
        this.order.settings.fedexAccount = this.fedexAccount;
        this.order.settings.useFedexAccount = true;
        console.log("Fedex Account", this.fedexAccount);
        console.log("Fedex Account", typeof this.fedexAccount);
        // if(this.isFed==="false"){
            localStorage.setItem("fedAcc",this.fedexAccount)
        // }
        this.checkoutService.saveFedexAccount(String(this.fedexAccount),true);
        this.updateOrderWithShipping()
        this.currentOrder.settings.fedexAccount = this.fedexAccount;
        this.currentOrder.settings.useFedexAccount = true;
    }


}

  ngOnInit() {
    
        console.log("test");
        this.userService.userProfile$.subscribe(
            (user) => {this.currentUser = user
            console.log(this.currentUser);
            }
        );
    // if (this.currentUser.isEmployee)
    // {
    //     this.showLoginUserAddress = false;
    // }

    this.orderTypeCode = this.checkoutService.getOrderTypeCode();

    if (this.showLoginUserAddress != false) {
        this.checkoutService.getRefreshOrder(this.checkoutService.currentOrder.id).subscribe((order) => {
        if (!order.allItemsWithDeclaredValue()) return;
        this.order = order;
        if(this.isFed == 'false') {
          this.fedexAccount = null;
        }
        else {
          this.fedexAccount = this.order.settings.fedexAccount;
        }
        let isFedexAccount = this.order.settings.useFedexAccount;
        this.canEditFedexAccount = false;
        this.userService.userAddresses$.subscribe((addresses) => {
          this.userAddresses = addresses;
          if (addresses.length == 0) {
            // this.openAddressEditor(
            //     new Address({
            //         country: { code: "US" },
            //         state: { code: "" },
            //     })
            // );
          } else {
            if (order.shippingAddress && order.shippingAddress.id) {
              this.selectedAddress = _.find(
                addresses,
                (a) => a.id == order.shippingAddress.getId()
              )!;
              this.checkoutService
                .getAvailableShippingMethods()
                .subscribe((shippingMethods) => {
                    if (shippingMethods.length > 0) {
                        this.shippingMethods = shippingMethods;
                        //if (order.shippingMethod == null || order.shippingMethod.id == null || order.shippingMethod.id == undefined)
                        //    order.shippingMethod = shippingMethods[0];
                        if (order.shippingMethod != null && order.shippingMethod != undefined) {
                            this.selectedShippingMethod = _.find(
                                shippingMethods,
                                (sp) =>
                                    sp.id == order.shippingMethod.id
                            )!;
                        }
                        if (this.selectedShippingMethod == null || this.selectedShippingMethod == undefined || this.selectedShippingMethod.id == null || this.selectedShippingMethod.id == undefined) {
                            this.selectedShippingMethod = shippingMethods[0];
                            order.shippingMethod = this.selectedShippingMethod;
                            this.checkoutService.currentOrder.shippingMethod = this.selectedShippingMethod;
                            this.selectShippingMethod(this.selectedShippingMethod);
                            this.checkoutService.getShippingStepState();
                        }
                        else {
                            order.shippingMethod = this.selectedShippingMethod;
                            this.checkoutService.currentOrder.shippingMethod = this.selectedShippingMethod;
                            this.checkoutService.getShippingStepState();
                        }

                        //if (this.selectedShippingMethod) {
                        //    this.checkoutService.currentOrder.shippingMethod = this.selectedShippingMethod;
                        //    this.checkoutService.refreshOrderWithShipping();
                        //    this.checkoutService.getShippingStepState();
                        //}

                        if (!this.selectedShippingMethod)
                            this.checkoutService.unSetShippingMethod();
                    }
                });
            } else {
              if (addresses.length >= 1) this.selectAddress(addresses[0]);
            }
          }
        });
      });
    } else {
      this.checkoutService.currentOrder$.subscribe((order) => {
        if (!order.allItemsWithDeclaredValue()) return;
        this.order = order;
        if(this.isFed == 'false') {
          this.fedexAccount = null;
        }
        else {
          this.fedexAccount = this.order.settings.fedexAccount;
        }
      });
    }

    this.checkoutService.changeCurrentpayTerms(false);
    var orderId = this.checkoutService.currentOrder.id;
    if (!orderId) return;
    this.orderService.getOrder(orderId).subscribe((order) => {
      this.order = order;
      if (this.order.shippingAddress.phoneNumber) {
        this.showPhoneNumber = true;
        this.phoneNumber = this.order.shippingAddress.phoneNumber;
      }
    });
    this.orderTypeCode = this.checkoutService.getOrderTypeCode();
    // this.orderService
    //     .checkBusinessDayGur()
    //     .subscribe((adminKPIExecutive) => {
    //         this.adminKPIExecutive = adminKPIExecutive;
    //         this.checkoutService.newGuranteeDaysMsg(this.adminKPIExecutive);
    //     });
    // if (this.order.shippingAddress.country.code != "US") {
    //     var hasShippingMethod = this.currentOrder.hasShippingMethod();
    //     this.checkoutService.unSetShippingMethod();
    //     this.order.fee.shipping = 0;
    //     this.selectShippingMethod;
    //     hasShippingMethod == null;
    //     !hasShippingMethod;
    //     console.log("international shipping");
    // }
    if (this.order.shippingAddress.country.code != 'US') {
      // var hasShippingMethod = this.order.hasShippingMethod();
      this.order.fee.shipping = 0;
      this.currentOrder.fee.shipping = 0;
      this.selectShippingMethod == null;
      this.checkoutService.unSetShippingMethod();
      // hasShippingMethod == null;
      // !hasShippingMethod;
      // this.checkoutService.getShippingStepState();
      console.log('international shipping');
      if (this.order.shippingMethod.carrier!.code == null)
        this.checkoutService.unSetShippingMethod();
    }
  }

  // addAddress() {
  //     this.openAddressEditor(
  //         new Address({ country: { code: "US" }, state: { code: "" } })
  //     );
  // }
  openAddressEditor(address?: Address) {
    this.modalService.open('address-editor', address);
  }

  selectAddress(address: Address) {
    // if (this.selectedAddress == address) return;
    // this.selectedAddress = address;
    // this.checkoutService.setShippingAddress(address).subscribe((order) => {
    //     this.checkoutService
    //         .getAvailableShippingMethods()
    //         .subscribe((shippingMethods) => {
    //             this.shippingMethods = shippingMethods;
    //             if (order.shippingMethod && order.shippingMethod.id)
    //                 this.selectedShippingMethod = _.find(
    //                     shippingMethods,
    //                     (sp) => sp.id == order.shippingMethod.getId()
    //                 );
    //             if (!this.selectedShippingMethod)
    //                 this.checkoutService.unSetShippingMethod();
    //         });
    //     this.checkoutService
    //         .getAvailableShippingMethods()
    //         .subscribe((shippingMethods) => {
    //             this.shippingMethods = shippingMethods;
    //         });
    // });
  }

  //Contactus() {

  //
  //    this.orderService.isPaymentContactInfo$(true);
  //    //this.location.replaceState('/collector-support/contact-us');
  //    this.router.navigate(['collector-support/contact-us']);
  //    //this.router.navigate([`/collector-support`]);

  //}
}
