import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { GeneralLayoutComponent } from '../common/layout/general-layout/general-layout.component';
import { PopReportsComponent } from '../pop-reports/views/pop-reports/pop-reports.component';
import { PopReportresultComponent } from '../pop-reports/views/pop-reportresult/pop-reportresult.component';
import { PopreportSendmessageComponent } from './popreport-sendmessage/popreport-sendmessage.component';
import { PopreportMasterpopComponent } from './popreport-masterpop/popreport-masterpop.component';
import { AuthCodeQuestionsComponent } from './auth-code-questions/auth-code-questions.component';

export const routes: Routes = [
  //{
  //    path: 'pop-report',
  //    component: GeneralLayoutComponent,
  //    children: [
  //        { path: '', component: PopReportsComponent }
  //    ],
  //},
  {
    path: 'pop-report/result/:cardSet/:sport',
    component: PopReportresultComponent,
  },
  {
    path: 'pop-report/message/:cardno/:cardSet/:subject/:description/:sportcard/:page',
    component: PopreportSendmessageComponent,
  },
  {
    path: 'population-reports/masterpop/:t205',
    component: PopreportMasterpopComponent,
  },
  {
    path: 'pop-report/authcode-questions/:authcode',
    component: AuthCodeQuestionsComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class PopReportsRoutingModule {}
