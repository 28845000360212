import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['../../dashboard.component.css'],
})
export class MyProfileComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
