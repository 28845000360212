import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sgc-terms-conditions',
  templateUrl: './sgc-terms-conditions.component.html',
  styleUrls: ['./sgc-terms-conditions.component.css']
})
export class SgcTermsConditionsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
