<div class="user-content">
  <h2>Hello {{ currentUser.name }}!</h2>
  <div class="user-submissions">
    <h3>My collections</h3>
    <div class="user-submissions__order">
      <div class="user-submissions__order-info">
        <!-- <form [formGroup]="form" style="display:flex;margin-right:400px;">
                    <i style="padding-top: 12px;padding-right: 5px;">Auth Code:</i><input type="text" class="input" placeholder="Enter Auth Code" formControlName="authcode">

                    <button class="submit-btn e-btn" id="submit-btn" [disabled]="form.invalid" (click)="submitAuthCode()" style="padding-bottom:7px;padding-top:4px;cursor:pointer;margin-left:10px;">
                        <span *ngIf="!isRefreshing">Submit</span>
                        <div *ngIf="isRefreshing" class="loader xs invert-color"></div>
                    </button>
                    <div *ngIf="form.controls['authcode'].touched && !form.controls['authcode'].valid" style="color:red;padding-top:12px;padding-left:10px;">
                        <p *ngIf="form.controls['authcode'].hasError('minlength')" class="text-danger">
                            * Min Length is 7
                        </p>
                        <p *ngIf="form.controls['authcode'].hasError('maxlength')" class="text-danger">
                            * Max Length is 11
                        </p>
                        <p *ngIf="form.controls['authcode'].hasError('pattern')" class="text-danger">
                            * Invalid format
                        </p>
                        <p *ngIf="form.controls['authcode'].hasError('required')" class="text-danger">
                            * Auth code is required
                        </p>
                    </div>
                </form> -->
        <button
          class="submit-btn e-btn"
          (click)="SearchAuthCodeCollection()"
          style="padding-bottom: 7px; padding-top: 4px; cursor: pointer"
        >
          <span *ngIf="!SeeSearch">See Collection</span>
          <div *ngIf="SeeSearch" class="loader xs invert-color"></div>
        </button>
      </div>
      <div class="user-submissions__order-info">
        <!-- <form [formGroup]="Searchform" style="display:flex">
                    <i style="padding-top: 12px;padding-right:8px;padding-left:13px;">Search:</i><input type="text" class="input" placeholder="Enter Auth Code" formControlName="searchauthcode">

                    <button class="submit-btn e-btn" id="submit-Searchbtn" [disabled]="Searchform.invalid" (click)="searchAuthCode()" style="padding-bottom:7px;padding-top:4px;cursor:pointer;margin-left:10px;">
                        <span *ngIf="!isSearching">Search Auth Code</span>
                        <div *ngIf="isSearching" class="loader xs invert-color"></div>
                    </button>

                    <div *ngIf="Searchform.controls['searchauthcode'].touched && !Searchform.controls['searchauthcode'].valid" style="color:red;padding-top:12px;padding-left:10px;">
                        <p *ngIf="Searchform.controls['searchauthcode'].hasError('minlength')" class="text-danger">
                            * Min Length is 7
                        </p>
                        <p *ngIf="Searchform.controls['searchauthcode'].hasError('maxlength')" class="text-danger">
                            * Max Length is 11
                        </p>
                        <p *ngIf="Searchform.controls['searchauthcode'].hasError('pattern')" class="text-danger">
                            * Invalid format
                        </p>
                        <p *ngIf="Searchform.controls['searchauthcode'].hasError('required')" class="text-danger">
                            * Auth code is required
                        </p>
                    </div>

                </form> -->
      </div>
    </div>

    <p *ngIf="duplicatemessage" style="padding-top: 12px; padding-left: 10px">
      <span style="color: red; font-size: 18px"
        >The Auth Code already exists in your Collection</span
      >
    </p>
    <p
      *ngIf="submitmsg"
      style="color: red; padding-top: 12px; padding-left: 10px"
    >
      <span style="color: #785aff; font-size: 18px"
        >Auth Code
        <span
          style="
            color: black;
            font-family: 'd77';
            margin: 0;
            font-weight: normal;
            text-transform: uppercase;
          "
        >
          {{ authCode }}
        </span>
        added to your collection</span
      >
    </p>
    <p
      *ngIf="searchmsg"
      style="color: red; padding-top: 12px; padding-left: 10px"
    >
      <span style="color: #785aff; font-size: 18px"
        >Searched Auth Code
        <span
          style="
            color: black;
            font-family: 'd77';
            margin: 0;
            font-weight: normal;
            text-transform: uppercase;
          "
        >
          {{ authCode }}
        </span></span
      >
    </p>
    <p
      *ngIf="emptySearchmsg"
      style="color: red; padding-top: 12px; padding-left: 10px"
    >
      <span style="color: red; font-size: 18px"
        >Auth Code does not exist in your Collection</span
      >
    </p>

    <div *ngIf="showRecord">
      <div
        class="tracker"
        style="width: 10%; padding-bottom: 8px; margin-right: 8px; float: left"
        *ngFor="
          let collection of UserCollections
            | slice : curPage * pageSize - pageSize : curPage * pageSize
        "
      >
        <span class="tracker--active">
          {{ collection.authCode }}
        </span>
      </div>
    </div>

    <p class="pagination" *ngIf="showRecord" style="text-align: center">
      <button [disabled]="curPage == 1" (click)="curPage = curPage - 1">
        PREV
      </button>
      <span>Page {{ curPage }} of {{ numberOfPages() }}</span>
      <!-- <button [disabled]="curPage >= UserCollections.length/pageSize"
                    (click)="curPage = curPage + 1">
                NEXT
            </button> -->
    </p>
  </div>
</div>
