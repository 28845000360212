import { Component, OnInit, Compiler } from "@angular/core";
import { OrderService } from "../../../orders/services/order.service";
import {
    Order,
    OrderType,
    OrderTypeHint,
    AdminKPIExecutive,
} from "../../../orders/models/order";
import { CheckoutService } from "../../services/checkout.service";
import { ModalService } from "../../../common/services/modal.service";
import { StateService } from "../../../common/services/state.service";

@Component({
    selector: "select-expertise-tier",
    templateUrl: "./select-expertise.component.html",
    styleUrls: ["../../checkout.component.sass"],
})
export class SelectExpertiseComponent implements OnInit {
    constructor(
        private _compiler: Compiler,
        private checkoutService: CheckoutService,
        private orderService: OrderService,
        private modalService: ModalService,
        private stateService: StateService
    ) {}

    selectedType = new OrderType();
    orderTypes = new Array<OrderType>();
    order = new Order();

    select(orderType: OrderType) {
        console.log(orderType)
        if (this.hasItems() && this.selectedType.id != orderType.id) return;
        this.selectedType = orderType;
        this.checkoutService.setOrderType(orderType);
        this.checkoutService.moveForward();
    }

    isSelected(orderType: OrderType): boolean {
        return (
            this.selectedType && this.selectedType.getId() == orderType.getId()
        );
    }

    getOrderTypeHints(): Array<OrderTypeHint> {
        return this.order.getOrderType().getOrderTypeHints();
    }

    hasItems(): boolean {
        return this.order.getItemsCount() > 0;
    }

    ngOnInit() {
        this._compiler.clearCache();
        if(localStorage.getItem("fedAcc")){
            localStorage.removeItem("fedAcc")
        }
        this.checkoutService.currentOrder$.subscribe((order) => {
            this.order = order;
            this.selectedType = this.order.getOrderType();
        });

        this.checkoutService.changeCurrentpayTerms(false);
        this.checkoutService.currentOrder.promoCode = null!;
        this.orderService.getOrderTypes().subscribe((orderTypes) => {
            this.orderTypes = orderTypes;
            this.orderTypes = orderTypes.slice().reverse();
            var userId = this.stateService.currentUser.get().id;
            var termsAlertResponse = JSON.parse(
                localStorage.getItem(`${userId}_acceptTerms`)!
            );
            if (!termsAlertResponse) this.modalService.open("terms-alert");
        });
    }

    cancelOrder() {
        this.checkoutService.cancelCheckout();
    }
}
