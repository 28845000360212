import { Component } from '@angular/core';

import { ModalService, ModalInterface } from '../../services/modal.service';

@Component({
    selector: 'view-bio',
    templateUrl: './view-bio.component.html'
})

export class ViewBioComponent implements ModalInterface {
    constructor(
        private modalService: ModalService
    ){}

    type = "view-bio";
    loader!: boolean;
    options: any;

    close() {
        this.modalService.close(this.type, 'close');
    }

}
