
<img style="top:10px" src="/assets/img/close-modal.svg" class="modal__close" (click)="close()">
<div style="margin-top:4px" class="feature-not-available">
    <div>
        <p style="font-size:13px;text-align: left;">{{getMessage()}}</p>
        <br />
        <p style="font-size:13px;text-align: left;">{{getMessage1()}}</p>
        <br />
        <p style="font-size:13px;text-align: left;">If your shipping address is not displayed, or you would like to add another address, click the '<b>+</b>' to add an address. After saving the address, click it to view your shipping options.</p>
    </div>
    </div>
