<!--<img style="top:10px" src="/assets/img/close-modal.svg" class="modal__close" (click)="close()">
<div style="margin-top:4px" class="feature-not-available">
    <div>
        <p style="font-size:13px;text-align: left;">{{getMessage()}}</p>
        <br />
        <p style="font-size:13px;text-align: left;">{{getMessage1()}}</p>
        <br />
        <p style="font-size:13px;text-align: left;">If your shipping address is not displayed, or you would like to add another address, click the '<b>+</b>' to add an address. After saving the address, click it to view your shipping options.</p>
    </div>
</div>-->
<div class="new-site__modal">
    <h3>INSTRUCTIONS</h3>

    <p> AFTER SELECTING YOUR CARD, ENTER YOUR DECLARED VALUE AND SELECT DESIRED SERVICES BELOW. AFTER YOU HAVE ADDED ALL YOUR CARDS, CLICK THE NEXT BUTTON ON THE BOTTOM RIGHT CORNER OF THE SCREEN.</p>
    <button class="button" (click)="close()">Got it!</button>
</div>
