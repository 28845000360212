import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';

import {
  ModalService,
  ModalInterface,
} from '../../../../../common/services/modal.service';
import { OrderService } from '../../../../../orders/services/order.service';

@Component({
  //moduleId: module.id,
  selector: 'select-grading',
  templateUrl: './select-grading.component.html',
  styleUrls: ['../../../../checkout.component.sass'],
})
export class SelectGradingComponent implements ModalInterface, OnInit {
  constructor(
    private modalService: ModalService,
    private orderService: OrderService
  ) {}

  title = 'Grading';
  type = 'select-grading';
  options: any;
  gradingOptions!: any[];

  select(option: any) {
    switch (option.code) {
      case 'autograph-grading':
        this.options.settings.autographGrading =
          !this.options.settings.autographGrading;
        this.unselectNoGrading();
        break;
      case 'card-grading':
        this.options.settings.cardGrading = !this.options.settings.cardGrading;
        this.unselectNoGrading();
        break;
      case 'card-pre-grade':
        this.options.settings.cardGrading = !this.options.settings.cardGrading;
        this.unselectNoGrading();
        break;
      case '22BOWMANCHR':
        this.options.settings.cardGrading = !this.options.settings.cardGrading;
        this.unselectNoGrading();
        break;
      case '22POKEMON':
        this.options.settings.cardGrading = !this.options.settings.cardGrading;
        this.unselectNoGrading();
        break;
        case '23BOWMANEOY':
          this.options.settings.cardGrading = !this.options.settings.cardGrading;
          this.unselectNoGrading();
          break;
       case '24BASKETBALL':
          this.options.settings.cardGrading = !this.options.settings.cardGrading;
          this.unselectNoGrading();
          break;
       case '24TOPPSS2':
          this.options.settings.cardGrading = !this.options.settings.cardGrading;
          this.unselectNoGrading();
          break;
      case 'no-grading':
        option.selected = !option.selected;
        this.options.settings.autographGrading = false;
        this.options.settings.cardGrading = false;
        break;
    }
  }

  isSelected(option: any): boolean {
    switch (option.code) {
      case 'autograph-grading':
        return this.options.settings.autographGrading;
      case 'card-grading':
        return this.options.settings.cardGrading;
      case 'card-pre-grade':
        return this.options.settings.cardGrading;
      case '22BOWMANCHR':
        return this.options.settings.cardGrading;
      case '22POKEMON':
        return this.options.settings.cardGrading;
      case 'no-grading':
        return option.selected;
      case '23BOWMANEOY':
            return this.options.settings.cardGrading;
      case '24BASKETBALL':
            return this.options.settings.cardGrading;
      case '24TOPPSS2':
            return this.options.settings.cardGrading;
      default:
        return false;
    }
  }

  unselectNoGrading() {
    var option = this.gradingOptions.find(
      (grading) => grading.code == 'no-grading'
    );
    option.selected = false;
  }

  ngOnInit() {
    // this.orderService.getAvailableGradingOptions(this.options).subscribe(options => this.gradingOptions = _.filter(options, option => option.available));
    this.gradingOptions = [
      {
        name: 'Autograph Grading',
        code: 'autograph-grading',
      },
    ];
    if (this.options.item.signedObject.code == 'card')
      this.gradingOptions.push({ name: 'Card Grading', code: 'card-grading' });
    this.gradingOptions.push({
      name: 'No Grading, Authentication Only',
      code: 'no-grading',
      selected: false,
    });
  }
}
