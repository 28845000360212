<div
    class="checkout__content"
    style="display: flex; background-color: #f3f3f3f5"
>
    <div class="checkOut">
        <h2>STEP 6. Review your submission</h2>
        <h3 style="padding-top: 15px">CHECKOUT INSTRUCTIONS</h3>
        <!--<h4 style="text-transform:inherit !important">Please follow the instructions below to pay via credit or debit card.  Click <a href="/assets/docs/CheckoutCreditDebitInstructions.pdf" target="_blank">here</a> to download instructions with images. </h4>
        <div style=" margin-left: 8px; line-height: 28px;">
            <h4 style="text-transform:inherit !important">1. On the far right of the screen, click the purple “CLICK HERE TO FINISH” button.</h4>
            <h4 style="text-transform:inherit !important">2. In the pop-up, if you agree to our Terms and Conditions, check the “I agree” box.</h4>
            <h4 style="text-transform:inherit !important">3. On the far right of the screen, click the VISA, MASTERCARD, AMEX or DISCOVER button.</h4>
            <!-- <h4 style="text-transform:inherit !important">4. In the PayPal pop-up, enter your card and billing info, phone, and email.  IMPORTANT! At the bottom, uncheck the “Save info & create your PayPal account” option.</h4> -->
        <!-- <h4 style="text-transform:inherit !important">4. Finally, click the “Continue as Guest” button to pay.</h4>
            <h4 style="text-transform:inherit !important">5. If you have an issue, please click <a target="_blank" routerLink="/collector-support">here</a> to contact customer support or send an email to <a href="mailto:support@gosgc.com?subject=PayPal Issue">support@gosgc.com</a>.</h4>
        </div> -->

        <p style="padding-top: 5px; padding-bottom: 10px; width: 149%">
            Is all your information correct? Before placing your order, please
            take a moment to review your contact information and submission
            summary.
        </p>

        <div
            class="checkout__review checkout__review--columns"
            style="margin-bottom: 15px; width: 149%"
        >
            <h3 *ngIf="orderTypeCode != 'card-pre-grade'">
                1. Shipping Information
            </h3>
            <h3 *ngIf="orderTypeCode == 'card-pre-grade'">
                1. VERIFY YOUR CONTACT INFO
            </h3>
            <div style="margin-top: 15px">
                <h4>{{ order.shippingAddress | streetFormat }}</h4>
                <p>
                    {{ order.shippingAddress | cityStateCountryFormat : true }}
                </p>
                <p *ngIf="showPhoneNumber">{{ phoneNumber }}</p>
            </div>
            <div class="checkout__review-divider"></div>
            <div class="checkout__review-shipping">
                <img
                    src="{{ getShippingMethodCarrierLogoUrl() }}"
                    alt="Carrier"
                />
                <h5>{{ getShippingMethod() }} - $ {{ order.fee.shipping }}</h5>
            </div>
            <div class="checkout__review-divider"></div>
            <div class="checkout__review-shipping">
                <img
                    src="{{ getShippingMethodCarrierLogoUrl() }}"
                    alt="Carrier"
                />
                <h5>{{ getShippingOptionText() }}</h5>
            </div>
        </div>
        <div class="checkout__review" style="margin-bottom: 15px; width: 149%">
            <div class="checkout__review-edit">
                <h3>2. Items added</h3>
            </div>
            <order-item-list
                [items]="getOrderItems()"
                [type]="getOrderType()"
            ></order-item-list>
        </div>
        <div
            class="checkout__review checkout__review--columns"
            style="margin-bottom: 15px; width: 149%"
        >
            <h3>3. Submission Details</h3>
            <div>
                <p><i>Total items added</i></p>
                <p>{{ getItemsCount() }}</p>
            </div>
            <div class="checkout__review-divider"></div>
            <div class="checkout__review-shipping">
                <p><i>Total Declared Value</i></p>
                <p>
                    {{
                        getTotalDeclaredValue()
                            | currency : "USD" : "symbol" : "1.0-0"
                    }}
                </p>
            </div>
            <div class="checkout__review-divider"></div>
            <div class="checkout__review-shipping">
                <p><i>Turnaround time</i></p>
                <p>{{ getTurnaround() }}</p>
            </div>
        </div>
        <div
            class="checkout__review checkout__review--terms"
            style="margin-bottom: 15px; width: 149%"
        >
            <div class="checkOutB">
                <checkout-side-bar [order]="order"></checkout-side-bar>
            </div>
            <!-- <h3>4. Terms and Conditions</h3>
            <p>
                By clicking FINISH & PAY, I hereby agree and consent to the
                <a href="https://www.gosgc.com/terms" target="_blank"
                    >Terms and Conditions</a
                >, its policies, and the
                <a routerLink="/privacy">Privacy Policy</a>.
            </p> -->
            <!-- <input type="checkbox" [(ngModel)]="checked" (ngModelChange)="accept($event)">
      <span class="toggle__check toggle__check_2"></span> -->
        </div>
        <div
            class="checkout__review checkout__review--terms"
            style="width: 149%"
        >
            <h3>5. Terms and Conditions</h3>
            <p>
                By clicking FINISH & PAY, I hereby agree and consent to the
                <a href="https://www.gosgc.com/terms" target="_blank"
                    >Terms and Conditions</a
                >, its policies, and the
                <a routerLink="/privacy">Privacy Policy</a>.
                <!-- <input type="checkbox" [(ngModel)]="checked" (ngModelChange)="accept($event)">
          <span class="toggle__check toggle__check_2"></span> -->
            </p>
        </div>

        <!-- <div class="modal__content"> -->
        <!-- <p class="shipping-account-alert">Click here to agree to the SGC <a href="https://www.gosgc.com/terms" target="_blank">terms and conditions</a>.</p> -->
        <!-- <label class="toggle">
                Click here to agree to the SGC <a href="https://www.gosgc.com/terms" target="_blank">terms and conditions</a>.
                <input type="checkbox" [ngModel]="accepted" (ngModelChange)="accept($event)">
                <span class="toggle__check toggle__check_2"></span>
            </label>
        </div> -->
    </div>
    <!-- <div class="checkOutB">
        <checkout-side-bar [order]="order"></checkout-side-bar>
    </div> -->
</div>

<style>
    .checkout__content {
        margin-bottom: 70px !important;
    }

    .checkOut {
        /* background-color: aquamarine; */
        width: 65%;
    }
    .checkOutB {
        /* background-color: blueviolet; */
        /* width: 25%; */
        /* margin-top:5%; */
        /* margin-left: 9%; */
    }
    @media only screen and(max-width:770px) {
        .checkOutB {
            margin-left: 0;
            width: 258px;
        }
    }
    .modal__content {
        margin-top: 20px;
    }

    @media (max-width: 768px) {
        .checkout__content {
            flex-direction: column;
        }
    }

    @media only screen and (max-width: 550px) {
        .checkout__content {
            padding-bottom: 80px;
        }
    }
    @media only screen and (max-width: 630px) {
        .checkout__review.checkout__review--columns,
        .checkout__review.checkout__review--terms,
        .checkout__review {
            width: 160% !important;
        }
        .checkout__review-shipping {
            margin-left: -30px;
        }
    }
</style>
