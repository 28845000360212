import { Injectable } from '@angular/core';
import { PopReports } from '../models/pop-reports';
import { PopReportsSet } from '../models/pop-reports-set';
import { PopReportsSport } from '../models/pop-reports-sport';
import {
  PopulationReport,
  PopulationReportSearch,
  PopulationReportYear,
  PopReportAuthCode,
  PopPipelineStatus,
} from '../models/PopulationReport';
import * as _ from 'lodash';
import { SPORTS } from '../mock-sports';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { ApiService } from '../../common/services/api.service';
import { ErrorService } from '../../common/services/error.service';
// import { ArrayObservable } from 'rxjs/observable/ArrayObservable';
import { catchError, map } from 'rxjs/operators';
// import { throwError } from '@syncfusion/ej2/base';

@Injectable()
export class PopReportsService {
  constructor(
    private errorService: ErrorService,
    private apiService: ApiService
  ) {}

  public GetallPopReport(
    cardSet: any,
    sport: any
  ): Observable<PopulationReport[]> {
    // var options = {
    //   params: [],
    // };

    // options.params.push({ key: 'cardSet', value: cardSet } );
    // options.params.push({ key: 'sport', value: sport });

    interface Param {
      key: string;
      value: any;
    }

    var options: { params: Param[] } = {
      params: [],
    };

    options.params.push({ key: 'cardSet', value: cardSet });
    options.params.push({ key: 'sport', value: sport });

    return this.apiService
      .post(`/pop-report/getPopReportByCardSet`, { cardSet: cardSet, sport: sport })

      .pipe(
        catchError((error: any) =>
          this.errorService.handleAsDefaultErrorOrderSubmit(error)
        )
      );
    //let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    //headers = headers.set('Authorization', `Bearer ${localStorage.getItem('access_token')}`);
    //return this.http.get<PopulationReport[]>(this.baseUrl + '/pop-report/getPopReportByCardSet/' + cardSet, { headers: headers }).pipe(tap(data => data),
    //    catchError(this.handleError)
    //);
  }

  public GetPopReportT205(): Observable<PopulationReport[]> {
    return this.apiService
      .post(`/pop-report/GetPopReportT205`, {})

      .pipe(
        catchError((error: any) =>
          this.errorService.handleAsDefaultErrorOrderSubmit(error)
        )
      );
    //let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    //headers = headers.set('Authorization', `Bearer ${localStorage.getItem('access_token')}`);
    //return this.http.get<PopulationReport[]>(this.baseUrl + '/pop-report/getPopReportByCardSet/' + cardSet, { headers: headers }).pipe(tap(data => data),
    //    catchError(this.handleError)
    //);
  }

  checkInstgramFeed(): Observable<any> {
    return this.apiService
      .get(`/pop-report/getInstgram`)

      .pipe(
        catchError((error: any) =>
          this.errorService.handleAsDefaultErrorOrderSubmit(error)
        )
      );
  }

  public GetPopReportT206(): Observable<PopulationReport[]> {
    return this.apiService
      .post(`/pop-report/GetPopReportT206`, {})

      .pipe(
        catchError((error: any) =>
          this.errorService.handleAsDefaultErrorOrderSubmit(error)
        )
      );
    //let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    //headers = headers.set('Authorization', `Bearer ${localStorage.getItem('access_token')}`);
    //return this.http.get<PopulationReport[]>(this.baseUrl + '/pop-report/getPopReportByCardSet/' + cardSet, { headers: headers }).pipe(tap(data => data),
    //    catchError(this.handleError)
    //);
  }

  public getPopPipelineStatus(): Observable<PopPipelineStatus[]> {
    return this.apiService
      .post(`/pop-report/GetPoplineStaus`, {})
      .pipe(
        catchError((error: any) =>
          this.errorService.handleAsDefaultErrorOrderSubmit(error)
        )
      );
    //let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    //headers = headers.set('Authorization', `Bearer ${localStorage.getItem('access_token')}`);
    //return this.http.get<PopulationReport[]>(this.baseUrl + '/pop-report/getPopReportByCardSet/' + cardSet, { headers: headers }).pipe(tap(data => data),
    //    catchError(this.handleError)
    //);
  }

  ReportIssueEmail(PopReportSendMessage: any): Observable<any> {
    var request: any = {
      setname: PopReportSendMessage.setname,
      cardnumber: PopReportSendMessage.cardnumber,
      subject: PopReportSendMessage.subject,
      description: PopReportSendMessage.description,
      typeofissue: PopReportSendMessage.typeofissue,
      firstname: PopReportSendMessage.firstname,
      email: PopReportSendMessage.email,
      certificationcode: PopReportSendMessage.certificationcode,
      comments: PopReportSendMessage.comments,
      mobile: PopReportSendMessage.mobile,
    };
    return this.apiService
      .post(`/pop-report/SendEmail`, request)

      .pipe(
        catchError((error: any) =>
          this.errorService.handleAsDefaultErrorOrderSubmit(error)
        )
      );
  }

  AuthCodeEmail(AuthCodeLookUpQuestions: any): Observable<any> {
    var request: any = {
      typeofissue: AuthCodeLookUpQuestions.typeofissue,
      firstname: AuthCodeLookUpQuestions.firstname,
      email: AuthCodeLookUpQuestions.email,
      comments: AuthCodeLookUpQuestions.comments,
      mobile: AuthCodeLookUpQuestions.mobile,
      authcode: AuthCodeLookUpQuestions.authcode,
    };
    return this.apiService
      .post(`/pop-report/AuthCodeLookUpQuestionSendEmail`, request)
      .pipe(
        catchError((error: any) =>
          this.errorService.handleAsDefaultErrorOrderSubmit(error)
        )
      );
  }

  ContactUsEmail(
    ContactUs: any,
    isUserLoggedIn: any,
    customerNo: any,
    page: any
  ): Observable<any> {
    var request: any = {
      typeofinquiry: ContactUs.typeofinquiry,
      name: ContactUs.name,
      email: ContactUs.email,
      message: ContactUs.message,
      comments: ContactUs.comments,
      orderNumber: ContactUs.orderNumber,
      issue: ContactUs.issue,
      //customerNumber: ContactUs.customerNumber,
      //NumOfCard: ContactUs.NumOfCard,
      //EstPay: ContactUs.EstPay,
      phone: ContactUs.phone,
      phoneoptional: ContactUs.phoneoptional,
      isUserLoggedIn: isUserLoggedIn,
      customerNo: customerNo,
      page: page,
      //besttimetocall: ContactUs.besttimetocall,
      //captchaId: data.captchaId,
      //userEnteredCaptchaCode: data.userEnteredCaptchaCode,
    };
    return this.apiService
      .post(`/pop-report/ContactUsEmail`, request)

      .pipe(
        catchError((error: any) =>
          this.errorService.handleAsDefaultErrorOrderSubmit(error)
        )
      );
  }

  public GetCertAuthCode(
    authCode: string,
    grade: string,
    subject: string
  ): Observable<PopReportAuthCode> {
    var authcodecertcode = authCode.charAt(0);
    if (
      authcodecertcode == 'A' ||
      authcodecertcode == 'B' ||
      authcodecertcode == 'a' ||
      authcodecertcode == 'b'
    ) {
      grade = 'A';
      subject = 'empty';
    } else {
      if (grade == '') {
        grade = 'empty';
      }
      if (subject == '') {
        subject = 'empty';
      }
    }

    return this.apiService
      .post(`/pop-report/GetCertAuthCode`, { authcode: authCode, grade: grade, subject: subject })
      .pipe(
        catchError((error: any) =>
          this.errorService.handleAsDefaultErrorOrderSubmit(error)
        )
      );
    //var orderType = order.getOrderType();

    //return this.apiService.put(`/orders/${orderId}/type`, { "code": orderType.getCode() }).map(data => new Order(data));

    //return this.apiService.get(`/pop-report/${authCode}/${grade}/${subject}/shipping-methods/available`).map(data => _.map(data.items, item => new ShippingMethod(item)));
    //return this.apiService.get(`/orders/${orderId}`).map(data => new Order(data));
  }
  public SaveCardToMyCollections(
    userId: string | undefined,
    authCode: any
  ): Observable<any> {
    var request: any = {
      authCode: authCode,
    };

    return this.apiService
      .post(`/my-collection/AddCardsToMyCollection/${userId}`, request)
      .pipe(
        catchError((error) => {
          console.error('error', error);
          return of(error);
        })
      );
  }

  public GetAllPopulationreportsheetcut(
    cardSet: any,
    sport: any
  ): Observable<PopulationReport[]> {
    return this.apiService
      .get(`/pop-report/GetAllPopulationreportsheetcut/${cardSet}/${sport}`)

      .pipe(
        catchError((error: any) =>
          this.errorService.handleAsDefaultErrorOrderSubmit(error)
        )
      );
    //let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    //headers = headers.set('Authorization', `Bearer ${localStorage.getItem('access_token')}`);
    //return this.http.get<PopulationReport[]>(this.baseUrl + '/pop-report/getPopReportByCardSet/' + cardSet, { headers: headers }).pipe(tap(data => data),
    //    catchError(this.handleError)
    //);
  }

  public GetPopReportYear(
    cardSet: any,
    sport: any
  ): Observable<PopulationReportYear> {
    return this.apiService
      .get(`/pop-report/GetPopReportYear/${cardSet}/${sport}`)

      .pipe(
        catchError((error: any) =>
          this.errorService.handleAsDefaultErrorOrderSubmit(error)
        )
      );
    //let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    //headers = headers.set('Authorization', `Bearer ${localStorage.getItem('access_token')}`);
    //return this.http.get<PopulationReportYear>(this.baseUrl + '/pop-report/GetPopReportYear/' + cardSet, { headers: headers }).pipe(tap(data => data),
    //    catchError(this.handleError)
    //);
  }

  public getPopReportBySetName(
    setName: string
  ): Observable<PopulationReportSearch[]> {
    return this.apiService
      .post(`/pop-report/getPopReportBySetName`, { setName: setName })

      .pipe(
        catchError((error: any) =>
          this.errorService.handleAsDefaultErrorOrderSubmit(error)
        )
      );
  }

  getPopReports(options: any): Observable<any> {
    var httpOptions = {
      params: [
        { key: 'query', value: options.filter.query },
        { key: 'sport', value: options.filter.sport },
        { key: 'offset', value: options.offset },
        { key: 'limit', value: options.limit },
      ],
    };

    return this.apiService.get('/population-reports', httpOptions).pipe(
      map((data: { items: any }) => {
        let popReports = data;
        popReports.items = _.map(data.items, (item) => new PopReports(item));
        return popReports;
      }),
      catchError((error: any) =>
        this.errorService.handleAsDefaultErrorOrderSubmit(error)
      )
    );
  }

  // getPopReportBySet(setId: number): Observable<any> {
  //   return this.apiService
  //     .get(`/population-reports/sets/${setId}`)
  //     .map((data: any) => new PopReportsSet(data))
  //     .catch((error: any) =>
  //       this.errorService.handleAsDefaultErrorOrderSubmit(error)
  //     );
  // }
  getPopReportBySet(setId: number): Observable<any> {
    return this.apiService.get(`/population-reports/sets/${setId}`).pipe(
      map((data: any) => new PopReportsSet(data)),
      catchError((error: any) =>
        this.errorService.handleAsDefaultErrorOrderSubmit(error)
      )
    );
  }

  getPopReportSports(): Observable<PopReportsSport[]> {
    return of(SPORTS);
  }
}
