<img src="/assets/img/close-modal.svg" class="modal__close" (click)="close()">

<div class="card-details">
    <img src="/assets/img/no-image.png">
    <img src="/assets/img/no-image.png">
    <div class="card-details__info">
        <h2>Ty Cobb</h2>

        <div>
            <i>Year</i>
            <p>1910</p>
        </div>

        <div>
            <i>Set</i>
            <p>1909-11 T206</p>
        </div>

        <div>
            <i>Card</i>
            <p>114</p>
        </div>

        <div>
            <i>Manufacturer</i>
            <p>Piedmont</p>
        </div>

        <div class="card-details__description">
            <i>Description</i>
            <p>
                Phasellus magna dolor, rhoncus sit amet maximus in, convallis condimentum arcu. Praesent eget porta nibh.
            </p>
        </div>

        <div>
            <i>Grading</i>
            <h3>80</h3>
        </div>

        <div>
            <i>Estimated Value</i>
            <h3>$ 7,000</h3>
        </div>

    </div>
</div>