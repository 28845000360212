<div class="modal__content">
    <div class="modal__list">
        <div class="modal__list-columns">
            <label class="toggle">
                Crossover
                <input type="checkbox" [(ngModel)]="data.crossover">
                <span class="toggle__check"></span>
            </label>
    
            <label class="toggle">
                Review
                <input type="checkbox" [(ngModel)]="data.review">
                <span class="toggle__check"></span>
            </label>

            <label class="toggle">
                Reholder
                <input type="checkbox" [(ngModel)]="data.reholder">
                <span class="toggle__check"></span>
            </label>
    
            <label class="toggle">
                Relabel
                <input type="checkbox" [(ngModel)]="data.relabel">
                <span class="toggle__check"></span>
            </label>
    
            <label class="toggle">
                Only A
                <input type="checkbox" [ngModel]="data.encapsulationOnly" (ngModelChange)="setEncapsulationOnly($event)">
                <span class="toggle__check"></span>
            </label>
        </div>

        <div class="modal__list-columns">
            <input type="text" class="input" placeholder="Minimum Requested Grade" [(ngModel)]="data.minimumRequestedGrade">
            <input type="text" class="input" placeholder="Certification Code" [(ngModel)]="data.certificationCode">
        </div>
    </div>
</div>
<div class="modal__actions">
    <button class="button button--secondary" (click)="close()">Cancel</button>
    <button class="button" [disabled]="!canSave() || isSubmitting" [class.button--disabled]="!canSave() || isSubmitting" (click)="save()">
        <div *ngIf="isSubmitting" class="loader xs invert-color"></div>
        <span *ngIf="!isSubmitting">Save</span>
    </button>
</div>
