import { Injectable } from '@angular/core';
// import 'rxjs/add/observable/of';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import * as _ from 'lodash';
import { ErrorService } from '../../common/services/error.service';
import {of,catchError,map} from 'rxjs'
@Injectable()
export class SearchService {

    constructor(
        private apiService: ApiService,
        private errorService: ErrorService
    ){}

    searchItemByKeyword(options: any): Observable<any> {

        var httpOptions = {
            params: [
                { key: "query", value: options.filter.query },
                { key: "kind", value: options.filter.kind },
                { key: "offset", value: options.offset },
                { key: "limit", value: options.limit },
                { key: "ordertype", value: options.filter.ordertype }
            ]
        };

        return this.apiService.get("/catalog/search", httpOptions).pipe(map((x:any) => { 
            _.forEach(x.items, y => this.parseItem(y))
            return x;
        }),catchError((error:any) => this.errorService.handleAsDefaultErrorOrderSubmit(error)))
    }

    parseItem(item: any): any {

        if(!item || item.kind != 'card' || !item.name) return item;
        
        var yearLabel = /^(?:\d+-*\d*)/g.exec(item.name);

        if(!yearLabel) return item;
        
        item.year = yearLabel[0];

        return item;
    }
}
