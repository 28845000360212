<div class="checkout__content">
    <div class="overlay" [class.active]="isRefreshing" *ngIf="isRefreshing" style="height:1030%;width:100%;position:relative;">
        <div class="loader m"></div>
    </div>
    <!--<h2>Ship.Grade.Collect</h2>-->
    <h2 *ngIf="orderTypeCode != 'card-pre-grade'">STEP 3. SELECT SERVICE</h2>
    <h2 *ngIf="orderTypeCode == 'card-pre-grade'">STEP 3. PRE-GRADE PRICING PER CARD</h2>

    <!--<h3>Select plan</h3>-->
    <div class="checkout__grid-items" style=" margin-left: 5%;margin-right:32px; grid-gap: 50px; grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); margin-top: 30px; height: 20rem;" *ngIf="orderTypeCode != 'card-pre-grade'">
        <div class="checkout__plans" *ngFor="let plan of getPlans()">
            <!--<h5 *ngIf="plan.isRecommended">Recommended</h5>-->
            <div class="item item--plans fade-in" [class.businessdayGur--off]="isGuranteeOff(plan)" [class.item--selected]="isSelected(plan)" (click)="select(plan)" style="padding: 55px 14px 40px;">
                <h2 style="margin-top:-18px;">{{getTurnAroundDays(plan)}}</h2>
                <h3>{{getPlanName(plan)}}</h3>
                <h3 style="margin-top:10px;">Total Grading Fee: {{plan.fee | currency: 'USD': 'symbol': '1.0-0'}}</h3>
                <!--<i>{{getTurnAroundTime(plan)}}</i>-->
            </div>
        </div>
        <button class="button button--more" *ngIf="showMorePlans()" (click)="togglePlans()">View more plans</button>
    </div>

    <div class="content content--compact overflow-x-auto" style="grid-gap:30px 0px;margin-bottom:20px;" *ngIf="orderTypeCode == 'card-pre-grade'">

        <!--<h3>PRICING FOR STANDARD SIZE PRE-GRADE</h3>-->
        <ejs-grid #grid [dataSource]='dataStandardRawCard' [gridLines]="lines" allowTextWrap='true' [textWrapSettings]='wrapSettings'>
            <e-columns>
                <e-column field='DECVALUE' headerText='DECLARED VALUE' textAlign='Center' width=''></e-column>
                <e-column field='BusinessDays' headerText='Estimated Days' textAlign='Center' width='100'></e-column>
                <e-column field='ServiceFee' headerText='Service Fee' textAlign='Center' width='100'></e-column>
            </e-columns>
        </ejs-grid>
            <!--<section id="base-pricing" style="margin-top:20px;">
            <div class="grid-list">
                <div class="grid-list__note">
                    <p>** For each additional $10,000 over $100,000 in card value, add $375 to the base fee.</p>
                </div>
            </div>
        </section>-->
        <div style="visibility:hidden;" *ngFor="let plan of getPlans()">
            <div [class.item--selected]="isRawCardReviewSelected(plan)">
            </div>
        </div>
    </div>

</div>
<style type="text/css">
    @media only screen and (max-width: 550px) {
        .checkout__content {
            padding-bottom: 350px !important;
        }
    }
    @media only screen and (max-height: 420px) {
.checkout__content {
padding-bottom: 80px !important;
}
}
</style>
