import { Injectable } from '@angular/core';
import { AlertService } from './alert.service';
import { MonitorService } from './monitor.service';
import { Observable, BehaviorSubject, from } from 'rxjs';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import {of,throwError} from 'rxjs'
@Injectable()
export class ErrorService {

    constructor(
        private alertService: AlertService,
        private monitorService: MonitorService,
        private authService: AuthService,
        private router: Router,
    ) {
        this.init();
    }

    private isPaypalExecption = new BehaviorSubject<string>("");
    paypalExecption$ = this.isPaypalExecption.asObservable();
    paypalErrorMsg(ExeceptionMsg:any) {
        this.isPaypalExecption.next(ExeceptionMsg);
    }

    registrations = new Array<ErrorRegistration>();

    handleError(error: any, context: any): Observable<any> {

        var errorId = this.getErrorId(error);

        var registration = this.getRegistration(errorId);

        if (!registration) {
            return this.handleAsDefaultError(error);
        }

        var mapping = registration.getMapping(context.operation);

        if (!mapping) {
            return this.handleAsDefaultError(error);
        }

        var message = this.getErrorMessage(error);
        mapping && mapping.message && (message = mapping.message);
        this.alertService.error(message, mapping);
        this.monitorService.trackError(error);

        return of(null);
    }

    handleAsDefaultError(error: any): Observable<any> {
        this.alertService.error("Ops! Something went wrong. Please try again in a few seconds.");
        return throwError(error);
    }

    handleAsDefaultErrorOrderSubmit(error: any): Observable<any> {
        //code: "order-has-no-items"
        //code: "invalid-order-status"
        //code:"order-has-no-shipping-method" 400
        if (error.status == 409 && error.error.code=="order-has-no-items") {
            this.alertService.error("Please add cards to the order.");
        }
        if (error.status == 409 && error.error.code == "invalid-order-status") {
            this.alertService.error("Order is already submitted. Please start a new submission.");
        }
        if (error.status == 400 && error.error.code == "order-has-no-shipping-method" ) {
            this.alertService.error("Please select a shipping method from the Shipping page.");
        }
        if (error.status == 429) {
            this.alertService.error("We're allocating more resources for your request. Please try again.");
        }
        return throwError(error);
    }
    promoCodeError(error: any): Observable<any> {
        console.log('errorerrorerrorerrorerror',error.error)
        this.alertService.error(error.error);
        return throwError(error);
    }

    handleAsDefaultErrorOrderType(error: any): Observable<any> {
        this.alertService.error(error.error.message);
        return throwError(error);
    }

    uspHandleError(error: any): Observable<any> {
        this.alertService.error(error);
        return throwError(error);
    }

    paypalHandleError(error: any): Observable<any> {
        this.alertService.error(error);
        return throwError(error);
    }

    handlePaypalError(error: any, orderId: any): Observable<any> {
        this.paypalErrorMsg(error.message);
        this.router.navigate([`/orders/${orderId}/paypal`])
        return throwError(error);
    }

    getErrorId(error: any): string {
        if (!error) return 'unknown';
        if (error.error && error.error.code) return error.error.code;
        if (error.status) return error.status.toString();
        return 'unknown';
    }

    getErrorMessage(error: any): string {
        if (!error) return 'unknown';
        if (error.error && error.error.message) return error.error.message;
        return 'unknown';
    }

    getRegistration(errorId: string): ErrorRegistration {
        return this.registrations.find(x => x.errorId == errorId)!;
    }

    register(errorId: string, message: string, operation: string) {
        this.registerWithOptions(errorId, {
            message: message,
            operation: operation
        });
    }

    registerWithOptions(errorId: string, mappingOptions: any) {
        var registration = this.getRegistration(errorId);
        if (!registration) {
            registration = new ErrorRegistration({ errorId: errorId });
            this.registrations.push(registration);
        }
        registration.mappings.push(new ErrorMapping(mappingOptions));
    }

    init() {
        this.registerWithOptions('customer-not-found', {
            message: 'Looks like you are trying to create an order as an employee. Please sign out and sign up with another email or username.',
            operation: 'CheckoutService.createOrder',
            actionLabel: 'Sign out',
            actionCallback: () => this.authService.logout()
        });

        this.registerWithOptions('invalid-promo-code', { message: 'The promo code is not valid.' });
        this.registerWithOptions('promo-code-expired', { message: 'The promo code has expired. Try with another one.' });
        this.registerWithOptions('promo-code-not-available', { message: 'The promo code is no longer available.' });
        this.registerWithOptions('auth0-exception', {});
    }
}

export class ErrorRegistration {

    constructor(options?: any) {
        if (!options) return;
        this.errorId = options.errorId;
    }

    errorId!: string;
    mappings = new Array<ErrorMapping>();

    getMapping(operation: string): ErrorMapping {
        var mapping = this.mappings.find(x => x.operation == operation);
        if (mapping) {
            return mapping;
        }
        mapping = this.mappings.find(x => !x.operation);
        if (mapping) {
            return mapping;
        }
        if (this.mappings.length > 0) {
            return this.mappings[0];
        }
        return null!;
    }
}

export class ErrorMapping {

    constructor(options?: any) {
        if (!options) return;
        this.message = options.message;
        this.operation = options.operation;
        this.actionCallback = options.actionCallback;
        this.actionLabel = options.actionLabel;
    }

    message!: string;
    operation!: string;
    actionLabel!: string;
    actionCallback: any;
}
