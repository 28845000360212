import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { OrderService } from "../../services/order.service";
import { Order, OrderType, OrderItem } from "../../models/order";
import { Location } from '@angular/common';
import { StateService } from "../../../common/services/state.service";
import { User } from "../../../common/models/user";
import { Router } from "@angular/router";
import { UserService } from "src/app/common/services/user.service";

@Component({
    selector: 'invoice',
    templateUrl: './invoice.component.html',
    styleUrls: ['./invoice.component.sass']
})
export class InvoiceComponent implements OnInit {

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private orderService: OrderService,
        private location: Location,
        private stateService: StateService,
        private userService: UserService
    ) { }

    order = new Order();
    currentUser = new User();
    orderStatus: any;
    ShowIcon: boolean = false;
    orderTypeCode: any;
    AttnDept: any;
    isEmployee: boolean = false;

    back() {
        //this.location.back();
        this.router.navigate(['/account/myorder']);
    }

    getShippingMethod(): string {
        if (this.order.orderType.code == '23NSCCONSITE') {
            return '/assets/img/SGC_Dark-New.png';
        }
        else {
            return this.order.getShippingMethodName();
        }
    }

    getInvoiceHeader(): string {
        return this.order.getInvoiceHeader();
    }

    getShippingMethodImageUrl(): string {
      if (!this.order.shippingMethod) return '/assets/img/shipping-empty.png';

      return !this.order.isShippingMethodPickUp()
        ? `/assets/img/${this.order.shippingMethod.carrier?.code}.png`
        : '/assets/img/shipping-empty.png';
    }

    getShippingOptionText(): string {
        return this.order.settings.useFedexAccount ? `Payment via FedEx account (${this.order.settings.fedexAccount})` : 'Payment via SGC Account';
    }

    getOrderItems(): any[] {
        return this.order.getItems();
    }

    getOrderType(): OrderType {
        return this.order.getOrderType();
    }

    getOrderTypeName(): string {
        return this.order.getOrderTypeName();
    }

    getItemsCount(): number {
        return this.order.getItemsCount();
    }
    getCardExtraData(item: OrderItem) {
        var cardExtraData = new Array<any>();

        if (item.data.crossover) {
            cardExtraData.push({
                label: 'crossover'
            })
        }
        if (item.data.pregradeEncapsulation) {
            cardExtraData.push({
                label: 'Pre-Grade'
            })
        }
        if (this.isEmployee && item.data.minimumRequestedGrade) {
            cardExtraData.push({
                label: item.data.minimumRequestedGrade
            })
        }
        if (!this.isEmployee && (this.order.orderStatus.code == "shipped" || this.order.orderStatus.code == "delivered") && item.data.minimumRequestedGrade) {
            cardExtraData.push({
                label: item.data.minimumRequestedGrade
            })
        }
        if (item.data.reholder) {
            cardExtraData.push({
                label: 'Reholder'
            })
        }
        if (item.data.review) {
            cardExtraData.push({
                label: 'review'
            })
        }
        if (item.data.review == false && item.data.reholder == false && item.data.crossover == false && item.data.pregradeEncapsulation == false) {
            cardExtraData.push({
                label: 'Raw Card Grading'
            })
        }


        if (item.data.encapsulationOnly) {
            cardExtraData.push({
                label: 'only A'
            })
        }
        if (item.data.relabel) {
            cardExtraData.push({
                label: 'relabel'
            })
        }
        if (item.settings.encapsulationRequested) {
            cardExtraData.push({
                label: 'encapsulation'
            })
        }

        if (this.isEmployee && item.data.autoGrade) {
            cardExtraData.push({
                label: 'Auto Grade'
            })
        }
        if (!this.isEmployee && (this.order.orderStatus.code == "shipped" || this.order.orderStatus.code == "delivered") && item.data.autoGrade) {
            cardExtraData.push({
                label: 'Auto Grade'
            })
        }
        if (item.data.autograph) {
            cardExtraData.push({
                label: 'Autograph'
            })
        }
        if (item.data.sheetCut) {
            cardExtraData.push({
                label: 'Sheet Cut'
            })
        }

        return cardExtraData;
    }

    getTurnaround(): any {

        if (this.order.orderType.code == '23NSCCONSITE') {
            if (this.order.getTotalTurnaround() == 1) return 'SAME DAY';
            if (this.order.getTotalTurnaround() == 4) return 'END OF SHOW';
        }
        else if (this.order.orderType.code == '23NSCCTAKEBACK') {
            return '5-10 estimated business days';
        }

        else if (this.order.getTotalTurnaround() == 1) {
            return "End of Show";
        }
        else if (this.order.getTotalTurnaround() == 2) {
            return "1-2 estimated business days";
        }
        else if (this.order.getTotalTurnaround() == 10) {
            return "5-10 estimated business days";
        }
        //else if (this.order.turnaround.total == 5 && this.order.planExist == true) {
        //    return "1-5 estimated business days";
        //}
        else if (this.order.turnaround.total == 45) {
            return "40-45 estimated business days";
        }
        else if (this.order.getTotalTurnaround() == 48) {
            return "43-48 estimated business days";
        }
        else if (this.order.getTotalTurnaround() == 55) {
            return "50-55 estimated business days";
        }
        else if (this.order.getTotalTurnaround() == 57) {
            return "52-57 estimated business days";
        }
        else if (this.order.getTotalTurnaround() == 70) {
            return "65-70 estimated business days";
        }
        else if (this.order.getTotalTurnaround() == 25) {
            return "20-25 estimated business days";
        }
        else if (this.order.getTotalTurnaround() == 3) {
            return "1-3 estimated business days";
        }
        else if (this.order.getTotalTurnaround() == 50) {
            return "45-50 estimated business days";
        }
        else if (this.order.getTotalTurnaround() == 50) {
            return "45-50 estimated business days";
        }
        //else if (this.order.getTotalTurnaround() == 30) {
        //    return "30 Business Days Guaranteed";
        //}
        //else if (this.order.getTotalTurnaround() == 100) {
        //    return "Special Items Non-Guarantee";
        //}
        else {
            return this.order.getTotalTurnaround() ? this.order.getTotalTurnaround() + " estimated business days" : this.order.getTotalTurnaround();
        }
    }

    getTotalServiceFee(): number {
        return this.order.getTotalServiceFee();
    }

    getShippingMethodName(): string {
        return this.order.getShippingMethodName();
    }

    print() {
        if (this.order.orderStatus.code == "draft") {
            document.title = `SGC - Packing Slip #${this.order.publicId}`;
        }
        else {
            document.title = `SGC - Invoice #${this.order.publicId}`;
        }
        window.print();
    }

    ngOnInit() {
        this.userService.userProfile$.subscribe((user) => {
            this.isEmployee = user.isEmployee;
          });
        var orderId = this.getOrderId();
        if (!orderId) return;
        this.orderService.getOrder(orderId).subscribe(order => {
            this.order = order;
            this.orderTypeCode = order.orderType.code;

            if (this.order.orderStatus.code == "shipped" || this.order.orderStatus.code == "delivered") {
                this.ShowIcon = true;
            }
            if (this.order.orderStatus.code == "draft") {
                this.orderStatus = "PACKING SLIP";
            }
            else {
                this.orderStatus = "Invoice";
            }

            if (this.order.orderType.code == 'card-grading' && this.order.getTotalTurnaround() == 2) {
                this.AttnDept = "Attn: Dept. E, ";
            }
            else if (this.order.orderType.code == '22BOWMANCHR') {
                this.AttnDept = "Attn: Dept. B, ";
            }
            else if (this.order.orderType.code == '22POKEMON') {
                this.AttnDept = "Attn: Dept. P, ";
            }
            else if (this.order.orderType.code == 'GRNOTES') {
                this.AttnDept = "Attn: Dept. G, ";
            }
            else if (this.order.orderType.code == '23TOPPSS2') {
                this.AttnDept = "Attn: Dept. T, ";
            }
            else if (this.order.orderType.code == 'FOOTBALL2023') {
                this.AttnDept = "Attn: Dept. F, ";
            }
            else if (this.order.orderType.code == '23BOWMAN') {
                this.AttnDept = "Attn: Dept. B, ";
            }
            else {
                this.AttnDept = "";
            }

            //setTimeout(() => {
            //    this.print();
            //}, 500);
        });
        this.currentUser = this.stateService.currentUser.get();
    }


    getNsccOrderType(order: Order): any | undefined {
        if (order.orderType.code == '23NSCCONSITE') {
            if (order.turnaround.total == 1) return 'SAME DAY ';
            if (order.turnaround.total == 4) return 'END OF SHOW';
        }
    }

    get275AuthImageFront(id: any): string {
        return "https://sgcimagprodstorage.blob.core.windows.net/mycollections/" + id.toLowerCase() + "/h275/front/" + id.toLowerCase() + ".jpg";
    }
    get275AuthImageBack(id: any): string {
        return "https://sgcimagprodstorage.blob.core.windows.net/mycollections/" + id.toLowerCase() + "/h275/back/" + id.toLowerCase() + ".jpg";
    }

    getOrderId() {
        if (!this.route.snapshot.params) return null;

        return this.route.snapshot.params['id'];
    }

    showInvertedRowStyle(index: number): boolean {
        return index % 2 != 0;
    }

    getAddressPhoneNumber(): string | undefined {
        return this.hasAddressPhoneNumber() ? this.order.shippingAddress.phoneNumber : undefined;
    }

    hasAddressPhoneNumber(): boolean {
        return this.order && this.order.shippingAddress && this.order.shippingAddress.phoneNumber != null;
    }

    getCardName(item: any): string {
        return item && item.card ? item.card.name
            : item.name ? item.name : '--';
    }

    isCardGrading(): boolean {
        return this.order.orderType && (this.order.orderType.isCardGrading() || this.order.orderType.isRawCardReview()
            || this.order.orderType.isBowmanChrome() || this.order.orderType.isGRNOTES()
            || this.order.orderType.isPokemanspecials() || this.order.orderType.is23Bowman()
            || this.order.orderType.isToppsSeries2() || this.order.orderType.isFootBall()
            || this.order.orderType.is23TOPPSCHR() || this.order.orderType.is23NSCCONSITE() || this.order.orderType.is24BASKETBALL() || this.order.orderType.is24TOPPSS2() || this.order.orderType.is23NSCCTAKEBACK() || this.getOrderType().is23BOWMANEOY()
        );
    }

    isAutographAuthentication(): boolean {
        return this.order.orderType && this.order.orderType.isAutographAuthentication();
    }

    getGradeResult(item: any): string {
        return item && item.getGradeResult();
    }

    showGradeResult(item: any): boolean {
        return this.showResults() && item.hasGradeResult();
    }

    showAuthenticationCode(item: any): boolean {
        return this.showResults() && item.authenticationCode;
    }

    getAuthenticationCode(item: OrderItem): string {
        return item.getAuthenticationCode();
    }

    showResults(): boolean {
        return this.order.isShipped();
    }

    getPrintingInstruction(): any | undefined {
        if (this.order.orderType.code == '23NSCCONSITE') {
            if (this.order.turnaround.total == 1) return 'Please print out this invoice and bring it along with your cards to booth #525.';
            if (this.order.turnaround.total == 4) return 'Please print out this invoice and bring it along with your cards to booth #525.';
        }
        else if (this.order.orderType.code == '23NSCCTAKEBACK') {
             return 'Please print out this invoice and bring it along with your cards to booth #525.';
        }
        else {
            return this.isCardGrading() ? 'Please print out this invoice and send it along with your cards for grading to the address below:' : 'Please print out this invoice and send it along with your items for autograph authentication to the address below:';
        }
    }

    checkTypeForAddress(order: Order): boolean {
        if (order.orderType.code == '23NSCCONSITE' || order.orderType.code == '23NSCCTAKEBACK') {
            return true
        }
        else
            return false;
    }

    getCardDescription(item: any): string {

        let val = item && item?.card ? item.card.description : item?.line3;
        return val;
    }

    isAlteredItemsEncapsulated(item:any): boolean {
        return item.getRejectionCode() === 'A' && item.getShouldEncapsulate();
    }

    getHexagonPath(): string {
        return '../../../assets/img/hexagon-empty.svg';
    }

    getImageAccountUrl() {
        const regex = /^(http|https|ftp):\/\//i;

        if (regex.test(this.currentUser.imageAccountUrl)) {
            return this.currentUser.imageAccountUrl;
        }

        return `//${this.currentUser.imageAccountUrl}`;
    }

    hasImageAccountUrl(): boolean {
        return this.currentUser.imageAccountUrl != null;
    }
}
