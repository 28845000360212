import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import {
  PopulationReport,
  PopulationReportYear,
} from '../../models/PopulationReport';
import { GradingValues } from '../../models/PopulationReport';
import { FormControl, Validators } from '@angular/forms';
import { PopReportsService } from '../../services/pop-reports.service';
import { PopReportsSport } from '../../models/pop-reports-sport';
import { ErrorService } from '../../../common/services/error.service';
import {
  ColumnModel,
  GroupSettingsModel,
  ToolbarItems,
  FilterSettingsModel,
  IFilter,
} from '@syncfusion/ej2-grids';
import {
  GridComponent,
  PageSettingsModel,
  TextWrapSettingsModel,
  ContextMenuItemModel,
  ContextMenuService,
  InfiniteScrollService,
} from '@syncfusion/ej2-angular-grids';
import { Router, ActivatedRoute, Data } from '@angular/router';
import {
  SidebarComponent,
  MenuEventArgs,
} from '@syncfusion/ej2-angular-navigations';
import {
  ButtonComponent,
  RadioButtonComponent,
} from '@syncfusion/ej2-angular-buttons';
import { L10n, setCulture } from '@syncfusion/ej2-base';
import { SubSink } from 'subsink';
import { HttpClient } from '@angular/common/http';
import { SearchSettingsModel } from '@syncfusion/ej2-angular-grids';
setCulture('en-US');

L10n.load({
  'en-US': {
    grid: {
      EmptyRecord: 'No records to display',
    },
  },
});

@Component({
  selector: 'app-pop-reportresult',
  templateUrl: './pop-reportresult.component.html',
  styleUrls: ['./pop-reportresult.component.css'],
  //encapsulation: ViewEncapsulation.None,
  providers: [ContextMenuService,InfiniteScrollService],
})
export class PopReportresultComponent implements OnInit, OnDestroy {
  //public stackedHeaderRows: any;
  //@ViewChild('sidebar') sidebar: SidebarComponent;
  //public type: string = 'Push';
  //public target: string = 'content';
  //public enablePersistence: boolean = true;
  //@ViewChild('togglebtn')
  //public togglebtn: ButtonComponent;
  //public onCreated(args: any) {
  //    this.sidebar.element.style.visibility = '';
  //    this.sidebar.hide();
  //}
  //btnClick() {
  //    if (this.togglebtn.element.classList.contains('e-active')) {
  //        this.togglebtn.content = 'View POP Options';
  //        this.sidebar.hide();
  //    } else {
  //        this.togglebtn.content = 'Close';
  //        this.sidebar.show();
  //        this.sidebar.position = "Right";
  //    }
  //}
  //closeClick() {
  //    this.sidebar.hide();
  //    this.togglebtn.element.classList.remove('e-active');
  //    this.togglebtn.content = 'Close'
  //}
  //@ViewChild('radio')
  //public radiobutton: RadioButtonComponent;
  //public changeHandler(args: any): void {
  //    this.sidebar.position = (args.event.target.ej2_instances[0].label == "Left") ? "Left" : "Right";
  //}

  private subs = new SubSink();
  public AllPopulationReport!: PopulationReport[];

  //public Alldata: object[];
  //public sortOptions: Object;
  //public initial: boolean = true;
  //public groupOptions: GroupSettingsModel;

  @ViewChild('grid')
  public grid!: GridComponent;
  public filterOptions!: FilterSettingsModel;
  public filter!: IFilter;
  public wrapSettings!: TextWrapSettingsModel;

  //AllPopreportYear: PopulationReportYear = new PopulationReportYear();
  //grades = new Array<any>();
  //public toolbarOptions: ToolbarItems[];

  //public grade10P: ColumnModel[];
  //public grade10: ColumnModel[];
  //public grade9pt5: ColumnModel[];
  //public grade9: ColumnModel[];
  //public grade8pt5: ColumnModel[];
  //public grade8: ColumnModel[];
  //public grade7pt5: ColumnModel[];
  //public grade7: ColumnModel[];
  //public grade6pt5: ColumnModel[];
  //public grade6: ColumnModel[];
  //public grade5pt5: ColumnModel[];
  //public grade5: ColumnModel[];
  //public grade4pt5: ColumnModel[];
  //public grade4: ColumnModel[];
  //public grade3pt5: ColumnModel[];
  //public grade3: ColumnModel[];
  //public grade2pt5: ColumnModel[];
  //public grade2: ColumnModel[];
  //public grade1pt5: ColumnModel[];
  //public grade1: ColumnModel[];
  //public gradeA: ColumnModel[];
  //public options: PageSettingsModel;

  setName: any;
  cardSet: any;

  //year: any;
  //manufacturer: any;
  //sport: any;

  sportcard: any;
  page: any;
  description: any;
  isSearching = true;
  showt205 = false;
  showt206 = false;
  //enable = true;
  GradingOrder: FormControl = new FormControl(true);
  SgcGrades: FormControl = new FormControl(false);

  public contextMenuItems: ContextMenuItemModel[] = [
    { text: 'Report an Issue', target: '.e-content', id: 'copywithheader' },
  ];

  SelectGrades: FormControl = new FormControl('All', Validators.required);
  SelectGradingOrder: FormControl = new FormControl('10', Validators.required);
  oldSgcGrades: FormControl = new FormControl('No', Validators.required);
  Issheetcut: FormControl = new FormControl('0', Validators.required);
  public options?: PageSettingsModel;
  searchText = '';
  public searchSettings!: SearchSettingsModel;

  constructor(
    private router: Router,
    private _routeParams: ActivatedRoute,
    private popReportsService: PopReportsService,
    private errorService: ErrorService,
    private httpClient: HttpClient
  ) {}

  public dataGrades: string[] = [
    'All',
    '10 Pristine only',
    '10 Gem or 10 Pristine',
    '9.5 MT+ or better',
    '9 MT or better',
    '8.5 NM MT+ or better',
    '8 NM MT or better',
    '7.5 NM+ or better',
    '7 NM or better',
    '6.5 EX NM+ or better',
    '6 EX NM or better',
    '5.5 EX+ or better',
    '5 EX or better',
    '4.5 VG EX+ or better',
    '4 VG EX or better',
    '3.5 VG+ or better',
    '3 VG or better',
    '2.5 Good+ or better',
    '2 Good or better',
    '1.5 Fair or better',
    '1 Poor or better',
    'A',
  ];
  public AllWithSum = [
    'Total 10 PRI',
    'Total 10 Gem/Pri',
    'Total 9.5 ≥',
    'Total 9 ≥',
    'Total 8.5 ≥',
    'Total 8 ≥',
    'Total 7.5 ≥',
    'Total 7 ≥',
    'Total 6.5 ≥',
    'Total 6 ≥',
    'Total 5.5 ≥',
    'Total 5 ≥',
    'Total 4.5 ≥',
    'Total 4 ≥',
    'Total 3.5 ≥',
    'Total 3 ≥',
    'Total 2.5 ≥',
    'Total 2 ≥',
    'Total 1.5 ≥',
    'Total 1 ≥',
    'Total',
    '10 PRI',
    '10',
    '9.5',
    '9',
    '8.5',
    '8',
    '7.5',
    '7',
    '6.5',
    '6',
    '5.5',
    '5',
    '4.5',
    '4',
    '3.5',
    '3',
    '2.5',
    '2',
    '1.5',
    '1',
    'A',
  ];
  public All = [
    '10 PRI',
    '10',
    '9.5',
    '9',
    '8.5',
    '8',
    '7.5',
    '7',
    '6.5',
    '6',
    '5.5',
    '5',
    '4.5',
    '4',
    '3.5',
    '3',
    '2.5',
    '2',
    '1.5',
    '1',
    'A',
    'Total',
  ];
  public TenPRI = ['10 PRI', 'Total 10 PRI', 'Total'];
  public Ten = ['10 PRI', '10', 'Total 10 Gem/Pri', 'Total'];
  public Ninepfive = ['10 PRI', '10', '9.5', 'Total 9.5 ≥', 'Total'];
  public Nine = ['10 PRI', '10', '9.5', '9', 'Total 9 ≥', 'Total'];
  public Eightpfive = [
    '10 PRI',
    '10',
    '9.5',
    '9',
    '8.5',
    'Total 8.5 ≥',
    'Total',
  ];
  public Eight = ['10 PRI', '10', '9.5', '9', '8.5', '8', 'Total 8 ≥', 'Total'];
  public Sevenpfive = [
    '10 PRI',
    '10',
    '9.5',
    '9',
    '8.5',
    '8',
    '7.5',
    'Total 7.5 ≥',
    'Total',
  ];
  public Seven = [
    '10 PRI',
    '10',
    '9.5',
    '9',
    '8.5',
    '8',
    '7.5',
    '7',
    'Total 7 ≥',
    'Total',
  ];
  public Sixpfive = [
    '10 PRI',
    '10',
    '9.5',
    '9',
    '8.5',
    '8',
    '7.5',
    '7',
    '6.5',
    'Total 6.5 ≥',
    'Total',
  ];
  public Six = [
    '10 PRI',
    '10',
    '9.5',
    '9',
    '8.5',
    '8',
    '7.5',
    '7',
    '6.5',
    '6',
    'Total 6 ≥',
    'Total',
  ];
  public Fivepfive = [
    '10 PRI',
    '10',
    '9.5',
    '9',
    '8.5',
    '8',
    '7.5',
    '7',
    '6.5',
    '6',
    '5.5',
    'Total 5.5 ≥',
    'Total',
  ];
  public Five = [
    '10 PRI',
    '10',
    '9.5',
    '9',
    '8.5',
    '8',
    '7.5',
    '7',
    '6.5',
    '6',
    '5.5',
    '5',
    'Total 5 ≥',
    'Total',
  ];
  public Fourpfive = [
    '10 PRI',
    '10',
    '9.5',
    '9',
    '8.5',
    '8',
    '7.5',
    '7',
    '6.5',
    '6',
    '5.5',
    '5',
    '4.5',
    'Total 4.5 ≥',
    'Total',
  ];
  public Four = [
    '10 PRI',
    '10',
    '9.5',
    '9',
    '8.5',
    '8',
    '7.5',
    '7',
    '6.5',
    '6',
    '5.5',
    '5',
    '4.5',
    '4',
    'Total 4 ≥',
    'Total',
  ];
  public Threepfive = [
    '10 PRI',
    '10',
    '9.5',
    '9',
    '8.5',
    '8',
    '7.5',
    '7',
    '6.5',
    '6',
    '5.5',
    '5',
    '4.5',
    '4',
    '3.5',
    'Total 3.5 ≥',
    'Total',
  ];
  public Three = [
    '10 PRI',
    '10',
    '9.5',
    '9',
    '8.5',
    '8',
    '7.5',
    '7',
    '6.5',
    '6',
    '5.5',
    '5',
    '4.5',
    '4',
    '3.5',
    '3',
    'Total 3 ≥',
    'Total',
  ];
  public Twopfive = [
    '10 PRI',
    '10',
    '9.5',
    '9',
    '8.5',
    '8',
    '7.5',
    '7',
    '6.5',
    '6',
    '5.5',
    '5',
    '4.5',
    '4',
    '3.5',
    '3',
    '2.5',
    'Total 2.5 ≥',
    'Total',
  ];
  public Two = [
    '10 PRI',
    '10',
    '9.5',
    '9',
    '8.5',
    '8',
    '7.5',
    '7',
    '6.5',
    '6',
    '5.5',
    '5',
    '4.5',
    '4',
    '3.5',
    '3',
    '2.5',
    '2',
    'Total 2 ≥',
    'Total',
  ];
  public Onepfive = [
    '10 PRI',
    '10',
    '9.5',
    '9',
    '8.5',
    '8',
    '7.5',
    '7',
    '6.5',
    '6',
    '5.5',
    '5',
    '4.5',
    '4',
    '3.5',
    '3',
    '2.5',
    '2',
    '1.5',
    'Total 1.5 ≥',
    'Total',
  ];
  public One = [
    '10 PRI',
    '10',
    '9.5',
    '9',
    '8.5',
    '8',
    '7.5',
    '7',
    '6.5',
    '6',
    '5.5',
    '5',
    '4.5',
    '4',
    '3.5',
    '3',
    '2.5',
    '2',
    '1.5',
    '1',
    'Total 1 ≥',
    'Total',
  ];

  public AllWithSumold = [
    'Total 10 PRI',
    'Total 10 Gem/Pri',
    'Total 9.5 ≥',
    'Total 9 ≥',
    'Total 8.5 ≥',
    'Total 8 ≥',
    'Total 7.5 ≥',
    'Total 7 ≥',
    'Total 6.5 ≥',
    'Total 6 ≥',
    'Total 5.5 ≥',
    'Total 5 ≥',
    'Total 4.5 ≥',
    'Total 4 ≥',
    'Total 3.5 ≥',
    'Total 3 ≥',
    'Total 2.5 ≥',
    'Total 2 ≥',
    'Total 1.5 ≥',
    'Total 1 ≥',
    'Total',
    '100/10 PRI',
    '98/10',
    '97/9.5',
    '96/9',
    '92/8.5',
    '88/8',
    '86/7.5',
    '84/7',
    '82/6.5',
    '80/6',
    '70/5.5',
    '60/5',
    '55/4.5',
    '50/4',
    '45/3.5',
    '40/3',
    '35/2.5',
    '30/2',
    '20/1.5',
    '10/1',
    'A',
  ];
  public Allold = [
    '100/10 PRI',
    '98/10',
    '97/9.5',
    '96/9',
    '92/8.5',
    '88/8',
    '86/7.5',
    '84/7',
    '82/6.5',
    '80/6',
    '70/5.5',
    '60/5',
    '55/4.5',
    '50/4',
    '45/3.5',
    '40/3',
    '35/2.5',
    '30/2',
    '20/1.5',
    '10/1',
    'A',
    'Total',
  ];
  public TenPRIold = ['100/10 PRI', 'Total 10 PRI', 'Total'];
  public Tenold = ['100/10 PRI', '98/10', 'Total 10 Gem/Pri', 'Total'];
  public Ninepfiveold = [
    '100/10 PRI',
    '98/10',
    '97/9.5',
    'Total 9.5 ≥',
    'Total',
  ];
  public Nineold = [
    '100/10 PRI',
    '98/10',
    '97/9.5',
    '96/9',
    'Total 9 ≥',
    'Total',
  ];
  public Eightpfiveold = [
    '100/10 PRI',
    '98/10',
    '97/9.5',
    '96/9',
    '92/8.5',
    'Total 8.5 ≥',
    'Total',
  ];
  public Eightold = [
    '100/10 PRI',
    '98/10',
    '97/9.5',
    '96/9',
    '92/8.5',
    '88/8',
    'Total 8 ≥',
    'Total',
  ];
  public Sevenpfiveold = [
    '100/10 PRI',
    '98/10',
    '97/9.5',
    '96/9',
    '92/8.5',
    '88/8',
    '86/7.5',
    'Total 7.5 ≥',
    'Total',
  ];
  public Sevenold = [
    '100/10 PRI',
    '98/10',
    '97/9.5',
    '96/9',
    '92/8.5',
    '88/8',
    '86/7.5',
    '84/7',
    'Total 7 ≥',
    'Total',
  ];
  public Sixpfiveold = [
    '100/10 PRI',
    '98/10',
    '97/9.5',
    '96/9',
    '92/8.5',
    '88/8',
    '86/7.5',
    '84/7',
    '82/6.5',
    'Total 6.5 ≥',
    'Total',
  ];
  public Sixold = [
    '100/10 PRI',
    '98/10',
    '97/9.5',
    '96/9',
    '92/8.5',
    '88/8',
    '86/7.5',
    '84/7',
    '82/6.5',
    '80/6',
    'Total 6 ≥',
    'Total',
  ];
  public Fivepfiveold = [
    '100/10 PRI',
    '98/10',
    '97/9.5',
    '96/9',
    '92/8.5',
    '88/8',
    '86/7.5',
    '84/7',
    '82/6.5',
    '80/6',
    '70/5.5',
    'Total 5.5 ≥',
    'Total',
  ];
  public Fiveold = [
    '100/10 PRI',
    '98/10',
    '97/9.5',
    '96/9',
    '92/8.5',
    '88/8',
    '86/7.5',
    '84/7',
    '82/6.5',
    '80/6',
    '70/5.5',
    '60/5',
    'Total 5 ≥',
    'Total',
  ];
  public Fourpfiveold = [
    '100/10 PRI',
    '98/10',
    '97/9.5',
    '96/9',
    '92/8.5',
    '88/8',
    '86/7.5',
    '84/7',
    '82/6.5',
    '80/6',
    '70/5.5',
    '60/5',
    '55/4.5',
    'Total 4.5 ≥',
    'Total',
  ];
  public Fourold = [
    '100/10 PRI',
    '98/10',
    '97/9.5',
    '96/9',
    '92/8.5',
    '88/8',
    '86/7.5',
    '84/7',
    '82/6.5',
    '80/6',
    '70/5.5',
    '60/5',
    '55/4.5',
    '50/4',
    'Total 4 ≥',
    'Total',
  ];
  public Threepfiveold = [
    '100/10 PRI',
    '98/10',
    '97/9.5',
    '96/9',
    '92/8.5',
    '88/8',
    '86/7.5',
    '84/7',
    '82/6.5',
    '80/6',
    '70/5.5',
    '60/5',
    '55/4.5',
    '50/4',
    '45/3.5',
    'Total 3.5 ≥',
    'Total',
  ];
  public Threeold = [
    '100/10 PRI',
    '98/10',
    '97/9.5',
    '96/9',
    '92/8.5',
    '88/8',
    '86/7.5',
    '84/7',
    '82/6.5',
    '80/6',
    '70/5.5',
    '60/5',
    '55/4.5',
    '50/4',
    '45/3.5',
    '40/3',
    'Total 3 ≥',
    'Total',
  ];
  public Twopfiveold = [
    '100/10 PRI',
    '98/10',
    '97/9.5',
    '96/9',
    '92/8.5',
    '88/8',
    '86/7.5',
    '84/7',
    '82/6.5',
    '80/6',
    '70/5.5',
    '60/5',
    '55/4.5',
    '50/4',
    '45/3.5',
    '40/3',
    '35/2.5',
    'Total 2.5 ≥',
    'Total',
  ];
  public Twoold = [
    '100/10 PRI',
    '98/10',
    '97/9.5',
    '96/9',
    '92/8.5',
    '88/8',
    '86/7.5',
    '84/7',
    '82/6.5',
    '80/6',
    '70/5.5',
    '60/5',
    '55/4.5',
    '50/4',
    '45/3.5',
    '40/3',
    '35/2.5',
    '30/2',
    'Total 2 ≥',
    'Total',
  ];
  public Onepfiveold = [
    '100/10 PRI',
    '98/10',
    '97/9.5',
    '96/9',
    '92/8.5',
    '88/8',
    '86/7.5',
    '84/7',
    '82/6.5',
    '80/6',
    '70/5.5',
    '60/5',
    '55/4.5',
    '50/4',
    '45/3.5',
    '40/3',
    '35/2.5',
    '30/2',
    '20/1.5',
    'Total 1.5 ≥',
    'Total',
  ];
  public Oneold = [
    '100/10 PRI',
    '98/10',
    '97/9.5',
    '96/9',
    '92/8.5',
    '88/8',
    '86/7.5',
    '84/7',
    '82/6.5',
    '80/6',
    '70/5.5',
    '60/5',
    '55/4.5',
    '50/4',
    '45/3.5',
    '40/3',
    '35/2.5',
    '30/2',
    '20/1.5',
    '10/1',
    'Total 1 ≥',
    'Total',
  ];

  contextMenuClick(args: MenuEventArgs): void {
    if (args.item.id === 'copywithheader') {
      this.grid.copy(true);
      let cardno: string =
        this.grid.contextMenuModule.row.cells[0].innerText.toString();
      let subject: string =
        this.grid.contextMenuModule.row.cells[1].innerText.toString();
      let description: string =
        this.grid.contextMenuModule.row.cells[2].innerText.toString();

      if (cardno == '') {
        cardno = 'N/A';
      }
      if (subject == '') {
        subject = 'N/A';
      }
      if (description == '') {
        description = 'N/A';
      }
      this.page = 'popreport';
      let cardset = this.cardSet;
      this.router.navigate([
        'pop-report/message/',
        cardno,
        this.cardSet,
        subject,
        description,
        this.sportcard,
        this.page,
      ]);
    }
  }

  ngOnInit() {
    this.cardSet = this._routeParams.snapshot.params['cardSet'];
    this.sportcard = this._routeParams.snapshot.params['sport'];
    this.getPopReport(this.cardSet);
    var id = this.cardSet;
    var t205 = id.substr(id.length - 6);
    var t2051 = id.substr(id.length - 4);
    let cardYear = id.substr(0, 4);
    if ( (t205 == '(T205)' || t2051 == 'T205') && (cardYear == '1909' || cardYear == '1910' || cardYear == '1911')) {
      this.showt205 = true;
    } else if ((t205 == '(T206)' || t2051 == 'T206') && (cardYear == '1909' || cardYear == '1910' || cardYear == '1911')) {
      this.showt206 = true;
    }
  }

  getPopReport(cardSet: any) {
    this.isSearching = true;
    this.popReportsService
      .GetallPopReport(this.cardSet, this.sportcard)
      .subscribe((response) => {
        this.AllPopulationReport = response;
        console.log('dataset', this.AllPopulationReport);
        this.options = { pageSize: 400 };
        this.filterOptions = {
          type: 'Menu',
          operators: {
            stringOperator: [
              { value: 'contains', text: 'Contains' },
              { value: 'equal', text: 'Equal' },
            ],
            numberOperator: [],
          },
        };
        this.wrapSettings = { wrapMode: 'Both' };
        this.isSearching = false;
        this.searchSettings = { fields: ['cardSubject', 'cardDescription', 'cardSet', 'cardNumber'] };
      });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  refreshgrid() {
    this.SelectGrades.setValue('All');
    this.isSearching = true;
    this.popReportsService
      .GetallPopReport(this.cardSet, this.sportcard)
      .subscribe((response) => {
        this.AllPopulationReport = response;
        this.filterOptions = { type: 'Menu' };
        this.filter = { type: 'CheckBox' };
        this.wrapSettings = { wrapMode: 'Content' };
        this.isSearching = false;
      });
  }

  changeGrades(event: any) {
    this.isSearching = true;
    this.grid.hideColumns(this.AllWithSum);
    this.grid.hideColumns(this.AllWithSumold);
    const selectedValue = event.target.value;
    if (selectedValue == 'All')
      if (this.oldSgcGrades.value == 'Yes') {
        this.grid.showColumns(this.Allold);
      } else {
        this.grid.showColumns(this.All);
      }
    else if (selectedValue == '10 Pristine only')
      if (this.oldSgcGrades.value == 'Yes') {
        this.grid.showColumns(this.TenPRIold);
      } else {
        this.grid.showColumns(this.TenPRI);
      }
    else if (selectedValue == '10 Gem or 10 Pristine')
      if (this.oldSgcGrades.value == 'Yes') {
        this.grid.showColumns(this.Tenold);
      } else {
        this.grid.showColumns(this.Ten);
      }
    else if (selectedValue == '9.5 MT+ or better')
      if (this.oldSgcGrades.value == 'Yes') {
        this.grid.showColumns(this.Ninepfiveold);
      } else {
        this.grid.showColumns(this.Ninepfive);
      }
    else if (selectedValue == '9 MT or better')
      if (this.oldSgcGrades.value == 'Yes') {
        this.grid.showColumns(this.Nineold);
      } else {
        this.grid.showColumns(this.Nine);
      }
    else if (selectedValue == '8.5 NM MT+ or better')
      if (this.oldSgcGrades.value == 'Yes') {
        this.grid.showColumns(this.Eightpfiveold);
      } else {
        this.grid.showColumns(this.Eightpfive);
      }
    else if (selectedValue == '8 NM MT or better')
      if (this.oldSgcGrades.value == 'Yes') {
        this.grid.showColumns(this.Eightold);
      } else {
        this.grid.showColumns(this.Eight);
      }
    else if (selectedValue == '7.5 NM+ or better')
      if (this.oldSgcGrades.value == 'Yes') {
        this.grid.showColumns(this.Sevenpfiveold);
      } else {
        this.grid.showColumns(this.Sevenpfive);
      }
    else if (selectedValue == '7 NM or better')
      if (this.oldSgcGrades.value == 'Yes') {
        this.grid.showColumns(this.Sevenold);
      } else {
        this.grid.showColumns(this.Seven);
      }
    else if (selectedValue == '6.5 EX NM+ or better')
      if (this.oldSgcGrades.value == 'Yes') {
        this.grid.showColumns(this.Sixpfiveold);
      } else {
        this.grid.showColumns(this.Sixpfive);
      }
    else if (selectedValue == '6 EX NM or better')
      if (this.oldSgcGrades.value == 'Yes') {
        this.grid.showColumns(this.Sixold);
      } else {
        this.grid.showColumns(this.Six);
      }
    else if (selectedValue == '5.5 EX+ or better')
      if (this.oldSgcGrades.value == 'Yes') {
        this.grid.showColumns(this.Fivepfiveold);
      } else {
        this.grid.showColumns(this.Fivepfive);
      }
    else if (selectedValue == '5 EX or better')
      if (this.oldSgcGrades.value == 'Yes') {
        this.grid.showColumns(this.Fiveold);
      } else {
        this.grid.showColumns(this.Five);
      }
    else if (selectedValue == '4.5 VG EX+ or better')
      if (this.oldSgcGrades.value == 'Yes') {
        this.grid.showColumns(this.Fourpfiveold);
      } else {
        this.grid.showColumns(this.Fourpfive);
      }
    else if (selectedValue == '4 VG EX or better')
      if (this.oldSgcGrades.value == 'Yes') {
        this.grid.showColumns(this.Fourold);
      } else {
        this.grid.showColumns(this.Four);
      }
    else if (selectedValue == '3.5 VG+ or better')
      if (this.oldSgcGrades.value == 'Yes') {
        this.grid.showColumns(this.Threepfiveold);
      } else {
        this.grid.showColumns(this.Threepfive);
      }
    else if (selectedValue == '3 VG or better')
      if (this.oldSgcGrades.value == 'Yes') {
        this.grid.showColumns(this.Threeold);
      } else {
        this.grid.showColumns(this.Three);
      }
    else if (selectedValue == '2.5 Good+ or better')
      if (this.oldSgcGrades.value == 'Yes') {
        this.grid.showColumns(this.Twopfiveold);
      } else {
        this.grid.showColumns(this.Twopfive);
      }
    else if (selectedValue == '2 Good or better')
      if (this.oldSgcGrades.value == 'Yes') {
        this.grid.showColumns(this.Twoold);
      } else {
        this.grid.showColumns(this.Two);
      }
    else if (selectedValue == '1.5 Fair or better')
      if (this.oldSgcGrades.value == 'Yes') {
        this.grid.showColumns(this.Onepfiveold);
      } else {
        this.grid.showColumns(this.Onepfive);
      }
    else if (selectedValue == '1 Poor or better')
      if (this.oldSgcGrades.value == 'Yes') {
        this.grid.showColumns(this.Oneold);
      } else {
        this.grid.showColumns(this.One);
      }
    else if (selectedValue == 'A')
      if (this.oldSgcGrades.value == 'Yes') {
        this.grid.showColumns(this.Allold);
      } else {
        this.grid.showColumns(this.All);
      }

    this.isSearching = false;
  }

  GradingOrder10(event: any) {
    this.SelectGrades.setValue('All');
  }

  GradingOrderA(event: any) {
    this.SelectGrades.setValue('All');
  }

  oldSgcGradesNo(event: any) {
    this.SelectGrades.setValue('All');
  }

  oldSgcGradesYes(event: any) {
    this.SelectGrades.setValue('All');
  }

  reportanissue(cardNumber: any, cardSubject: any, cardDescription: any) {
    this.router.navigate([
      'pop-report/message/',
      cardNumber,
      this.cardSet,
      cardSubject,
      cardDescription,
      this.sportcard,
    ]);
  }

  search()
  {
    this.grid.search(this.searchText);
  }

  searchEnterKey(event: any)
  {
    if (event.keyCode == 13) {
      this.search();
    }
  }

  clearSearch()
  {
    this.searchText = '';
    this.grid.search(this.searchText);
  }
}
