import { Component, OnInit } from '@angular/core';
import { OrderService } from '../../services/order.service';
import { CheckoutService } from '../../../checkout/services/checkout.service';
import {
  loadStripe,
  PaymentIntent,
  Stripe,
  StripeElement,
  StripeElements,
  StripePaymentElement,
} from '@stripe/stripe-js';
import { Order, OrderType, OrderItem } from '../../models/order';
import { Router } from '@angular/router';
import { User } from '../../../common/models/user';
import { ActivatedRoute } from '@angular/router';
import { StateService } from '../../../common/services/state.service';
import { NEVER as never } from 'rxjs';

@Component({
  selector: 'stripe',
  templateUrl: './stripe.component.html',
  styleUrls: ['./stripe.component.css'],
})
export class StripeComponent implements OnInit {
  constructor(
    private orderService: OrderService,
    private checkoutService: CheckoutService,
    private route: ActivatedRoute,
    private router: Router,
    private stateService: StateService
  ) {}

  order = new Order();
  currentUser = new User();
  isLoading: boolean = false;
  paymentType: any;
  saveCard: boolean = false;
  orderId: any;
  showCards: boolean = true;
  stripe: any;
  elements: any;
  stripeCustomerID?: string;
  customerPaymentIntent: any;

  ngOnInit() {
    var _this = this;
    this.currentUser = this.stateService.currentUser.get();

    this.orderId = this.getOrderId();
    if (!this.orderId) return;

    this.checkoutService.getOrder(_this.orderId).subscribe((order) => {
      this.order = order;
      this.checkoutService.stripePaymentStep(order, 5);

      this.loadStripe();
      this.saveCard = false;
      this.getCustomerSaveCards();
    });

    //payment form submit call
    var form = document.getElementById('payment-form');
    form?.addEventListener('submit', function (e) {
      e.preventDefault();
      _this.isLoading = false;
      const setupFutureUsage = _this.saveCard ? 'off_session' : 'null';
      _this.orderService
        .updateStripeCustomer(
          _this.orderId,
          _this.paymentType,
          _this.customerPaymentIntent.id,
          setupFutureUsage,
          _this.stripeCustomerID!
        )
        .subscribe((res) => {
          _this.stripe
            .confirmPayment({
              elements: _this.elements,
              redirect: 'if_required',
            })
            .then((result: { paymentIntent: { client_secret: string } }) => {
              if (result.paymentIntent) {
                _this.processPayment(
                  result.paymentIntent,
                  result.paymentIntent.client_secret
                );
              } else {
                _this.navigateToStripeError(_this.orderId);
              }
            });
        });
    });
  }

  createStripePayment() {
    
    this.isLoading = false;
    var elements = this.stripe.elements({
      clientSecret: this.customerPaymentIntent.clientSecret,
    });
    var paymentElement = elements.create('payment', {
      wallets: { googlePay: 'never', applePay: 'never' },
    });
    paymentElement.mount('#payment-element');

    //google pay btn
    const paymentRequest = this.stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: {
        label: 'Amount',
        amount: this.order.fee.total * 100,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });

    const prButton = elements.create('paymentRequestButton', {
      paymentRequest: paymentRequest,
    });
    paymentRequest
      .canMakePayment()
      .then(() => {
        prButton.mount('#payment-request-button');
      })
      .catch((e: any) => console.log(e));

    paymentRequest.on(
      'paymentmethod',
      async (ev: { paymentMethod: { id: any } }) => {
        var pRequest = await this.stripe.confirmCardPayment(
          this.customerPaymentIntent.clientSecret,
          { payment_method: ev.paymentMethod.id },
          { handleActions: false }
        );

        if (pRequest.paymentIntent) {
          this.processPayment(
            pRequest.paymentIntent,
            this.customerPaymentIntent.clientSecret
          );
        } else {
          this.navigateToStripeError(this.orderId);
        }
      }
    );

    // change payment elements
    this.isLoading = true;
    paymentElement.on(
      'change',
      (event: { value: { type: any }; complete: any }) => {
        this.paymentType = event.value.type;
        const saveCardChkbox = document.getElementById('saveCardChkbox');
        const submitButton = document.getElementById('submit');

        if (this.paymentType === 'card') {
          if (saveCardChkbox) {
            saveCardChkbox.style.display = 'block';
          }
          if (submitButton) {
            submitButton.style.display = 'block';
          }
        } else {
          if (saveCardChkbox) {
            saveCardChkbox.style.display = 'none';
          }
          if (submitButton) {
            submitButton.style.display = 'none';
          }
        }

        if (event.complete && submitButton) {
          submitButton.style.display = 'block';
        } else if (submitButton) {
          submitButton.style.display = 'none';
        }
      }
    );

    this.elements = elements;
  }

  getCustomerSaveCards() {
    this.orderService
      .getCustomerSaveCards(
        this.orderId,
        this.order.fee.total,
        this.currentUser,
        this.order.publicId!
      )
      .subscribe((res) => {
        if (res.success) {
          this.stripeCustomerID = res.stripeCustomerID;
          this.customerPaymentIntent = res.paymentIntent;
          if (res.cards && res.cards.length > 0) {
            this.renderTable(res.cards);
            const savedCardsElement = document.getElementById('savedCards');
            const paymentFormElement = document.getElementById('payment-form');

            if (savedCardsElement) {
              savedCardsElement.style.visibility = 'visible';
            }
            if (paymentFormElement) {
              paymentFormElement.style.display = 'none';
            }

            this.isLoading = true;
          } else {
            const savedCardsElement = document.getElementById('savedCards');
            const paymentFormElement = document.getElementById('payment-form');

            if (savedCardsElement) {
              savedCardsElement.style.display = 'none';
            }
            if (paymentFormElement) {
              paymentFormElement.style.display = 'block';
            }

            this.createStripePayment();
          }
        } else {
          this.navigateToStripeError(this.orderId);
        }
      });
  }

  paymentWithSavedCard() {
    this.isLoading = false;
    var paymentMethodId = document.querySelector(
      'input[name="card"]:checked'
    )?.id;
    var paymentMethodType = 'card';
    this.orderService
      .paymentWithSavedCard(
        this.customerPaymentIntent.id,
        this.currentUser,
        this.orderId,
        this.order.fee.total,
        paymentMethodId!,
        paymentMethodType,
        this.stripeCustomerID!
      )
      .subscribe((res) => {
        if (res.success) {
          const _this = this;
          const clientSecret = res.paymentIntent.clientSecret;
          this.stripe
            .confirmCardPayment(clientSecret, {
              payment_method: paymentMethodId,
            })
            .then(function (result: { paymentIntent: any }) {
              if (result.paymentIntent) {
                _this.processPayment(result.paymentIntent, clientSecret);
              } else {
                _this.navigateToStripeError(_this.orderId);
              }
            });
        } else {
          this.navigateToStripeError(this.orderId);
        }
      });
  }

  saveCardDetails(event: { target: { checked: boolean } }) {
    this.saveCard = event.target.checked;
  }

  getOrderId() {
    if (!this.route.snapshot.params) return null;
    return this.route.snapshot.params['id'];
  }

  loadStripe() {
    this.orderService.getStripePublishableKey().subscribe((response) => {
      loadStripe(response.publishableKey).then((stripe: any) => {
        this.stripe = stripe;
      });
    });
  }

  createNewPayment() {
    
    const savedCardsElement = document.getElementById('savedCards');
    const paymentFormElement = document.getElementById('payment-form');

    if (savedCardsElement) {
      savedCardsElement.style.display = 'none';
    }

    if (paymentFormElement) {
      paymentFormElement.style.display = 'block';
    }

    this.createStripePayment();
  }

  processPayment(paymentIntent: any, clientSecret: string) {
    if (
      paymentIntent.status == 'succeeded' ||
      paymentIntent.status == 'processing'
    ) {
      this.navigateToStripeSuccess(paymentIntent.status);
    } else if (paymentIntent.status == 'requires_action') {
      var _this = this;
      this.stripe
        .confirmCardPayment(clientSecret)
        .then(function (data: { paymentIntent: { status: string } }) {
          if (data.paymentIntent) {
            _this.navigateToStripeSuccess(data.paymentIntent.status);
          } else {
            _this.navigateToStripeError(_this.orderId);
          }
        });
    } else {
      this.navigateToStripeError(this.orderId);
    }
  }

  navigateToStripeError(orderId: string) {
    this.isLoading = true;
    this.router.navigate([`/orders/${orderId}/stripe-error`]);
  }

  navigateToStripeSuccess(status: string) {
    this.isLoading = true;
    this.checkoutService.processStripePayment(status);
  }

  renderTable = (data: any[]) => {
    let html = '';
    let i = 0;
    data.forEach((x: { id: any; card: { brand: any; last4: any } }) => {
      let id = x.id;
      let { brand, last4 } = x.card;

      if (i == 0) {
        html += `<tr style="font-size:2rem;">
                        <td>
                            <input type="radio" id=${id} name="card" class="card" value=${id} checked="checked">
                        </td>
                        <td>${brand}</td>
                        <td>**** **** **** ${last4}</td>
                    </tr>                       
                    `;
      } else {
        html += `<tr style="font-size:2rem;">
                        <td>
                            <input type="radio" id=${id} name="card" class="card" value=${id}>
                        </td>
                        <td>${brand}</td>
                        <td>**** **** **** ${last4}</td>
                    </tr>                       
                    `;
      }
      i = i + 1;
    });
    const tcardsElement = document.getElementById('tcards');
    if (tcardsElement) {
      tcardsElement.innerHTML = html;
    }
  };
}
