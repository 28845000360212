<div class="checkout__content">
    <h2>STEP 1. SELECT ORDER TYPE</h2>
    <!-- <h3>Select Expertise</h3> -->
    <div class="checkout__grid-items" style="grid-template-columns:repeat(auto-fit, 320px);grid-gap: 1px; background-color: #f3f3f3f5;">
        <div *ngFor="let orderType of orderTypes" class="item-new"  [class.item--selected]="isSelected(orderType)"
             (click)="select(orderType)">
            <!-- <img src="/assets/img/icon-{{orderType.getCode()}}.svg" alt="">
    <h3>{{orderType.getName()}}</h3> -->

            <h3 style="text-align: center;" [innerHTML]="orderType.getOrderingStep1Name()"></h3>
            <!-- <h3 style="line-height:15rem; padding-top: 33px !important;" *ngIf="orderType.getCode() == 'card-grading'">{{orderType.getOrderingStep1Name()}}</h3>
            <h3 style="line-height:10rem; padding-top: 33px !important;" *ngIf="orderType.getCode() == 'card-pre-grade'">{{orderType.getOrderingStep1Name()}}</h3>
            <h3 class="pokemon" style="line-height:15rem; padding-top: 33px !important;" *ngIf="orderType.getCode() == '22POKEMON'"><span>{{orderType.getOrderingStep1Name()}}</span></h3>
            <h3 style="line-height:5rem; padding-top: 50px !important;" *ngIf="orderType.getCode() == '22BOWMANCHR'"><span>TOPPS X SGC</span><br><span>2022 Bowman Chrome Special</span></h3>
            <h3 style="line-height:5rem; padding-top: 20px !important; padding-bottom: 20px !important; " *ngIf="orderType.getCode() == '23BOWMAN'" [innerHTML]="orderType.getOrderingStep1Name()"></h3>
            <h3 style="line-height:5rem; padding-top: 20px; padding-bottom: 20px;" *ngIf="orderType.getCode() == '23TOPPSS2'" [innerHTML]="orderType.getOrderingStep1Name()"></h3>
            <h3 style="line-height:10rem; padding-top: 50px !important; padding-bottom: 50px !important;" *ngIf="orderType.getCode() == 'FOOTBALL2023'"><span>{{orderType.getOrderingStep1Name()}}</span></h3>
            <h3 style="line-height:10rem; padding-top: 50px !important; padding-bottom: 50px !important;" *ngIf="orderType.getCode() == 'GRNOTES'">{{orderType.getOrderingStep1Name()}}</h3>

            <h3 style="line-height:5rem; padding-top: 50px !important; padding-bottom: 50px !important;" *ngIf="orderType.getCode() == '23TOPPSCHR'" [innerHTML]="orderType.getOrderingStep1Name()"></h3>
            <h3 style="line-height:5rem; padding-top: 80px !important; padding-bottom: 50px !important;" *ngIf="orderType.getCode() == '23NSCCONSITE'">{{orderType.getOrderingStep1Name()}}</h3>
            <h3 style="line-height:5rem; padding-top: 80px !important; padding-bottom: 50px !important;" *ngIf="orderType.getCode() == '23NSCCTAKEBACK'">{{orderType.getOrderingStep1Name()}}</h3>
            <h3 style="line-height:5rem; padding-top: 80px !important; padding-bottom: 50px !important;" *ngIf="orderType.getCode() == '23BOWMANEOY'">TOPPS X SGC <br/> '22 & '23 BOWMAN SPECIAL</h3> -->
            <!-- <ul></ul> -->

        </div>

        <!-- <div (click)="cancelOrder()">
            <a href="https://sgcuniforms.typeform.com/to/LhZMvZ" target="_blank">
                <div class="item">
                    <img src="/assets/img/icon-uniform-authentication.svg" alt="">
                    <h3>Uniform Authentication</h3>
                    <ul>
                    </ul>
                </div>
            </a>
        </div> -->
    </div>
</div>

<style>
    @media(max-width:1003px){
        .checkout__content{
            padding-bottom: 100px;
        }
        /* .pokemon{
            padding-bottom: 30px;
        } */
    }
    @media only screen and (max-height: 420px) {
.checkout__content {
padding-bottom: 80px !important;
}
}
</style>
