import { Injectable } from '@angular/core';
import { StateService } from '../../common/services/state.service';
import {
  Order,
  AuthCodeCollections,
  AdminKPIExecutive,
  OrderType,
  OrderItem,
  MyCardOrderItems,
  PayPalOrderForCustomer,
  ServiceTier,
  CardOrderItem,
  SignableObject,
  SignableObjectType,
  AutographOrderItem,
  ShippingMethod,
  SubmittedOrderForCustomer,
  DraftOrderForCustomer,
  CompletedOrderForCustomer,
  DeletedUnDeletedOrderForCustomer,
} from '../models/order';
import * as _ from 'lodash';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { ApiService } from '../../common/services/api.service';
import { Address, User } from '../../common/models/user';

import { ErrorService } from '../../common/services/error.service';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class OrderService {
  constructor(
    private state: StateService,
    private apiService: ApiService,
    private errorService: ErrorService,
    private router: Router,
    private http: HttpClient
  ) {}

  //private isPaymentContactSource = new BehaviorSubject<boolean>(true);
  //isPaymentContact$ = this.isPaymentContactSource.asObservable();
  //isPaymentContactInfo$(isPaymentContact) {
  //    this.isPaymentContactSource.next(isPaymentContact);
  //}

  // New stuff
  private cacheSignableObjects: Array<any> | undefined;

  createOrder(): Observable<Order> {
    return this.apiService.post('/orders', {}).pipe(
      map((data: any) => new Order(data)),
      catchError((error: any) =>
        this.errorService.handleAsDefaultErrorOrderSubmit(error)
      )
    );
  }

  getOrderForStripePaymentReconciliation(orderId: string): Observable<Order> {
    return this.apiService.get(`/orders/${orderId}/getOrderForStripePaymentReconciliation`)
    .pipe(
      map((data: any) => new Order(data)),
      catchError((error: any) =>
        this.errorService.handleAsDefaultErrorOrderSubmit(error)
      )
    );
}

  checkBusinessDayGur(): Observable<AdminKPIExecutive> {
    //return this.apiService.get(`/admin-executive/businessday-gurantee`).map(data => {
    //    return new AdminKPIExecutive(data)
    return this.apiService.get(`/admin-executive/businessday-gurantee`).pipe(
      map((data: any) => new AdminKPIExecutive(data)),
      catchError((error: any) =>
        this.errorService.handleAsDefaultErrorOrderSubmit(error)
      )
    );
  }

  getOrder(orderId: string): Observable<Order> {
    return this.apiService.get(`/orders/${orderId}`).pipe(
      map((data: any) => new Order(data)),
      catchError((error: any) =>
        this.errorService.handleAsDefaultErrorOrderSubmit(error)
      )
    );
  }

  getOrderTypeByOrderId(orderId: string): Observable<Order> {
    return this.apiService.get(`/orders/${orderId}/orderType`).pipe(
      map((data: any) => new Order(data)),
      catchError((error: any) =>
        this.errorService.handleAsDefaultErrorOrderSubmit(error)
      )
    );
  }

  getOrderWithPlans(orderId: string): Observable<Order> {
    return this.apiService.get(`/orders/${orderId}/orderPlans`).pipe(
      map((data: any) => new Order(data)),
      catchError((error: any) =>
        this.errorService.handleAsDefaultErrorOrderSubmit(error)
      )
    );
  }

  getOrderWithShipping(orderId: string): Observable<Order> {
    return this.apiService.get(`/orders/${orderId}/orderShipping`).pipe(
      map((data: any) => new Order(data)),
      catchError((error: any) =>
        this.errorService.handleAsDefaultErrorOrderSubmit(error)
      )
    );
  }

  getOrderTypes(): Observable<OrderType[]> {
    return this.apiService.get(`/order-types`).pipe(
      map((data: { items: any[] }) =>
        data.items.map((item) => new OrderType(item))
      ),
      catchError((error: any) =>
        this.errorService.handleAsDefaultErrorOrderSubmit(error)
      )
    );
  }


  googleAndApplePayPaymentReconciliation(orderId: string, paymentIntentId: string, amount: number): Observable<any> {
    return this.apiService.post(`/payments/${orderId}/googleAndApplePayPaymentReconciliation`, { PaymentIntentId: paymentIntentId, Amount: amount })
        .pipe(
            catchError((error: any) => {
                // Handle errors here
                return this.errorService.handleAsDefaultErrorOrderSubmit(error);
            })
        );
}

  setOrderType(order: Order): Observable<Order> {
    const orderId = order.getId();
    const orderType = order.getOrderType();
    return this.apiService
      .put(`/orders/${orderId}/type`, { code: orderType?.getCode() })
      .pipe(
        map((data: any) => new Order(data)),
        catchError((error: any) =>
          this.errorService.handleAsDefaultErrorOrderSubmit(error)
        )
      );
  }
  addOrderItem(item: any, order: Order): Observable<OrderItem> {
    const orderId = order.getId();
    const orderType = order.getOrderType();
    const body = this.getOrderItemForCreateRequest(item, orderType!);

    return this.apiService.post(`/orders/${orderId}/items`, body).pipe(
      map((data: any) => this.instantiateOrderItem(data)),
      catchError((error: any) =>
        this.errorService.handleAsDefaultErrorOrderSubmit(error)
      )
    );
  }
  getOrderItemForCreateRequest(configuredItem: any, orderType: OrderType): any {
    var body: any = {
        item: {},
        settings: {},
        AdminSide: false
    }

    if ((orderType.isCardGrading() || orderType.isRawCardReview() || orderType.isBowmanChrome() || orderType.isGRNOTES() || orderType.isPokemanspecials() || orderType.is23Bowman() || orderType.is23BOWMANEOY()
        || orderType.isToppsSeries2() || orderType.isFootBall() || orderType.is23TOPPSCHR() || orderType.is23NSCCONSITE() || orderType.is23NSCCTAKEBACK() || orderType.is24BASKETBALL() || orderType.is24TOPPSS2())) {
        body.item.id = configuredItem.item.id;
        body.item.kind = 'card';
        if (configuredItem.data) body.data = configuredItem.data;
        if (configuredItem.item.name) body.item.name = configuredItem.item.name;
    }

    if (orderType.isAutographAuthentication()) {
        body.item.kind = 'autograph';
        configuredItem.item.signers.length && (body.item.subjectIds = _.map(configuredItem.item.signers, signer => signer.id));
        body.item.signedObjectId = configuredItem.item.signedObject.id;
        body.settings.stickerOnItem = configuredItem.settings.premiumCertificate ? configuredItem.settings.stickerOnItem : null;
        body.settings.autographGrading = configuredItem.settings.autographGrading;
        body.settings.cardGrading = configuredItem.settings.cardGrading;
        body.settings.encapsulationRequested = configuredItem.settings.encapsulation;
        body.settings.premiumCertificateRequested = configuredItem.settings.premiumCertificate;
        configuredItem.item.card && (body.item.cardId = configuredItem.item.card.id);
    }
    configuredItem.settings.declaredValue && (body.settings.declaredValue = configuredItem.settings.declaredValue);

    return body;
}

  getOrderItemForUpdateRequest(orderItem: OrderItem): any {
    var body: any = {
      id: orderItem.id,
      item: {},
      settings: {},
    };

    body.settings = orderItem.getSettings();

    switch (orderItem.kind) {
      case 'card':
        var cardItem = orderItem as CardOrderItem;
        body.item.cardId = cardItem.getCardId();
        if (orderItem.data) body.data = orderItem.data;
        if (cardItem.name) body.item.name = cardItem.name;
        break;
      case 'autograph':
        var autographItem = orderItem as AutographOrderItem;
        body.item.signedObjectId = autographItem.getSignableObjectId();
        body.item.subjectIds = autographItem.getSubjectIds();
        break;
    }

    body.item.line1 = orderItem.line1;
    body.item.line2 = orderItem.line2;
    body.item.line3 = orderItem.line3;
    body.item.line4 = orderItem.line4;

    return body;
  }

  removeOrderItem(orderItem: OrderItem): Observable<any> {
    const orderItemId = orderItem.getId();

    return this.apiService
      .delete(`/order-items/${orderItemId}`)
      .pipe(
        catchError((error: any) =>
          this.errorService.handleAsDefaultErrorOrderSubmit(error)
        )
      );
  }

  updateOrderItemForNewPlan(orderItem: OrderItem): Observable<OrderItem> {
    const orderItemId = orderItem.getId();
    const request = this.getOrderItemForUpdateRequest(orderItem);

    return this.apiService
      .patch(`/order-items/${orderItemId}/UpdateOrderItem`, request)
      .pipe(
        map((data: any) => this.instantiateOrderItem(data)),
        catchError((error: any) =>
          this.errorService.handleAsDefaultErrorOrderSubmit(error)
        )
      );
  }

  updateOrderItem(orderItem: OrderItem): Observable<OrderItem> {
    const orderItemId = orderItem.getId();
    const request = this.getOrderItemForUpdateRequest(orderItem);

    return this.apiService.patch(`/order-items/${orderItemId}`, request).pipe(
      map((data: any) => this.instantiateOrderItem(data)),
      catchError((error: any) =>
        this.errorService.handleAsDefaultErrorOrderSubmit(error)
      )
    );
  }

  instantiateOrderItem(item: any) {
    switch (item.kind) {
      case 'card':
        return new CardOrderItem(item);
      case 'autograph':
        return new AutographOrderItem(item);
      default:
        return new OrderItem(item);
    }
  }

  getSignableObjects(): Observable<SignableObject[]> {
    if (this.cacheSignableObjects) {
      return of(this.cacheSignableObjects);
    }

    return this.apiService.get(`/signable-objects`).pipe(
      map((data: { items: any }) => {
        const result = _.map(data.items, (item) => new SignableObject(item));
        this.cacheSignableObjects = result;
        return result;
      }),
      catchError((error: any) =>
        this.errorService.handleAsDefaultErrorOrderSubmit(error)
      )
    );
  }

  getAvailableAutographService(configuredItem: any): Observable<any[]> {
    const body: any = {
      item: {
        kind: 'autograph',
        signedObjectId: configuredItem.item.signedObject.id,
      },
      settings: {
        declaredValue: configuredItem.settings.declaredValue || null,
      },
    };

    if (configuredItem.item.signers.length) {
      body.item.subjectIds = configuredItem.item.signers.map(
        (signer: any) => signer.id
      );
    }

    return this.apiService.post(`/autograph-service-types`, body).pipe(
      map((data: { items: any }) => data.items),
      catchError((error: any) =>
        this.errorService.handleAsDefaultErrorOrderSubmit(error)
      )
    );
  }

  getAvailableGradingOptions(configuredItem: any): Observable<any[]> {
    const body: any = {
      item: {
        kind: 'autograph',
        signedObjectId: configuredItem.item.signedObject.id,
      },
    };

    if (configuredItem.item.signers.length) {
      body.item.subjectIds = configuredItem.item.signers.map(
        (signer: any) => signer.id
      );
    }

    return this.apiService.post(`/autograph-grading-options`, body).pipe(
      map((data: { items: any }) => data.items),
      catchError((error: any) =>
        this.errorService.handleAsDefaultErrorOrderSubmit(error)
      )
    );
  }

  getOrderPlans(order: Order): Observable<any[]> {
    const orderId = order.getId();

    return this.apiService.get(`/orders/${orderId}/plans/available`).pipe(
      map((data: { plans: any }) => data.plans),
      catchError((error: any) =>
        this.errorService.handleAsDefaultErrorOrderSubmit(error)
      )
    );
  }

  setOrderPlan(
    order: Order,
    plan: any,
    plan1st: any,
    plan2nd: any,
    plan3rd: any,
    plan4th: any,
    planCount: any
  ): Observable<any> {
    const orderId = order.getId();
    const planId = plan.id;
    const planTurnaround = plan.turnaround;

    return this.apiService
      .put(`/orders/${orderId}/plans`, {
        id: planId,
        turnaAround: planTurnaround,
        plan1st,
        plan2nd,
        plan3rd,
        plan4th,
        planCount,
      })
      .pipe(
        catchError((error: any) =>
          this.errorService.handleAsDefaultErrorOrderSubmit(error)
        )
      );
  }

  getAvailableShippingMethods(order: Order): Observable<ShippingMethod[]> {
    const orderId = order.getId();

    return this.apiService
      .get(`/orders/${orderId}/shipping-methods/available`)
      .pipe(
        map((data: { items: any }) =>
          _.map(data.items, (item) => new ShippingMethod(item))
        ),
        catchError((error: any) =>
          this.errorService.handleAsDefaultErrorOrderSubmit(error)
        )
      );
  }

  setShippingMethod(
    order: Order,
    shippingMethod: ShippingMethod
  ): Observable<any> {
    const orderId = order.getId();
    const shippingMethodId = shippingMethod.getId();

    return this.apiService
      .put(`/orders/${orderId}/shipping-method`, { id: shippingMethodId })
      .pipe(
        catchError((error: any) =>
          this.errorService.handleAsDefaultErrorOrderSubmit(error)
        )
      );
  }

  //setShippingMethodForFee(order: Order, shippingMethod: ShippingMethod) {
  //    var orderId = order.getId();
  //    var shippingMethodId = shippingMethod.getId();

  //    return this.apiService.put(`/orders/${orderId}/shipping-method`, { id: shippingMethodId });
  //}

  setShippingAddress(order: Order, address: Address) {
    var orderId = order.getId();
    var addressIds = address.id;
    return this.apiService
      .put(`/orders/${orderId}/shipping-address`, { id: addressIds })
      .pipe(
        catchError((error: any) =>
          this.errorService.handleAsDefaultErrorOrderSubmit(error)
        )
      );
  }

  cancelOrder(order: Order) {
    var orderId = order.getId();

    return this.apiService
      .delete(`/orders/${orderId}`)
      .pipe(
        catchError((error: any) =>
          this.errorService.handleAsDefaultErrorOrderSubmit(error)
        )
      );
  }

  applyPromoCode(
    code: string,
    order: Order,
    shippingMethod: ShippingMethod
  ): Observable<any> {
    var orderId = order.getId();
    //    var shippingMethodId = '54db5c9e-b8c7-4114-a328-1d2b485b43ca'
    var shippingMethodId = shippingMethod.getId();
    return this.apiService
      .post(`/orders/${orderId}/apply-promo`, {
        code: code,
        id: shippingMethodId,
      })
      .pipe(
        map((data: any) => data.fee),
        catchError((error: any) => {
          console.error('Error in applyPromoCode:', error); // Log the error to the console
          return this.errorService.promoCodeError(error);
        })
      );
  }

  createPayment(order: Order): Observable<any> {
    if (
      !order.hasOrderType() ||
      !order.hasOrderItems() ||
      !order.hasShippingMethod()
    ) {
      this.navigateStep(order);
      return of(null); // Return a dummy Observable to keep the return type consistent
    } else {
      return this.apiService
        .post(`/payments/${order.id}/create`, {})
        .pipe(
          catchError((error: any) =>
            this.errorService.handlePaypalError(error, order.id)
          )
        );
    }
  }

  processPayment(order: Order, payment: any): Observable<any> {
    if (
      !order.hasOrderType() ||
      !order.hasOrderItems() ||
      !order.hasShippingMethod()
    ) {
      this.navigateStep(order);
      return of(null);
    } else {
      return this.apiService
        .post(`/payments/${order.id}/process`, {
          PayerId: payment.payerID,
          PaymentId: payment.paymentID,
          OrderId: payment.orderID,
        })
        .pipe(
          catchError((error: any) =>
            this.errorService.handlePaypalError(error, order.id)
          )
        );
    }
  }

  navigateStep(order: Order) {

    /*  this.router.navigate([`/checkout/${order.id}/expertise`]);*/
    if (!order.hasOrderType()) {
      window.location.href = `/checkout/${order.id}/expertise`;
    } else if (!order.hasOrderItems()) {
      window.location.href = `/checkout/${order.id}/items`;
    } else if (!order.hasShippingMethod()) {
      window.location.href = `/checkout/${order.id}/items`;
    } else {
      window.location.href = `/checkout`;
    }
  }

  updateOrder(order: Order): Observable<Order> {
    var orderId = order.id;
    var request: any = {
      encapsulationRequested: order.settings.encapsulationRequested,
      useFedexAccount: order.settings.useFedexAccount,
    };

    if (order.settings.useFedexAccount)
      request.fedexAccount = order.settings.fedexAccount;

    return this.apiService.patch(`/orders/${orderId}`, request).pipe(
      map((data: any) => new Order(data)),
      catchError((error: any) =>
        this.errorService.handleAsDefaultErrorOrderSubmit(error)
      )
    );
  }

  updateRecalculateOrder(order: Order): Observable<Order> {
    var orderId = order.id;
    var request: any = {
      encapsulationRequested: order.settings.encapsulationRequested,
      useFedexAccount: order.settings.useFedexAccount,
    };

    if (order.settings.useFedexAccount)
      request.fedexAccount = order.settings.fedexAccount;

    return this.apiService
      .patch(`/orders/${orderId}/recalculateOrder`, request)
      .pipe(
        map((data: any) => new Order(data)),
        catchError((error: any) =>
          this.errorService.handleAsDefaultErrorOrderSubmit(error)
        )
      );
  }

  updateOrderForCusFedex(order: Order): Observable<Order> {
    var orderId = order.id;
    var request: any = {
      encapsulationRequested: order.settings.encapsulationRequested,
      useFedexAccount: order.settings.useFedexAccount,
    };

    request.fedexAccount = order.settings.fedexAccount;

    return this.apiService.patch(`/orders/${orderId}`, request).pipe(
      map((data: any) => new Order(data)),
      catchError((error: any) =>
        this.errorService.handleAsDefaultErrorOrderSubmit(error)
      )
    );
  }

  getOrders(limit?: number, offset?: number): Observable<Order[]> {
    const options: { params: { key: string; value: number }[] } = {
      params: [],
    };

    if (limit) options.params.push({ key: 'limit', value: limit });
    if (offset) options.params.push({ key: 'offset', value: offset });

    return this.apiService.get(`/orders`, options).pipe(
      map((data: { items: any }) =>
        _.map(data.items, (item) => new Order(item))
      ),
      catchError((error: any) =>
        this.errorService.handleAsDefaultErrorOrderSubmit(error)
      )
    );
  }

  submitOrder(order: Order): Observable<any> {
    const orderId = order.id;
    var data = JSON.parse(localStorage.getItem('newUserInfo')!);
        var userId = null
        if (data != null) {
            userId = data.newUserId;
        }
    return this.apiService
      .post(`/orders/${orderId}/submit`, { userId })
      .pipe(
        catchError((error: any) =>
          this.errorService.handleAsDefaultErrorOrderSubmit(error)
        )
      );
  }

  submitOrderTerms(
    termsAccept: boolean,
    termsAcceptData: string,
    order: Order
  ): Observable<any> {
    var orderId = order.id;
    return this.apiService
      .post(`/orders/${orderId}/submitOrderTerms`, {
        termsAccept,
        termsAcceptData,
      })
      .pipe(
        catchError((error: any) =>
          this.errorService.handleAsDefaultErrorOrderSubmit(error)
        )
      );
  }

  submitCertCode(
    authCode: any,
    userId: string
  ): Observable<AuthCodeCollections[]> {
    return this.apiService
      .post(`/orders/${authCode}/${userId}/authcode-collections`, {})
      .pipe(
        catchError((error: any) =>
          this.errorService.handleAsDefaultErrorOrderSubmit(error)
        )
      );
  }

  GetCertCode(
    authCode: string,
    userId: string
  ): Observable<AuthCodeCollections[]> {
    if (authCode == '') {
      authCode = 'empty';
    }
    return this.apiService
      .get(`/orders/${authCode}/${userId}/authcode-collections`)
      .pipe(
        catchError((error: any) =>
          this.errorService.handleAsDefaultErrorOrderSubmit(error)
        )
      );
  }

  GetCustomerCardForCustomer(CustomerId: any): Observable<any> {
    return this.apiService
      .get(`/orders/GetMyCardsFromOrderByCustomerIDForWeb/${CustomerId}`)
      .pipe(
        catchError((error: any) =>
          this.errorService.handleAsDefaultErrorOrderSubmit(error)
        )
      );
  }

  GetMyCardsFromOrderByCustomerIDForWeb(CustomerId: any): Observable<any[]> {
    return this.apiService
      .get(`/orders/GetMyCardsFromOrderByCustomerIDForWeb/${CustomerId}`)
      .pipe(
        catchError((error: any) =>
          this.errorService.handleAsDefaultErrorOrderSubmit(error)
        )
      );
  }

  GetUserCardsCollections(CustomerId: string): Observable<any[]> {
    return this.apiService
      .get(`/my-collection/GetUserCardsCollections/${CustomerId}`)
      .pipe(
        catchError((error: any) =>
          this.errorService.handleAsDefaultErrorOrderSubmit(error)
        )
      );
  }
  // DeleteCard(CustomerId,AuthCode): Observable<any[]> {
  //
  //     return this.apiService.put(`/my-collection/RemoveMyCollectionCards/${CustomerId}`,AuthCode)
  //         .catch(error => this.errorService.handleAsDefaultErrorOrderSubmit(error));
  // }

  emoveMyCollectionCards(userId: string, authCode: string): Observable<any> {


    let body = { AuthCode: authCode };
    return this.apiService
      .delete(`/my-collection/RemoveMyCollectionCards/${userId}`)
      .pipe(
        catchError((error: any) =>
          this.errorService.handleAsDefaultErrorOrderSubmit(error)
        )
      );
  }

  removeMyCollectionCards(userId: string, authCode: string): Observable<any> {
    return this.apiService
      .delete(`/my-collection/RemoveMyCollectionCards/${userId}/${authCode}`)
      .pipe(
        catchError((error: any) =>
          this.errorService.handleAsDefaultErrorOrderSubmit(error)
        )
      );
  }

  GetStatusForDownloadCards(CustomerId: string): Observable<any[]> {
    return this.apiService
      .get(`/orders/GetDownloadCardsByUserId/${CustomerId}`)
      .pipe(
        catchError((error: any) =>
          this.errorService.handleAsDefaultErrorOrderSubmit(error)
        )
      );
  }

  downloadMyCards(id: any, customerId: any, ordernumber: any, authCode: any): Observable<any> {
    var request: any = {
      OrderIds: '',
      OrderItemIds: id,
      CreatedBy: customerId,
      DownloadNamingType: 2,
      OrderNumber: ordernumber,
      AuthCode: authCode
    };
    return this.apiService
      .post(`/orders/DownloadSellerReadyImages/`, request)
      .pipe(
        catchError((error: any) =>
          this.errorService.handleAsDefaultErrorOrderSubmit(error)
        )
      );
  }

  GetCompletedOrderForCustomer(
    CustomerId: string
  ): Observable<CompletedOrderForCustomer[]> {
    return this.apiService
      .get(`/pop-report/GetCompletedOrderForCustomer/${CustomerId}`)
      .pipe(
        catchError((error: any) =>
          this.errorService.handleAsDefaultErrorOrderSubmit(error)
        )
      );
  }

  GetSubmittedOrderForCustomer(
    CustomerId: string
  ): Observable<SubmittedOrderForCustomer[]> {
    return this.apiService
      .get(`/pop-report/GetSubmittedOrderForCustomer/${CustomerId}`)
      .pipe(
        catchError((error: any) =>
          this.errorService.handleAsDefaultErrorOrderSubmit(error)
        )
      );
  }

  GetDraftOrderForCustomer(
    CustomerId: string
  ): Observable<DraftOrderForCustomer[]> {
    return this.apiService
      .get(`/pop-report/GetDraftOrderForCustomer/${CustomerId}`)
      .pipe(
        catchError((error: any) =>
          this.errorService.handleAsDefaultErrorOrderSubmit(error)
        )
      );
  }

  GetDeletedUnDeletedOrderForCustomer(
    CustomerId: string
  ): Observable<DeletedUnDeletedOrderForCustomer[]> {
    return this.apiService
      .get(`/pop-report/GetDeletedUnDeletedOrderForCustomer/${CustomerId}`)
      .pipe(
        catchError((error: any) =>
          this.errorService.handleAsDefaultErrorOrderSubmit(error)
        )
      );
  }

  UnDeleteOrderAsync(
    CustomerId: string,
    OrderId: string
  ): Observable<DeletedUnDeletedOrderForCustomer[]> {
    return this.apiService
      .get(`/pop-report/UnDeleteOrderAsync/${CustomerId}/${OrderId}`)
      .pipe(
        catchError((error: any) =>
          this.errorService.handleAsDefaultErrorOrderSubmit(error)
        )
      );
  }

  GetPayPalOrderForCustomer(
    CustomerId: string
  ): Observable<PayPalOrderForCustomer[]> {
    return this.apiService
      .get(`/pop-report/GetPayPalOrderForCustomer/${CustomerId}`)
      .pipe(
        catchError((error: any) =>
          this.errorService.handleAsDefaultErrorOrderSubmit(error)
        )
      );
  }

  getStripePublishableKey(): Observable<any> {
    var result = this.apiService
      .get(`/payments/stripeConfig`)
      .pipe(
        catchError((error: any) =>
          this.errorService.handleAsDefaultErrorOrderSubmit(error)
        )
      );
    return result;
  }

  createPaymentIntent(
    orderId: string,
    amount: any,
    currentUser: User,
    stripeCustomerID: string
  ): Observable<any> {

    return this.apiService
      .post(`/payments/${orderId}/createPaymentIntent`, {
        UserId: currentUser.id,
        CustomerId: currentUser.customerId,
        Name: currentUser.name,
        Email: currentUser.email,
        PhoneNumber: currentUser.phoneNumber,
        Amount: amount,
        StripeCustomerID: stripeCustomerID,
      })
      .pipe(
        catchError((error: any) => this.errorService.promoCodeError(error))
      );
  }

  updateStripeCustomer(
    orderId: string,
    paymentType: string,
    paymentIntentId: string,
    setupFutureUsage: string,
    stripeCustomerID: string
  ): Observable<any> {

    return this.apiService
      .post(`/payments/${orderId}/paymentReconciliationAndCardSave`, {
        PaymentType: paymentType,
        PaymentIntentId: paymentIntentId,
        SetupFutureUsage: setupFutureUsage,
      })
      .pipe(
        catchError((error: any) => this.errorService.promoCodeError(error))
      );
  }

  getCustomerSaveCards(
    orderId: string,
    amount: any,
    currentUser: User,
    orderNumber: number
  ): Observable<any> {
    return this.apiService
      .post(`/payments/${orderId}/savedCardsList`, {
        UserId: currentUser.id,
        CustomerId: currentUser.customerId,
        Name: currentUser.name,
        Email: currentUser.email,
        PhoneNumber: currentUser.phoneNumber,
        Amount: amount,
        OrderNumber: orderNumber,
      })
      .pipe(
        catchError((error: any) => this.errorService.promoCodeError(error))
      );
  }

  paymentWithSavedCard(
    paymentIntentId: any,
    currentUser: User,
    orderId: string,
    amount: any,
    paymentMethodId: string,
    paymentMethodType: string,
    stripeCustomerID: string
  ): Observable<any> {
    return this.apiService
      .post(`/payments/${orderId}/savedCardsPayment`, {
        PaymentIntentId: paymentIntentId,
        UserId: currentUser.id,
        CustomerId: currentUser.customerId,
        Email: currentUser.email,
        Amount: amount,
        Name: currentUser.name,
        PaymentMethodId: paymentMethodId,
        PaymentMethodType: paymentMethodType,
        StripeCustomerID: stripeCustomerID,
      })
      .pipe(
        catchError((error: any) => this.errorService.promoCodeError(error))
      );
  }
}
