import { Component, Input } from '@angular/core';
import { CardOrderItem, OrderItem } from '../../../orders/models/order';
@Component({
  selector: 'card-item-list',
  templateUrl: './card-item-list.component.html',
  styleUrls: ['../../../checkout/checkout.component.sass'],
})
export class CardItemListComponent {
  @Input() items = new Array<CardOrderItem>();
  @Input() item = new OrderItem();
  getCardName(item: CardOrderItem): string {
    return item && item.card ? item.card.name : item.name;
  }
  getCardDescription(item: CardOrderItem): string {
    return item && item.card ? item.card.description : null!;
  }
  getitemdata(item: OrderItem): any {
    if (item.data.crossover == true) {
      return 'Crossover';
    } else if (item.data.review == true) {
      return 'Review';
    } else if (item.data.reholder == true) {
      return 'Reholder';
    } else if (item.data.pregradeEncapsulation == true) {
      return 'Pregrade Encapsulation';
    }
  }
}
