<div class="row_ m-0 p-4 w-100">
    <div class="col-12">
        <nav class="navbar ">
            <div class="sub-menu">
                <input type="checkbox" id="show-menu" role="button">
                <label for="show-menu" class="show-menu"></label>
                <ul class=" menu" id="menu">
                    <li>
                        <a class="menu__item " routerLink="/account/dashboard"><img src="./assets/img/dashboard/subm.png" alt="" width="15" /> Dashboard</a>
                    </li>
                    <li>
                        <a class="menu__item" routerLink="/account/myorder"><img src="./assets/img/dashboard/order.png" alt="" width="15" /> My Orders</a>
                    </li>
                    <li>
                        <a class="menu__item " routerLink="/account/sellerready"><img src="./assets/img/dashboard/seller.png" alt="" width="15" /> Seller Ready</a>
                    </li>
                    <li>
                        <a class="menu__item " routerLink="/account/receipts"><img src="../../../../assets/img/dashboard/Dollar Icon - Black - S.png" alt="" width="15" />Receipts</a>
                    </li>
                    <li>
                        <a class="menu__item " routerLink="/account/needhelp"><img src="./assets/img/dashboard/helpp.png" alt="" width="15" /> I Need Help</a>
                    </li>
                    <li>
                        <a class="menu__item " routerLink="/account"><img src="./assets/img/dashboard/user.png" alt="" width="15" />My Profile</a>
                    </li>
                    <li>
                        <a class="menu__item" routerLink="/account/my-cards"><img src="./assets/img/dashboard/seller.png" alt="" width="15" />My Cards</a>
                    </li>
                    <!--<li>
                        <a class="menu__item active" routerLink="/account/my-cards-download"><img src="./assets/img/dashboard/seller.png" alt="" width="15" />Card Downloads</a>
                    </li>-->
                </ul>

            </div>
        </nav>
    </div>
</div>
<div class="wrapper myorder">
    <h2>My Cards Download Status</h2>
    <div class="user-links mt-4 p-4">
        <div>

            <div class="accordion_content">
                <ejs-grid #gridSub [dataSource]='checkDownlaodCardOrderItems' gridLines="both" allowPaging="true" [allowSorting]='true'
                          [allowFiltering]="true" [pageSettings]="pageSettings" [filterSettings]='FilterOptions'
                          [allowResizing]='true' allowTextWrap='true' [textWrapSettings]='wrapSettings'>
                    <e-columns>
                        <e-column field='createdOn' headerText='Requested On' type="date" [format]="format" [filter]='filter' textAlign="center" width=90></e-column>
                        <e-column field='zipGeneratedOn' headerText='Generated On' [format]="format" [filter]='filter' type="date" [allowSorting]='false' textAlign="center" width=90></e-column>
                        <e-column field='zipRequestStateStatus' [allowSorting]='false' headerText='Status' width='70' textAlign="center"></e-column>
                        <e-column field='zipUrl' headerText='Download' width='90' textAlign="center">
                            <ng-template #template let-checkDownlaodCardOrderItems>
                                <div *ngIf="checkDownlaodCardOrderItems.zipRequestState == 3;else result">
                                    <a style="text-decoration-line: none;cursor: pointer;color:#785aff;" target="_blank" href="{{checkDownlaodCardOrderItems.zipUrl}}">Download</a>
                                </div>
                                <ng-template #result>
                                    <div>
                                        <span>-</span>
                                    </div>
                                </ng-template>
                            </ng-template>
                        </e-column>

                    </e-columns>
                </ejs-grid>
            </div>
        </div>

    </div>
</div>


