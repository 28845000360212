import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ModalEvent } from '../models/modal.event';

@Injectable()
export class ModalService {
    private eventsSource = new BehaviorSubject<ModalEvent>(new ModalEvent());

    events$ = this.eventsSource.asObservable();

    open(type: string, options?: any, heading?:any) {
        this.registerEvent(type, "open", "open", options);
    }

    close(type: string, action: string) {
        this.registerEvent(type, "close", action);
    }

    registerEvent(type: string, event: string, action: string, options?: any) {
        this.eventsSource.next(new ModalEvent(type, event, action, options));
    }

}

export interface ModalInterface {
    title?: string;
    subtitle?: string;
    type: string;
    options?: any;
}
