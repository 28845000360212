import { Component, Input, OnInit } from '@angular/core';
import { ModalService, ModalInterface } from '../../services/modal.service';
import { CheckoutService } from '../../../checkout/services/checkout.service';
import { Order } from '../../../orders/models/order';
import { Address } from '../../models/user';
import * as _ from 'lodash';

@Component({
  selector: 'app-alert-add-address',
  templateUrl: './alert-add-address.component.html',
  styleUrls: ['./alert-add-address.component.css']
})
export class AlertAddAddressComponent implements ModalInterface, OnInit {

  constructor(
    private modalService: ModalService,
    private checkoutService: CheckoutService,
) { }

type = "app-alert-add-address";
loader!: boolean;
options: any;
updatedAddress: Address = new Address();

  close() {
    this.modalService.close(this.type, 'close');
    this.openAddressEditor(this.updatedAddress);
}

ngOnInit() {
    this.checkoutService.addressEditor$.subscribe(address => this.updatedAddress = address);
    //this.updatedAddress = _.cloneDeep(this.options);       
}

openAddressEditor(address?: Address) {
    this.modalService.open('address-editor', address);
}

hasAction(): boolean {
    return this.options && this.options.actionLabel;
}

takeAction() {
    this.options.actionCallback();
}

}
