export class Card {

    constructor(card?: any) {
        if(!card) return;

        this.id = card.id;
        this.name = card.name;
        this.set = card.set ? card.set.name ? card.set.name : card.set : null;
        this.year = card.year;
        this.number = card.cardNumber ? card.cardNumber : card.number;
        this.manufacturer = card.manufacturer ? card.manufacturer.name ? card.manufacturer.name : card.manufacturer : null;
        this.description = card.description;
        this.extendedDescription = card.extendedDescription;
    }

    id!: string;
    name!: string;
    set!: string
    manufacturer!: string
    year!: string;
    number!: string;
    description!: string;
    extendedDescription!: string;

    getId(): string {
        return this.id;
    }
}

export class CardGrade {

    constructor(grade?: any) {
        this.grade = grade.grade;
        this.name = grade.name;
        this.value = grade.value;
    }

    grade: number;
    name: string;
    value: string;
}

export class CardRejectionCode {
    
    constructor(code?: any) {
        this.code = code.code;
        this.name = code.name;
        this.tag = code.tag;
    }

    code: string;
    name: string;
    tag: string;
}

export class Set {
    
    constructor(set?: any) {
        if(!set) return;
        
        this.id = set.id;
        this.name = set.name;
        this.description = set.description;
        this.year = set.year;
        set.manufacturer && (this.manufacturer = set.manufacturer.name);
        set.sport && (this.sport = set.sport.name);
    }

    id!: string;
    name!: string;
    description!: string;
    year!: string;
    manufacturer!: string;
    sport!: string;
}

export class Manufacturer {

    constructor(manufacturer?: any) {
        if(!manufacturer) return;
        
        this.id = manufacturer.id;
        this.name = manufacturer.name;
        this.description = manufacturer.description;
    }
    
    id!: string;
    name!: string;
    description!: string;
}

export class Subject {
    
    constructor(subject?: any) {
        if (!subject) return;

        this.id = subject.id;
        this.name = subject.name;
        subject.sport && (this.sport = subject.sport.name);
    }

    id!: string;
    name!: string;
    sport!: string;

    getName(): string {
        return this.name;
    }

    getId(): string {
        return this.id;
    }
}

export const Sports: Array<any> = [
    {
        "name": "Baseball",
        "code": "baseball"
    },
    {
        "name": "Basketball",
        "code": "basketball"
    },
    {
        "name": "Football",
        "code": "football"
    },
    {
        "name": "Hockey",
        "code": "hockey"
    },
    {
        "name": "Auto Racing",
        "code": "auto-racing"
    },
    {
        "name": "Billiards",
        "code": "billiards"
    },
    {
        "name": "Bowling",
        "code": "bowling"
    },
    {
        "name": "Boxing",
        "code": "boxing"
    },
    {
        "name": "Cricket",
        "code": "cricket"
    },
    {
        "name": "Cycling",
        "code": "cycling"
    },
    {
        "name": "Four Sport",
        "code": "four-sport"
    },
    {
        "name": "Golf",
        "code": "golf"
    },
    {
        "name": "Horse Racing",
        "code": "horse-racing"
    },
    {
        "name": "Non-Sport",
        "code": "non-sport"
    },
    {
        "name": "Olympic",
        "code": "olympic"
    },
    {
        "name": "Other",
        "code": "other"
    },
    {
        "name": "Pokemon",
        "code": "pokemon"
    },
    {
        "name": "Racing",
        "code": "racing"
    },
    {
        "name": "Soccer",
        "code": "soccer"
    },
    {
        "name": "Softball",
        "code": "softball"
    },
    {
        "name": "Tennis",
        "code": "tennis"
    },
    {
        "name": "Track",
        "code": "track"
    },
    {
        "name": "Wrestling",
        "code": "wrestling"
    }
]