import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
// import "rxjs/add/observable/of";
// import "rxjs/add/operator/catch";
// import "rxjs/add/operator/finally";
import { of } from 'rxjs';
import { catchError, map, finalize } from 'rxjs/operators';
import { throwError } from 'rxjs';
import * as _ from 'lodash';
import { OrderService } from '../../orders/services/order.service';
import {
  Order,
  OrderType,
  OrderItem,
  ShippingMethod,
  AdminKPIExecutive,
} from '../../orders/models/order';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { OrderItemConfigurationService } from './order-item-configuration.services';
import { Address } from '../../common/models/user';
import { ModalService } from '../../common/services/modal.service';
import { Card } from '../../common/models/catalog';
import { StateService } from '../../common/services/state.service';
import { AlertService } from '../../common/services/alert.service';
import { ErrorService } from '../../common/services/error.service';
import { Subject } from 'rxjs';
// import { log } from "console";

@Injectable()
export class CheckoutService {
  shippingValue: boolean = false;

  constructor(
    private orderService: OrderService,
    private router: Router,
    private orderItemConfiguration: OrderItemConfigurationService,
    private modalService: ModalService,
    private stateService: StateService,
    private alertService: AlertService,
    private errorService: ErrorService
  ) {
    this.orderItemConfiguration.itemConfigured$.subscribe((item: any) => {
      if (!_.isEmpty(item)) this.addOrderItem(item);
    });

    this.next.subscribe((data) => {
      if (data == true) {
        // console.log(data);
        this.shippingValue = true;
      }
    });
  }

  currentOrder = new Order();
  summaryOrder = new Order();

  currentStep = 0;
  finishAndPay: boolean = true;
  //ADDITIONAL
  isCheckboxChecked = false;
  accepted: boolean = false;
  certificationCodePattern = '^(SGC\\s*:\\s*|SGC\\s*)?(\\d{7,8}(-\\d{3,4})?)$';
  olderOrder = new Order();
  order = new Order();
  show: boolean = false;
  initchk: boolean = false;
  planstate: boolean = false;
  onstep3: boolean = false;
  showFixissues!: boolean;

  // saveFedexAccount(fedexAccount: number) {
  //   this.currentOrder.settings.fedexAccount = fedexAccount;
  //   this.currentOrder.settings.useFedexAccount = true;
  //   console.log('Fedex Account', this.currentOrder.settings.fedexAccount);
  //   console.log(
  //     'Use Fedex Account',
  //     this.currentOrder.settings.useFedexAccount
  //   );
  //   console.log('Saving');
  //   this.summaryOrder.shippingMethod!.fee = 0;
  //   this.order.shippingMethod!.fee = 0;
  //   this.olderOrder.shippingMethod!.fee = 0;
  //   this.currentOrder.shippingMethod!.fee = 0;
  //   //  this.summaryOrder.fee.shipping = 0;
  // }

  saveFedexAccount(fedexAccount: any, apply: boolean) {
    
    // console.log(fedexAccount);
    if (fedexAccount != null && apply == true) {
      this.currentOrder.settings.fedexAccount = fedexAccount;
      this.currentOrder.settings.useFedexAccount = true;
      // console.log('Fedex Account', this.currentOrder.settings.fedexAccount);
      // console.log(
      //   'Use Fedex Account',
      //   this.currentOrder.settings.useFedexAccount
      // );
      // console.log('Saving');
    } else {
      this.currentOrder.settings.fedexAccount = fedexAccount;
    }
  }


  stripePaymentMismatch(order: Order) {
    this.currentOrder = order;
    this.setCurrentOrder(order);
    this.setCurrentStep(3);
    this.router.navigate([`/checkout/${order.id}/plans`]);
}

  getOrderForStripePaymentReconciliation(): Observable<any> {
    return this.orderService.getOrderForStripePaymentReconciliation(this.currentOrder.id);
}

  getshow(): any {
    return this.show;
  }
  getinit(): any {
    return this.initchk;
  }
  setinit(): any {
    this.initchk = true;
  }

  setshow(set: any): any {
    this.show = set;
  }
  oldOrder() {
    this.olderOrder = this.currentOrder;
    // console.log(this.olderOrder);
  }

  moveForward2() {
    
    var step = this.steps[this.currentStep - 1];
    if (step.alert) return step.alert();
    if (this.errorCount == 0) {
      this.moveToNextStep();
    }
  }

  resetfee() {
    this.currentOrder.fee = this.olderOrder.fee;
  }

  setPromo(pc: any) {
    this.currentOrder.promoCode = pc;
  }
  //  oderUpdate(){
  //     if(this.isStep5){
  //         this.currentOrder = this.olderOrder
  //     }
  //     console.log(this.olderOrder)
  //  }

  // saveOlderOder(){
  //     var olderOrder;

  //         olderOrder = Object.assign({}, this.currentOrder);
  //         console.log(olderOrder);

  // }

  private checked = new BehaviorSubject<boolean>(false);
  checked$ = this.checked.asObservable();

  setChecked(checked: boolean) {
    this.checked.next(checked);
  }
  // Behaviors
  private ItemsStepStateMessage = new BehaviorSubject<string>('');
  cast = this.ItemsStepStateMessage.asObservable();

  private data = new Subject<any>();
  error = this.data.asObservable();

  private enableValue = new Subject<any>();
  next = this.enableValue.asObservable();

  emitdata(x: any) {
    this.data.next(x);
  }

  setEnable() {
    this.enableValue.next(true);
  }

  newItemsStepStateMessage(validationMessage: any) {
    this.ItemsStepStateMessage.next(validationMessage);
  }

  private businessGurDaysMsg = new BehaviorSubject<AdminKPIExecutive>(
    new AdminKPIExecutive()
  );
  current30daymsg$ = this.businessGurDaysMsg.asObservable();

  newGuranteeDaysMsg(adminKPIExecutive: AdminKPIExecutive) {
    this.businessGurDaysMsg.next(adminKPIExecutive);
  }

  private addressEditor = new BehaviorSubject<Address>(new Address());
  addressEditor$ = this.addressEditor.asObservable();

  newAddress(address: Address) {
    this.addressEditor.next(address);
  }

  private removeAddress = new BehaviorSubject<Address>(new Address());
  removeAddress$ = this.removeAddress.asObservable();

  addressRemove(address: Address) {
    this.removeAddress.next(address);
  }

  private payTermsSource = new BehaviorSubject<boolean>(false);
  currentpayTerms = this.payTermsSource.asObservable();

  changeCurrentpayTerms(acceptterms: any) {
    this.payTermsSource.next(acceptterms);
  }

  private deleteAdressSource = new BehaviorSubject<boolean>(false);
  CheckdeleteAdress = this.deleteAdressSource.asObservable();

  deleteAdress(deleted: any) {
    this.deleteAdressSource.next(deleted);
  }

  private currentStepSource = new BehaviorSubject<number>(0);
  currentStep$ = this.currentStepSource.asObservable();

  private currentOrderSource = new BehaviorSubject<Order>(new Order());
  currentOrder$ = this.currentOrderSource.asObservable();

  private isRefreshingOrderSource = new BehaviorSubject<boolean>(false);
  isRefreshingOrder$ = this.isRefreshingOrderSource.asObservable();

  private refreshedOrderSource = new BehaviorSubject<Order>(new Order());
  refreshedOrder$ = this.refreshedOrderSource.asObservable();

  private itemAddedSource = new BehaviorSubject<any>({});
  itemAdded$ = this.itemAddedSource.asObservable();

  private newCardAddedSource = new BehaviorSubject<Card | null>(null);
  newCardAdded$ = this.newCardAddedSource.asObservable();

  addNewCard(card: Card) {
    this.newCardAddedSource.next(card);
    this.newCardAddedSource.next(null);
  }

  // Steps
  private steps: any = [
    {
      id: 'expertise',
      segment: 'expertise',
      name: 'Order Type',
      stepNumber: 1,
      canMoveBackward: false,
      canMoveForward: true,
      state: this.getExpertiseStepState.bind(this),
    },
    {
      id: 'items',
      segment: 'items',
      name: 'Add Cards',
      stepNumber: 2,
      canMoveBackward: true,
      canMoveForward: true,
      state: this.getItemsStepState.bind(this),
    },
    {
      id: 'plans',
      segment: 'plans',
      name: 'Service',
      stepNumber: 3,
      canMoveBackward: true,
      canMoveForward: true,
      state: this.getPlansStepState.bind(this),
    },
    {
      id: 'address',
      segment: 'address',
      name: 'Address',
      stepNumber: 4,
      canMoveBackward: true,
      canMoveForward: true,
      alert: this.alertAboutPersonalShipping.bind(this),
      alerted: false,
      state: this.getAddressStepState.bind(this),
    },
    {
      id: 'shipping',
      segment: 'shipping',
      name: 'Shipping ',
      stepNumber: 5,
      canMoveBackward: true,
      canMoveForward: true,
      alert: this.alertAboutPersonalShipping.bind(this),
      alerted: false,
      state: this.getShippingStepState.bind(this),
    },
    {
      id: 'review',
      segment: 'review',
      name: 'Review',
      stepNumber: 6,
      canMoveBackward: true,
      canMoveForward: false,
      state: this.getShippingStepState.bind(this),
    },
    {
      id: 'payment',
      segment: 'payment',
      name: 'Payment',
      stepNumber: 7,
      canMoveBackward: true,
      canMoveForward: true,
      state: this.getPaymentStepState.bind(this),
    },
    {
      id: 'confirmation',
      segment: 'confirmation',
      name: 'Confirm',
      stepNumber: 8,
      canMoveBackward: false,
      canMoveForward: false,
    },
  ];

  getSteps(): any[] {
    return this.steps;
  }

  getStep(step: number): any {
    var index = step - 1;
    // console.log(this.getSteps()[index]);
    return this.getSteps()[index];
  }

  getCurrentStep(): any {
    return this.getStep(this.currentStep);
  }

  getPreviousStep(step: any): any {
    var previousStepNumber = step.stepNumber - 1;
    return this.getStep(previousStepNumber);
  }

  agreed(): any {
    return (this.accepted = true);
  }

  getStepBySegment(segment: string): any {
    return _.find(this.getSteps(), (step) => step.segment == segment);
  }

  getCurrentOrder(): Order {
    return this.currentOrder;
  }

  hasCurrentOrder(): boolean {
    return this.currentOrder.id != null;
  }

  // Navigation
  navigateToStep() {
    this.router.navigate([
      `/checkout/${this.currentOrder.id}/${
        this.steps[this.currentStep - 1].segment
      }`,
    ]);
  }
  //ADDITIONAL
  navigateTOFinish() {
    this.router.navigate([`/checkout/${this.currentOrder.id}/confirmation`]);
  }
  //

  navigateStep(order: Order) {
    
    if (!order.hasOrderType()) {
      this.router.navigate([`/checkout/${order.id}/expertise`]);
    } else if (!order.hasOrderItems()) {
      this.router.navigate([`/checkout/${order.id}/items`]);
    } else if (!order.hasPlan()) {
      this.router.navigate([`/checkout/${order.id}/plans`]);
    } else if (!order.hasShippingAddress()) {
      this.router.navigate([`/checkout/${order.id}/address`]);
    } else if (!order.hasShippingMethod()) {
      this.router.navigate([`/checkout/${order.id}/shipping`]);
    } else {
      this.router.navigate(['/checkout']);
    }
  }

  navigateToHome() {
    this.router.navigate(['/']);
  }

  moveForward() {
    
    var step = this.steps[this.currentStep - 1];
    if (step.alert) return step.alert();
    this.moveToNextStep();
    // console.log(this.currentStep);
  }

  finishPay() {
    

    var step = this.steps[this.currentStep - 1];
    if (step.alert) return step.alert();
    this.moveToNextStep();
    if (
      this.currentOrder.fee.shipping === 0 &&
      this.currentOrder.settings.fedexAccount == null
    ) {
      this.submitOrder();
    }
  }

  submitWithoutPay() {
    this.setCurrentStep(this.currentStep + 2);
    this.navigateTOFinish();
  }

  moveToNextStep() {
    this.setCurrentStep(this.currentStep + 1);
    this.navigateToStep();
  }

  moveBackward() {
    if (this.currentStep <= 1) {
      this.cancelCheckout();
    } else {
      this.setCurrentStep(this.currentStep - 1);
      this.navigateToStep();
    }
  }

  canMoveBackward(): boolean {
    if (this.currentStep < 1) return false;
    return this.steps[this.currentStep - 1].canMoveBackward;
  }

  canMoveForward(): boolean {
    if (this.currentStep < 1) return true;
    return this.steps[this.currentStep - 1].canMoveForward;
  }

  cancelCheckout() {
    this.orderService.cancelOrder(this.currentOrder).subscribe(() => {
      this.resetCheckoutState();
      this.navigateToHome();
    });
  }

  isCheckoutFinished(): boolean {
    return this.currentStep > 8;
  }

  showPromoCode(): boolean {
    return this.isPaymentStep() || this.hasPromoCode();
  }

  hasPromoCode(): boolean {
    return (
      this.currentOrder.promoCode != null || this.summaryOrder.promoCode != null
    );
  }

  alertAboutPersonalShipping() {
    if (!this.currentOrder.settings.useFedexAccount)
      return this.moveToNextStep();

    var userId = this.stateService.currentUser.get().id;
    var acceptPersonalShippingAccountClause = JSON.parse(
      localStorage.getItem(`${userId}_acceptPersonalShippingClause`)!
    );

    if (acceptPersonalShippingAccountClause) return this.moveToNextStep();

    var options: any = {
      accept: this.moveToNextStep.bind(this),
    };

    this.modalService.open('personal-shipping-alert', options);
  }

  // State
  resetCheckoutState() {
    this.currentOrder = new Order();
    this.summaryOrder = new Order();
    this.currentOrderSource.next(this.currentOrder);
    this.refreshedOrderSource.next(this.summaryOrder);
    this.setCurrentStep(0);
  }

  setCurrentStep(step: number) {
    this.currentStep = step;
    this.currentStepSource.next(step);
  }

  setCurrentOrder(order: Order) {
    this.currentOrder = order;
    this.currentOrderSource.next(order);
    this.refreshedOrderSource.next(order);
    this.isRefreshingOrderSource.next(false);
    return order;
  }

  setCurrentOrderFesexAccount(order: Order) {
    this.currentOrderSource.next(order);
  }

  // showcolor():any{
  //     this.
  // }

  getStepState(): any {
    if (this.currentStep < 1) return false;

    if (this.currentOrder.isSubmitted()) return false;

    var section = this.steps[this.currentStep - 1].id;
    switch (section) {
      case this.steps[0].id:
        return this.getExpertiseStepState();
      case this.steps[1].id:
        return this.getItemsStepState();
      case this.steps[2].id:
        return this.getPlansStepState();
      case this.steps[3].id:
        return this.getAddressStepState();
      case this.steps[4].id:
        return this.getShippingStepState();
      case this.steps[5].id:
        return this.getShippingStepState();
      //    case this.steps[4].id:
      //         return this.getPaymentStepState();
      default:
        return { completed: true };
    }
  }

  isShippingState(): boolean {
    var section = this.steps[this.currentStep - 1].id;
    return section == 'shipping';
  }

  getExpertiseStepState() {
    var completed = this.currentOrder.hasOrderType();

    return {
      completed: completed,
      message: 'Please select an expertise',
    };
  }

  getItemsStepState() {
    var hasOrderItems = this.currentOrder.hasOrderItems();
    var allItemsWithDeclaredValue =
      this.currentOrder.allItemsWithDeclaredValue();
    var completed = hasOrderItems && allItemsWithDeclaredValue;

    var statements = [];

    if (!hasOrderItems) statements.push('search and select your items');
    if (!allItemsWithDeclaredValue)
      statements.push('enter a declared value for your items');

    var message = this.getFormattedMessage('Please', statements);

    return {
      completed: completed,
    };
  }

  getPlansStepState() {
    //var completed = this.currentOrder.hasPlan() || this.currentOrder.fee.base > 0;
    // var completed = this.currentOrder.hasPlan();

    var completed;

    if (this.planstate == false && this.onstep3 == true) {
      completed = false;
    } else {
      completed = true;
    }

    return {
      completed: completed,
      message: 'Please select a plan',
    };
  }

  getAddressStepState() {
    var hasShippingAddress = this.currentOrder.hasShippingAddress();

    var completed = hasShippingAddress;

    var statements = [];

    if (!hasShippingAddress) statements.push('select or enter an address');

    var message = this.getFormattedMessage('Please', statements);
    // if (this.shippingValue) {
    //     completed = true;
    // }
    return {
      completed: completed,
      message: message,
    };
  }

  getShippingStepState() {

    // console.log(this.currentOrder);
    var hasShippingMethod = this.currentOrder.hasShippingMethod();
    // var hasShippingAddress = this.currentOrder.hasShippingAddress();
    var isFedexAccountSet = this.isFedexAccountSet();

    var completed = hasShippingMethod && isFedexAccountSet;

    var statements = [];

    if (!hasShippingMethod || hasShippingMethod == null)
      statements.push('select a shipping method');
    // if (!hasShippingAddress) statements.push("select or enter an address");
    if (!isFedexAccountSet) statements.push('enter your FedEx account');

    var message = this.getFormattedMessage('Please', statements);
    // if (this.shippingValue) {
    //     completed = true;
    // }
    return {
      completed: completed,
      message: message,
    };
  }

  getPaymentStepState() {
    var completed = this.currentOrder.isSubmitted();
    if (!completed) {
      this.orderService
        .getOrder(this.currentOrder.id!)
        .subscribe((order: any) => {
          this.currentOrder = order;
          this.setCurrentOrder(order);
        });
    }

    return {
      completed: completed,
      message: 'Please pay the order',
    };
  }

  isOrderCompleted(): boolean {
    var isExpertiseCompleted = this.getExpertiseStepState().completed;
    var isItemsCompleted = this.getItemsStepState().completed;
    var isPlansCompleted = this.getPlansStepState().completed;
    var isShippingCompleted = this.getShippingStepState().completed;

    return (
      isExpertiseCompleted! &&
      isItemsCompleted! &&
      isPlansCompleted! &&
      isShippingCompleted!
    );
  }

  getFormattedMessage(prefix: string, statements: Array<string>): string {
    var message = prefix || '';

    if (statements.length == 0) return message;

    message = `${message} ${statements[0]}`;

    for (var i = 1; i < statements.length - 1; i++) {
      message = `${message}, ${statements[i]}`;
    }

    if (statements.length > 1)
      return `${message} and ${statements[statements.length - 1]}`;

    message = `${message}.`;

    return message;
  }

  getOrderPlans(): Observable<any[]> {
    return this.orderService.getOrderPlans(this.currentOrder);
  }

  setOrderPlan(
    plan: any,
    plan1st: any,
    plan2nd: any,
    plan3rd: any,
    plan4th: any,
    planCount: any
  ) {
    this.orderService
      .setOrderPlan(
        this.currentOrder,
        plan,
        plan1st,
        plan2nd,
        plan3rd,
        plan4th,
        planCount
      )
      .subscribe(() => {
        this.refreshOrderWithPlanAsObservable().subscribe((order) =>
          this.currentOrder.setPlan(plan)
        );
      });
  }

  isFedexAccountSet(): boolean {
    return (
      (this.currentOrder.settings.useFedexAccount &&
        this.currentOrder.settings.fedexAccount != undefined) ||
      !this.currentOrder.settings.useFedexAccount
    );
  }
  // Actions
  initCheckout(orderId: string, segment?: string) {
    // console.log(segment);
    if (this.hasCurrentOrder() && this.getCurrentOrder().id == orderId) {
        var step = segment
            ? this.getStepBySegment(segment)
            : this.getStep(this.steps.length);
            // console.log(step);
        this.redirectToStep(step.stepNumber, this.getCurrentOrder());
        return;
    }

    var orderObservable = orderId
        ? this.getOrder(orderId)
        : this.createOrder();

    orderObservable.subscribe((o) => {
        if (!o) return;

        this.currentOrder = o;
        var step = segment
            ? this.getStepBySegment(segment)
            : this.getStep(this.steps.length);

        this.redirectToStep(step.stepNumber, o);
    });
}

  goToStep(stepNumber: number, order: Order) {
    this.setCurrentOrder(order);
    this.setCurrentStep(stepNumber);
    this.navigateToStep();
  }

  redirectToStep(stepNumber: number, order: Order) {
    for (var i = stepNumber; i > 1; i--) {
      if (this.canAccess(this.getStep(i))) break;
    }

    if (!i || i == 1) return this.goToStep(1, order);

    this.goToStep(i, order);
  }

  getAllowedStep(stepNumber: number, order: Order) {
    for (var i = stepNumber; i > 1; i--) {
      if (this.canAccess(this.getStep(i))) {
        return this.getStep(i);
      }
    }

    return this.getStep(i);
  }

  canAccess(step: any) {
    if (!step) return;

    var previousStep = this.getPreviousStep(step);
    // console.log(previousStep.state().completed);
    return previousStep.state().completed;
  }

  stripePaymentStep(order: Order, step: any) {
    this.currentOrder = order;
    if (order.id && order.items.length > 0) {
      this.redirectToStripePaymentStep(step, this.currentOrder);
    } else {
      if (this.canAccess(this.getStep(2))) {
        this.goToStep(2, order);
      } else {
        this.goToStep(1, order);
      }
    }
  }

  redirectToStripePaymentStep(stepNumber: number, order: Order) {
    if (this.canAccess(this.getStep(7))) {
      this.setCurrentOrder(order);
      this.setCurrentStep(stepNumber);
      this.router.navigate([`/orders/${this.currentOrder.id}/stripe`]);
    } else if (this.canAccess(this.getStep(2))) {
      this.goToStep(2, order);
    } else {
      this.goToStep(1, order);
    }
  }

  // createOrder(): Observable<Order> {
  //     this.resetCheckoutState();
  //     return this.orderService
  //         .createOrder()
  //         .catch((error:any) =>
  //             this.errorService.handleError(
  //                 error,
  //                 "CheckoutService.createOrder"
  //             )
  //         );
  // }

  createOrder(): Observable<Order> {
    this.resetCheckoutState();
    return this.orderService
      .createOrder()
      .pipe(
        catchError((error: any) =>
          throwError(
            this.errorService.handleError(error, 'CheckoutService.createOrder')
          )
        )
      );
  }
  getOrder(id: string): Observable<Order> {
    if (this.currentOrder.getId() == id) return of(this.currentOrder);
    return this.orderService.getOrder(id);
  }

  refreshOrder() {
    return this.refreshOrderAsObservable().subscribe();
  }

  refreshOrderWithShipping() {
    return this.refreshOrderWithShippingAsObservable().subscribe();
  }

  refreshOrderAsObservable(): Observable<Order> {
    this.isRefreshingOrderSource.next(true);

    return this.orderService.getOrder(this.currentOrder.getId()!).pipe(
      map((order: any) => {
        this.currentOrder.updateFee(order.fee);
        return this.refreshSummaryOrder(order);
      })
    );
  }

  refreshOrderWithPlanAsObservable(): Observable<Order> {
    this.isRefreshingOrderSource.next(true);

    return this.orderService.getOrderWithPlans(this.currentOrder.getId()!).pipe(
      map((order: any) => {
        this.currentOrder.updateFee(order.fee);
        return this.refreshSummaryOrder(order);
      })
    );
  }

  refreshOrderWithShippingAsObservable(): Observable<Order> {
    this.isRefreshingOrderSource.next(true);

    return this.orderService
      .getOrderWithShipping(this.currentOrder.getId()!)
      .pipe(
        map((order: any) => {
          this.currentOrder.updateFee(order.fee);
          return this.refreshSummaryOrder(order);
        })
      );
  }

  refreshSummaryOrder(order: Order) {
    this.refreshedOrderSource.next(order);
    this.summaryOrder = order;
    this.isRefreshingOrderSource.next(false);
    return order;
  }

  setOrderType(orderType: OrderType) {
    this.currentOrder.setOrderType(orderType);
    //this.orderService.getOrder(this.currentOrder.getId()).subscribe(o => {
    //    this.currentOrder.orderStatus = o.orderStatus;
    //});
    this.orderService
      .setOrderType(this.currentOrder)
      .pipe(catchError((error: any) => this.OrderTypeError(error)))
      .subscribe((order: any) => this.refreshSummaryOrder(order));
  }

  OrderTypeError(error: any): Observable<any> {
    this.orderService
      .getOrderTypeByOrderId(this.currentOrder.getId()!)
      .subscribe((o: any) => {
        this.currentOrder.setOrderType(o.orderType);
      });
    return this.errorService.handleAsDefaultErrorOrderType(error);
  }

  addOrderItem(item: any) {
    this.orderService
      .addOrderItem(item, this.currentOrder)
      .subscribe((orderItem: any) => {
        this.currentOrder.addOrderItem(orderItem);
        this.itemAddedSource.next(item);
        this.refreshOrder();
      });
  }

  removeOrderItem(orderItem: OrderItem) {
    this.currentOrder.removeOrderItem(orderItem);
    this.orderService.removeOrderItem(orderItem).subscribe((orderItem: any) => {
      this.refreshOrder();
    });
  }

  errorCount: number = 0;
  getErrorCount(): number {
    if (this.errorCount < 0) {
      return (this.errorCount = 0);
    }
    return this.errorCount;
  }

  selectResultItem(resultItem: any) {
    this.orderItemConfiguration.configureItem(
      resultItem,
      this.currentOrder.getOrderType().getCode()
    );
  }

  Errorcount(): number {
    // console.log(this.currentOrder.items);
    this.errorCount = 0;

    this.currentOrder.items.forEach((item: any) => {
      if (
        item.settings.declaredValue == null ||
        isNaN(item.settings.declaredValue) ||
        item.settings.declaredValue == 0
      ) {
        this.errorCount++;
      }
      if (item.data.crossover == true) {
        if (item.data.minimumRequestedGrade == '') {
          this.errorCount++;
        }
        if (
          item.data.certificationCode == '' ||
          item.data.certificationCode < 1000
        ) {
          this.errorCount++;
        }
      }
      if (item.data.review == true) {
        if (item.data.minimumRequestedGrade == '') {
          this.errorCount++;
        }
        if (
          item.data.certificationCode == '' ||
          !item.data.certificationCode.match(this.certificationCodePattern)
        ) {
          this.errorCount++;
        }
      }
      if (item.data.reholder == true) {
        if (
          item.data.certificationCode == '' ||
          !item.data.certificationCode.match(this.certificationCodePattern)
        ) {
          this.errorCount++;
        }
      }
    });
    if (this.errorCount < 0) {
      return (this.errorCount = 0);
    }
    return this.errorCount;
  }
  // getOrderTypeCode(): string {
  //     return this.currentOrder.getOrderType() ? this.currentOrder.getOrderType().getCode() : null;
  // }
  getOrderTypeCode(): string {
    return 'Card grading';
  }
  updateOrderItemForNewPlan(orderItem: OrderItem): Observable<OrderItem> {
    return this.orderService.updateOrderItemForNewPlan(orderItem).pipe(
      map((orderItem: any) => {
        this.refreshOrder();
        return orderItem;
      })
    );
  }

  updateOrderItem(orderItem: OrderItem): Observable<OrderItem> {
    return this.orderService.updateOrderItem(orderItem).pipe(
      map((orderItem: any) => {
        this.refreshOrder();
        return orderItem;
      })
    );
  }

  getAvailableShippingMethods(): Observable<ShippingMethod[]> {
    return this.orderService.getAvailableShippingMethods(this.currentOrder);
  }

  setShippingMethod(shippingMethod: ShippingMethod) {
    this.orderService
      .setShippingMethod(this.currentOrder, shippingMethod)
      .subscribe((data: any) => {
        this.currentOrder.shippingMethod = shippingMethod;
        this.refreshOrderWithShipping();
      });
  }

  //setShippingMethodForShipping(shippingMethod: ShippingMethod) {
  //    this.orderService.setShippingMethodForFee(this.currentOrder, shippingMethod).subscribe(data => {
  //        this.currentOrder.shippingMethod = shippingMethod;
  //        this.refreshOrder();
  //    });
  //}

  setShippingAddress(address: Address): Observable<any> {
    this.currentOrder.shippingAddress = address;
    return this.orderService.setShippingAddress(this.currentOrder, address);
  }

  unSetShippingAddress() {
    this.currentOrder.shippingAddress = new Address();
  }

  unSetShippingMethod() {
    this.currentOrder.shippingMethod = new ShippingMethod();
    this.summaryOrder = this.currentOrder;
    this.refreshedOrderSource.next(this.summaryOrder);
  }

  applyPromoCode(code: string): Observable<any> {
    debugger;
    this.isRefreshingOrderSource.next(true);
    return this.orderService
      .applyPromoCode(
        code,
        this.currentOrder,
        this.currentOrder.shippingMethod!
      )
      .pipe(
        finalize(() => {
          this.refreshOrder();
          this.isRefreshingOrderSource.next(false);
        })
      );
  }

  processPayment(data: any) {
    this.isRefreshingOrderSource.next(true);
    this.orderService.processPayment(this.currentOrder, data).subscribe(
      (response: any) => {
        if (response.status == 'approved') {
          this.currentOrder.setOrderStatus({
            code: 'submitted',
            name: 'Submitted',
          });
          this.moveForward();
          this.isRefreshingOrderSource.next(false);
        }
      },
      (error: any) => {
        this.errorService.handlePaypalError(error, this.currentOrder.id);
      }
    );
  }

  processStripePayment(status: any) {
    this.isRefreshingOrderSource.next(true);
    if (status == 'succeeded' || status == 'processing') {
      this.currentOrder.setOrderStatus({
        code: 'submitted',
        name: 'Submitted',
      });
      this.moveForward();
      this.isRefreshingOrderSource.next(false);
    }
  }



  googleAndApplePayPaymentReconciliation(orderId: string, paymentIntentId: string, amount: number): Observable<any> {
    return this.orderService.googleAndApplePayPaymentReconciliation(orderId, paymentIntentId, amount);
}

  submitOrder() {
    this.isRefreshingOrderSource.next(true);
    this.orderService.submitOrder(this.currentOrder).subscribe(() => {
      this.currentOrder.setOrderStatus({
        code: 'submitted',
        name: 'Submitted',
      });
      this.submitWithoutPay();
      this.isRefreshingOrderSource.next(false);
    });
  }

  submitTerms(termsAccept: boolean, termsAcceptData: string) {
    this.orderService
      .submitOrderTerms(termsAccept, termsAcceptData, this.currentOrder)
      .subscribe((order: any) => this.refreshOrder());
  }

  updateOrder() {
    this.orderService
      .updateOrder(this.currentOrder)
      .subscribe((order: any) => this.refreshOrder());
  }

  updateOrderWithShipping() {
    this.orderService
      .updateOrder(this.currentOrder)
      .subscribe((order: any) => this.refreshOrderWithShipping());
  }

  updateRecalculateOrder() {
    this.orderService
      .updateRecalculateOrder(this.currentOrder)
      .subscribe((order: any) => this.refreshOrder());
  }

  updateOrderForCusFedex() {
    this.orderService
      .updateOrderForCusFedex(this.currentOrder)
      .subscribe((order: any) => this.refreshOrder());
  }

    getRefreshOrder(id: string): Observable<Order> {
        return this.orderService.getOrder(id);
    }


  isPaymentStep(): boolean {
    return this.currentStep == 6;
  }
  isStep1(): boolean {
    return this.currentStep == 1;
  }
  isStep2(): boolean {
    return this.currentStep == 2;
  }
  isStep3(): boolean {
    return this.currentStep == 3;
  }
  isStep4(): boolean {
    return this.currentStep == 4;
  }
  isStep5(): boolean {
    return this.currentStep == 5;
  }
  isStep6(): boolean {
    return this.currentStep == 6;
  }
  isStep7(): boolean {
    return this.currentStep == 7;
  }
  isStep8(): boolean {
    return this.currentStep == 8;
  }
}
