import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';

import { ModalService, ModalInterface } from '../../services/modal.service';
import { StateService } from '../../services/state.service';
import { UserService } from '../../services/user.service';
import { Address } from '../../models/user';
import { CheckoutService } from '../../../checkout/services/checkout.service';
import { Order } from '../../../orders/models/order';

@Component({
    moduleId: module.id,
    selector: 'address-editor',
    templateUrl: './address-editor.component.html'
})

export class AddressEditorComponent implements ModalInterface, OnInit {
    constructor(
        private modalService: ModalService,
        private stateService: StateService,
        private userService: UserService,
        private checkoutService: CheckoutService,
    ) { }

    title = "User Address";
    type = "address-editor";
    options: any; // {address}
    countries = new Array<any>();
    states = new Array<any>();
    isSubmitting: boolean = false;
    userhasaddress: boolean = false;
    showState: boolean = false;
    updatedAddress: Address = new Address();

    userAddresses = new Array<Address>();
    customerAddresses = new Array<Address>();
    order = new Order();

    close(action: string) {
        this.modalService.close(this.type, action);
    }

    save() {
        this.isSubmitting = true;
        var address = (this.updatedAddress && this.updatedAddress.id) ? this.userService.editAddress(this.updatedAddress) : this.userService.addAddress(this.updatedAddress);
        address.subscribe(a => {
            this.isSubmitting = false;
            this.close('close');
        })
    }

    discard() {
        this.close('cancel');
    }

    canSelectState(): boolean {
        return this.updatedAddress && this.updatedAddress.country && this.isCountryHandled(this.updatedAddress.country.code);
    }

    canSave(): boolean {
        var hasCountry = this.updatedAddress.country && this.updatedAddress.country.code;
        var hasStreet = !!this.updatedAddress.street1;
        var hasState = (this.updatedAddress.state && this.updatedAddress.state.code) || !this.canSelectState();
        var hasCity = !!this.updatedAddress.city;
        var hasZipCode = !!this.updatedAddress.zipCode;

        return Boolean(hasCountry && hasStreet && hasState && hasCity && hasZipCode);
    }

    onCountryChange(country: any) {
        this.updatedAddress.country.code = country;

        if (this.isCountryHandled(country)) {
            this.filterStates(country);
            //this.showState = true;
        } else {
            this.updatedAddress.state.code = null!;
            //this.showState = false;
        }
    }

    isCountryHandled(country: string): boolean {
        return country == 'US' || country == 'CA'
    }

    filterStates(country: string) {
        this.states = this.stateService.getListOfStates(country);
    }

    ngOnInit() {
        this.stateService.getListOfCountries().subscribe(countries => this.countries = countries);
        this.filterStates(this.options.country.code);
        if (!this.options.state) {
            this.options.state = {
                code: ''
            }
        }
        this.updatedAddress = _.cloneDeep(this.options);
        //if (this.updatedAddress.country.code == 'US' || this.updatedAddress.country.code == 'CA') {
        //    this.showState = true;
        //}
        //else {
        //    this.showState = false;
        //    this.updatedAddress.state.code = '';
        //}

        var data = JSON.parse(localStorage.getItem('newUserInfo')!);
        if (data != null) {
            this.userService.selectedCusAddresses$.subscribe(addresses => {
                this.customerAddresses = addresses;
                if (addresses.length == 0) {
                    this.userhasaddress = true;
                }
                else {
                    this.userhasaddress = false;
                }
            });
        }
        else {
            this.userService.userAddresses$.subscribe(addresses => {
                this.userAddresses = addresses;
                if (addresses.length == 0) {
                    this.userhasaddress = true;
                }
                else {
                    this.userhasaddress = false;
                }
            });
        }
    }
}
