<div
  class="content content--compact overflow-x-auto"
  style="grid-gap: 30px 0px; margin-bottom: 20px"
>
  <h2>Services and Pricing</h2>
  <h3>PRICING FOR RAW CARD GRADING, REHOLDERS, CROSSOVERS, AND REVIEWS</h3>
</div>

<!--<div class="contents content--compact overflow-x-auto" style="grid-gap:30px 0px;margin-bottom:20px;">
    <section style="margin-bottom:20px;">
        <div class="grid-list">
            <h3>ADDITIONAL UPCHARGES</h3>
            <div class="grid-list__note">
                <h4>Custom Encapsulation</h4>
                <p>Base Fee + Additional Labor: $60.00</p>
                <p>
                    All Turnaround dates are not guaranteed and will be completed based on resource availability.
                </p>
            </div>
            <div class="grid-list__note">
                <h4>Advance Research For Non-Standard Issue Cards</h4>
                <p>All Turnaround dates are not guaranteed and will be completed based on resource availability.</p>
                <p>Base Fee + Advanced Research: $60.00</p>
            </div>
        </div>
    </section>
</div>-->

<div
  class="content content--compact overflow-x-auto"
  style="grid-gap: 30px 0px; margin-bottom: 20px"
>
  <h3>SHIPPING PRICES</h3>

  <!--<section class="section-text">
        <h3>PACK-PULLED, PRE-CERTIFIED AUTOGRAPHS</h3>
        <p>SGC will grade a pack-pulled, pre-certified autographed card (post-2000).</p>
        <p>
            As of April 1st, 2019, SGC is no longer accepting submissions for autograph authentication. If you have
            Any questions, please contact Customer Support at <a href="mailto:support@gosgc.com">support@gosgc.com</a>.
        </p>
    </section>
    <section class="section-text">
        <h3>Cards SGC does not grade</h3>
        <p>
            SGC can grade most items and issues that measure 7" x 9" or smaller. SGC will grade most of the issues
            catalogued in
            various publications. The following is a list of a few issues that SGC does not grade. Since many new and
            uncatalogued items exist, this list will occasionally be updated.
        </p>
        <p><a routerLink="/card-grading/cards-not-graded" class="button">View Do Not Grade List</a></p>
        <p>
            If you have an unusual piece that does not appear on this list, and you'd like to check to be sure we grade
            your card, feel free to contact Peter Steinberg @ <a href="mailto:peter@gosgc.com">peter@gosgc.com</a>.
        </p>

    </section>-->
  <!--<section id="return">
        <div class="grid-list">
            <h3>SGC SHIPPING, HANDLING AND INSURANCE RATES (CARDS AND OTHER PRINTED ITEMS)</h3>
            <h3>Standard Size Items via USPS Priority Mail and Fedex Ground</h3>
            <div class="grid-list__note">
                <h4>1-5 Standard Size Item(s)</h4>
                <p>USPS <$2,000: <strong>$15.00</strong></p>
                <p>FedEx Ground or USPS <$10,000: <strong>$25.00</strong></p>
            </div>
            <div class="grid-list__note">
                <h4>6-20 Standard Size Items</h4>
                <p>USPS <$2,000: <strong>$25.00</strong></p>
                <p>FedEx Ground or USPS <$10,000: <strong>$30.00</strong></p>
            </div>
            <div class="grid-list__note">
                <h4>21-30 Standard Size Items</h4>
                <p>USPS <$2,000: <strong>$30.00</strong></p>
                <p>FedEx Ground or USPS <$10,000: <strong>$35.00</strong></p>
            </div>
            <p class="grid-list__note bulletDisplay"><em class="bulletList"></em> For each item over 30, add $0.50 per item.</p>
            <h3>Standard Size Items via Fedex 2-Day and Standard Overnight</h3>
            <div class="grid-list__note">
                <h4>1-11 Standard Size Item(s)</h4>
                <p>FedEx 2-Day: <strong>$45.00</strong></p>
                <p>FedEx Standard Overnight: <strong>$60.00</strong></p>
            </div>
            <div class="grid-list__note">
                <h4>12-25 Standard Size Items</h4>
                <p>FedEx 2-Day: <strong>$60.00</strong></p>
                <p>FedEx Standard Overnight: <strong>$80.00</strong></p>
            </div>
            <div class="grid-list__note">
                <h4>26-50 Standard Size Items</h4>
                <p>FedEx 2-Day: <strong>$75.00</strong></p>
                <p>FedEx Standard Overnight: <strong>$100.00</strong></p>
            </div>
            <p class="grid-list__note bulletDisplay"><em class="bulletList"></em> For Fedex Standard Overnight, each item over 50, add $1.75 per item.</p>
            <div class="grid-list__note">
                <h4>51-75 Standard Size Items</h4>
                <p>FedEx 2-Day: <strong>$90.00</strong></p>
            </div>
            <div class="grid-list__note">
                <h4>76-100 Standard Size Items</h4>
                <p>FedEx 2-Day: <strong>$110.00</strong></p>
            </div>
            <p class="grid-list__note bulletDisplay"><em class="bulletList"></em> For Fedex 2-Day, each item over 100, add $0.50 per item.</p>
            <h3>Oversized and Tall Boy Items</h3>
            <div class="grid-list__note">
                <h4>USPS <10,000</h4>
                <p>1-5 Item(s): <strong>$30.00</strong></p>
                <p>6-20 Item(s): <strong>$40.00</strong></p>
                <p>21-30 Item(s): <strong>$50.00</strong></p>
                <p class="grid-list__note bulletDisplay"><em class="bulletList"></em> For each item over 30, add $1.00 per item.</p>
            </div>
            <div class="grid-list__note">
                <h4>FedEx Ground</h4>
                <p>1-5 Item(s): <strong>$30.00</strong></p>
                <p>6-15 Item(s): <strong>$40.00</strong></p>
                <p>16-20 Item(s): <strong>$50.00</strong></p>
                <p class="grid-list__note bulletDisplay"><em class="bulletList"></em> For each item over 20, add $1.00 per item.</p>
            </div>
            <div class="grid-list__note">
                <h4>21-30 Standard Size Item(s)</h4>
                <p>USPS <$2,000: <strong>$30.00</strong></p>
                <p>FedEx Ground or USPS <$10,000: <strong>$35.00</strong></p>
            </div>
            <p class="grid-list__note bulletDisplay"><em class="bulletList"></em> For each item over 30, add $0.50 per item.</p>
            <h3>Domestic Shipping - Additional Info</h3>
            <p class="grid-list__note bulletDisplay"><em class="bulletList"></em> For packages valued between $25,000 and $250,000, a $40 surcharge is added.</p>
            <p class="grid-list__note bulletDisplay"><em class="bulletList"></em> For packages valued at $250,000 or over, call (800) 742-9212 to coordinate special shipping arrangement and pricing.</p>
            <p class="grid-list__note bulletDisplay"><em class="bulletList"></em> All USPS packages are sent Priority Mail.</p>
            <p class="grid-list__note bulletDisplay"><em class="bulletList"></em> FedEx Ground shipping times vary based on region.</p>
            <p class="grid-list__note bulletDisplay"><em class="bulletList"></em> FedEx Prices include residential delivery.</p>
            <p class="grid-list__note bulletDisplay"><em class="bulletList"></em> All packages are sent with signature confirmation.</p>

            <h3>International Ground Orders</h3>
            <p class="grid-list__note">International Ground rates apply to Canada and Mexico only.</p>
            <div class="grid-list__note">
                <h4>FedEx International Ground: Base Rates</h4>
                <p>1-5 item(s): <strong>$45.00</strong></p>
                <p>6-10 Items: <strong>$50.00</strong></p>
                <p>11-25 Items: <strong>$60.00</strong></p>
                <p>26-50 Items: <strong>$80.00</strong></p>
                <p>51-100 Items: <strong>$150.00</strong></p>
                <p class="grid-list__note bulletDisplay"><em class="bulletList"></em> For each item over 100, add $2.00 per item.</p>
            </div>
            <h3>International Air Orders</h3>
            <div class="grid-list__note">
                <h4>FedEx International Economy Air: Base Rates</h4>
                <p>1-10 Item(s): <strong>$60.00</strong></p>
                <p>11-25 Item(s): <strong>$80.00</strong></p>
                <p>26-50 Item(s): <strong>$120.00</strong></p>
                <p class="grid-list__note bulletDisplay"><em class="bulletList"></em> For each item over 50, add $2.50 per item.</p>
            </div>
            <div class="grid-list__note">
                <h4>FedEx International Priority Air: Base Rates</h4>
                <p>1-10 Item(s): <strong>$80.00</strong></p>
                <p>11-25 Item(s): <strong>$110.00</strong></p>
                <p>26-50 Item(s): <strong>$150.00</strong></p>
                <p class="grid-list__note bulletDisplay"><em class="bulletList"></em> For each item over 50, add $3.00 per item.</p>
            </div>
            <h3>International Shipping - Additional Info</h3>
            <p class="grid-list__note bulletDisplay"><em class="bulletList"></em> For packages valued between $25,000 and $250,000, a $$60 surcharge is added for International shipments.</p>
            <p class="grid-list__note bulletDisplay"><em class="bulletList"></em> For packages valued at $250,000 or over, call (800) 742-9212 to coordinate special shipping arrangement and pricing.</p>
            <p class="grid-list__note bulletDisplay"><em class="bulletList"></em> Oversize and Tall Boy shipments and International shipments may be subject to an upcharge based on final shipping weight.</p>
            <p class="grid-list__note bulletDisplay"><em class="bulletList"></em> FedEx Prices include residential delivery.</p>
            <p class="grid-list__note bulletDisplay"><em class="bulletList"></em> All packages are sent with signature confirmation.</p>
            <p class="grid-list__note bulletDisplay"><em class="bulletList"></em> Shipping charges to Asia may be subject to additional upcharges based on volitility in carrier prices.</p>

        </div>
    </section>-->
</div>
