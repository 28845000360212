import { Component, OnInit, ViewChild } from '@angular/core';
import {
  GridComponent,
  FilterSettingsModel,
  IFilter,
  TextWrapSettingsModel,
  PageSettingsModel,
} from '@syncfusion/ej2-angular-grids';
import { ActivatedRoute } from '@angular/router';
import { OrderService } from '../../../orders/services/order.service';
import { Router } from '@angular/router';
import { PayPalOrderForCustomer } from '../../../orders/models/order';
import { User } from '../../../common/models/user';
import { DataUtil } from '@syncfusion/ej2-data';
import { UserService } from '../../../common/services/user.service';
import { StateService } from '../../../common/services/state.service';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';

@Component({
  selector: 'app-pay-pal-payment',
  templateUrl: './pay-pal-payment.component.html',
  styleUrls: [
    './pay-pal-payment.component.css',
    '../../dashboard.component.css',
  ],
})
export class PayPalPaymentComponent implements OnInit {
  constructor(
    private orderService: OrderService,
    private route: ActivatedRoute,
    private userService: UserService,
    private stateService: StateService
  ) {}

  ispayPalOrderForCustomer: boolean = false;
  payPalOrderForCustomer!: PayPalOrderForCustomer[];
  currentUser = new User();

  @ViewChild('grid')
  public grid!: GridComponent;
  public gridColumns!: Object[];
  public format = { type: 'date', format: 'MM-dd-yy' };
  public FilterOptions: FilterSettingsModel = {
    type: 'Menu',
  };
  public filter: IFilter = {
    params: {
      format: 'yyyy-MM-dd',
    },
  };

  public pageSettings!: PageSettingsModel;
  public wrapSettings!: TextWrapSettingsModel;
  public toolbar!: string[];

  ngOnInit() {
    this.toolbar = ['CsvExport'];
    this.pageSettings = { pageSize: 20 };
    this.wrapSettings = { wrapMode: 'Both' };
    this.stateService.currentUser.changes.subscribe((user) => {
      this.currentUser = user;
    });
    this.ispayPalOrderForCustomer = true;
    this.orderService
      .GetPayPalOrderForCustomer(this.currentUser.customerId)
      .subscribe((response) => {
        for (let o of response) {
          if (o.status == 'Draft' && o.payPalStatus == 'created')
            o.message = 'To send order, click here for instructions';
          if (o.transactionDate == '0001-01-01T00:00:00Z')
            o.transactionDate = '';
          if (o.payPalStatus == 'created')
            o.payPalStatus = 'Denied By Bank/CC or Cancelled By User';
          else if (o.payPalStatus == 'approved') o.payPalStatus = 'Approval';
          else if (o.payPalStatus == 'Failed') o.payPalStatus = 'Denied';
        }
        if (DataUtil.parse && DataUtil.parse.parseJson) {
          this.payPalOrderForCustomer = DataUtil.parse.parseJson(response);
        }

        this.ispayPalOrderForCustomer = false;
      });
  }

  toolbarClick(args: ClickEventArgs): void {
    if (args.item.text == 'CSV Export') {
      // store the columns in Grid
      this.gridColumns = this.grid.getColumns();
      this.grid.csvExport().then((e) => {
        //after eporting replace the grid columns with stored columns
        this.grid.columns = this.gridColumns;
        this.grid.refreshColumns();
      });
    }
  }
}
