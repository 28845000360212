<div class="row_ m-0 p-4 w-100">
    <div class="col-12">
        <nav class="navbar ">
            <div class="sub-menu">
                <input type="checkbox" id="show-menu" role="button">
                <label for="show-menu" class="show-menu"></label>
                <ul class=" menu" id="menu">
                    <li>
                        <a class="menu__item " routerLink="/account/dashboard"><img src="./assets/img/dashboard/subm.png" alt="" width="15" /> Dashboard</a>
                    </li>
                    <li>
                        <a class="menu__item active" routerLink="/account/myorder"><img src="./assets/img/dashboard/order.png" alt="" width="15" /> My Orders</a>
                    </li>
                    <li>
                        <a class="menu__item " routerLink="/account/sellerready"><img src="./assets/img/dashboard/seller.png" alt="" width="15" /> SELLER READY</a>
                    </li>
                    <li>
                        <a class="menu__item" routerLink="/account/receipts"><img src="../../../../assets/img/dashboard/Dollar Icon - Black - S.png" alt="" width="15" />Receipts</a>
                    </li>
                    <li>
                        <a class="menu__item " routerLink="/account/needhelp"><img src="./assets/img/dashboard/helpp.png" alt="" width="15" /> I Need Help</a>
                    </li>
                    <li>
                        <a class="menu__item " routerLink="/account"><img src="./assets/img/dashboard/user.png" alt="" width="15" />My Profile</a>
                    </li>
                    <!--<li>
                        <a class="menu__item" routerLink="/account/my-cards"><img src="./assets/img/dashboard/seller.png" alt="" width="15" />My Cards</a>
                    </li>-->
                    <!--<li>
                        <a class="menu__item" routerLink="/account/my-cards-download"><img src="./assets/img/dashboard/seller.png" alt="" width="15" />Card Downloads</a>
                    </li>-->
                </ul>

            </div>
        </nav>
    </div>
</div>
<div class="wrapper myorder">
    <h2>My Orders</h2>
    <div class="user-links mt-4 p-4">


        <div *ngIf="isSubmittedOrderForCustomer && !isAllSubmittedOrderForCustomer">

            <input type="checkbox" id="title1" />
            <label for="title1"><h3>MY SUBMISSIONS <a style="float: right;text-decoration: none;color: #785aff;" (click)="submittedOrderViewAll('submitViewAll')"><small>View All</small></a></h3></label>

            <div class="accordion_content custom-grid-styles">
                <ejs-grid #gridSub [dataSource]='submittedOrderForCustomer' gridLines="both" allowPaging="true" [allowSorting]='true'
                          [allowFiltering]="true" [pageSettings]="pageSettings" [filterSettings]='FilterOptions'
                          [allowResizing]='true' allowTextWrap='true' [textWrapSettings]='wrapSettings'>
                    <e-columns>
                        <e-column field='orderNumber' headerText='Order #' type='number' width='90' textAlign="center">
                            <ng-template #template let-submittedOrderForCustomer>
                                <a style="text-decoration-line: none;cursor: pointer;color:#785aff;" (click)="goToInvoice(submittedOrderForCustomer.id)">{{submittedOrderForCustomer.orderNumber}}</a>
                            </ng-template>
                        </e-column>
                        <e-column field='totalItems' [allowFiltering]="false" headerText='# Cards' type='string' textAlign='center' width=70></e-column>
                        <e-column field='declareVale' [allowSorting]='false' [allowFiltering]="false" type='number' format='C2' headerText='Declared Value' textAlign='center' width=70></e-column>
                        <e-column field='submittedDate' headerText='Submitted' type="date" [format]="format" [filter]='filter' textAlign="center" width=90></e-column>
                        <e-column field='receivedDate' headerText='Received' type="date" [format]="format" [filter]='filter' textAlign="center" width=90></e-column>
                        <e-column field='gradedDate' headerText='Graded' type="date" [format]="format" [filter]='filter' textAlign="center" width=90></e-column>
                        <e-column field='status' [allowSorting]='false' headerText='Status' width='70' textAlign="center"></e-column>
                    </e-columns>
                </ejs-grid>
            </div>
        </div>
        <div *ngIf="!isSubmittedOrderForCustomer && isAllSubmittedOrderForCustomer">

            <input type="checkbox" id="title11" />
            <label for="title11"><h3>MY SUBMISSIONS <a class="custom-font"
                style="float: right;text-decoration: none;color: #785aff;" (click)="submittedOrderViewAll('submitViewPage')"><small>View With Paging</small></a></h3></label>

            <div class="accordion_content custom-grid-styles">
                <ejs-grid #gridSubAll [dataSource]='submittedOrderForCustomer' gridLines="both" [allowSorting]='true'
                          [allowFiltering]="true" [filterSettings]='FilterOptions'
                          [allowResizing]='true' allowTextWrap='true' [textWrapSettings]='wrapSettings'>
                    <e-columns>
                        <e-column field='orderNumber' headerText='Order #' type='number' width='90' textAlign="center">
                            <ng-template #template let-submittedOrderForCustomer>
                                <a style="text-decoration-line: none;cursor: pointer;color:#785aff;" (click)="goToInvoice(submittedOrderForCustomer.id)">{{submittedOrderForCustomer.orderNumber}}</a>
                            </ng-template>
                        </e-column>
                        <e-column field='totalItems' [allowFiltering]="false" headerText='# Cards' type='string' textAlign='center' width=70></e-column>
                        <e-column field='declareVale' [allowSorting]='false' [allowFiltering]="false" type='number' format='C2' headerText='Declared Value' textAlign='center' width=70></e-column>
                        <e-column field='submittedDate' headerText='Submitted' type="date" [format]="format" [filter]='filter' textAlign="center" width=90></e-column>
                        <e-column field='receivedDate' headerText='Received' type="date" [format]="format" [filter]='filter' textAlign="center" width=90></e-column>
                        <e-column field='gradedDate' headerText='Graded' type="date" [format]="format" [filter]='filter' textAlign="center" width=90></e-column>
                        <e-column field='status' [allowSorting]='false' headerText='Status' width='70' textAlign="center"></e-column>
                    </e-columns>
                </ejs-grid>
            </div>
        </div>

        <div *ngIf="isCompletedOrderForCustomer && !isAllCompletedOrderForCustomer">

            <input type="checkbox" id="title3" />
            <label for="title3"><h3>Completed Orders <a style="float: right;text-decoration: none;color: #785aff;" (click)="completedOrderViewAll('completedViewAll')"><small>View All</small></a></h3> </label>

            <div class="accordion_content custom-grid-styles">
                <ejs-grid #gridComp [dataSource]='completedOrderForCustomer' gridLines="both" allowPaging="true" [allowSorting]='true'
                          [allowFiltering]="true" [pageSettings]="pageSettings" [filterSettings]='FilterOptions'
                          [allowResizing]='true' allowTextWrap='true' [textWrapSettings]='wrapSettings'>
                    <e-columns>
                        <e-column field='orderNumber' headerText='Order #' type='number' width='90' textAlign="center">
                            <ng-template #template let-completedOrderForCustomer>
                                <a style="text-decoration-line: none;cursor: pointer;color:#785aff;" (click)="goToInvoice(completedOrderForCustomer.id)">{{completedOrderForCustomer.orderNumber}}</a>
                            </ng-template>
                        </e-column>
                        <e-column field='totalItems' [allowFiltering]="false" headerText='# Cards' type='string' textAlign='center' width=70></e-column>
                        <e-column field='declareVale' [allowSorting]='false' [allowFiltering]="false" type='number' format='C2' headerText='Declared Value' textAlign='center' width=70></e-column>
                        <e-column field='shippedDate' headerText='Shipped' type="date" [format]="format" [filter]='filter' textAlign="center" width=90></e-column>
                        <e-column field='shippingMethod' headerText='Service' textAlign="center" width=90></e-column>
                        <e-column field='trackingNumber' headerText='Tracking #' textAlign="center" width=90>
                            <ng-template #template let-completedOrderForCustomer>
                                <a style="text-decoration-line: none;cursor: pointer;" target="_blank" href="{{completedOrderForCustomer.shippingTrackingUrl}}">{{completedOrderForCustomer.trackingNumber}}</a>
                            </ng-template>
                        </e-column>
                    </e-columns>
                </ejs-grid>
            </div>
        </div>
        <div *ngIf="!isCompletedOrderForCustomer && isAllCompletedOrderForCustomer">

            <input type="checkbox" id="title31" />
            <label for="title31"><h3>Completed Orders <a class="custom-font"
                style="float: right;text-decoration: none;color: #785aff;" (click)="completedOrderViewAll('completedViewPage')"><small>View with Paging</small></a></h3></label>

            <div class="accordion_content custom-grid-styles">
                <ejs-grid #gridCompAll [dataSource]='completedOrderForCustomer' gridLines="both" [allowSorting]='true'
                          [allowFiltering]="true" [filterSettings]='FilterOptions'
                          [allowResizing]='true' allowTextWrap='true' [textWrapSettings]='wrapSettings'>
                    <e-columns>
                        <e-column field='orderNumber' headerText='Order #' type='number' width='90' textAlign="center">
                            <ng-template #template let-completedOrderForCustomer>
                                <a style="text-decoration-line: none;cursor: pointer;color:#785aff;" (click)="goToInvoice(completedOrderForCustomer.id)">{{completedOrderForCustomer.orderNumber}}</a>
                            </ng-template>
                        </e-column>
                        <e-column field='totalItems' [allowFiltering]="false" headerText='# Cards' type='string' textAlign='center' width=70></e-column>
                        <e-column field='declareVale' [allowSorting]='false' [allowFiltering]="false" type='number' format='C2' headerText='Declared Value' textAlign='center' width=70></e-column>
                        <e-column field='shippedDate' headerText='Shipped' type="date" [format]="format" [filter]='filter' textAlign="center" width=90></e-column>
                        <e-column field='shippingMethod' headerText='Service' textAlign="center" width=90></e-column>
                        <e-column field='trackingNumber' headerText='Tracking #' textAlign="center" width=90>
                            <ng-template #template let-completedOrderForCustomer>
                                <a style="text-decoration-line: none;cursor: pointer;" target="_blank" href="{{completedOrderForCustomer.shippingTrackingUrl}}">{{completedOrderForCustomer.trackingNumber}}</a>
                            </ng-template>
                        </e-column>
                    </e-columns>
                </ejs-grid>
            </div>
        </div>

        <div *ngIf="isDraftOrderForCustomer && !isAllDraftOrderForCustomer">

            <input type="checkbox" id="title2" />
            <label for="title2"><h3>Draft Orders <a style="float: right;text-decoration: none;color: #785aff;" (click)="draftOrderViewAll('draftViewAll')"><small>View All</small></a></h3></label>

            <div class="accordion_content custom-grid-styles">
                <ejs-grid #gridDraf [dataSource]='draftOrderForCustomer' gridLines="both" allowPaging="true" [allowSorting]='true'
                          [allowFiltering]="true" [pageSettings]="pageSettings" [filterSettings]='FilterOptions'
                          [allowResizing]='true' allowTextWrap='true' [textWrapSettings]='wrapSettings'>
                    <e-columns>
                        <e-column field='orderNumber' headerText='Order #' type='number' width='90' textAlign="center">
                            <ng-template #template let-draftOrderForCustomer>
                                <a style="text-decoration-line: none;cursor: pointer;color:#785aff;" (click)="checkoutOrder(draftOrderForCustomer.id)">{{draftOrderForCustomer.orderNumber}}</a>
                            </ng-template>
                        </e-column>
                        <e-column field='totalItems' [allowFiltering]="false" headerText='# Cards' type='string' textAlign='center' width=70></e-column>
                        <e-column field='declareVale' [allowSorting]='false' [allowFiltering]="false" type='number' format='C2' headerText='Declared Value' textAlign='center' width=70></e-column>
                        <e-column field='lastModifiedOn' headerText='Last Modified' type="date" [format]="format" [filter]='filter' textAlign="center" width=90></e-column>
                        <e-column field='payPalStatus' [allowSorting]='false' headerText='PayPal Status' width='70' textAlign="center"></e-column>
                    </e-columns>
                </ejs-grid>
            </div>
        </div>
        <div *ngIf="!isDraftOrderForCustomer && isAllDraftOrderForCustomer">
            <div>
                <input type="checkbox" id="title21" />
                <label for="title21"><h3>Draft Orders <a class="custom-font"
                     style="float: right;text-decoration: none;color: #785aff;" (click)="draftOrderViewAll('draftViewPage')"><small>View With Paging</small></a></h3></label>

            </div>
            <div class="accordion_content custom-grid-styles">
                <ejs-grid #gridDrafAll [dataSource]='draftOrderForCustomer' gridLines="both" [allowSorting]='true'
                          [allowFiltering]="true" [filterSettings]='FilterOptions'
                          [allowResizing]='true' allowTextWrap='true' [textWrapSettings]='wrapSettings'>
                    <e-columns>
                        <e-column field='orderNumber' headerText='Order #' type='number' width='90' textAlign="center">
                            <ng-template #template let-draftOrderForCustomer>
                                <a style="text-decoration-line: none;cursor: pointer;color:#785aff;" (click)="checkoutOrder(draftOrderForCustomer.id)">{{draftOrderForCustomer.orderNumber}}</a>
                            </ng-template>
                        </e-column>
                        <e-column field='totalItems' [allowFiltering]="false" headerText='# Cards' type='string' textAlign='center' width=70></e-column>
                        <e-column field='declareVale' [allowSorting]='false' [allowFiltering]="false" type='number' format='C2' headerText='Declared Value' textAlign='center' width=70></e-column>
                        <e-column field='lastModifiedOn' headerText='Last Modified' type="date" [format]="format" [filter]='filter' textAlign="center" width=90></e-column>
                        <e-column field='payPalStatus' [allowSorting]='false' headerText='PayPal Status' width='70' textAlign="center"></e-column>
                    </e-columns>
                </ejs-grid>
            </div>
        </div>

    </div>
</div>

<style>
    @media screen and (max-width: 400px) {
  .custom-font {
    font-size: 14px;
  }
}
</style>
