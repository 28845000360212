<div class="modal__content">
  <div class="modal__list">
    <input
      type="text"
      class="input"
      placeholder="{{ options.label }}"
      [(ngModel)]="options.user.email"
      *ngIf="isEditing('email')"
    />
    <input
      type="text"
      class="input"
      placeholder="{{ options.label }}"
      [(ngModel)]="options.user.phoneNumber"
      *ngIf="isEditing('phoneNumber')"
    />
    <input
      type="text"
      class="input"
      placeholder="{{ options.label }}"
      [(ngModel)]="options.user.name"
      *ngIf="isEditing('name')"
    />
    <input
      type="text"
      class="input"
      placeholder="{{ options.label }}"
      [(ngModel)]="options.user.preferences.currentFedexAccount"
      *ngIf="isEditing('fedexAccount')"
    />
    <input
      type="text"
      class="input"
      placeholder="{{ options.label }}"
      [(ngModel)]="options.user.preferences.fedexAccountPostalCode"
      *ngIf="isEditing('fedexAccountPostalCode')"
    />
  </div>
</div>
<div class="modal__actions">
  <button class="button button--secondary" (click)="close()">Cancel</button>
  <button
    class="button"
    (click)="save()"
    [disabled]="isSubmitting"
    [class.button--disabled]="isSubmitting"
  >
    <div *ngIf="isSubmitting" class="loader xs invert-color"></div>
    <span *ngIf="!isSubmitting">Save</span>
  </button>
</div>
