<img style="top:10px" src="/assets/img/close-modal.svg" class="modal__close" (click)="close()">

    <div class="new-site__modal">
        <p style="font-size:18px;text-align:left;white-space:inherit;">
            Check here to indicate that you have read and agree to the <a routerLink="/terms" target="_blank" class="footer__menu-item" style="color:#785aff;">SGC Terms
            and Conditions</a>.<!--If you are submitting a SGC 30 Business
            Day Guaranteed Order Terms and Conditions, checking here indicates
            that you have also read and agree to <a routerLink="/businessday-guarantee/terms-condition" target="_blank" class="footer__menu-item" style="color:#785aff;">SGC 30 Business Day
            Guaranteed Order Terms and Conditions</a>.-->
        </p>
        <label class="toggle">
            I agree
            <input type="checkbox" [(ngModel)]="termsAccept" (ngModelChange)="termsaccepted($event)">
            <span class="toggle__check_2"></span>
        </label>
    </div>
