
<div class="container" style="font-size: 130%; ">
    <h4 style="text-align: center;">Report An Issue</h4>

    <form id="form" [formGroup]="form">
        <div class="row">
            <div class="col-27">
            </div>
            <div class="col-46" style="text-align: center;">
                <div *ngIf="check === 'regular'">
                    <h5>{{crdSet}}  {{crdno}}  {{subj}}  {{desc}} / {{sport}}</h5>
                </div>
                <div *ngIf="check === 'T205 Master Pop'">
                    <h5>{{sport}}</h5>
                </div>
                <div *ngIf="check ==='T206 Master Pop'">
                    <h5>{{sport}}</h5>
                </div>
            </div>
            <div class="col-27">
            </div>
        </div>
        <div class="row">
            <div class="col-27">
            </div>
            <div class="col-15">
                <label for="typeissue">Type of Issue<span style="color:red;">*</span></label>
            </div>
            <div class="col-31">
                <select id="typeissue" name="typeissue" formControlName="typeofissue" class="required" required style="width: 100%;margin-bottom: 20px;padding: 12px;border: 1px solid #ccc;border-radius: 3px;">
                    <option value="">Please Select Type of Issue</option>
                    <option value="Card Number is not correct">Card Number is not correct</option>
                    <option value="Card Subject is not correct">Card Subject is not correct</option>
                    <option value="Card Description is not correct">Card Description is not correct</option>
                    <option value="This card needs to be merged with another card in the set">This card needs to be merged with another card in the set</option>
                    <option value="My card doesn't appear to be on the POP. Why?">My card doesn't appear to be on the POP. Why?</option>
                    <option value="I had this card and it was cracked out">I had this card and it was cracked out</option>
                    <option value="General Questions">General Questions</option>
                </select>

            </div>
            <div class="col-27">
                <div *ngIf="form.controls['typeofissue'].touched && !form.controls['typeofissue'].valid" style="color: red;margin-top: 0px;">
                    <p *ngIf="form.controls['typeofissue'].hasError('required')" class="text-danger">
                        * Please select Type of Issue
                    </p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-27">
            </div>
            <div class="col-15">
                <label for="firstname">Your Name<span style="color:red;">*</span></label>
            </div>
            <div class="col-31">
                <input type="text" id="firstname" name="firstname" class="required" formControlName="firstname" required>
            </div>
            <div class="col-27">
                <div *ngIf="form.controls['firstname'].touched && !form.controls['firstname'].valid" style="color: red;margin-top: 0px;">
                    <p *ngIf="form.controls['firstname'].hasError('required')" class="text-danger">
                        * Please enter Your Name
                    </p>
                    <p *ngIf="form.controls['firstname'].hasError('maxlength')" class="text-danger">
                        * Max Length is 100
                    </p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-27">
            </div>
            <div class="col-15">
                <label for="email">Your Email (Optional)</label>
            </div>
            <div class="col-31">
                <input type="text" id="email" name="email" formControlName="email">
            </div>
            <div class="col-27">
                <div *ngIf="form.controls['email'].touched && !form.controls['email'].valid" style="color: red;margin-top: 0px;">
                    <p *ngIf="form.controls['email'].hasError('pattern')" class="text-danger">
                        * Your email is invalid
                    </p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-27">
            </div>
            <div class="col-15">
                <label for="certificationcode">Certification code (Optional)</label>
            </div>
            <div class="col-31">
                <input type="text" id="certificationcode" name="certificationcode" formControlName="certificationcode">
            </div>
            <div class="col-27">
                <div *ngIf="form.controls['certificationcode'].touched && !form.controls['certificationcode'].valid" style="color: red;margin-top: 0px;">
                    <p *ngIf="form.controls['certificationcode'].hasError('minlength')" class="text-danger">
                        * Min Length is 7
                    </p>
                    <p *ngIf="form.controls['certificationcode'].hasError('maxlength')" class="text-danger">
                        * Max Length is 11
                    </p>
                    <p *ngIf="form.controls['certificationcode'].hasError('pattern')" class="text-danger">
                        * Certification code is invalid
                    </p>
                </div>
            </div>

        </div>
        <div class="row">
            <div class="col-27">
            </div>
            <div class="col-15">
                <label for="mobile">Phone No (Optional)</label>
            </div>
            <div class="col-31">
                <input type="text" id="mobile" name="mobile" formControlName="mobile">
            </div>
            <div class="col-27">
                <div *ngIf="form.controls['mobile'].touched && !form.controls['mobile'].valid" style="color: red;margin-top: 0px;">
                    <p *ngIf="form.controls['mobile'].hasError('maxlength')" class="text-danger">
                        * Max Length is 20
                    </p>
                    <!--<p *ngIf="form.controls['mobile'].hasError('pattern')" class="text-danger">
                        * Phone No is invalid
                    </p>-->
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-27">
            </div>
            <div class="col-15">
                <label for="comments">Comments (Optional)</label>
            </div>
            <div class="col-31">
                <textarea id="comments" name="comments" formControlName="comments" style="height:200px"></textarea>
            </div>
            <div class="col-27">
            </div>
        </div>
        <div class="row">
            <div class="col-27">
            </div>
            <div class="col-15">
            </div>
            <div class="col-31">
                <div class="form-group template" style="text-align: left;">
                    <div class="submitBtn" *ngIf="successmsg===false">
                        <button class="submit-btn e-btn" id="submit-btn" [disabled]="form.invalid" (click)="SubmitEmail()">Submit</button>
                        <button class="submit-btn e-btn" *ngIf="check === 'regular' && page=='popreport'" id="submit-btn" [routerLink]="['/pop-report/result',cardSet,sportcard]">Back to Pop Report</button>
                        <button class="submit-btn e-btn" *ngIf="check === 'regular' && page=='authcode'" id="submit-btn" routerLink="/cert-code-lookup">Back to Cert Code Look-up</button>
                        <button class="submit-btn e-btn" *ngIf="check ==='T205 Master Pop'" id="submit-btn" [routerLink]="['/population-reports/masterpop','T205 Master Pop']">Back to T205 Master Pop</button>
                        <button class="submit-btn e-btn" *ngIf="check ==='T206 Master Pop'" id="submit-btn" [routerLink]="['/population-reports/masterpop','T206 Master Pop']">Back to T206 Master Pop</button>

                    </div>
                    <div *ngIf="successmsg===true">
                        <label style="color: #785aff;font-size: 15px;font-weight: 600;">Message sent successfully.</label>
                        <button class="submit-btn e-btn" *ngIf="check === 'regular' && page=='popreport'" id="submit-btn" [routerLink]="['/pop-report/result',cardSet,sportcard]">Back to Pop Report</button>
                        <button class="submit-btn e-btn" *ngIf="check === 'regular' && page=='authcode'" id="submit-btn" routerLink="/cert-code-lookup">Back to Cert Code Look-up</button>
                        <button class="submit-btn e-btn" *ngIf="check ==='T205 Master Pop'" id="submit-btn" [routerLink]="['/population-reports/masterpop','T205 Master Pop']">Back to T205 Master Pop</button>
                        <button class="submit-btn e-btn" *ngIf="check ==='T206 Master Pop'" id="submit-btn" [routerLink]="['/population-reports/masterpop','T206 Master Pop']">Back to T206 Master Pop</button>
                    </div>
                </div>
            </div>
            <div class="col-27">
            </div>
        </div>
    </form>

</div>




<!--<div class="control-section">
    <div class="col-lg-12">
        <div class="control_wrapper" id="control_wrapper" style="margin: 80px auto;">
            <h4 class="form-title">Report Issue</h4>
            <form id="reactive" [formGroup]="form">

                <div class="form-group template" style="display: inline-block;width:23%;margin-left: 15px;">
                    <div class="e-float-input e-success">
                        <label for="cardnumber">Card Number</label>
                        <span class="e-float-line"></span>
                        <input type="text" id="cardnumber" name="cardnumber" formControlName="cardnumber">
                    </div>
                </div>
                <div class="form-group template" style="display: inline-block;width:35%;margin-left: 15px;">
                    <div class="e-float-input e-success">
                        <input type="text" id="setname" name="setname" formControlName="setname">
                        <span class="e-float-line"></span>
                        <label class="e-float-text" for="setname">Set Name</label>
                    </div>
                </div>
                <div class="form-group template" style="display: inline-block;width:35%;margin-left: 15px;">
                    <div class="e-float-input e-success">
                        <input type="text" id="subject" name="subject" formControlName="subject">
                        <span class="e-float-line"></span>
                        <label class="e-float-text" for="subject">Subject</label>
                    </div>
                </div>

                <div class="form-group template" style="display: inline-block;width:60%;margin-left: 15px;">
                    <div class="e-float-input e-success">
                        <input type="text" id="description" name="description" formControlName="description">
                        <span class="e-float-line"></span>
                        <label class="e-float-text" for="description">Card Description</label>
                    </div>
                </div>
                <div class="form-group template" style="display:inline-block;width:35%;margin-left: 15px;">
                    <label class="e-float-text" for="typeissue">Type of Issue</label>
                    <div class="e-float-input e-success">
                        <ejs-dropdownlist id="typeissue" name="typeissue" [dataSource]='typeofissue' formControlName="typeofissue" class="required" required>
                        </ejs-dropdownlist>
                    </div>
                </div>


                <div class="form-group template" style="display: inline-block;width:35%;margin-left: 15px;">
                    <div class="e-float-input" [ngClass]="{'e-success': form.controls.firstname.valid}">
                        <input type="text" id="firstname" name="firstname" class="required" formControlName="firstname" required>
                        <span class="e-float-line"></span>
                        <label class="e-float-text" for="firstname">Your Name</label>
                    </div>
                    <app-error-display [displayError]="isFieldValid('firstname')" errorMsg="* Enter your first name">
                    </app-error-display>
                </div>
                <div class="form-group template" style="display: inline-block;width:35%;margin-left: 15px;">
                    <div class="e-float-input" [ngClass]="{'e-success': form.controls.email.valid}">
                        <input type="text" id="email" name="email" formControlName="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$">
                        <span class="e-float-line"></span>
                        <label class="e-float-text" for="email">Your Email</label>
                    </div>
                    <app-error-display [displayError]="isFieldValid('email')" errorMsg="* Enter a valid email address">
                    </app-error-display>
                </div>
                <div class="form-group template" style="display: inline-block;width:23%;margin-left: 15px;">
                    <div class="e-float-input" [ngClass]="{'e-success': form.controls.certificationcode.valid}">
                        <input type="text" id="certificationcode" name="certificationcode" formControlName="certificationcode" [pattern]="certificationCodePattern" minlength="7" maxlength="11">
                        <span class="e-float-line"></span>
                        <label class="e-float-text" for="certificationcode">Certification code (Optional)</label>
                    </div>
                    <app-error-display [displayError]="isFieldValid('certificationcode')" errorMsg="* Enter a valid Certification code">
                    </app-error-display>
                </div>

                <div class="form-group template" style="display: inline-block;width:35%;margin-left: 15px;">
                    <div id="mobile-no" class="e-float-input" [ngClass]="{'e-success': form.controls.mobile.valid}">
                        <input type="number" id="mobileno" name="mobile" formControlName="mobile">
                        <span class="e-float-line"></span>
                        <label class="e-float-text" for="mobile">Phone No (Optional)</label>
                    </div>
                    <app-error-display [displayError]="isFieldValid('mobile')" errorMsg="* Enter a valid phone number">
                    </app-error-display>
                </div>
                <div class="form-group template" style="display: inline-block;width:60%;margin-left: 15px;">
                    <div id="comments" class="e-float-input" [ngClass]="{'e-success': form.controls.comments.valid}">
                        <textarea type="text" id="comments" name="comments" formControlName="comments"></textarea>
                        <span class="e-float-line"></span>
                        <label class="e-float-text" for="comments">Comments (Optional)</label>
                    </div>
                    <app-error-display [displayError]="isFieldValid('mobile')" errorMsg="* Enter a valid phone number">
                    </app-error-display>
                </div>

                <div class="form-group template">
                    <div class="submitBtn">
                        <button class="submit-btn e-btn" id="submit-btn" [disabled]="form.invalid" (click)="Submit()">Submit</button>
                        <div class="desc"><span>*This button is not a submit type and the form submit handled from externally.</span></div>
                    </div>
                </div>
            </form>
            <ejs-dialog id="confirmationDialog" #Dialog [buttons]='dlgButtons' [animationSettings]='animationSettings' [header]='formHeader' [showCloseIcon]='showCloseIcon' [content]='content' [target]='target' [width]='width' [visible]="visible" [isModal]="isModal">
            </ejs-dialog>
        </div>
    </div>
</div>-->
