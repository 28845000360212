import { Component, OnInit, ViewChild } from '@angular/core';
import {
  GridComponent,
  FilterSettingsModel,
  IFilter,
  TextWrapSettingsModel,
  PageSettingsModel,
  DataStateChangeEventArgs,
  DataResult,
} from '@syncfusion/ej2-angular-grids';
import { ActivatedRoute } from '@angular/router';
import { OrderService } from '../../../orders/services/order.service';
import { Router } from '@angular/router';
import { CheckDownlaodCardOrderItems } from '../../../orders/models/order';
import { User } from '../../../common/models/user';
import { DataUtil } from '@syncfusion/ej2-data';
import { UserService } from '../../../common/services/user.service';
import { StateService } from '../../../common/services/state.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-mycards-download',
  templateUrl: './mycards-download.component.html',
  styleUrls: [
    './mycards-download.component.css',
    '../../dashboard.component.css',
  ],
})
export class MycardsDownloadComponent implements OnInit {
  constructor(
    private orderService: OrderService,
    private router: Router,
    private userService: UserService,
    private stateService: StateService
  ) {}

  currentUser = new User();
  checkDownlaodCardOrderItems!: CheckDownlaodCardOrderItems[];

  public format = { type: 'date', format: 'dd/MM/yyyy hh:mm:ss a' };
  public FilterOptions: FilterSettingsModel = {
    type: 'Menu',
  };
  public filter: IFilter = {
    params: {
      format: 'dd/MM/yyyy-hh:mm',
    },
  };
  public pageSettings!: PageSettingsModel;
  public wrapSettings!: TextWrapSettingsModel;

  ngOnInit() {
    this.pageSettings = { pageSize: 10, pageCount: 4 };
    this.wrapSettings = { wrapMode: 'Both' };
    this.stateService.currentUser.changes.subscribe((user) => {
      this.currentUser = user;
    });
    this.orderService
      .GetStatusForDownloadCards(this.currentUser.id)
      .subscribe((response) => {
        
        this.checkDownlaodCardOrderItems = response;

        for (let item of this.checkDownlaodCardOrderItems) {
          if (item.zipRequestState == 3) {
            item.zipRequestStateStatus = 'Available For Download';
          } else {
            item.zipRequestStateStatus = 'Will Be Ready Soon';
          }

          if (item.zipGeneratedOn == '0001-01-01T00:00:00') {
            item.zipGeneratedOn = '';
          }
        }
      });
  }

  download(zipUrl: any) {
    const blob = new Blob([zipUrl], {
      type: 'application/zip',
    });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }
}
