import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { Card, Sports } from '../models/catalog';

@Injectable()
export class CatalogService {

    constructor(
        private apiService: ApiService
    ){}

    getListOfSports(): Array<any> {
        return Sports;
    }
}