<div class="checkout__content">
  <div class="invoice__section" style="margin-top: 30px">
    <div style="margin-left: 50px">
      <h2 *ngIf="isLoading">STEP 7. PAYMENT</h2>

      <div id="savedCards" style="visibility: hidden">
        <div
          class="table"
          id="dv-cards"
          *ngIf="showCards"
          style="width: 100%; margin-bottom: 10px; margin-top: 20px"
        >
          <table class="table--8 table--review">
            <thead style="font-size: 2rem">
              <tr class="table__header">
                <td>Select</td>
                <td>Type</td>
                <td>Ending</td>
              </tr>
            </thead>
            <tbody id="tcards"></tbody>
          </table>
        </div>
        <button
          class="button button--full"
          id="payWithCard"
          style="margin-top: 10px; width: 235px"
          (click)="paymentWithSavedCard()"
        >
          Pay now
        </button>
        <div style="margin-top: 10px">
          <div
            class="button_plus"
            style="margin-top: 20px"
            (click)="createNewPayment()"
          ></div>
          <p
            style="
              margin-top: 27px;
              margin-left: 45px;
              display: inline-block;
              font-size: 18px;
            "
          >
            Add Credit or Debit Card
          </p>
        </div>
      </div>

      <form id="payment-form" style="width: 500px">
        <div
          id="payment-request-button"
          style="margin-top: 20px; margin-bottom: 20px"
        ></div>
        <div id="payment-element"></div>

        <div *ngIf="isLoading" style="margin-top: 10px"></div>

        <label
          class="toggle"
          style="padding-right: 20px; margin-top: 10px"
          *ngIf="isLoading"
          id="saveCardChkbox"
        >
          Save Card Details
          <input type="checkbox" [checked]="saveCard" />
          <span class="toggle__check toggle__check_2"></span>
        </label>

        <button
          class="button button--full"
          id="submit"
          style="margin-top: 10px; display: none"
          *ngIf="isLoading"
        >
          Pay now
        </button>

        <div id="error-message"></div>
      </form>

      <div
        class=""
        [class.active]="!isLoading"
        *ngIf="!isLoading"
        style="height: 10px; margin-left: 50%; margin-top: 150px"
      >
        <div class="loader m"></div>
      </div>

      <div id="messages" role="alert" style="display: none"></div>
    </div>
  </div>
</div>
