<div class="section-title section-title--population">
    <h2>Auth Code Look-up Questions Form</h2>
</div>
<div class="container" style="font-size: 130%; ">
    <form id="form" [formGroup]="form">
        <div class="row">
            <div class="col-27">
            </div>
            <div class="col-15">
                <label for="typeissue">Type of Issue<span style="color:red;">*</span></label>
            </div>
            <div class="col-31">
                <select id="typeissue" name="typeissue" formControlName="typeofissue" class="required" required style="width: 100%;margin-bottom: 20px;padding: 12px;border: 1px solid #ccc;border-radius: 3px;">
                    <option value="">Please Select Type of Issue</option>
                    <option value="I need more information on a card slabbed in an earlier green/white SGC label">I need more information on a card slabbed in an earlier green/white SGC label</option>
                    <option value="I have an SGC card in a holder and the cert is coming back an invalid.">I have an SGC card in a holder and the cert is coming back an invalid.</option>
                    <option value="The system is showing a different grade than my label">The system is showing a different grade than my label</option>
                    <option value="I cracked out my SGC card. This cert is no longer valid">I cracked out my SGC card. This cert is no longer valid</option>
                    <option value="Authcode lookup displays wrong image(s)">Authcode lookup displays wrong image(s)</option>
                </select>

            </div>
            <div class="col-27">
                <div *ngIf="form.controls['typeofissue'].touched && !form.controls['typeofissue'].valid" style="color: red;margin-top: 0px;">
                    <p *ngIf="form.controls['typeofissue'].hasError('required')" class="text-danger">
                        * Please select Type of Issue
                    </p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-27">
            </div>
            <div class="col-15">
                <label for="authcode">Auth Code<span style="color:red;">*</span></label>
            </div>
            <div class="col-31">
                <input type="text" id="authcode" name="authcode" formControlName="authcode">
            </div>
            <div class="col-27">
                <div *ngIf="form.controls['authcode'].touched && !form.controls['authcode'].valid" style="color: red;margin-top: 0px;">
                    <p *ngIf="form.controls['authcode'].hasError('minlength')" class="text-danger">
                        * Min Length is 7
                    </p>
                    <p *ngIf="form.controls['authcode'].hasError('maxlength')" class="text-danger">
                        * Max Length is 11
                    </p>
                    <p *ngIf="form.controls['authcode'].hasError('pattern')" class="text-danger">
                        * Invalid format
                    </p>
                    <p *ngIf="form.controls['authcode'].hasError('required')" class="text-danger">
                        * Auth code is required
                    </p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-27">
            </div>
            <div class="col-15">
                <label for="firstname">Your Name<span style="color:red;">*</span></label>
            </div>
            <div class="col-31">
                <input type="text" id="firstname" name="firstname" class="required" formControlName="firstname" required>
            </div>
            <div class="col-27">
                <div *ngIf="form.controls['firstname'].touched && !form.controls['firstname'].valid" style="color: red;margin-top: 0px;">
                    <p *ngIf="form.controls['firstname'].hasError('required')" class="text-danger">
                        * Please enter Your Name
                    </p>
                    <p *ngIf="form.controls['firstname'].hasError('maxlength')" class="text-danger">
                        * Max Length is 100
                    </p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-27">
            </div>
            <div class="col-15">
                <label for="email">Your Email<span style="color:red;">*</span></label>
            </div>
            <div class="col-31">
                <input type="text" id="email" name="email" formControlName="email">
            </div>
            <div class="col-27">
                <div *ngIf="form.controls['email'].touched && !form.controls['email'].valid" style="color: red;margin-top: 0px;">
                    <p *ngIf="form.controls['email'].hasError('pattern')" class="text-danger">
                        * Your email is invalid
                    </p>
                    <p *ngIf="form.controls['email'].hasError('required')" class="text-danger">
                        * Please enter Your Email
                    </p>
                    <p *ngIf="form.controls['email'].hasError('maxlength')" class="text-danger">
                        * Max Length is 100
                    </p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-27">
            </div>
            <div class="col-15">
                <label for="mobile">Phone No (Optional)</label>
            </div>
            <div class="col-31">
                <input type="text" id="mobile" name="mobile" formControlName="mobile">
            </div>
            <div class="col-27">
                <div *ngIf="form.controls['mobile'].touched && !form.controls['mobile'].valid" style="color: red;margin-top: 0px;">
                    <p *ngIf="form.controls['mobile'].hasError('maxlength')" class="text-danger">
                        * Max Length is 20
                    </p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-27">
            </div>
            <div class="col-15">
                <label for="comments">Comments (Optional)</label>
            </div>
            <div class="col-31">
                <textarea id="comments" name="comments" formControlName="comments" style="height:200px"></textarea>
            </div>
            <div class="col-27">
            </div>
        </div>

        <div class="row">
            <div class="col-27">
            </div>
            <div class="col-15">
            </div>
            <div class="col-31">
                <div class="form-group template" style="text-align: left;">
                    <div class="submitBtn" *ngIf="successmsg===false">
                        <button class="submit-btn e-btn" id="submit-btn" [disabled]="form.invalid" (click)="SubmitEmail()">Submit</button>
                        <button class="submit-btn e-btn" routerLink="/cert-code-lookup" id="submit-btn">Back to Cert Code Look-up</button>
                    </div>
                    <div *ngIf="successmsg===true">
                        <label style="color: #785aff;font-size: 15px;font-weight: 600;">Message sent successfully.</label>
                        <button class="submit-btn e-btn" routerLink="/cert-code-lookup" id="submit-btn">Back to Cert Code Look-up</button>
                    </div>
                </div>
            </div>
            <div class="col-27">
            </div>
        </div>
    </form>

</div>
