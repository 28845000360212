<div class="checkout__content checkout promo-banner-adjustment">
  <div class="checkout__columns">
    <h2 class="h2-step2" *ngIf="orderType.getCode() == '22POKEMON'">STEP 2. ADD YOUR TCG CARDS</h2>
    <h2 class="h2-step2" *ngIf="orderType.getCode() != '22POKEMON'">STEP 2. ADD YOUR CARDS</h2>
    <div class="checkout__search">
      <div class="input-search">
        <input
          type="text"
          id="search"
          class="input"
          placeholder="{{ getSearchInputLabel() }}"
          name="search"
          autocomplete="off"
          [formControl]="searchTerm"
        />
        <div
          class="loader search-input-loader"
          [class.active]="isSearching"
          *ngIf="isSearching"
        ></div>
      </div>
    </div>
    
    <div>
      <i>{{ getHintLabelForSearchInput() }}</i>
      <div *ngIf="!isAnyResult() && isAutographAuthentication()">
        <p>
          <a class="link" (click)="createItemWithNoSubject()"
            >I don't know the name of the signer</a
          >
        </p>
        <div class="autograph__warning">
          <p>
            Having trouble with the online Autograph Submission? Click below for
            the PDF version.
          </p>
          <p>
            <a
              class="link"
              href="/assets/docs/AutographPricingInstructions.pdf"
              download
              >Autograph Pricing and Instructions</a
            >
          </p>
          <p>
            <a
              class="link"
              href="/assets/docs/AutographSubmissionForm.pdf"
              download
              >Autograph Submission Form</a
            >
          </p>
          <p>
            For a list of individual Premium Signers, please click
            <a
              class="link"
              href="https://www.gosgc.com/card-grading/individual-signers"
              target="_blank"
              >here</a
            >.
          </p>
        </div>
      </div>
      <p *ngIf="showAddItemWithoutCard()" style="margin-top: 10px;">
        I don't see my card listed.
        <a class="link" (click)="addItemWithoutCard()"
          >Click here to add your card</a
        >
      </p>
    </div>

    <!-- Results -->
    <div class="checkout__results">
      <div class="checkout__results-list" *ngIf="isAnyResult()">
        <div
          *ngFor="let item of searchResults"
          class="fade-in quick loader-container"
        >
          <div
            class="overlay"
            [class.active]="item.isAdding"
            *ngIf="item.isAdding"
          >
            <div class="loader m"></div>
          </div>
          <result-item
            [item]="item"
            [type]="orderType"
            (click)="selectResultItem(item)"
          ></result-item>
        </div>

        <button
          class="button button--more"
          *ngIf="showLoadMore()"
          (click)="loadMore()"
          style="margin-bottom: 10px;"
        >
          View more results
        </button>
      </div>
    </div>

    <!-- Items -->
    <div class="checkout__items" style="margin-bottom: 65px">
      <div>
        <div class="gridborder" *ngIf="hide">
          <div class="nopading" *ngIf="orderType.getCode() == 'card-grading' || orderType.getCode() == '22POKEMON'">
            <ejs-grid #grid
                      [dataSource]="grading()"
                      gridLines="lines"
                      allowTextWrap="true">
              <e-columns>
                <e-column headerText="SERVICES AND PRICING"
                          textAlign="Center"
                          [columns]="StdColumns"></e-column>
              </e-columns>
            </ejs-grid>
          </div>
          <div *ngIf="orderType.getCode() == '23TOPPSCHR'">
            <ejs-grid #grid
                      [dataSource]="grading()"
                      gridLines="lines"
                      allowTextWrap="true">
              <e-columns>
                <e-column headerText="STANDARD (5-10 DAY) TURNAROUND TIME"
                          textAlign="Center"
                          [columns]="StdColumns"></e-column>
              </e-columns>
            </ejs-grid>
          </div>
          <div class="basketball-grid" *ngIf="orderType.getCode() == '24TOPPSS2'">
            <ejs-grid #grid
                      [dataSource]="grading()"
                      gridLines="lines"
                      allowTextWrap="true">
              <e-columns>
                <e-column headerText="SERVICES AND PRICING"
                          textAlign="Center"
                          [columns]="StdColumnsBaseball"></e-column>
              </e-columns>
            </ejs-grid>
          </div>
          <div class="nopading" *ngIf="orderType.getCode() == '23BOWMANEOY'">
            <ejs-grid #grid
                      [dataSource]="grading()"
                      gridLines="lines"
                      allowTextWrap="true">
              <e-columns>
                <e-column headerText="SERVICES AND PRICING"
                          textAlign="Center"
                          [columns]="StdColumns"></e-column>
              </e-columns>
            </ejs-grid>
          </div>
          <div class="basketball-grid" *ngIf="orderType.getCode() == '24BASKETBALL'">
            <ejs-grid #grid
                      [dataSource]="grading()"
                      gridLines="lines"
                      allowTextWrap="true">
              <e-columns>
                <e-column headerText="SERVICES AND PRICING"
                          textAlign="Center"
                          [columns]="StdColumnsBasketball"></e-column>
              </e-columns>
            </ejs-grid>
          </div>
          <h4 class="gridfooter" *ngIf="orderType.getCode() == 'card-grading'">
            RAW CARD GRADING, REVIEWS, & CROSSOVERS DECLARED VALUE <
            $1,500*
          </h4>
          <h4 class="gridfooter" *ngIf="orderType.getCode() == '24BASKETBALL'">
            RAW CARD GRADING
          </h4>
          <h4 class="gridfooter" *ngIf="orderType.getCode() == '24TOPPSS2'">
            RAW CARD GRADING
          </h4>
          <p class="gridfooter2" *ngIf="orderType.getCode() == 'card-grading'">
            *VALUE UPCHARGES DO NOT APPLY FOR CARDS PRODUCED POST-2000
          </p>
          <p class="gridfooter2" *ngIf="orderType.getCode() == '24BASKETBALL'">
            *Applicable for all standard and tall boy size basketball cards. Oversized cards are not eligible for the Basketball Special.
          </p>
          <p class="gridfooter2" *ngIf="orderType.getCode() == '24TOPPSS2'">
            *Applicable for all standard size 2024 Topps Series 2 cards.
          </p>
        </div>
            <div class="showLabel" style="margin-bottom: 20px;">
              <p>
                <a class="link" (click)="Show()">{{ showLabel }}</a>
              </p>
              <!-- <button class="button" (click)="Show()">{{showLabel}}</button> -->
            </div>
      </div>

      
      <h5 *ngIf="orderType.code == '22BOWMANCHR'">
        <span style="text-transform: none"
          >All cards added to your order must be from the 2022 Bowman Chrome
          set. Use the search bar to add additional cards to your order and
          click ‘Next’ when finished.</span
        >
      </h5>
      <h5 *ngIf="orderType.code == '23TOPPSCHR'">
        <span style="text-transform: none"
          >All cards added to your order must be standard size and out of the 2023 Topps Chrome set. Each card must have a declared value entered before proceeding to the next step. Use the search bar to add additional cards to your order and click 'Next' when finished.</span
        >
      </h5>
      <!-- <h5 *ngIf="orderType.code == '24TOPPSS2'">
        <span style="text-transform: none"
          >All cards added to your order must be standard size and out of the 2024 Topps Series 2 Baseball set. Each card must have a declared value entered before proceeding to the next step. Use the search bar to add additional cards to your order and click 'Next' when finished.</span
        >
      </h5> -->
      <!-- Encapsulate all -->
      <div
        *ngIf="orderType.getCode() == 'card-grading'"
        style="display: flex; scroll-padding: 10px"
      >
        <label
          class="toggle"
          *ngIf="getItemsCount() && showEncapsulationOption()"
        >
          Encapsulate all if altered
          <input
            type="checkbox"
            [checked]="order.settings.encapsulationRequested"
            (change)="encapsulationSelected($event)"
          />
          <span class="toggle__check toggle__check_2"></span>
        </label>
      </div>
      <!-- <p *ngIf="showAddItemWithoutCard()"> <a class="link" (click)="addItemWithoutCard()">VIEW PRICING TABLE</a></p> -->

      <!-- Items List -->
      <added-order-item
        *ngFor="
          let item of order.items.slice().reverse(); let i = index
        "
        [item]="item"
        [type]="orderType"
        [options]="options"
        class="fade-in"
        [index]="i + 1"
      >
      </added-order-item>

      <!-- <added-order-item
                *ngFor="
                    let item of order.items
                        | orderBy
                            : orderitemsort
                            : reverse
                            : 'case-insensitive';
                    let i = index
                "
                [item]="item"
                [type]="orderType"
                [options]="options"
                class="fade-in"
                [index]="i + 1"
            ></added-order-item> -->
      <!--<a class="button button--cancel" href="/assets/docs/SGC-CardGrading-Invoice-2020.pdf" download style="background:#785aff;color:#fff;padding-bottom:13px;font-size:1.5rem;font-family:'d77';font-weight:normal;padding-left:5px;padding-top:13px;text-align:center;">
        Have over 100 cards? Click here to download our bulk submission PDF
    </a>-->
      <!--<div *ngIf="orderType.getCode() != 'card-pre-grade'">
        <p>Turnaround times vary with demand. Any of the following will result in longer lead times:</p>
        <p>
            * Non-use of online invoice
        </p>
        <p>
            * Cards not sent in Card Saver I or Ultra Pro semi-rigid holders
        </p>
        <p>
            * Oversized items, Non-sports cards, or Items requiring custom research
        </p>
    </div>-->
      <!-- <div class="gridborder" *ngIf="orderType.getCode() == 'card-grading'">
                 <ejs-grid #grid [dataSource]='standardRawGrading' gridLines="lines" allowTextWrap='true'>
                      <e-columns>
                        <e-column headerText='ESTIMATED 5-10 BUSINESS DAY TURNAROUND TIME' textAlign='Center' [columns]='StdColumns'></e-column>
                    </e-columns>
                </ejs-grid>
                <h4 class="gridfooter">RAW CARD GRADING, REVIEWS, & CROSSOVERS DECLARED VALUE < $1,500*	</h4>
                <p class="gridfooter2" >*VALUE UPCHARGES DO NOT APPLY FOR CARDS PRODUCED POST-2000	</p>
            </div>
            <label class="toggle">
                Click here to agree to the SGC <a href="https://www.gosgc.com/terms" target="_blank">terms and conditions</a>.
                <input type="checkbox" [ngModel]="accepted" (ngModelChange)="accept($event)">
                <span class="toggle__check_2"></span>
            </label> -->

      <!-- <button class="btn btn-outline-secondary ms-2" #popover="ngbPopover" [ngbPopover]="tableContent" [autoClose]="false" [placement]="'bottom'">
                this button,
              </button>

              <ng-template #tableContent>
                <div class="gridborder">
                  <ejs-grid #grid [dataSource]='standardRawGrading' gridLines="lines" allowTextWrap='true'>
                    <e-columns>
                      <e-column headerText='ESTIMATED 5-10 BUSINESS DAY TURNAROUND TIME' textAlign='Center' [columns]='StdColumns'></e-column>
                    </e-columns>
                  </ejs-grid>
                  <h4 class="gridfooter">RAW CARD GRADING, REVIEWS, & CROSSOVERS DECLARED VALUE < $1,500*</h4>
                  <p class="gridfooter2">*VALUE UPCHARGES DO NOT APPLY FOR CARDS PRODUCED POST-2000</p>
                </div>
              </ng-template> -->
    </div>
  </div>
</div>

<style type="text/css">
  /* @media only screen and (max-width: 770px) {
        .checkout__items {
            margin-left: -35px;
            width: fit-content;
            margin-bottom: 30px;
            padding-top: 44px;
            margin-inline: -50px;
        }
        .fade-in {
            margin-left: -4px;
        }
        .checkout__columns {
            width: 200px;
            margin-bottom: 30px;
        }
        .gridborder {
            width: 148px;
        }
        .showLabel {
            width: 127px;
        }
        .card_content {
            width: 165px;
        }
        .with {
            width: 152px;
        }
        .toggle {
            font-size: 12px;
        }
        .checkout__content {
            grid-gap: 5px;
            padding: 6px;
        }
        .h2-step2 {
            width: 337px;
        }
        .checkout__results-list {
            width: 182px;
        }
        .checkout__search {
            width: 190px;
        }
        .input-search {
            width: 183px;
        }
    } */
</style>
