<div class="row m-0 p-4 w-100">
    <div class="col-12">
        <nav class="navbar ">
            <div class="sub-menu">
                <input type="checkbox" id="show-menu" role="button">
                <label for="show-menu" class="show-menu"></label>
                <ul class=" menu" id="menu">
                    <li>
                        <a class="menu__item " routerLink="/account/dashboard"><img src="./assets/img/dashboard/subm.png" alt="" width="15" /> Dashboard</a>
                    </li>
                    <li>
                        <a class="menu__item " routerLink="/account/myorder"><img src="./assets/img/dashboard/order.png" alt="" width="15" /> My Orders</a>
                    </li>
                    <li>
                        <a class="menu__item " routerLink="/account/sellerready"><img src="./assets/img/dashboard/seller.png" alt="" width="15" /> Seller Ready</a>
                    </li>
                    <li>
                        <a class="menu__item " routerLink="/account/receipts"><img src=".../../../../assets/img/dashboard/Dollar Icon - Black - S.png" alt="" width="15" />Receipts</a>
                    </li>
                    <li>
                        <a class="menu__item " routerLink="/account/needhelp"><img src="./assets/img/dashboard/helpp.png" alt="" width="15" /> I need Help</a>
                    </li>
                    <li>
                        <a class="menu__item active" routerLink="/account"><img src="./assets/img/dashboard/user.png" alt="" width="15" />My Profile</a>
                    </li>
                    <!--<li>
                        <a class="menu__item" routerLink="/account/my-cards"><img src="./assets/img/dashboard/seller.png" alt="" width="15" />My Cards</a>
                    </li>-->
                    <!--<li>
                        <a class="menu__item" routerLink="/account/my-cards-download"><img src="./assets/img/dashboard/seller.png" alt="" width="15" />Card Downloads</a>
                    </li>-->
                </ul>

            </div>
        </nav>
    </div>
</div>
<div class="wrapper">
    <h2>My Profile</h2>
    <div class="user-links mt-4 p-4">
        <div class="row m-0 mt-1">
            <div class="col-12 mb-4">
                <img src="" /><h4>Umair Arshad</h4>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="grey-bg h-100">
                    <div class="row m-0 align-items-center">
                        <div class="col-9">
                            <h5>Customer Name</h5>
                            <p>Umair Arshad</p>
                        </div>
                        <div class="col-3 text-right"><a href="#"><img src="./assets/img/dashboard/edit.png" alt="" width="15" /></a></div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="grey-bg h-100">
                    <div class="row m-0 align-items-center">
                        <div class="col-9">
                            <h5>Email</h5>
                            <p>Umairarshad&#64;example.com</p>
                        </div>
                        <div class="col-3 text-right"><a href="#"><button class="button">Save</button></a></div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="grey-bg h-100">
                    <div class="row m-0 align-items-center">
                        <div class="col-9">
                            <h5>Phone Number</h5>
                            <p>Umair Arshad</p>
                        </div>
                        <div class="col-3 text-right"><a href="#"><img src="./assets/img/dashboard/edit.png" alt="" width="15" /></a></div>
                    </div>
                </div>
            </div>

        </div>
        <div class="row m-0 mt-4">
            <div class="col-12 mb-4"><h4>Shipping Address(es):</h4></div>
            <div class="col-md-6 mb-4">
                <div class="grey-bg h-100">
                    <div class="row m-0 align-items-center">
                        <div class="col-9">15 Ann Dr. Feasterville Trevose, PA 19053</div>
                        <div class="col-3 edit-btn text-right">
                            <a href="#"><img src="./assets/img/dashboard/edit.png" alt="" width="15" /></a>
                            <a href="#"><img src="./assets/img/dashboard/del.png" alt="" width="15" /></a>
                            <a href="#"><img src="./assets/img/dashboard/add.png" alt="" width="15" /></a>
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-md-6 mb-4">
                <div class="grey-bg h-100">
                    <div class="row m-0 align-items-center">
                        <div class="col-9">15 Ann Dr. Feasterville Trevose, PA 19053</div>
                        <div class="col-3 edit-btn text-right">
                            <a href="#"><img src="./assets/img/dashboard/edit.png" alt="" width="15" /></a>
                            <a href="#"><img src="./assets/img/dashboard/del.png" alt="" width="15" /></a>
                            <a href="#"><img src="./assets/img/dashboard/add.png" alt="" width="15" /></a>
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-md-6 mb-4">
                <div class="grey-bg h-100">
                    <div class="row m-0 align-items-center">
                        <div class="col-9">15 Ann Dr. Feasterville Trevose, PA 19053</div>
                        <div class="col-3 edit-btn text-right">
                            <a href="#"><img src="./assets/img/dashboard/edit.png" alt="" width="15" /></a>
                            <a href="#"><img src="./assets/img/dashboard/del.png" alt="" width="15" /></a>
                            <a href="#"><img src="./assets/img/dashboard/add.png" alt="" width="15" /></a>
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-md-6 mb-4">
                <div class="grey-bg h-100">
                    <div class="row m-0 align-items-center">
                        <div class="col-9">15 Ann Dr. Feasterville Trevose, PA 19053</div>
                        <div class="col-3 edit-btn text-right">
                            <a href="#"><img src="./assets/img/dashboard/edit.png" alt="" width="15" /></a>
                            <a href="#"><img src="./assets/img/dashboard/del.png" alt="" width="15" /></a>
                            <a href="#"><img src="./assets/img/dashboard/add.png" alt="" width="15" /></a>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="row m-0 mt-4">
            <div class="col-12 mb-4"><h4>My Preferances:</h4></div>
        </div>
    </div>
</div>
