import { Component } from '@angular/core';

import { ModalService, ModalInterface } from '../../services/modal.service';

@Component({
    selector: 'new-site-alert',
    templateUrl: './new-site.component.html'
})

export class NewSiteAlertComponent implements ModalInterface {
    constructor(
        private modalService: ModalService
    ){}

    type = "new-site-alert";
    loader!: boolean;
    options: any;

    modalCopy: string = `To commemorate our 20th anniversary of serving collectors worldwide, we are updating the SGC collector experience, beginning with our brand new online invoice. You may use your existing SGC login and password to sign in, or create a new account if you do not have one already.

    If you have difficulties with the new site, please contact Collector Support at support@gosgc.com. We are excited to introduce you to our new and improved website, and we look forward to announcing more tech updates soon! ...Next up: New Pop Report and Registry!
    
    Please Note: We are currently experiencing a temporary increase in our processing times due to an increased flow of submissions.`;

    close() {
        localStorage.setItem('new_site_alerted', JSON.stringify(true));
        this.modalService.close(this.type, 'close');
    }

}