import { Injectable } from '@angular/core';
import * as _ from 'lodash';

import { ORDER_MODAL_TYPES } from '../components/modals/order-modals';
import { ModalService } from '../../common/services/modal.service';
import { BehaviorSubject } from 'rxjs';

interface ConfigurationStep {
  modalType: string;
  validation: (context: any) => boolean;
  show?: (context: any) => boolean;
}

interface ConfigurationSteps {
  [key: string]: ConfigurationStep[];
}

@Injectable()
export class OrderItemConfigurationService {
  autographSteps: ConfigurationStep[] = [
    {
      modalType: ORDER_MODAL_TYPES.addSigner,
      validation: this.isAnySignerAdded,
      show: this.isAnySignerAdded,
    },
    {
      modalType: ORDER_MODAL_TYPES.selectAutographItem,
      validation: this.isSignableObjectSelected,
    },
    {
      modalType: ORDER_MODAL_TYPES.addAutographCard,
      validation: this.cardAdded,
      show: this.isSignableObjectACard,
    },
    {
      modalType: ORDER_MODAL_TYPES.selectAutographService,
      validation: this.isAutographServiceSelected,
    },
    {
      modalType: ORDER_MODAL_TYPES.selectStickerPlacement,
      validation: this.isStickerPlacementSelected,
      show: this.canSelectStickerPlacement,
    },
    {
      modalType: ORDER_MODAL_TYPES.selectGrading,
      validation: this.isGradingOptionSelected,
    },
  ];

  cardSteps: ConfigurationStep[] = [];

  currentStep!: number;
  currentOrderType!: string;
  configurationSteps: ConfigurationSteps = {
    'autograph-authentication': this.autographSteps,
    'card-grading': this.cardSteps,
    'card-pre-grade': this.cardSteps,
    '22BOWMANCHR': this.cardSteps,
    '22POKEMON': this.cardSteps,
    '23TOPPSCHR': this.cardSteps,
    '23BOWMANEOY': this.cardSteps,
    '24BASKETBALL': this.cardSteps,
    '24TOPPSS2': this.cardSteps,
  };

  private itemConfiguredSource = new BehaviorSubject<any>({});
  itemConfigured$ = this.itemConfiguredSource.asObservable();

  constructor(private modalService: ModalService) {}

  isStepCompleted(context: any): boolean {
    return this.configurationSteps[this.currentOrderType][
      this.currentStep
    ].validation(context);
  }

  isAnySignerAdded(context: any): boolean {
    return context.item.signers.length > 0;
  }

  isSignableObjectSelected(context: any): boolean {
    return context.item.signedObject.id;
  }

  isAutographServiceSelected(context: any): boolean {
    return true;
  }

  isStickerPlacementSelected(context: any): boolean {
    return true;
  }

  isGradingOptionSelected(context: any): boolean {
    return true;
  }

  cardAdded(context: any): boolean {
    return context.item.card;
  }

  isSignableObjectACard(context: any): boolean {
    return (
      context.item.signedObject && context.item.signedObject.code == 'card'
    );
  }

  canSelectStickerPlacement(context: any): boolean {
    return context.settings.premiumCertificate;
  }

  openModal(item: any) {
    const step =
      this.configurationSteps[this.currentOrderType][this.currentStep];
    if (!step) return;
    this.modalService.open(step.modalType, item);
  }

  closeModal() {
    const step =
      this.configurationSteps[this.currentOrderType][this.currentStep];
    if (!step) return;
    this.modalService.close(step.modalType, 'close');
  }

  moveForward(options: any) {
    this.currentStep++;
    const step =
      this.configurationSteps[this.currentOrderType][this.currentStep];
    if (step && step.show && !step.show(options)) this.currentStep++;
    this.openModal(options);
  }

  moveBackward(options: any) {
    this.currentStep--;
    const step =
      this.configurationSteps[this.currentOrderType][this.currentStep];
    if (step && step.show && !step.show(options)) this.currentStep--;
    this.openModal(options);
  }

  cancel() {
    this.closeModal();
  }

  saveStep(type: string, configuredItem: any) {
    if (this.isLastStep(type)) {
      this.itemConfiguredSource.next(configuredItem);
      this.closeModal();
    } else {
      this.moveForward(configuredItem);
    }
  }

  configureItem(resultItem: any, type: string) {

    this.currentOrderType = type;
    this.currentStep = 0;

    const item = this.getItemForConfiguration(resultItem);
    const step =
      this.configurationSteps[this.currentOrderType][this.currentStep];
    if (step && step.show && !step.show(item)) this.currentStep++;

    if (this.requiresConfiguration(this.currentOrderType, this.currentStep)) {
      this.openModal(item);
    } else {
      this.addConfiguredItem(item);
    }
  }

  getItemForConfiguration(resultItem: any): any {

    let configuredItem: any;

    switch (resultItem.kind) {
      case 'card':
        configuredItem = {
          item: {
            kind: resultItem.kind,
            id: resultItem.id,
            name: resultItem.name,
          },
          settings: {},
        };
        break;
      case 'subject':
        configuredItem = {
          item: {
            kind: resultItem.kind,
            signers: [],
            signedObject: {
              id: null,
              code: null,
              name: null,
            },
          },
          settings: {
            stickerOnItem: null,
            autographGrading: false,
            cardGrading: false,
            encapsulation: false,
            premiumCertificate: false,
          },
        };
        if (resultItem.id) configuredItem.item.signers.push(resultItem);
        break;
    }

    configuredItem.resultItem = resultItem;

    return configuredItem;
  }

  requiresConfiguration(type: string, step: number): boolean {

    return !!this.configurationSteps[type][step];
  }

  addConfiguredItem(item: any) {

    this.itemConfiguredSource.next(item);
  }

  isLastStep(type: string): boolean {
    const steps = this.configurationSteps[this.currentOrderType];
    return this.getTypeIndex(type, steps) === steps.length - 1;
  }

  //   isFirstStep(type: string, options: any): boolean {
  //     const steps = this.configurationSteps[this.currentOrderType];
  //     const index = this.getTypeIndex(type, steps);
  //     return index === 0 || (!steps[0].show(options) && index === 1);
  //   }
  isFirstStep(type: string, options: any): boolean {
    const steps = this.configurationSteps[this.currentOrderType];
    const index = this.getTypeIndex(type, steps);
    const isIndexZero = index === 0;
    const isStepsDefined = !!steps;
    const isFirstStepWithoutShow =
      isStepsDefined && steps[0]?.show && !steps[0].show(options);
    const isIndexOne = index === 1;

    const isFirstStep =
      (isIndexZero || (isStepsDefined && isFirstStepWithoutShow)) && isIndexOne;
    return !!isFirstStep;
  }

  getTypeIndex(type: string, steps: any[]): number {
    return _.findIndex(steps, (step) => step.modalType === type);
  }

  getForwardButtonLabel(type: string): string {
    return this.isLastStep(type) ? 'Save' : 'Continue';
  }
}
