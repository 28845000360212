<div class="row_ m-0 p-4 w-100">
  <div class="col-12">
    <nav class="navbar">
      <div class="sub-menu">
        <input type="checkbox" id="show-menu" role="button" />
        <label for="show-menu" class="show-menu"></label>
        <ul class="menu" id="menu">
          <li>
            <a class="menu__item" routerLink="/account/dashboard"
              ><img src="./assets/img/dashboard/subm.png" alt="" width="15" />
              Dashboard</a
            >
          </li>
          <li>
            <a class="menu__item" routerLink="/account/myorder"
              ><img src="./assets/img/dashboard/order.png" alt="" width="15" />
              My Orders</a
            >
          </li>
          <li>
            <a class="menu__item" routerLink="/account/sellerready"
              ><img src="./assets/img/dashboard/seller.png" alt="" width="15" />
              Seller Ready</a
            >
          </li>
          <li>
            <a class="menu__item active" routerLink="/account/receipts"
              ><img
                src="../../../../assets/img/dashboard/Dollar Icon - Black - S.png"
                alt=""
                width="15"
              />
              Receipts
            </a>
          </li>
          <li>
            <a class="menu__item" routerLink="/account/needhelp"
              ><img src="./assets/img/dashboard/helpp.png" alt="" width="15" />
              I Need Help</a
            >
          </li>
          <li>
            <a class="menu__item" routerLink="/account"
              ><img src="./assets/img/dashboard/user.png" alt="" width="15" />My
              Profile</a
            >
          </li>
          <!--<li>
                        <a class="menu__item" routerLink="/account/my-cards"><img src="./assets/img/dashboard/seller.png" alt="" width="15" />My Cards</a>
                    </li>-->
          <!--<li>
                        <a class="menu__item" routerLink="/account/my-cards-download"><img src="./assets/img/dashboard/seller.png" alt="" width="15" />Card Downloads</a>
                    </li>-->
        </ul>
      </div>
    </nav>
  </div>
</div>
<div class="wrapper">
  <h2>Receipts</h2>
  <div class="user-links mt-4 p-4">
    <p>
      The table below displays the status and information our payment processor
      sent to SGC during online checkout. If your bank says the transaction was
      approved, and in the table below, our payment processor responded to us
      that the transaction was not approved, there is an issue between your
      bank/credit card provider and our payment processor. When this occurs, you
      should receive a notification with instructions to send your order to SGC
      and upon receipt, we will call you to process payment over the phone.
    </p>
    <p>
      <strong style="font-weight: 400;">
        The table below DOES NOT reflect any other transaction types besides
        online transactions with our payment processor. Payments taken over the
        phone, along with any refund or upcharge information will not be
        displayed here.
      </strong>
    </p>
    <!--<div class="user-account__edit mt-6 mr-2" style="margin-top:25px;margin-bottom:14px;">
            <a style="background-color:black;" class="button button--secondary mt-4 mr-2" href="" routerLink="/collector-support/contact-us" target="_blank"><strong>Contact Us</strong></a>
        </div>-->

    <div class="accordion_content custom-grid-styles" *ngIf="!ispayPalOrderForCustomer">
      <ejs-grid
        #grid
        [dataSource]="payPalOrderForCustomer"
        gridLines="both"
        allowPaging="true"
        [allowSorting]="true"
        [allowFiltering]="true"
        [pageSettings]="pageSettings"
        [filterSettings]="FilterOptions"
        [allowResizing]="true"
        allowTextWrap="true"
        [textWrapSettings]="wrapSettings"
        [toolbar]="toolbar"
        (toolbarClick)="toolbarClick($event)"
        [allowExcelExport]="true"
      >
        <e-columns>
          <e-column
            field="orderNumber"
            headerText="Order #"
            type="number"
            width="90"
            textAlign="center"
          >
            <ng-template #template let-payPalOrderForCustomer>
              <a
                style="text-decoration-line: none; cursor: pointer"
                target="_blank"
                href="https://www.gosgc.com/orders/{{
                  payPalOrderForCustomer.id
                }}/invoice"
                >{{ payPalOrderForCustomer.orderNumber }}</a
              >
            </ng-template>
          </e-column>
          <e-column
            field="status"
            [allowSorting]="false"
            headerText="Order Status"
            width="70"
            textAlign="center"
          ></e-column>
          <e-column
            field="transactionDate"
            headerText="Transaction Date"
            type="date"
            [format]="format"
            [filter]="filter"
            textAlign="center"
            width="90"
          ></e-column>
          <e-column
            field="amount"
            [allowSorting]="false"
            [allowFiltering]="false"
            type="number"
            format="C2"
            headerText="Amount"
            textAlign="center"
            width="70"
          ></e-column>
          <e-column
            field="payPalStatus"
            [allowSorting]="false"
            headerText="Status"
            width="70"
            textAlign="center"
          ></e-column>
          <e-column
            field="message"
            [allowSorting]="false"
            [allowFiltering]="false"
            headerText="Receipt"
            width="70"
            textAlign="center"
          >
            <!-- <ng-template #template>
              <a
                style="text-decoration-line: none; cursor: pointer"
                target="_blank"
                href="https://www.gosgc.com/orders/{{
                  payPalOrderForCustomer.id
                }}/paypal"
                >View Receipt</a
              >
            </ng-template> -->
            <ng-container *ngFor="let order of payPalOrderForCustomer">
              <ng-template #template let-payPalOrderForCustomer>
                <a style="text-decoration-line: none;cursor: pointer;" target="_blank" href="{{payPalOrderForCustomer.stripeReceiptUrl}}" *ngIf="payPalOrderForCustomer.stripeReceiptUrl != null">View Receipt</a>
            </ng-template>
            </ng-container>
          </e-column>
          <e-column
            field="message"
            [allowSorting]="false"
            [allowFiltering]="false"
            headerText="Message"
            width="70"
            textAlign="center"
          >
            <ng-template #template let-payPalOrderForCustomer>
              <a
                style="text-decoration-line: none; cursor: pointer"
                target="_blank"
                href="https://www.gosgc.com/orders/{{
                  payPalOrderForCustomer.id
                }}/paypal"
                >{{ payPalOrderForCustomer.message }}</a
              >
            </ng-template>
          </e-column>
        </e-columns>
      </ejs-grid>
    </div>
  </div>
</div>
