import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../common/services/user.service';
import { User } from '../../../common/models/user';

@Component({
  selector: 'app-seller-ready',
  templateUrl: './seller-ready.component.html',
  styleUrls: ['../../dashboard.component.css'],
})
export class SellerReadyComponent implements OnInit {
  constructor(private userService: UserService) {}

  currentUser = new User();
  ngOnInit() {
    this.userService.userProfile$.subscribe(
      (user) => (this.currentUser = user)
    );
  }

  getImageAccountUrl() {
    const regex = /^(http|https|ftp):\/\//i;

    if (regex.test(this.currentUser.imageAccountUrl)) {
      return this.currentUser.imageAccountUrl;
    }

    return `//${this.currentUser.imageAccountUrl}`;
  }

  hasImageAccountUrl(): boolean {
    return this.currentUser.imageAccountUrl != null;
  }
}
