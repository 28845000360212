import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable,of } from 'rxjs';
import * as _ from 'lodash';

import { User, Countries, States } from '../models/user';

@Injectable()
export class StateService {

    currentUser = new StateItem<User>('user');
    
    private subMenuActiveSource = new BehaviorSubject<any[]>(new Array());

    subMenuActive$ = this.subMenuActiveSource.asObservable();

    activateSubMenu(submenu: any) {
        this.subMenuActiveSource.next(submenu);
    }

    deactivateSubMenu() {
        this.subMenuActiveSource.next(new Array());
    }

    getGuid(): string {
        return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
    }

    getListOfCountries(): Observable<any[]> {
        return of(Countries);
    }

    getListOfStates(country?: string): any[] {
        if(!country) country = 'US';
        return States.filter(state => state.country == country);
    }
}

export class StateItem<TType> {

    private subject: BehaviorSubject<TType>;
    private value: TType;
    private name: string;
    public changes: Observable<TType>;

    constructor(name: string)
    {
        this.name = name;
        this.value = this.getFromStorage(this.name);
        this.subject = new BehaviorSubject<TType>(this.value);
        this.changes = this.subject.asObservable();
    }

    get() : TType {
        return this.value;
    }

    set(value: TType) {
        this.value = value;
        console.log(this.value,this.name)
        localStorage.setItem(this.name, JSON.stringify(this.value));
        this.subject.next(this.value);
    }

    has() : boolean {
        return !_.isEmpty(this.value);
    }

    private getFromStorage(key: string): any {
        const storedValue = localStorage.getItem(key);
        if(storedValue) return JSON.parse(storedValue);
        return null;
    }
}