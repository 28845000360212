import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';

import {
  ModalService,
  ModalInterface,
} from '../../../../../common/services/modal.service';
import { OrderService } from '../../../../../orders/services/order.service';
//import { AutographOrderItemService } from '../../../../../order/models/order.model';

@Component({
  //moduleId: module.id,
  selector: 'select-autograph-service',
  templateUrl: './select-autograph-service.component.html',
  styleUrls: ['../../../../checkout.component.sass'],
})
export class SelectAutographServiceComponent implements ModalInterface, OnInit {
  constructor(
    private modalService: ModalService,
    private orderService: OrderService
  ) {}

  title = 'Service';
  subtitle = 'Please specify the service required';
  type = 'select-autograph-service';
  options: any;
  autographServices!: any[];

  select(service: any) {
    switch (service.code) {
      case 'encapsulation':
        this.options.settings.encapsulation =
          !this.options.settings.encapsulation;
        break;
      case 'requested-premium-certificate':
        this.options.settings.premiumCertificate =
          !this.options.settings.premiumCertificate;
        break;
      case 'standard-certificate':
        this.options.settings.premiumCertificate = false;
        break;
    }
  }

  isSelected(service: any): boolean {
    switch (service.code) {
      case 'encapsulation':
        return this.options.settings.encapsulation;
      case 'requested-premium-certificate':
        return this.options.settings.premiumCertificate;
      case 'standard-certificate':
        return !this.options.settings.premiumCertificate;
      default:
        return false;
    }
  }

  isEncapsulation(service: any) {
    return service.code == 'encapsulation';
  }

  ngOnInit() {
    this.orderService
      .getAvailableAutographService(this.options)
      .subscribe((autographServices) => {
        this.autographServices = _.filter(
          autographServices,
          (service) => service.available
        );
      });
  }
}
