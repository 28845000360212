import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MomentModule } from 'ngx-moment';
import { RouterModule } from '@angular/router';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OrderService } from './services/order.service';
import { AppCommonModule } from '../common/common.module';
// import { OrderListComponent } from './views/order-list/order-list.component';
import { OrdersRoutingModule } from './order-routing.module';
import { LeftNavLayoutComponent } from '../common/layout/left-nav-layout/left-nav-layout.component';
import { CommonModule } from '@angular/common';
// import { OrderItemComponent } from './components/order-item/order-item.component';
import { CardItemComponent } from './components/card-item/card-item.component';
import { AutographItemComponent } from './components/autograph-item/autograph-item.component';
import { InvoiceComponent } from './views/invoice/invoice.component';
// import { CollectionListComponent } from './views/collection-list/collection-list.component';
//import { PaypalComponent } from '../paypal/paypal.component';
//import { BotDetectCaptchaModule } from 'angular-captcha';
import { TooltipModule } from 'ng2-tooltip-directive';
// import { BrowserAnimationsModule } from '@angular/common/animations';
// import { NewinvoiceComponent } from './views/newinvoice/newinvoice.component';
// import { StripeComponent } from './views/stripe/stripe.component';

@NgModule({
  imports: [
    AppCommonModule,
    BrowserModule,
    RouterModule,
    // BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    OrdersRoutingModule,
    CommonModule,
    MomentModule,
    TooltipModule,
  ],
  declarations: [
    // OrderListComponent,
    LeftNavLayoutComponent,
    // OrderItemComponent,
    CardItemComponent,
    AutographItemComponent,
    InvoiceComponent,
    // CollectionListComponent,
    // NewinvoiceComponent,
    // StripeComponent,
  ],
  providers: [OrderService],
})
export class OrdersModule {}
