import { Component, Input, OnInit, OnChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService, ModalInterface } from '../services/modal.service';
import { MODALS } from '../enumerations/modal.enumeration';
import { SGCModalDirective } from '../directives/modal.directive';

@Component({
  selector: 'sgc-modal',
  templateUrl: './modal.component.html',
})
export class ModalComponent implements OnInit, OnChanges {
  public revisedUrl: boolean = false;

  constructor(private modalService: ModalService, private router: Router) {}

  @Input() type!: string;
  @Input() options: any;
  @ViewChild(SGCModalDirective, { static: true }) modalHost!: SGCModalDirective;
  title!: string;
  subtitle!: string;

  ngOnInit() {
    this.loadModal();
    this.checkRevisedUrl();
  }

  ngOnChanges() {
    this.loadModal();
    this.checkRevisedUrl();
  }

  loadModal() {
    const modalItem = MODALS.find((modal) => modal.type === this.type);

    if (modalItem) {
      const componentFactory = modalItem.component;
      const viewContainerRef = this.modalHost.viewContainerRef;
      viewContainerRef.clear();

      const componentRef = viewContainerRef.createComponent(componentFactory);
      const modalInstance = componentRef.instance as ModalInterface;

      this.title = modalInstance.title!;
      this.subtitle = modalInstance.subtitle!;
      modalInstance.options = this.options;
    }
  }

  private checkRevisedUrl() {
    const targetUrls = ['/auth-code', '/cert-code-lookup'];
    this.revisedUrl = targetUrls.includes(this.router.url);
  }
}
