import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppCommonModule } from '../common/common.module';
import { CommonModule } from '@angular/common';
import { AccountRoutingModule } from './account-routing.module';
import { AccountProfileComponent } from './views/account-profile/account-profile.component';
import { EditProfileComponent } from './components/edit-profile/edit-profile.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { MycardsComponent } from './views/mycards/mycards.component';
import { NeedHelpComponent } from './views/need-help/need-help.component';
import { PayPalPaymentComponent } from './views/pay-pal-payment/pay-pal-payment.component';
import { SellerReadyComponent } from './views/seller-ready/seller-ready.component';
import { MyProfileComponent } from './views/my-profile/my-profile.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { BotDetectCaptchaModule } from 'angular-captcha';
import {
  GridModule,
  PageService,
  ToolbarService,
  ExcelExportService,
  SortService,
  FilterService,
  GroupService,
} from '@syncfusion/ej2-angular-grids';
import { MySubmissionsComponent } from './views/my-submissions/my-submissions.component';
import { SearchPipe } from './views/mycards/search.pipe';
import { MycardsDownloadComponent } from './views/mycards-download/mycards-download.component';
import {
  DropDownButtonModule,
  SplitButtonModule,
  ProgressButtonModule,
} from '@syncfusion/ej2-angular-splitbuttons';
import { ToastrModule } from 'ngx-toastr';
import { RibbonAllModule, RibbonModule } from "@syncfusion/ej2-angular-ribbon";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  imports: [
    AppCommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AccountRoutingModule,
    CommonModule,
    FormsModule,
    DropDownButtonModule,
    SplitButtonModule,
    ProgressButtonModule,
    ReactiveFormsModule,
    // BotDetectCaptchaModule,
    GridModule,
    RibbonModule,
    RibbonAllModule,
    ToastrModule.forRoot(),
  ],
  declarations: [
    AccountProfileComponent,
    EditProfileComponent,
    DashboardComponent,
    MycardsComponent,
    NeedHelpComponent,
    PayPalPaymentComponent,
    SellerReadyComponent,
    MyProfileComponent,
    MySubmissionsComponent,
    SearchPipe,
    MycardsDownloadComponent,
  ],
  // entryComponents: [EditProfileComponent],
  providers: [
    PageService,
    SortService,
    FilterService,
    GroupService,
    ToolbarService,
    ExcelExportService,
  ],
})
export class AccountModule {}
