import { Component, OnInit } from '@angular/core';
import { AdminKPIExecutive } from '../../../orders/models/order';
import { StateService } from '../../services/state.service'
import { AuthService } from '../../services/auth.service';
import { ModalService } from '../../services/modal.service';
import { OrderService } from '../../../orders/services/order.service';
import { CheckoutService } from '../../../checkout/services/checkout.service';
import { UserService } from '../../../common/services/user.service';
// import { Tree } from '@angular/router/src/utils/tree';
// import { Tree } from '@angular/router';

@Component({
  selector: 'general-layout',
  templateUrl: './general-layout.component.html',
  styleUrls: ['./general-layout.component.sass']
})

export class GeneralLayoutComponent implements OnInit {
  isAndroid: boolean = false;
  isiOS: boolean = false;

  constructor(
    private checkoutService: CheckoutService,
    private stateService: StateService,
    private authService: AuthService,
    private userService: UserService,
    private modalService: ModalService,
    private orderService: OrderService,
  ) { }

  adminKPIExecutive = new AdminKPIExecutive();
  currentUser: any;
  userMenu: boolean = false;
  mobileMenu: boolean = false;
  //allowGurPurpleBar: boolean = false;
  allowMainPurpleBar: boolean = false;
  showCollection: boolean = false;

  onActivate(event: any) {
    window.scroll(0, 0);
  }

  isMainPurpleBar(): boolean {
    this.checkoutService.current30daymsg$.subscribe(businessGurDaysMsg => {
      this.adminKPIExecutive = businessGurDaysMsg
      if (this.adminKPIExecutive.allowMainPurpleBar == true)
        this.allowMainPurpleBar = true;
      else
        this.allowMainPurpleBar = false;
    });
    return this.allowMainPurpleBar;
  }

  ngOnInit() {
    var userAgent = navigator.userAgent;
    if (userAgent.match(/Android/i)) {
      this.isAndroid = true;
    }
    else if (userAgent.match(/iPhone|iPad|iPod/i)) {
      this.isiOS = true;
    }

    this.stateService.currentUser.changes.subscribe(user => {
      this.currentUser = user;
    });
    if (this.currentUser) {
      if (this.currentUser.email == 'msaad@kineticasys.com' || this.currentUser.email == 'rich@gosgc.com') {
        this.showCollection = true;
      }
    }
    this.orderService.checkBusinessDayGur().subscribe(adminKPIExecutive => {
      this.checkoutService.newGuranteeDaysMsg(adminKPIExecutive);
    });
  }

  showStartSubmission(): boolean {
    return !this.isAuthenticated();
  }


  login(): void {
    this.authService.login();
  }

  logout(): void {
    this.currentUser.id = null;
    this.userService.logoutUser(this.currentUser);
    this.userService.logoutCurrentUser().subscribe(user => { });
    this.authService.logout();
  }

  getUserName(): string {
    return this.currentUser && this.currentUser.username;
  }

  isAuthenticated(): boolean {
    // this.checkoutService.setinit()
    return this.authService.isAuthenticated();
  }

  toggleUserMenu() {
    this.userMenu = !this.userMenu;
  }

  toggleMobileMenu() {
    // debugger
    this.checkoutService.setinit()
    this.mobileMenu = !this.mobileMenu;
    // this.checkoutService.cancelCheckout()
  }

  getUserProfilePictureUrl(): string {
    return this.currentUser.profilePictureUrl ? this.currentUser.profilePictureUrl : '/assets/img/default-user.svg';
  }

  goToLegacyRegistry() {
    var options = {
      message: `Our brand new SGC Registry is coming soon!

            In the meantime, if you wish to use our current registry, please click on "Go to Legacy Registry" and you will be redirected to the old site.`,
      actionLabel: 'Go To Legacy Registry',
      actionCallback: () => window.open('https://sgclegacy.com/registrymain.aspx', '_blank')
    }

    this.modalService.open('feature-coming-soon', options);
  }


  goToLegacyPopulationReport() {
    var options = {
      message: `Our brand new SGC Population Report is coming soon!

            In the meantime, if you wish to use our current Population Report, please click on "Go to Legacy Pop Report" and you will be redirected to the old site.`,
      actionLabel: 'Go To Legacy Pop Report',
      actionCallback: () => window.open('https://sgclegacy.com/PopulationReport.aspx', '_blank')
    }

    this.modalService.open('feature-coming-soon', options);
  }
}
