import * as _ from 'lodash';

export class User { 
    
    constructor(user?: any){

        if(!user) return;
        this.id = user.id;
        this.customerId = user.customerId;
        this.name = user.name;
        this.email = user.email;
        this.customerNumber = user.customerNumber;
        this.username = user.username;
        this.profilePictureUrl = user.profilePictureUrl;
        this.isEmployee = user.isEmployee;
        this.addresses = _.map(user.addresses, a => new Address(a));
        this.phoneNumber = user.phoneNumber;
        this.imageAccountUrl = user.imageAccountUrl;
        this.custReqAcctDeletePrivacy = false;
        this.saveInProgress = false;
        if (user.preferences) {
            user.preferences.useFedexAccount != null && (this.preferences.useFedexAccount = user.preferences.useFedexAccount);
            user.preferences.currentFedexAccount && (this.preferences.currentFedexAccount = user.preferences.currentFedexAccount);
            user.preferences.encapsulationRequested != null && (this.preferences.encapsulationRequested = user.preferences.encapsulationRequested);
            user.preferences.fedexAccountPostalCode != null && (this.preferences.fedexAccountPostalCode = user.preferences.fedexAccountPostalCode);
            user.preferences.mktgOptInSGCProductsPricing != null && (this.preferences.mktgOptInSGCProductsPricing = user.preferences.mktgOptInSGCProductsPricing);
            user.preferences.mktgOptInSGCMarketing != null && (this.preferences.mktgOptInSGCMarketing = user.preferences.mktgOptInSGCMarketing);
            user.preferences.mktgOptIn3PartyMarketing != null && (this.preferences.mktgOptIn3PartyMarketing = user.preferences.mktgOptIn3PartyMarketing);
            user.preferences.custReqAcctDelete != null && (this.preferences.custReqAcctDelete = user.preferences.custReqAcctDelete);
            user.preferences.myCardsAddAfterShipped != null && (this.preferences.myCardsAddAfterShipped = user.preferences.myCardsAddAfterShipped);
        }
    }
    id!: string;
    name!: string;
    email!: string;
    customerNumber!: number;
    customerId!: string;
    addresses = new Array<Address>();
    isEmployee!: boolean;
    username!: string;
    profilePictureUrl!: string;
    phoneNumber!: string;
    imageAccountUrl!: string;
    preferences: any = {
      useFedexAccount: null,
      currentFedexAccount: null,
      encapsulationRequested: false,
      fedexAccountPostalCode: null,
      mktgOptInSGCProductsPricing: false,
      mktgOptInSGCMarketing: false,
      mktgOptIn3PartyMarketing: false,
      custReqAcctDelete: false,
      myCardsAddAfterShipped: false
  };
    custReqAcctDeletePrivacy!: boolean;
    saveInProgress!: boolean;

    replaceAddress(address: Address) {
        var existingAddress = this.addresses.find(a => a.id == address.id);
        if (existingAddress) {
            existingAddress = address;
        } 
    }

    removeAddress(address: Address) {
        var index = this.addresses.findIndex(address => address.id == address.id);
        if (index != -1) {
            this.addresses.splice(index, 1);
        } 
    }
}

export class Address {

    constructor(address?: any) {
        if (!address) return;

        this.id = address.id;
        this.street1 = address.street1;
        this.street2 = address.street2;
        this.city = address.city;
        this.state = new AddressState(address.state);
        this.country = new Country(address.country);
        this.zipCode = address.zipCode;
        this.isDefault = address.default;
        this.phoneNumber = address.phoneNumber;
    }
    id!: string;
    street1!: string;
    street2?: string;
    city!: string;
    state!: AddressState;
    country!: Country;
    zipCode!: string;
    isDefault!: boolean;
    phoneNumber!: string;

    getId(): string {
        return this.id;
    }
}

export class AddressState {

    constructor(state?: any) {
        if (!state) return;

        this.name = state.name;
        this.code = state.code;
    }

    name!: string;
    code!: string;
}

export class Country {

    constructor(country?: any) {
        if (!country) return;

        this.name = country.name;
        this.code = country.code;
    }

    name!: string;
    code!: string;
}

export const Countries: Array<any> = [
    {
      "code": "AF",
      "name": "Afghanistan"
    },
    {
      "code": "AL",
      "name": "Albania"
    },
    {
      "code": "DZ",
      "name": "Algeria"
    },
    {
      "code": "AS",
      "name": "American Samoa"
    },
    {
      "code": "AD",
      "name": "Andorra"
    },
    {
      "code": "AO",
      "name": "Angola"
    },
    {
      "code": "AI",
      "name": "Anguilla"
    },
    {
      "code": "AQ",
      "name": "Antarctica"
    },
    {
      "code": "AG",
      "name": "Antigua and Barbuda"
    },
    {
      "code": "AR",
      "name": "Argentina"
    },
    {
      "code": "AM",
      "name": "Armenia"
    },
    {
      "code": "AW",
      "name": "Aruba"
    },
    {
      "code": "AU",
      "name": "Australia"
    },
    {
      "code": "AT",
      "name": "Austria"
    },
    {
      "code": "AZ",
      "name": "Azerbaijan"
    },
    {
      "code": "BH",
      "name": "Bahrain"
    },
    {
      "code": "BD",
      "name": "Bangladesh"
    },
    {
      "code": "BB",
      "name": "Barbados"
    },
    {
      "code": "BY",
      "name": "Belarus"
    },
    {
      "code": "BE",
      "name": "Belgium"
    },
    {
      "code": "BZ",
      "name": "Belize"
    },
    {
      "code": "BJ",
      "name": "Benin"
    },
    {
      "code": "BM",
      "name": "Bermuda"
    },
    {
      "code": "BT",
      "name": "Bhutan"
    },
    {
      "code": "BO",
      "name": "Bolivia"
    },
    {
      "code": "BA",
      "name": "Bosnia"
    },
    {
      "code": "BW",
      "name": "Botswana"
    },
    {
      "code": "BV",
      "name": "Bouvet Island"
    },
    {
      "code": "BR",
      "name": "Brazil"
    },
    {
      "code": "VG",
      "name": "British Virgin Isles"
    },
    {
      "code": "BN",
      "name": "Brunei Darussalam"
    },
    {
      "code": "BG",
      "name": "Bulgaria"
    },
    {
      "code": "BF",
      "name": "Burkina Faso"
    },
    {
      "code": "MM",
      "name": "Burma"
    },
    {
      "code": "BI",
      "name": "Burundi"
    },
    {
      "code": "KH",
      "name": "Cambodia"
    },
    {
      "code": "CM",
      "name": "Cameroon"
    },
    {
      "code": "CA",
      "name": "Canada"
    },
    {
      "code": "CV",
      "name": "Cape Verde"
    },
    {
      "code": "KY",
      "name": "Cayman Islands"
    },
    {
      "code": "CF",
      "name": "Central African Republic"
    },
    {
      "code": "TD",
      "name": "Chad"
    },
    {
      "code": "CL",
      "name": "Chile"
    },
    {
      "code": "CN",
      "name": "China"
    },
    {
      "code": "CX",
      "name": "Christmas Island"
    },
    {
      "code": "CC",
      "name": "Cocos (Keeling) Islands"
    },
    {
      "code": "CO",
      "name": "Colombia"
    },
    {
      "code": "KM",
      "name": "Comoros"
    },
    {
      "code": "CK",
      "name": "Cook Islands"
    },
    {
      "code": "CR",
      "name": "Costa Rica"
    },
    {
      "code": "CI",
      "name": "Cote d'Ivoire"
    },
    {
      "code": "HR",
      "name": "Croatia"
    },
    {
      "code": "CU",
      "name": "Cuba"
    },
    {
      "code": "CY",
      "name": "Cyprus"
    },
    {
      "code": "CZ",
      "name": "Czech Republic"
    },
    {
      "code": "CD",
      "name": "Dem Rep of Congo"
    },
    {
      "code": "DK",
      "name": "Denmark"
    },
    {
      "code": "DJ",
      "name": "Djibouti"
    },
    {
      "code": "DM",
      "name": "Dominica"
    },
    {
      "code": "DO",
      "name": "Dominican Republic"
    },
    {
      "code": "TL",
      "name": "East Timor"
    },
    {
      "code": "EC",
      "name": "Ecuador"
    },
    {
      "code": "EG",
      "name": "Egypt"
    },
    {
      "code": "SV",
      "name": "El Salvador"
    },
    {
      "code": "GQ",
      "name": "Equatorial Guinea"
    },
    {
      "code": "ER",
      "name": "Eritrea"
    },
    {
      "code": "EE",
      "name": "Estonia"
    },
    {
      "code": "ET",
      "name": "Ethiopia"
    },
    {
      "code": "FK",
      "name": "Falkland Islands"
    },
    {
      "code": "FO",
      "name": "Faroe Islands"
    },
    {
      "code": "FJ",
      "name": "Fiji"
    },
    {
      "code": "FI",
      "name": "Finland"
    },
    {
      "code": "FR",
      "name": "France"
    },
    {
      "code": "FRM",
      "name": "France, Metropolitan"
    },
    {
      "code": "GF",
      "name": "French Guiana"
    },
    {
      "code": "PF",
      "name": "French Polynesia"
    },
    {
      "code": "GA",
      "name": "Gabon"
    },
    {
      "code": "GE",
      "name": "Georgia"
    },
    {
      "code": "DE",
      "name": "Germany"
    },
    {
      "code": "GH",
      "name": "Ghana"
    },
    {
      "code": "GI",
      "name": "Gibraltar"
    },
    {
      "code": "GR",
      "name": "Greece"
    },
    {
      "code": "GL",
      "name": "Greenland"
    },
    {
      "code": "GD",
      "name": "Grenada"
    },
    {
      "code": "GP",
      "name": "Guadeloupe"
    },
    {
      "code": "GU",
      "name": "Guam"
    },
    {
      "code": "GT",
      "name": "Guatemala"
    },
    {
      "code": "GG",
      "name": "Guernsey"
    },
    {
      "code": "GN",
      "name": "Guinea"
    },
    {
      "code": "GW",
      "name": "Guinea-Bissau"
    },
    {
      "code": "GY",
      "name": "Guyana"
    },
    {
      "code": "HT",
      "name": "Haiti"
    },
    {
      "code": "HN",
      "name": "Honduras"
    },
    {
      "code": "HK",
      "name": "Hong Kong (SAR)"
    },
    {
      "code": "HU",
      "name": "Hungary"
    },
    {
      "code": "IS",
      "name": "Iceland"
    },
    {
      "code": "IN",
      "name": "India"
    },
    {
      "code": "ID",
      "name": "Indonesia"
    },
    {
      "code": "IR",
      "name": "Iran"
    },
    {
      "code": "IQ",
      "name": "Iraq"
    },
    {
      "code": "IE",
      "name": "Ireland"
    },
    {
      "code": "IL",
      "name": "Israel"
    },
    {
      "code": "IT",
      "name": "Italy"
    },
    {
      "code": "JM",
      "name": "Jamaica"
    },
    {
      "code": "JP",
      "name": "Japan"
    },
    {
      "code": "JE",
      "name": "Jersey"
    },
    {
      "code": "JO",
      "name": "Jordan"
    },
    {
      "code": "KZ",
      "name": "Kazakhstan"
    },
    {
      "code": "KE",
      "name": "Kenya"
    },
    {
      "code": "KI",
      "name": "Kiribati"
    },
    {
      "code": "KP",
      "name": "Korea, North"
    },
    {
      "code": "KR",
      "name": "Korea, South"
    },
    {
      "code": "KW",
      "name": "Kuwait"
    },
    {
      "code": "KG",
      "name": "Kyrgyzstan"
    },
    {
      "code": "LA",
      "name": "Laos"
    },
    {
      "code": "LV",
      "name": "Latvia"
    },
    {
      "code": "LB",
      "name": "Lebanon"
    },
    {
      "code": "LS",
      "name": "Lesotho"
    },
    {
      "code": "LR",
      "name": "Liberia"
    },
    {
      "code": "LY",
      "name": "Libya"
    },
    {
      "code": "LI",
      "name": "Liechtenstein"
    },
    {
      "code": "LT",
      "name": "Lithuania"
    },
    {
      "code": "LU",
      "name": "Luxembourg"
    },
    {
      "code": "MO",
      "name": "Macao"
    },
    {
      "code": "MK",
      "name": "Macedonia"
    },
    {
      "code": "MG",
      "name": "Madagascar"
    },
    {
      "code": "MW",
      "name": "Malawi"
    },
    {
      "code": "MY",
      "name": "Malaysia"
    },
    {
      "code": "MV",
      "name": "Maldives"
    },
    {
      "code": "ML",
      "name": "Mali"
    },
    {
      "code": "MT",
      "name": "Malta"
    },
    {
      "code": "IM",
      "name": "Man, Isle of"
    },
    {
      "code": "MH",
      "name": "Marshall Islands"
    },
    {
      "code": "MQ",
      "name": "Martinique"
    },
    {
      "code": "MR",
      "name": "Mauritania"
    },
    {
      "code": "MU",
      "name": "Mauritius"
    },
    {
      "code": "YT",
      "name": "Mayotte"
    },
    {
      "code": "MX",
      "name": "Mexico"
    },
    {
      "code": "FM",
      "name": "Micronesia"
    },
    {
      "code": "MD",
      "name": "Moldova"
    },
    {
      "code": "MC",
      "name": "Monaco"
    },
    {
      "code": "MN",
      "name": "Mongolia"
    },
    {
      "code": "ME",
      "name": "Montenegro"
    },
    {
      "code": "MS",
      "name": "Montserrat"
    },
    {
      "code": "MA",
      "name": "Morocco"
    },
    {
      "code": "MZ",
      "name": "Mozambique"
    },
    {
      "code": "MP",
      "name": "N. Mariana Islands"
    },
    {
      "code": "NA",
      "name": "Namibia"
    },
    {
      "code": "NR",
      "name": "Nauru"
    },
    {
      "code": "NP",
      "name": "Nepal"
    },
    {
      "code": "NL",
      "name": "Netherlands"
    },
    {
      "code": "AN",
      "name": "Netherlands Antilles"
    },
    {
      "code": "NC",
      "name": "New Caledonia"
    },
    {
      "code": "NZ",
      "name": "New Zealand"
    },
    {
      "code": "NI",
      "name": "Nicaragua"
    },
    {
      "code": "NE",
      "name": "Niger"
    },
    {
      "code": "NG",
      "name": "Nigeria"
    },
    {
      "code": "NU",
      "name": "Niue"
    },
    {
      "code": "NF",
      "name": "Norfolk Island"
    },
    {
      "code": "NO",
      "name": "Norway"
    },
    {
      "code": "OM",
      "name": "Oman"
    },
    {
      "code": "PK",
      "name": "Pakistan"
    },
    {
      "code": "PW",
      "name": "Palau"
    },
    {
      "code": "PS",
      "name": "Palestinian Territory"
    },
    {
      "code": "PA",
      "name": "Panama"
    },
    {
      "code": "PG",
      "name": "Papua New Guinea"
    },
    {
      "code": "PY",
      "name": "Paraguay"
    },
    {
      "code": "PE",
      "name": "Peru"
    },
    {
      "code": "PH",
      "name": "Philippines"
    },
    {
      "code": "PN",
      "name": "Pitcairn Islands"
    },
    {
      "code": "PL",
      "name": "Poland"
    },
    {
      "code": "PT",
      "name": "Portugal"
    },
    {
      "code": "PR",
      "name": "Puerto Rico"
    },
    {
      "code": "QA",
      "name": "Qatar"
    },
    {
      "code": "CG",
      "name": "Republic of Congo"
    },
    {
      "code": "RE",
      "name": "Réunion"
    },
    {
      "code": "RO",
      "name": "Romania"
    },
    {
      "code": "RU",
      "name": "Russia"
    },
    {
      "code": "RW",
      "name": "Rwanda"
    },
    {
      "code": "SH",
      "name": "Saint Helena"
    },
    {
      "code": "KN",
      "name": "Saint Kitts & Nevis"
    },
    {
      "code": "LC",
      "name": "Saint Lucia"
    },
    {
      "code": "PM",
      "name": "Saint Pierre & Miquelon"
    },
    {
      "code": "VC",
      "name": "Saint Vincent/Grenadines"
    },
    {
      "code": "WS",
      "name": "Samoa"
    },
    {
      "code": "SM",
      "name": "San Marino"
    },
    {
      "code": "ST",
      "name": "São Tomé and Príncipe"
    },
    {
      "code": "SA",
      "name": "Saudi Arabia"
    },
    {
      "code": "SN",
      "name": "Senegal"
    },
    {
      "code": "RS",
      "name": "Serbia"
    },
    {
      "code": "SC",
      "name": "Seychelles"
    },
    {
      "code": "SL",
      "name": "Sierra Leone"
    },
    {
      "code": "SG",
      "name": "Singapore"
    },
    {
      "code": "SK",
      "name": "Slovakia"
    },
    {
      "code": "SI",
      "name": "Slovenia"
    },
    {
      "code": "SB",
      "name": "Solomon Islands"
    },
    {
      "code": "SO",
      "name": "Somalia"
    },
    {
      "code": "ZA",
      "name": "South Africa"
    },
    {
      "code": "ES",
      "name": "Spain"
    },
    {
      "code": "LK",
      "name": "Sri Lanka"
    },
    {
      "code": "SD",
      "name": "Sudan"
    },
    {
      "code": "SR",
      "name": "Suriname"
    },
    {
      "code": "SJ",
      "name": "Svalbard"
    },
    {
      "code": "SZ",
      "name": "Swaziland"
    },
    {
      "code": "SE",
      "name": "Sweden"
    },
    {
      "code": "CH",
      "name": "Switzerland"
    },
    {
      "code": "SY",
      "name": "Syria"
    },
    {
      "code": "TW",
      "name": "Taiwan"
    },
    {
      "code": "TJ",
      "name": "Tajikistan"
    },
    {
      "code": "TZ",
      "name": "Tanzania"
    },
    {
      "code": "TH",
      "name": "Thailand"
    },
    {
      "code": "BS",
      "name": "The Bahamas"
    },
    {
      "code": "GM",
      "name": "The Gambia"
    },
    {
      "code": "TG",
      "name": "Togo"
    },
    {
      "code": "TK",
      "name": "Tokelau"
    },
    {
      "code": "TO",
      "name": "Tonga"
    },
    {
      "code": "TT",
      "name": "Trinidad and Tobago"
    },
    {
      "code": "TN",
      "name": "Tunisia"
    },
    {
      "code": "TR",
      "name": "Turkey"
    },
    {
      "code": "TM",
      "name": "Turkmenistan"
    },
    {
      "code": "TC",
      "name": "Turks & Caicos Islands"
    },
    {
      "code": "TV",
      "name": "Tuvalu"
    },
    {
      "code": "UG",
      "name": "Uganda"
    },
    {
      "code": "UA",
      "name": "Ukraine"
    },
    {
      "code": "AE",
      "name": "United Arab Emirates"
    },
    {
      "code": "GB",
      "name": "United Kingdom"
    },
    {
      "code": "US",
      "name": "United States"
    },
    {
      "code": "UY",
      "name": "Uruguay"
    },
    {
      "code": "UZ",
      "name": "Uzbekistan"
    },
    {
      "code": "VU",
      "name": "Vanuatu"
    },
    {
      "code": "VA",
      "name": "Vatican City"
    },
    {
      "code": "VE",
      "name": "Venezuela"
    },
    {
      "code": "VN",
      "name": "Vietnam"
    },
    {
      "code": "VI",
      "name": "Virgin Islands"
    },
    {
      "code": "WF",
      "name": "Wallis and Futuna"
    },
    {
      "code": "EH",
      "name": "Western Sahara"
    },
    {
      "code": "YE",
      "name": "Yemen"
    },
    {
      "code": "ZM",
      "name": "Zambia"
    },
    {
      "code": "ZW",
      "name": "Zimbabwe"
    }
  ]

export const States: Array<any> = [
    {
      "code": "AL",
      "name": "Alabama",
      "country": "US"
    },
    {
      "code": "AK",
      "name": "Alaska",
      "country": "US"
    },
    {
      "code": "AB",
      "name": "Alberta",
      "country": "CA"
    },
    {
      "code": "AS",
      "name": "American Samoa",
      "country": "US"
    },
    {
      "code": "AZ",
      "name": "Arizona",
      "country": "US"
    },
    {
      "code": "AR",
      "name": "Arkansas",
      "country": "US"
    },
    {
      "code": "AA",
      "name": "Armed Forces Africa",
      "country": "US"
    },
    {
      "code": "AAM",
      "name": "Armed Forces Americas",
      "country": "US"
    },
    {
      "code": "AC",
      "name": "Armed Forces Canada",
      "country": "US"
    },
    {
      "code": "AU",
      "name": "Armed Forces Europe",
      "country": "US"
    },
    {
      "code": "AME",
      "name": "Armed Forces Middle East",
      "country": "US"
    },
    {
      "code": "AP",
      "name": "Armed Forces Pacific",
      "country": "US"
    },
    {
      "code": "BC",
      "name": "British Columbia",
      "country": "CA"
    },
    {
      "code": "CA",
      "name": "California",
      "country": "US"
    },
    {
      "code": "CO",
      "name": "Colorado",
      "country": "US"
    },
    {
      "code": "CT",
      "name": "Connecticut",
      "country": "US"
    },
    {
      "code": "DE",
      "name": "Delaware",
      "country": "US"
    },
    {
      "code": "DC",
      "name": "District of Columbia",
      "country": "US"
    },
    {
      "code": "FL",
      "name": "Florida",
      "country": "US"
    },
    {
      "code": "GA",
      "name": "Georgia",
      "country": "US"
    },
    {
      "code": "GU",
      "name": "Guam",
      "country": "US"
    },
    {
      "code": "HI",
      "name": "Hawaii",
      "country": "US"
    },
    {
      "code": "ID",
      "name": "Idaho",
      "country": "US"
    },
    {
      "code": "IL",
      "name": "Illinois",
      "country": "US"
    },
    {
      "code": "IN",
      "name": "Indiana",
      "country": "US"
    },
    {
      "code": "IA",
      "name": "Iowa",
      "country": "US"
    },
    {
      "code": "KS",
      "name": "Kansas",
      "country": "US"
    },
    {
      "code": "KY",
      "name": "Kentucky",
      "country": "US"
    },
    {
      "code": "LA",
      "name": "Louisiana",
      "country": "US"
    },
    {
      "code": "ME",
      "name": "Maine",
      "country": "US"
    },
    {
      "code": "MB",
      "name": "Manitoba",
      "country": "CA"
    },
    {
      "code": "MH",
      "name": "Marshall Islands",
      "country": "US"
    },
    {
      "code": "MD",
      "name": "Maryland",
      "country": "US"
    },
    {
      "code": "MA",
      "name": "Massachusetts",
      "country": "US"
    },
    {
      "code": "MI",
      "name": "Michigan",
      "country": "US"
    },
    {
      "code": "FM",
      "name": "Micronesia",
      "country": "US"
    },
    {
      "code": "MN",
      "name": "Minnesota",
      "country": "US"
    },
    {
      "code": "MS",
      "name": "Mississippi",
      "country": "US"
    },
    {
      "code": "MO",
      "name": "Missouri",
      "country": "US"
    },
    {
      "code": "MT",
      "name": "Montana",
      "country": "US"
    },
    {
      "code": "NE",
      "name": "Nebraska",
      "country": "US"
    },
    {
      "code": "NV",
      "name": "Nevada",
      "country": "US"
    },
    {
      "code": "NB",
      "name": "New Brunswick",
      "country": "CA"
    },
    {
      "code": "NH",
      "name": "New Hampshire",
      "country": "US"
    },
    {
      "code": "NJ",
      "name": "New Jersey",
      "country": "US"
    },
    {
      "code": "NM",
      "name": "New Mexico",
      "country": "US"
    },
    {
      "code": "NY",
      "name": "New York",
      "country": "US"
    },
    {
      "code": "NL",
      "name": "Newfoundland and Labrador",
      "country": "CA"
    },
    {
      "code": "NC",
      "name": "North Carolina",
      "country": "US"
    },
    {
      "code": "ND",
      "name": "North Dakota",
      "country": "US"
    },
    {
      "code": "MP",
      "name": "Northern Mariana Islands",
      "country": "US"
    },
    {
      "code": "NT",
      "name": "Northwest Territories",
      "country": "CA"
    },
    {
      "code": "NS",
      "name": "Nova Scotia",
      "country": "CA"
    },
    {
      "code": "NU",
      "name": "Nunavut",
      "country": "CA"
    },
    {
      "code": "OH",
      "name": "Ohio",
      "country": "US"
    },
    {
      "code": "OK",
      "name": "Oklahoma",
      "country": "US"
    },
    {
      "code": "ON",
      "name": "Ontario",
      "country": "CA"
    },
    {
      "code": "OR",
      "name": "Oregon",
      "country": "US"
    },
    {
      "code": "PW",
      "name": "Palau",
      "country": "US"
    },
    {
      "code": "PA",
      "name": "Pennsylvania",
      "country": "US"
    },
    {
      "code": "PE",
      "name": "Prince Edward Island",
      "country": "CA"
    },
    {
      "code": "PR",
      "name": "Puerto Rico",
      "country": "US"
    },
    {
        "code": "QC",
        "name": "Quebec",
        "country": "CA"
    },
    {
        "code": "RI",
        "name": "Rhode Island",
        "country": "US"
    },
    {
        "code": "SK",
        "name": "Saskatchewan",
        "country": "CA"
      },
    {
      "code": "SC",
      "name": "South Carolina",
      "country": "US"
    },
    {
      "code": "SD",
      "name": "South Dakota",
      "country": "US"
    },
    {
      "code": "TN",
      "name": "Tennessee",
      "country": "US"
    },
    {
      "code": "TX",
      "name": "Texas",
      "country": "US"
    },
    {
      "code": "UT",
      "name": "Utah",
      "country": "US"
    },
    {
      "code": "VT",
      "name": "Vermont",
      "country": "US"
    },
    {
      "code": "VI",
      "name": "Virgin Islands",
      "country": "US"
    },
    {
      "code": "VA",
      "name": "Virginia",
      "country": "US"
    },
    {
      "code": "WA",
      "name": "Washington",
      "country": "US"
    },
    {
      "code": "WV",
      "name": "West Virginia",
      "country": "US"
    },
    {
      "code": "WI",
      "name": "Wisconsin",
      "country": "US"
    },
    {
      "code": "WY",
      "name": "Wyoming",
      "country": "US"
    },
    {
      "code": "YT",
      "name": "Yukon",
      "country": "CA"
    }
]

export class Customer {

    constructor(customer?: any) {
        if (!customer) return;

        this.id = customer.id;
        this.user = new User(customer);
        this.isGoldClubMember = customer.isGoldClubMember;
        this.customerNumber = customer.customerNumber;
        this.phoneNumber = customer.phoneNumber;
        this.preferences = customer.preferences;
        this.addresses = _.map(customer.addresses, a => new Address(a));
        this.imageAccountUrl = customer.imageAccountUrl;
    }
    user = new User();
    preferences!: {
      currentFedexAccount: string,
      useFedexAccount: boolean,
      encapsulationRequested: boolean,
      fedexAccountPostalCode: string,
      mktgOptInSGCProductsPricing: boolean,
      mktgOptInSGCMarketing: boolean,
      mktgOptIn3PartyMarketing: boolean,
      custReqAcctDelete: boolean,
      myCardsAddAfterShipped: boolean
  };
    addresses= new Array<Address>();
    isGoldClubMember!: boolean;
    customerNumber!: number;
    phoneNumber!: string;
    imageAccountUrl!: string;
    id!: string;
    eligibleForMerging: boolean = true;
    mergingRequested: boolean = false;

    getName(): string {
        return this.user && this.user.name;
    }
    
    getEmail(): string {
        return this.user && this.user.email;
    }
}


export class UserOrderInfo {

    constructor(userOrderInfo?: any) {
        if (!userOrderInfo) return;
        this.id = userOrderInfo.id;
        this.shippingAddressId = userOrderInfo.shippingAddressId;
        this.publicId = userOrderInfo.publicId;
        this.status = userOrderInfo.status;
    }
    id!: string;
    shippingAddressId!: string;
    publicId!: number;
    status!: string;
}


