<img style="top:10px" src="/assets/img/close-modal.svg" class="modal__close" (click)="close()">
<div style="margin-top:4px" class="feature-not-available">
    <div>
        <div>
            <p style="font-size:18px;text-align: left;">Are you sure you want to delete this Address?</p>
        </div>
        <br />
        <br />
        <div>
            <button class="button" style="margin-left:32%" (click)="deleteAddress()">Yes</button>
            <button style="margin-left:15px;" class="button" (click)="close()">NO</button>
        </div>
    </div>
</div>
