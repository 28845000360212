<div class="modal__content">
    <div class="modal__list">
        <div class="select">
            <select class="input" [ngModel]="updatedAddress.country.code" (ngModelChange)="onCountryChange($event)">
                <option value="" disabled>Country</option>
                <option value="{{country.code}}" *ngFor="let country of countries">{{country.name}}</option>
            </select>
        </div>
        <input type="text" class="input" placeholder="Street and number, P.O. box, c/o." [(ngModel)]="updatedAddress.street1">
        <input type="text" class="input" placeholder="Apartment, suite, unit, building, floor, etc." [(ngModel)]="updatedAddress.street2">
        <input type="text" class="input" placeholder="City" [(ngModel)]="updatedAddress.city">
        <div class="select">
            <select class="input" [(ngModel)]="updatedAddress.state.code" [disabled]="!canSelectState()">
                <option value="" disabled>State / Province</option>
                <option value="{{state.code}}" *ngFor="let state of states">{{state.name}}</option>
            </select>
        </div>
        <input type="text" class="input" placeholder="Zip Code" [(ngModel)]="updatedAddress.zipCode">
        <input type="text" class="input" placeholder="Phone number" [(ngModel)]="updatedAddress.phoneNumber">
    
        <label class="toggle">
            Make it my default address
            <input type="checkbox" [(ngModel)]="updatedAddress.isDefault">
            <span class="toggle__check"></span>
        </label>
    </div>
</div>
<div class="modal__actions">
    <div *ngIf="!userhasaddress">
        <button class="button button--secondary" (click)="discard()">Cancel</button>
    </div>
    <button class="button" (click)="save()" [disabled]="!canSave() || isSubmitting" [class.button--disabled]="!canSave() || isSubmitting">
        <div *ngIf="isSubmitting" class="loader xs invert-color"></div>
        <span *ngIf="!isSubmitting">Save</span>
    </button>
</div>
