import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'autographGradingFormat'
})
export class AutographGradingFormatPipe implements PipeTransform {
    transform(item: any): string {
        let output: string = null!;

        if (!item) return null!;
        
        if (item.settings.autographGrading) {
            output = 'Auto Grade';
        }

        if (item.settings.cardGrading) {
            if (!output) {
                output = 'Card Grade';
            } else {
                output += ', Card Grade';
            }
        }

        if (!output) {
            output = 'No Grading Service Selected';
        }

        return output;
    }
}