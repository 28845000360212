import { Component, OnInit } from '@angular/core';

import {
  ModalService,
  ModalInterface,
} from '../../../../../common/services/modal.service';
import { OrderService } from '../../../../../orders/services/order.service';

@Component({
  //moduleId: module.id,
  selector: 'select-sticker-placement',
  templateUrl: './select-sticker-placement.component.html',
  styleUrls: ['../../../../checkout.component.sass'],
})
export class SelectStickerPlacementComponent implements ModalInterface, OnInit {
  constructor(
    private modalService: ModalService,
    private orderService: OrderService
  ) {}

  title = 'Sticker Placement';
  subtitle =
    'Please choose where you want the authentication sticker to be placed';
  type = 'select-sticker-placement';
  options: any;
  stickerPlacement!: any[];

  select(placement: any) {
    this.options.settings.stickerOnItem = placement.onItem;
  }

  isSelected(placement: any): boolean {
    return this.options.settings.stickerOnItem == placement.onItem;
  }

  ngOnInit() {
    this.stickerPlacement = [
      { name: 'On Item', onItem: true },
      { name: 'On Letter', onItem: false },
    ];
  }
}
