<div class="content content--compact">
    <img src="/assets/img/logoInvoice.svg" alt="SGC" style="width:100px;">
    <div>
        <h2>Ooops, something went wrong...</h2>
        <p>Don't worry! You can go back and try again or head to our homepage.</p>
    </div>
    <p>
        <a class="button">Go back</a>
        <a routerLink="/" class="button" style="background:#000;">Take me home</a>
    </p>
</div>
