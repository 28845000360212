import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { UserService } from '../../services/user.service';
import { StateService } from '../../services/state.service';
import { AuthService } from '../../services/auth.service';
import { MonitorService } from '../../services/monitor.service';
import { CheckoutService } from '../../../checkout/services/checkout.service';

@Component({
    selector: 'sgc-welcome',
    templateUrl: './welcome.component.html'
})
export class WelcomeComponent implements OnInit {

    constructor(
        private userService: UserService,
        private stateService: StateService,
        private authService: AuthService,
        private monitorService: MonitorService,
        private checkoutService: CheckoutService,
        private router: Router) { }

    ngOnInit() {

        this.toggleMobileMenu()
       this.authService.handleAuthentication().subscribe((result) => {
  var isAuthenticated = this.authService.isAuthenticated();

  if (!result && !isAuthenticated) {
    this.router.navigate(['/login']);
    return; // Add return statement here
  }

  this.userService.getCurrentUser().subscribe((user: any) => {

                this.monitorService.setAuthenticatedUserId(user.id);
                this.stateService.currentUser.set(user);

                var redirectUrl = localStorage.getItem('redirectUrl') || '/account/dashboard';

                localStorage.removeItem('redirectUrl');

                this.router.navigate([redirectUrl]);

                this.userService.userLastLoggedIn(user.id).subscribe((user:any) => {

                });
            });
        });
    }
    toggleMobileMenu() {
        // debugger
        this.checkoutService.setinit()
        // this.checkoutService.cancelCheckout()
    }
}
