import { Component, OnInit, HostListener, forwardRef, Inject, ViewChild, ChangeDetectorRef, ApplicationRef, ElementRef, NgZone, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { OrderService } from "../../../orders/services/order.service";
import { MyCardOrderItems, DownlaodCardOrderItems, MyCardOrderItemsGrade, MyCardOrderItemsGradeFilterPipe, gradeitems, CheckDownlaodCardOrderItems } from "../../../orders/models/order";
import { GridComponent, FilterSettingsModel, IFilter, TextWrapSettingsModel, PageSettingsModel, DataStateChangeEventArgs, DataResult } from '@syncfusion/ej2-angular-grids';
import { StateService } from "../../../common/services/state.service";
import { User } from "../../../common/models/user";
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import * as _ from 'lodash';
import { DOCUMENT } from '@angular/common';
import { ModalService } from '../../../common/services/modal.service';
import { ToastrService } from 'ngx-toastr';
//import { isNumeric } from 'rxjs';
import { PopReportsService } from '../../../pop-reports/services/pop-reports.service';
import { PopPipelineStatus, PopReportAuthCode } from '../../../pop-reports/models/PopulationReport';
import { UserService } from '../../../common/services/user.service';

import {
  Ribbon, TabSelectingEventArgs, RibbonButtonSettingsModel,
  RibbonComboBoxSettingsModel, RibbonItemSize, RibbonCheckBoxSettingsModel, FileMenuSettingsModel, FileMenuEventArgs
} from '@syncfusion/ej2-ribbon';
import { MenuItemModel } from '@syncfusion/ej2-angular-navigations';
import { Subscription, interval, switchMap } from 'rxjs';
import { RibbonComponent } from '@syncfusion/ej2-angular-ribbon';


@Component({
  selector: 'app-mycards',
  templateUrl: './mycards.component.html',
  styleUrls: ['./mycards.component.css',
    './myCardsBootstrap.component.css',
    './mycards-download.component.css'
  ]
})
export class MycardsComponent implements OnInit, OnDestroy {

  public PopPipelineStatus!: PopPipelineStatus[];
  PopLineheck = true;
  authCodeResult = new PopReportAuthCode();
  private fetchInterval!: Subscription;
  isCheckboxDisabled = true;

  deleteResponse: any;
  router: any;
  constructor(
    private toastr: ToastrService,
    private modalService: ModalService,
    private orderService: OrderService,
    private stateService: StateService,
    private formBuilder: FormBuilder,
    private popReportsService: PopReportsService,
    private userService: UserService
  ) { }


  @ViewChild('ribbon') ribbon!: RibbonComponent;


  public fontSize: string[] = ["8", "9", "10", "11", "12", "14", "16", "18", "20", "22", "24", "26", "28", "36", "48", "72", "96"];
  public fontStyle: string[] = ["Algerian", "Arial", "Calibri", "Cambria", "Cambria Math", "Courier New", "Candara", "Georgia", "Impact", "Segoe Print", "Segoe Script", "Segoe UI", "Symbol", "Times New Roman", "Verdana", "Windings"];

  selectedSport: any;
  selectedSet: any;
  selectedSubject: any;
  selectedYearFrom: any;
  selectedYearTo: any;
  selectedCardNo: any;
  selectedGrade: any;
  selectedCertCode: any;
  selectedOrdNo: any;
  selectedAutoOnly: boolean = false;
  selectedGradeOrBetter: boolean = false;
  selectedDownloadType: any;
  availableForDownload: number = 0;
  willBeReadySoon: number = 0;
  cardAddedStatus: string = "";
  cardExistMsg: string = "";

  searchAuthCode: FormControl = new FormControl('');
  searchCardNumber: FormControl = new FormControl('');
  searchCardSet: FormControl = new FormControl('');
  searchCardSubject: FormControl = new FormControl('');
  searchSport: FormControl = new FormControl('');
  yearFrom: FormControl = new FormControl('');
  yearTo: FormControl = new FormControl('');
  searchOrderNumber: FormControl = new FormControl('');


  SelectCardOptions: FormControl = new FormControl('');
  searchFormattedGrade: FormControl = new FormControl('');
  searchAutoOnly: FormControl = new FormControl(false);
  searchGradeOrBetter: FormControl = new FormControl(false);


  public downlaodCardOrderItems: DownlaodCardOrderItems[] = []
  currentUser = new User();
  MyCardOrderItems: MyCardOrderItems[] = [];
  MyCardOrderItemLength: number = 0;
  MyCardOrderItemsForGradeOrBetter: MyCardOrderItemsGrade[] = [];
  MyCardOrderItemsGradeFilterPipe: MyCardOrderItemsGradeFilterPipe[] = [];
  public allGradeItems: gradeitems[] = [];


  public format = { type: 'date', format: 'dd/MM/yyyy hh:mm:ss a' }
  public FilterOptions: FilterSettingsModel = {
    type: 'Menu',
  };
  public filter: IFilter = {
    params: {
      format: 'dd/MM/yyyy-hh:mm'
    }
  };
  public pageSettings!: PageSettingsModel;
  public wrapSettings!: TextWrapSettingsModel;
  checkDownlaodCardOrderItems!: CheckDownlaodCardOrderItems[];
  allCheckDownlaodCardOrderItems!: CheckDownlaodCardOrderItems[];

  twoCol: boolean = false;
  fourCol: boolean = false;
  sixCol: boolean = true;
  onecol: boolean = false;
  cardsLoad: boolean = false;
  isCardsLoading: boolean = false;
  isRefreshing: boolean = false;
  downLoadRequest: string = "";
  isCertLookup: boolean = false;
  form!: FormGroup;
  showCertCodeMsg = false;
  showPopReport = false;
  authCode: any;
  grade: any;
  subject: any;
  showImage = false;
  userSignIn = false;
  h275FrontImage: any;
  h275BackImage: any;
  h692FrontImage: any;
  h692BackImage: any;
  h1000FrontImage: any;
  h1000BackImage: any;
  emptymsg = false;
  authcertcode: any;
  authcertcodelength = false;
  btnAuthPopLoad = false;
  showgradeSubject: any;

  TotalorderItemGuidList: any = 0;
  MyCardOrderItemsCount: any;
  allOrderItemGuidList: any = "";
  activeSports: any = "";
  orderNumbers: any = "";
  grades: any = "";
  gradesList: any = "";
  gradeone: any = "";
  gradetwo: any = "";
  gradethree: any = "";
  filterrec: any = "";
  cardsMsg: string = "";
  msgColor: string = "";


  //public Overflow: DisplayMode = DisplayMode.Overflow;
  //public position = { X: 'Right' };
  //public animation = { show: { effect: 'FadeIn' }, hide: { effect: 'FadeOut' } };

  public largeSize: RibbonItemSize = RibbonItemSize.Large;
  public smallSize: RibbonItemSize = RibbonItemSize.Small;
  activeTab: number = 0;
  public tabSelecting(args: TabSelectingEventArgs) {
    debugger;
    if (args.selectedIndex == 0) {
      this.selectTab(1, 'home');
      this.activeTab = 1;
    }
    if (args.selectedIndex == 1) {
      this.activeTab = 2;
      this.selectTab(3, 'completed');
    }
  }

  ngOnDestroy(): void {
    // Clear the interval when the component is destroyed
    if (this.fetchInterval) {
      this.fetchInterval.unsubscribe();
    }
  }

  public cardAddButton: RibbonButtonSettingsModel = {
    iconCss: "e-icons e-plus-2", content: "Add Card(s)",
    clicked: () => {
      this.certCodeLookup();
    }
  };
  public downlaodAddButton: RibbonButtonSettingsModel = {
    iconCss: "e-icons e-download", content: "Download", cssClass: 'e-download-button',
    clicked: () => {
      this.DownloadAll();
    }
  };
  sportDdl: RibbonComboBoxSettingsModel = {
    dataSource: [], width: '140px', popupWidth: '150px', placeholder: 'Sport', autofill: false, allowFiltering: true,
    change: (args) => {
      if (args.itemData) {
        this.selectedSport = args.itemData.text;
        this.filterItems();
      }
      else {
        this.selectedSport = null;
        this.filterItems();
      }
    }
  };
  public setDdl: RibbonComboBoxSettingsModel = {
    dataSource: [], width: '140px', popupWidth: '150px', placeholder: 'Set', autofill: false, allowFiltering: true,
    change: (args) => {
      if (args.itemData) {
        this.selectedSet = args.itemData.text;
        this.filterItems();
      }
      else {
        this.selectedSet = null;
        this.filterItems();
      }
    }
  };
  public subjectDdl: RibbonComboBoxSettingsModel = {
    dataSource: [], width: '140px', popupWidth: '150px', placeholder: 'Subject', autofill: false, allowFiltering: true,
    change: (args) => {
      if (args.itemData) {
        this.selectedSubject = args.itemData.text;
        this.filterItems();
      }
      else {
        this.selectedSubject = null;
        this.filterItems();
      }
    }
  };
  public yearFromDdl: RibbonComboBoxSettingsModel = {
    dataSource: [], width: '140px', popupWidth: '150px', placeholder: 'Year From', autofill: false, allowFiltering: true,
    change: (args) => {
      if (args.itemData) {
        this.selectedYearFrom = args.itemData.text;
        this.filterItems();
      }
      else {
        this.selectedYearFrom = null;
        this.filterItems();
      }
    }
  };
  public yearToDdl: RibbonComboBoxSettingsModel = {
    dataSource: [], width: '140px', popupWidth: '150px', placeholder: 'Year To', autofill: false, allowFiltering: true,
    change: (args) => {
      if (args.itemData) {
        this.selectedYearTo = args.itemData.text;
        this.filterItems();
      }
      else {
        this.selectedYearTo = null;
        this.filterItems();
      }
    }
  };
  public cardNoDdl: RibbonComboBoxSettingsModel = {
    dataSource: [], width: '140px', popupWidth: '150px', placeholder: 'Card #', autofill: false, allowFiltering: true,
    change: (args) => {
      if (args.itemData) {
        this.selectedCardNo = args.itemData.text;
        this.filterItems();
      }
      else {
        this.selectedCardNo = null;
        this.filterItems();
      }
    }
  };
  public gradeDdl: RibbonComboBoxSettingsModel = {
    dataSource: [], width: '140px', popupWidth: '150px', placeholder: 'Grade', autofill: false, allowFiltering: true,
    change: (args) => {
      if (args.itemData) {
        this.selectedGrade = args.itemData.text;
        this.filterItems();
      }
      else {
        this.selectedGrade = null;
        this.filterItems();
      }
    }
  };
  public certCodeDdl: RibbonComboBoxSettingsModel = {
    dataSource: [], width: '140px', popupWidth: '150px', placeholder: 'Cert.Code', autofill: false, allowFiltering: true,
    change: (args) => {
      if (args.itemData) {
        this.selectedCertCode = args.itemData.text;
        this.filterItems();
      }
      else {
        this.selectedCertCode = null;
        this.filterItems();
      }
    }
  };

  public ordNoDdl: RibbonComboBoxSettingsModel = {
    dataSource: [], width: '140px', popupWidth: '150px', placeholder: 'Order No.', allowFiltering: true,
    change: (args) => {
      if (args.itemData) {
        this.selectedOrdNo = args.itemData.value;
        this.filterItems();
      }
      else {
        this.selectedOrdNo = null;
        this.filterItems();
      }
    }
  };
  public autoOnlyChkB: RibbonCheckBoxSettingsModel = {
    label: "Autos Only", checked: false,
    change: (args) => {
      this.selectedAutoOnly = args.checked;
      this.filterItems();
    }
  };
  public gradeOrBetterChk: RibbonCheckBoxSettingsModel = {
    label: "This Grade or Better", checked: false,
    change: (args) => {
      this.selectedGradeOrBetter = args.checked;
      this.filterItems();
    }
  };


  public listButton: RibbonButtonSettingsModel = {
    iconCss: "e-icons e-level-1", content: "List",
    clicked: () => {
      this.ShowoneCol();
    }
  };
  public gallery6Button: RibbonButtonSettingsModel = {
    iconCss: "e-icons e-grip-vertical", content: "Gallery 6",
    clicked: () => {
      this.ShowsixCol();
    }
  };
  public gallery4Button: RibbonButtonSettingsModel = {
    iconCss: "e-icons e-level-4", content: "Gallery 4",
    clicked: () => {
      this.ShowfourCol();
    }
  };
  public gallery2Button: RibbonButtonSettingsModel = {
    iconCss: "e-icons e-level-2", content: "Gallery 2",
    clicked: () => {
      this.ShowtwoCol();
    }
  };

  public selectAllButFilter: RibbonButtonSettingsModel = { iconCss: "e-icons e-select-all", content: "Select All Cards", clicked: () => { this.selectall(); } };
  public clearAllButFilter: RibbonButtonSettingsModel = { iconCss: "e-icons e-erase", content: "Clear Selection", clicked: () => { this.clearall(); } };

  public downlaodTypeData: string[] = ["Front & Back", "Front Only", "Back Only"];
  public downlaodTypeDdl: RibbonComboBoxSettingsModel = {
    dataSource: this.downlaodTypeData, width: '140px', popupWidth: '150px', placeholder: 'Download Type', autofill: false, allowFiltering: true,
    change: (args) => {
      if (args.itemData) {
        if (args.itemData.text == 'Front & Back') {
          this.selectedDownloadType = '_F_B';
        }
        if (args.itemData.text == 'Front Only') {
          this.selectedDownloadType = '_F';
        }
        if (args.itemData.text == 'Back Only') {
          this.selectedDownloadType = '_B';
        }
      }
      else {
        this.selectedDownloadType = null;
      }
    }
  };

  ngOnInit() {
    this.pageSettings = { pageSize: 10, pageCount: 4 };
    this.wrapSettings = { wrapMode: 'Both' };
    this.stateService.currentUser.changes.subscribe(user => {
      this.currentUser = user;
    });
    this.selectedSport = null;
    this.selectedSet = null;
    this.selectedSubject = null;
    this.selectedYearFrom = null;
    this.selectedYearTo = null;
    this.selectedCardNo = null;
    this.selectedGrade = null;
    this.selectedCertCode = null;
    this.selectedOrdNo = null;
    this.selectedDownloadType = null;

    this.CheckCardDownloadStatus();
    this.PageMainCall();

    this.fetchInterval = interval(120000) // 120000 ms = 2 minutes
      .pipe(
        switchMap(() => this.orderService.GetStatusForDownloadCards(this.currentUser.id))
      )
      .subscribe(response => {
        this.allCheckDownlaodCardOrderItems = response;
        this.checkDownlaodCardOrderItems = this.allCheckDownlaodCardOrderItems.filter(item => item.zipRequestState === 3);

        let availableForDownloadCount = 0;
        let willBeReadySoonCount = 0;
        for (let item of this.allCheckDownlaodCardOrderItems) {
          if (item.zipRequestState == 3) {
            item.zipRequestStateStatus = "Available For Download";
            availableForDownloadCount++;
          }
          else {
            item.zipRequestStateStatus = "Will Be Ready Soon";
            willBeReadySoonCount++;
          }
          if (item.zipGeneratedOn == "0001-01-01T00:00:00") {
            item.zipGeneratedOn = "";
          }
        }

        this.availableForDownload = availableForDownloadCount;
        this.willBeReadySoon = willBeReadySoonCount;
      });
  }

  CheckCardDownloadStatus() {
    this.orderService.GetStatusForDownloadCards(this.currentUser.id).subscribe(response => {
      this.allCheckDownlaodCardOrderItems = response;
      this.checkDownlaodCardOrderItems = this.allCheckDownlaodCardOrderItems.filter(item => item.zipRequestState === 3);

      let availableForDownloadCount = 0;
      let willBeReadySoonCount = 0;
      for (let item of this.allCheckDownlaodCardOrderItems) {
        if (item.zipRequestState == 3) {
          item.zipRequestStateStatus = "Available For Download";
          availableForDownloadCount++;
        }
        else {
          item.zipRequestStateStatus = "Will Be Ready Soon";
          willBeReadySoonCount++;
        }
        if (item.zipGeneratedOn == "0001-01-01T00:00:00") {
          item.zipGeneratedOn = "";
        }
      }

      this.availableForDownload = availableForDownloadCount;
      this.willBeReadySoon = willBeReadySoonCount;
    });
  }

  LookUpQuestions() {
    this.router.navigate(['/pop-report/authcode-questions/', this.authcertcode]);
  }

  download(zipUrl: any) {
    const blob = new Blob([zipUrl], {
      type: 'application/zip'
    });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }

  selectedTab: number = 1;
  selectTab(selectedTab: number, downloadType: string) {
    this.cardsMsg = "";
    this.selectedTab = selectedTab;

    if (this.selectedTab == 3) {
      this.isCertLookup = false;
      if (downloadType == 'completed') {
        this.checkDownlaodCardOrderItems = this.allCheckDownlaodCardOrderItems.filter(item => item.zipRequestState === 3);
      }
      else {
        this.checkDownlaodCardOrderItems = this.allCheckDownlaodCardOrderItems.filter(item => item.zipRequestState != 3);
      }
    }
    if (this.selectedTab == 1) {
      if (downloadType == 'home') {
        this.isCertLookup = false;
        this.cardAddedStatus = "";
        this.cardExistMsg = "";
        this.selectedTab = 1;
      }
    }
  }


  getCardGrade(item: MyCardOrderItems): string {
    if (item.cardRejectionDescription != null && (item.cardGrade == '0 A' || item.cardGrade == 'AUTHENTIC')) {
      var grd = item.cardGrade + ' ' + item.cardRejectionDescription
      return grd;
    }
    else
      return item.cardGrade;
  }

  onImgError(item: MyCardOrderItems, event: any) {
    event.target.src = 'assets/img/noimageavailable.jpeg';
  }

  bindRibbonData() {
    const sprt = this.MyCardOrderItemsForGradeOrBetter.map(item => item.sport)
      .filter((value, index, self) => self.indexOf(value) === index);
    sprt.sort();

    const uniqueOrderNumbers = this.MyCardOrderItemsForGradeOrBetter.filter(item => item.myCardOwnerId === this.currentUser.id && item.myCardOwnerId !== '0')
      .map(item => item.orderNumber)
      .filter((value, index, self) => self.indexOf(value) === index);
    uniqueOrderNumbers.sort((a: any, b: any) => (a > b ? -1 : 1))

    const certCode = this.MyCardOrderItemsForGradeOrBetter.map(item => item.certificationCode)
      .filter((value, index, self) => self.indexOf(value) === index);
    certCode.sort((a: any, b: any) => (a > b ? -1 : 1))

    this.grades = this.MyCardOrderItemsForGradeOrBetter.map(item => item.cardGrade)
      .filter((value, index, self) => self.indexOf(value) === index);

    const set = this.MyCardOrderItemsForGradeOrBetter.map(item => item.cardSet)
      .filter((value, index, self) => self.indexOf(value) === index);
    set.sort((a: any, b: any) => (a > b ? -1 : 1))

    const yFrom = this.MyCardOrderItemsForGradeOrBetter.map(item => item.yearFrom)
      .filter((value, index, self) => self.indexOf(value) === index);
    yFrom.sort((a: any, b: any) => (a > b ? -1 : 1))

    const yTo = this.MyCardOrderItemsForGradeOrBetter.map(item => item.yearTo)
      .filter((value, index, self) => self.indexOf(value) === index);
    yTo.sort((a: any, b: any) => (a > b ? -1 : 1))

    const cSubject = this.MyCardOrderItemsForGradeOrBetter.map(item => item.cardSubject)
      .filter((value, index, self) => self.indexOf(value) === index);
    cSubject.sort((a: any, b: any) => (a > b ? -1 : 1))

    const cNumber = this.MyCardOrderItemsForGradeOrBetter.map(item => item.cardNumber)
      .filter((value, index, self) => self.indexOf(value) === index);
    cNumber.sort((a: any, b: any) => (a > b ? -1 : 1))


    this.ordNoDdl.dataSource = uniqueOrderNumbers;
    this.sportDdl = { ...this.sportDdl, dataSource: sprt };
    /*this.ordNoDdl = { ...this.ordNoDdl, dataSource: ordNums };*/
    this.setDdl = { ...this.setDdl, dataSource: set };
    this.yearFromDdl = { ...this.yearFromDdl, dataSource: yFrom };
    this.yearToDdl = { ...this.yearToDdl, dataSource: yTo };
    this.subjectDdl = { ...this.subjectDdl, dataSource: cSubject };
    this.cardNoDdl = { ...this.cardNoDdl, dataSource: cNumber };
    this.certCodeDdl = { ...this.certCodeDdl, dataSource: certCode };

    this.allGradeItems = []
    const uniqueGrades = new Set();
    for (let item of this.grades) {

      if (item != null) {
        let gradeitem = new gradeitems();
        if (item == "10 PRI") {
          gradeitem.grade = "10 PRI";
          gradeitem.gradevalue = 1;
        }
        if (item == "10 GM") {
          gradeitem.grade = "10 GM";
          gradeitem.gradevalue = 2;
        }
        if (item == "9.5 MT+") {
          gradeitem.grade = "9.5 MT+";
          gradeitem.gradevalue = 3;
        }
        if (item == "9 MT") {
          gradeitem.grade = "9 MT";
          gradeitem.gradevalue = 4;
        }
        if (item == "8.5 NM MT+") {
          gradeitem.grade = "8.5 NM MT+";
          gradeitem.gradevalue = 5;
        }
        if (item == "8 NM MT") {
          gradeitem.grade = "8 NM MT";
          gradeitem.gradevalue = 6;
        }
        if (item == "7.5 NM+") {
          gradeitem.grade = "7.5 NM+";
          gradeitem.gradevalue = 7;
        }
        if (item == "7 NM") {
          gradeitem.grade = "7 NM";
          gradeitem.gradevalue = 8;
        }
        if (item == "6.5 EX NM+") {
          gradeitem.grade = "6.5 EX NM+";
          gradeitem.gradevalue = 9;
        }
        if (item == "6 EX NM") {
          gradeitem.grade = "6 EX NM";
          gradeitem.gradevalue = 10;
        }
        if (item == "5.5 EX+") {
          gradeitem.grade = "5.5 EX+";
          gradeitem.gradevalue = 11;
        }
        if (item == "5 EX") {
          gradeitem.grade = "5 EX";
          gradeitem.gradevalue = 12;
        }
        if (item == "4.5 VG EX+") {
          gradeitem.grade = "4.5 VG EX+";
          gradeitem.gradevalue = 13;
        }
        if (item == "4 VG EX") {
          gradeitem.grade = "4 VG EX";
          gradeitem.gradevalue = 14;
        }
        if (item == "3.5 VG+") {
          gradeitem.grade = "3.5 VG+";
          gradeitem.gradevalue = 15;
        }
        if (item == "3 VG") {
          gradeitem.grade = "3 VG";
          gradeitem.gradevalue = 16;
        }
        if (item == "2.5 GD+") {
          gradeitem.grade = "2.5 GD+";
          gradeitem.gradevalue = 17;
        }
        if (item == "2 GD") {
          gradeitem.grade = "2 GD";
          gradeitem.gradevalue = 18;
        }
        if (item == "1.5 FR") {
          gradeitem.grade = "1.5 FR";
          gradeitem.gradevalue = 19;
        }
        if (item == "1 PR") {
          gradeitem.grade = "1 PR";
          gradeitem.gradevalue = 20;
        }
        if (item == "AUTHENTIC" || item == "0 A") {
          gradeitem.grade = "AUTHENTIC";
          gradeitem.gradevalue = 21;
        }
        if (!uniqueGrades.has(gradeitem.grade)) {
          this.allGradeItems.push(gradeitem);
          uniqueGrades.add(gradeitem.grade);
        }
      }
    }
    this.allGradeItems.sort((a, b) => (a.gradevalue < b.gradevalue) ? -1 : 1);
    const gradeValues = this.allGradeItems.map(item => item.grade);

    this.gradeDdl = { ...this.gradeDdl, dataSource: gradeValues };
  }

  PageMainCall() {
    this.cardAddedStatus = "";
    this.cardExistMsg = "";
    this.isCardsLoading = true;
    this.cardsLoad = false;
    this.isRefreshing = true;
    this.isCertLookup = false;
    this.buildForm();
    this.setAuthcodeValidators();
    this.downlaodCardOrderItems.length = 0;
    this.TotalorderItemGuidList = this.downlaodCardOrderItems.length;
    this.MyCardOrderItems = [];
    this.MyCardOrderItemsForGradeOrBetter = [];

    this.orderService.GetUserCardsCollections(this.currentUser.id).subscribe(response => {

      const dataResponse = response.filter(item => item.orderId !== null && item.orderItemGuid !== null)

      this.MyCardOrderItemsForGradeOrBetter = dataResponse;

      if (this.MyCardOrderItemsForGradeOrBetter.length >= 1) {
        for (let item of this.MyCardOrderItemsForGradeOrBetter) {
          item.showBackImage = false;
          item.orderNumber = item.orderNumber.toString();
        }
      }

      this.MyCardOrderItems = [...this.MyCardOrderItemsForGradeOrBetter];
      if (this.MyCardOrderItems.length >= 1) {
        for (let item of this.MyCardOrderItems) {
          if (item.cardGrade == '0 A') {
            item.cardGrade = 'AUTHENTIC'
          }
        }
        this.MyCardOrderItemLength = this.MyCardOrderItems.length;
        this.bindRibbonData();
      }

      this.isCardsLoading = false;
      this.cardsLoad = true;
      this.isRefreshing = false;
    });
  }

  getallCards() {
    this.orderService.GetUserCardsCollections(this.currentUser.id).subscribe(response => {

      const dataResponse = response.filter(item => item.orderId !== null && item.orderItemGuid !== null)

      this.MyCardOrderItemsForGradeOrBetter = dataResponse;

      if (this.MyCardOrderItemsForGradeOrBetter.length >= 1) {
        for (let item of this.MyCardOrderItemsForGradeOrBetter) {
          item.showBackImage = false;
          item.orderNumber = item.orderNumber.toString();
        }
      }

      this.MyCardOrderItems = [...this.MyCardOrderItemsForGradeOrBetter];
      if (this.MyCardOrderItems.length >= 1) {
        for (let item of this.MyCardOrderItems) {
          if (item.cardGrade == '0 A') {
            item.cardGrade = 'AUTHENTIC'
          }
        }
        this.MyCardOrderItemLength = this.MyCardOrderItems.length;
        this.bindRibbonData();
      }
    });
  }



  buildForm() {
    this.form = this.formBuilder.group({
      authcode: new FormControl('', Validators.compose([Validators.minLength(5), Validators.maxLength(12), Validators.required])),
      grade: new FormControl(''),
      subject: new FormControl('', Validators.compose([Validators.maxLength(15)]))
    });
  }
  validateInput(c: FormControl) {
    let certificationCodePattern = /^\d*(?:[-,]\d{1,4})?$/;
    let StringCodePattern = /^[0-9a-zA-Z]+$/;
    return (certificationCodePattern.test(c.value) || StringCodePattern.test(c.value)) ? null : {
      validateInput: {
        valid: false
      }
    };
  }
  setAuthcodeValidators() {
    const gradeControl = this.form.get('grade');
    const subjectControl = this.form.get('subject');
    this.form?.get('authcode')?.valueChanges
      .subscribe(authcodeLength => {
        var authcodecertcode = authcodeLength.charAt(0);
        if (authcodecertcode == 'A' || authcodecertcode == 'B' || authcodecertcode == 'a' || authcodecertcode == 'b') {
          gradeControl?.setValidators(null);
          subjectControl?.setValidators(null);
          this.showgradeSubject = false;
        }
        else {
          if (authcodeLength.length > 7) {
            gradeControl?.setValidators([Validators.required]);
            subjectControl?.setValidators([Validators.required]);
            this.showgradeSubject = true;
          }
          if (authcodeLength.length === 7) {
            gradeControl?.setValidators(null);
            subjectControl?.setValidators(null);
            this.showgradeSubject = false;
          }
          if (authcodeLength.length === '') {
            gradeControl?.setValidators(null);
            subjectControl?.setValidators(null);
            this.showgradeSubject = false;
          }
        }
        gradeControl?.updateValueAndValidity();
        subjectControl?.updateValueAndValidity();
      });
  }
  CheckAuthCode() {
    this.cardExistMsg = "";
    this.cardAddedStatus = "";
    if (this.form.invalid) {
      return;
    }
    else {


      if (this.form.valid) {

        this.showCertCodeMsg = true;
        this.showPopReport = false;
        this.isRefreshing = true;

        this.authCode = this.form.controls['authcode'].value;
        this.grade = this.form.controls['grade'].value;
        this.subject = this.form.controls['subject'].value;


        const checkCertCode = this.MyCardOrderItemsForGradeOrBetter.filter(item => item.certificationCode === this.authCode)
        if (checkCertCode && checkCertCode.length > 0) {
          this.authcertcode = this.authCode;
          this.cardExistMsg = "This card is already in your My Cards.";
          this.cardAddedStatus = "cardExist";
          //this.toastr.info("This card is already in your My Cards.", "Already Exist", {
          //  progressBar: true,
          //  timeOut: 60000,
          //  progressAnimation: "decreasing"
          //});
          this.isRefreshing = false;
        }
        else {
          this.cardExistMsg = "";
          this.DataLoad(this.authCode, this.grade, this.subject);
        }
        //this.DataLoad(this.authCode, this.grade, this.subject);
      }
    }
  }

  DataLoad(authCode: any, grade: any, subject: any) {
    this.popReportsService.getPopPipelineStatus().subscribe(response => {
      this.PopPipelineStatus = response;
      if (this.PopPipelineStatus.length >= 1) {
        this.PopLineheck = true
        this.popReportsService.GetCertAuthCode(authCode, grade, subject).subscribe(response => {
          if (response != null) {
            this.showImage = false;
            this.userSignIn = false;
            this.authCodeResult = response;
            this.emptymsg = false;
            if (this.authCodeResult.popResultCount == 1) {
              this.authcertcode = this.form.controls['authcode'].value;
              if (this.authcertcode.length > 7) {
                this.authcertcodelength = true;
              }
              var AuthPopLoadcheck = this.authcertcode.charAt(0);
              if (AuthPopLoadcheck == 'A' || AuthPopLoadcheck == 'B' || AuthPopLoadcheck == 'a' || AuthPopLoadcheck == 'b') {
                this.btnAuthPopLoad = false;
              }
              else {
                this.btnAuthPopLoad = true;
              }
              this.showgradeSubject = false
              this.isRefreshing = false;
              this.userService.userProfile$.subscribe(user => {
                this.currentUser = user;
              });
              //this.userService.userProfile$.subscribe(user => this.currentUser = user);
              this.form.controls['authcode'].setValue('');
              if (this.currentUser.id != null) {
                this.userSignIn = true;
              }
              else {
                this.userSignIn = false;
              }
              if (this.authCodeResult.variantGenerationState == 2 && this.authCodeResult.migrationState == 2 && this.authCodeResult.id.length >= 36) {
                this.showImage = true;
                this.h275FrontImage = "https://sgcimagprodstorage.blob.core.windows.net/mycollections/" + this.authCodeResult.id.toLowerCase() + "/h275/front/" + this.authCodeResult.id.toLowerCase() + ".jpg";
                this.h275BackImage = "https://sgcimagprodstorage.blob.core.windows.net/mycollections/" + this.authCodeResult.id.toLowerCase() + "/h275/back/" + this.authCodeResult.id.toLowerCase() + ".jpg";

                this.h692FrontImage = "https://sgcimagprodstorage.blob.core.windows.net/mycollections/" + this.authCodeResult.id.toLowerCase() + "/h692/front/" + this.authCodeResult.id.toLowerCase() + ".jpg";
                this.h692BackImage = "https://sgcimagprodstorage.blob.core.windows.net/mycollections/" + this.authCodeResult.id.toLowerCase() + "/h692/back/" + this.authCodeResult.id.toLowerCase() + ".jpg";

                this.h1000FrontImage = "https://sgcimagprodstorage.blob.core.windows.net/mycollections/" + this.authCodeResult.id.toLowerCase() + "/h1000/front/" + this.authCodeResult.id.toLowerCase() + ".jpg";
                this.h1000BackImage = "https://sgcimagprodstorage.blob.core.windows.net/mycollections/" + this.authCodeResult.id.toLowerCase() + "/h1000/back/" + this.authCodeResult.id.toLowerCase() + ".jpg";
              }
              else {
                this.showImage = false;
              }

            }
            else if (this.authCodeResult.popResultCount! > 1) {
              this.showgradeSubject = true
              this.isRefreshing = false;

              const gradeControl = this.form.get('grade');
              const subjectControl = this.form.get('subject');
              gradeControl?.setValidators([Validators.required]);
              subjectControl?.setValidators([Validators.required]);
              gradeControl?.updateValueAndValidity();
              subjectControl?.updateValueAndValidity();
            }
            this.SaveCardToCollection();
          }
          else {
            this.authcertcode = this.form.controls['authcode'].value
            this.emptymsg = true;
            this.isRefreshing = false;
            this.showgradeSubject = false;
            this.authCodeResult.popResultCount = 0;
            this.form.controls['authcode'].setValue('');
            //this.form.controls['grade'].setValue('');
            //this.form.controls['subject'].setValue('');
            //this.form.controls['authcode'].setValue('');
          }
        });
      }
      else {
        this.isRefreshing = false;
        this.PopLineheck = false
      }
    });
  }
  SaveCardToCollection() {

    var userId = this.stateService.currentUser.get().id;
    this.cardAddedStatus = "";
    this.popReportsService.SaveCardToMyCollections(userId, this.authCode).subscribe(response => {

      if (response.ok) {
        this.getallCards();
        this.cardAddedStatus = "cardAdded";
        //this.toastr.success("Card added successfully.", "Success", {
        //  progressBar: true,
        //  timeOut: 60000,
        //  progressAnimation: "decreasing"
        //});
      }
      else {
        if (response.error && response.error.text === 'CardAlreadyExist') {
          this.cardAddedStatus = "cardExist";
          this.cardExistMsg = "This card is already in your My Cards.";
          //this.toastr.info("This card is already in your My Cards.", "Already Exist", {
          //  progressBar: true,
          //  timeOut: 60000,
          //  progressAnimation: "decreasing"
          //});
        } else if (response.error && response.error.text === 'CardAdded') {
          this.getallCards();
          this.cardAddedStatus = "cardAdded";
          //this.toastr.success("Card added successfully.", "Success", {
          //  progressBar: true,
          //  timeOut: 60000,
          //  progressAnimation: "decreasing"
          //});
        }
      }
    });
  }
  removeCardFromCollection(certificationCode: any) {
    this.stateService.currentUser.changes.subscribe(user => {
      this.currentUser = user;
    });
    this.orderService.removeMyCollectionCards(this.currentUser.id, certificationCode).subscribe(response => {
      this.deleteResponse = response;
      if (this.deleteResponse.Status == 3) {
        this.cardAddedStatus = "cardRemoved";
        this.cardExistMsg = "";
        //this.MyCardOrderItemsForGradeOrBetter.splice(this.MyCardOrderItemsForGradeOrBetter.findIndex(x => x.certificationCode == certificationCode), 1);
        this.getallCards();

        //this.toastr.success("Card removed successfully.", "Success", {
        //  progressBar: true,
        //  timeOut: 60000,
        //  progressAnimation: "decreasing"
        //});
      }
      else {
        //this.toastr.error("Card not removed successfully.", "Error", {
        //  progressBar: true,
        //  timeOut: 60000,
        //  progressAnimation: "decreasing"
        //});
      }
    });
  }
  //openFrontImage(orderItemGuid: any) {
  //  this.modalService.open('app-view-thumbnail-front-image', orderItemGuid.toLowerCase());
  //}
  //openBackImage(orderItemGuid: any) {
  //  this.modalService.open('app-view-thumbnail-image', orderItemGuid.toLowerCase());
  //}
  filterItems() {
    this.cardsMsg = "";
    this.cardsLoad = false;
    this.isRefreshing = true;
    this.MyCardOrderItems = [];
    const selectedGradeNumber = this.selectedGrade ? parseFloat(this.selectedGrade.split(' ')[0]) : null;

    const filteredItems = this.MyCardOrderItemsForGradeOrBetter.filter(item => {
      let cardGradeNumber = item.cardGrade ? parseFloat(item.cardGrade.split(' ')[0]) : null;

      if (item.cardGrade) {
        let formatedGrade = item.cardGrade.trim();
        if (formatedGrade === "AUTHENTIC" || formatedGrade === "0 A") {
          cardGradeNumber = 0;
        }
      }

      return (!this.selectedSport || item.sport === this.selectedSport) &&
        (!this.selectedSet || item.cardSet === this.selectedSet) &&
        (!this.selectedSubject || item.cardSubject === this.selectedSubject) &&
        (!this.selectedYearFrom || item.yearFrom >= this.selectedYearFrom) &&
        (!this.selectedYearTo || item.yearTo <= this.selectedYearTo) &&
        (!this.selectedCardNo || item.cardNumber === this.selectedCardNo) &&

        (!this.selectedOrdNo || item.orderNumber === this.selectedOrdNo) &&
        (!this.selectedAutoOnly || item.cardAutoGrade != null) &&
        (!this.selectedCertCode || item.certificationCode === this.selectedCertCode) &&

        (this.selectedGradeOrBetter && selectedGradeNumber !== null
          ? cardGradeNumber !== null && cardGradeNumber >= selectedGradeNumber
          : true) &&


        (!this.selectedGradeOrBetter && this.selectedGrade
          ? item.cardGrade === this.selectedGrade
          : true);

    });
    for (let item of filteredItems) {
      item.showBackImage = false;
      this.MyCardOrderItems.push(item);
    }
    setTimeout(() => {
      this.MyCardOrderItemLength = this.MyCardOrderItems.length;
      this.cardsLoad = true;
      this.isRefreshing = false;
    }, 100);
  }
  public trackItem(index: number, item: MyCardOrderItems) {
    return item.certificationCode;
  }
  check() {

    this.cardsLoad = false;
    this.isRefreshing = true;

    var frmtdGrade = this.searchFormattedGrade.value;
    if (this.searchFormattedGrade.value != "") {
      this.MyCardOrderItems = [];
    }
    else {
      this.MyCardOrderItems = [];
      this.MyCardOrderItems = this.MyCardOrderItemsForGradeOrBetter;
    }

    if (this.searchAutoOnly.value == false && this.searchGradeOrBetter.value == false && frmtdGrade != "") {
      for (let myItem of this.MyCardOrderItemsForGradeOrBetter) {
        if (myItem.cardGrade != null) {
          if (myItem.cardGrade != 'AUTHENTIC' && myItem.cardGrade != 'AUTHENTIC Erased' && myItem.cardGrade != '0 A') {
            if (Number(parseFloat(myItem.cardGrade)) == Number(parseFloat(frmtdGrade))) {
              this.MyCardOrderItems.push(myItem);
            }
          }
          if (myItem.cardGrade == 'AUTHENTIC' || myItem.cardGrade == 'AUTHENTIC Erased' || myItem.cardGrade == '0 A') {
            if (frmtdGrade == 'AUTHENTIC') {

              this.MyCardOrderItems.push(myItem);
            }
          }
        }
      }
    }
    if (this.searchAutoOnly.value == true && this.searchGradeOrBetter.value == true && frmtdGrade != "") {
      for (let myItem of this.MyCardOrderItemsForGradeOrBetter) {
        if (myItem.cardGrade != null) {
          let formatedgrade = myItem.cardGrade;
          let garde = formatedgrade;
          if (garde.trim() == "AUTHENTIC" || garde.trim() == "0 A") {
            garde = "0";
          }
          if (frmtdGrade.trim() == "AUTHENTIC" || frmtdGrade.trim() == "0 A") {
            frmtdGrade = "0";
          }
          if (Number(parseFloat(garde.split(' ')[0])) >= Number(parseFloat(frmtdGrade.split(' ')[0]))) {
            if (myItem.cardAutoGrade != null) {
              this.MyCardOrderItems.push(myItem);
            }
          }
        }
      }
    }
    if (this.searchAutoOnly.value == false && this.searchGradeOrBetter.value == true && frmtdGrade != "") {
      for (let myItem of this.MyCardOrderItemsForGradeOrBetter) {
        if (myItem.cardGrade != null) {
          if (frmtdGrade.trim() == "AUTHENTIC" || frmtdGrade.trim() == "0 A") {
            frmtdGrade = "0";
          }

          let garde = "";
          if (myItem.cardGrade.trim() == "AUTHENTIC" || myItem.cardGrade.trim() == "0 A") {
            garde = "0";
          }
          else {
            garde = myItem.cardGrade.trim();
          }
          //var a = Number(parseFloat(garde.split(' ')[0]));
          //var b = Number(parseFloat(frmtdGrade.split(' ')[0]));

          if (Number(parseFloat(garde.split(' ')[0])) >= Number(parseFloat(frmtdGrade.split(' ')[0]))) {
            this.MyCardOrderItems.push(myItem);

          }
        }
      }
    }
    if (this.searchAutoOnly.value == true && this.searchGradeOrBetter.value == false && frmtdGrade != "") {
      for (let myItem of this.MyCardOrderItemsForGradeOrBetter) {
        if (myItem.cardGrade != null) {
          if (frmtdGrade.trim() == "AUTHENTIC" || frmtdGrade.trim() == "0 A") {
            frmtdGrade = "0";
          }

          let garde = "";
          if (myItem.cardGrade.trim() == "AUTHENTIC" || myItem.cardGrade.trim() == "0 A") {
            garde = "0";
          }
          else {
            garde = myItem.cardGrade.trim();
          }

          if (Number(parseFloat(garde.split(' ')[0])) == Number(parseFloat(frmtdGrade.split(' ')[0]))) {
            if (myItem.cardAutoGrade != null) {
              this.MyCardOrderItems.push(myItem);
            }
          }
        }
      }
    }

    if (this.searchAutoOnly.value == true && this.searchGradeOrBetter.value == false && frmtdGrade == "") {
      this.MyCardOrderItems = [];
      for (let myItem of this.MyCardOrderItemsForGradeOrBetter) {
        if (myItem.cardAutoGrade != null) {
          this.MyCardOrderItems.push(myItem);
        }
      }
    }
    if (this.searchAutoOnly.value == true && this.searchGradeOrBetter.value == true && frmtdGrade == "") {
      this.MyCardOrderItems = [];
      for (let myItem of this.MyCardOrderItemsForGradeOrBetter) {
        if (myItem.cardGrade != null) {
          this.MyCardOrderItems.push(myItem);

        }
      }
    }


    if (this.searchAuthCode.value != "") {
      this.filterrec = this.MyCardOrderItems.filter((card) => card.certificationCode.toLowerCase().includes(this.searchAuthCode.value.toLowerCase()));
      this.MyCardOrderItems = [];
      this.MyCardOrderItems = this.filterrec;
      this.filterrec = [];
    }
    if (this.searchCardNumber.value != "") {
      this.filterrec = this.MyCardOrderItems.filter((card) => card.cardNumber.toLowerCase().includes(this.searchCardNumber.value.toLowerCase()));
      this.MyCardOrderItems = [];
      this.MyCardOrderItems = this.filterrec;
      this.filterrec = [];
    }
    if (this.searchCardSet.value != "") {
      this.filterrec = this.MyCardOrderItems.filter((card) => card.cardSet.toLowerCase().includes(this.searchCardSet.value.toLowerCase()));
      this.MyCardOrderItems = [];
      this.MyCardOrderItems = this.filterrec;
      this.filterrec = [];
    }
    if (this.searchCardSubject.value != "") {
      this.filterrec = this.MyCardOrderItems.filter((card) => card.cardSubject.toLowerCase().includes(this.searchCardSubject.value.toLowerCase()));
      this.MyCardOrderItems = [];
      this.MyCardOrderItems = this.filterrec;
      this.filterrec = [];
    }
    if (this.searchSport.value != "") {
      this.filterrec = this.MyCardOrderItems.filter((card) => card.sport.toLowerCase().includes(this.searchSport.value.toLowerCase()));
      this.MyCardOrderItems = [];
      this.MyCardOrderItems = this.filterrec;
      this.filterrec = [];
    }
    if (this.searchOrderNumber.value != "") {
      this.filterrec = this.MyCardOrderItems.filter((card) => String(card.orderNumber).includes(String(this.searchOrderNumber.value)));
      this.MyCardOrderItems = [];
      this.MyCardOrderItems = this.filterrec;
      this.filterrec = [];
    }
    if (this.yearFrom.value != "" && this.yearTo.value == "") {
      this.filterrec = this.MyCardOrderItems.filter((card) => String(card.yearFrom).includes(String(this.yearFrom.value)));
      this.MyCardOrderItems = [];
      this.MyCardOrderItems = this.filterrec;
      this.filterrec = [];
    }
    if (this.yearTo.value != "" && this.yearFrom.value == "") {
      this.filterrec = this.MyCardOrderItems.filter((card) => String(card.yearTo).includes(String(this.yearTo.value)));
      this.MyCardOrderItems = [];
      this.MyCardOrderItems = this.filterrec;
      this.filterrec = [];
    }
    if (this.yearTo.value != "" && this.yearFrom.value != "") {
      this.filterrec = this.MyCardOrderItems.filter((card) => String(card.yearFrom).includes(String(this.yearFrom.value)) && String(card.yearTo).includes(String(this.yearTo.value)));
      this.MyCardOrderItems = [];
      this.MyCardOrderItems = this.filterrec;
      this.filterrec = [];
    }
    this.MyCardOrderItems = this.MyCardOrderItems;
    this.MyCardOrderItemLength = this.MyCardOrderItems.length;
    this.cardsLoad = true;
    this.isRefreshing = false;
  }

  selectall() {
    this.cardsMsg = "";
    _.forEach(this.MyCardOrderItems, item => {
      const orderItems = this.downlaodCardOrderItems.filter(nb => nb.itemGuid == item.orderItemId);
      if (orderItems.length >= 1) {
        const foundItem = this.downlaodCardOrderItems.find(d => d.itemGuid === item.orderItemId);
        if (foundItem) {
          foundItem.itemGuid = item.orderItemId;
          foundItem.orderNumber = item.orderNumber;
        }
      } else {
        if (item.myCardOwnerId == this.currentUser.id) {
          const downlaodCardOrderItems = new DownlaodCardOrderItems();
          downlaodCardOrderItems.itemGuid = item.orderItemId;
          downlaodCardOrderItems.orderNumber = item.orderNumber;
          item.isChecked = true;
          this.downlaodCardOrderItems.push(downlaodCardOrderItems);
        }
      }
    });
    this.TotalorderItemGuidList = this.downlaodCardOrderItems.length;
  }
  totalCardsSeleted() {
    if (this.TotalorderItemGuidList.length >= 1) {
      return this.TotalorderItemGuidList + " Cards Selected";
    }
    else {
      return "Cards Selection";
    }
  }

  clearall() {
    _.forEach(this.MyCardOrderItems, item => {
      item.isChecked = false;
    });
    this.downlaodCardOrderItems.length = 0;
    this.TotalorderItemGuidList = this.downlaodCardOrderItems.length;
  }

  certCodeLookup() {
    this.isCertLookup = true
    this.emptymsg = false;
    this.cardExistMsg = "";
    this.cardAddedStatus = "";
    this.form.controls['authcode'].setValue('');
    this.authCodeResult = new PopReportAuthCode();

  }

  BackFromcertCodeLookup() {
    this.authCodeResult = new PopReportAuthCode();
    this.showImage = false;
    this.PageMainCall();
  }

  ShowImage(item: any, showBackImage: boolean) {
    if (showBackImage == true) {
      item.showBackImage = false;
    }
    else {
      item.showBackImage = true;
    }
  }
  openImage(Image: boolean, orderItemGuid: any) {
    if (Image == false) {
      this.modalService.open('app-view-thumbnail-front-image', orderItemGuid.toLowerCase());
    }
    else {
      this.modalService.open('app-view-thumbnail-image', orderItemGuid.toLowerCase());
    }
  }
  openFrontImage(orderItemGuid: any) {
    this.modalService.open('app-view-thumbnail-front-image', orderItemGuid.toLowerCase());
  }
  openBackImage(orderItemGuid: any) {
    this.modalService.open('app-view-thumbnail-image', orderItemGuid.toLowerCase());
  }

  addToDownload(isChecked: any, itemGuid: string) {
    this.cardsMsg = "";
    if (isChecked == false) {
      this.downlaodCardOrderItems.splice(this.downlaodCardOrderItems.findIndex(x => x.itemGuid == itemGuid), 1);
    }
    else {
      const orderItems = this.MyCardOrderItemsForGradeOrBetter.filter(nb => nb.orderItemId == itemGuid);
      let downlaodCardOrderItems = new DownlaodCardOrderItems();
      downlaodCardOrderItems.itemGuid = itemGuid;
      downlaodCardOrderItems.orderNumber = orderItems[0].orderNumber;
      this.downlaodCardOrderItems.push(downlaodCardOrderItems);
    }
    this.TotalorderItemGuidList = this.downlaodCardOrderItems.length;
  }
  DownloadAll() {
    this.cardsMsg = "";
    if (this.downlaodCardOrderItems.length < 1) {
      this.cardsMsg = "Please select at least one card!.";
      this.msgColor = "red";

      //this.toastr.warning("Please select at least one card!", "Download Request", {
      //  progressBar: true,
      //  timeOut: 120000,
      //  progressAnimation: "decreasing"
      //});
      return;
    }
    if (this.selectedDownloadType == null) {
      this.cardsMsg = "Please select at least one option!.";
      this.msgColor = "red";
      //this.toastr.warning("Please select at least one option!", "Download Request", {
      //  progressBar: true,
      //  timeOut: 10000,
      //  progressAnimation: "decreasing"
      //});
      return;
    }
    var Cardtype = this.selectedDownloadType;
    if (Cardtype != null && this.downlaodCardOrderItems.length >= 1) {
      this.allOrderItemGuidList = "";

      var auhCode = "null";
      if (this.downlaodCardOrderItems.length == 1) {
        var data = this.MyCardOrderItemsForGradeOrBetter.filter(nb => nb.orderNumber == this.downlaodCardOrderItems[0].orderNumber)
        auhCode = data[0].certificationCode
      }

      var ordernumber = this.downlaodCardOrderItems[0].orderNumber;
      const oNumCount = this.MyCardOrderItemsForGradeOrBetter.filter(nb => nb.orderNumber == ordernumber);
      const ordNumCount = this.downlaodCardOrderItems.filter(nb => nb.orderNumber == ordernumber);

      if (oNumCount.length != ordNumCount.length) {
        ordernumber = "0";
      }

      for (let item of this.downlaodCardOrderItems) {
        this.allOrderItemGuidList = this.allOrderItemGuidList + "" + item.itemGuid + Cardtype + ",";
      }
      this.orderService.downloadMyCards(this.allOrderItemGuidList.slice(0, -1), this.currentUser.id, Number(ordernumber), auhCode).subscribe(response => {
        if (response.status == "Success") {
          this.cardsMsg = "Your download request is in process. To see if your cards are ready to download, click 'Downloads' or the 'Pending Downloads' menu next to Home.";
          this.msgColor = "green";
          this.willBeReadySoon = this.willBeReadySoon + 1;
          this.CheckCardDownloadStatus();
          //this.toastr.success("Your download request is in process. To see if your cards are ready to download, click the Status button below.", "Download Request", {
          //  progressBar: true,
          //  timeOut: 10000,
          //  progressAnimation: "decreasing"
          //});
          this.downLoadRequest = "Success";
        }
        if (response.status == "Failed") {
          this.cardsMsg = "We could not process your download request. Please try again later.";
          this.msgColor = "red";
          //this.toastr.error("We could not process your download request. Please try again later.", "Download Request", {
          //  progressBar: true,
          //  timeOut: 10000,
          //  progressAnimation: "decreasing"
          //});
          this.downLoadRequest = "Failed";
        }
        if (response.status == "Failed. Request already exists.") {
          this.cardsMsg = "Your download request already exists. To see if your cards are ready to download, click 'Downloads' or the 'Pending Downloads' menu next to Home.";
          this.msgColor = "red";
          //this.toastr.warning("Your download request already exists. To see if your cards are ready to download, click the Status button below.", "Download Request", {
          //  progressBar: true,
          //  timeOut: 10000,
          //  progressAnimation: "decreasing"
          //});
          this.downLoadRequest = "Failed";
        }
        this.clear();
        this.cardsLoad = true;
      });
    }
  }

  get275AuthImageFront(id: any): string {

    return "https://sgcimagprodstorage.blob.core.windows.net/mycollections/" + id.toLowerCase() + "/h275/front/" + id.toLowerCase() + ".jpg";
  }
  get275AuthImageBack(id: any): string {
    return "https://sgcimagprodstorage.blob.core.windows.net/mycollections/" + id.toLowerCase() + "/h275/back/" + id.toLowerCase() + ".jpg";
  }
  get692AuthImageFront(id: any): string {
    return "https://sgcimagprodstorage.blob.core.windows.net/mycollections/" + id.toLowerCase() + "/h692/front/" + id.toLowerCase() + ".jpg";
  }
  get692AuthImageBack(id: any): string {
    return "https://sgcimagprodstorage.blob.core.windows.net/mycollections/" + id.toLowerCase() + "/h692/back/" + id.toLowerCase() + ".jpg";
  }
  get1000AuthImageFront(id: any): string {
    return "https://sgcimagprodstorage.blob.core.windows.net/mycollections/" + id.toLowerCase() + "/h1000/front/" + id.toLowerCase() + ".jpg";
  }
  get1000AuthImageBack(id: any): string {
    return "https://sgcimagprodstorage.blob.core.windows.net/mycollections/" + id.toLowerCase() + "/h1000/back/" + id.toLowerCase() + ".jpg";
  }

  ShowtwoCol() {
    this.onecol = false;
    this.twoCol = true;
    this.fourCol = false;
    this.sixCol = false;
    this.selectTab(1, 'home');
    this.cardAddedStatus = "";
    this.cardExistMsg = "";
  }
  ShowfourCol() {
    this.onecol = false;
    this.twoCol = false;
    this.fourCol = true;
    this.sixCol = false;
    this.selectTab(1, 'home');
    this.cardAddedStatus = "";
    this.cardExistMsg = "";
  }
  ShowsixCol() {
    this.onecol = false;
    this.twoCol = false;
    this.fourCol = false;
    this.sixCol = true;
    this.selectTab(1, 'home');
    this.cardAddedStatus = "";
    this.cardExistMsg = "";
  }
  ShowoneCol() {
    this.onecol = true;
    this.twoCol = false;
    this.fourCol = false;
    this.sixCol = false;
    this.selectTab(1, 'home');
    this.cardAddedStatus = "";
    this.cardExistMsg = "";
  }

  clearFilter() {
    this.searchAuthCode.setValue("");
    this.yearFrom.setValue("");
    this.yearTo.setValue("");
    this.searchCardNumber.setValue("");
    this.searchCardSet.setValue("");
    this.searchCardSubject.setValue("");
    this.searchFormattedGrade.setValue("");
    this.searchAutoOnly.setValue(false);
    this.searchGradeOrBetter.setValue(false);
    this.searchSport.setValue("");
    this.searchOrderNumber.setValue("");
    this.MyCardOrderItems = [];
    this.MyCardOrderItems = this.MyCardOrderItemsForGradeOrBetter;

  }
  OpenConfirmationModal(item: any) {

    this.modalService.open('app-delete-card', { item, resultCallback: this.onModalResult.bind(this) });
  }

  onModalResult(result: string, certificationCode: string) {

    if (result == 'yes') {
      this.stateService.currentUser.changes.subscribe(user => {
        this.currentUser = user;
      });
      this.orderService.removeMyCollectionCards(this.currentUser.id, certificationCode).subscribe(response => {

        this.deleteResponse = response;
        if (this.deleteResponse.Status == 3) {
          this.toastr.success("Card removed successfully.", "Success", {
            progressBar: true,
            timeOut: 60000,
            progressAnimation: "decreasing"
          });
          this.PageMainCall()
        }
        else {
          this.toastr.error("Card not removed successfully.", "Error", {
            progressBar: true,
            timeOut: 60000,
            progressAnimation: "decreasing"
          });
        }

      });
    }
  }

  clear() {
    //this.defaultRibbon.refreshLayout();
    //this.defaultRibbon.refresh();
    //this.defaultRibbon.isServerRendered = true;
    //this.defaultRibbon.refresh;
    //this.defaultRibbon.enablePersistence = false;
    /*this.cardsMsg = "";*/
    this.downLoadRequest = "";
    this.allOrderItemGuidList = "";
    this.TotalorderItemGuidList = 0;
    this.downlaodCardOrderItems.length = 0;
    this.SelectCardOptions.setValue("");

    this.MyCardOrderItems = [];
    this.MyCardOrderItems = this.MyCardOrderItemsForGradeOrBetter;
    _.forEach(this.MyCardOrderItems, item => {
      item.isChecked = false;
      item.showBackImage = false;
    });
  }
}
