<div class="lead">
    Would you like to use your personal Fed-Ex Account?
</div>
<input type="text" class="input" placeholder="Provide Fed-Ex Account Number">
<div class="toggle">
    <div class="toggle__label">Remember this setting for next orders</div>
    <div class="toggle__checkbox">
        <input type="checkbox" id="check1">
        <label for="check1"></label>
    </div>
</div>
<div class="modal__actions">
    <button class="button button--secondary">No</button>
    <button class="button">Yes</button>
</div>
