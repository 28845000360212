import { Component, Input, AfterViewChecked, OnInit, OnDestroy } from '@angular/core';
import { Order } from '../../../orders/models/order';
import { CheckoutService } from '../../services/checkout.service';
import { OrderService } from '../../../orders/services/order.service';
import { ErrorService } from '../../../common/services/error.service';
import { environment } from '../../../../environments/environment';
// import 'rxjs/add/operator/catch';
import { ModalService } from '../../../common/services/modal.service';
// import { Subscription } from 'rxjs';
import { Router } from '../../../../../node_modules/@angular/router';

declare let paypal: any;

@Component({
    selector: 'checkout-side-bar',
    templateUrl: './checkout-side-bar.component.html',
    styleUrls: ['../../checkout.component.sass']
})

export class CheckoutSideBarComponent implements OnInit, AfterViewChecked {

    constructor(
        private checkoutService: CheckoutService,
        private ordersService: OrderService,
        private errorService: ErrorService,
        private modalService: ModalService,
        public router: Router
    ) { }

    @Input() order = new Order();
    promoCode: string = '';
    isRefreshing: boolean = false;
    isError: boolean = false;
    termsaccept: boolean = false;
    popUpWindow: any;
    //subscription: Subscription;
    //ngOnDestroy() {
    //    this.subscription.unsubscribe();
    //}

    getOrderNumber(): number {
        return this.order.getNumber();
    }

    getOrderTypeName(): string {
        return this.order.getOrderTypeName();
    }

    getOrderTypeCode(): string {
        return this.order.getOrderTypeCode();
     }

    getItemsCount(): number {
        return this.order.getItemsCount();
    }

    getTotalDeclaredValue(): number {
        return this.order.getTotalDeclaredValue();
    }

    getTurnaround(): any {

        if (this.order.getTotalTurnaround() == 1 && this.checkoutService.currentOrder.hasPlan()) {
            return "End of Show";//"1 Business Day";
        }
        else if (this.order.getTotalTurnaround() == 2 && this.checkoutService.currentOrder.hasPlan()) {
            return "1-2 Estimated Business Days";
        }
        else if (this.order.getTotalTurnaround() == 10 && this.checkoutService.currentOrder.hasPlan()) {
            return "5-10 Estimated business days";
        }
        else if (this.order.getTotalTurnaround() == 5 && this.checkoutService.currentOrder.hasPlan()) {
            return "5 Estimated business days";
        } // old
        //else if (this.order.getTotalTurnaround() == 5 && this.checkoutService.currentOrder.hasPlan()) {
        //    return "1-5 Estimated business days";
        //} // new
        else if (this.order.getTotalTurnaround() == 48 && this.checkoutService.currentOrder.hasPlan()) {
            return "43-48 Estimated business days";
        }
        else if (this.order.getTotalTurnaround() == 55 && this.checkoutService.currentOrder.hasPlan()) {
            return "50-55 Estimated business days";
        }
        else if (this.order.getTotalTurnaround() == 57 && this.checkoutService.currentOrder.hasPlan()) {
            return "52-57 Estimated business days";
        }
        else if (this.order.getTotalTurnaround() == 70 && this.checkoutService.currentOrder.hasPlan()) {
            return "65-70 Estimated Business Days";
        }
        else if (this.order.getTotalTurnaround() == 3 && this.checkoutService.currentOrder.hasPlan()) {
            return "1-3 Estimated Business Days";
        }
        else if (this.order.getTotalTurnaround() == 25 && this.checkoutService.currentOrder.hasPlan()) {
            return "20-25 Estimated Business Days";
        }
        else if (this.order.getTotalTurnaround() == 50 && this.checkoutService.currentOrder.hasPlan()) {
            return "45-50 Estimated Business Days";
        }
        //else if (this.order.getTotalTurnaround() == 30 && this.checkoutService.currentOrder.hasPlan()) {
        //    return "30 Business Days Guaranteed";
        //}
        //else if (this.order.getTotalTurnaround() == 100 && this.checkoutService.currentOrder.hasPlan()) {
        //    return "Special Items Non-Guarantee";
        //}
        else {
            if (this.checkoutService.currentOrder.hasPlan()) {
                return this.order.getTotalTurnaround() ? this.order.getTotalTurnaround() + " estimated business days" : this.order.getTotalTurnaround();
            }
        }
    }

    getShippingMethodName(): string {
        return this.order.getShippingMethodName();
    }

    //getShippingMethod(): any {
    //
    //    return this.order.getShippingMethod();
    //}

    getTotalServiceFee(): number {
        return this.order.getTotalServiceFee();
    }

    getShippingFee(): number {
        return this.order.getTotalShippingFee();
    }

    getTotalFee(): number {
        return this.order.getTotalFee();
    }

    showPromoCode(): boolean {
        return this.checkoutService.showPromoCode();
    }

    getPromoFee(): number {
        return this.order.getPromoFee();
    }

    openPaymentTerms() {
        var options = {
            message: this.getOrderNumber(),
            message1: `If you are picking up your order at SGC or a show, we require a shipping address in the event you are unable to pick up your order.`,
            message2: `If your shipping address is not displayed, or you would like to add another address, click the '+' to add an address. After saving the address, click it to view your shipping options`,

            actionLabel: 'Go To Legacy Registry',
            actionCallback: () => window.open('https://sgclegacy.com/registrymain.aspx', '_blank')
        }

        this.modalService.open('app-payment-terms', options);
        //this.checkoutService.cancelCheckout();
    }

    payWithStripe() {
        var orderId = this.order.id;
        this.router.navigate([`/orders/${orderId}/stripe`]);
    }

    applyPromoCode() {
        debugger;
        this.isError = false;
        //this.checkoutService.setShippingMethodForShipping();
        this.checkoutService
        .applyPromoCode(this.promoCode)
        .subscribe();
        this.checkoutService.setPromo(this.promoCode)
    }

    canSubmitValue(): boolean {
        return Boolean(this.promoCode && this.promoCode != this.order.promoCode);
    }

    canSubmitWithoutPaying(): boolean {
        return this.order && this.checkoutService.isOrderCompleted() && this.order.getTotalFee() <= 0;
    }

    canPaySubmission(): boolean {
        return this.checkoutService.isPaymentStep();
    }

    canPaySubmissionWithTerms(): boolean {
        return this.checkoutService.isPaymentStep() && this.termsaccept == true;
    }

    submitOrder() {
        this.checkoutService.submitOrder();
    }

    didPaypalScriptLoad: boolean = false;

    //paypalConfig: any = {
    //    locale: 'en_US',

    //    createOrder: (data, actions) => {
    //
    //        return this.ordersService.createPayment(this.order).toPromise()
    //            .then(response => {
    //                return response.paymentId;
    //            }, error => {
    //                this.errorService.handlePaypalError(error, this.order.id)
    //            });
    //    },

    //    onApprove: (data, actions) => {
    //
    //          this.checkoutService.processPayment(data);
    //    },

    //    onError: function (err) {
    //        this.errorService.handlePaypalError(err, this.order.id)
    //    }
    //};

    paypalConfig: any = {
        env: environment.paypal.env,

        commit: true, // Show a 'Pay Now' button

        style: {
            label: 'paypal',
            size: 'responsive',    // small | medium | large | responsive
            shape: 'rect',     // pill | rect
            color: 'silver',     // gold | blue | silver | black
            tagline: false,
            fundingicons: 'true',
            layout: 'horizontal'
        },

        payment: (data:any, actions:any) => {
                return this.ordersService.createPayment(this.order).toPromise()
                    .then(response => {
                        return response.paymentId;
                    }, error => {
                        this.errorService.handlePaypalError(error, this.order.id)
                    });
        },

        onAuthorize: (data:any, actions:any) => {
            this.checkoutService.processPayment(data);
        },

        onCancel: function (data:any, actions:any) {
        },
    };

    ngOnInit(): void {
        this.checkoutService.currentpayTerms.subscribe(x => this.termsaccept = x)
        this.checkoutService.isRefreshingOrder$.subscribe(x => this.isRefreshing = x);
        
        if(this.checkoutService.currentOrder.promoCode){
            console.log(this.checkoutService.currentOrder.promoCode);
            
            this.promoCode=this.checkoutService.currentOrder.promoCode
            this.applyPromoCode()
        }
    }

    ngAfterViewChecked() {
        //if (!this.didPaypalScriptLoad && this.checkoutService.isPaymentStep() == true) {
        //    this.loadPaypalScript().then(() => {
        //        paypal.Button.render(this.paypalConfig, '#paypal-button-container');
        //    });
        //}
    }

    loadPaypalScript(): Promise<any> {
        this.didPaypalScriptLoad = true;

        return new Promise((resolve, reject) => {
            const scriptElement = document.createElement('script');
            scriptElement.src = 'https://www.paypalobjects.com/api/checkout.js';
            scriptElement.onload = resolve;
            document.body.appendChild(scriptElement);
        });
    }

    //ngAfterViewChecked() {
    //    if (!this.didPaypalScriptLoad) {
    //        this.loadPaypalScript().then(() => {
    //            //this.initPayPalButton();
    //            paypal.Buttons(this.paypalConfig).render('#paypal-button-container');
    //        });
    //    }
    //}

    //loadPaypalScript(): Promise<any> {
    //    this.didPaypalScriptLoad = true;
    //    return new Promise((resolve, reject) => {
    //        const scriptElement = document.createElement('script');
    //        scriptElement.src = 'https://www.paypal.com/sdk/js?client-id=AWW8XgpQcX4yXP2XjpkdW9FsGLlzrU29C3UtfZOfFMeJVKJPIOTBqlc8xqtuPbDP5HyY-3ONRnD7NHt9&currency=USD&disable-funding=credit';
    //        scriptElement.onload = resolve;
    //        document.body.appendChild(scriptElement);
    //    });
    //}
}
