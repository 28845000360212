<div class="table">
    <table class="table--5c table--review">
        <tr class="table__header">
            <td>#</td>
            <td>Name</td>
            <td>Declared Value</td>
            <td>Service Fee</td>
            <td>Service</td>
        </tr>
        <tr *ngFor="let item of items; let i = index">
            <td>{{ i + 1 }}</td>
            <td>
                {{ getCardName(item) | cardNameFormat }}
                {{ getCardDescription(item) }}
            </td>
            <td>
                {{
                    item.getDeclaredValue()
                        | currency : "USD" : "symbol" : "1.0-0"
                        | dashForNoValueFormat
                }}
            </td>
            <td>
                {{
                    item.totalFee
                        | currency : "USD" : "symbol" : "1.0-0"
                        | dashForNoValueFormat
                }}
            </td>
            <td>{{ getitemdata(item) }}</td>
        </tr>
    </table>
</div>
<style>
    @media only screen and (max-width: 660px) {
    .table table td {
    padding: 10px 5px 10px 0px;
    font-size: 12px;
    }
    }
    </style>
