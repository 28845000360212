import { Component, OnInit } from '@angular/core';
import { ModalService, ModalInterface } from '../../services/modal.service';

@Component({
    selector: 'app-view-thumbnail-front-image',
    templateUrl: './view-thumbnail-front-image.component.html',
    styleUrls: ['./view-thumbnail-front-image.component.css']
})
export class ViewThumbnailFrontImageComponent implements ModalInterface {
    
    constructor(
        private modalService: ModalService
    ) { }
    options: any;
    type = "app-view-thumbnail-front-image";

    geth692FrontImage(): string {
        var h692FrontImage = "https://sgcimagprodstorage.blob.core.windows.net/mycollections/" + this.options + "/h692/front/" + this.options + ".jpg";
        return h692FrontImage;
    }
    geth1000FrontImage(): string {
        var h1000FrontImage = "https://sgcimagprodstorage.blob.core.windows.net/mycollections/" + this.options + "/h1000/front/" + this.options + ".jpg";
        return h1000FrontImage;
    }

    close() {
        this.modalService.close(this.type, 'close');
    }
}

