import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sgc-privacy',
  templateUrl: './sgc-privacy.component.html',
  styleUrls: ['./sgc-privacy.component.css']
})
export class SgcPrivacyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
