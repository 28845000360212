import { Component } from '@angular/core';
import { ModalService, ModalInterface } from '../../services/modal.service';


@Component({
  selector: 'app-cards-instructions',
  templateUrl: './cards-instructions.component.html',
  styleUrls: ['./cards-instructions.component.css']
})
export class CardsInstructionsComponent implements ModalInterface {
    constructor(
        private modalService: ModalService
    ) { }

    type = "app-cards-instructions";
    loader!: boolean;
    options: any;

    modalCopy: string = `Please Not: Trunaround Times vary with demand.Any of the following will result in longer lead times.`

    //modalCopy: string = `Please Not: Trunaround Times vary with demand.Any of the following will result in longer lead times.

    //-Non-use of online invoice.     -Cards requiring custom research.
    //-Non-sports cards.              -Oversized Items.
    //-Cards not sent in Card Saver I Or Ultra Pro semi-rigid holders`;

    close() {
        this.modalService.close(this.type, 'close');
    }
}
