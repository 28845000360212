import {
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  OnDestroy,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PopReportsService } from '../services/pop-reports.service';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';

@Component({
  selector: 'app-auth-code-questions',
  templateUrl: './auth-code-questions.component.html',
  styleUrls: ['./auth-code-questions.component.css'],
})
export class AuthCodeQuestionsComponent implements OnInit {
  authcertcode: any;
  certificationCodePattern = '^-?[0-9]\\d*(\\-\\d{3})?$';
  successmsg = false;
  mobNumberPattern = '^((\\+91-?)|0)?[0-9]{10}$';
  email = '[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$';
  form!: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private _routeParams: ActivatedRoute,
    private popReportsService: PopReportsService
  ) {}

  ngOnInit() {
    this.authcertcode = this._routeParams.snapshot.params['authcode'];
    this.form = this.formBuilder.group({
      typeofissue: new FormControl('', Validators.required),
      firstname: new FormControl(
        null,
        Validators.compose([Validators.maxLength(100), Validators.required])
      ),
      authcode: new FormControl(
        this.authcertcode,
        Validators.compose([
          Validators.minLength(7),
          Validators.maxLength(11),
          Validators.required,
          Validators.pattern(this.certificationCodePattern),
        ])
      ),
      email: new FormControl(
        null,
        Validators.compose([
          Validators.pattern(this.email),
          Validators.maxLength(100),
          Validators.required,
        ])
      ),
      comments: new FormControl(null),
      mobile: new FormControl(
        null,
        Validators.compose([Validators.maxLength(20)])
      ),
    });
  }

  SubmitEmail() {
    if (this.form.invalid) {
      return;
    }
    this.popReportsService.AuthCodeEmail(this.form.value).subscribe(
      (data) => {
        this.successmsg = true;
      },
      (error) => {
        this.successmsg = false;
      }
    );
  }
}
