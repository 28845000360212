<div
    class="checkout__content"
    *ngIf="showLoginUserAddress"
    style="padding-bottom: 80px"
>
    <h2 *ngIf="orderTypeCode != 'card-pre-grade'" class="address">
        STEP 4. SELECT RETURN SHIPPING ADDRESS
    </h2>
    <h2 *ngIf="orderTypeCode == 'card-pre-grade'">
        STEP 4. VERIFY PHONE NUMBER & ADDRESS
    </h2>
    <div class="checkout__columns">
        <!-- <div class="additional-info" style="margin-left:17px" (click)="inst()" *ngIf="orderTypeCode != 'card-pre-grade'">
            <h5 style="font-family:'d77';font-weight:normal;font-size:19px;color:#785aff;cursor:pointer">See Shipping Instructions</h5>
        </div> -->
        <div
            class=""
            style="margin-left: 33px; font-size: 15px"
            *ngIf="orderTypeCode == 'card-pre-grade'"
        >
            Please Note: Your Pre-Grade Submission will not be shipped. It will
            be completed and returned at the 2022 NSCC.
        </div>
        <div class="checkout__items" style="grid-column: 1">
            <div
                *ngFor="let address of userAddresses"
                class="checkout__shipping-item"
                style="display: block"
                [class.item--selected]="isAddressSelected(address)"
                (click)="selectAddress(address)"
            >
            <h3 class="select-list__item-title">
                {{ address.street1 }} <span>{{ address.street2 }}</span>
            </h3>
                <p>{{ address | cityStateCountryFormat : true }}</p>
                <p class="no-margin" *ngIf="address.phoneNumber">
                    {{ address.phoneNumber }}
                </p>
            </div>

            <div class="new-item" (click)="addAddress()">
                <img
                    src="/assets/img/plus-sign.svg"
                    alt="Add New Address"
                    style="margin-right: 5px"
                />
                <h5>Add New Shipping Address</h5>
            </div>
            <!-- <div class="checkout__shipping-account" *ngIf="orderTypeCode != 'card-pre-grade'">
                <h4 style="margin:12px;">Select shipping payment account</h4>
                <div class="checkout__shipping-account-item" [class.item--selected]="isPersonalShippingAccountSelected()" (click)="selectShippingAccount(true)">
                    <h5>FedEx (Personal)</h5>
                    <div *ngIf="canEditFedexAccount">
                        <input type="text" class="input" placeholder="Fedex Account Number" [(ngModel)]="order.settings.fedexAccount">
                        <button class="button" (click)="saveFedexAccount(); $event.stopPropagation()" [disabled]="!canSubmitValue()" [class.button--disabled]="!canSubmitValue()">Save</button>
                    </div>
                    <div *ngIf="!canEditFedexAccount">
                        <p><i>Account number:</i> {{getFedexAccountNumber()}}</p>
                        <input type="image" src="/assets/img/edit.svg" class="button-icon" (click)="editFedexAccount(); $event.stopPropagation()">
                    </div>
                </div>
                <div class="checkout__shipping-account-item" [class.item--selected]="!isPersonalShippingAccountSelected()" (click)="selectShippingAccount(false)">
                    <h5>SGC Shipping & Handling</h5>
                </div>
            </div> -->
        </div>

        <!-- <div class="checkout__shipping" *ngIf="orderTypeCode == 'card-pre-grade'" style="visibility: hidden;">
            <div class="checkout__shipping-item fade-in" *ngFor="let shippingMethod of shippingMethods" [class.item--selected]="isShippingMethodSelected(shippingMethod)" (click)="selectShippingMethod(shippingMethod)">
                <img src="/assets/img/{{shippingMethod.carrier.code}}.png">
                <h5>{{shippingMethod.name}}</h5>
                <p>{{shippingMethod.fee | currency: 'USD': 'symbol': '1.0-0'}}</p>
            </div>
        </div> -->
    </div>
</div>

<div class="checkout__content" *ngIf="!showLoginUserAddress">
    <h2 *ngIf="orderTypeCode != 'card-pre-grade'">
        STEP 4. SELECT RETURN SHIPPING ADDRESS
    </h2>
    <h2 *ngIf="orderTypeCode == 'card-pre-grade'">
        STEP 4. VERIFY PHONE NUMBER & ADDRESS
    </h2>
    <div class="checkout__columns">
        <div>
            <div
                class="checkout__items"
                style="padding-top: 10px; margin-bottom: 40px"
                *ngIf="showCustomer"
            >
                <h4>Customer Detail</h4>
                <div>
                    <p>
                        <i>customer name: </i>{{ currentCustomer.user.name }} /
                        {{ currentCustomer.user.username | lowercase }}
                    </p>
                    <p>
                        <i>customer number: </i>
                        {{ currentCustomer.customerNumber | number }}
                    </p>
                    <p>
                        <i>customer email: </i
                        >{{ currentCustomer.user.email | lowercase }}
                    </p>
                </div>

                <!-- <div class="checkout__shipping-account" *ngIf="orderTypeCode != 'card-pre-grade'">
                    <h4>Select shipping payment account</h4>
                    <div class="checkout__shipping-account-item" [class.item--selected]="isPersonalShippingAccountSelected()" (click)="selectShippingAccount(true)">
                        <h5>FedEx (Personal)</h5>
                        <div *ngIf="canEditFedexAccount">
                            <input type="text" class="input" placeholder="Fedex Account Number" [(ngModel)]="order.settings.fedexAccount">
                            <button class="button" (click)="saveFedexAccount(); $event.stopPropagation()" [disabled]="!canSubmitValue()" [class.button--disabled]="!canSubmitValue()">Save</button>
                        </div>
                        <div *ngIf="!canEditFedexAccount">
                            <p><i>Account number:</i> {{getFedexAccountNumber()}}</p>
                            <input type="image" src="/assets/img/edit.svg" class="button-icon" (click)="editFedexAccount(); $event.stopPropagation()">
                        </div>
                    </div>
                    <div class="checkout__shipping-account-item" [class.item--selected]="!isPersonalShippingAccountSelected()" (click)="selectShippingAccount(false)">
                        <h5>SGC Shipping & Handling</h5>
                    </div>
                </div> -->
            </div>
            <div
                class="checkout__shipping-item"
                style="display: block"
                *ngFor="let address of customerAddresses"
                [class.item--selected]="isAddressSelected(address)"
                (click)="selectAddress(address)"
            >
                <!--<div class="added-card-item__actions">
                    <input type="image" src="/assets/img/edit.svg" class="button-icon" (click)="editAddress(address); $event.stopPropagation()">
                    <input type="image" src="/assets/img/trash.svg" class="button-icon" (click)="removeAddress(address); $event.stopPropagation()">
                </div>-->
                <h3 class="select-list__item-title">
                    {{ address.street1 }} <span>{{ address.street2 }}</span>
                 <!-- {{ address.street2 }} -->
                </h3>
                <!-- <div class="added-card-item__info"> -->
                    <!-- <h4>{{ address.street2 }}</h4> -->
                    <p>{{ address | cityStateCountryFormat : true }}</p>
                    <p class="no-margin" *ngIf="address.phoneNumber">
                        {{ address.phoneNumber }}
                    </p>
                <!-- </div> -->
            </div>
            <div
                class="new-item"
                (click)="addAddress()"
                style="margin-top: 10px"
            >
                <h5>Add New Shipping Address</h5>
                <!--<img src="/assets/img/plus-sign.svg" alt="Add New Address">-->
            </div>
        </div>
        <div class="checkout__search">
            <div style="display: flex">
                <input
                    type="text"
                    placeholder="Search Customer Name"
                    [formControl]="searchTerm"
                    style="width: 75%"
                    class="input"
                    (keyup.enter)="search(searchTerm.value)"
                    autofocus
                    autocomplete="nope"
                />
                <!--<div class="loader search-input-loader" [class.active]="isRetrievingResults" *ngIf="isRetrievingResults"></div>-->
                <button
                    class="button"
                    (click)="search(searchTerm.value)"
                    [disabled]="!canSearch()"
                    [class.button--disabled]="!canSearch()"
                    style="margin-left: 25px; font-size: 11px; width: 150px"
                >
                    Search Customer
                </button>
            </div>

            <div class="checkout__results">
                <div class="checkout__results-list">
                    <div
                        class="fade-in quick loader-container"
                        data-status="primary"
                        *ngFor="let customer of searchResults"
                        (click)="editCustomer(customer)"
                    >
                        <div class="card-result-item">
                            <p>
                                <strong>{{
                                    customer.customerNumber | number
                                }}</strong>
                            </p>
                            <p>
                                <i>Name:</i>{{ customer.user.name | titlecase }}
                            </p>
                            <p>
                                <i>Email:</i
                                >{{ customer.user.email | lowercase }}
                            </p>
                        </div>
                    </div>

                    <button
                        class="button button--secondary"
                        *ngIf="showLoadMore()"
                        (click)="loadMore()"
                        [disabled]="isRetrievingResults"
                        [class.button--disabled]="isRetrievingResults"
                    >
                        <div
                            *ngIf="isRetrievingResults"
                            class="loader xs invert-color"
                        ></div>
                        <span *ngIf="!isRetrievingResults"
                            >Load more customers</span
                        >
                    </button>
                    <p *ngIf="!searchResults.length && !isRetrievingResults">
                        No customers available
                    </p>
                    <div class="loading-results" *ngIf="isRetrievingResults">
                        <div class="loader"></div>
                        <span>Loading customers...</span>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="checkout__shipping" *ngIf="showCustomer && orderTypeCode != 'card-pre-grade'">
            <h5 style="font-family:'d77';font-weight:normal;font-size:19px;color:#785aff;cursor:pointer">See Shipping Instructions</h5>
            <div class="checkout__shipping-item fade-in" *ngFor="let shippingMethod of shippingMethods" [class.item--selected]="isShippingMethodSelected(shippingMethod)" (click)="selectShippingMethod(shippingMethod)">
                <img src="/assets/img/{{shippingMethod.carrier.code}}.png">
                <h5>{{shippingMethod.name}}</h5>
                <p>{{shippingMethod.fee | currency: 'USD': 'symbol': '1.0-0'}}</p>
            </div>
            <div class="additional-info" style="margin-left:17px">
                <h5>Additional Shipping Information</h5>
                <ul>
                    <li>Available methods are based on total order items and Insurance Value.</li>
                    <li>For orders insured over $ 25,000 please email <strong><a href="mailto:support@gosgc.com?subject=Shipping Inquiry for Order# {{order.publicId}} over $25,000">support@gosgc.com</a></strong> for pricing.</li>
                    <li>FedEx prices include residential delivery.</li>
                    <li>FedEx Ground shipping times vary based on region.</li>
                    <li>All USPS orders are sent Priority Mail</li>
                    <li>All orders are sent with signature confirmation.</li>
                </ul>
            </div>
        </div>

        <div class="" style="margin-left: 33px;font-size: 15px;" *ngIf="orderTypeCode == 'card-pre-grade'">
            Please Note: Your Pre-Grade Submission will not be shipped. It will be completed and returned at the
            2022 NSCC.
        </div>

        <div class="checkout__shipping" *ngIf="showCustomer && orderTypeCode == 'card-pre-grade'" style="visibility:hidden;">
            <h5 style="font-family:'d77';font-weight:normal;font-size:19px;color:#785aff;cursor:pointer">See Shipping Instructions</h5>
            <div class="checkout__shipping-item fade-in" *ngFor="let shippingMethod of shippingMethods" [class.item--selected]="isShippingMethodSelected(shippingMethod)" (click)="selectShippingMethod(shippingMethod)">
                <img src="/assets/img/{{shippingMethod.carrier.code}}.png">
                <h5>{{shippingMethod.name}}</h5>
                <p>{{shippingMethod.fee | currency: 'USD': 'symbol': '1.0-0'}}</p>
            </div>
        </div> -->
    </div>
</div>

<style type="text/css">
    .checkout__content {
        margin-bottom: 70px;
    }
    .checkout__shipping-item.item--selected h3.select-list__item-title,
    .checkout__shipping-item.item--selected h4 {
        color: #785aff;
    }

    @media only screen and (max-width: 550px) {
        .checkout__content {
            margin-bottom: 80px;
        }
        @media only screen and (max-width: 456px) {
            .address {
                padding-left: 10px;
            }
        }
    }
    @media only screen and (max-width: 700px) {
        .checkout__columns {
            width: 50px;
        }
        .checkout__items {
            width: 330px;
        }
        .checkout__shipping-item {
            display: flow-root;
        }
        .select-list__item-title {
            margin-bottom: 10px;
        }
        .added-card-item__info h4,
        .added-card-item__info p:not(.no-margin) {
            margin-bottom: 10px;
        }
    }
    @media only screen and (max-height: 420px) {
.checkout__content {
margin-bottom: 0px;
}
}
</style>
