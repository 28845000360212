import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../common/services/user.service';
import { CheckoutService } from '../../../checkout/services/checkout.service';
import { User } from '../../../common/models/user';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['../../dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  constructor(
    private userService: UserService,
    private checkoutService: CheckoutService
  ) {}

  currentUser = new User();
  ngOnInit() {
    this.userService.userProfile$.subscribe(
      (user) => (this.currentUser = user)
    );
  }

  toggleMobileMenu() {
    // debugger
    this.checkoutService.setinit();
    // this.checkoutService.cancelCheckout()
  }
}
