<section
    class="order-placed"
    style="background-color: #e8e8e8; border: none; padding-bottom: 100px"
>
    <h2>Thank you for your submission!</h2>
    <h3>SHIPPING INSTRUCTIONS</h3>
    <h4 *ngIf="orderTypeCode != 'card-pre-grade'">
        SGC<br />
        951 W. YAMATO RD., SUITE 150<br />
        BOCA RATON, FL 33431
    </h4>

    <h4 style="margin-bottom: 10px" *ngIf="orderTypeCode == 'card-pre-grade'">
        PLEASE PRINT THIS INVOICE AND BRING IT TO THE SGC BOOTH (#1231) AT THE
        2022 NSCC. VERIFY THAT YOUR EMAIL AND PHONE NUMBER ARE CORRECT. WHEN
        YOUR ORDER IS READY TO BE PICKED UP, SGC WILL EMAIL, CALL, AND/OR TEXT.
    </h4>

    <div class="order-placed__actions" style="padding-top: 9px;">
        <a
            class="button"
            routerLink="/orders/{{ order.id }}/invoice"
            target="_blank"
            style="margin-bottom: 12px; margin-top: 12px"
            >Print Your Invoice</a
        >
    </div>

    <p *ngIf="orderTypeCode != 'card-pre-grade'" style="padding-top: 9px">
        <!--Please print the receipt for your order and include it in the package along with your items to:-->
        Please help us with the following:<br />
        1) Click the Print Your Invoice button above and include the printed
        receipt in your package.<br />
        2) Secure your cards in semi-rigid card holders, like Card Saver I or
        Ultra Pro.
    </p>

    <div class="order-placed__tracking">
        <h4>Your order {{ getOrderPublicId() }} has started tracking!</h4>
        <div class="tracker">
            <span class="tracker--active">Submitted</span>
            <span>Graded</span>
            <span>Delivered</span>
        </div>
        <p
            class="order-placed__note"
            style="padding-bottom: 7px; font-size: 1.5rem !important"
        >
            Your order has been saved to your Submissions. You can access
            tracking information at any time on your Dashboard.
        </p>
        <a routerLink="/account/myorder" class="button button--secondary"
            >View all submissions</a
        >
    </div>

    <!-- <div class="order-placed__learn">
        <h4>Want to learn more about our grading & authentication process?</h4>
        <div class="order-placed__learn-text">
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut.</p>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="video">
            <iframe width="560" height="349" src="https://www.youtube.com/embed/NpEaa2P7qZI" frameborder="0" allow="encrypted-media"
                allowfullscreen></iframe>
        </div>
    </div> -->
</section>
