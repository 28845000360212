import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import {
    BowmanGrading, standardRawGrading, OversizedstandardRawGrading, reholder, Oversizedreholder, preCardGrading,
    SSI_GROUND_D, OVERSIZEDTALL_BOY_ITEMS_GROUND_D, SSI_FEDEXOVERNIGHT_D, SSI_FEDEX_INTERNATIONAL
} from '../../../card-grading/views/services-pricing/models/services-pricing'
import { GridComponent, GridLine, TextWrapSettingsModel } from '@syncfusion/ej2-angular-grids';
import { VirtualScrollService } from '@syncfusion/ej2-angular-grids';
import { TabComponent } from '@syncfusion/ej2-angular-navigations';
import { SelectEventArgs } from '@syncfusion/ej2-navigations';
import { ColumnModel } from '@syncfusion/ej2-angular-grids';
import { QueryCellInfoEventArgs } from '@syncfusion/ej2-grids';


@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.css'],
  providers: [VirtualScrollService]
})
export class PricingComponent implements OnInit, OnDestroy {
    constructor(
        private metaService: Meta,
        private titleService: Title
    ) { }
    @ViewChild('tab_default') tabInstance!: TabComponent;
    public select(e: SelectEventArgs) {
        if (e.isSwiped) {
            e.cancel = true;
        }
    }

    @ViewChild('grid2')
    public lines!: GridLine;
    public grid!: GridComponent;
    public dataBowmanCard!: Object[];

    public dataStandardRawCard!: Object[];
    public dataOversizedStandardRawCard!: Object[];
    public dataReholder!: Object[];
    public dataOversizedReholder!: Object[];
    public dataPreGradeCard!: Object[];
    public wrapSettings!: TextWrapSettingsModel;

    public SSI_GROUND_D!: Object[];
    public SSI_FEDEXOVERNIGHT_D!: Object[];
    public OVERSIZEDTALL_BOY_ITEMS_GROUND_D!: Object[];
    public SSI_FEDEX_INTERNATIONAL!: Object[];
    public StdColumns!: ColumnModel[];
    public StdOvrColumns!: ColumnModel[];
    public RehColumns!: ColumnModel[];
    public RehOvrColumns!: ColumnModel[];
    public BowmanColumns!: ColumnModel[];

    public headerText: Object = [{ text: "STANDARD SIZE", }, { text: "REHOLDERS" },
        { text: "OVERSIZED" }, { text: "OVERSIZED REHOLDERS" }];
    public headerTextShipping: Array<Object> = [{ text: "STD. GROUND", },
    { text: "STD. 2 Day & Overnight" }, { text: "O/S Ground" }, { text: "STD. INT’L" }];

    ngOnInit() {
        this.dataStandardRawCard = standardRawGrading;
        this.dataOversizedStandardRawCard = OversizedstandardRawGrading;
        this.dataReholder = reholder;
        this.dataOversizedReholder = Oversizedreholder;
        this.dataPreGradeCard = preCardGrading;

        this.dataBowmanCard = BowmanGrading;

        this.StdColumns = [
            {
                field: '5',
                headerText: '5-10',
                width: 100,
                textAlign: 'Center'
            },
            //{
            //    field: '10',
            //    headerText: '10',
            //    width: 100,
            //    textAlign: 'Center'
            //},
            //{
            //    field: '5',
            //    headerText: '1-5',
            //    width: 100,
            //    textAlign: 'Center'
            //}
            {
                field: '2',
                headerText: '1-2',
                width: 100,
                textAlign: 'Center'
            }
        ];
        this.StdOvrColumns = [
            {
                field: '5',
                headerText: '5-10',
                width: 100,
                textAlign: 'Center'
            },
            //{
            //    field: '10',
            //    headerText: '10',
            //    width: 100,
            //    textAlign: 'Center'
            //},
            //{
            //    field: '5',
            //    headerText: '1-5',
            //    width: 100,
            //    textAlign: 'Center'
            //}
            //{
            //    field: '2',
            //    headerText: '1-2',
            //    width: 100,
            //    textAlign: 'Center'
            //}
        ];
        this.RehColumns = [
            {
                field: '5',
                headerText: '5-10',
                width: 100,
                textAlign: 'Center'
            },
            //{
            //    field: '10',
            //    headerText: '10',
            //    width: 100,
            //    textAlign: 'Center'
            //},
            //{
            //    field: '5',
            //    headerText: '1-5',
            //    width: 100,
            //    textAlign: 'Center'
            //}
            {
                field: '2',
                headerText: '1-2',
                width: 100,
                textAlign: 'Center'
            }
        ];
        this.RehOvrColumns = [
            {
                field: '5',
                headerText: '5-10',
                width: 100,
                textAlign: 'Center'
            },
            //{
            //    field: '10',
            //    headerText: '10',
            //    width: 100,
            //    textAlign: 'Center'
            //},
            //{
            //    field: '5',
            //    headerText: '1-5',
            //    width: 100,
            //    textAlign: 'Center'
            //}
            //{
            //    field: '2',
            //    headerText: '1-2',
            //    width: 100,
            //    textAlign: 'Center'
            //}
        ];

        this.BowmanColumns = [
            {
                field: '5',
                headerText: '5-10',
                width: 100,
                textAlign: 'Center'
            }
        ];

        this.SSI_GROUND_D = SSI_GROUND_D;
        this.SSI_FEDEXOVERNIGHT_D = SSI_FEDEXOVERNIGHT_D;
        this.OVERSIZEDTALL_BOY_ITEMS_GROUND_D = OVERSIZEDTALL_BOY_ITEMS_GROUND_D;
        this.SSI_FEDEX_INTERNATIONAL = SSI_FEDEX_INTERNATIONAL;

        this.lines = 'Both';
        this.wrapSettings = { wrapMode: 'Both' };
        this.titleService.setTitle('Services and Pricing');
        this.metaService.addTag({ name: 'description', content: 'Here is a guide to getting your cards graded, from filling out the online submission form to receiving them back in your hands.' });
    }

    ngOnDestroy() {
        this.metaService.removeTag("name='description'");
    }

    customiseCellRawCard(args: QueryCellInfoEventArgs) {
        if (args.column?.field === 'DECVALUE') {
            const data = args.data as { DECVALUE?: string };

            if (data.DECVALUE == '1-19 Total Cards on Order') {
                (args.cell as any).style.padding = "0 0 0 15%";
            }
            if (data.DECVALUE == '20-49 Total Cards on Order') {
                (args.cell as any).style.padding = "0 0 0 15%";
            }
            if (data.DECVALUE == '50+ Total Cards on Order') {
                (args.cell as any).style.padding = "0 0 0 15%";
            }
        }
    }

    customiseCellReholder(args: QueryCellInfoEventArgs) {
        if (args.column?.field === 'DECVALUE') {
            const data = args.data as { DECVALUE?: string };

            if (data.DECVALUE == '1-49 Total Cards on Order') {
                (args.cell as any).style.padding = "0 0 0 15%";
            }
            if (data.DECVALUE == '50+ Total Cards on Order') {
                (args.cell as any).style.padding = "0 0 0 15%";
            }
        }
    }

}
