<!-- <div class="checkout promo-banner-adjustment"> -->

<div class="overlay" [class.active]="isRefreshing" *ngIf="isRefreshing">
    <div class="loader xl"></div>
</div>
<checkout-top-nav
    [currentStep]="checkoutCurrentStep"
    [steps]="checkoutSteps"
></checkout-top-nav>

<div>
    <router-outlet></router-outlet>
</div>
<!-- <checkout-side-bar [order]="order"></checkout-side-bar> -->
<!--</div>-->
<checkout-bottom-nav [order]="order"></checkout-bottom-nav>
