import { Component, Input } from '@angular/core';

@Component({
    selector: 'subject-result-item',
    templateUrl: './subject-result-item.component.html',
    styleUrls: ['../../checkout.component.sass']
})

export class SubjectResultItem {
    @Input() item: any = {};
}