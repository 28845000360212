import { Modal } from '../models/modal';
import { UseFedexAccountComponent } from '../../checkout/components/modals/use-fedex-account/use-fedex-account.component';
import { AddressEditorComponent } from '../modals/address-editor/address-editor.component';
import { ViewCardDetailsComponent } from '../modals/view-card-details/view-card-details.component';
import { AddSignerComponent } from '../../checkout/components/modals/specify-autograph/add-signer/add-signer.component';
import { ViewBioComponent } from '../modals/view-bio/view-bio.component';
import { SelectAutographItemComponent } from '../../checkout/components/modals/specify-autograph/select-autograph-item/select-autograph-item.component';
import { SelectAutographServiceComponent } from '../../checkout/components/modals/specify-autograph/select-autograph-service/select-autograph-service.component';
import { SelectStickerPlacementComponent } from '../../checkout/components/modals/specify-autograph/select-sticker-placement/select-sticker-placement.component';
import { SelectGradingComponent } from '../../checkout/components/modals/specify-autograph/select-grading/select-grading.component';
import { FeatureNotAvailableComponent } from '../modals/feature-not-available/feature-not-available.component';
import { FeatureComingSoonComponent } from '../modals/feature-coming-soon/feature-coming-soon.component';
import { AddAutographCardComponent } from '../../checkout/components/modals/specify-autograph/add-autograph-card/add-autograph-card.component';
import { EditAutographComponent } from '../../checkout/components/modals/edit-autograph/edit-autograph.component';
import { PersonalShippingAlertComponent } from '../../checkout/components/modals/personal-shipping-alert/personal-shipping-alert.component';
import { SetCardOptionsComponent } from '../../checkout/components/modals/set-card-options/set-card-options.component';
import { TermsAlertComponent } from '../../checkout/components/modals/terms-alert/terms-alert.component';
import { EditProfileComponent } from '../../account/components/edit-profile/edit-profile.component';
import { NewSiteAlertComponent } from '../modals/new-site/new-site.component';
import { ShippingInstructionComponent } from '../modals/shipping-instruction/shipping-instruction.component';
import { NoUserAddressAlertComponent } from '../../checkout/components/modals/no-user-address-alert/no-user-address-alert.component';
import { CardsInstructionsComponent } from '../modals/cards-instructions/cards-instructions.component';
import { CancelOrderComponent } from '../modals/cancel-order/cancel-order.component';
import { PaymentTermsComponent } from '../modals/payment-terms/payment-terms.component';
import { AlertAddressComponent } from '../modals/alert-address/alert-address.component';
import { AlertAddAddressComponent } from '../modals/alert-add-address/alert-add-address.component';
import { DeleteAddressComponent } from '../modals/delete-address/delete-address.component';
import { ViewThumbnailImageComponent } from '../modals/view-thumbnail-image/view-thumbnail-image.component';
import { ViewThumbnailFrontImageComponent } from '../modals/view-thumbnail-front-image/view-thumbnail-front-image.component';
import { DeleteCardComponent } from '../modals/delete-card/delete-card.component';


export const MODALS: Modal[] = [
    {
        type: 'use-fedex-account',
        component: UseFedexAccountComponent
    },
    {
        type: 'app-view-thumbnail-image',
        component: ViewThumbnailImageComponent
    },
    {
        type: 'app-view-thumbnail-front-image',
        component: ViewThumbnailFrontImageComponent
    },
    {
        type: 'address-editor',
        component: AddressEditorComponent
    },
    {
        type: 'add-signer',
        component: AddSignerComponent
    },
    {
        type: 'select-autograph-item',
        component: SelectAutographItemComponent
    },
    {
        type: 'add-autograph-card',
        component: AddAutographCardComponent
    },
    {
        type: 'select-autograph-service',
        component: SelectAutographServiceComponent
    },
    {
        type: 'select-sticker-placement',
        component: SelectStickerPlacementComponent
    },
    {
        type: 'select-grading',
        component: SelectGradingComponent
    },
    {
        type: 'view-card-details',
        component: ViewCardDetailsComponent
    },
    {
        type: 'view-bio',
        component: ViewBioComponent
    },
    {
        type: 'feature-not-available',
        component: FeatureNotAvailableComponent
    },
    {
        type: 'feature-coming-soon',
        component: FeatureComingSoonComponent
    }, 
    {
        type: 'edit-autograph',
        component: EditAutographComponent
    },
    {
        type: 'personal-shipping-alert',
        component: PersonalShippingAlertComponent
    },
    {
        type: 'set-card-options',
        component: SetCardOptionsComponent
    },
    {
        type: 'terms-alert',
        component: TermsAlertComponent
    },
    {
        type: 'app-payment-terms',
        component: PaymentTermsComponent
    },
    {
        type: 'app-alert-address',
        component: AlertAddressComponent
    },

    {
        type: 'app-alert-add-address',
        component: AlertAddAddressComponent
    },
    
    {
        type: 'edit-profile',
        component: EditProfileComponent
    },
    {
        type: 'new-site-alert',
        component: NewSiteAlertComponent
    },
    {
        type: 'app-cards-instructions',
        component: CardsInstructionsComponent
    },
    {
        type: 'app-shipping-instruction',
        component: ShippingInstructionComponent
    },
    {
        type: 'no-user-address-alert',
        component: NoUserAddressAlertComponent
    },
    {
        type: 'app-cancel-order',
        component: CancelOrderComponent
    },
    {
        type: 'app-delete-address',
        component: DeleteAddressComponent
    },
    {
        type: 'app-delete-card',
        component: DeleteCardComponent
    }
    
]
