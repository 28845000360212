import { Component } from '@angular/core';

import { ModalService, ModalInterface } from '../../services/modal.service';

@Component({
    moduleId: module.id,
    selector: 'view-card-details',
    templateUrl: './view-card-details.component.html'
})

export class ViewCardDetailsComponent implements ModalInterface {
    constructor(
        private modalService: ModalService
    ){}

    type = "view-card-details";
    loader!: boolean;
    options: any;

    close() {
        this.modalService.close(this.type, 'close');
    }

}
