import { Component } from "@angular/core";
import { ModalInterface, ModalService } from "../../../../common/services/modal.service";
import { OrderService } from "../../../../orders/services/order.service";
// import { OrderItemConfigurationService } from "../../../services/order-item-configuration.service";
import { OrderItemConfigurationService } from "src/app/checkout/services/order-item-configuration.services";
@Component({
    selector: 'edit-autograph',
    templateUrl: './edit-autograph.component.html',
    styleUrls: ['../../../checkout.component.sass']
})
export class EditAutographComponent implements ModalInterface {

    constructor(
        private modalService: ModalService,
        private orderService: OrderService,
        private orderItemConfigurationService: OrderItemConfigurationService
    ){}

    title = "Edit Autograph";
    subtitle = "";
    type = "edit-autograph";
    options: any;

    close() {
        this.modalService.close(this.type, 'close');
    }
}