import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'cardNameFormat'
})
export class CardNameFormatPipe implements PipeTransform {
    transform(cardName: string): string {
        let output = cardName || '';

        if(output.match(/#[a-zA-Z]/gi)) {
            output = output.replace('#', '');
        }
        
        return output;
    }
}