<div class="row_ m-0 p-4 w-100">
    <div class="col-12">
        <nav class="navbar ">
            <div class="sub-menu">
                <input type="checkbox" id="show-menu" role="button">
                <label for="show-menu" class="show-menu"></label>
                <ul class=" menu" id="menu">
                    <li>
                        <a class="menu__item " routerLink="/account/dashboard"><img src="./assets/img/dashboard/subm.png" alt="" width="15" /> Dashboard</a>
                    </li>
                    <li>
                        <a class="menu__item " routerLink="/account/myorder"><img src="./assets/img/dashboard/order.png" alt="" width="15" /> My Orders</a>
                    </li>
                    <li>
                        <a class="menu__item active" routerLink="/account/sellerready"><img src="./assets/img/dashboard/seller.png" alt="" width="15" /> Seller Ready</a>
                    </li>
                    <li>
                        <a class="menu__item " routerLink="/account/receipts"><img src="../../../../assets/img/dashboard/Dollar Icon - Black - S.png" alt="" width="15" /> Receipts</a>
                    </li>
                    <li>
                        <a class="menu__item " routerLink="/account/needhelp"><img src="./assets/img/dashboard/helpp.png" alt="" width="15" /> I Need Help</a>
                    </li>
                    <li>
                        <a class="menu__item " routerLink="/account"><img src="./assets/img/dashboard/user.png" alt="" width="15" />My Profile</a>
                    </li>
                    <!--<li>
                        <a class="menu__item" routerLink="/account/my-cards"><img src="./assets/img/dashboard/user.png" alt="" width="15" />My Cards</a>
                    </li>-->
                    <!--<li>
                        <a class="menu__item" routerLink="/account/my-cards-download"><img src="./assets/img/dashboard/user.png" alt="" width="15" />Card Downloads</a>
                    </li>-->
                </ul>

            </div>
        </nav>
    </div>
</div>
<div class="wrapper">
    <h2>Seller Ready</h2>
    <div class="user-links mt-4 p-4">
        <p>
            Every card that SGC grades is imaged at our facility. These images are provided free of charge to those that submitted the cards. To view the high-resolution images of your SGC graded cards, please click the link below. You will be redirected to another site.
        </p>
        <!--<button class="button button--secondary mt-4 mr-2">Seller Ready Image</button>-->
        <!--<div class="user-account__edit mt-6 mr-2" *ngIf="hasImageAccountUrl()" style="margin-top:25px;margin-bottom:14px;">
            <a style="background-color:black;" class="button button--secondary mt-4 mr-2" href="{{getImageAccountUrl()}}" target="_blank"><strong>Seller Ready Image</strong></a>
        </div>-->
        <div class="user-account__edit" *ngIf="hasImageAccountUrl()" style="margin-top:25px;margin-bottom:14px;">
            <p><a style="font-size:22px;" href="{{getImageAccountUrl()}}" target="_blank"><strong style="font-weight: 400;">View SellerReady Images</strong></a></p>
        </div>
    </div>
</div>
