import { Component, Input } from '@angular/core';
import { ModalService, ModalInterface } from '../../services/modal.service';

@Component({
  selector: 'app-view-thumbnail-image',
  templateUrl: './view-thumbnail-image.component.html',
  styleUrls: ['./view-thumbnail-image.component.css']
})
export class ViewThumbnailImageComponent implements ModalInterface {

    constructor(
        private modalService: ModalService
    ) { }

    options: any;
    type = "app-view-thumbnail-image";

    geth692BackImage(): string {
        var h692BackImage = "https://sgcimagprodstorage.blob.core.windows.net/mycollections/" + this.options + "/h692/back/" + this.options + ".jpg";
        return h692BackImage;
    }
    geth1000BackImage(): string {
        var h1000BackImage = "https://sgcimagprodstorage.blob.core.windows.net/mycollections/" + this.options + "/h1000/back/" + this.options + ".jpg";
        return h1000BackImage;
    }

    close() {
        this.modalService.close(this.type, 'close');
    }
}
