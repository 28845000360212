import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalService, ModalInterface } from '../../services/modal.service';
import { CheckoutService } from '../../../checkout/services/checkout.service';
import { UserService } from "../../../common/services/user.service";
import { Address, User } from "../../../common/models/user";
import { OrderService } from '../../../orders/services/order.service';
import { StateService } from '../../services/state.service';
// import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-delete-card',
  templateUrl: './delete-card.component.html',
  styleUrls: ['./delete-card.component.css']
})
export class DeleteCardComponent implements ModalInterface {
    @Output() result = new EventEmitter<string>();
    constructor(
        private modalService: ModalService,
        private checkoutService: CheckoutService,
        private userService: UserService,
        private orderService: OrderService,
        private stateService: StateService,
        // private toastr: ToastrService,
    ) { }
  
    type = "app-delete-address";
    loader!: boolean;
    options: any;
    address = new Address();
    UserId!:string;
    AuthCode:any;
    deleteResponse:any;
    item:any;
    currentUser = new User();
    close() {
        this.options.resultCallback('no');
        this.modalService.close(this.type, 'close');
    }

    deleteAddress() {
        this.options.resultCallback('yes',this.options.item.certificationCode);
        this.modalService.close(this.type, 'close');
    }

    getMessage(): string {
        var defaultMessage = ``

        return this.options && this.options.message ? this.options.message : defaultMessage;
    }
    getMessage1(): string {
        var defaultMessage = ` If you are picking up your order at SGC or a show, we require a shipping address in the event you are unable to pick up your order. `

        return this.options && this.options.message1 ? this.options.message1 : defaultMessage;
    }
    getMessage2(): string {
        var defaultMessage = `If your shipping address is not displayed, or you would like to add another address, click the '+' to add an address. After saving the address, click it to view your shipping options`

        return this.options && this.options.message2 ? this.options.message2 : defaultMessage;
    }

    getActionLabel(): string {
        return this.options && this.options.actionLabel;
    }

    hasAction(): boolean {
        return this.options && this.options.actionLabel;
    }

    takeAction() {
        this.options.actionCallback();
    }
}
