import { Component } from '@angular/core';

import { ModalService, ModalInterface } from '../../services/modal.service';

@Component({
    selector: 'feature-not-available',
    templateUrl: './feature-not-available.component.html'
})

export class FeatureNotAvailableComponent implements ModalInterface {
    constructor(
        private modalService: ModalService
    ){}

    type = "feature-not-available";
    loader!: boolean;
    options: any;

    close() {
        this.modalService.close(this.type, 'close');
    }

}
