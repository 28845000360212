import { Component } from "@angular/core";
import { ModalInterface, ModalService } from "../../../../common/services/modal.service";
import { StateService } from "../../../../common/services/state.service";

@Component({
    selector: 'terms-alert',
    templateUrl: './terms-alert.component.html',
    styleUrls: ['../../../checkout.component.sass']
})
export class TermsAlertComponent implements ModalInterface {

    constructor(
        private modalService: ModalService,
        private stateService: StateService
    ){}

    title = "Terms & Conditions";
    subtitle = "";
    type = "terms-alert";
    options: any;
    accepted: boolean = false;

    close() {
        this.modalService.close(this.type, 'close');
    }

    accept(accept: boolean) {
        var userId = this.stateService.currentUser.get().id;

        localStorage.setItem(`${userId}_acceptTerms`, JSON.stringify(accept));
        this.close();
    }
}