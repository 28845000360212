<added-card-item
  [item]="item"
  *ngIf="isCard()"
  [options]="options"
  [index]="index"
></added-card-item>
<!-- <added-autograph-item
  [item]="item"
  [options]="options"
  *ngIf="isAutograph()"
  [index]="index"
></added-autograph-item> -->
