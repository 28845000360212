import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { MomentModule } from 'ngx-moment';

import { CheckoutModule } from './checkout/checkout.module';
import { AppCommonModule } from './common/common.module';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './routes/app-routing.module';

import { GeneralLayoutComponent } from './common/layout/general-layout/general-layout.component';
// import { HomeComponent } from "./home/views/home/home.component";

import { MonitorErrorHandler } from './common/services/monitor.service';

// import { RegistryCardSetsComponent } from "./registry/views/card-sets/card-sets.component";
// import { RegistryRankingComponent } from "./registry/views/ranking/ranking.component";
// import { RegistryCustomRegistriesComponent } from "./registry/views/custom-registries/custom-registries.component";
// import { RegistrySetDetailsComponent } from "./registry/views/set-details/set-details.component";
// import { RegistryService } from "./registry/services/registry.service";
// import { RegistryComponent } from "./registry/registry.component";
import { OrdersModule } from './orders/orders.module';

import { PopReportsModule } from './pop-reports/pop-reports.module';

import { StaticLayoutComponent } from './common/layout/static-layout/static-layout.component';
// import { CaardSystemComponent } from "./caard-system/views/caard-system/caard-system.component";
// import { NewsInteriorComponent } from "./culture/views/news-interior/news-interior.component";
// import { ShowsComponent } from "./culture/views/shows/shows.component";
// import { RecommendedSitesComponent } from "./culture/views/recommended-sites/recommended-sites.component";
import { HowItWorksIndividualSignersComponent } from './card-grading/views/individual-signers/individual-signers.component';
import { HowItWorksCustomInsertsComponent } from './card-grading/views/custom-inserts/custom-inserts.component';
import { HowItWorksCardsNotGradedComponent } from './card-grading/views/cards-not-graded/cards-not-graded.component';
// import { GradingCultureComponent } from "./culture/views/grading-culture/grading-culture.component";
// import { CultureComponent } from "./culture/culture.component";
// import { CultureService } from "./culture/services/culture.service";
import { HowItWorksSubmissionsComponent } from './card-grading/views/submissions/submissions.component';
// import { ItemVerificationComponent } from "./item-verification/views/item-verification/item-verification.component";
import { PageNotAvailableComponent } from './common/views/page-not-available/page-not-available.component';
// import { PricingComponent } from "./common/views/pricing/pricing.component";
import { PageErrorComponent } from './common/views/page-error/page-error.component';
import { OrderTrackerComponent } from './common/components/order-tracker/order-tracker.component';
import { AccountModule } from './account/account.module';
import { TermsComponent } from './terms/terms.component';
import { AlertComponent } from './common/components/alert/alert.component';
// import { CuttingEdgeComponent } from "./culture/views/cutting-edge/cutting-edge.component";
// import { PhotoMatchingComponent } from "./culture/views/photo-matching/photo-matching.component";
// import { AutographSpecialsComponent } from "./culture/views/autograph-specials/autograph-specials.component";
// import { RecoverUserComponent } from "./common/views/recover-user/recover-user.component";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { DealersComponent } from "./culture/views/dealers/dealers.component";
import { CardGradingComponent } from './card-grading/card-grading.component';
import { ServicesAndPricingComponent } from './card-grading/views/services-pricing/services-pricing.component';
import { ToppsChromeSpecial } from './card-grading/views/topps-chrome-special/topps-chrome-special.component';
import { shippingFees } from './card-grading/views/shipping-fees/shipping-fees.component';
import { GradingAppComponent } from './card-grading/views/sgc-grading-app/sgc-grading-app.component';
import { FutureNowComponent } from './card-grading/views/future-now/future-now.component';
import { WelcomeNowComponent } from './card-grading/views/welcome-now/welcome-now.component';

import { CardGradingService } from './card-grading/services/card-grading.service';
// import { UniformAuthenticationComponent } from "./uniform-authentication/uniform-authentication.component";
// import { UniformAuthenticationService } from "./uniform-authentication/services/uniform-authentication.service";
// import { UniformServicesAndPricingComponent } from "./uniform-authentication/views/services-pricing/services-pricing.component";
// import { UniformSubmissionsComponent } from "./uniform-authentication/views/submissions/submissions.component";
// import { CrossoverSpecialComponent } from "./culture/views/crossover-special/crossover-special.component";
// import { RuthJerseyComponent } from "./culture/views/ruth-jersey/ruth-jersey.component";
// import { PeteRosePikachuComponent } from "./culture/views/PeteRose-Pikachu/PeteRose-Pikachu.componnet";
import { GridModule } from '@syncfusion/ej2-angular-grids';
import { UiSwitchModule } from 'ngx-ui-switch';
// import { BlogSellerReadyComponent } from "./culture/views/blog-seller-ready/blog-seller-ready.component";
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import {
  SidebarModule,
  ContextMenuModule,
  CarouselModule,
} from '@syncfusion/ej2-angular-navigations';

import {
  TextBoxModule,
  NumericTextBoxModule,
} from '@syncfusion/ej2-angular-inputs';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
// import { Covid19Component } from "./culture/views/covid-19/covid-19.component";
// import { JoinTeamComponent } from "./culture/views/join-team/join-team.component";
// import { LetterPresidentComponent } from "./culture/views/letter-president/letter-president.component";
// import { BusinessDayGuranteeComponent } from "./culture/views/business-day-gurantee/business-day-gurantee.component";
// import { BusinessdayGuaranteeService } from "./business-day-guarantee/services/businessday-guarantee.service";

// import { AboutUsService } from "./about-us/services/about-us.service";
import { CollectorSupportService } from './collector-support/services/collector-support.service';
// import { MediaService } from "./media/services/media.service";

// import { BusinessguaranteeComponent } from "./business-day-guarantee/businessguarantee.component";
// import { BusinessgurOverviewComponent } from "./business-day-guarantee/views/businessgur-overview/businessgur-overview.component";
// import { BusinessgurTermsconditionComponent } from "./business-day-guarantee/views/businessgur-termscondition/businessgur-termscondition.component";
// import { BusinessgurFaqComponent } from "./business-day-guarantee/views/businessgur-faq/businessgur-faq.component";

import { CollectorSupportComponent } from './collector-support/collector-support.component';
import { CollectorFaqComponent } from './collector-support/views/collector-faq/collector-faq.component';
import { ContactUsComponent } from './collector-support/views/contact-us/contact-us.component';

// import { AboutUsComponent } from "./about-us/about-us.component";
// import { AboutusComponent } from "./about-us/views/aboutus/aboutus.component";
// import { OurStoryComponent } from "./about-us/views/our-story/our-story.component";
// import { MediaComponent } from "./media/media.component";
// import { MediaExperienceComponent } from "./media/views/media-experience/media-experience.component";
// import { NewsComponent } from "./media/views/news/news.component";
import { TabModule } from '@syncfusion/ej2-angular-navigations';
// import { ReadUpdateComponent } from "./read-update/read-update.component";
// import { PaypalComponent } from "./paypal/paypal.component";
// import { Update20210401Component } from "./update-20210401/update-20210401.component";
// import { Update20210909Component } from "./update-20210909/update-20210909.component";
// import { OldHomePageComponent } from "./old-home-page/old-home-page.component";
// import { OldHomePage2Component } from "./old-home-page-2/old-home-page-2.component";
import { RevisedOriginalLandingPageComponent } from './revised-original-landing-page/revised-original-landing-page.component';
import { CarouselContainerComponent } from './carousel/carousel.component';
// import { WillieMaysTxtComponent } from "./willie-mays-txt/willie-mays-txt.component";
// import { BirdMagicTxtComponent } from "./bird-magic-txt/bird-magic-txt.component";
// import { CertCodeVeriVideoComponent } from "./cert-code-veri-video/cert-code-veri-video.component";
// import { HonusWagnerTxtComponent } from "./honus-wagner-txt/honus-wagner-txt.component";
// import { InstantNewsComponent } from "./instant-news/instant-news.component";
// import { ServiceLevelsNewsComponent } from "./service-levels-news/service-levels-news.component";
// import { YankeeImageNewsComponent } from "./yankee-image-news/yankee-image-news.component";
// import { CardFanComponent } from "./card-fan/card-fan.component";
// import { AuthenticImprovementComponent } from "./home/views/authentic-improvement/authentic-improvement.component";
// import { HonusWagnerComponent } from "./home/views/honus-wagner/honus-wagner.component";
// import { TurnaroundTimeComponent } from "./home/views/turnaround-time/turnaround-time.component";
// import { ScottArticleComponent } from "./home/views/scott-article/scott-article.component";
// import { BestHomepageComponent } from "./home/views/best-homepage/best-homepage.component";
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
// import { TransparencyComponent } from "./home/views/transparency/transparency.component";
// import { PricingDecreaseComponent } from "./home/views/pricing-decrease/pricing-decrease.component";
// import { MantleRecordSaleComponent } from "./home/views/mantle-record-sale/mantle-record-sale.component";
// import { FreeAutogradesComponent } from "./home/views/free-autogrades/free-autogrades.component";
// import { NoUpchargeModernComponent } from "./home/views/no-upcharge-modern/no-upcharge-modern.component";
// import { HomenewsComponent } from "./homenews/homenews.component";
import { FanaticsBowmanchromeSpecialComponent } from "./home/views/fanatics-bowmanchrome-special/fanatics-bowmanchrome-special.component";
// import { MakingGradingMoreFunComponent } from "./home/views/making-grading-more-fun/making-grading-more-fun.component";
// import { PokemonSpecialComponent } from "./home/views/2023-01-pokemon-special/2023-01-pokemon-special.component";
// import { TrustedComponent } from "./home/views/trusted/trusted.component";
// import { StripeErrorComponent } from "./orders/views/stripe-error/stripe-error.component";
// import { NewComponent } from "./new-component/new-component";
import { NgxCaptchaModule } from 'ngx-captcha';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NgOptimizedImage } from '@angular/common';
import { SgcJoinsCollectorsComponent } from './home/views/sgc-joins-collectors/sgc-joins-collectors.component';
import { BowmanSpecialComponent } from './card-grading/views/tcs-services-pricing copy/tcg-services-pricing.component';
import { TcgServicesAndPricingComponent } from './card-grading/views/tcs-services-pricing/tcg-services-pricing.component';
import { BasketballSpecialComponent } from './card-grading/views/basketball-special/basketball-special.component';
import { ToppsSeriesTwoComponent } from './card-grading/views/topps-series-two/topps-series-two.component';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppCommonModule,
    CheckoutModule,
    OrdersModule,
    PopReportsModule,
    AccountModule,
    NgxCaptchaModule,
    HttpClientModule,
    AppRoutingModule,
    MomentModule,
    FormsModule,
    ReactiveFormsModule,
    GridModule,
    UiSwitchModule,
    ButtonModule,
    SidebarModule,
    ContextMenuModule,
    TextBoxModule,
    NumericTextBoxModule,
    DialogModule,
    TabModule,
    LazyLoadImageModule,
    NgOptimizedImage,
    CarouselModule
  ],
  declarations: [
    AppComponent,
    // HomeComponent,
    // HomenewsComponent,
    // CultureComponent,
    // GradingCultureComponent,
    CardGradingComponent,
    // CaardSystemComponent,
    GeneralLayoutComponent,
    StaticLayoutComponent,
    // RegistryCardSetsComponent,
    // RegistryRankingComponent,
    // RegistryCustomRegistriesComponent,
    // RegistrySetDetailsComponent,
    // NewsComponent,
    // NewsInteriorComponent,
    // ShowsComponent,
    // RecommendedSitesComponent,
    // ItemVerificationComponent,
    BowmanSpecialComponent,
    HowItWorksIndividualSignersComponent,
    HowItWorksCustomInsertsComponent,
    HowItWorksCardsNotGradedComponent,
    HowItWorksSubmissionsComponent,
    ContactUsComponent,
    // RegistryComponent,
    PageNotAvailableComponent,
    // PricingComponent,
    PageErrorComponent,
    OrderTrackerComponent,
    TermsComponent,
    PrivacyPolicyComponent,
    AlertComponent,
    // CuttingEdgeComponent,
    // PhotoMatchingComponent,
    // AutographSpecialsComponent,
    // RecoverUserComponent,
    // DealersComponent,
    ServicesAndPricingComponent,
    TcgServicesAndPricingComponent,
    ToppsChromeSpecial,
    shippingFees,
    GradingAppComponent,
    FutureNowComponent,
    WelcomeNowComponent,

    // UniformAuthenticationComponent,
    // UniformServicesAndPricingComponent,
    // UniformSubmissionsComponent,
    // CrossoverSpecialComponent,
    // RuthJerseyComponent,
    // PeteRosePikachuComponent,
    // BlogSellerReadyComponent,
    // Covid19Component,
    // JoinTeamComponent,
    // LetterPresidentComponent,
    // BusinessDayGuranteeComponent,
    // BusinessguaranteeComponent,
    // BusinessgurOverviewComponent,
    // BusinessgurTermsconditionComponent,
    // BusinessgurFaqComponent,
    CollectorSupportComponent,
    CollectorFaqComponent,
    // AboutUsComponent,
    // MediaComponent,
    // AboutusComponent,
    // OurStoryComponent,
    // MediaExperienceComponent,
    // ReadUpdateComponent,
    // PaypalComponent,
    // Update20210401Component,
    // Update20210909Component,
    // OldHomePageComponent,
    // OldHomePage2Component,
    RevisedOriginalLandingPageComponent,
    CarouselContainerComponent,
    // WillieMaysTxtComponent,
    // BirdMagicTxtComponent,
    // CertCodeVeriVideoComponent,
    // HonusWagnerTxtComponent,
    // InstantNewsComponent,
    // ServiceLevelsNewsComponent,
    // YankeeImageNewsComponent,
    // CardFanComponent,
    // AuthenticImprovementComponent,
    // HonusWagnerComponent,
    // TurnaroundTimeComponent,
    // ScottArticleComponent,
    // BestHomepageComponent,
    // TransparencyComponent,
    // PricingDecreaseComponent,
    // MantleRecordSaleComponent,
    // FreeAutogradesComponent,
    // NoUpchargeModernComponent,
    FanaticsBowmanchromeSpecialComponent,
    SgcJoinsCollectorsComponent,
    BasketballSpecialComponent,
    ToppsSeriesTwoComponent,
    // MakingGradingMoreFunComponent,
    // PokemonSpecialComponent,
    // TrustedComponent,

    // StripeErrorComponent,
    // NewComponent,
  ],
  providers: [
    // RegistryService,
    // CultureService,
    CardGradingService,
    // BusinessdayGuaranteeService,
    // AboutUsService,
    CollectorSupportService,
    // MediaService,
    // UniformAuthenticationService,
    {
      provide: ErrorHandler,
      useClass: MonitorErrorHandler,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
