<card-item-list [items]="items" *ngIf="isCardGrading()"></card-item-list>
<autograph-item-list
  [items2]="items2"
  *ngIf="isAutographAuthentication()"
></autograph-item-list>

<!-- <card-item-list [items]="items" *ngIf="isCardGrading()"></card-item-list>
<autograph-item-list [items]="items" *ngIf="isAutographAuthentication()"></autograph-item-list> -->
<!-- <ng-container *ngIf="isCardGrading()">
    <ng-container
      *ngTemplateOutlet="cardItemList; context: { items: items }"
    ></ng-container>
  </ng-container>
  
  <ng-container *ngIf="isAutographAuthentication()">
    <ng-container
      *ngTemplateOutlet="autographItemList; context: { items: items }"
    ></ng-container>
  </ng-container> -->
<!-- <ng-template #cardItemList>
    <card-item-list [items]="items"></card-item-list>
  </ng-template>
  
  <ng-template #autographItemList>
    <autograph-item-list [items]="items"></autograph-item-list>
  </ng-template> -->
