import { Component, Input } from '@angular/core';
import { OrderType } from '../../../orders/models/order';

@Component({
    selector: 'result-item',
    templateUrl: './result-item.component.html'
})
export class ResultItem {

    @Input() type = new OrderType();
    @Input() item = {};

    isCard(): boolean {
        // console.log('typeeeeeeeeeeeeeeeeeeeeeee', this.type)
        return (this.type.isCardGrading() || this.type.isRawCardReview() || this.type.isBowmanChrome() || this.type.isPokemanspecials() || this.type.is23BOWMANEOY()
        || this.type.is23TOPPSCHR() || this.type.is24BASKETBALL() || this.type.is24TOPPSS2());
    }

    isAutograph(): boolean {
        return this.type.isAutographAuthentication();
    }
}
