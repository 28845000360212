import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SelectExpertiseComponent } from './views/expertise/select-expertise.component';
import { AddItemsComponent } from './views/items/add-items.component';
import { SelectShippingComponent } from './views/shipping/select-shipping.component';
import { PayOrderComponent } from './views/payment/pay-order.component';
import { OrderPlacedComponent } from './views/confirmed/order-placed.component';
import { CheckoutComponent } from './checkout.component';
import { CheckoutOrderComponent } from './views/checkout-order/checkout-order.component';
import { GeneralLayoutComponent } from '../common/layout/general-layout/general-layout.component';
import { SelectPlanComponent } from './views/plans/select-plan.component';
import { AuthGuard } from '../common/guards/auth.guard';
import { CheckoutStepGuard } from './guards/checkout-step.guard';
import { AddressComponent } from './views/address/address.component';
import { PaymentProcessComponent } from './views/payment-process/payment-process.component';

export const routes: Routes = [
  {
    path: 'checkout',
    // path: '',
    canActivate: [AuthGuard],
    component: GeneralLayoutComponent,
    children: [
      {
        path: '',
        component: CheckoutComponent,
        children: [
          {
            path: ':id',
            canActivateChild: [CheckoutStepGuard],
            component: CheckoutOrderComponent,
            children: [
              { path: 'expertise', component: SelectExpertiseComponent },
              { path: 'items', component: AddItemsComponent },
              { path: 'plans', component: SelectPlanComponent },
              { path: 'address', component: SelectShippingComponent },
              { path: 'shipping', component: AddressComponent },
              { path: 'review', component: PayOrderComponent },
              { path: 'payment', component: PaymentProcessComponent },
              { path: 'confirmation', component: OrderPlacedComponent },
            ],
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class CheckoutRoutingModule {}
