import { Component } from '@angular/core';

import {
  ModalService,
  ModalInterface,
} from '../../../../common/services/modal.service';

@Component({
  //moduleId: module.id,
  selector: 'use-fedex-account',
  templateUrl: './use-fedex-account.component.html',
})
export class UseFedexAccountComponent implements ModalInterface {
  constructor(private modalService: ModalService) {}

  title = 'Fed-Ex Account';
  subtitle =
    'You can use your personal Fed-Ex account for shipping orders. Please note that if you use it, the order will not be covered by SGC insurance.';
  type = 'use-fedex-account';
  loader!: boolean;
  options: any;

  close() {
    this.modalService.close(this.type, 'close');
  }

  save() {
    this.loader = true;
    setTimeout(() => {
      this.loader = false;
      this.modalService.close(this.type, 'ok');
    }, 2000);
  }

  discard() {
    this.modalService.close(this.type, 'cancel');
  }
}
