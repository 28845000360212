import { Component, OnInit } from '@angular/core';
import { CheckoutService } from '../../services/checkout.service';
import { OrderService } from '../../../orders/services/order.service';
import { Order } from '../../../orders/models/order';

@Component({
    selector: 'checkout-order',
    templateUrl: './checkout-order.component.html',
    styleUrls: ['../../checkout.component.sass']
})
export class CheckoutOrderComponent implements OnInit
{
    constructor(
        private checkoutService: CheckoutService
    ) { }

    order = new Order();
    checkoutSteps!: any[];
    checkoutCurrentStep: any;
    isRefreshing: boolean = false;

    ngOnInit()
    {
        this.isRefreshing = true;
        localStorage.removeItem('newUserInfo');
        this.checkoutService.currentOrder$.subscribe(order =>
        {
            this.order = order;
            this.isRefreshing = false;
        });
        this.checkoutService.refreshedOrder$.subscribe(order =>
        {
            this.order = order;
            this.isRefreshing = false;
        });
        this.checkoutService.currentStep$.subscribe(
            step =>
            {

                this.checkoutCurrentStep = step
            });
        this.checkoutSteps = this.checkoutService.getSteps();
    }
}
