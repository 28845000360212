import { Component, Input } from '@angular/core';
import {
  OrderItem,
  OrderType,
  CardOrderItem,
  AutographOrderItem,
} from '../../../orders/models/order';

import { CardItemListComponent } from '../card-item-list/card-item-list.component'; // Replace the path with the actual path to the component file
import { AutographItemListComponent } from '../autograph-item-list/autograph-item-list.component'; // Replace the path with the actual path to the component file

@Component({
  selector: 'order-item-list',
  templateUrl: './order-item-list.component.html',
  styleUrls: ['../../../checkout/checkout.component.sass'],
  // entryComponents: [CardItemListComponent, AutographItemListComponent],
})
export class OrderItemListComponent {
  // @Input() items = new Array<OrderItem>();
  @Input() items = new Array<CardOrderItem>();
  @Input() items2 = new Array<AutographOrderItem>();
  @Input() type = new OrderType();
  cardItemList: any; // Replace 'any' with the appropriate type
  autographItemList: any; // Replace 'any' with the appropriate type

  isCardGrading(): boolean {
    return (
      this.type &&
      (this.type.isCardGrading() ||
        this.type.isRawCardReview() ||
        this.type.isBowmanChrome() ||
        this.type.isPokemanspecials() || this.type.is23BOWMANEOY() || this.type.is24BASKETBALL() || this.type.is24TOPPSS2())
    );
  }

  isAutographAuthentication(): boolean {
    return this.type && this.type.isAutographAuthentication();
  }
}
