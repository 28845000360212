import { Pipe, PipeTransform } from '@angular/core';
import { Address } from '../models/user';

@Pipe({
    name: 'cityStateCountryFormat'
})
export class CityStateCountryFormat implements PipeTransform {
    transform(address: Address, includeCountry: boolean): string {
        let output: string = null!;

        if (!address) return null!;
        
        output = address.city;

        if (address.state && address.state.name) output += ', ' + address.state.name;

        output += ', ' + address.zipCode;

        includeCountry && address.country && (output += ', ' + address.country.name);

        return output;
    }
}