import { empty } from 'rxjs';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
//import { PopReportsService } from '../../../pop-reports/services/pop-reports.service';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { OrderService } from '../../../orders/services/order.service';
import { User } from '../../../common/models/user';
import { StateService } from '../../../common/services/state.service';
import { DeletedUnDeletedOrderForCustomer } from '../../../orders/models/order';
import {
  GridComponent,
  FilterSettingsModel,
  IFilter,
  TextWrapSettingsModel,
  PageSettingsModel,
} from '@syncfusion/ej2-angular-grids';
import { DataUtil } from '@syncfusion/ej2-data';
import { PopReportsService } from 'src/app/pop-reports/services/pop-reports.service';

@Component({
  selector: 'app-need-help',
  templateUrl: './need-help.component.html',
  styleUrls: ['../../dashboard.component.css', './need-help.component.css'],
})
export class NeedHelpComponent implements OnInit {
  form!: FormGroup;
  email = '^[^@]+@[a-zA-Z0-9._-]+\\.+[a-z._-]+$';
  isSubmitting = false;
  successmsg = false;
  emailsentmsg: any;
  baseUrl: string = environment.sgc.apiUrl;
  deletedUnDeletedOrderForCustomer!: DeletedUnDeletedOrderForCustomer[];

  isDeletedUnDeletedOrderForCustomer: boolean = false;
  isorderNumber: boolean = false;
  isUserLoggedIn: boolean = false;
  customerNo!: number;
  isIssue: boolean = false;
  isDisabilities: boolean = false;

  iscustomerNumber: boolean = false;
  isNumOfCard: boolean = false;
  isEstPay: boolean = false;
  isphone: boolean = false;
  isphoneoptional: boolean = false;
  isbesttimetocall: boolean = false;
  isComments: boolean = false;
  isMessage: boolean = true;

  page: any;
  iscaptchawrong: any;
  captchamsg: any;
  captchaLocaleStyleName: any;
  isPaypal: boolean = false;
  currentUser = new User();

  constructor(
    private metaService: Meta,
    private orderService: OrderService,
    private stateService: StateService,
    private titleService: Title,
    private formBuilder: FormBuilder,
    private popReportsService: PopReportsService
  )
  {}

  @ViewChild('grid')
  public grid!: GridComponent;

  public format = { type: 'date', format: 'MM-dd-yy' };
  public FilterOptions: FilterSettingsModel = {
    type: 'Menu',
  };
  public filter: IFilter = {
    params: {
      format: 'yyyy-MM-dd',
    },
  };
  public pageSettings!: PageSettingsModel;
  public wrapSettings!: TextWrapSettingsModel;

  ngOnInit() {
    this.pageSettings = { pageSize: 25 };
    this.wrapSettings = { wrapMode: 'Both' };

    this.isDeletedUnDeletedOrderForCustomer = true;
    this.stateService.currentUser.changes.subscribe((user) => {
      this.currentUser = user;
    });
    this.orderService
      .GetDeletedUnDeletedOrderForCustomer(this.currentUser.customerId)
      .subscribe((response) => {
        this.deletedUnDeletedOrderForCustomer = response;
        this.isDeletedUnDeletedOrderForCustomer = false;
      });

    this.captchaLocaleStyleName = 'loginCaptcha_en';
    this.iscaptchawrong = false;
    this.form = this.formBuilder.group({
      typeofinquiry: new FormControl('brent@gosgc.com', Validators.required),
      name: new FormControl(
        null,
        Validators.compose([
          Validators.pattern('^[a-zA-Z,.!? ]*$'),
          Validators.maxLength(100),
          Validators.required,
        ])
      ),
      email: new FormControl(
        null,
        Validators.compose([
          Validators.pattern(this.email),
          Validators.required,
        ])
      ),
      message: new FormControl('', Validators.required),
      issue: new FormControl(
        'I want to opt-out of all marketing communications',
        Validators.required
      ),
    });
    this.typeofinquiry.valueChanges.subscribe((checked) => {
      if (checked == 'PayPal Issue / Completing Order Payment') {
        const validators = [Validators.required];

        this.isDisabilities = false;
        this.isPaypal = true;
        this.isorderNumber = true;
        this.isIssue = false;
        this.isphone = true;
        this.isphoneoptional = true;
        //this.isbesttimetocall = true;
        this.isComments = true;
        this.isMessage = false;

        this.form.addControl(
          'orderNumber',
          new FormControl(
            '',
            Validators.compose([
              Validators.pattern('^[0-9]*$'),
              Validators.required,
            ])
          )
        );
        //this.form.addControl('customerNumber', new FormControl('', Validators.compose([Validators.pattern('^[0-9]*$'), Validators.required])));
        //this.form.addControl('NumOfCard', new FormControl('', Validators.compose([Validators.pattern('^[0-9]*$'), Validators.required])));
        //this.form.addControl('EstPay', new FormControl('', validators));
        this.form.addControl('phone', new FormControl('', validators));
        this.form.addControl('phoneoptional', new FormControl(''));
        //this.form.addControl('besttimetocall', new FormControl('', validators));
        this.form.addControl('comments', new FormControl(''));
        this.form.removeControl('message');
        this.form.removeControl('issue');
      } else if (checked == 'Assistance for Users With Disabilities') {
        const validators = [Validators.required];

        this.isDisabilities = true;
        this.isPaypal = false;
        this.isorderNumber = false;
        this.isphone = true;
        this.isIssue = false;
        this.isphoneoptional = false;
        this.isComments = false;
        this.isMessage = true;

        this.form.removeControl('orderNumber');
        this.form.addControl('phone', new FormControl('', validators));
        this.form.removeControl('phoneoptional');
        this.form.removeControl('comments');
        this.form.addControl(
          'message',
          new FormControl('', Validators.required)
        );
        this.form.removeControl('issue');
      } else if (checked == 'Privacy Concerns / Account Deletion') {
        const validators = [Validators.required];

        this.isDisabilities = false;
        this.isPaypal = false;
        this.isorderNumber = false;
        this.isphone = true;
        this.isIssue = true;
        this.isphoneoptional = false;
        this.isComments = false;
        this.isMessage = true;

        this.form.removeControl('orderNumber');
        this.form.addControl('phone', new FormControl('', validators));
        this.form.removeControl('phoneoptional');
        this.form.removeControl('comments');
        this.form.addControl(
          'message',
          new FormControl('', Validators.required)
        );
        this.form.addControl(
          'issue',
          new FormControl(
            'I want to opt-out of all marketing communications',
            Validators.required
          )
        );
      } else {
        this.isDisabilities = false;
        this.isPaypal = false;
        this.isorderNumber = false;
        this.isIssue = false;
        this.isphone = false;
        this.isphoneoptional = false;
        //this.isbesttimetocall = false;
        this.isComments = false;
        this.isMessage = true;

        this.form.removeControl('orderNumber');
        //this.form.removeControl('customerNumber');
        //this.form.removeControl('NumOfCard');
        //this.form.removeControl('EstPay');
        this.form.removeControl('phone');
        this.form.removeControl('phoneoptional');
        //this.form.removeControl('besttimetocall');
        this.form.removeControl('comments');
        this.form.addControl(
          'message',
          new FormControl('', Validators.required)
        );
        this.form.removeControl('issue');
      }
      this.form.updateValueAndValidity();
    });
    this.titleService.setTitle('Contact SGC');
    this.metaService.addTag({
      name: 'description',
      content:
        'Contact SGC today with any questions you may have about grading and authenticating your collection.',
    });
  }

  get typeofinquiry() {
    return this.form.get('typeofinquiry') as FormControl;
  }
  get orderNumber() {
    return this.form.get('orderNumber') as FormControl;
  }
  get customerNumber() {
    return this.form.get('customerNumber') as FormControl;
  }
  get phone() {
    return this.form.get('phone') as FormControl;
  }
  get phoneoptional() {
    return this.form.get('phoneoptional') as FormControl;
  }
  get comments() {
    return this.form.get('comments') as FormControl;
  }
  get message() {
    return this.form.get('message') as FormControl;
  }
  SubmitEmail() {
    this.isUserLoggedIn = true;
    this.customerNo = this.currentUser.customerNumber;
    this.page = 'need-help';
    this.isSubmitting = true;
    if (this.form.invalid) {
        return;
    }
    this.popReportsService.ContactUsEmail(this.form.value, this.isUserLoggedIn, this.customerNo, this.page).subscribe(response => {
        if (response.success == true) {
            this.successmsg = true;
            this.isSubmitting = false;
            this.emailsentmsg = "Message sent successfully.";
        } else {
            this.successmsg = false;
            this.isSubmitting = false;
            this.emailsentmsg = "something went wrong please try again.";
        }
    });
}

  undeleteOrder(id: string) {
    this.isDeletedUnDeletedOrderForCustomer = true;
    this.orderService
      .UnDeleteOrderAsync(this.currentUser.customerId, id)
      .subscribe((response) => {
        this.deletedUnDeletedOrderForCustomer = response;
        this.isDeletedUnDeletedOrderForCustomer = false;
      });
  }

  getRowClass(data: any): string {
    // Use your criteria to determine the row color
    if (data.someCondition) {
      return 'black-row'; // Apply the black background color
    } else {
      return 'red-row'; // Apply the red background color
    }
  }
  
}
