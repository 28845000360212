<img
  style="top: 10px"
  src="/assets/img/close-modal.svg"
  class="modal__close"
  (click)="close()"
/>
<div style="margin-top: 4px" class="feature-not-available">
  <div>
    <div>
      <p *ngIf="OrderCancel == false" style="font-size: 18px; text-align: left">
        Are you sure you want to delete/cancel this order?
      </p>
    </div>
    <br />
    <div *ngIf="OrderCancel">
      <p style="font-size: 18px; text-align: left">
        Order has been deleted. If you made a mistake, you may restore a recently
        deleted order at the bottom of the I Need Help page. If you need further assistance,
        you may contact support using the
        <a class="menu__item" routerLink="/account/needhelp">I Need Help</a>
        page or emailing
        <a href="mailto:support@gosgc.com?subject=Deleted Order #{{getMessage()}}" target="_blank">support&#64;gosgc.com</a>
        directly.
      </p>
    </div>
    <br />
    <div *ngIf="OrderCancel == false">
      <!-- <button class="button" style="margin-left:32%" (click)="cancelOrder()" [class.button--disabled]="!isCheckoutFinished() && OrderCancel==true" [disabled]="!isCheckoutFinished() && OrderCancel==true">Yes</button> -->
      <button
        class="button"
        style="margin-left: 32%"
        (click)="cancelOrder()"
        [class.button--disabled]="!isCheckoutFinished() && OrderCancel"
        [disabled]="!isCheckoutFinished() && OrderCancel"
      >
        Yes
      </button>

      <button style="margin-left: 15px" class="button" (click)="close()">
        NO
      </button>
    </div>
  </div>
</div>
