import { Component } from '@angular/core';

import { ModalService, ModalInterface } from '../../services/modal.service';
@Component({
  selector: 'app-shipping-instruction',
  templateUrl: './shipping-instruction.component.html',
  styleUrls: ['./shipping-instruction.component.css']
})
export class ShippingInstructionComponent implements ModalInterface {
    constructor(
        private modalService: ModalService
    ) { }

    type = "app-shipping-instruction";
    loader!: boolean;
    options: any;

    close() {
        this.modalService.close(this.type, 'close');
    }

    getMessage(): string {
        var defaultMessage = `Click on the shipping address below to view your shipping options. If you are picking up your order at SGC or a show, we require a shipping address in the event you are unable to pick up your order. If your shipping address is not displayed, or you would like to add another address, click the '+' to add an address. After saving the address, click it to view your shipping options`

        return this.options && this.options.message ? this.options.message : defaultMessage;
    }
    getMessage1(): string {
        var defaultMessage = ` If you are picking up your order at SGC or a show, we require a shipping address in the event you are unable to pick up your order. `

        return this.options && this.options.message1 ? this.options.message1 : defaultMessage;
    }
    getMessage2(): string {
        var defaultMessage = `If your shipping address is not displayed, or you would like to add another address, click the '+' to add an address. After saving the address, click it to view your shipping options`

        return this.options && this.options.message2 ? this.options.message2 : defaultMessage;
    }

    getActionLabel(): string {
        return this.options && this.options.actionLabel;
    }

    hasAction(): boolean {
        return this.options && this.options.actionLabel;
    }

    takeAction() {
        this.options.actionCallback();
    }
}

