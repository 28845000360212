import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

import { Idle, DocumentInterruptSource } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { MonitorService } from './monitor.service';
import { StateService } from './state.service';

@Injectable()
export class SessionService {
    constructor(
        private authService: AuthService,
        private idle: Idle, 
        private keepalive: Keepalive,
        private monitorService: MonitorService,
        private stateService: StateService
    ){}

    timedOut = false;
    private subscription = new Subscription();

    init() {
        this.authService.loggedIn$.subscribe(event => {
            if(!event) return;
            this.start();
        });

        this.authService.loggedOut$.subscribe(event => {
            if(!event) return;
            this.stop();
        })
    }

    start() {
        this.idle.setIdle(environment.session.idle);
        this.idle.setTimeout(environment.session.logoutTimeout);
        this.idle.setInterrupts([new DocumentInterruptSource("mousemove keydown DOMMouseScroll mousewheel mousedown touchstart touchmove scroll")])

        // remove alert
        // this.idle.onIdleEnd.subscribe();
        
        const onTimeoutSubscription = this.idle.onTimeout.subscribe(() => {
            this.logout();
        });

        this.subscription.add(onTimeoutSubscription);
        
        // show an alert when being idle
        // this.idle.onIdleStart.subscribe();
        
        // show an alert when about to being logged out
        // this.idle.onTimeoutWarning.subscribe((countdown) => {});

        this.keepalive.interval(environment.session.keepAliveInterval);

        const onPingSubscription = this.keepalive.onPing.subscribe(() => this.keepAlive());
        
        this.subscription.add(onPingSubscription);

        this.idle.watch();
    }

    stop() {
        this.idle.stop();
        this.subscription.unsubscribe();
    }

    keepAlive() {
        this.trackEvent('authenticate', 'renew-session');
        this.authService.checkSession();
    }

    logout() {
        this.timedOut = true;
        this.trackEvent('logout', 'on-inactivity');
        this.authService.logout();
    }

    trackEvent(event: string, category: string) {
        var userId = this.stateService.currentUser.get().id;

        this.monitorService.trackEvent(event, { 'userId': userId, 'category' : category });
    }
}