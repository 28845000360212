import { Injectable } from '@angular/core';
import { StateService } from '../../common/services/state.service';

@Injectable()
export class CollectorSupportService {
  constructor(private stateService: StateService) {}

  submenuItems = [
    {
      name: 'Contact US',
      path: '/collector-support/contact-us',
    },
    {
      name: 'Faq',
      path: '/collector-support/collector-faq',
    },
  ];

  activateSubMenu() {
    this.stateService.activateSubMenu(this.submenuItems);
  }

  deactivateSubMenu() {
    this.stateService.deactivateSubMenu();
  }
}
