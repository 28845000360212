<div>
  <div class="overlay active" *ngIf="isLoading()">
    <div class="welcome">
      <div class="loader xl"></div>
      <div class="welcome-text">
        Please, hold on a few seconds while we prepare your order
      </div>
    </div>
  </div>
  <router-outlet></router-outlet>
</div>
