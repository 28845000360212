import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { GENERAL_ROUTES } from './general.routes';
import { GeneralLayoutComponent } from '../common/layout/general-layout/general-layout.component';

import { PageNotAvailableComponent } from '../common/views/page-not-available/page-not-available.component';
import { PricingComponent } from '../common/views/pricing/pricing.component';
import { PageErrorComponent } from '../common/views/page-error/page-error.component';
import { AuthGuard } from '../common/guards/auth.guard';
//import { MycardsComponent} from '../customercardscollection/mycards/mycards.component';

const moduleRoutes: Routes = [
    { path: '', loadChildren: () => import('./../modules/public.module').then((m) => m.PublicModule) },
    // { 
    //     path: 'account', 
    //     loadChildren: () => import('./../account/account.module').then((m) => m.AccountModule) 
    // },
];

const routes: Routes = [
    {
        path: '',
        component: GeneralLayoutComponent,
        children: moduleRoutes
    },
    // { 
    //     path: 'account', 
    //     canActivate: [AuthGuard],
    //     loadChildren: () => import('./../account/account.module').then((m) => m.AccountModule) 
    // },
    // {
    //     path: 'checkout',
    //     loadChildren: () => import('./../checkout/checkout.module').then((m) => m.CheckoutModule)
    // },
    // {
    //     path: 'orders',
    //     canActivate: [AuthGuard],
    //     loadChildren: () => import('./../orders/orders.module').then((m) => m.OrdersModule)
    // },

    //{
    //    path: 'customercard',
    //   /* loadChildren: '../customercardscollection/customercardscollection.module#CustomercardscollectionModule'*/
    //    loadChildren: () => import('../customercardscollection/customercardscollection.module')
    //        .then(m => m.CustomercardscollectionModule)
    //},
    /*{ path: 'customercard', component: MycardsComponent },*/
    { path: '404', component: PageNotAvailableComponent },
    { path: 'pricing', component: PricingComponent },
    { path: '500', component: PageErrorComponent },
    { path: '**', redirectTo: '404' },
];


@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})

export class AppRoutingModule { }
