<div class="row_ m-0 p-4 w-100">
    <div class="col-12">
        <nav class="navbar ">
            <div class="sub-menu">
                <input type="checkbox" id="show-menu" role="button">
                <label for="show-menu" class="show-menu"></label>
                <ul class=" menu" id="menu">
                    <li>
                        <a class="menu__item " routerLink="/account/dashboard"><img src="./assets/img/dashboard/subm.png" alt="" width="15" /> Dashboard</a>
                    </li>
                    <li>
                        <a class="menu__item " routerLink="/account/myorder"><img src="./assets/img/dashboard/order.png" alt="" width="15" /> My Orders</a>
                    </li>
                    <li>
                        <a class="menu__item " routerLink="/account/sellerready"><img src="./assets/img/dashboard/seller.png" alt="" width="15" /> Seller Ready</a>
                    </li>
                    <li>
                        <a class="menu__item " routerLink="/account/receipts"><img src="../../../../assets/img/dashboard/Dollar Icon - Black - S.png" alt="" width="15" />Receipts</a>
                    </li>
                    <li>
                        <a class="menu__item active" routerLink="/account/needhelp"><img src="./assets/img/dashboard/helpp.png" alt="" width="15" /> I Need Help</a>
                    </li>
                    <li>
                        <a class="menu__item " routerLink="/account"><img src="./assets/img/dashboard/user.png" alt="" width="15" />My Profile</a>
                    </li>
                    <!--<li>
                        <a class="menu__item" routerLink="/account/my-cards"><img src="./assets/img/dashboard/seller.png" alt="" width="15" />My Cards</a>
                    </li>-->
                    <!--<li>
                        <a class="menu__item" routerLink="/account/my-cards-download"><img src="./assets/img/dashboard/seller.png" alt="" width="15" />Card Downloads</a>
                    </li>-->
                </ul>

            </div>
        </nav>
    </div>
</div>
<div class="wrapper">
    <h2>I Need Help</h2>
    <div class="user-links  mt-4 p-4">

        <p style="padding-top:15px;font-weight:bolder;">If you have a question, comment, or concern, we would like to hear from you. Please complete the Inquiry Form below and we will respond to you as soon as possible.</p>

        <p style="padding-top:15px;font-weight:bolder;">If you are having any issues contacting us or you have not heard from us in 2 business days, email <a style="color:#785aff;" href="mailto:support@gosgc.com?subject=COLLECTOR SUPPORT">support&#64;gosgc.com</a></p>


        <div class="contact-us__list" style="margin-top:15px;">
            <form id="form" [formGroup]="form">
                <ul>
                    <li>
                        <div class="added-card-item__info">
                            <h4 style="padding-bottom:4px;padding-top:3px; ">Type of Inquiry</h4>
                            <div class="select" style="width: 50%;">
                                <select class="input" formControlName="typeofinquiry">
                                    <option value="brent&#64;gosgc.com">General Inquiries</option>
                                    <!--<option value="bulk&#64;gosgc.com">Bulk Order Info (100 cards or more)</option>-->
                                    <option value="PayPal Issue / Completing Order Payment">Payment Issues</option>
                                    <option value="support&#64;gosgc.com">Technical Issues (including Pop Report)</option>
                                    <option value="Assistance for Users With Disabilities">Assistance for Users With Disabilities</option>
                                    <option value="Privacy Concerns / Account Deletion">Privacy Concerns / Account Deletion</option>
                                </select>
                            </div>
                        </div>
                        <div *ngIf="form.controls['typeofinquiry'].touched && !form.controls['typeofinquiry'].valid" style="color: red;margin-top: 0px;">
                            <p *ngIf="form.controls['typeofinquiry'].hasError('required')" class="text-danger">
                                * Please select Type of Inquiry
                            </p>
                        </div>
                    </li>
                    <li *ngIf="isDisabilities">
                        <p style="padding-top:15px;font-weight:bolder;">If you are a user with a disability and have issues with this form, email <a style="color:#785aff;" href="mailto:support@gosgc.com?subject=COLLECTOR SUPPORT">support&#64;gosgc.com</a></p>
                    </li>
                    <li *ngIf="isIssue">
                        <p style="padding-top:15px;font-weight:bolder;">
                            If you have any questions or concerns about your personal information or want to delete your account, please complete the form below.
                        </p>
                        <p style="padding-top:15px;font-weight:bolder;">
                            To understand what Personal Information SGC retains, how you consent to SGC using your Personal Information when you submit an order, how
                            you consent to receiving transactional emails from SGC about your order, please read our <a routerLink="/privacy">Privacy Policy</a> and <a routerLink="/terms">Terms and Conditions</a>.
                        </p>
                    </li>


                    <li *ngIf="isorderNumber">
                        <div class="added-card-item__info">
                            <h4 style="padding-bottom:4px;padding-top:3px; ">Order Number</h4>
                            <input type="text" class="input" formControlName="orderNumber" style="width:100%;">
                        </div>
                        <div *ngIf="form.controls['orderNumber'].touched && !form.controls['orderNumber'].valid" style="color: red;margin-top: 0px;">
                            <p *ngIf="form.controls['orderNumber'].hasError('required')" class="text-danger">
                                * Please enter Your Order Number
                            </p>
                            <p *ngIf="form.controls['orderNumber'].hasError('pattern')" class="text-danger">
                                * Your Order Number is invalid
                            </p>
                        </div>
                    </li>

                    <li>
                        <div class="added-card-item__info">
                            <h4 style="padding-bottom:4px;padding-top:3px;" *ngIf="isPaypal">Name On Your Account</h4>
                            <h4 style="padding-bottom:4px;padding-top:3px;" *ngIf="!isPaypal">Name</h4>
                            <input type="text" class="input" formControlName="name" style="width:100%;">
                        </div>
                        <div *ngIf="form.controls['name'].touched && !form.controls['name'].valid" style="color: red;margin-top: 0px;">
                            <p *ngIf="form.controls['name'].hasError('required')" class="text-danger">
                                * Please enter Your Name
                            </p>
                            <p *ngIf="form.controls['name'].hasError('maxlength')" class="text-danger">
                                * Max Length is 100
                            </p>
                            <p *ngIf="form.controls['name'].hasError('pattern')" class="text-danger">
                                * Your Name is invalid
                            </p>
                        </div>
                    </li>
                    <li>
                        <div class="added-card-item__info">
                            <h4 style="padding-bottom:4px;padding-top:3px; ">Email</h4>
                            <input type="text" class="input" formControlName="email" style="width:100%;">
                        </div>
                        <div *ngIf="form.controls['email'].touched && !form.controls['email'].valid" style="color: red;margin-top: 0px;">
                            <p *ngIf="form.controls['email'].hasError('required')" class="text-danger">
                                * Please enter Your Email
                            </p>
                            <p *ngIf="form.controls['email'].hasError('pattern')" class="text-danger">
                                * Your email is invalid
                            </p>
                        </div>
                    </li>


                    <li *ngIf="isphone">
                        <div class="added-card-item__info">
                            <h4 style="padding-bottom:4px;padding-top:3px; ">Phone</h4>
                            <input type="text" class="input" formControlName="phone" style="width:100%;">
                        </div>
                        <div *ngIf="form.controls['phone'].touched && !form.controls['phone'].valid" style="color: red;margin-top: 0px;">
                            <p *ngIf="form.controls['phone'].hasError('required')" class="text-danger">
                                * Please enter Your Phone
                            </p>
                        </div>
                    </li>
                    <li *ngIf="isphoneoptional">
                        <div class="added-card-item__info">
                            <h4 style="padding-bottom:4px;padding-top:3px; ">Alternate Phone</h4>
                            <input type="text" class="input" formControlName="phoneoptional" style="width:100%;">
                        </div>
                    </li>
                    <li *ngIf="isIssue">
                        <div class="added-card-item__info">
                            <h4 style="padding-bottom:4px;padding-top:3px; ">Issue</h4>
                            <div class="select" style="width: 55%;">
                                <select class="input" formControlName="issue">
                                    <option value="I want to opt-out of all marketing communications">I want to opt-out of all marketing communications</option>
                                    <option value="Marketing Communications Questions">Marketing Communications Questions</option>
                                    <option value="Request Copy of Personal Data and Usage">Request Copy of Personal Data and Usage</option>
                                    <option value="Questions About Deleting My Account">Questions About Deleting My Account</option>
                                    <option value="Cancel Request to Delete My Account">Cancel Request to Delete My Account</option>
                                </select>
                            </div>
                        </div>
                    </li>

                    <li *ngIf="isMessage">
                        <div class="added-card-item__info">
                            <h4 style="padding-bottom:4px;padding-top:3px; ">Message</h4>
                            <textarea type="text" class="input" formControlName="message" style="width:100%;"></textarea>
                        </div>
                        <div *ngIf="form.controls['message'].touched && !form.controls['message'].valid" style="color: red;margin-top: 0px;">
                            <p *ngIf="form.controls['message'].hasError('required')" class="text-danger">
                                * Please enter Your Message
                            </p>
                        </div>
                    </li>
                    <li *ngIf="isComments">
                        <div class="added-card-item__info">
                            <h4 style="padding-bottom:4px;padding-top:3px; ">Comments</h4>
                            <textarea type="text" class="input" formControlName="comments" style="width:100%;"></textarea>
                        </div>
                    </li>
                    <li *ngIf="isIssue">
                        <p style="padding-top:15px;font-weight:bolder;">If you do not want to use the form, you may email <a style="color:#785aff;" href="mailto:privacy@gosgc.com?subject=COLLECTOR SUPPORT">privacy&#64;gosgc.com</a></p>

                        <p style="padding-top:15px;font-weight:bolder;">
                            No matter which method you choose, form or email, you will be asked to verify your account(s) and identity.
                        </p>

                    </li>

                    <li>
                        <div *ngIf="successmsg===false">
                            <button class="button" id="Buttondefault" [disabled]="form.invalid" [class.button--disabled]="form.invalid" (click)="SubmitEmail()" style="background:#000;">
                                <div *ngIf="isSubmitting" class="loader xs invert-color"></div>
                                <span *ngIf="!isSubmitting">Submit</span>
                            </button>
                        </div>
                        <div *ngIf="successmsg===true">
                            <label style="color: #785aff;font-size: 15px;font-weight: 600;">{{emailsentmsg}}</label>
                        </div>
                    </li>
                </ul>
            </form>
            <ul>
                <li>
                    <h3>Mail Orders To</h3>
                    <p>SGC</p>
                    <p>951 W. Yamato Rd, Suite 150</p>
                    <p>Boca Raton, FL 33431</p>
                </li>
            </ul>
        </div>
        <div class="mt-4 p-4">
            <h4>
                RESTORE RECENTLY DELETED ORDERS
            </h4>
            <p>
                If your order was accidentally deleted, locate it below and click the "Restore" link. Your order should appear in the "My Orders" tab under "Drafts."
            </p>
        </div>
        <div *ngIf="!isDeletedUnDeletedOrderForCustomer" style="margin-top:11px;" class="custom-grid-styles">
            <ejs-grid #grid [dataSource]='deletedUnDeletedOrderForCustomer' gridLines="both" allowPaging="true" [allowSorting]='true'
                      [allowFiltering]="true" [pageSettings]="pageSettings" [filterSettings]='FilterOptions'
                      [allowResizing]='true' allowTextWrap='true' [textWrapSettings]='wrapSettings'>
                <e-columns>
                    <e-column field='orderNumber' headerText='Order #' type='number' width='90' textAlign="center">
                    </e-column>
                    <e-column field='totalItems' [allowFiltering]="false" headerText='# of Lines' type='string' textAlign='center' width=70></e-column>
                    <e-column field='lastModifiedOn' headerText='Deleted On' type="date" [format]="format" [filter]='filter' textAlign="center" width=90>
                    </e-column>
                    <e-column field='action' [allowFiltering]="false" [allowSorting]='false' headerText='Action' width='70' textAlign="center">
                        <ng-template #template let-deletedUnDeletedOrderForCustomer>
                            <button (click)="undeleteOrder(deletedUnDeletedOrderForCustomer.id)">Restore</button>
                        </ng-template>
                    </e-column>
                </e-columns>
            </ejs-grid>
        </div>
    </div>
</div>
