<div class="modal__content">
    <div class="columns">

        <div class="modal__list">
            <!-- Search -->
            <div>
                <input type="text" id="search" class="input" placeholder="Search Card" name="search" [formControl]="searchTerm">
                <div class="loader search-input-loader" [class.active]="isSearching" *ngIf="isSearching"></div>     
            </div>

            <div class="checkout__results">
                <div class="checkout__results-list" *ngIf="isAnyResult()">
                    <!-- <i>Choose your item. Isn't listed? <a href="#" class="link">Try our advanced search</a></i> -->
                    <card-result-item *ngFor="let item of searchResults" [item]="item" (click)="selectResultItem(item)" class="fade-in"></card-result-item>
                    <button class="button button--more" *ngIf="showLoadMore()" (click)="loadMore()">View more results</button>
                </div>
            </div>

            <!-- Added Card -->
            <div class="added-card-item" *ngIf="isCardAdded()">
                <div class="added-card-item__actions">
                    <input type="image" src="/assets/img/trash.svg" class="button-icon" (click)="removeCard()">
                </div>
                <h3>{{options.item.card.name | cardNameFormat}}</h3>
                <div class="added-card-item__info">
                    <p *ngIf="options.item.card.set"><i>Set:</i> {{options.item.card.set.name}}</p>
                    <p><i>Year:</i> {{options.item.card.year | date: 'yyyy'}}</p>
                    <p><i>Card:</i> {{options.item.card.number}}</p>
                    <p *ngIf="options.item.card.manufacturer"><i>Manufacturer:</i> {{options.item.card.manufacturer.name}}</p>
                    <p *ngIf="options.item.card.description">{{options.item.card.description}}</p>
                </div>
            </div>
        </div>

    </div>
</div>
<modal-actions [options]="options" [type]="type"></modal-actions>