<div class="content content--compact" style="padding-bottom: 30px">
  <div class="contact-us">
    <h2>PAYMENT NOT PROCESSED</h2>
    <div
      style="
        margin-left: auto;
        display: -ms-grid;
        grid-auto-flow: column;
        grid-gap: 10px;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        float: right;
        margin-top: -37px;
      "
    >
      <button
        class="button"
        style="background: #000"
        (click)="startNewSubmission()"
      >
        Start new submission
      </button>
      <button class="button" style="background: #000" (click)="mySubmission()">
        My Orders
      </button>
    </div>
    <p style="padding-top: 50px">
      Stripe was not able to process your order at this time.
    </p>
    <p style="padding-top: 10px">
      <strong>
        If you are attempting to pay by credit/debit card using your Stripe
        account and you’re denied, please login to Stripe and verify that
        payment method has been saved and verified in your Stripe wallet. You
        may also click on one of the credit card buttons and avoid using
        Stripe’s login.
      </strong>
    </p>
    <p style="padding-top: 10px">
      If you have received an email from Stripe saying your payment has been
      processed but you cannot complete your payment, Stripe has sent you this
      message by mistake. You should be receiving an email that says the charge
      was reversed between 1-3 days. To complete your order, you will need to do
      the following the 3 steps:
    </p>
  </div>

  <div style="width: 100%; display: flex; grid-gap: 40px">
    <div
      class="contact-us__list"
      style="grid-template-columns: none; width: 100%; height: max-content"
    >
      <ul>
        <li style="margin-bottom: 10px">
          <h3 style="margin-bottom: 10px">
            <span style="color: #785aff">STEP 1. </span> PRINT OUT YOUR PACKING
            SLIP
          </h3>
          <h3 style="text-transform: none; margin-bottom: 10px">
            IMPORTANT: On the packing slip, please locate your phone number and
            email. We will be using this info to reach out to coordinate
            payment. If you do not see the email or best number to reach you,
            please write it down on the packing slip. If you do not write your
            phone number and email, we will have no way to contact you.
          </h3>
          <h3 style="margin-bottom: 10px">
            <a
              class="button"
              href=""
              style="cursor: pointer; background: #000"
              target="_blank"
              >PRINT PACKING SLIP</a
            >
          </h3>
        </li>
        <li style="margin-bottom: 10px">
          <h3>
            <span style="color: #785aff">STEP 2. </span> SEND YOUR CARDS IN
          </h3>
          <h3 style="text-transform: none">
            Package your cards and packing slip as safely as possible and send
            them to
          </h3>
          <!--<h3>Mail Orders To</h3>-->
          <h4 style="color: #785aff; margin-top: 10px">SGC</h4>
          <h4 style="color: #785aff">951 W. Yamato Rd, Suite 150</h4>
          <h4 style="color: #785aff">Boca Raton, FL 33431</h4>
        </li>
        <li style="margin-bottom: 10px">
          <h3>
            <span style="color: #785aff">STEP 3. </span> WAIT FOR OUR CALL
          </h3>
          <h3 style="text-transform: none">
            When your package arrives, our team will reach out to collect
            payment and immediately send your order to processing.
          </h3>
          <h4 style="padding-top: 3%; text-transform: inherit">
            If you have any questions, send an email to
            <a
              style="
                color: #785aff;
                font-size: 15px;
                font-weight: 800;
                cursor: pointer;
              "
              href="mailto:paybyphone@gosgc.com"
              >paybyphone&#64;gosgc.com</a
            >zz
          </h4>
        </li>
      </ul>
    </div>
  </div>
</div>
