import {
  Card,
  CardGrade,
  Subject,
  CardRejectionCode,
} from '../../common/models/catalog';
import { AutographGrade } from '../../common/models/autograph';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Address, User, Customer } from '../../common/models/user';

export class Order {
  constructor(data?: any) {
    if (!data) return;
    this.id = data.id || data.orderId;
    this.orderStatus = new OrderStatus(data.status) || null;
    this.orderType = new OrderType(data.type) || null;
    this.customer = new Customer(data.customer) || null;
    this.shippingMethod = new ShippingMethod(data.shippingMethod) || null;
    this.publicId = data.publicId;
    data.settings && (this.settings.encapsulationRequested = data.settings.encapsulationRequested);
    data.settings && (this.settings.fedexAccount = data.settings.fedexAccount);
    data.settings &&
      (this.settings.useFedexAccount = data.settings.useFedexAccount);
    this.shippingAddress = new Address(data.shippingAddress) || null;
    data.fee && (this.fee.base = data.fee.base);
    data.fee && (this.fee.upgrade = data.fee.upgrade);
    data.fee && (this.fee.promo = data.fee.promo);
    data.fee && (this.fee.discountShipping = data.fee.discountShipping);
    data.fee && (this.fee.shipping = data.fee.shipping);
    data.fee && (this.fee.total = data.fee.total);
    data.turnaround && (this.turnaround.base = data.turnaround.base);
    data.turnaround && (this.turnaround.upgrade = data.turnaround.upgrade);
    data.turnaround && (this.turnaround.total = data.turnaround.total);
    this.plan = data.plan;
    this.invoiceHeader = data.invoiceHeader;

    if (data.items != null) {
      data.items.forEach(function (value: any) {
        value.item.type = data.type ? new OrderType(data.type) : null;
      });
      // data.items.reverse();
    }

    this.items = _.map(data.items, (item) => {
      switch (item.kind) {
        case 'card':
          return new CardOrderItem(item);
        case 'autograph':
          return new AutographOrderItem(item);
        default:
          return new OrderItem(item);
      }
    });
    this.totalDeclaredValue = data.totalDeclaredValue;
    this.promoCode = data.promo && data.promo.code ? data.promo.code : null;
    this.createdOn = data.createdOn;
    this.submittedOn = data.submittedOn;
    this.receivedOn = data.receivedOn;
    this.returnDate = data.returnDate;
    this.shippedOn = data.shippedOn;
    this.dueOn = data.dueOn;
    this.shippingTrackingId = data.shippingTrackingId;
    this.amountOfItems = data.amountOfItems;
    this.planExist = data.planExist;
    if (data.nonGuaraneedData) {
      this.extraNonGuaraneeData.nonUseOnlineInvoice =
        data.nonGuaraneedData.nonUseOnlineInvoice;
      this.extraNonGuaraneeData.nonUseUltraPro =
        data.nonGuaraneedData.nonUseUltraPro;
      this.extraNonGuaraneeData.customEncapsulation =
        data.nonGuaraneedData.customEncapsulation;
      this.extraNonGuaraneeData.payDiscrepancies =
        data.nonGuaraneedData.payDiscrepancies;
      this.extraNonGuaraneeData.other = data.nonGuaraneedData.other;
      this.extraNonGuaraneeData.otherReason = data.nonGuaraneedData.otherReason;
    }

    this.isNotGuaranteed = data.isNotGuaranteed;
    this.isPreviousToGuarantee = data.isPreviousToGuarantee;
    this.binLocation = data.binLocation;
    this.evaluateOrderStatus = data.evaluateOrderStatus;
    this.submittedBy = data.submittedBy;
    this.termsAccept = data.termsAccept;
    this.termsAcceptData = data.termsAcceptData;
  }

  id!: string;
  user = new User();
  orderStatus!: OrderStatus;
  orderType!: OrderType;
  customer = new Customer();
  shippingMethod!: ShippingMethod;
  publicId!: number;
  settings = {
    encapsulationRequested: true,
    Oversized: false,
    fedexAccount: 0,
    useFedexAccount: false,
  };
  promoCode!: string;
  items = new Array<OrderItem>();
  shippingAddress!: Address;
  fee = {
    base: 0,
    upgrade: 0,
    promo: 0,
    discountShipping: 0,
    shipping: 0,
    total: 0,
  };
  turnaround = {
    base: 0,
    upgrade: 0,
    total: 0,
  };
  plan!: any;
  totalDeclaredValue!: number;
  createdOn!: string;
  submittedOn!: string;
  receivedOn!: string;
  returnDate!: string;
  shippedOn!: string;
  dueOn!: string;
  shippingTrackingId!: string;
  amountOfItems!: number;
  isNotGuaranteed!: boolean;
  isPreviousToGuarantee!: boolean;
  binLocation!: number;
  evaluateOrderStatus!: string;
  extraNonGuaraneeData: any = {
    nonUseOnlineInvoice: false,
    nonUseUltraPro: false,
    customEncapsulation: false,
    payDiscrepancies: false,
    other: false,
    otherReason: null,
  };
  submittedBy!: string;
  termsAccept!: boolean;
  termsAcceptData!: string;
  planExist!: boolean;
  invoiceHeader!: string;

  getId(): string {
    return this.id;
  }

  getNumber(): number {
    return this.publicId;
  }

  getInvoiceHeader(): string {
    return this.invoiceHeader;
  }

  getOrderTypeName(): string {
    return this.orderType ? this.orderType.getName() : '';
  }

  getOrderTypeCode(): string {
    return this.orderType ? this.orderType.getCode() : '';
  }

  isCardGrading(): boolean {
    return (
      this.getOrderType().isCardGrading() ||
      this.getOrderType().isRawCardReview() ||
      this.getOrderType().isBowmanChrome() ||
      this.getOrderType().isPokemanspecials() || this.getOrderType().is23BOWMANEOY()|| this.getOrderType().is24BASKETBALL() || this.getOrderType().is24TOPPSS2()
    );
  }

  isAutographAuthentication(): boolean {
    return this.getOrderType().isAutographAuthentication();
  }

  getShippingMethodName(): string {
    return this.shippingMethod ? this.shippingMethod.getName() : '';
  }

  //getShippingMethod(): any {
  //    return this.shippingMethod ? this.shippingMethod : null;
  //}

  getTotalDeclaredValue(): number {
    return _.sumBy(this.items, (item) => item.settings.declaredValue);
  }

  getTotalServiceFee(): number {
    return this.fee.upgrade ? this.fee.upgrade : this.fee.base;
  }

  getTotalTurnaround(): number {
    return this.plan ? this.plan.turnaround : this.turnaround.total;
  }

  getTotalShippingFee(): number {
    return this.fee.shipping;
  }

  getTotalFee(): number {
    return this.fee.total - this.fee.shipping;
  }
  getshippingFee(): number {
    return this.fee.shipping;
  }

  getPromoFee(): number {
    return this.fee.promo;
  }

  updateFee(fee: any) {
    this.fee.base = fee.base;
    this.fee.upgrade = fee.upgrade;
    this.fee.promo = fee.promo;
    this.fee.discountShipping = fee.discountShipping;
    this.fee.shipping = fee.shipping;
    this.fee.total = fee.total;
  }

  getTotal(): number {
    return this.fee.total;
  }

  getItemsCount(): number {
    return this.items.length || this.amountOfItems;
  }

  getItems(): OrderItem[] {
    return this.items;
  }

  getOrderType(): OrderType {
    return this.orderType;
  }

  setOrderType(orderType: OrderType) {
    this.orderType = orderType;
  }

  addOrderItem(orderItem: OrderItem) {
    // this.items.splice(0, 0, orderItem);
    this.items.push(orderItem);
  }

  removeOrderItem(orderItem: OrderItem) {
    var index = this.items.indexOf(orderItem);
    if (index != -1) {
      this.items.splice(index, 1);
    }
  }

  updateOrderItem(orderItem: OrderItem) {
    var index = this.items.indexOf(orderItem);
    if (index != -1) {
      this.items[index] = orderItem;
    }
  }

  hasPlan(): boolean {
    return !_.isEmpty(this.plan);
  }

  hasOrderType(): boolean {
    return !_.isEmpty(this.orderType);
  }

  hasOrderItems(): boolean {
    return this.getItemsCount() > 0;
  }

  allItemsWithDeclaredValue(): boolean {
    return (
      this.hasOrderItems() &&
      _.every(this.items, (item) => item.settings.declaredValue > 0)
    );
  }

  hasShippingMethod(): boolean {
    return this.shippingMethod && this.shippingMethod.id != null;
  }

  hasShippingAddress(): boolean {
    return this.shippingAddress && this.shippingAddress.id != null;
  }

  setPlan(plan: any) {
    this.plan = plan;
  }

  setPromoCode(code: string) {
    this.promoCode = code;
  }

  setOrderStatus(status: any) {
    this.orderStatus = new OrderStatus(status);
  }

  isSubmitted(): boolean {
    return this.orderStatus && this.orderStatus.code != 'draft';
  }

  isShipped(): boolean {
    return (
      this.orderStatus &&
      (this.orderStatus.code == 'shipped' ||
        this.orderStatus.code == 'delivered')
    );
  }

  isShippingMethodPickUp() {
    return this.shippingMethod && this.shippingMethod.isPickUp();
  }
}

export class OrderType {

    constructor(orderType?: any) {
        if (!orderType) return;

        this.id = orderType.id;
        this.name = orderType.name;
        this.code = orderType.code;
        this.orderingStep1Name = orderType.orderingStep1Name;
        this.invoiceHeader = orderType.invoiceHeader;
        this.hints = _.map(orderType.hints, hint => new OrderTypeHint(hint));
    }

    id!: string;
    name!: string;
    code!: string;
    orderingStep1Name!: string;
    invoiceHeader!: string;
    hints = Array<OrderTypeHint>();

    getId(): string {
        return this.id;
    }

    getName(): string {
        return this.name;
    }

    getCode(): string {
        return this.code;
    }

    getInvoiceHeader(): string {
        return this.invoiceHeader;
    }

    getOrderingStep1Name(): string {
        return this.orderingStep1Name;
    }

    getOrderTypeHints(): Array<OrderTypeHint> {
        return this.hints;
    }

    isCardGrading(): boolean {
        return this.code == 'card-grading';
    }

    isRawCardReview(): boolean {
        return this.code == 'card-pre-grade';
    }

    isBowmanChrome(): boolean {
        return this.code == '22BOWMANCHR';
    }

    isPokemanspecials(): boolean {
        return this.code == '22POKEMON';
    }
    isGRNOTES(): boolean {
        return this.code == 'GRNOTES';
    }

    is23Bowman(): boolean {
        return this.code == '23BOWMAN';
    }

    isAutographAuthentication(): boolean {
        return this.code == 'autograph-authentication';
    }

    isFootBall(): boolean {
        return this.code == 'FOOTBALL2023';
    }

    isToppsSeries2(): boolean {
        return this.code == '23TOPPSS2';
    }

    is23NSCCTAKEBACK(): boolean {
        return this.code == '23NSCCTAKEBACK';
    }
    is23NSCCONSITE(): boolean {
        return this.code == '23NSCCONSITE';
    }
    is23TOPPSCHR(): boolean {
        return this.code == '23TOPPSCHR';
    }
    is23BOWMANEOY(): boolean {
      return this.code == '23BOWMANEOY';
  }
    is24BASKETBALL(): boolean {
      return this.code == '24BASKETBALL';
  }
    is24TOPPSS2(): boolean {
      return this.code == '24TOPPSS2';
  }
}

export class OrderTypeHint {
  constructor(hint?: any) {
    if (!hint) return;

    this.name = hint.name;
    this.description = hint.description;
  }

  name!: string;
  description!: string;
}

export class OrderStatus {
  constructor(orderStatus?: any) {
    if (!orderStatus) return;

    this.name = orderStatus.name;
    this.code = orderStatus.code;
  }

  name!: string;
  code!: string;
}

export class ShippingMethod {
  constructor(shippingMethod?: any) {
    if (!shippingMethod) return;

    this.id = shippingMethod.id;
    this.name = shippingMethod.name;
    this.fee = shippingMethod.fee;
    // this.carrier.code = shippingMethod.carrier ? shippingMethod.carrier.code : null
    // this.carrier.name = shippingMethod.carrier ? shippingMethod.carrier.name : null
    this.carrier = shippingMethod.carrier
      ? {
          code: shippingMethod.carrier.code,
          name: shippingMethod.carrier.name,
        }
      : {
          code: null,
          name: null,
        };
  }

  id!: string;
  name!: string;
  fee!: number;
  carrier?: {
    code: string | null;
    name: string | null;
  };

  getName(): string {
    return this.name;
  }

  getId(): string {
    return this.id;
  }

  // isPickUp(): boolean {
  //     return this.carrier && this.carrier.code == 'pick-up';
  // }
  isPickUp() {
    return this.carrier && this.carrier.code === 'pick-up';
  }
}

export class SignableObject {
  constructor(signableObject: any) {
    if (!signableObject) return;

    this.id = signableObject.id;
    this.name = signableObject.name;
    this.code = signableObject.code;
  }

  id!: string;
  name!: string;
  code!: string;
  type = new SignableObjectType();

  getId(): string {
    return this.id;
  }

  getName(): string {
    return this.name;
  }
}

export class SignableObjectType {
  id!: string;
  name!: string;
  canBeEncapsulated!: boolean;
}

export class ServiceTier {
  constructor(serviceTier?: any) {
    if (!serviceTier) return;

    this.id = serviceTier.id;
    this.name = serviceTier.name;
    this.fee = serviceTier.fee;
    this.thresholdValue = serviceTier.thresholdValue;
    this.turnaround = serviceTier.estimatedTurnaround;
  }

  id!: string;
  name!: string;
  fee!: number;
  thresholdValue!: number;
  turnaround!: number;
}

export class CardServiceTier extends ServiceTier {
  validForOversized!: boolean;
  validForCustomInsert!: boolean;
}

export class AutographServiceTier extends ServiceTier {
  extraFeePerSigner?: number;
  amountOfSigners?: number;
  extraServiceFee!: number;
  isPremiumCert!: boolean;
  isForSportSignature!: boolean;
}

export class TicketServiceTier extends ServiceTier {}

export class HolderServiceTier extends ServiceTier {
  validForOversized!: boolean;
}

export class OrderItem {
  constructor(orderItem?: any) {
    if (!orderItem) return;

    this.id = orderItem.id;
    this.publicId = orderItem.publicId;
    this.kind = orderItem.kind;
    this.settings.declaredValue = orderItem.settings.declaredValue;
    this.settings.encapsulationRequested =
      orderItem.settings.encapsulationRequested;
    this.settings.oversized = orderItem.settings.oversized;
    this.settings.orderitemwithoutvalue = false;
    this.serviceTier = orderItem.serviceTier
      ? new ServiceTier(orderItem.serviceTier)
      : undefined;
    this.totalFee = orderItem.totalFee;
    this.shouldEncapsulate = orderItem.shouldEncapsulate;
    this.authenticationCode = orderItem.authenticationCode;
    this.migrationState = orderItem.migrationState;
    this.variantGenerationState = orderItem.variantGenerationState;
    if (orderItem.data) {
      this.data.certificationCode = orderItem.data.certificationCode;
      this.data.crossover = orderItem.data.crossover;
      this.data.encapsulationOnly = orderItem.data.encapsulationOnly;
      this.data.minimumRequestedGrade = orderItem.data.minimumRequestedGrade;
      this.data.reholder = orderItem.data.reholder;
      this.data.relabel = orderItem.data.relabel;
      this.data.review = orderItem.data.review;
      this.data.notes = orderItem.data.notes;
      this.data.autograph = orderItem.data.autograph;
      this.data.autoGrade = orderItem.data.autoGrade;
      this.data.sheetCut = orderItem.data.sheetCut;
      this.data.pregradeEncapsulation = orderItem.data.pregradeEncapsulation;
    }
    if (orderItem.item) {
      this.line1 = orderItem.item.line1;
      this.line2 = orderItem.item.line2;
      this.line3 = orderItem.item.line3;
      this.line4 = orderItem.item.line4;
      this.orderType = orderItem.item.type;
    }
  }

  id!: string;
  publicId!: number;
  kind!: string;
  orderItemStatus?: OrderItemStatus;
  serviceTier?: ServiceTier;
  totalFee?: number;
  shouldEncapsulate!: boolean;
  oversized!: boolean;
  settings = {
    declaredValue: 0,
    stickerOnItem: null,
    autographGrading: false,
    cardGrading: false,
    encapsulationRequested: true,
    oversized: false,
    premiumCertificateRequested: false,
    orderitemwithoutvalue: false,
  };
  data: any = {
    certificationCode: null,
    crossover: false,
    encapsulationOnly: false,
    minimumRequestedGrade: null,
    reholder: false,
    relabel: false,
    review: false,
    notes: null,
    autograph: false,
    autoGrade: false,
    sheetCut: false,
    pregradeEncapsulation: false,
  };
  authenticationCode!: string;
  line1!: string;
  line2!: string;
  line3!: string;
  line4!: string;
  orderType?: OrderType | null;
  migrationState!: number;
  variantGenerationState!: number;

  getDeclaredValue(): number {
    return this.settings.declaredValue;
  }

  getTotalFee() {
    return this.totalFee;
  }

  getId(): string {
    return this.id;
  }

  setDeclaredValue(value: number) {
    this.settings.declaredValue = value;
  }

  setTotalFee(value: number) {
    this.totalFee = value;
  }

  getSettings(): any {
    return this.settings;
  }

  getShouldEncapsulate(): boolean {
    return this.shouldEncapsulate;
  }

  getAuthenticationCode(): string {
    return this.authenticationCode;
  }
}

export class OrderItemStatus {
  constructor(orderItemStatus: any) {
    if (!orderItemStatus) return;

    this.id = orderItemStatus.id;
    this.name = orderItemStatus.name;
  }

  id!: string;
  name!: string;
}

export class CardOrderItem extends OrderItem {
  constructor(cardItem?: any) {
    super(cardItem);

    if (!cardItem) {
      this.orderType = new OrderType();
      return;
    }

    this.card = cardItem.item && cardItem.item.card ? new Card(cardItem.item.card) : null;
    this.orderType = cardItem.item && cardItem.item.type ? new OrderType(cardItem.item.type) : null;
    this.grade = cardItem.item.grade ? new CardGrade(cardItem.item.grade) : null;
    this.rejectionCode = cardItem.item.rejectionCode ? new CardRejectionCode(cardItem.item.rejectionCode) : null;
    cardItem.item && (this.name = cardItem.item.name);
  }

  card!: Card | null;
  override orderType!: OrderType | null;
  grade?: CardGrade | null;
  rejectionCode?: CardRejectionCode | null;
  name!: string;

  getCardId(): string | null {
    return this.card && this.card.getId();
  }

  getGradeResult(): string {
    if (this.data.autograph == true && this.grade?.value == 'A') {
      return this.grade ? 'AU' : this.rejectionCode ? this.rejectionCode.name : '--';
    } else {
      return this.grade ? this.grade.value : this.rejectionCode ? this.rejectionCode.tag : '--';
    }
  }

  hasGradeResult(): boolean {
    return !!this.grade || !!this.rejectionCode;
  }

  getCardName(): string {
    return this.card && this.card.name ? this.card.name : this.name;
  }

  getRejectionCode(): string {
    return this.rejectionCode?.code ?? '';
  }
}

export class AutographOrderItem extends OrderItem {
  constructor(autographItem?: any) {
    super(autographItem);

    if (!autographItem) return;

    this.settings.stickerOnItem = autographItem.settings?.stickerOnItem;
    this.settings.autographGrading = autographItem.settings?.autographGrading;
    this.settings.cardGrading = autographItem.settings?.cardGrading;
    this.settings.premiumCertificateRequested =
      autographItem.settings?.premiumCertificateRequested;
    this.signableObject = autographItem.item?.signableObject
      ? new SignableObject(autographItem.item.signableObject)
      : undefined;
    this.subjects = _.map(
      autographItem.item?.subjects,
      (subject) => new Subject(subject)
    );
    this.card = autographItem.item.card
      ? new Card(autographItem.item?.card)
      : undefined;
  }

  autographGrade?: AutographGrade;
  cardGrade?: CardGrade;
  subjects!: Subject[];
  signableObject?: SignableObject;
  card?: Card;

  getSubjects(): Subject[] {
    return this.subjects;
  }

  getFirstSubject(): Subject {
    return this.subjects[0];
  }

  getSignableObjectName(): string {
    return this.signableObject?.getName() ?? '';
  }

  getSignableObjectId(): string {
    return this.signableObject?.id ?? '';
  }

  getSubjectIds(): Array<string> {
    return _.map(this.subjects, (subject) => subject.getId());
  }
}

export const CardGradingValues: Array<any> = [
  {
    value: '10 PR',
    label: '10 Pristine only',
  },
  {
    value: '10 GM',
    label: '10 Gem or 10 Pristine',
  },
  {
    value: '9.5',
    label: '9.5 MT+ or better',
  },
  {
    value: '9',
    label: '9 MT or better',
  },
  {
    value: '8.5',
    label: '8.5 NM MT+ or better',
  },
  {
    value: '8',
    label: '8 NM MT or better',
  },
  {
    value: '7.5',
    label: '7.5 NM+ or better',
  },
  {
    value: '7',
    label: '7 NM or better',
  },
  {
    value: '6.5',
    label: '6.5 EX NM+ or better',
  },
  {
    value: '6',
    label: '6 EX NM or better',
  },
  {
    value: '5.5',
    label: '5.5 EX+ or better',
  },
  {
    value: '5',
    label: '5 EX or better',
  },
  {
    value: '4.5',
    label: '4.5 VG EX+ or better',
  },
  {
    value: '4',
    label: '4 VG EX or better',
  },
  {
    value: '3.5',
    label: '3.5 VG+ or better',
  },
  {
    value: '3',
    label: '3 VG or better',
  },
  {
    value: '2.5',
    label: '2.5 Good+ or better',
  },
  {
    value: '2',
    label: '2 Good or better',
  },
  {
    value: '1.5',
    label: '1.5 Fair or better',
  },
  {
    value: '1',
    label: 'Any numeric grade',
  },
  {
    value: 'Any',
    label: 'Any grade, even "A" Authentic, if altered',
  },
];

export class AuthCodeCollections {
  constructor(authCodeCollections?: any) {
    if (!authCodeCollections) return;
    this.authCode = authCodeCollections.authCode;
  }
  authCode!: string;
}

export class AdminKPIExecutive {
  constructor(AdminKPIExecutive?: any) {
    if (!AdminKPIExecutive) return;

    this.userId = AdminKPIExecutive.userId;
    this.id = AdminKPIExecutive.id;
    this.createdOn = moment(AdminKPIExecutive.createdOn)
      .utc()
      .format('YYYY-MM-DD');
    this.allowBusinessDayGuarantee =
      AdminKPIExecutive.allowBusinessDayGuarantee;
    this.businessDaygurOpen = AdminKPIExecutive.businessDaygurOpen;
    this.businessDaygurClose = AdminKPIExecutive.businessDaygurClose;
    this.instructions = AdminKPIExecutive.instructions;
    this.allowMainPurpleBar = AdminKPIExecutive.allowMainPurpleBar;
    this.mainPurpleBarMsg = AdminKPIExecutive.mainPurpleBarMsg;
  }
  userId!: string;
  id!: string;
  createdOn!: string;
  allowBusinessDayGuarantee!: boolean;
  businessDaygurOpen!: string;
  businessDaygurClose!: string;
  instructions!: string;
  allowMainPurpleBar!: boolean;
  mainPurpleBarMsg!: string;
}

export class SubmittedOrderForCustomer {
  constructor(SubmittedOrderForCustomer?: any) {
    if (!SubmittedOrderForCustomer) return;
    this.totalCount = SubmittedOrderForCustomer.totalCount;
    this.id = SubmittedOrderForCustomer.id;
    this.orderNumber = SubmittedOrderForCustomer.orderNumber;
    this.totalItems = SubmittedOrderForCustomer.totalItems;
    this.declareVale = SubmittedOrderForCustomer.declareVale;
    this.status = SubmittedOrderForCustomer.status;
    this.submittedDate = SubmittedOrderForCustomer.submittedDate;
    this.receivedDate = SubmittedOrderForCustomer.receivedDate;
    this.gradedDate = SubmittedOrderForCustomer.gradedDate;
  }
  totalCount!: number;
  id!: string;
  orderNumber!: number;
  totalItems!: number;
  declareVale!: number;
  status!: string;
  submittedDate!: string;
  receivedDate!: string;
  gradedDate!: string;
}

export class DraftOrderForCustomer {
  constructor(DraftOrderForCustomer?: any) {
    if (!DraftOrderForCustomer) return;
    this.totalCount = DraftOrderForCustomer.totalCount;
    this.id = DraftOrderForCustomer.id;
    this.orderNumber = DraftOrderForCustomer.orderNumber;
    this.totalItems = DraftOrderForCustomer.totalItems;
    this.declareVale = DraftOrderForCustomer.declareVale;
    this.payPalStatus = DraftOrderForCustomer.payPalStatus;
    this.lastModifiedOn = DraftOrderForCustomer.lastModifiedOn;
  }
  totalCount!: number;
  id!: string;
  orderNumber!: number;
  totalItems!: number;
  declareVale!: number;
  payPalStatus!: string;
  lastModifiedOn!: string;
}

export class CompletedOrderForCustomer {
  constructor(CompletedOrderForCustomer?: any) {
    if (!CompletedOrderForCustomer) return;
    this.totalCount = CompletedOrderForCustomer.totalCount;
    this.id = CompletedOrderForCustomer.id;
    this.orderNumber = CompletedOrderForCustomer.orderNumber;
    this.totalItems = CompletedOrderForCustomer.totalItems;
    this.declareVale = CompletedOrderForCustomer.declareVale;
    this.shippedDate = CompletedOrderForCustomer.shippedDate;
    this.trackingNumber = CompletedOrderForCustomer.trackingNumber;
    this.shippingMethod = CompletedOrderForCustomer.shippingMethod;
    this.name = CompletedOrderForCustomer.name;
    this.shippingTrackingUrl = CompletedOrderForCustomer.shippingTrackingUrl;
  }
  totalCount!: number;
  id!: string;
  orderNumber!: number;
  totalItems!: number;
  declareVale!: number;
  shippedDate!: string;
  trackingNumber!: string;
  shippingMethod!: string;
  name!: string;
  shippingTrackingUrl!: string;
}

export class DeletedUnDeletedOrderForCustomer {
  constructor(DeletedUnDeletedOrderForCustomer?: any) {
    if (!DeletedUnDeletedOrderForCustomer) return;
    this.id = DeletedUnDeletedOrderForCustomer.id;
    this.orderNumber = DeletedUnDeletedOrderForCustomer.orderNumber;
    this.totalItems = DeletedUnDeletedOrderForCustomer.totalItems;
    this.lastModifiedOn = DeletedUnDeletedOrderForCustomer.lastModifiedOn;
    this.action = DeletedUnDeletedOrderForCustomer.action;
  }
  id!: string;
  orderNumber!: number;
  totalItems!: number;
  lastModifiedOn!: string;
  action!: string;
}

export class PayPalOrderForCustomer {
  constructor(payPalOrderForCustomer?: any) {
    if (!payPalOrderForCustomer) return;
    this.id = payPalOrderForCustomer.id;
    this.orderNumber = payPalOrderForCustomer.orderNumber;
    this.status = payPalOrderForCustomer.status;
    this.amount = payPalOrderForCustomer.amount;
    this.transactionDate = payPalOrderForCustomer.transactionDate;
    this.payPalStatus = payPalOrderForCustomer.payPalStatus;
    this.message = payPalOrderForCustomer.message;
  }
  id!: string;
  orderNumber!: number;
  status!: string;
  amount!: number;
  payPalStatus!: string;
  transactionDate!: string;
  message!: string;
}
export class gradeitems {
  grade!: string;
  gradevalue!: number;
}

export class MyCardOrderItems {
  certificationCode!: string;
  cardDescription!: string;
  cardNumber!: string;
  cardSet!: string;
  cardRejectionDescription!: string;
  cardGrade!: string;
  cardSubject!: string;
  formattedGrade!: string;
  orderItemGuid!: string;
  orderItemId!: string;
  sport!: string;
  orderNumber!: string;
  orderId!: string;

  gradFilter: boolean = false;
  showBackImage!: boolean;
  isChecked!: boolean;
  grade!: string;
  autoGrade!: string;
  yearFrom!: string;
  yearTo!: string;
  myCardOwnerId!: string;
  cardAutoGrade!: string;
  imgNotAvailable!: boolean;
  line3!: string;
  line4!: string;
}

export class MyCardOrderItemsGrade {
  certificationCode!: string;
  cardDescription!: string;
  cardAutoGrade!: string;
  cardNumber!: string;
  cardRejectionDescription!: string;
  cardGrade!: string;
  cardGradeNumeric!: string;
  cardSet!: string;
  cardSubject!: string;
  formattedGrade!: string;
  orderItemGuid!: string;
  orderItemId!: string;
  sport!: string;
  orderNumber!: string;
  orderId!: string;
  myCardOwnerId!: string;
  gradFilter: boolean = false;
  showBackImage!: boolean;
  isChecked!: boolean;
  grade!: string;
  autoGrade!: string;
  yearFrom!: string;
  yearTo!: string;
  imgNotAvailable!: boolean;
  line3!: string;
  line4!: string;
}
export class MyCardOrderItemsGradeFilterPipe {
  authCode!: string;
  cardDescription!: string;
  cardNumber!: string;
  cardSet!: string;
  cardSubject!: string;
  formattedGrade!: string;
  orderItemGuid!: string;
  sport!: string;
  orderNumber!: string;
  orderId!: string;

  gradFilter: boolean = false;
  showBackImage!: boolean;
  isChecked!: boolean;
  grade!: string;
  autoGrade!: string;
}

export class DownlaodCardOrderItems {
  itemGuid: any;
  orderNumber!: string;
}

export class CheckDownlaodCardOrderItems {
  createdOn!: string;
  orderId!: string;
  orderItemId!: string;
  requestId!: string;
  userId!: string;
  zipGeneratedOn!: string;
  zipRequestState!: number;
  zipRequestStateStatus!: string;
  zipUrl!: string;
  downloadRequestStatus!: number;
  orderNumber!: string;
  certCode!: string;
  totalCards!: string;
}
