<button
  class="button button--secondary"
  (click)="moveBackward()"
  *ngIf="!isStep8()"
  [hidden]="isCheckoutFinished()"
>
  {{ getBackwardLabel() }}
</button>
<div class="footer footer-row" style="gap: 50px">
  <div
    class="footer footer-row-1"
    style="display: flex; flex-direction: row; gap: 50px"
  >
    <div class="checkout__table" *ngIf="!isStep178() && !isStep8()">
      <h4 class="footer heading-h4">
        CARD GRADING
        <span class="double_dot" *ngIf="getTurnaround()"> :</span>
      </h4>
      <h4 class="p-h4" style="text-transform: capitalize">
        {{ getTurnaround() }}
      </h4>
    </div>
    <!-- <div *ngIf="!isStep178()">
            <h4 class="footer heading-h4">{{ getTurnaround() }}</h4>
        </div> -->
    <div class="checkout__table" *ngIf="!isStep178() && !isStep8()">
      <h4 class="footer heading-h4">
        # CARDS
        <span class="double_dot" *ngIf="getItemsCount()"> :</span>
      </h4>
      <h4 class="p-h4">
        {{ getItemsCount() }}
      </h4>
    </div>
    <div class="checkout__table" *ngIf="!isStep178() && !isStep8()">
      <h4 class="footer heading-h4" title="Delared Value">
        Total DV <span class="double_dot"> :</span>
      </h4>
      <h4 class="p-h4">
        {{ getTotalDeclaredValue() | currency : "USD" : "symbol" : "1.0-0" }}
      </h4>
    </div>
  </div>
  <div
    class="footer footer-row-1"
    style="display: flex; flex-direction: row; gap: 50px"
  >
    <div class="checkout__table" *ngIf="!isStep12378() && !isStep8() || isStep3() || isStep2()">
      <h4 class="footer heading-h4">
        GRADING FEE <span class="double_dot"> :</span>
      </h4>
      <h4 class="p-h4">
        {{ getTotalFee() | currency : "USD" : "symbol" : "1.2-2" }}
      </h4>
    </div>
    <div class="checkout__table" *ngIf="!isStep12378() && !isStep8()">
      <h4 class="footer heading-h4">
        Shipping <span class="double_dot"> :</span>
      </h4>
      <h4 class="p-h4">
        {{ getShippingFee() | currency : "USD" : "symbol" : "1.2-2" }}
      </h4>
    </div>
    <div
      class="checkout__table"
      *ngIf="showPromoCode() && !isStep12378() && getPromoFee() && !isStep8()"
    >
      <h4 class="footer heading-h4">
        Promo Disc<span class="double_dot"> :</span
        ><span class="promo-mobile">ount</span>
      </h4>
      <h4 class="p-h4">
        {{
          getPromoFee()
            | currency : "USD" : "symbol" : "1.2-2"
            | dashForNoValueFormat
        }}
      </h4>
    </div>
    <div class="checkout__table" *ngIf="!isStep12378() && !isStep8()">
      <h4 class="footer heading-h4">
        Total <span class="double_dot"> :</span>
      </h4>
      <h4 class="p-h4">
        {{
          getTotal()
            | currency : "USD" : "symbol" : "1.2-2"
            | dashForNoValueFormat
        }}
      </h4>
    </div>
  </div>
</div>
<div *ngIf="isStep2()" class="checkout__fixissue">
  <!-- <span  (click)="checkError()" *ngIf="fixissue() == true"
        >Fix {{ getErrorCount() }} Issue</span
    >
    <span (click)="checkError()" *ngIf="fixissue() == false"
        >Fix {{ getErrorCount() }} Issues</span
    > -->
  <ng-container *ngIf="getErrorCount() && showFixIssue() == true; else noIssue">
    <span style="color: blue" (click)="checkError()" *ngIf="fixissue() == true"
      >Fix {{ getErrorCount() }} Issue</span
    >
    <span style="color: blue" (click)="checkError()" *ngIf="fixissue() == false"
      >Fix {{ getErrorCount() }} Issues</span
    >
  </ng-container>
  <ng-template #noIssue>
    <!-- Rendered when getErrorCount() returns 0 -->
    <span
      style="color: blue"
      (click)="checkError()"
      *ngIf="fixissue() == false"
    ></span>
  </ng-template>
</div>
<div>
  <!-- <button class="button" (click)="moveForward()" [disabled]="!isStepCompleted()" [class.button--disabled]="!isStepCompleted()" *ngIf="canMoveForward()">Cancel</button>  -->
  <div *ngIf="newmessage()" class="footer-row">
    <div
      class="checkout__warning"
      *ngIf="newItemsStepStateMessage != '' && !isStepCompleted()"
    >
      <!-- <p (click)="checkError()" class="checkout__warning">
                {{ newItemsStepStateMessage
                }}<img src="/assets/img/warning.svg" class="warning__image" />
            </p> -->
    </div>
  </div>
  <div
    *ngIf="newItemsStepStateMessage === '' && !isStep1()"
    style="display: contents"
  >
    <div *ngIf="getOrderTypeCodes() === ''" style="display: contents">
      <div class="checkout__warning" *ngIf="!isStepCompleted()">
        {{ getOrderTypeName() | dashForNoValueFormat
        }}<img
          *ngIf="getStepMessage() != null"
          src="/assets/img/warning.svg"
          class="warning__image"
        />
      </div>
      <!-- <h4 *ngIf="getOrderTypeCode() == '22BOWMANCHR'">TOPPS X SGC 2022 Bowman Chrome Special</h4>
            <h4 *ngIf="getOrderTypeCode() == '22POKEMON'">2023 POKÉMON SPECIAL</h4> -->
    </div>
    <div style="display: flex">
      <div
        class="checkout__warning"
        style="margin-top: 10px"
        *ngIf="!isStepCompleted()"
        (click)="checkError()"
      >
        {{ getStepMessage()
        }}<img
          (click)="checkError()"
          *ngIf="getStepMessage() != null"
          src="/assets/img/warning.svg"
          class="warning__image"
        />
      </div>
      <div *ngIf="newItemsStepStateMessage === ''" class="footer-row">
        <button
          class="button"
          (click)="moveForward()"
          [disabled]="!isStepCompleted() && noissues()"
          [class.button--disabled]="!isStepCompleted() || !zeroissues()"
          *ngIf="canMoveForward() && !ischeckout() && !isStep2()"
        >
          Next
        </button>
        <!-- <button class="button" (click)="moveForward()" *ngIf="isfinishPay()" [class.button--disabled]="!accept()"> Finish & Pay</button> -->
        <div
          class="checkout__sidebar-item"
          [hidden]="!canSubmitWithoutPaying()"
          *ngIf="!isRefreshing"
        >
          <button class="button" (click)="submitOrder()" *ngIf="isfinishPay()">
            Finish & Pay
          </button>
        </div>
        <div class="checkout__sidebar-item" [hidden]="canSubmitWithoutPaying()">
          <button class="button" (click)="finishPay()" *ngIf="isfinishPay()">
            Finish & Pay
          </button>
        </div>
      </div>
    </div>
  </div>
  <div>
    <button class="button" (click)="moveForward2()" *ngIf="isStep2()">
      Next
    </button>
  </div>
  <!-- <div
        *ngIf="isStep2() && !isStepCompleted() && resultitem()"
        class="checkout__fixissue"
    >
        <span style="color: rgb(226, 12, 12)" (click)="checkError()"
            >Fix {{ getErrorCount() }} issues</span
        >
    </div> -->
  <div class="bottom-nav--finished" *ngIf="!isStep1234567()">
    <button class="button" (click)="startSubmission()">
      Start New Submission
    </button>
    <button class="button" (click)="navigateToHome()">Return to Home</button>
  </div>
</div>
<div
  *ngIf="
    newItemsStepStateMessage === '' && getOrderTypeCode() == 'card-pre-grade'
  "
  style="display: contents"
>
  <div class="checkout__warning" *ngIf="!isStepCompleted()">
    {{ getStepMessage()
    }}<img src="/assets/img/warning.svg" class="warning__image" />
  </div>
</div>
<style type="text/css">
    .mx-1 {
        margin-left: 1rem !important;
    }
  .checkout__fixissue {
    font-size: 1.7rem;
    font-weight: bold;
    cursor: pointer;
    color: blue !important;
    text-decoration: underline;
  }
  .double_dot {
    display: none;
  }
  /* .checkout__fixissue span:hover {
        cursor: pointer;
        color: blue !important;
        text-decoration: underline;
    } */
  @media only screen and (min-width: 426px) {
    .footer {
      /* display: none; */
      flex-direction: column;
    }
  }
  @media only screen and (min-width: 768px) {
    .footer-row {
      display: flex;
      flex-direction: row;
      justify-content: start;
    }
  }
  @media only screen and (max-width: 550px) {
    .footer-row {
      display: flex;
      flex-direction: row;
      margin-left: 30px;
    }
    .footer-row-1 {
      display: flex !important;
      flex-direction: column !important;
      gap: 0px !important;
      font-size: 1px !important;
      margin-block: -16px;
      margin-inline: -23px;
      margin-left: -16px;
      justify-content: center;
    }
    .footer-row-2 {
      display: flex !important;
      flex-direction: column !important;
      gap: 4px !important;
      font-size: 1px !important;
      margin-block: -20px;
      margin-inline: -40px;
    }
    .heading-h4 {
      font-size: 8px;
      margin-left: 5px;
      display: contents;
    }
    .heading-h5 {
      font-size: 7px;
      margin-top: 4px;
    }
    .p-h4 {
      font-size: 8px;
      margin-left: 5px;
    }
    .p-h5 {
      font-size: 7px;
      margin-left: 2px;
      margin-top: -3px;
      margin-bottom: -7px;
    }
    .grading-p-h4 {
      font-size: 7px;
      margin-top: -4px;
      margin-bottom: -13px;
    }
    .checkout__warning {
      display: none !important;
      font-size: 5px;
      margin-left: 5px;
      width: 60px !important;
      margin-left: -35px;
      margin-right: -30px;
      column-gap: 2px !important;
      line-height: 0.5rem !important;
    }
    .warning__image {
      display: none;
    }
    .checkout__fixissue {
      font-size: 10px;
      /* margin-left: -15px !important; */
      text-decoration: underline;
    }
    .checkout__table {
      width: 85px !important;
      display: flex !important;
      flex-direction: row !important;
    }
    .button {
      padding: 5px 5px 5px;
      font-size: 12px;
    }
    .promo-mobile {
      display: none;
    }
    .double_dot {
      display: block;
    }
  }
  @media only screen and (max-height: 420px) {
    .footer-row {
      display: flex;
      flex-direction: row;
      margin-left: 30px;
    }
    .footer-row-1 {
      display: flex !important;
      flex-direction: column !important;
      gap: 0px !important;
      font-size: 1px !important;
      margin-block: -16px;
      margin-inline: -23px;
      margin-left: -16px;
      justify-content: center;
    }
    .footer-row-2 {
      display: flex !important;
      flex-direction: column !important;
      gap: 4px !important;
      font-size: 1px !important;
      margin-block: -20px;
      margin-inline: -40px;
    }
    .heading-h4 {
      font-size: 8px;
      margin-left: 5px;
      display: contents;
    }
    .heading-h5 {
      font-size: 7px;
      margin-top: 4px;
    }
    .p-h4 {
      font-size: 8px;
      margin-left: 5px;
    }
    .p-h5 {
      font-size: 7px;
      margin-left: 2px;
      margin-top: -3px;
      margin-bottom: -7px;
    }
    .grading-p-h4 {
      font-size: 7px;
      margin-top: -4px;
      margin-bottom: -13px;
    }
    .checkout__warning {
      display: none !important;
      font-size: 5px;
      margin-left: 5px;
      width: 60px !important;
      margin-left: -35px;
      margin-right: -30px;
      column-gap: 2px !important;
      line-height: 0.5rem !important;
    }
    .warning__image {
      display: none;
    }
    .checkout__fixissue {
      font-size: 10px;
      /* margin-left: -15px !important; */
      text-decoration: underline;
    }
    .checkout__table {
      width: 85px !important;
      display: flex !important;
      flex-direction: row !important;
    }
    .button {
      padding: 5px 5px 5px;
      font-size: 12px;
    }
    .promo-mobile {
      display: none;
    }
    .double_dot {
      display: block;
    }
  }
</style>
