import { Component, Input } from '@angular/core';
import { ModalService, ModalInterface } from '../../services/modal.service';
import { CheckoutService } from '../../../checkout/services/checkout.service';
import { UserService } from "../../../common/services/user.service";
import { Address } from "../../../common/models/user";

@Component({
  selector: 'app-delete-address',
  templateUrl: './delete-address.component.html',
  styleUrls: ['./delete-address.component.css']
})
export class DeleteAddressComponent implements ModalInterface {
    constructor(
        private modalService: ModalService,
        private checkoutService: CheckoutService,
        private userService: UserService
    ) { }

    type = "app-delete-address";
    loader!: boolean;
    options: any;
    address = new Address();
    
    close() {
        this.modalService.close(this.type, 'close');
    }

    deleteAddress() {
        this.checkoutService.removeAddress$.subscribe(addr => this.address = addr);
        //this.checkoutService.deleteAdress(true);
        this.userService.removeAddress(this.address);
        this.close();
    }

    getMessage(): string {
        var defaultMessage = ``

        return this.options && this.options.message ? this.options.message : defaultMessage;
    }
    getMessage1(): string {
        var defaultMessage = ` If you are picking up your order at SGC or a show, we require a shipping address in the event you are unable to pick up your order. `

        return this.options && this.options.message1 ? this.options.message1 : defaultMessage;
    }
    getMessage2(): string {
        var defaultMessage = `If your shipping address is not displayed, or you would like to add another address, click the '+' to add an address. After saving the address, click it to view your shipping options`

        return this.options && this.options.message2 ? this.options.message2 : defaultMessage;
    }

    getActionLabel(): string {
        return this.options && this.options.actionLabel;
    }

    hasAction(): boolean {
        return this.options && this.options.actionLabel;
    }

    takeAction() {
        this.options.actionCallback();
    }
}
