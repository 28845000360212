import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';

import {
  ModalService,
  ModalInterface,
} from '../../../../../common/services/modal.service';
// import { OrderItemConfigurationService } from '../../../../services/order-item-configuration.service';
import { SearchService } from '../../../../../common/services/search.service';
import { debounceTime } from 'rxjs/operators';
import { OrderItemConfigurationService } from 'src/app/checkout/services/order-item-configuration.services';
@Component({
  //moduleId: module.id,
  selector: 'add-signer',
  templateUrl: './add-signer.component.html',
  styleUrls: ['../../../../checkout.component.sass'],
})
export class AddSignerComponent implements ModalInterface {
  constructor(
    private modalService: ModalService,
    private orderItemConfigurationService: OrderItemConfigurationService,
    private searchService: SearchService
  ) {
    this.searchTerm.valueChanges.pipe(debounceTime(200)).subscribe((data) => {
      this.search(data);
    });
  }

  searchResults = new Array<any>();
  searchTerm: FormControl = new FormControl();

  title = 'Add Signers';
  subtitle =
    'If your item contains more signers than the selected one, you can add them here.';
  type = 'add-signer';
  options: any;

  searchOptions = {
    limit: 10,
    offset: 0,
    count: 0,
    // filter: { query: null, kind: null}
    filter: { query: null, kind: null as string | null },
  };

  search(data: any) {
    if (data) {
      this.searchOptions.filter.query = data;
      this.searchOptions.filter.kind = 'subject';
      this.searchService
        .searchItemByKeyword(this.searchOptions)
        .subscribe((response: any) => {
          this.updateSearchOptions(response);
          this.searchResults = response.items;
        });
    } else {
      this.searchResults = [];
    }
  }

  updateSearchOptions(response: any) {
    this.searchOptions.count = response.count;
    this.searchOptions.offset = response.offset;
    this.searchOptions.filter.query = response.filter.query;
    this.searchOptions.filter.kind = response.filter.kind;
  }

  showLoadMore(): boolean {
    return (
      this.searchOptions.count >
      this.searchOptions.limit + this.searchOptions.offset
    );
  }

  loadMore() {
    var options = {
      limit: this.searchOptions.limit,
      offset: this.searchOptions.limit + this.searchOptions.offset,
      filter: this.searchOptions.filter,
    };

    this.searchService
      .searchItemByKeyword(options)
      .subscribe((response: any) => {
        this.updateSearchOptions(response);
        this.searchResults.push.apply(response.items);
      });
  }

  isAnyResult(): boolean {
    return this.searchResults != null && this.searchResults.length > 0;
  }

  select(item: any) {
    this.options.item.signers.push(item);
  }

  remove(item: any) {
    var index = this.options.item.signers.indexOf(item);
    if (index != -1) {
      this.options.item.signers.splice(index, 1);
    }
  }
}
