<style>
    p, ul, ol, i {
        font-size: 1.8rem;
        line-height: 2.25rem;
    }
    strong {
        font-weight: 400;
    }
</style>
<!--<div class="section-title section-title--culture">
    <h2>Terms & Conditions</h2>
</div>-->

<div class="content content--terms" style="background: black; color: #fff; margin-top: 0px; margin-bottom: 0px;">

    <p style="margin-top:60px;"><strong>LAST UPDATED: 6/28/2022</strong></p>

    <i>CUSTOMER ACKNOWLEDGES THAT THEY HAVE READ THE NEW SGC, LLC (“SGC”) GRADING TERMS AND CONDITIONS (THE “AGREEMENT”) SET FORTH BELOW AND AGREES TO ABIDE BY THIS AGREEMENT, AND FURTHER AGREES THAT SGC IS ENTITLED TO RELY UPON AND BENEFIT FROM THIS AGREEMENT.</i>

    <p><strong>1.</strong> The “Customer” is defined as a person, persons, or corporate entity with the legal authority to submit items to SGC for grading and/or authentication services. The individual who submits the order to SGC will be treated as SGC’s sole point of contact for that transaction. A customer may submit one or more of the following items to SGC for grading and/or authentication: any trading card, uniform, or other memorabilia (“item”) to SGC either via the GoSGC.com website or in-person to a verified SGC officer or employee. By using this website or SGC’s services, you represent and warrant that you are at least eighteen (18) years of age, or if you are under eighteen (18) years of age but at least thirteen (13) years of age (a “Minor”), that you are using the website with the consent of your parent or legal guardian and that you have received your parent or legal guardian’s permission to use the website and agree to its Terms and Conditions. If you are a parent or legal guardian of a Minor, you hereby agree to bind the Minor to these Terms and Conditions and to fully indemnify and hold harmless SGC if the Minor breaches any of these Terms. If you are not at least thirteen (13) years of age, you may not use this website at any time or in any manner or submit any information to SGC or the website. SGC will endeavor to grade and/or authenticate submitted items within the estimated turnaround timeframe, if any, offered as part of a SGC grading and/or authentication service. SGC accepts no liability for orders completed later than the estimated turnaround time frame.</p>

    <p><strong>2.</strong> In no event shall SGC have liability to Customer for incidental, indirect, or consequential damages due to SGC’s failure to grade and/or authenticate any items within any timeframe, and Customer waives all rights to seek incidental, indirect, or consequential damages against SGC. All turnaround time frames are estimates and refer to business days, which shall not include holidays or days that the SGC office is closed.</p>

    <p><strong>3.</strong> Customer acknowledges and agrees that SGC’s owners and employees shall be permitted to submit items for grading and/or authentication without limitation. Furthermore, SGC’s owners and employees may buy, sell, and trade SGC authenticated or graded items without limitation.</p>

    <p><strong>4.</strong> All fees and amounts charged by SGC are subject to change in SGC’s sole and absolute discretion. Unless otherwise provided in writing, all services or products provided by SGC shall be billed at the prices prevailing at the time the services or products are provided to Customer.</p>

    <p><strong>5.</strong> SGC reserves the right to increase the base service fees for any item submitted at a low estimated value.</p>

    <p><strong>6.</strong> SGC reserves the right to issue upcharges on any item for any reason, including, but not limited to, items requiring custom modifications to the black insert component in SGC’s holder, commonly referred to as “custom-cuts,” those items requiring any amount of research, oversized items (cards measuring larger than 3 1⁄2” x 5 1⁄2”, or any other non-standard items.</p>

    <p><strong>7.</strong> Customer agrees not to knowingly submit cards to SGC that bear evidence of trimming, recoloring, restoration or any other form of tampering, or are of questionable authenticity, as determined in the sole judgment of SGC (“Altered Cards”). SGC shall not grade Altered Cards, but shall have no obligation to refund the fee paid by Customer, as the determination to reject an Altered Card requires a review by SGC’s graders and authenticators. Customer represents and warrants that they have no knowledge and no reasonable basis to believe that any card submitted for grading has been altered in any way or is not genuine.</p>

    <p><strong>8.</strong> Customer agrees not to knowingly submit any forged, counterfeit, or otherwise non-genuine items to SGC for any services including, but not limited to, card grading and uniform authentication.</p>

    <p><strong>9.</strong> Customer acknowledges and agrees the grading and/or authentication of items requires the exercise of individual judgment and professional opinion, which is subjective in nature, and can change from time to time. Therefore, SGC makes no warranty or representation and shall have no liability whatsoever to Customer for the grade or determination of authenticity assigned by SGC to any item.</p>

    <p><strong>10.</strong> After the submission and grading and/or authentication of an item by SGC, new information may arise or become available that was unavailable at the time a grade or determination of authenticity was assigned by SGC to any item. SGC shall have no liability to Customer, and SGC shall be under no obligation to change a grade or determination of authenticity assigned to an item (unless such an item is re-submitted for grading and/or authentication) to the extent new information arises or becomes available after a grade or determination of authenticity is assigned by SGC to any item.</p>

    <p><strong>11.</strong> Clerical errors can occur in connection with the grading and/or authentication, review, or reholdering of an item submitted to SGC. Customer’s sole and exclusive remedy is to allow SGC to correct the clerical error, and reholder the item and/or produce a new letter of authenticity if required, at SGC’s sole cost and expense.</p>

    <p><strong>12.</strong> SGC shall have the right (not obligation) to capture the image of any item submitted by Customer. Customer agrees SGC shall be permitted to publish images and information relating to any items submitted by Customer without compensation of any kind to Customer, and SGC further reserves the right to publish images and information relating to items as it deems fit. All images created/produced or modified by SGC is the sole property of SGC, and may only be used by SGC in its sole and absolute discretion.</p>

    <p><strong>13.</strong> SGC will exercise reasonable care in handling items submitted for grading and/or authentication, review, or reholdering. However, if Customer’s item was lost or damaged while in SGC’s possession, Customer will be compensated based upon the fair market value of the item as determined by SGC standard procedures which may include filing a claim with our insurance carrier. The declared value you stated on the front of this form is for estimating the insurance coverage only, and the fair market value of the item may be less than your declared value. IN NO EVENT SHALL THE TOTAL LIABILITY OF SGC, ITS AFFILIATES OR ANY OF SGC’S DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, PARTNERS, SUBSIDIARIES, DIVISIONS, SUCCESSORS, SUPPLIERS, DISTRIBUTORS, AFFILIATES, VENDORS, CONTRACTORS, REPRESENTATIVES OR CONTENT OR SERVICE PROVIDERS EXCEED THE GREATER OF: (I) THE FEES PAID TO SGC FOR GRADING ANDOR AUTHENTICATION OF AN ITEM, OR (II) THE NET PROCEEDS OF INSURANCE (LESS ANY DEDUCTIBLE PAID BY SGC). Such compensation shall be Customer’s exclusive remedy for any loss or damage.</p>

    <p><strong>14.</strong> Customer is required to inspect all items immediately upon receipt and SGC disclaims any liability for discrepancies or errors, including, but not limited to, errors in the description of the item unless reported to SGC within five (5) business days of Customer’s receipt of the item(s). Customer agrees to return any incorrectly described item to SGC upon request for correction and agrees to indemnify and hold SGC harmless from any and all losses and/or claims caused by the circulation or sale of incorrectly described items.</p>

    <p><strong>15.</strong> Customer agrees as follows: (a) SGC shall release a graded and/or authenticated item to Customer only upon payment of all fees and other charges; (b) to pay to SGC all fees and other charges when due without further demand; (c) any amounts not paid when due shall accrue interest at the rate of 18% per year until paid (or, if less, the maximum interest rate permitted by applicable law); and (d) SGC shall have a security interest in the items submitted, as well as in any other property of Customer in the possession of SGC or its affiliates (collectively, the “Client Property”), to secure payment thereof. Customer hereby grants to SGC an assignment of and lien against the Client Property in the amount of any fees and other charges due and payable pursuant to the terms of this Agreement. Customer hereby authorizes SGC to file, at any time on or after the date such fees and other charges become due, appropriate uniform commercial code financing statements in such jurisdictions and offices as SGC deems necessary in connection with the perfection of a security interest in the Client Property, and Customer shall be responsible for all fees and out-of-pocket costs.</p>

    <p><strong>16.</strong> SGC shall have no liability whatsoever to Customer, or any third party for whom Customer may be acting, related to any damage to any item resulting from the breaking open of an SGC holder or third-party holder, or for any damage to any item hat occurred while the card was not in the custody or control of SGC including, but not limited to, loss or damage to items while being shipped to SGC, or while being shipped by SGC to Customer.</p>

    <p><strong>17.</strong> Customer agrees that it will notify SGC if the Customer knowingly submits an item encapsulated within a case, holder or other protective outer layer that is broken, tampered with, or otherwise defective in any manner. If SGC receives from Customer a case, holder or other protective outer layer that is broken, tampered with, or defective in any matter, regardless of whether or not the Customer knew the case/holder was broken, then SGC shall have no liability whatsoever to Customer, or any third party for whom Customer may be acting, for any further or additional damage that may occur to the case/holder or the item contained therein if SGC opens or otherwise removes the broken or defective case/holder.</p>

    <p><strong>18.</strong> In the event SGC, in SGC’s sole and absolute opinion, determines that an item within an SGC holder is fraudulent, tampered with, or is not accurately described, then SGC is authorized, without further notice to Customer, to remove the item from the holder. In addition, if an item has been previously graded by SGC and placed in a holder, and such item is subsequently damaged due to Customer’s lack of care, then SGC reserves the right to remove the item from its holder, and is under no obligation to re-grade such an item.</p>

    <p><strong>19.</strong> Customer acknowledges and agrees to exercise reasonable care with regard to any item graded and/or authenticated by SGC, and further acknowledges and agrees items in an SGC holder can be damaged and/or destroyed if reasonable care is not exercised (i.e. holders are not waterproof or UV-resistant).</p>

    <p><strong>20.</strong> Except as expressly set forth herein to the contrary, SGC DISCLAIMS ANY AND ALL WARRANTIES, EXPRESSED OR IMPLIED, REGARDING SGC’S GOODS AND SERVICES, INCLUDING, BUT NOT LIMITED TO, THE WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE.</p>

    <p><strong>21.</strong> Except as expressly set forth herein to the contrary, THE MAXIMUM AGGREGATE LIABILITY THAT SGC SHALL HAVE TO CUSTOMER, OR ANY THIRD PARTY FOR WHOM THE CUSTOMER MAY BE ACTING, ARISING FROM ANY CAUSE, ACT, OMISSION OR OTHER CIRCUMSTANCE, including the negligence of SGC, SHALL IN NO EVENT EXCEED THE GREATER OF: (I) THE FEES PAID TO SGC FOR GRADING AND/OR AUTHENTICATION OF AN ITEM, OR (II) THE NET PROCEEDS OF INSURANCE (LESS ANY DEDUCTIBLE PAID BY SGC.</p>

    <p><strong>22.</strong> SGC OR ANY OF ITS AFFILIATES, OR ANY OF ITS OR THEIR RESPECTIVE EMPLOYEES, OFFICERS, DIRECTORS OR AGENTS SHALL NOT BE LIABLE FOR ANY INCIDENTAL, SPECIAL, EXEMPLARY, CONSEQUENTIAL OR OTHER DAMAGES, NOR SHALL SGC OR ANY OF ITS AFFILIATES, OR ANY OF ITS OR THEIR RESPECTIVE EMPLOYEES, OFFICERS, DIRECTORS OR AGENTS BE LIABLE FOR ANY DAMAGES WHICH ARE BASED UPON ALLEGED NEGLIGENCE, BREACH OF WARRANTY, STRICT LIABILITY, OR ANY THEORY OTHER THAN THE LIMITED LIABILITY STATED HEREIN, EVEN IF ADVISED OF THE POSSIBILITY OF SAME.</p>

    <p><strong>23.</strong> In the ordinary course of its grading operations, SGC (i) compiles data regarding each item submitted for grading and/or authentication, including, but not limited to, data relating to the identity, production, condition, authenticity, and grade of the item (the “Data”); and (ii) may take, or have taken, one or more digital or other types of photographs, images or reproductions of each such item (collectively, the “Images”). In consideration for the grading and/or authentication services being provided by SGC, Customer, on behalf of itself and any third party for whom Customer may be acting, hereby authorizes SGC (i) to compile and maintain such Data with respect to each item submitted hereunder for grading and/or authentication; and (ii) to take, or cause to be taken, one or more Images of each such item, and further agrees that SGC will be the owner of such Data and all such Images and that SGC may use and exploit such Data and the Images for commercial and any other purposes, as SGC in its sole discretion deems appropriate, including, but not limited to, the publication and republication or reproduction in or on any media, of such Data and Images. Without limiting the generality of the foregoing, Customer, on behalf of itself and any third party for whom Customer may be acting with respect to this agreement, unconditionally and irrevocably transfers, conveys and assigns to SGC any and all current and any hereafter acquired rights, title and interests (including, without limitation, rights in copyright, patent, trade secret and trademark) that Customer or any such third party may have in or to the Data and the Images (on whatever media or in whatever form such Images may be reproduced or published).</p>

    <p><strong>24.</strong> If any items are being submitted on behalf of a third party, Customer represents and warrants that such third party has agreed and accepted this Agreement and has signed a duplicate copy hereof where indicated. Customer agrees to provide that third party signed copy to SGC at any time upon its request. SGC shall not be required to complete any work until Customer provides a copy of the executed third party Agreement.</p>

    <p><strong>25.</strong> Customer agrees to indemnify, defend and hold SGC and (as applicable) its parent, subsidiaries, affiliates, officers, directors, agents, and employees, harmless from any loss, claim or demand, including reasonable attorneys’ fees, made by any third party due to or arising out of or related to the submission of an item to SGC, related to the grade and/or determination of authenticity assigned by SGC, any services or products provided by SGC to Customer, Customer’s breach of this Agreement or the documents it incorporates by reference, or customer’s violation of any law or the rights of a third party. This indemnification will survive termination.</p>

    <p><strong>26.</strong> This Agreement is governed by and construed in accordance with the substantive laws of the State of Florida, without regard to conflicts of laws principles. The parties hereby consent to personal jurisdiction of the courts of the State of Florida with respect to any legal action to enforce the terms and conditions of this Agreement or otherwise arising under or with respect to this Agreement, and agree that venue for all such actions shall be in Broward County, Florida. The non-prevailing party shall pay all costs and expenses, including all attorneys’ fees and court costs, incurred by the prevailing party in enforcing the terms and conditions of this Agreement.</p>

    <p><strong>27.</strong> The terms and provisions in this Agreement constitutes the entire agreement of SGC and Customer (and any third party for whom Customer may be acting) regarding, and supersedes all prior agreements and understandings (written or oral) between or among such parties relating to the subject matter hereof. If any term or provision of this Agreement is determined by a final and unappealable ruling or order of a court of competent jurisdiction, to be invalid or unenforceable under applicable law, such invalidity or unenforceability shall not affect the validity or enforceability of any of the other of the terms or provisions of this agreement. Each party shall execute and deliver such additional documents and instruments as any other party may request to better evidence or effectuate the agreements contained herein.</p>

    <p><strong>28.</strong> SGC reserves the right to not perform services requested by the customer for any reason, including items being too fragile to handle or encapsulate, items being of a type that SGC does not wish to grade and/or authenticate, or any other reason that may come up. If customer wishes to have a fragile item encapsulated and SGC agrees to perform the services requested, customer must agree to sign a waiver of liability prior to SGC starting the agreed to service.</p>

    <p><strong>29.</strong> The customer consents to receiving transactional communications (“email”), including, but not limited to, a confirmation email confirming account creation and email verification, a password reset email when requested by the customer, a confirmation email that the customer’s order has been received, a notification email that the customer’s order has shipped.</p>

    <p><strong>30.</strong> The customer consents to SGC sharing their personal information with third-party vendors for the purpose of fulfilling their orders, including, but not limited to, payment processors, and shipping carriers to print return labels.  Our website may contain links to third-party websites, services, content, apps, and advertisements for third parties (“Third-Party Services”).  We are not responsible for third parties or their Third-Party Services. Use caution when dealing with third parties and consult their terms of use and privacy policies.</p>

    <p style="margin-bottom:60px"><strong>31.</strong>  All information we collect is subject to the SGC Global Customer Privacy Statement (“Privacy Policy”), which describes how we collect, use, and share information. By using our website, or otherwise using our services in any manner, you acknowledge that you have read the <a style="color:#fff;" routerLink="/sgc-privacy-policy">Privacy Policy</a>, consent to our data practices as described in the <a style="color:#fff;" routerLink="/sgc-privacy-policy"> Privacy Policy</a>, and agree that these Terms and Conditions will govern any disputes arising out of or related to our <a style="color:#fff;" routerLink="/sgc-privacy-policy">Privacy Policy</a>.</p>
</div>
