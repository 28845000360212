import { Pipe, PipeTransform } from '@angular/core';
import { Subject } from '../models/catalog';

import * as _ from 'lodash';

@Pipe({
    name: 'listSubjectsFormat'
})
export class ListSubjectsFormatPipe implements PipeTransform {
    transform(subjects: Subject[]): string {
        let output: string = null!;

        _.forEach(subjects, subject => output = this.concatenate(subject.name, output));

        return output;
    }

    concatenate(value: string, output: string) {
        if(!value) {
            return output;
        }
        if (output) {
            return output + ", " + value;
        } else {
            return value;
        }
    }
}