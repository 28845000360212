import { Component } from "@angular/core";
import { ModalInterface, ModalService } from "../../../../common/services/modal.service";
import { StateService } from "../../../../common/services/state.service";

@Component({
    selector: 'personal-shipping-alert',
    templateUrl: './personal-shipping-alert.component.html',
    styleUrls: ['../../../checkout.component.sass']
})
export class PersonalShippingAlertComponent implements ModalInterface {

    constructor(
        private modalService: ModalService,
        private stateService: StateService
    ){}

    title = "Shipping Alert";
    subtitle = "";
    type = "personal-shipping-alert";
    options: any;

    close() {
        this.modalService.close(this.type, 'close');
    }

    accept() {
        var userId = this.stateService.currentUser.get().id;

        localStorage.setItem(`${userId}_acceptPersonalShippingClause`, JSON.stringify(true));

        this.options.accept();
        this.close();
    }
}