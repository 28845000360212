import * as _ from 'lodash';

export class PopReportsSet {
  constructor(data?: any) {
    if (!data) return;

    this.id = data.id;
    this.name = data.name;
    this.description = data.description;
    this.cards = _.map(data.cards, (card) => new CardPopReportItem(card));
  }

  id!: number;
  name!: string;
  description!: string;
  cards = Array<CardPopReportItem>();
}

export class CardPopReportItem {
  constructor(data?: any) {
    if (!data) return;

    this.id = data.id;
    this.name = data.name;
    this.subjects = _.map(data.subjects, (subject) => new Subject(subject));
    this.gradeA = data.grades.gradeA;
    this.grade1 = data.grades.grade1;
    this.grade15 = data.grades['grade1.5'];
    this.grade2 = data.grades.grade2;
    this.grade25 = data.grades['grade2.5'];
    this.grade3 = data.grades.grade3;
    this.grade35 = data.grades['grade3.5'];
    this.grade4 = data.grades.grade4;
    this.grade45 = data.grades['grade4.5'];
    this.grade5 = data.grades.grade5;
    this.grade55 = data.grades['grade5.5'];
    this.grade6 = data.grades.grade6;
    this.grade65 = data.grades['grade6.5'];
    this.grade7 = data.grades.grade7;
    this.grade75 = data.grades['grade7.5'];
    this.grade8 = data.grades.grade8;
    this.grade85 = data.grades['grade8.5'];
    this.grade9 = data.grades.grade9;
    this.pristine = data.grades.pristine;
  }

  id!: number;
  name!: string;
  description!: string;
  subjects!: Array<Subject>;
  gradeA!: number;
  grade1!: number;
  grade15!: number;
  grade2!: number;
  grade25!: number;
  grade3!: number;
  grade35!: number;
  grade4!: number;
  grade45!: number;
  grade5!: number;
  grade55!: number;
  grade6!: number;
  grade65!: number;
  grade7!: number;
  grade75!: number;
  grade8!: number;
  grade85!: number;
  grade9!: number;
  pristine!: string;
}

export class Subject {
  constructor(data?: Subject) {
    if (!data) return;
    this.name = data.name;
  }
  name!: string;
}
