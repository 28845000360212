import { Compiler, Component, Input, OnInit } from '@angular/core';
import { CheckoutService } from '../../services/checkout.service';
import { Router } from '@angular/router';
import { Order } from '../../../orders/models/order';
import { OrderService } from '../../../orders/services/order.service';
import { ErrorService } from '../../../common/services/error.service';
import { ModalService } from '../../../common/services/modal.service';
import { environment } from '../../../../environments/environment';
import { first } from 'rxjs/operators';
import { Subject, BehaviorSubject } from 'rxjs';

@Component({
  selector: 'checkout-bottom-nav',
  templateUrl: './checkout-bottom-nav.component.html',
  styleUrls: ['../../checkout.component.sass'],
})
export class CheckoutBottomNavComponent implements OnInit {
  newItemsStepStateMessage: string = '';
  message: any;
  subject = new BehaviorSubject<string>(this.newItemsStepStateMessage);
  currentMessage = this.subject.asObservable();
  constructor(
    private checkoutService: CheckoutService,
    private ordersService: OrderService,
    private errorService: ErrorService,
    private modalService: ModalService,
    private router: Router,
    private _compiler: Compiler
  ) {}

  @Input() order = new Order();
  promoCode: string = '';
  isRefreshing: boolean = false;
  isError: boolean = false;
  termsaccept: boolean = false;
  popUpWindow: any;
  ///ADDITION

  getoldOrder(): any {
    return this.checkoutService.oldOrder();
  }
  getOrderTypeName(): string | undefined {
    return this.order.getOrderTypeName();
  }
  fixissue(): any {
    if (this.checkoutService.errorCount <= 1) {
      return true;
    } else {
      return false;
    }
  }

  zeroissues(): any {
    return this.checkoutService.errorCount == 0;
  }

  showFixIssue() {
    debugger;
    return this.checkoutService.showFixissues;
  }

  noissues(): any {
    // if (this.checkoutService.errorCount == 0) {
    //     return true;
    // } //else if (this.checkoutService.errorCount == 0) {
    //     return this.isStepCompleted;}
    if (
      // this.order.items == null ||
      this.order.items.length == 0 ||
      this.checkoutService.errorCount == 0
    ) {
      return true;
    }
  }
  ngOnInit(): void {}
  getOrderTypeCodes(): string | undefined {
    return this.order.getOrderTypeCode();
  }

  // newmessage(): boolean {
  //   if (
  //     !this.checkoutService.cast.subscribe(
  //       (ItemsStepStateMessage) =>
  //         (this.newItemsStepStateMessage = ItemsStepStateMessage)
  //     )
  //   )
  //     return false;
  //   if (
  //     this.checkoutService.cast.subscribe(
  //       (ItemsStepStateMessage) =>
  //         (this.newItemsStepStateMessage = ItemsStepStateMessage)
  //     )
  //   )
  //     return true;
  // }

  newmessage(): boolean {
    this.checkoutService.cast
      .pipe(first())
      .subscribe(
        (ItemsStepStateMessage) =>
          (this.newItemsStepStateMessage = ItemsStepStateMessage)
      );

    return true;
  }

  moveForward() {
    this.checkoutService.moveForward();
    this.getoldOrder();
  }

  moveForward2() {
    if (this.checkoutService.errorCount >= 1) {
      this.checkoutService.showFixissues = true;
    }
    this.checkoutService.moveForward2();
    this.getoldOrder();
  }

  finishPay() {
    this.checkoutService.finishPay();
  }

  showPromoCode(): boolean {
    return this.checkoutService.showPromoCode();
  }
  getPromoFee(): number {
    return this.order.getPromoFee();
  }

  canMoveForward(): boolean {
    return this.checkoutService.canMoveForward();
  }
  ///ADDITION
  canPaySubmission(): boolean {
    return this.checkoutService.isPaymentStep();
  }

  getErrorCount(): number {
    debugger;
    return this.checkoutService.Errorcount();
  }

  getTotal(): number {
    return this.order.getTotal();
  }
  isStep1(): boolean {
    return this.checkoutService.isStep1();
  }
  isStep4(): boolean {
    return this.checkoutService.isStep4();
  }
  isStep5(): boolean {
    return this.checkoutService.isStep5();
  }
  isStep6(): boolean {
    return this.checkoutService.isStep6();
  }
  isStep7(): boolean {
    return this.checkoutService.isStep7();
  }

  getItemsCount(): number | undefined {
    return this.order.getItemsCount();
  }

  getTotalDeclaredValue(): number {
    return this.order.getTotalDeclaredValue();
  }

  getTotalFee(): number {
    return this.order.getTotalFee();
  }

  resultitem(): any {
    return this.checkoutService.getshow();
  }

  getShippingFee(): number {
    return this.order.getshippingFee();
  }
  getTurnaround(): any {
    if (
      this.order.getTotalTurnaround() == 1 &&
      this.checkoutService.currentOrder.hasPlan()
    ) {
      return 'Immediate';
      // return "End of Show";//"1 Business Day";
    } else if (
      this.order.getTotalTurnaround() == 2 &&
      this.checkoutService.currentOrder.hasPlan()
    ) {
      return 'Immediate';
      // return "1-2 Estimated Business Days";
    } else if (
      this.order.getTotalTurnaround() == 10 &&
      this.checkoutService.currentOrder.hasPlan()
    ) {
      return 'Standard';
      // return "5-10 Estimated business days";
    } else if (
      this.order.getTotalTurnaround() == 5 &&
      this.checkoutService.currentOrder.hasPlan()
    ) {
      return 'SUPER EXPRESS';
      // return "5 Estimated business days";
    } // old
    //else if (this.order.getTotalTurnaround() == 5 && this.checkoutService.currentOrder.hasPlan()) {
    //    return "1-5 Estimated business days";
    //} // new
    else if (
      this.order.getTotalTurnaround() == 48 &&
      this.checkoutService.currentOrder.hasPlan()
    ) {
      return 'Standard';
      // return "43-48 Estimated business days";
    } else if (
      this.order.getTotalTurnaround() == 55 &&
      this.checkoutService.currentOrder.hasPlan()
    ) {
      return 'Standard';
      // return "50-55 Estimated business days";
    } else if (
      this.order.getTotalTurnaround() == 57 &&
      this.checkoutService.currentOrder.hasPlan()
    ) {
      return 'Standard';
      // return "52-57 Estimated business days";
    } else if (
      this.order.getTotalTurnaround() == 70 &&
      this.checkoutService.currentOrder.hasPlan()
    ) {
      return 'Standard';
      // return "65-70 Estimated Business Days";
    } else if (
      this.order.getTotalTurnaround() == 3 &&
      this.checkoutService.currentOrder.hasPlan()
    ) {
      return 'Express';
      // return "1-3 Estimated Business Days";
    } else if (
      this.order.getTotalTurnaround() == 25 &&
      this.checkoutService.currentOrder.hasPlan()
    ) {
      return 'Standard';
      // return "20-25 Estimated Business Days";
    } else if (
      this.order.getTotalTurnaround() == 50 &&
      this.checkoutService.currentOrder.hasPlan()
    ) {
      return 'Standard';
      // return "45-50 Estimated Business Days";
    }
    //else if (this.order.getTotalTurnaround() == 30 && this.checkoutService.currentOrder.hasPlan()) {
    //    return "30 Business Days Guaranteed";
    //}
    //else if (this.order.getTotalTurnaround() == 100 && this.checkoutService.currentOrder.hasPlan()) {
    //    return "Special Items Non-Guarantee";
    //}
    else {
      if (this.checkoutService.currentOrder.hasPlan()) {
        return this.order.getTotalTurnaround()
          ? this.order.getTotalTurnaround() + ' estimated business days'
          : this.order.getTotalTurnaround();
      }
    }
  }

  //ADDITION
  // getBackwardLabel(): string | undefined {
  //   if (!this.checkoutService.canMoveBackward()) {
  //     if (!this.checkoutService.isCheckoutFinished()) return 'Back';
  //   } else {
  //     return 'Back';
  //   }
  // }

  getBackwardLabel(): string {
    if (!this.checkoutService.canMoveBackward()) {
      if (!this.checkoutService.isCheckoutFinished()) {
        return 'Back';
      } else {
        return 'Some default label'; // Replace this with the appropriate label when the checkout is finished
      }
    } else {
      return 'Back';
    }
  }

  canMoveBackward(): boolean {
    return !this.checkoutService.isCheckoutFinished();
  }

  moveBackward() {
    this.checkoutService.moveBackward();
    // this.checkoutService.resetfee();
    this.checkoutService.showFixissues = false;
  }

  isStepCompleted(): boolean {
    return this.checkoutService.getStepState().completed;
  }

  isShippingState(): boolean {
    return this.checkoutService.isShippingState();
  }
  isfinishPay() {
    var a = this.checkoutService.getCurrentStep();
    if (a.id == 'review') {
      return true;
    } else {
      return false;
    }
  }

  canSubmitWithoutPaying(): boolean {
    return (
      this.order &&
      this.checkoutService.isOrderCompleted() &&
      this.order.getTotalFee() <= 0 &&
      this.order.getshippingFee() <= 0
    );
  }

  canPaySubmissionWithTerms(): boolean {
    return this.checkoutService.isPaymentStep() && this.termsaccept == true;
  }
  submitOrder() {
    this.checkoutService.submitOrder();
  }
  ischeckout() {
    var a = this.checkoutService.getCurrentStep();
    if (a.id == 'payment') {
      return true;
    } else {
      return false;
    }
  }

  payWithStripe() {
    var orderId = this.order.id;
    this.router.navigate([`/checkout/${orderId}/payment`]);
  }

  isRequiredLabelForAddCard() {
    var a = this.checkoutService.getCurrentStep();
    if (a.id == 'items') {
      return false;
    } else {
      return true;
    }
  }
  getOrderTypeCode(): any {
    return this.checkoutService.getOrderTypeCode();
  }

  getStepMessage(): string {
    return this.checkoutService.getStepState().message;
  }

  //ADDITION
  checkError() {
    this.checkoutService.emitdata(this.subject);
    this.checkoutService.emitdata(this.getStepMessage());
    this.checkoutService.emitdata(
      this.checkoutService.currentOrder.items.forEach((item) => {
        item.settings.cardGrading;
      })
    );
  }

  isCheckoutFinished(): boolean {
    return this.checkoutService.isCheckoutFinished();
  }

  navigateToHome() {
    this.router.navigate(["/"]);
}

  startSubmission() {
    
    this._compiler.clearCache();
    // this.checkoutService.initCheckout(null, "expertise");
    // this.ordersService.createOrder()
    // this.router.navigate(["/checkout/"]);
    localStorage.setItem("init", "true")
    window.location.href = `/checkout`
}

  isStep1234567(): boolean {
    return (
      this.checkoutService.isStep1() ||
      this.checkoutService.isStep2() ||
      this.checkoutService.isStep3() ||
      this.checkoutService.isStep7() ||
      this.checkoutService.isStep4() ||
      this.checkoutService.isStep5() ||
      this.checkoutService.isStep6()
    );
  }

  isStep178(): boolean {
    return this.checkoutService.isStep1() || this.checkoutService.isStep8();
  }
  isStep12378(): boolean {
    return (
      this.checkoutService.isStep1() ||
      this.checkoutService.isStep2() ||
      this.checkoutService.isStep3() ||
      this.checkoutService.isStep8()
    );
  }
  isStep2(): boolean {
    return this.checkoutService.isStep2();
  }
  isStep3(): boolean {
    return this.checkoutService.isStep3();
  }
  isStep8(): boolean {
    return this.checkoutService.isStep8();
  }

  ///ADDITION
}
