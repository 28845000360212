import { Component, OnInit, ViewChild, OnDestroy,NgModule  } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { CheckoutService } from '../../services/checkout.service';
import { OrderService } from '../../../orders/services/order.service';
import { AdminKPIExecutive } from '../../../orders/models/order';
import * as _ from 'lodash';
import { GridModule } from '@syncfusion/ej2-angular-grids';

import { UserService } from '../../../common/services/user.service';
import { Address, Customer, User } from '../../../common/models/user';
import { isNullOrUndefined } from '@syncfusion/ej2-base';
// import { isEmpty } from 'rxjs/operators';
// import { empty } from 'rxjs';
// import { forEach } from '@angular/router/src/utils/collection';

// for raw card review pricing
import {
    RawCardReview
} from '../../../card-grading/views/services-pricing/models/services-pricing';
import { GridComponent, GridLine, TextWrapSettingsModel } from '@syncfusion/ej2-angular-grids';
import { VirtualScrollService } from '@syncfusion/ej2-angular-grids';
import { TabComponent } from '@syncfusion/ej2-angular-navigations';
import { SelectEventArgs } from '@syncfusion/ej2-navigations';
// import { ColumnModel } from '@syncfusion/ej2-angular-grids';
import { Column } from '@syncfusion/ej2-grids';

@Component({
    selector: 'select-plan',
    templateUrl: './select-plan.component.html',
    styleUrls: ['../../checkout.component.sass', '../../../card-grading/views/services-pricing/services-pricing.component.css'],
    providers: [VirtualScrollService]
})
// @NgModule({
//     imports: [
//       // Other imported modules
//       GridModule
//     ],
//     // Other component properties
//   })
export class SelectPlanComponent implements OnInit {
    constructor(
        private checkoutService: CheckoutService,
        private orderService: OrderService,
        private userService: UserService,
        private metaService: Meta,
        private titleService: Title
    ) { }
    @ViewChild('tab_default') tabInstance!: TabComponent;

    // Start -- for raw card review pricing
    @ViewChild('grid2')
    public lines!: GridLine;
    public grid!: GridComponent;
    public dataStandardRawCard!: Object[];
    public wrapSettings!: TextWrapSettingsModel;
    public StdColumns!: Column[];
    public ServiceColumns!: Column[];
    

    public headerText: Object = [{ text: "RAW CARD REVIEW", }];

    // End -- for raw card review pricing

    adminKPIExecutive = new AdminKPIExecutive();
    currentUser = new User();
    orderPlans = new Array<any>();
    selectedPlan: any;
    expanded: boolean = false;
    isRefreshing: boolean = true;
    plan1st: number = 0;
    plan2nd: number = 0;
    plan3rd: number = 0;
    plan4th: number = 0;
    planCount: number = 0;
    orderTypeCode: any;
    planSelected!: boolean;
    //Planbydefault: any;





    select(plan: any) {
        this.checkoutService.planstate=true
        this.selectedPlan = plan;
        this.checkoutService.setOrderPlan(plan, this.plan1st, this.plan2nd, this.plan3rd, this.plan4th, this.planCount);
    }

    isSelected(plan: any): boolean {
        return _.isEqual(this.selectedPlan, plan);
    }

    isRawCardReviewSelected(plan: any): boolean {

        if (this.planSelected == false) {
            this.planSelected = true;
            this.select(plan);
        }
        return _.isEqual(this.selectedPlan, plan);
    }

    isGuranteeOff(plan: any) :boolean{
        this.userService.userProfile$.subscribe(user => this.currentUser = user);

        if (this.currentUser.isEmployee) {
            return false;
        }
        else {
            //if (plan.turnaround == 30 && this.adminKPIExecutive.allowBusinessDayGuarantee == false) {
            //    return true;
            //}
            if (plan.turnaround == 30) {
                return true;
            }
            else{
                return false
            }
        }

    }

    //getTurnaround(): any {
    //
    //    if (this.order.getTotalTurnaround() == 45) {
    //        return 45;
    //    }
    //    else if (this.order.getTotalTurnaround() == 3) {
    //        return 3;
    //    }
    //    else {
    //        return 0;
    //    }
    //}

    getPlanName(plan: any): string {
        if (plan.turnaround == 1) return 'Same Day';
        //if (plan.turnaround == 2) return 'Next Day';
        if (plan.turnaround == 2) return '1-2 BUSINESS DAY ESTIMATE';
        if (plan.turnaround == 48) return 'CURRENT ESTIMATE: 43-48 BUSINESS DAYS';
        if (plan.turnaround == 55) return 'CURRENT ESTIMATE: 50-55 BUSINESS DAYS';
        if (plan.turnaround == 57) return 'CURRENT ESTIMATE: 52-57 BUSINESS DAYS';
        if (plan.turnaround == 70) return 'CURRENT ESTIMATE: 65-70 BUSINESS DAYS';
        if (plan.turnaround == 3) return '1-3 BUSINESS DAY ESTIMATE';
        if (plan.turnaround == 25) return '20-25 BUSINESS DAY ESTIMATE';
        if (plan.turnaround == 10) return '5-10 BUSINESS DAY ESTIMATE';
        if (plan.turnaround == 5) return '5 BUSINESS DAY ESTIMATE'; //old
        //if (plan.turnaround == 5) return '1-5 BUSINESS DAY ESTIMATE'; // new
        if (plan.turnaround == 50) return '45-50 BUSINESS DAY ESTIMATE';
        //if (plan.turnaround == 30) return '30 Business Days Guaranteed';
        //if (plan.turnaround == 100) return 'Special Items Non-Guarantee';
        return `${plan.turnaround} Days`;
    }

    getTurnAroundTime(plan: any): string {
        if (plan.turnaround == 1) return '1 BUSINESS DAY';
        if (plan.turnaround == 2) return '1-2 BUSINESS DAYS ESTIMATED TIME';
        if (plan.turnaround == 10) return '5-10 BUSINESS DAYS ESTIMATED TIME';
        if (plan.turnaround == 48) return '43-48 BUSINESS DAYS ESTIMATED TIME';
        if (plan.turnaround == 55) return '50-55 BUSINESS DAYS ESTIMATED TIME';
        if (plan.turnaround == 57) return '52-57 BUSINESS DAYS ESTIMATED TIME';
        if (plan.turnaround == 70) return '65-70 BUSINESS DAYS ESTIMATED TIME';
        if (plan.turnaround == 3) return '1-3 BUSINESS DAYS ESTIMATED TIME';
        if (plan.turnaround == 25) return '20-25 BUSINESS DAYS ESTIMATED TIME';
        if (plan.turnaround == 50) return '45-50 BUSINESS DAYS ESTIMATED TIME';
        //if (plan.turnaround == 30) return '30 Business Days Guaranteed';
        else{return''}
        //if (plan.turnaround == 100) return 'Special Items Non-Guarantee';
    }

    getTurnAroundDays(plan: any): string {

        if (plan.turnaround == 70) return 'STANDARD';
        if (plan.turnaround == 57) return 'STANDARD';
        if (plan.turnaround == 25) return 'STANDARD';
        if (plan.turnaround == 50) return 'STANDARD';
        //if (plan.turnaround == 30) return 'SGC PROMISE';
        if (plan.turnaround == 10) return 'STANDARD';
        if (plan.turnaround == 5) return 'SUPER EXPRESS'; //old
        //if (plan.turnaround == 5) return 'STANDARD'; //new
        if (plan.turnaround == 2) return 'IMMEDIATE';
        if (plan.turnaround == 3) return 'EXPRESS';
        else{return''}

        //if (plan.turnaround == 100) return 'I CAN WAIT';
    }

    togglePlans() {
        this.expanded = !this.expanded;
    }

    showMorePlans(): boolean {
        return this.orderPlans.length > 4 && !this.expanded;
    }

    getPlans(): Array<any> {
        var size = this.expanded ? this.orderPlans.length : 4;
        return this.orderPlans.slice(0, size);
    }

    ngOnInit() {

        this.checkoutService.planstate=false
        this.checkoutService.onstep3=true
        this.orderTypeCode = this.checkoutService.getOrderTypeCode();

        this.checkoutService.currentOrder$.subscribe(order => {
            if (!order.allItemsWithDeclaredValue()) return;
            this.checkoutService.getOrderPlans().subscribe(plans => {

                this.orderPlans = plans;

                for (let ordplan of this.orderPlans) {

                    if (this.orderTypeCode != "card-pre-grade") {

                        this.planCount = this.planCount + 1;
                        if (this.plan1st == 0 && ordplan.turnaround == 25) {
                            this.plan1st = ordplan.turnaround;
                        }
                        if (this.plan2nd == 0 && ordplan.turnaround == 10) {
                            this.plan2nd = ordplan.turnaround;
                        }
                        if (this.plan3rd == 0 && ordplan.turnaround == 5) {
                            this.plan3rd = ordplan.turnaround;
                        }
                        if (this.plan4th == 0 && ordplan.turnaround == 2) {
                            this.plan4th = ordplan.turnaround;
                        }
                    }
                    else {

                        if (this.plan1st == 0 && ordplan.turnaround == 1) {
                            this.plan1st = ordplan.turnaround;
                        }
                    }
                }

                var a = this.planCount;
                var b = this.plan1st;
                var c = this.plan2nd;
                var d = this.plan3rd;
                var e = this.plan4th;

                this.isRefreshing = false;
                this.selectedPlan = _.find(plans, plan => plan.isSelected);
                if (this.selectedPlan) order.setPlan(this.selectedPlan);
            });
        });

        //For Raw Card Grading
        if (this.orderTypeCode == "card-pre-grade") {
            this.planSelected = false;
        }

        this.dataStandardRawCard = RawCardReview;

        this.lines = 'Both';
        this.wrapSettings = { wrapMode: 'Both' };
    }
}
