import { Component, Input } from '@angular/core';
// import { OrderItemConfigurationService } from '../../services/order-item-configuration.service';
import { OrderItemConfigurationService } from '../../services/order-item-configuration.services';
@Component({
    selector: 'modal-actions',
    templateUrl: './modal-actions.component.html'
})
export class ModalActionsComponent {
    constructor(
        private orderItemConfigurationService: OrderItemConfigurationService
    ){}

    @Input() options: any;
    @Input() type!: string;

    isStepCompleted(): boolean {
        return this.orderItemConfigurationService.isStepCompleted(this.options);
    }

    save() {
        this.orderItemConfigurationService.saveStep(this.type, this.options);
    }

    cancel() {
        this.orderItemConfigurationService.cancel();
    }

    back() {
        this.orderItemConfigurationService.moveBackward(this.options);
    }

    getForwardButtonLabel(): string {
        return this.orderItemConfigurationService.getForwardButtonLabel(this.type);
    }

    showBack(): boolean {
        return !this.orderItemConfigurationService.isFirstStep(this.type, this.options);
    }
}