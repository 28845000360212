import { Injectable, ErrorHandler, Injector } from '@angular/core';
import { Router, ResolveEnd, ActivatedRouteSnapshot } from '@angular/router';
import { AppInsights } from 'applicationinsights-js';
import { filter } from 'rxjs/operators';
// import { filter } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { AuthService } from './auth.service';

@Injectable()
export class MonitorService {
  constructor(private router: Router) {}

  init() {
    this.initAppInsights();
    this.subscribeToRouterEvents();
  }

  trackPageView(
    name: string,
    url?: string,
    properties?: { [key: string]: string },
    measurements?: { [key: string]: number },
    duration?: number
  ) {
    AppInsights.trackPageView(
      name,
      url,
      this.AddGlobalProperties(properties),
      measurements,
      duration
    );
  }

  trackEvent(
    name: string,
    properties?: { [key: string]: string },
    measurements?: { [key: string]: number }
  ) {
    AppInsights.trackEvent(
      name,
      this.AddGlobalProperties(properties),
      measurements
    );
  }

  trackError(
    error: Error,
    properties?: { [key: string]: string },
    measurements?: { [key: string]: number }
  ) {
    AppInsights.trackException(
      error,
      undefined,
      this.AddGlobalProperties(properties),
      measurements
    );
  }

  private AddGlobalProperties(properties?: { [key: string]: string }): {
    [key: string]: string;
  } {
    if (!properties) {
      properties = {};
    }

    return properties;
  }

  setAuthenticatedUserId(userId: string) {
    AppInsights.setAuthenticatedUserContext(userId);
  }

  private initAppInsights() {
    if (environment.appInsights && environment.appInsights.instrumentationKey) {
      if (AppInsights && typeof AppInsights.downloadAndSetup === 'function') {
        AppInsights.downloadAndSetup(environment.appInsights);
      }
    }
  }

  // private subscribeToRouterEvents() {
  //     this
  //         .router
  //         .events
  //         .filter((event:any) => event instanceof ResolveEnd)
  //         .subscribe((event: ResolveEnd) => {
  //             const activatedComponent = this.getActivatedComponent(event.state.root);
  //             if (activatedComponent) {
  //                 this.trackPageView(`${activatedComponent.name} ${this.getRouteTemplate(event.state.root)}`, event.urlAfterRedirects);
  //             }
  //         });
  // }
  private subscribeToRouterEvents() {
    this.router.events
      .pipe(filter((event: any) => event instanceof ResolveEnd))
      .subscribe((event: ResolveEnd) => {
        const activatedComponent = this.getActivatedComponent(event.state.root);
        if (activatedComponent) {
          this.trackPageView(
            `${activatedComponent.name} ${this.getRouteTemplate(
              event.state.root
            )}`,
            event.urlAfterRedirects
          );
        }
      });
  }

  private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {
    if (snapshot.firstChild) {
      return this.getActivatedComponent(snapshot.firstChild);
    }

    return snapshot.component;
  }

  private getRouteTemplate(snapshot: ActivatedRouteSnapshot): string {
    let path = '';
    if (snapshot.routeConfig) {
      path += snapshot.routeConfig.path;
    }

    if (snapshot.firstChild) {
      return path + this.getRouteTemplate(snapshot.firstChild);
    }

    return path;
  }
}

@Injectable()
export class MonitorErrorHandler extends ErrorHandler {
  constructor(private injector: Injector) {
    super();
  }

  override handleError(error: any): void {
    // const monitorService = this.injector.get(MonitorService);
    // monitorService.trackError(error);

    const authService = this.injector.get(AuthService);
    const router = this.injector.get(Router);

    // if (error.status === 401) {
    //   var url = router.routerState.snapshot.url;
    //   localStorage.setItem('redirectUrl', url);
    //   authService.login();
    // }

    //   super.handleError(error);
    if (error && error.status === 401) {
      // Ensure that 'error' is not null or undefined before accessing its properties
      const url = router.routerState.snapshot.url;
      localStorage.setItem('redirectUrl', url);
      authService.login();
    }

    //super.handleError(error);
  }
}
