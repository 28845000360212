import { Component, OnInit } from "@angular/core";

import {
    ShippingMethod,
    Order,
    AdminKPIExecutive,
    OrderType,
} from "../../../orders/models/order";
import { LowerCasePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import { CheckoutService } from "../../services/checkout.service";
import { UserService } from "../../../common/services/user.service";
import { Address, Customer, User } from "../../../common/models/user";
import { ModalService } from "../../../common/services/modal.service";
import { OrderService } from "../../../orders/services/order.service";
import { Observable } from "rxjs";
import { delay } from "rxjs/operators";
import { of } from "rxjs";
import { FormControl } from "@angular/forms";
import { ErrorService } from "../../../common/services/error.service";
import * as _ from "lodash";
// import { debug } from "util";

@Component({
    selector: 'select-shipping',
    templateUrl: './select-shipping.component.html',
    // styleUrls: ['./select-shipping.component.css']
  })
  export class SelectShippingComponent implements OnInit {
    constructor(
      private checkoutService: CheckoutService,
      private userService: UserService,
      private errorService: ErrorService,
      private modalService: ModalService,
      private orderService: OrderService
    ) {}
  
    isRetrievingResults = false;
    showCustomersEditor = false;
    showNewCustomerData: any;
    shippingUspsMessage: any;
    showCustomer = false;
    searchResults = new Array<Customer>();
    searchTerm: FormControl = new FormControl();
    private listingOptions: any = {
      limit: 10,
      offset: 0,
      result: 0,
    };
    //editableCustomer = new Customer();
    private searchOptions = {
      limit: 10,
      offset: 0,
      filter: { query: null as string | null },
      result: 0,
    };
  
    currentUser = new User();
    currentCustomer = new Customer();
    shippingMethods = new Array<ShippingMethod>();
    selectedShippingMethod = new ShippingMethod();
    userAddresses = new Array<Address>();
    customerAddresses = new Array<Address>();
    selectedAddress = new Address();
    order = new Order();
    adminKPIExecutive = new AdminKPIExecutive();
    orderTypeCode: any;
    shippingSelected!: boolean;
  
    oldFedexAccount!: number;
    canEditFedexAccount: boolean = false;
    showLoginUserAddress: boolean = true;

    useFedex = JSON.parse(localStorage.getItem("user")!)
  
    ngOnInit() {
      
      if(!localStorage.getItem("useFed")){
        localStorage.setItem("useFed",String(this.useFedex.preferences.useFedexAccount))
    }
      this.userService.userProfile$.subscribe((user) => {
        this.currentUser = user;
        if (this.currentUser.isEmployee) {
          this.showLoginUserAddress = false;
          this.checkoutService.currentOrder.shippingAddress = null!;
        }
      });
  
      this.orderTypeCode = this.checkoutService.getOrderTypeCode();
  
      if (this.showLoginUserAddress != false) {
        this.checkoutService.currentOrder$.subscribe((order) => {
          if (!order.allItemsWithDeclaredValue()) return;
          this.order = order;
          this.oldFedexAccount = this.order.settings.fedexAccount;
          this.userService.userAddresses$.subscribe((addresses) => {
            this.userAddresses = addresses;
            if (addresses.length == 0) {
              // this.openAddressEditor(new Address({ country: { code: 'US' }, state: { code: '' } }));
            } else {
              if (order.shippingAddress && order.shippingAddress.id) {
                this.selectedAddress = _.find(
                  addresses,
                  (a) => a.id == order.shippingAddress.getId()
                  )!;
                  if (this.selectedAddress == null || this.selectedAddress == undefined) {
                      var defaultAddress = _.find(addresses, a => a.isDefault == true);
                      if (defaultAddress != null && defaultAddress != undefined) {
                          this.selectAddress(defaultAddress);
                          this.selectedAddress = defaultAddress;
                          order.shippingAddress = defaultAddress;
                      }
                      else {
                          this.selectAddress(addresses[0]);
                          this.selectedAddress = addresses[0];
                          order.shippingAddress = addresses[0];
                      }
                  }
                this.checkoutService
                  .getAvailableShippingMethods()
                  .subscribe((shippingMethods) => {
                    this.shippingMethods = shippingMethods;
                    if (
                      order.shippingMethod &&
                      order.shippingMethod.id
                    )
                      this.selectedShippingMethod = _.find(
                        shippingMethods,
                        (sp) =>
                          sp.id ==
                          order.shippingMethod.getId()
                      )!;
                    if (!this.selectedShippingMethod)
                      this.checkoutService.unSetShippingMethod();
                  });
              } else {
                if (addresses.length >= 1)
                  this.selectAddress(addresses[0]);
              }
            }
          });
        });
      } else {
        this.checkoutService.currentOrder$.subscribe((order) => {
          if (!order.allItemsWithDeclaredValue()) return;
          this.order = order;
          this.checkoutService.currentOrder.shippingAddress = null!;
          this.checkoutService.order.shippingAddress = null!;
          this.checkoutService.getAddressStepState();
  
          this.oldFedexAccount = this.order.settings.fedexAccount;
        });
      }
  
      if (this.orderTypeCode == "card-pre-grade") {
        this.selectShippingAccount(false);
        this.shippingSelected = false;
        //this.checkoutService.getAvailableShippingMethods().subscribe(shippingMethods => {
        //    this.selectedShippingMethod = shippingMethods[0];
        //});
      }
    }
  
    selectShippingMethod(shippingMethod: ShippingMethod) {
      this.checkoutService.setEnable();
      this.selectedShippingMethod = shippingMethod;
      if (this.isUSPSSelected() && this.order.settings.useFedexAccount) {
        this.checkoutService.setShippingMethod(shippingMethod);
      } else {
        this.checkoutService.setShippingMethod(shippingMethod);
      }
    }
  
    isUSPSSelected(): boolean {
      return Boolean(
        this.selectedShippingMethod &&
        this.selectedShippingMethod.carrier &&
        this.selectedShippingMethod.carrier.code == "usps"
      );
    }
  
    isShippingMethodSelected(shippingMethod: ShippingMethod): boolean {
      if (this.shippingSelected == false) {
        this.shippingSelected = true;
        this.selectShippingMethod(shippingMethod);
      }
      return this.selectedShippingMethod == shippingMethod;
    }
  
    isAddressSelected(address: Address): boolean {
      return this.selectedAddress == address;
    }
  
    selectAddress(address: Address) {
      // if (this.selectedAddress == address) return;
      this.checkoutService.setEnable();
      this.selectedAddress = address;
      this.checkoutService.setShippingAddress(address).subscribe((order) => {
        this.checkoutService
          .getAvailableShippingMethods()
          .subscribe((shippingMethods) => {
            this.shippingMethods = shippingMethods;
            if (order.shippingMethod && order.shippingMethod.id)
              this.selectedShippingMethod = _.find(
                shippingMethods,
                (sp) => sp.id == order.shippingMethod.getId()
              )!;
            if (!this.selectedShippingMethod)
              this.checkoutService.unSetShippingMethod();
          });
      });
    }
  
    addAddress() {
      this.openAddressEditor(
        new Address({ country: { code: "US" }, state: { code: "" } })
      );
    }
  
    openAddressEditor(address?: Address) {
      this.modalService.open("address-editor", address);
    }
  
    inst() {
      var options = {
        message: `Click on the shipping address below to view your shipping options.`,
        message1: `If you are picking up your order at SGC or a show, we require a shipping address in the event you are unable to pick up your order.`,
        message2: `If your shipping address is not displayed, or you would like to add another address, click the '+' to add an address. After saving the address, click it to view your shipping options`,
  
        actionLabel: "Go To Legacy Registry",
        actionCallback: () =>
          window.open(
            "https://sgclegacy.com/registrymain.aspx",
            "_blank"
          ),
      };
  
      this.modalService.open("app-shipping-instruction", options);
    }
  
    isPersonalShippingAccountSelected(): boolean {
      return this.order && this.order.settings.useFedexAccount;
    }
  
    isShipping(value: any) {
      if (value == false) {
        return true;
      } else {
        return false;
      }
    }
  
    saveFedexAccount() {
      this.canEditFedexAccount = false;
      this.oldFedexAccount = this.order.settings.fedexAccount;
      this.order.settings.useFedexAccount = true;
      this.updateOrder();
    }
  
    updateOrder() {
      this.checkoutService.updateOrder();
    }
  
    updateOrderWithShipping() {
      this.checkoutService.updateOrderWithShipping();
    }
  
    selectShip(value: boolean) {
      this.checkoutService.setEnable();
    }
  
    selectShippingAccount(personal: boolean) {
      this.order.settings.useFedexAccount = personal;
      if (
        (personal &&
          this.order.settings.fedexAccount &&
          !this.canEditFedexAccount) ||
        !personal
      )
        this.updateOrderWithShipping();
    }
  
    editFedexAccount() {
      this.canEditFedexAccount = true;
    }
  
    updateOrderForCusFedex() {
      this.checkoutService.updateOrderForCusFedex();
    }
  
    getFedexAccountNumber(): number {
      return this.order && this.order.settings.fedexAccount;
    }
  
    getCustomerFedexAccount(): string {
      return this.currentCustomer.preferences.currentFedexAccount
        ? this.currentCustomer.preferences.currentFedexAccount
        : "Your Fedex account";
    }
  
    canSubmitValue(): boolean {
      var fedexAccount = this.order ? this.order.settings.fedexAccount : null;
      return Boolean(fedexAccount && fedexAccount != this.oldFedexAccount);
    }
  
    canSearch(): any {
      if (this.searchTerm.value) return true
    }
  
    getCustomers() {
      this.isRetrievingResults = true;
      this.userService
        .getCustomerByName(this.listingOptions)
        .subscribe((customers) => {
          this.searchResults = _.cloneDeep(
            this.searchResults.concat(customers)
          );
          this.listingOptions.result = customers.length;
          this.listingOptions.offset += this.listingOptions.limit;
          this.isRetrievingResults = false;
        });
    }
  
    editCustomer(customer: Customer) {
      this.currentCustomer = customer;
      this.order.settings.fedexAccount =
        this.currentCustomer.user.preferences.currentFedexAccount;
      this.oldFedexAccount = this.order.settings.fedexAccount;
      this.checkoutService.currentOrder.settings.fedexAccount =
        this.currentCustomer.user.preferences.currentFedexAccount;
      this.showCustomer = true;
      if (customer.addresses.length >= 1) {
        this.selectAddress(customer.addresses[0]);
      } else {
        this.selectedAddress = customer.addresses[0];
      }
      this.userService.updatedSeletedCustomerAdrs(customer.addresses);
      this.userService.selectedCusAddresses$.subscribe(
        (addresses) => (this.customerAddresses = addresses)
      );
      localStorage.setItem(
        "newUserInfo",
        JSON.stringify({
          newUserId: customer.user.id,
          newCustomerId: customer.id,
        })
      );
    }
  
    closeCustomerEditor() {
      this.showCustomersEditor = false;
    }
  
    showLoadMore(): boolean {
      return Boolean(
        this.searchResults.length &&
          ((!this.searchOptions.filter.query &&
            this.listingOptions.result === this.listingOptions.limit) ||
            (this.searchOptions.filter.query &&
              this.searchOptions.result === this.searchOptions.limit))
      );
    }
  
    loadMore() {
      if (this.searchOptions.filter.query) {
        this.search(this.searchOptions.filter.query);
      } else {
        this.getCustomers();
      }
    }
  
    search(query: any) {
      if (query) {
        if (query !== this.searchOptions.filter.query) {
          this.resetSearchOptions();
        }
        this.searchOptions.filter.query = query;
        this.isRetrievingResults = true;
        this.userService
          .getCustomerByName(this.searchOptions)
          .subscribe((response) => {
            this.isRetrievingResults = false;
            this.updateSearchOptions(response);
            this.searchResults = this.searchResults.concat(response);
          });
      } else {
        this.searchResults = new Array<Customer>();
        this.resetSearchOptions();
        this.resetlistingOptions();
        this.isRetrievingResults = false;
        this.getCustomers();
      }
    }
  
    resetSearchOptions() {
      this.searchOptions.offset = 0;
      this.searchOptions.result = 0;
      this.searchOptions.filter.query = "";
      this.searchResults = new Array<Customer>();
    }
  
    resetlistingOptions() {
      this.listingOptions.result = 0;
      this.listingOptions.offset = 0;
      this.searchResults = new Array<Customer>();
    }
  
    updateSearchOptions(response: any) {
      this.searchOptions.offset += this.searchOptions.limit;
      this.searchOptions.result = response.length;
    }
  
    static toTitleCase(str: string): string {
      return str.replace(/\b\w/g, (match) => match.toUpperCase());
    }
  }
  
  @Pipe({
    name: 'titleCase'
  })
  export class TitleCasePipe implements PipeTransform {
    transform(value: string): string {
      return SelectShippingComponent.toTitleCase(value);
    }
  }