import { Component, OnInit } from '@angular/core';
 
import { Alert, AlertType } from '../../models/alert';
import { AlertService } from '../../services/alert.service';
 
@Component({
    selector: 'alert',
    templateUrl: 'alert.component.html',
    styleUrls : ['alert.component.sass']
})
 
export class AlertComponent {
    alerts: Alert[] = [];
 
    constructor(private alertService: AlertService) { }
 
    ngOnInit() {
        this.alertService.getAlert().subscribe((alert: Alert) => {
            if (alert.message == '' || alert.message == undefined) {
                // clear alerts when an empty alert is received
                this.alerts = [];
                return;
            }
 else {
// add alert to array
this.alerts.push(alert);
 }
            
        });
    }
 
    removeAlert(alert: Alert) {
        this.alerts = this.alerts.filter(x => x !== alert);
        if(alert.actionCallback) alert.actionCallback();
    }
}