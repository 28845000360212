import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { GeneralLayoutComponent } from '../common/layout/general-layout/general-layout.component';
import { LeftNavLayoutComponent } from '../common/layout/left-nav-layout/left-nav-layout.component';
import { AccountProfileComponent } from './views/account-profile/account-profile.component';
import { AuthGuard } from '../common/guards/auth.guard';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { MycardsComponent } from './views/mycards/mycards.component';
import { NeedHelpComponent } from './views/need-help/need-help.component';
import { PayPalPaymentComponent } from './views/pay-pal-payment/pay-pal-payment.component';
import { SellerReadyComponent } from './views/seller-ready/seller-ready.component';
import { MyProfileComponent } from './views/my-profile/my-profile.component';
import { MySubmissionsComponent } from './views/my-submissions/my-submissions.component';
import { MycardsDownloadComponent } from './views/mycards-download/mycards-download.component';

export const routes: Routes = [
  {
    // path: '',
    path: 'account',
    canActivate: [AuthGuard],
    component: GeneralLayoutComponent,
    children: [
      { path: '', component: AccountProfileComponent },
      { path: 'dashboard', component: DashboardComponent },
      { path: 'my-cards', component: MycardsComponent },
      { path: 'my-cards-download', component: MycardsDownloadComponent },
      { path: 'needhelp', component: NeedHelpComponent },
      { path: 'receipts', component: PayPalPaymentComponent },
      { path: 'myorder', component: MySubmissionsComponent },
      { path: 'sellerready', component: SellerReadyComponent },
      { path: 'myprofile', component: MyProfileComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AccountRoutingModule {}
