import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { GeneralLayoutComponent } from '../common/layout/general-layout/general-layout.component';
import { OrderListComponent } from './views/order-list/order-list.component';
import { LeftNavLayoutComponent } from '../common/layout/left-nav-layout/left-nav-layout.component';
import { InvoiceComponent } from './views/invoice/invoice.component';
import { CollectionListComponent } from './views//collection-list/collection-list.component';
import { AuthGuard } from '../common/guards/auth.guard';
import { PaypalComponent } from '../paypal/paypal.component';
import { StripeComponent } from './views/stripe/stripe.component';
import { StripeErrorComponent } from './views/stripe-error/stripe-error.component';

export const routes: Routes = [
  {
    path: 'orders',
    // path: '',
    canActivate: [AuthGuard],
    component: GeneralLayoutComponent,
    children: [
      {
        path: ':id',
        children: [
          {
            path: 'invoice',
            component: InvoiceComponent,
          },
        ],
      },
      {
        path: ':id',
        children: [
          {
            path: 'paypal',
            component: PaypalComponent,
          },
        ],
      },
      {
        path: ':id',
        children: [
          {
            path: 'stripe',
            component: StripeComponent,
          },
        ],
      },
      {
        path: ':id',
        children: [
          {
            path: 'stripe-error',
            component: StripeErrorComponent,
          },
        ],
      },
    ],
  },
  {
    path: 'authcollection',
    canActivate: [AuthGuard],
    component: GeneralLayoutComponent,
    children: [
      {
        path: '',
        component: LeftNavLayoutComponent,
        children: [
          {
            path: '',
            component: CollectionListComponent,
          },
        ],
      },
    ],
  },
  {
    path: 'authcollection',
    canActivate: [AuthGuard],
    component: GeneralLayoutComponent,
    children: [
      {
        path: '',
        component: LeftNavLayoutComponent,
        children: [
          {
            path: '',
            component: CollectionListComponent,
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class OrdersRoutingModule {}
